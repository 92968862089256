import RegistrationFunnel from "pages/RegistrationFunnel";
import { Navigate } from "react-router-dom";
import { PATH } from "./path";

export const regFunnelRoutes = [
    {
        path: "*",
        element: <Navigate to={PATH.regFunnel.root} />,
    },
    {
        path: PATH.regFunnel.root,
        element: <RegistrationFunnel />,
    },
    {
        path: PATH.regFunnel.b,
        element: <RegistrationFunnel />,
    },
];
