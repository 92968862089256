import { ReactComponent as Alibaba } from "assets/icons/alibaba.svg";
import { ReactComponent as Aliexpress } from "assets/icons/aliexpress.svg";
import { ReactComponent as Amazon } from "assets/icons/amazon.svg";
import { ReactComponent as Cj } from "assets/icons/cj.svg";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import { ReactComponent as Reload } from "assets/icons/reload.svg";
import { ReactComponent as Walmart } from "assets/icons/walmart.svg";

import Button from "components/Button";
import HoveredIcon from "components/HoveredIcon";
import Input from "components/Input";
import Loading from "components/Loading";
import SelectFolder from "components/Modal/SelectFolder";
import {
  ALIEXPRESS_SITE,
  CJ_DROPSHIPPING,
  GOOGLE_LENS,
  WALMART_SITE
} from "config/constants";
import { ErrorCodes, ErrorMsgs } from "config/errorMsgs";
import useModal from "hooks/useModal";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  iconFinderApi,
  useAddManuallyMutation,
  useSaveItemMutation
} from "store/api/iconFinderApi";
import { useGetSettingsQuery } from "store/api/quickSettingsApi";
import { Option } from "types/common";
import {
  IconFinderItem,
  PostAddManuallyResponse
} from "types/services/iconFinder";
import findBoxIcon from "utils/findBoxIcon";
import { CURRENCY_SIGNS } from "utils/formatCurrency";
import { makeQueryParams } from "utils/makeQueryParams";
import { getAmazonDomain, getCountryFromMarketplace } from "utils/settings";
import AliexpressSimilarItems from "./AliexpressSimilarItems";
import IconFinderSelectedItem from "./IconFinderSelectedItem";
import ItemFinderWrapper from "./ItemFinderWrapper";
import SimilarItemsList from "./SimilarItemsList";

interface Props {
  helpers: ReturnType<useModal>;
  item: IconFinderItem;
  marketplace?: string;
  currency?: CURRENCY_SIGNS;
  thirdParty?: boolean;
  dashboardMarketPlace?: boolean
  sourcePlace?: string
}

export interface SaveItem {
  asin: string;
  title: string;
  price: number;
  pictureUrl: string;
  profit: string | number;
}

const GetIcon = {
  WAL: { Icon: Walmart, className: "fill-[#FEC301]" },
  Amazon: { Icon: Amazon, className: "" },
  Cjd: { Icon: Cj, className: "fill-[#FF6600]" },
  Ali: { Icon: Aliexpress, className: "" },
  Baba: { Icon: Alibaba, className: "" }
}
const IconFinder: FC<Props> = ({ helpers, item, currency, marketplace, thirdParty = false, dashboardMarketPlace = false, sourcePlace = "" }) => {
  const [selectedFolder, setSelectedFolder] = useState<Option | null>(null);
  const [itemData, setItemData] = useState<PostAddManuallyResponse | null>(
    null
  );
  const [saveItem, { isLoading: saveLoading }] =
    useSaveItemMutation();

  const [isAddItemId, setIsAddItemId] = useState(false);
  const [addItemValue, setAddItemValue] = useState("");
  const dispatch = useDispatch();
  const onFolderSelect = (newVal: Option | null) => {
    setSelectedFolder(newVal);
  }
  const [postAddManually, { isLoading, data }] = useAddManuallyMutation();
  const { data: settings } = useGetSettingsQuery();
  const site = marketplace || settings?.result.amazonsite;
  const amazonDomain = dashboardMarketPlace === true ? getCountryFromMarketplace(sourcePlace) : settings?.result.amazonsite;
  const isWal = site === "WAL"
  const isPlural = isWal || site === "US" ? "" : "s"
  const MarketplaceIcon = site === "Baba" ||
    site === "Cjd" ||
    isWal ||
    site === "Ali" ? GetIcon[site] : GetIcon.Amazon

  const onAddItemValueChange = (newVal: string) => {
    if (newVal.length <= 10) {
      setAddItemValue(newVal.toUpperCase().trim());
    }
  };

  const findIcon = findBoxIcon(site || "");
  const isNotAmazon = site === "Baba" || site === "Cjd" || site === "Ali" || (!dashboardMarketPlace && isWal);
  useEffect(() => {
    if (data) {
      setItemData(data);
    }
  }, [data]);

  const redirectTo = {
    google: makeQueryParams(`${GOOGLE_LENS}/uploadbyurl`, {
      url: item.img,
    }),
    amazon: makeQueryParams(`https://www.amazon${getAmazonDomain(amazonDomain || "US")}/s`, { k: item.title }),
    aliexpress: `${ALIEXPRESS_SITE}/af/${item.title}.html?switch_new_app=y&origin=n&dida=y`,
    walmart: makeQueryParams(`${WALMART_SITE}/search`, {
      query: item.title,
    }),
    cj: makeQueryParams(
      `${CJ_DROPSHIPPING}/list/wholesale-all-categories-l-all.html`,
      { search: item.title }
    ),
  };

  const onSaveItem = async ({
    asin,
    title,
    price,
    pictureUrl,
    profit,
  }: SaveItem) => {
    var listid = "0"
    if (
      Boolean(Number(selectedFolder?.value)) === false ||
      selectedFolder === null
    ) {
      listid = site === "Ali" ? "-1" : "0"
    } else {
      listid = selectedFolder?.value
    }
    await saveItem({
      sendBulkItems: [
        {
          ebayTitle: item.title,
          ebayPrice: item.price.toString(),
          ebayItemId: item.id.toString(),
          amazonTitle: title,
          amazonPrice: price,
          amazonImageUrl: pictureUrl,
          asin,
          profit,
          sales: item.sold,
        },
      ],
      listid: listid,
      requestId: item.id.toString(),
      userID: 0,
      type: "Ebay",
    }).unwrap().then((data) => {
      if (data.message === ErrorCodes.itemAlreadyAdded) {
        toast.error(ErrorMsgs.ItemAlreadyAdded(selectedFolder?.text || ""));
      } else {
        toast.success("Item Saved!");
      }
    }).catch((error) => {
      if (error?.data === ErrorCodes.itemAlreadyAdded) {
        toast.error(ErrorMsgs.ItemAlreadyAdded(selectedFolder?.text || ""));
      }
    });

  };

  const onReloadItemId = () => {
    postAddManually({
      asin: addItemValue,
      itemId: item.id.toString(),
      isWal,
    }).unwrap().then((data) => {
      if (data.result.item) {
        setIsAddItemId(false);
        setAddItemValue("")
        toast.success(isWal ? "Item ID reloaded successfully" : "Asin Added Successfully");
      } else {
        toast.error("Something went wrong, please try again later");
      }
    }).catch((error) => {
      if (error) {
        toast.error("Something went wrong, please try again later");
      }
    })
  }
  const onHandleClose = () => {
    setIsAddItemId(false);
    setAddItemValue("");
    setItemData(null);
    dispatch(iconFinderApi.util.resetApiState());
  }

  useEffect(() => {
    if (helpers.isOpen) {
      setIsAddItemId(false);
      setAddItemValue("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);




  return (
    <ItemFinderWrapper helpers={helpers} onHandleClose={onHandleClose} redirectTo={redirectTo} type="ebay">
      <IconFinderSelectedItem item={item} currency={currency} />
      {isLoading ? (
        <div className="w-full h-[150px] flex justify-center items-center">
          <Loading fill="fill-primaryPurple dark:fill-purple500" width={50} height={50} />
        </div>
      ) : (
        <>
          <div className="flex justify-between items-center mb-5.5">
            <div className="flex items-center gap-3">
              <MarketplaceIcon.Icon className={MarketplaceIcon.className} />
              <h6 className="text-xs font-bold dark:text-grey100">
                {((item.isSimilar && item.isGreen && !dashboardMarketPlace))
                  ? `Similar Product${isPlural} on ${findIcon.title}`
                  : item.isGreen || itemData
                    ? `Product${isPlural} on ${findIcon.title}`
                    : !isNotAmazon ? `We could not find similar item on ${findIcon.title}` : (!dashboardMarketPlace && isWal) ?
                      `We could not find similar item on ${findIcon.title}` : `Similar products on ${findIcon.title}`}
              </h6>
            </div>
            {
              site !== 'Baba' && site !== 'Cjd' &&
              <SelectFolder
                cutLongOption={14}
                cutTitle={14}
                listWrapperClassName="!top-11"
                btnClassName="!h-9 !min-w-[195px]"
                type={site !== "Ali" ? "eBay" : "aliexpress"}
                isOpen={helpers.isOpen}
                selectedFolder={selectedFolder}
                onFolderSelect={onFolderSelect}
              />
            }

          </div>

          {helpers.isOpen && item.isGreen ? (
            !isNotAmazon ? (
              <SimilarItemsList
                item={item}
                marketplace={site}
                selectedFolder={selectedFolder}
                addAsinData={itemData}
                currency={currency}
                onSaveItem={onSaveItem}
                saveLoading={saveLoading}
                thirdParty={thirdParty}
              />
            ) : (
              <AliexpressSimilarItems
                item={item}
                helpers={helpers}
                marketplace={site}
                onSaveItem={onSaveItem}
              />

            )
          ) : (
            (!dashboardMarketPlace && findIcon.title.toLowerCase() === "amazon" && item.isGreen === false) || (!dashboardMarketPlace && isWal && item.isGreen === false) ?
              (
                <SimilarItemsList
                  item={item}
                  marketplace={site}
                  selectedFolder={selectedFolder}
                  addAsinData={itemData}
                  currency={currency}
                  onSaveItem={onSaveItem}
                  saveLoading={saveLoading}
                  thirdParty={thirdParty}
                />) : (!dashboardMarketPlace ?
                  <AliexpressSimilarItems
                    item={item}
                    helpers={helpers}
                    marketplace={site}
                    onSaveItem={onSaveItem}
                  /> : "")
          )}
          {(isWal && !item.isGreen) || !isNotAmazon ? (isAddItemId ? (
            <div className="flex items-center gap-4">
              <Input
                className="max-w-[200px] w-full"
                placeholder={`e.g ${isWal ? "403031940" : "ABC1234567"}`}
                erase
                value={addItemValue}
                handleChange={onAddItemValueChange}
              />
              {addItemValue.length > 0 ? (
                <HoveredIcon
                  variant="outlined"
                  Icon={Reload}
                  handleClick={onReloadItemId}
                  title="Reload"
                  iconClassName={`w-3.5 group-hover:rotate-180 group-hover:transition-rotate group-hover:duration-200`}
                />
              ) : (
                ""
              )}
            </div>) : (

            <Button
              title={isWal ? "Add Item ID Manually" : "Add ASIN Manually"}
              handleClick={() => setIsAddItemId(true)}
              color="smoothGreen"
              className={isWal ? "max-w-[210px]" : "max-w-[200px]"}
              Icon={Plus}
            />
          )) : ""}
        </>
      )}
    </ItemFinderWrapper>
  );
};

export default IconFinder;
