import { FeaturesRow } from "./ProPlus";

const Enterprise = () => (
  <div className="flex flex-col gap-4 mx-auto">
    <div className="font-medium text-sm px-2">
      All PRO+ plan features, and:
    </div>
    <FeaturesRow title="Autopilot" subtitle={<span>
      Automatically find dropshipping products from AliExpress,<br className="hidden max-[1060px]:block max-[965px]:hidden" /> Amazon, and
      Walmart
    </span>} />
    <FeaturesRow title="Turbo Scanner" subtitle="Automatically discover the best selling dropship items from Amazon and Walmart" />
  </div>
);

export default Enterprise;
