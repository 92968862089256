import risky_keywords from "assets/data/riskKeywords";
import vero_brands from "assets/data/veroBrands";
import { useMemo } from "react";

export type FindKeywords = (
  name: string,
  check?: boolean
) => {
  risky: string[];
  vero: string[];
  foundRisky: boolean;
  foundVero: boolean;
  noKeywords: boolean;
};

const useFindKeywords: FindKeywords = (name, check) => {
  const checkVero = () => {
    if (name && check) {
      return vero_brands.filter((veroKeyword) =>
        (" " + name + " ")
          .toLowerCase()
          .includes(" " + veroKeyword.toLowerCase() + " ")
      );
    } else {
      return [];
    }
  };
  const checkRisky = () => {
    if (name && check) {
      return risky_keywords.filter((riskKeyword) =>
        (" " + name + " ")
          .toLowerCase()
          .includes(" " + riskKeyword.toLowerCase() + " ")
      );
    } else {
      return [];
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedRisky = useMemo(() => checkRisky(), [check, name]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedVero = useMemo(() => checkVero(), [check, name]);

  const foundRisky = memoizedRisky.length > 0;
  const foundVero = memoizedVero.length > 0;

  return {
    risky: memoizedRisky,
    vero: memoizedVero,
    foundRisky,
    foundVero,
    noKeywords: !foundVero && !foundRisky,
  };
};

export default useFindKeywords;
