import { ReactComponent as Clear } from "assets/icons/clear-input.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import FilterCheckbox from "components/Filters/FilterCheckbox";
import Scrollbar from "components/Scrollbar";
import { useEffect, useRef, useState } from "react";
import { Option } from "types/common";


interface Props {
    listWrapperClassName?: string;
    options: Option[];
    value: string | string[] | any;
    handleChange?: (newValue: string | number) => void;
    handleReset?: () => void;
    isCurrency?: boolean;
    dividerAfter?: number;
    hideSearchBar?: boolean;
    showCheckedFirst?: boolean;
    maxCheckedAndDisableAfter?: number;
}
const AdvancedOptions = ({ listWrapperClassName, value, options, handleChange, handleReset, isCurrency, dividerAfter, hideSearchBar, showCheckedFirst, maxCheckedAndDisableAfter }: Props) => {
    const [listOptions, setListOptions] = useState(options);
    const ref = useRef<HTMLInputElement>(null);
    const handleKeyDown = () => {
        const searchVal = ref.current?.value.toLowerCase();
        const filteredOption = options?.filter(option => option.text.toLowerCase().includes(searchVal || ""));
        setListOptions(filteredOption);
    }

    const resetFilters = () => {
        handleReset?.();
        ref.current!.value = "";
        setListOptions(options);
    }

    useEffect(() => {
        if (showCheckedFirst) {
            let checked: Option[] = [];
            listOptions?.map((list) => {
                value?.find((val: string | number) => {
                    val === list.value && checked.push(list)

                })

            });
            const merge = [...checked, ...listOptions]
            const checkedFirst = Array.from(new Set(merge));
            setListOptions(checkedFirst);
        }

    }, [value])

    return (

        <div
            className={`pr-1 p-2 bg-white absolute top-16 rounded-2xl z-20 shadow-lg w-full border border-[#CBD5E0]  dark:border-grey700 text-sm dark:bg-deepSpace900 dark:text-grey100  ${listWrapperClassName || ""
                }`}
        >
            <Scrollbar className="max-h-[331px]">
                {!hideSearchBar &&
                    <div className="flex items-center py-[5px] px-[12px] border-[2px] border-darkBlue dark:border-grey100 rounded-full gap-[8px] mb-[3px]">
                        <SearchIcon className="fill-neutral3 dark:fill-grey200 w-3.5 h-3.5" />
                        <input autoFocus placeholder="Search" ref={ref} onChange={handleKeyDown} className="placeholder:text-neutral3 placeholder:dark:text-grey100 text-[14px] leading-[20px] w-full bg-transparent" />
                    </div>
                }


                <FilterCheckbox
                    value={value}
                    options={listOptions}
                    handleChange={(newValue) =>
                        handleChange?.(newValue)
                    }
                    isCurrency={isCurrency}
                    multiselect
                    checkboxClassName="w-[16px] h-[16px] !rounded-[4px] dark:hover:!border-lilac400"
                    className="!gap-[3px]"
                    optionLabelClassName="pl-[12px] pr-[8px] h-[36px] bg-transparent dark:bg-transparent dark:hover:!bg-purple500 border-transparent"
                    checkmarkClassName="w-[8px] h-[6px]"
                    dividerAfter={dividerAfter}
                    maxCheckedAndDisableAfter={maxCheckedAndDisableAfter}
                />
                {handleReset &&
                    <div onClick={resetFilters} className="w-[90px] h-[36px] bg-neutral2 hover:bg-primaryPurple flex items-center justify-center gap-[10px] rounded-full cursor-pointer group mt-[3px] dark:bg-grey700 dark:hover:bg-purple500">
                        <span className="text-[14px] group-hover:text-white dark:text-lilac400 dark:group-hover:text-grey100">Reset</span>
                        <div className="w-[16px] h-[16px] flex items-center justify-center">
                            <Clear className="fill-darkBlue w-[8px] h-[8px] group-hover:fill-white dark:fill-lilac400 dark:group-hover:fill-lilac400" />
                        </div>

                    </div>
                }

            </Scrollbar>
        </div>


    )
}

export default AdvancedOptions