import Card from "components/Card";
import CustomRechartsTooltip from "components/CustomRechartsTooltip";
import Loading from "components/Loading";
import { THEME_COLORS } from "config/constants";
import { useAppSelector } from "hooks/useAppSelector";
import { FC } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { GetUserStoreDetailsRes } from "types/services/store";
const weekDays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

interface Props {
  isStorePage?: boolean;
  storeDetails?: GetUserStoreDetailsRes;
  isFetching?: boolean;
}
const RushDay: FC<Props> = ({ isStorePage, storeDetails, isFetching }) => {
  const { isDarkMode } = useAppSelector((state) => state.themeSlice);
  const rushDays = storeDetails
    ? JSON.parse(storeDetails?.result?.rushDays)?.map(
      (item: { Day: number; Sales: string }) => ({
        day: weekDays[item.Day],
        ...item,
      })
    )
    : [];

  return (
    <Card className="p-6 pt-7 pb-4 text-center dark:text-grey100" bordered>
      <h5 className="mb-3 font-bold">Rush Day</h5>
      <div className={isStorePage ? "h-[188px]" : "h-[160px]"}>
        {isFetching ? (
          <div className="flex items-center justify-center h-full">
            <Loading fill="fill-primaryPurple dark:fill-purple500" height={48} width={48} />
          </div>
        ) : (
          <ResponsiveContainer width="100%">
            <AreaChart
              width={500}
              height={100}
              className="product-graph-chart"
              data={rushDays}
              margin={{
                top: 10,
                right: 5,
                left: -55,
                bottom: 0,
              }}
            >
              <CartesianGrid
                stroke={THEME_COLORS[isDarkMode ? "deepSpace800" : "secondary"]}
                strokeWidth="2"
                rx={25}
                vertical={false}
                radius="40px"
              />
              <XAxis
                dataKey="day"
                strokeWidth={0}
                interval="preserveStartEnd"
                style={{
                  fill: THEME_COLORS[isDarkMode ? "grey100" : "darkBlue"],
                  textAnchor: "middle",
                  fontSize: 12,
                }}
                tickLine={false}
                tickMargin={11}
              />
              <YAxis strokeWidth={0} tickFormatter={() => ""} />
              {/* <YAxis tickCount={4} strokeWidth={0} style={{ display: "none" }} /> */}
              <Tooltip
                content={<CustomRechartsTooltip valueLabel="Sales" />}
                cursor={false}
                wrapperStyle={{ outline: "none" }}
              />
              <Area
                type="monotone"
                dataKey="Sales"
                activeDot={{
                  style: {
                    stroke: THEME_COLORS[isDarkMode ? "grey100" : "darkBlue"],
                    zIndex: "10",
                  },
                }}
                unit="date"
                strokeWidth={2}
                stroke={THEME_COLORS[isDarkMode ? "grey100" : "darkBlue"]}
                fill="#66E7D7"
              />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </div>
    </Card>
  );
};

export default RushDay;
