import Button from "components/Button";
import Textarea from "components/Input/Textarea";
import { useState } from "react";

interface Props {
    handleSubmit: (val: string) => void;
}

const GetFeedback = ({ handleSubmit }: Props) => {
    const [inputVal, setInputVal] = useState("");

    const handleChange = (newVal: string) => {
        setInputVal(newVal);
    }
    return (
        <div className="flex flex-col items-center">
            <h3 className="text-center text-xl font-semibold">Please, tell us why you are leaving.</h3>
            <p className="text-center text-xl mb-[19px]">Your feedback help us improve!</p>
            <Textarea
                value={inputVal}
                handleChange={handleChange}
                className="max-w-[475px] h-[152px] mb-6 scrollbar dark:placeholder:text-grey100 placeholder:!font-normal !text-base"
                placeholder="Type your feedback here"
            />
            <Button title="Submit" height="h-16" className="border-darkBlue text-darkBlue max-w-[214px]" titleClassName="!text-base font-medium" handleClick={() => { handleSubmit(inputVal) }} />

        </div>
    )
}

export default GetFeedback;