import TitleInput from "components/Input/TitleInput";
import { useAppSelector } from "hooks/useAppSelector";
import { useState } from "react";
import { toast } from "react-toastify";
import TitleAnalyticsLists from "../lists/TitleAnalytics";
import TopSellingTitles from "../TopSellingTitles";

const TitleAnalyticsContent = () => {
  const [isTitleError, setIsTitleError] = useState(false)
  const { topTenSellingTitles } = useAppSelector(
    (state) => state.prSlice.ebayData.result
  );
  const [title, setTitle] = useState("");

  const handleTitleChange = (newVal: string) => {
    setTitle(newVal);
  };


  const onTitleCopy = (newVal: string) => {
    if ((title.length + newVal.length) > 80) {
      setIsTitleError(true);
      toast.warning("Title can have maximum of 80 characters.");
      setTimeout(() => {
        setIsTitleError(false);
      }, 2000);
    } else {
      setTitle(t => (t + " " + newVal).trimStart())
      setIsTitleError(false);
    }
  };

  return (
    <div className="flex flex-col gap-5">
      <TitleInput title={title} handleChange={handleTitleChange} hideTitleText cardClassName="pt-5.5 pl-[37px] pr-[65px] max-lg:pr-5 max-lg:pl-5" error={isTitleError} />
      <TopSellingTitles
        titles={topTenSellingTitles || []}
        handleTitleChange={onTitleCopy}
      />
      <TitleAnalyticsLists onTitleCopy={onTitleCopy} title={title} />
    </div>
  );
};

export default TitleAnalyticsContent;
