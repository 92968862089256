import React from "react";

export const SupplierIcon1: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 229 229">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-111 -141)">
        <g transform="translate(111 141)">
          <g fill="#FED340">
            <circle cx="114.5" cy="114.5" r="114.5" />
          </g>
          <path
            d="m33 114.5c0-45.012 36.488-81.5 81.5-81.5 45.012 0 81.5 36.488 81.5 81.5 0 45.012-36.488 81.5-81.5 81.5-45.012 0-81.5-36.488-81.5-81.5"
            fill="#D8725B"
          />
          <mask fill="white">
            <circle id="path-1" cx="114.5" cy="114.5" r="114.5" />
          </mask>
          <path
            d="m112.8 116.93 68.929-68.929 26.87 26.87-95.729 95.729-0.34181-0.34181-0.070621 0.070621-60.458-60.458 26.87-26.87 33.929 33.929z"
            fill="#FED340"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const SupplierIcon2: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 64 64">
    <circle fill="#FACE32" cx="32" cy="32" r="32" />
    <circle fill="#E3715D" cx="32" cy="32" r="22.667" />
    <path
      fill="#FACE32"
      d="M17.333 20L20 30.67h24L46.667 20zM20 33.335L17.333 44h29.334L44 33.336zm8-32.002h8V16h-8zM28 48h8v14.667h-8z"
    />
  </svg>
);

export const CrownIcon1: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 64 64">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#FACE32"
      d="M52.268 5.564V14.5h-9.784V2.637L32 0 21.516 2.636V14.5h-9.784V5.565L1.592 8.65l2.644 46.703L32 64l27.764-8.647L62.408 8.65z"
    />
    <path
      fill="#FFF"
      d="M35.643 40.64c0-1.067-.33-1.934-.993-2.603-.66-.668-1.808-1.29-3.438-1.866-2.635-.893-4.587-1.948-5.856-3.16s-1.902-2.918-1.902-5.112c0-2.084.61-3.766 1.83-5.047 1.218-1.28 2.906-2.05 5.064-2.307v-4.008h2.942v4.044c2.145.318 3.797 1.203 4.955 2.656 1.16 1.453 1.738 3.423 1.738 5.91h-4.45c0-1.568-.338-2.79-1.012-3.667-.673-.877-1.623-1.316-2.85-1.316-1.274 0-2.217.328-2.83.984-.614.656-.92 1.56-.92 2.712 0 1.078.32 1.94.956 2.583s1.826 1.272 3.567 1.885c2.635.944 4.572 2.013 5.81 3.208 1.238 1.196 1.857 2.884 1.857 5.066 0 2.157-.635 3.858-1.904 5.102s-3.037 1.982-5.304 2.216v3.584H29.98v-3.567c-2.233-.233-4.05-1.02-5.454-2.362-1.403-1.343-2.105-3.374-2.105-6.095h4.47c0 1.777.42 3.03 1.26 3.76.84.73 1.908 1.094 3.208 1.094 1.385 0 2.445-.325 3.18-.975.738-.65 1.105-1.556 1.105-2.72z"
    />
  </svg>
);

export const DiamondIcon: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 64 64">
    <g transform="translate(1 6)" fill="none" fillRule="evenodd">
      <path
        fill="#FF6A00"
        d="M61.228 14.762l-30.285 39.51-.07-.098 15.022-39.412z"
      />
      <path
        fill="#FF8A3D"
        d="M61.53 14.376l-.302.386H45.895L39.048.19h9.565zM21.872.19L15.257 14.76H.337L12.538.19z"
      />
      <path fill="#FF6A00" d="M30.873 54.174L.336 14.762h14.92z" />
      <path fill="#FF8A3D" d="M45.895 14.762L30.873 54.174 15.257 14.762z" />
      <path fill="#FFAC74" d="M45.895 14.762H15.257L21.872.19h17.176z" />
    </g>
  </svg>
);

export const DiamondHalfIcon: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 64 64">
    <defs>
      <filter
        id="a"
        filterUnits="userSpaceOnUse"
        x=".61"
        y="4.728"
        width="61.982"
        height="54.188"
      >
        <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
      </filter>
    </defs>
    <mask
      maskUnits="userSpaceOnUse"
      x=".61"
      y="4.728"
      width="61.982"
      height="54.188"
      id="b"
    >
      <path fill="#FFF" d="M63 5H30.814v53.78H63z" filter="url(#a)" />
    </mask>
    <g transform="translate(.89 .55)" mask="url(#b)">
      <path fill="#F7BC1A" d="M.022 18.83l30.27 37.016.07-.092L15.35 18.83z" />
      <path fill="#FACB25" d="M-.28 18.468l.302.362H15.35l6.84-13.653h-9.56z" />
      <path
        fill="#DAE2ED"
        d="M61.235 19.83h-15.89l-7.58-15.653h11.37l12.385 14.93M46.6 17.83h11.323L48.24 6.177h-7.284L46.6 17.83z"
      />
      <path
        fill="#DAE2ED"
        d="M61.702 19.41l-30.816 38.4-1.442-2.193L45.31 17.83h15.113m-13.788 2L33.943 49.854 58.76 19.83H46.636z"
      />
      <path
        fill="none"
        stroke="#DAE2ED"
        stroke-width="2"
        d="M15.35 18.83l15.013 36.924 15.61-36.924zm0 0h30.62L39.36 5.177H22.192z"
      />
    </g>
    <defs>
      <filter
        id="c"
        filterUnits="userSpaceOnUse"
        x="1"
        y="4.938"
        width="60.487"
        height="53.987"
      >
        <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
      </filter>
    </defs>
    <mask
      maskUnits="userSpaceOnUse"
      x="1"
      y="4.938"
      width="60.487"
      height="53.987"
      id="d"
    >
      <path fill="#FFF" d="M-.226 4.75H31.96v56.818H-.226z" filter="url(#c)" />
    </mask>
    <g transform="translate(.89)" mask="url(#d)">
      <path
        fill="#F7BC1A"
        d="M60.598 19.135l-29.713 39.03-.167.333-.333-.25L45.27 19.134z"
      />
      <path fill="#FF8A3D" d="M21.636 4.938l-6.612 14.574H.11L12.307 4.938z" />
      <path fill="#FF6A00" d="M30.632 58.926L.11 19.512h14.914z" />
      <path fill="#FF8A3D" d="M45.646 19.512L30.632 58.926 15.024 19.512z" />
      <path fill="#FFAC74" d="M45.646 19.512H15.024l6.612-14.574h17.167z" />
    </g>
  </svg>
);
