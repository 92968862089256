import { ReactComponent as LineGraph } from "assets/icons/line-graph.svg";
import { ReactComponent as ProductExplorer } from "assets/icons/product-exlorer.svg";
import { useAppSelector } from "hooks/useAppSelector";
import { FC } from "react";
import { Link } from "react-router-dom";
import { PATH } from "routes/path";
import {
    MARKET_PLACES,
    SOURCE_MARKET_PLACES
} from "types/sellerSettings";
import { makeQueryParams } from "utils/makeQueryParams";
interface Props {
    item: string;
    isClickable: boolean;
    isShopify?: boolean;
}

const KeywordsItem: FC<Props> = ({ item, isClickable, isShopify }) => {
    const isLongTitle = item.length > 100;
    const textStyle = "text-sm my-auto group-hover:text-white dark:text-grey100 dark:group-hover:text-lilac400 text-darkBlue text-sm"
    const {
        sourceMarketplace, marketplace
    } = useAppSelector((state) => state.sellerSettings);
    const redirPath = isShopify ? PATH.shopify.productExplorer : sourceMarketplace === SOURCE_MARKET_PLACES.aliexpress || marketplace === MARKET_PLACES.aliexpress ? PATH.productResearch.aliexpress : PATH.productResearch.ebay;

    const path = makeQueryParams(redirPath, isClickable ? { search: item, autosearch: isShopify ? true : false } : {});
    const wrapperStyle = `flex items-center justify-between border-1 mr-2 px-[17px] py-[11px] bg-white dark:bg-black900 group ${isLongTitle ? "" : "rounded-full hover:bg-primaryPurple dark:hover:bg-purple500"}`
    const iconStyles = "group-hover:fill-white fill-darkBlue"
    const longTitleCont = {
        __html: item.replaceAll("\\n", "<br />").replaceAll("\\r", ""),
    }
    return (

        <>
            {isClickable && <Link
                to={path}
                target="_blank"
                rel="noreferrer"
            >
                <div
                    className={`${wrapperStyle} cursor-pointer`}
                >
                    {isLongTitle ? (
                        <div dangerouslySetInnerHTML={longTitleCont} />
                    ) : (
                        <>
                            <p className={textStyle}>{item}</p>
                            {isShopify ? <ProductExplorer className={`${iconStyles} dark:fill-grey100 dark:group-hover:fill-lilac400 w-[12.83px] h-[13.75px`} /> :
                                <LineGraph className={`${iconStyles} dark:fill-grey100 dark:group-hover:fill-lilac400`} />}
                        </>
                    )}

                </div>
            </Link>}
            {!isClickable &&
                <div
                    className={wrapperStyle}
                >
                    {isLongTitle ? (
                        <div dangerouslySetInnerHTML={longTitleCont} />
                    ) : (
                        <>
                            <p className={textStyle}>{item}</p>
                            {isShopify ? <ProductExplorer className={`${iconStyles} w-[12.83px] h-[13.75px]`} /> : <LineGraph className={iconStyles} />}
                        </>
                    )}
                </div>}
        </>
    );
}

export default KeywordsItem