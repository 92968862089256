import { ReactComponent as Amazon } from "assets/icons/amazon-f.svg";
import { ReactComponent as Ebay } from "assets/icons/ebay.svg";
import { ReactComponent as Other } from "assets/icons/other-seller.svg";
import { ReactComponent as Shopify } from "assets/icons/shopify.svg";
import Checkbox from "components/Input/Checkbox";
import { FC } from "react";


interface Props {
    id: number;
    optionValue: number;
    isChecked: boolean;
    optionLabel: string;
    handleChannelSelection: (opt: number) => void;
};
const getChannelImg = {
    ebay: <Ebay className="w-[86px] h-[49px]" />,
    amazon: <Amazon className="w-[76px] h-6" />,
    shopify: <Shopify className="w-[86px] h-6" />,
    other: <Other className="w-[60px] h-7" />
}
const SalesChannelOption: FC<Props> = ({ id, optionValue, isChecked, optionLabel, handleChannelSelection }) => (
    <label
        key={id}
        htmlFor={optionValue.toString()}
        className={`flex items-center justify-center max-lg:w-full gap-[15px] max-lg:justify-between px-5
           border-2 bg-neutral1 hover:bg-neutral2 border-solid rounded-[10px] h-[84px] max-sm:h-[64px] lg:w-[163px] hover:scale-105 transition-all hover:shadow-xl cursor-pointer 
          ${isChecked ? "border-darkBlue hover:border-primaryPurple" : "border-secondary hover:border-primaryPurple"}`}
    >
        {getChannelImg[optionLabel as keyof typeof getChannelImg]}
        <Checkbox
            rectangleCheckbox
            id={optionValue}
            checked={isChecked}
            value={optionValue}
            handleChange={() => {
                handleChannelSelection(optionValue);
            }}
        />
    </label>
)

export default SalesChannelOption