import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { PATH } from "routes/path";
import { bulkScannerApi } from "store/api/bulkScannerApi";
import { catResApi } from "store/api/catResApi";
import { crApi } from "store/api/crApi";
import { dashboardApi } from "store/api/dashboardApi";
import { foldersApi } from "store/api/foldersApi";
import { iconFinderApi } from "store/api/iconFinderApi";
import { newDashboardApi } from "store/api/newDashboardApi";
import { prApi } from "store/api/prApi";
import { quickSettingsApi } from "store/api/quickSettingsApi";
import { turboScannerApi } from "store/api/turboScannerApi";
import { zikProApi } from "store/api/zikProApi";
import { makeQueryParams } from "utils/makeQueryParams";
import { useAppDispatch } from "./useAppDispatch";

const useResetApi = () => {
  const {
    productResearch: pr,
    competitorResearch: cr,
    categoryResearch,
  } = PATH;
  //CECK EBAY
  const newReq = { newReq: true };
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const handleReset = () => {
    dispatch(prApi.util.resetApiState());
    dispatch(crApi.util.resetApiState());
    dispatch(foldersApi.util.resetApiState());
    dispatch(iconFinderApi.util.resetApiState());
    dispatch(zikProApi.util.resetApiState());
    dispatch(catResApi.util.resetApiState());
    dispatch(quickSettingsApi.util.resetApiState());
    dispatch(turboScannerApi.util.resetApiState());
    dispatch(bulkScannerApi.util.resetApiState());
    dispatch(dashboardApi.util.resetApiState());
    dispatch(newDashboardApi.util.resetApiState());
    if (pathname.includes(pr.ebay)) {
      navigate(makeQueryParams(pr.ebay, newReq));
    } else if (pathname.includes(pr.aliexpress)) {
      navigate(makeQueryParams(pr.aliexpress, newReq));
    } else if (pathname.includes(cr.ebay)) {
      navigate(cr.ebay + search + "&newReq=true");
    } else if (pathname.includes(cr.aliexpress)) {
      navigate(makeQueryParams(cr.aliexpress, newReq));
    } else if (pathname.includes(categoryResearch.results)) {
      searchParams.append("newReq", "true");
      setSearchParams(searchParams);
    }
  };

  return handleReset;
};

export default useResetApi;
