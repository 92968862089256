// Types

// Hooks

// Icons
import { ColumnDef } from "@tanstack/react-table";
import ProductNameCell from "components/ProductNameCell";
import { CURRENCY } from "types/currency";
import { StoreProductList } from "types/services/store";
import { CURRENCY_SIGNS, formatCurrency, formatNumber } from "utils/formatCurrency";

export const storeAnalyticsCols = (
  currency: string,
  handleSort: (column: string) => void
): ColumnDef<StoreProductList>[] => [
    {
      header: "Image",
      accessorKey: "ebayimage",
      enableSorting: false,
      cell: (img) => (
        <div className="w-[7rem] pl-4">
          <ProductNameCell
            img={String(img.getValue())}
            imgWrapperClassName="left-[12px]"
          />
        </div>
      ),
    },
    {
      header: "Title",
      accessorKey: "title",
      cell: ({ row: { original } }) => (
        <a
          href={original.ebayItemUrl}
          target="_blank"
          rel="noreferrer"
          className="hover:text-primaryPurple dark:hover:text-purple500 mx-4"
        >
          {original.title}
        </a>
      ),
      meta: {
        customSort: () => handleSort("Title"),
        sortColumnId: "Title",
      },
    },
    {
      header: "Current Price",
      accessorKey: "currentPrice",
      cell: (price) => (
        <p className="font-bold">{formatCurrency(String(price.getValue()), CURRENCY.none,
          currency as CURRENCY_SIGNS)}</p>
      ),
      meta: {
        customSort: () => handleSort("CurrentPrice"),
        sortColumnId: "CurrentPrice",
      },
    },
    {
      header: "Quantity Sold",
      accessorKey: "selectedColumnSales",
      cell: (sold) => (
        <p className="font-bold">{formatNumber(String(sold.getValue()))}</p>
      ),
      meta: {
        customSort: () => handleSort("SelectedColumnSales"),
        sortColumnId: "SelectedColumnSales",
      },
    },
    {
      header: "Revenue",
      accessorKey: "totalRevenuePerItem",
      cell: (revenue) => (
        <p className="font-bold">{formatCurrency(String(revenue.getValue()), CURRENCY.none,
          currency as CURRENCY_SIGNS)}</p>
      ),
      meta: {
        customSort: () => handleSort("TotalRevenuePerItem"),
        sortColumnId: "TotalRevenuePerItem",
      },
    },
  ];
