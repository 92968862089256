import { COOKIE_DOMAIN } from "config/constants";
import { useAppSelector } from "hooks/useAppSelector";
import LoadingPage from "pages/Loading";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { GetAccessToken } from "utils/localStorage";

const TitleBuilder = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie] = useCookies(['CAT']);
  const { accessToken } = useAppSelector((state) => state.user.result);
  const token = accessToken || GetAccessToken();
  useEffect(() => {
    setCookie("CAT", token, COOKIE_DOMAIN);
    const titleBuilderURL = process.env.REACT_APP_TITLE_BUILDER
    if (titleBuilderURL) {
      window.location.href = titleBuilderURL

      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <LoadingPage loading={true}> </LoadingPage>
  );
}

export default TitleBuilder;


