import { ReactComponent as Clear } from "assets/icons/clear-input.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import useHover from "hooks/useHover";
import { useState } from "react";

interface Props {
    placeholder?: string;
    borderColor?: string;
    handleApply: (newValue: string) => void;
}

const SearchTable = ({ placeholder, borderColor, handleApply }: Props) => {
    const [focusRef, isFocused] = useHover<HTMLInputElement>("focus");
    const [searchTable, setSearchTable] = useState("");

    const handleKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            handleApply(event.target.value);
        }
    }

    return (
        <div className={`w-full flex items-center border-2 rounded-[6.25rem] h-9 max-w-[18.8125rem] ${isFocused
            ? "border-primaryPurple dark:border-purple500"
            : borderColor || "border-darkBlue dark:border-grey100 dark:bg-deepSpace900"
            }`}>
            <div>
                <SearchIcon
                    className={`${isFocused ? "fill-primaryPurple dark:fill-purple500" : "fill-darkBlue dark:fill-grey100"
                        } w-[23px] h-[23px] ml-5.5`}
                />
            </div>
            <input
                className={`h-full overflow-hidden focus:outline-none pl-4 placeholder:text-neutral-3 placeholder:font-normal text-sm bg-transparent dark:text-grey100 w-full`}
                ref={focusRef}
                placeholder={placeholder || "search"}
                autoComplete="off"
                value={searchTable}
                onChange={(e) => setSearchTable(e.target.value)}
                name="search"
                onKeyDown={handleKeyDown}
            />
            {searchTable.length > 0 ? (
                <button
                    className="mr-4 p-2 cursor-pointer group"
                    onClick={() => {
                        setSearchTable("");
                        handleApply("")
                    }}
                >
                    <Clear
                        className="w-3.5 h-3.5 group-hover:fill-primaryPurple dark:group-hover:fill-purple500 fill-neutral3 dark:fill-grey100"
                    />
                </button>
            ) : (
                ""
            )}
        </div>
    )
}

export default SearchTable