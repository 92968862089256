import { ColumnDef } from "@tanstack/react-table";
import { ReactComponent as InfoOrange } from "assets/icons/info-orange.svg";
import HoveredIcon from "components/HoveredIcon";
import Label from "components/Label";
import Tooltip from "components/Tooltip";
import { Link } from "react-router-dom";
import { PATH } from "routes/path";
import { formatDate } from "utils/formatTime";
// Types
import { ScanResultList } from "types/services/autopilot";
//Icons
import { ReactComponent as Reload } from "assets/icons/reload.svg";
import { ReactComponent as Trash } from "assets/icons/trash.svg";

export const autopilotCols = (
  handleDeleteScan: (id: number) => void,
  handleRescanFilters: (rescanFilters: ScanResultList) => void,
): ColumnDef<ScanResultList>[] => [
    {
      header: "View Scan",
      accessorKey: "autoScanId",
      enableSorting: false,
      cell: ({ row: { original } }) => (
        <>
          <Link className="flex justify-center items-center text-darkBlue max-w-[119px] h-[29px] border-2 rounded-full w-full border-darkBlue hover:text-white hover:bg-primaryPurple dark:hover:border-purple500 dark:hover:bg-purple500 hover:border-primaryPurple dark:bg-deepSpace900 dark:border-deepSpace900 dark:text-grey100"
            to={`${PATH.zikPro.autopilotscan.root}/${String(original.autoScanId)}?sup=${String(original.supplier)}`}
            title={`${original.name}`}
          ><span className="text-sm mx-auto w-[90%] text-center whitespace-nowrap overflow-hidden text-ellipsis block">{original.name}</span></Link>
        </>

      ),
    },
    {
      header: "Supplier",
      accessorKey: "supplier",
    },
    {
      header: "Products found",
      accessorKey: "itemFound",
      cell: ({ row: { original } }) =>
        original.status === true ? <div className="flex gap-4 items-center">
          <p>  {String(original.itemFound || 0)}</p>
          <InfoOrange className="cursor-pointer" data-tooltip-id={original.autoScanId.toString()} />
          <Tooltip
            title="New Feature! Each scan excludes previously found products. 
            For including past results in future scans, delete the past scans. If you have low results, you can delete all scans."
            anchorId={original.autoScanId}
            place="top"
          />
        </div> : "",
      meta: {
        alignHead: "justify-between"
      }
    },
    {
      header: "Date Created",
      accessorKey: "dateCreated",
      cell: (date) => formatDate(String(date.getValue())),
    },

    {
      header: "Status",
      accessorKey: "status",
      cell: (status) =>
        status.getValue() === true ? (
          <Label bgColor="success" title="Completed! " />
        ) :
          (
            <Label bgColor="errorOrange" title="Pending " />
          ),
      meta: {
        align: "justify-center",
      },
    },
    {
      header: () => (
        <div className="flex gap-7">
          <Reload data-tooltip-id="rescan-icon" className="fill-darkBlue dark:fill-grey100 cursor-pointer" />
          <Trash data-tooltip-id="delete-icon" className="fill-darkBlue dark:fill-grey100 cursor-pointer" />
          <Tooltip title="Rescan using the same filters (you can also change them)" anchorId="rescan-icon" />
        </div>
      ),
      accessorKey: "delete",
      enableSorting: false,
      cell: ({ row: { original } }) => (
        <div className="flex gap-4">
          <HoveredIcon
            variant="outlined"
            Icon={Reload}
            width="w-6"
            height="h-6"
            handleClick={() => handleRescanFilters(original)}
          />
          <HoveredIcon
            variant="outlined"
            Icon={Trash}
            width="w-6"
            height="h-6"
            iconClassName={"w-[10px]"}
            handleClick={() => handleDeleteScan(original.autoScanId)}
          />
        </div>
      ),
      meta: {
        alignHead: "justify-center"
      }
    },
  ];
