import { ColumnDef } from "@tanstack/react-table";
import { ReactComponent as Box } from "assets/icons/boxes/box.svg";
import ShopifyItemFinderBox from "components/IconFinderBox/ShopifyItemFinderBox";
import ProductNameCell from "components/ProductNameCell";
import { ShopifyCRList } from "types/services/salesSpy";
import { formatCurrency, formatNumber } from "utils/formatCurrency";
import { formatDate } from "utils/formatTime";

export const salesSpyItem = (isSalesHistory: boolean, storeCurrency: string, handleBoxClick: (item: ShopifyCRList) => void): ColumnDef<ShopifyCRList>[] => [
    {
        header: "Product Name",
        accessorKey: "productName",
        enableSorting: false,
        cell: ({ row: { original } }) => (
            <ProductNameCell
                img={original.image}
                name={original.productName}
                url={original.url.includes("https") ? original.url : `https://${original.url}`}
                height="59px"
                width="75px"
                adjustFirstandLastItemsAuto
                hoveredImgClassName="!left-[85px]"
                titleClassName="ml-4"
                maxHeight="max-h-[auto]" />
        ),
        meta: {
            align: "justify-start"
        }
    },
    ...(!isSalesHistory ? [
        {
            header: "Upload Date",
            accessorKey: "uploadDate",
            cell: ({ row: { original } }) => <p className="pl-2">{formatDate(original.uploadDate)}</p>,
            meta: {
                align: "justify-start",
            }
        },
        {
            header: "Price",
            accessorKey: "price",
            cell: ({ row: { original } }) => <div className="pl-2">
                <span className="block">{formatCurrency(original.convertedPrice)}</span>
                {!storeCurrency || storeCurrency === "USD" ? "" : (
                    <span className="block text-grey300">{storeCurrency} {original.price.toLocaleString("en-Us")}</span>
                )}
            </div>,
            meta: {
                align: "justify-start",
            }
        },
        {
            header: () => {
                return <>
                    <div className="flex flex-col">
                        Sales <span className="text-[10px] leading-[12px] font-[500]">Est.</span>
                    </div>

                </>
            },
            accessorKey: "sales",
            cell: ({ row: { original } }) => <p className="pl-2">{formatNumber(original.sales || "")}</p>,
            meta: {
                align: "justify-start",
                tooltipTitle: "Estimated sales based on store activity.",
                tooltipPosition: "left"
            }
        },
        {
            header: () => {
                return <>
                    <div className="flex flex-col">
                        Revenue <span className="text-[10px] leading-[12px] font-[500]">Est.</span>
                    </div>

                </>
            },
            accessorKey: "revenue",
            cell: ({ row: { original } }) => <div className="pl-2">
                <span className="block">{formatCurrency(original.convertedRevenue || "")}</span>
                {!storeCurrency || storeCurrency === "USD" ? "" : (
                    <span className="block text-grey300">{storeCurrency} {original.revenue?.toLocaleString("en-Us")}</span>
                )}
            </div>,
            meta: {
                align: "justify-start",
                tooltipTitle: "Estimated revenue based on store activity.",
                tooltipPosition: "left"
            }
        }
    ] as ColumnDef<ShopifyCRList>[] : [
        {
            header: "Price",
            accessorKey: "price",
            cell: ({ row: { original } }) => <div className="pl-2">
                <span className="block">{formatCurrency(original.convertedPrice)}</span>
                {!storeCurrency || storeCurrency === "USD" ? "" : (
                    <span className="block text-grey300">{storeCurrency} {original.price.toLocaleString("en-Us")}</span>
                )}
            </div>,
            meta: {
                align: "justify-start",
            }
        }, {
            header: "Sales Date",
            accessorKey: "salesDate",
            cell: ({ row: { original } }) => <p className="pl-2">{formatDate(original.salesDate)}</p>,
            meta: {
                align: "justify-start",
                tooltipTitle: "Date when the product was last sold."
            }
        }] as ColumnDef<ShopifyCRList>[]),
    {
        header: () => <Box className="fill-darkBlue dark:fill-grey100" />,
        accessorKey: "itemID",
        enableSorting: false,
        cell: ({ row: { original } }) =>
            <ShopifyItemFinderBox handleClick={() => handleBoxClick(original)} />,
        meta: {
            align: "justify-center",
            alignHead: "justify-center",
            tooltipTitle: 'Find products from your selected supplier. Switch suppliers in "Quick Settings" on the left menu.'
        }
    }
]