import ArikRaz from "assets/images/arik-raz.png";
import TrustpilotScore from "assets/images/trustpilot-score.png";
import ModalFunnelStartWithZikCheckmark from "../components/ModalFunnelStartWithZikCheckmark";
import { RegistrationImageText as Text } from "../components/RegistrationImageText";

const zikFeatures = ["Never miss a profitable trend again", "Find the best niches and products", "Dominate your market"]

const RegistrationRightContent = () => {
    const checkboxStyles = `bg-teal_A400 border border-solid border-teal_A400_01 flex items-center justify-center rounded-[12px]`

    return (
        <div className={`w-[48%] max-lg:w-full max-lg:static max-lg:h-fit bg-white fixed h-full top-0 right-0  max-sm:mt-4`}>
            <div className={`bg-white_A700 flex flex-col h-full justify-center mx-auto  max-lg:gap-4 max-w-[464px] gap-8 max-sm:max-w-[330px]`}>
                <h1
                    className={`text-darkBlue font-bold  max-lg:text-center max-sm:hidden px-6 text-[28px] max-lg:text-[20px]`}
                >
                    When eCommerce Works!
                </h1>
                <div className="flex flex-col gap-8 max-lg:gap-6 max-sm:gap-2">
                    <div className="border border-solid border-secondary flex items-center shadow-[0px_8px_30px_-6px_rgba(211,_218,_226,_0.79)] rounded-[8px] px-5.5 py-4 gap-6 max-sm:p-4">
                        <img src={ArikRaz} alt="Arik Raz" className="min-w-[64px] h-[64px] border-2 border-solid border-secondary rounded-full max-sm:hidden" />
                        <div>
                            <h4 className="mb-2 font-medium max-sm:text-xs max-sm:w-full max-sm:font-normal">
                                “Almost 3 years of using ZIK.<br className="max-sm:hidden" />
                                <span className="text-primaryPurple font-bold max-sm:font-medium">{" "}Truly the best tool</span> ever I used in my life!”
                            </h4>
                            <p className="text-xs">
                                <span className="font-medium">Arik Raz</span> eCommerce Seller
                            </p>
                        </div>

                    </div>
                    <img src={TrustpilotScore} alt="Trustpilot Score" className="max-w-[262px] h-[43px] max-sm:w-[129px] max-sm:h-[21px]" />
                </div>

                <div className="flex flex-col gap-[15px] pl-6 max-sm:hidden">
                    {zikFeatures.map((feature, i) => (
                        <div className="flex flex-row gap-2.5 items-center" key={i}>
                            <ModalFunnelStartWithZikCheckmark className={checkboxStyles} checkmarkStyles="w-[17.35px] h-[17.35px]" />
                            <Text
                                className="font-medium text-gray_900 sm:text-justify sm:w-auto"
                                as="h3"
                                variant="h3"
                            >
                                {feature}
                            </Text>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default RegistrationRightContent