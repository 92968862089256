import { ReactComponent as AmazonHovered } from "assets/icons/boxes/amazon-hovered.svg";
import { ReactComponent as Amazon } from "assets/icons/boxes/amazon.svg";

import { ReactComponent as WalmartHovered } from "assets/icons/boxes/walmart-hovered.svg";
import { ReactComponent as Walmart } from "assets/icons/boxes/walmart.svg";

import { ReactComponent as AliexpressHovered } from "assets/icons/boxes/aliexpress-hovered.svg";
import { ReactComponent as Aliexpress } from "assets/icons/boxes/aliexpress.svg";

import { ReactComponent as AlibabaHovered } from "assets/icons/boxes/alibaba-hovered.svg";
import { ReactComponent as Alibaba } from "assets/icons/boxes/alibaba.svg";

import { ReactComponent as CjdHovered } from "assets/icons/boxes/cjd-hovered.svg";
import { ReactComponent as Cjd } from "assets/icons/boxes/cjd.svg";

const iconDim = "w-[23px] h-[24.5px]";
export const iconFinderConfig = {
  Cjd: {
    title: "CJdropshipping",
    iconClassName: "w-[26px] h-[26px]",
    icon: Cjd,
    iconHovered: CjdHovered,
    success: Cjd,
    successHovered: CjdHovered,
  },
  Ali: {
    title: "AliExpress",
    iconClassName: iconDim,
    icon: Aliexpress,
    iconHovered: AliexpressHovered,
  },
  Baba: {
    title: "Alibaba",
    iconClassName: iconDim,
    icon: Alibaba,
    iconHovered: AlibabaHovered,
  },
  WAL: {
    title: "Walmart",
    iconClassName: iconDim,
    icon: Walmart,
    iconHovered: WalmartHovered,
  },
  Amazon: {
    title: "Amazon",
    iconClassName: iconDim,
    icon: Amazon,
    iconHovered: AmazonHovered,
  },
};

const findBoxIcon = (amazonsite: string) =>
  amazonsite === "Baba" ||
  amazonsite === "Cjd" ||
  amazonsite === "WAL" ||
  amazonsite === "Ali"
    ? iconFinderConfig[amazonsite as keyof typeof iconFinderConfig]
    : iconFinderConfig.Amazon;

export default findBoxIcon;
