import { selectedItemsCols } from "assets/tableColumns/iconFinder";
import Table from "components/Table";
import { TableStyles } from "components/Table/Table";
import { useAppSelector } from "hooks/useAppSelector";
import useTable from "hooks/useTable";
import { FC } from "react";
import { IconFinderItem } from "types/services/iconFinder";
import { CURRENCY_SIGNS } from "utils/formatCurrency";

interface Props {
  item: IconFinderItem;
  currency?: CURRENCY_SIGNS;
}

const tableStyles: TableStyles = {
  px: "px-0",
  style: "max-[1400px]:overflow-x-visible mb-8",
  body: {
    contHeight: "h-[50px]",
    rowHeight: "h-[85px]",
  },
};

const IconFinderSelectedItem: FC<Props> = ({ item, currency }) => {
  const { sourceMarketplace } =
    useAppSelector((state) => state.sellerSettings);

  const { table } = useTable({
    columns: selectedItemsCols(sourceMarketplace !== 'aliexpress' ? currency : "$"),
    dataDeps: [item],
    data: [item],
  });


  return (
    <Table<IconFinderItem> table={table} hideTableHead styles={tableStyles} />
  );
};

export default IconFinderSelectedItem;
