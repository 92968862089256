import InfoTooltip from "components/Card/InfoTooltip";
import DatePicker from "components/Input/DatePicker";
import { FC } from "react";

interface Props {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  value: {
    min: Date | null;
    max: Date | null;
  };
  type?: "date" | "number";
  handleChange: (newValue: Date | null, type: "min" | "max") => void;
  inputWrapperClassName?: string;
  inputWidth?: string;
  className?: string;
  iconClassName?: string
  bordered?: boolean;
  infoTitle?: string;
  infoTitleClassName?: string;
  datePickerClassName?: string;
}

const MinMaxDateFilter: FC<Props> = ({
  Icon,
  title,
  value,
  bordered,
  className,
  iconClassName,
  inputWrapperClassName,
  infoTitle,
  infoTitleClassName,
  datePickerClassName,
  handleChange,
}) => (
  <div
    className={`flex flex-col items-center justify-start gap-[19px] relative ${bordered ? "xl:border-r-2 xl:border-r-[#C8E1FF]" : ""
      } ${className}`}
  >
    <div className="flex gap-3 justify-between w-full max-w-[230px] h-6 items-center">
      <Icon className={`fill-darkBlue ${iconClassName}`} />
      <h4 className="font-bold text-sm">{title}</h4>
      {infoTitle && (
        <InfoTooltip title={infoTitle} className={` ${infoTitleClassName || ""}`} />
      )}
    </div>
    <div
      className={`flex items-center gap-[1.875rem] justify-center ${inputWrapperClassName || ""
        }`}
    >
      <DatePicker
        value={value.min}
        handleChange={(newValue) => handleChange(newValue, "min")}
        className={`w-[6.5rem] ${datePickerClassName}`}
        maxDate={new Date()}
      />
      <DatePicker
        value={value.max}
        handleChange={(newValue) => handleChange(newValue, "max")}
        className={`w-[6.5rem] ${datePickerClassName}`}
        maxDate={new Date()}
      />
    </div>

  </div>
);

export default MinMaxDateFilter;
