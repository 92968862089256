import tools, { shopifyTools } from "assets/data/dashboard/tools";
import { ReactComponent as Clear } from "assets/icons/clear-input.svg";
import useDecodeToken from "hooks/useDecodeToken";
import { FC } from "react";
import { Link } from "react-router-dom";

interface Props {
  bookmarkedItems: string[];
  handleRemove: (submenu_id: string) => void;
  isShopify?: boolean;
}

interface SubmenuProps {
  title: string;
  id: number;
  path?: string;
  href?: string;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>
  iconClassName?: string
}


interface ItemProps {
  submenu: SubmenuProps;
  handleRemove: (id: string) => void;
}
const BookmarkItem: FC<ItemProps> = ({ submenu, handleRemove }) => (
  <div className="flex  items-center justify-between h-6 min-h-[24px]">
    <Link
      className="text-xs  text-left cursor-pointer leading-[18px] group flex items-center gap-[5px] hover:text-primaryPurple dark:hover:text-purple500"
      to={submenu.href || submenu.path || ""}
      target="_blank"
    >
      {submenu.icon ?
        <div className="h-6 w-6 justify-center flex items-center">
          <submenu.icon className={`group-hover:fill-primaryPurple dark:group-hover:text-purple500 fill-darkBlue dark:fill-grey100 ${submenu?.iconClassName || ""}`} />
        </div> : ""}
      <span>

        {submenu.title}
      </span>
    </Link>

    <button
      onClick={() => handleRemove(submenu.id.toString())}
      className="h-4 w-4 flex items-center justify-center group"
    >
      <Clear className="fill-darkBlue group-hover:fill-primaryPurple dark:group-hover:fill-purple500 w-2 h-2 dark:fill-grey100" />
    </button>
  </div>
)
const BookmarkedItems: FC<Props> = ({ bookmarkedItems, handleRemove, isShopify }) => {
  const userDetails = useDecodeToken();
  const plan = userDetails.package;
  const items = isShopify ? shopifyTools : tools(plan);
  return (
    <div className={`flex flex-col gap-[10px] text-[11px] w-full xl:pr-1 max-sm:pr-8 ${bookmarkedItems.length > 14 ? "scrollbar overflow-y-auto max-h-[540px]" : ""}`}>
      {bookmarkedItems.map((bookmarkedItem, index) =>
        items.items.map((menu) =>
          menu.submenus.map((submenu: SubmenuProps) =>
            bookmarkedItem === submenu.id.toString() ? (
              <BookmarkItem submenu={submenu} key={index} handleRemove={handleRemove} />
            ) : (
              ""
            )
          )
        )
      )}
    </div >
  );
};

export default BookmarkedItems;
