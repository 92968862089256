import { createApi } from "@reduxjs/toolkit/query/react";
import {
  CrHistoryRequest,
  CrHistoryResponse,
  PostCrAliRequest,
  PostCrAliResponse,
  PostCrEbayRequest,
  PostCrEbayResponse,
  PostCrSaveBulkRequest,
} from "types/services/cr";
import baseQuery from "./baseQuery";
import { queryKeys } from "./queryKeys";
import { queryTags } from "./queryTags";

export const crApi = createApi({
  reducerPath: "cr",
  baseQuery,
  tagTypes: [queryTags.watchList.ebayCrHistory],
  endpoints: (builder) => ({
    crEbay: builder.mutation<PostCrEbayResponse, PostCrEbayRequest>({
      query: (body) => ({
        url: queryKeys.cr.getEbay,
        method: "POST",
        body,
      }),
      invalidatesTags: [queryTags.watchList.ebayCrHistory],
    }),

    getCrHistory: builder.query<CrHistoryResponse, CrHistoryRequest>({
      query: (params) => ({
        url: queryKeys.cr.getHistory,
        method: "GET",
        params,
      }),
      providesTags: [queryTags.watchList.ebayCrHistory],
    }),

    crSaveBulk: builder.mutation<null, PostCrSaveBulkRequest>({
      query: (body) => ({
        url: queryKeys.cr.saveBulk,
        method: "POST",
        body: body,
      }),
    }),

    crAli: builder.mutation<PostCrAliResponse, PostCrAliRequest>({
      query: (body) => ({
        url: queryKeys.cr.getAli,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const {
  useCrAliMutation,
  useCrEbayMutation,
  useCrSaveBulkMutation,
  useGetCrHistoryQuery,
} = crApi;
