import { useEffect } from "react";
import { useGetFeedbackQuery } from "store/api/onboardingApi";
import GetFeedback from "./GetFeedback";
import WelcomeStep from "./WelcomeStep";
import YesOrNoQuestion from "./YesOrNoQuestion";

interface Props {
  introStep: 0 | 1 | 2;
  isPricingFlow: boolean;
  onIntroStepChange: (step: 0 | 1 | 2) => void;
  onStepChange: (newStep: number) => void;
  step: number;
}

const Introduction = ({ introStep, step, onIntroStepChange, onStepChange, isPricingFlow }: Props) => {
  const { data: feedback } = useGetFeedbackQuery();
  const showFeedback = feedback?.message === null || feedback?.message === ""
  const stepContStyles = `flex flex-col max-sm:h-[60%] max-sm:justify-between max-lg:pt-[144px] max-sm:pt-2 max-sm:mt-0 items-center`
  const onWelcomeBtnClick = () => onIntroStepChange(1)
  const onFeedbackBtnClick = () => onIntroStepChange(2);

  useEffect(() => {
    if (isPricingFlow) {
      onIntroStepChange(2)
    }
  }, [])

  return (
    <div className={`flex flex-col justify-start max-sm:h-fit w-full mx-auto 
     ${introStep === 0 ? "max-w-[426px] max-lg:max-w-[536px] max-sm:max-w-[330px]" : introStep === 1 && showFeedback ? "max-w-[302px] max-lg:max-w-[536px] max-sm:h-[calc(100vh-65px)]" : "max-w-full"} 
     ${introStep === 2 ? "max-lg:mt-[0px] max-sm:mt-0" : "max-lg:mt-[0px]"}`}>
      {introStep === 0 ? (
        <WelcomeStep contStyles={stepContStyles} onGoClick={onWelcomeBtnClick} />
      ) : introStep === 1 && showFeedback ? (
        <GetFeedback contStyles={stepContStyles} goNextStep={onFeedbackBtnClick} />
      ) : <>
        <YesOrNoQuestion onStepChange={onStepChange} step={step} contStyles={stepContStyles} />
      </>}
    </div >
  );
};

export default Introduction;
