import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";
import { ReactComponent as Dropshipper } from "assets/icons/dropshipper.svg";
import { ReactComponent as PrintOnDemand } from "assets/icons/print-on-demand.svg";
import { ReactComponent as SingleStore } from "assets/icons/single-products.svg";
import { ReactComponent as Instagram } from "assets/icons/social/instagram.svg";
import { ReactComponent as Meta } from "assets/icons/social/meta.svg";
import { ReactComponent as Pinterest } from "assets/icons/social/pinterest.svg";
import { ReactComponent as SnapchatIcon } from "assets/icons/social/snapchat.svg";
import { ReactComponent as Tiktok } from "assets/icons/social/tiktok.svg";
import { ReactComponent as Twitter } from "assets/icons/social/twitter.svg";
import { ReactComponent as Youtube } from "assets/icons/social/youtube.svg";

import { ColumnDef } from "@tanstack/react-table";
import HoveredIcon from "components/HoveredIcon";
import ProductNameCell from "components/ProductNameCell";
import Tooltip from "components/Tooltip";
import dayjs from "dayjs";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { PATH } from "routes/path";
import { StoreFinderList } from "types/services/storeFinder";
import { newCutLongTitle } from "utils/cutLongTitle";
import { getCountryFlag } from "utils/getCountryFlag";
import { makeQueryParams } from "utils/makeQueryParams";
const linkClassName = "bg-neutral2 dark:bg-deepSpace900 text-xs px-[9px] py-[5px] rounded-[5px]"


const getSocialByName = (name: string) => {
    let channel: ReactNode;
    const storeName = name.toLowerCase()
    storeName === "youtube" ?
        channel = <Youtube className="w-[15px] h-[11px] fill-[#F61C0D]" />
        :
        storeName === "instagram" ?
            channel = <Instagram className="w-[13px] h-[13px]" />
            :
            storeName === "twitter" ?
                channel = <Twitter className="w-[12px] h-[11px] fill-deepSpace900" />
                :
                storeName === "facebook" ?
                    channel = <Meta className="w-[16p.6x] h-[11px]" />
                    :
                    storeName === "tiktok" ?
                        channel = <Tiktok className="w-[12px] h-[13.63px]" />
                        :
                        storeName === "pinterest" ?
                            channel = <Pinterest className="w-[13px] h-[13px] fill-[#CB2027]" />
                            :
                            storeName === "snapchat" ?
                                channel = <SnapchatIcon className="w-[13px] h-[13px] fill-[#FFFC00]" />
                                : <></>

    return channel;
}

const getStoreType = (storeType: number) => {
    let st: ReactNode;
    storeType === 1 ?
        st = <div className="w-[20px] h-[20px] flex items-center justify-center"><Dropshipper className="w-[15px] h-[15px]" /></div>
        :
        storeType === 2 ?
            st = <div className="w-[20px] h-[20px] flex items-center justify-center"><PrintOnDemand className="w-[15px] h-[15px]" /></div>
            :
            storeType === 3 ?
                st = <div className="w-[20px] h-[20px] flex items-center justify-center"><SingleStore className="w-[15px] h-[15px]" /></div>
                :
                <></>

    return st;
}

export const storeFinderCols = (handleSort: (column: string) => void): ColumnDef<StoreFinderList>[] => ([
    {
        header: "Store",
        accessorKey: "store",
        minSize: 400,
        maxSize: 400,
        enableSorting: false,
        cell: ({ row, row: { original } }) => (
            <div className="flex justify-between items-center w-full">
                <div className="flex items-center gap-2.5 ml-[13px]">
                    <div className="w-[5rem]">
                        <ProductNameCell
                            img={original.favIcon}
                            imgWrapperClassName="ml-0"
                            hoveredImgWidth={800}
                            responsiveImageWidth={35}
                            adjustFirstandLastItemsAuto
                            height="59px"
                            width="75px"
                            maxHeight="max-h-[59px]" />
                    </div>
                    <div className="flex flex-col gap-2.5">
                        <div className="flex items-center gap-2.5">

                            {
                                <Link to={makeQueryParams(`${PATH.shopify.salesSpy}/${original.similarwebDomainsId}`, { storeDomain: original.domain, redirected: true })} target="_blank" data-tooltip-id={original.storeName + "title" + row.index} className={linkClassName}>{newCutLongTitle(original.storeName || "-", 20)}</Link>}

                        </div>
                        <a data-tooltip-id={original.domain + row.index} href={original.domain.includes("https://") ? original.domain : `https://${original.domain}`} target="_blank" rel="noreferrer" className="text-electricBlue underline dark:text-lilac400">{newCutLongTitle(original.domain.replace("https://", "") || "", 30)}</a>
                        {original.storeName?.length > 28 ? <Tooltip title={original.storeName} anchorId={original.storeName + "title" + row.index} /> : ""}
                        {original.domain?.length > 31 ? <Tooltip title={original.domain.replace("https://", "")} anchorId={original.domain + row.index} /> : ""}
                    </div>
                </div>
                <div className="flex items-center justify-center gap-[8px]">
                    <img src={getCountryFlag(original.country || "")} className="w-4 h-4" alt="" />
                    {getStoreType(original.storeType)}
                </div>
            </div>

        ),
        meta: {
            align: "justify-start",

        }
    },
    {
        header: "Store Creation Date",
        minSize: 170,
        accessorKey: "storeCreationDate",
        cell: ({ row: { original } }) => (
            original.storeCreationDate === null ? "-" :
                dayjs(original.storeCreationDate).format('D MMM YYYY')

        ),
        meta: {
            align: "justify-start",
            customSort: () => handleSort("storeCreationDate"),


        }
    },
    {
        header: "Social Channels",
        minSize: 140,
        accessorKey: "socialChannels",
        enableSorting: false,
        cell: ({ row: { original } }) => (
            <>
                <div className="flex items-center justify-center gap-[5px] max-w-[82px] flex-wrap">
                    {original.socialChannels.length ?
                        original.socialChannels.map(channel =>
                        (<Link to={channel.url} target="_blank" key={channel.type} className="w-[24px] h-[24px] flex items-center justify-center">
                            {getSocialByName(channel.type)}
                        </Link>
                        ))
                        :
                        "-"}

                </div>
            </>

        ),
        meta: {

        }

    },
    {
        header: "Ads Channels",
        minSize: 130,
        accessorKey: "adsChannels",
        enableSorting: false,
        cell: ({ row: { original } }) => (
            <>

                <div className="flex items-center justify-center gap-[5px] max-w-[82px] flex-wrap">
                    {original.adsChannels.length ?
                        original.adsChannels.map(channel =>
                        (<div key={channel.adsChannel} className="w-[24px] h-[24px] flex items-center justify-center">
                            {channel.url ? <a href={channel.url} key={channel.adsChannel} target="_blank"> {getSocialByName(channel.adsChannel)}</a> : getSocialByName(channel.adsChannel)}

                        </div>
                        ))
                        :
                        "-"}
                </div>

            </>

        ),
        meta: {

        }

    },
    {
        header: "Active Products",
        minSize: 150,
        accessorKey: "activeProducts",
        cell: ({ row: { original } }) => (
            <>

                {original.activeProducts === null ? "-" : original.activeProducts.toLocaleString("en-Us")}

            </>

        ),
        meta: {
            align: "justify-start",
            customSort: () => handleSort("activeProducts"),

        }
    },
    {
        header: () => {
            return <>
                <div className="flex flex-col">
                    Sold Products <span className="text-[10px] leading-[12px] font-[500]">Est.</span>
                </div>

            </>
        },
        minSize: 150,
        accessorKey: "soldProducts",
        cell: ({ row: { original } }) => (
            <>

                <p>{original.soldProducts === null ? 0 : original.soldProducts.toLocaleString("en-Us")}</p>

            </>

        ),
        meta: {
            align: "justify-start",
            customSort: () => handleSort("soldProducts"),
            tooltipTitle: "Estimated Sold Products based on store activity.",
            tooltipPosition: "left"

        }
    },
    {
        header: () => {
            return <>
                <div className="flex flex-col">
                    Revenue <span className="text-[10px] leading-[12px] font-[500]">Est.</span>
                </div>

            </>
        },
        minSize: 120,
        accessorKey: "revenue",
        cell: ({ row: { original } }) => (
            <>
                <div data-tooltip-id={original.similarwebDomainsId + "r001"} className="flex flex-col item-center justify-center">{
                    original.currency === "USD" || original.currency === null ?
                        <p className="font-bold">${original.revenue === null ? 0 : original.revenue.toLocaleString('en-US')}</p>
                        :
                        <>
                            <p className="font-bold">${original.revenue === null ? 0 : original.revenue.toLocaleString('en-US')}</p>
                            <p className="text-grey300">{original.currency} {original.convertedRevenue === null ? 0 : original.convertedRevenue.toLocaleString('en-US')}</p>
                        </>
                }
                </div>
                <Tooltip anchorId={original.similarwebDomainsId + "r001"} title="Revenue generated in both USD and the local currency over your selected date range." />
            </>

        ),
        meta: {
            align: "justify-start",
            customSort: () => handleSort("revenue"),
            tooltipTitle: "Estimated Revenue based on store activity.",
            tooltipPosition: "left"

        }
    },
    {
        header: () => <CompetitorResearch className="fill-darkBlue w-[17.5px] h-[18.96px] dark:fill-grey100" />,
        accessorKey: "store",
        enableSorting: false,
        cell: ({ row: { original } }) => (
            <>
                <Link to={makeQueryParams(`${PATH.shopify.salesSpy}/${original.similarwebDomainsId}`, { storeDomain: original.domain, redirected: true })} target="_blank">
                    <HoveredIcon Icon={CompetitorResearch} iconClassName="w-[17.5px] h-[18.96px]" variant="outlined" width="w-[35px]" height="h-[35px]" />
                </Link>

            </>

        ),
        meta: {
            alignHead: "justify-center",
            className: "min-w-[80px]",
            tooltipTitle: "Scan Store",
            tooltipPosition: "left"
        }
    }

])

