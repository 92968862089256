import VideoEmbed from "components/VideoEmbed/Index";

interface Props {
  title: string;
  source: string;
}

const VideoCard = ({ title, source }: Props) => (
  <div className="bg-white rounded-10 overflow-hidden shadow-lg dark:bg-black900">
    <VideoEmbed source={source} />
    <div className="p-[15px] flex items-center h-[62px] leading-[1.2] text-xs font-medium dark:text-grey100">
      {title}
    </div>
  </div>
);

export default VideoCard;
