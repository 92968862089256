import { ColumnDef } from "@tanstack/react-table";
import Label from "components/Label";
import { LoadHistoryResult } from "types/services/productMangement";
import { formatDateTime } from "utils/formatTime";

export const uploadHistoryCols: ColumnDef<LoadHistoryResult>[] = [
  {
    header: "Lister",
    accessorKey: "lister",
  },
  {
    header: "Store",
    accessorKey: "store",
  },
  {
    header: "Products",
    accessorKey: "products",
  },
  {
    header: "Marketplace",
    accessorKey: "marketplace",
  },
  {
    header: "Supplier",
    accessorKey: "supplier",
  },
  {
    header: "Title Source",
    accessorKey: "titleSource",
  },
  {
    header: "Upload Date",
    accessorKey: "uploadDate",
    cell: (date) => formatDateTime(String(date.getValue())),
  },
  {
    header: "Status",
    accessorKey: "status",
    cell: (status) => (
      <Label
        bgColor={Boolean(status.getValue()) ? "success" : "errorOrange"}
        title={Boolean(status.getValue()) ? "Completed" : "In Progress"}
        maxWidth="max-w-[290px] w-full"
        className="mx-4 text-white"
      />
    ),
  },
];
