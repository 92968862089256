import { shopifyPlans } from "assets/data/plans/shopifyPlans";
import Card from "components/Card";
import CheckoutOrderSummary from "components/CheckoutOrderSummary/Index";
import CheckoutCouponInput from "components/Input/CheckoutCouponInput";
import ShopifyMarketplaceIcon from "components/MarketplaceIcon/Shopify";
import Toaster from "components/Toast";
import useDecodeToken from "hooks/useDecodeToken";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useGetDefaultCouponQuery, useLazyCheckUpgradeQuery } from "store/api/managePaymentApi";
import BillingDetails from "./components/BillingDetails";
import ShopifyBillingHeader from "./components/BillingHeader";


const ShopifyUpgrade = () => {
    const [checkUpgrade, { data, isFetching }] = useLazyCheckUpgradeQuery();
    const user = useDecodeToken();
    const [couponValidating, setCouponValidating] = useState(true);
    const findPlan = shopifyPlans.find((item) => item.type !== user.shopify_package_name);
    const selectedPlan = {
        type: findPlan?.type || "",
        planName: findPlan?.name || "",
        planPrice: findPlan?.price || 0,
        skip: false
    }
    const [coupon, setCoupon] = useState("");
    const { data: defaultCoupon, isFetching: couponLoading } =
        useGetDefaultCouponQuery(selectedPlan.type);

    const changeCouponValidation = (isLoading: boolean) => setCouponValidating(isLoading)
    const handleApplyCoupon = (newVal: string) => {
        setCoupon(newVal);
    };

    useEffect(() => {
        if (!couponLoading) {
            if (defaultCoupon?.message === "") {
                changeCouponValidation(false)
            }
        }
    }, [defaultCoupon])
    const handleRefetchUpgrade = () => {
        checkUpgrade({
            package: selectedPlan.type,
            coupon: coupon,
        })
            .unwrap()
            .then()
            .catch((error) => {
                toast.error(error.data.message);
            });

    }
    useEffect(() => {
        if (!couponLoading && !couponValidating) {
            handleRefetchUpgrade();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coupon, couponValidating]);

    return (
        <div>
            <ShopifyBillingHeader />
            <div className="flex gap-5 max-lg:flex-col">
                <Card className="w-full p-8 border-[1px] border-solid border-secondary flex flex-col gap-8 dark:text-grey100 dark:border-grey700">
                    <div className="flex items-center justify-between">
                        <h5 className="font-medium">Billing Details</h5>
                        <ShopifyMarketplaceIcon />
                    </div>
                    <BillingDetails selectedPlanType={selectedPlan.type} coupon={coupon} />
                    {!couponLoading && (
                        <CheckoutCouponInput
                            couponValidating={changeCouponValidation}
                            handleApplyCoupon={handleApplyCoupon}
                            selectedPlan={selectedPlan.type}
                            handleRefetchUpgrade={handleRefetchUpgrade}
                            preloadCoupon={defaultCoupon?.message}
                        />
                    )}
                </Card>
                <Card className="w-full dark:text-grey100">
                    <CheckoutOrderSummary
                        selectedPlan={selectedPlan}
                        isLoading={isFetching || couponLoading || couponValidating}
                        data={data}
                        isShopify
                        wrapperStyles="max-w-full w-full h-full"
                    />
                </Card>

            </div>
            <Toaster />
        </div>
    )
}

export default ShopifyUpgrade;