import { tutorialList } from "assets/data/academy/playList";
import { tutorialSidebarData } from "assets/data/academy/sidebarData";
import Header from "components/Header";
import HeaderTab from "components/Header/Tab";
import Search from "components/Search";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import Sidebar from "./components/Sidebar";
import TutorialList from "./components/TutorialList";

const Tutorials = () => {
  const navigate = useNavigate();
  const [tutorials, setTutorials] = useState(tutorialList);
  const [search, setSearch] = useState("");
  const { t } = useTranslation(['home']);

  const filterTutorials = (val: any) => {
    setSearch(val);
    const searchVal = val.toLowerCase();

    const filteredTutorials = tutorialList.map((list) => ({
      ...list,
      videos: list.videos.filter((video) =>
        video.title.toLowerCase().includes(searchVal)
      ),
    }));

    setTutorials(filteredTutorials);
  };

  return (
    <>
      <Header
        leftContent={
          <>
            <HeaderTab
              title={t('Academy')}
              isActive={false}
              handleClick={() => {
                navigate(PATH.academy);
              }}
              className="!min-w-[86px]"
              tooltipText="Learn more about ZIK and how to improve your business " id="Academy"
            />
            <HeaderTab
              title="Tutorials"
              isActive={true}
              handleClick={() => { }}
              tooltipText="Learn how to use ZIK tools"
              className="!min-w-[81px]"
              id="Tutorials"
            />
            <Search
              className="max-w-[480px] !gap-[7px] m-auto"
              handleChange={filterTutorials}
              search={search}
            />
          </>
        }
        leftContentClassName="gap-[2.9375rem] w-full"
      />

      <div className="flex gap-[42px] max-xl:gap-5">
        <Sidebar
          sidebarTitle={t('Helptitle')}
          sidebarData={tutorialSidebarData}
        />
        <TutorialList tutorials={tutorials} />
      </div>
    </>
  );
};

export default Tutorials;
