import Tooltip from "components/Tooltip";
import { TurboScanFiltersList } from "types/services/autopilot";

interface Props {
  filtersData?: TurboScanFiltersList[];
}

const ScanFilters = ({ filtersData }: Props) => {
  const filterContStyles =
    "px-2.5 py-1.5 bg-secondary rounded-10 min-w-[57px] max-w-[118px] flex justify-center items-center dark:text-grey100 dark:bg-deepSpace900";

  return (
    <>
      {filtersData?.map(({ filterName, filterValue }, i) => (
        filterName.toLowerCase() === "roi" ? "" :
          <div
            key={i}
            className={`flex flex-col items-center px-2 text-sm ${i + 1 !== filtersData.length && `border-r-2 border-secondary dark:border-deepSpace900`
              }`}
          >
            <div className="mb-2.5 text-center font-medium dark:text-grey100 capitalize">{filterName.toLowerCase() === "profit" ? `${filterName} %` : filterName}</div>
            <div className="flex flex-col items-stretch gap-[3px]">
              {filterName.toLowerCase() === "categories" ? (
                <div
                  className={filterContStyles}
                  key={i}
                  data-tooltip-id={filterValue.join("")}
                >
                  <div className="text-center leading-[1.3] overflow-hidden whitespace-nowrap text-ellipsis">
                    {filterValue.join(", ")}
                  </div>
                  <Tooltip
                    anchorId={filterValue.join("")}
                    title={filterValue.join(", ")}
                    maxWidth="max-w-[600px]"
                  />
                </div>
              ) : (
                filterValue.map((filterVal, i) => (
                  <div className={filterContStyles} key={i}>
                    <div className="text-center leading-[1.3]">
                      {filterVal === "" ? "-" : filterVal}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
      ))}
    </>
  );
};

export default ScanFilters;
