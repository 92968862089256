import { ReactComponent as Clear } from "assets/icons/clear-input.svg";
import { ReactComponent as TogglePassword } from "assets/icons/password-view.svg";
import { ReactComponent as PasswordVisible } from "assets/icons/password-visible.svg";
import useHover from "hooks/useHover";
import { HTMLInputTypeAttribute, useState } from "react";
import { FieldErrors, Path, UseFormGetValues, UseFormRegister } from "react-hook-form";

export type Props<T extends object> = {
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  name: Path<T>;
  variant?: "outlined" | "contained";
  autoComplete?: string;
  togglePassword?: boolean;
  inputClassName?: string;
  errorClassName?: string;
  focusedClassName?: string;
  register: UseFormRegister<T>;
  errors: FieldErrors<T> | null;
  defaultValue?: string | null;
  readOnly?: boolean;
  fontSize?: string
  dataBluesnap?: string;
  isInvalid?: boolean;
  erase?: boolean;
  clearInputValue?: () => void;
  handleChange?: () => void
  getValues?: UseFormGetValues<T>;
  maxLength?: number;
};

const outlinedInput = (hasError: boolean, inputFocused: boolean, disabled: boolean, isTyped: boolean = false) => {
  return (`pb-[11px] after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-full after:h-[3px] after:rounded-full relative
${hasError ? "after:bg-errorRed" : disabled ? "after:bg-secondary" : inputFocused ? "after:bg-primaryPurple dark:after:bg-purple500" : `hover:after:bg-primaryPurple dark:hover:after:bg-purple500 ${isTyped ? "after:bg-darkBlue dark:after:bg-grey100" : "after:bg-secondary dark:after:bg-grey100"}`}
`)
}
const containedInput = (hasError: boolean, inputFocused: boolean, disabled: boolean) => `border-2 ${hasError ? "border-errorRed" : disabled ? "border-neutral3 cursor-default" : inputFocused ? "border-primaryPurple dark:border-purple500" : "border-secondary dark:border-grey100 hover:border-primaryPurple dark:hover:border-purple500"}`

const ControlledInput = <T extends object>({
  placeholder,
  disabled,
  type,
  name,
  variant = "outlined",
  className,
  autoComplete,
  togglePassword,
  dataBluesnap,
  inputClassName,
  errorClassName,
  focusedClassName,
  register,
  errors,
  fontSize,
  defaultValue,
  readOnly,
  isInvalid,
  erase,
  clearInputValue,
  getValues,
  handleChange,
  maxLength
}: Props<T>) => {
  const [inputType, setInputType] = useState(type);
  const [inputRef, inputFocused] = useHover<HTMLInputElement>("focus");
  const isOutlined = variant === "outlined";
  const inputValue = String(getValues && getValues(name) !== undefined ? getValues(name) : "");
  const hasError = (errors && errors[name]?.message) || isInvalid
  const labelStyles = `flex items-center hover:text-primaryPurple dark:hover:text-purple500 ${className} 
  ${inputFocused ? focusedClassName : ""} 
  ${isOutlined ? outlinedInput(Boolean(hasError), inputFocused, disabled || readOnly || false, inputValue.length > 0) : containedInput(Boolean(hasError), inputFocused, disabled || readOnly || false)}
  `;
  const inputStyles = `w-full focus:outline-none  bg-transparent focus:bg-transparent font-normal  placeholder:font-normal placeholder:text-neutral3 dark:text-grey100 focus:dark:text-purple500
    ${readOnly && "cursor-default text-darkBlue"}
    ${fontSize || "text-sm"} 
    ${inputClassName || ""}
    ${isInvalid ? "text-errorRed" : ""}
  `;

  return (
    <div className="w-full">
      <label htmlFor={name} className={labelStyles} ref={register(name).ref}>
        <input
          autoComplete={autoComplete || "off"}
          type={inputType}
          id={name}
          data-bluesnap={dataBluesnap}
          disabled={disabled}
          maxLength={maxLength}
          placeholder={placeholder || ""}
          className={inputStyles}
          {...register(name)}
          onChange={(e) => { register(name).onChange(e); if (handleChange) { handleChange() } }}
          ref={(el) => {
            register(name).ref(el);
            inputRef.current = el;
          }}
          value={defaultValue ?? undefined}
          readOnly={readOnly}
        />

        {togglePassword && (
          <div
            className="cursor-pointer"
            onClick={() =>
              setInputType(inputType === "password" ? "text" : "password")
            }
          >
            {inputType === "password" ? (
              <TogglePassword className="fill-neutral3 dark:fill-grey100 w-6 h-4" />
            ) : (
              <PasswordVisible className="fill-neutral3 dark:fill-grey100 w-6 h-4" />
            )}
          </div>
        )}

        {erase && (inputValue !== "" && inputValue !== "undefined") ? (
          <button
            onClick={clearInputValue}
            type="button"
            className="w-6 h-6 flex items-center justify-center cursor-pointer"
          >

            <Clear
              className={`fill-darkBlue w-[12px] h-[12px] hover:fill-primaryPurple dark:hover:fill-purple500 dark:fill-grey100`}
            />
          </button>
        )
          :
          ("")}
      </label>
      {errors && errors[name]?.message && (
        <p className={`text-errorRed text-xs mt-2 ${errorClassName || ""}`}>
          {errors && errors[name]?.message?.toString()}
        </p>
      )}
    </div>
  );
};

export default ControlledInput;
