import Button from "components/Button";
import Card from "components/Card";
import { ReactNode } from "react";

import Checkbox from "components/Input/Checkbox";
import SelectFolder from "components/Modal/SelectFolder";
import Pagination from "components/Table/Pagination";
import Tooltip from "components/Tooltip";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useMoveToFolderMutation, useSaveAllItemsMutation } from "store/api/bulkScannerApi";
import { Option, UsePaginationResponse } from "types/common";
import { PostBulkScannerItemsListReq } from "types/services/bulkScanner";

interface Props {
  children: ReactNode;
  selectedRows: {
    ebayTitle: string;
    ebayPrice: string | number;
    ebayItemId: string;
    amazonTitle: string;
    amazonPrice: string | number;
    amazonImageUrl: string;
    asin: string;
    profit: string | number;
    sales: number;
  }[];
  payload: PostBulkScannerItemsListReq;
  unSelectAll: () => void;
  pagination: UsePaginationResponse;
  selectedFolder: Option | null;
  onFolderSelect: (opt: Option | null) => void;
  isAllChecked: boolean;
  onAllCheckedChange: () => void;
}

const ItemList = ({
  children,
  pagination,
  selectedFolder,
  selectedRows,
  unSelectAll,
  payload,
  onFolderSelect,
  isAllChecked,
  onAllCheckedChange
}: Props) => {
  const { id } = useParams();
  const [searchParams] = useSearchParams()
  const [saveItem, { isLoading }] = useMoveToFolderMutation();
  const [saveAllMatched, { isLoading: saveAllLoading }] = useSaveAllItemsMutation()

  const onMoveClick = async () => {
    if (selectedFolder?.value === "" ||
      selectedFolder === null) {
      toast.warning(
        "Please Select Folder"
      );
    } else {
      if (isAllChecked) {
        await saveAllMatched({
          competitorId: id || "",
          listId: Number(selectedFolder?.value),
          range: payload.saleRange,
          dateFrom: payload.dateFrom,
          dateTo: payload.dateTo,
          minPrice: payload.minCurrentPrice,
          maxPrice: payload.maxCurrentPrice,
          minProfit: payload.minProfit,
          maxProfit: payload.maxProfit,
          minSales: payload.minSalePrice,
          maxSales: payload.maxSalePrice,
          itemType: searchParams.get("tab") === "similar" ? "similar" : "matched"
        }).unwrap().then(() => {
          toast.success("All items moved to folder successfully");
          unSelectAll();
        })
      } else {
        if (
          selectedRows.length === 0) {
          toast.warning(
            selectedRows.length === 0
              ? "No items have been selected for upload!"
              : "Please Select Folder"
          );
        } else {
          await saveItem({
            sendBulkItems: selectedRows,
            listid: selectedFolder?.value,
            requestId: id || "",
            userID: 0,
          }).unwrap().then(() => {
            toast.success("Selected items moved to folder successfully");
            unSelectAll();
          });
        }
      }
    }
  };

  return (
    <Card className="p-4 overflow-x-auto scrollbar">
      <div className="flex max-md:flex-col max-md:gap-4 items-end justify-between mb-5 mx-4">
        <div className="flex items-end max-sm:flex-col max-sm:gap-4 gap-10">
          <div className="mb-1.5 ml-1.5" data-tooltip-id="check-all">
            <Checkbox checked={isAllChecked} handleChange={onAllCheckedChange} value=""
              checkboxClassName="dark:!border-grey100 dark:!bg-deepSpace900 dark:checked:!bg-purple500"
              className="dark:fill-white"
            />
            <Tooltip title="Check all items" anchorId="check-all" />
          </div>
          <SelectFolder
            type="eBay"
            selectFirstOption
            selectedFolder={selectedFolder}
            onFolderSelect={onFolderSelect}
            hideFolderIcon
            cutLongOption={14}
            cutTitle={14}
            isOpen={true}
          />
          <Button
            title="Save Products"
            color="primaryPurple"
            height="h-[34px]"
            className="min-w-[136px]"
            loading={isLoading || saveAllLoading}
            handleClick={onMoveClick}
          />
        </div>
        <Pagination pagination={pagination} />
      </div>
      {children}
      <Pagination pagination={pagination} labeled />
    </Card>
  );
};

export default ItemList;
