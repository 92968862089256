import SadEmoji from "assets/images/sad-emoji.png";
import Button from "components/Button";
import ControlledInput from "components/Input/ControlledInput";
import useDecodeToken from "hooks/useDecodeToken";
import { FC } from "react";
import { FieldErrors } from "react-hook-form";
import { getPlanName } from "utils/planUtils";

interface FormValues {
    email: string
}interface Props {
    onUpgrade: () => void;
    isLoading: boolean;
    register: any;
    renewalDate?: string;
    isShopify?: boolean;
    errors: FieldErrors<FormValues>;
    handleSubmit: () => void;
}

const CancelationEnterEmail: FC<Props> = ({ onUpgrade, errors, isLoading, register, renewalDate, handleSubmit, isShopify }) => {
    const user = useDecodeToken();
    const currentPlan = user.package_name || "";
    const currentShopifyPlan = user.shopify_package_name || "";

    return (
        <div className="flex flex-col items-center w-full" >
            <img src={SadEmoji} alt="" className="text-center mb-[29px] w-10 h-10 -mt-[19px]" />
            <h4 className="text-xl font-semibold mb-2.5">We’re sad to see you go...</h4>
            <div className="font-medium text-lg text-center mb-4">
                <p className=" mb-[29px]">
                    You are canceling your {isShopify ? `Shopify ${currentShopifyPlan === "ShopProM" ? "Monthly" : "Yearly"}` : `eBay ${getPlanName(currentPlan)}`} plan. You can still use it until {renewalDate}.
                </p>
                <p>In order to confirm the cancelation, please enter the email address
                    <br />  associated with your account
                </p>
            </div>

            <form
                className="w-full flex flex-col items-center gap-12 mx-auto pb-[18px]"
                onSubmit={handleSubmit}>
                <div className="max-w-[407px] w-full">
                    <ControlledInput register={register} errors={errors} name="email" className="pb-[0px] h-[55px] !text-sm  w-full" placeholder="Enter your Email" />
                </div>
                <div className="flex gap-[35px] w-full max-w-[535px]">
                    <div className="rounded-full w-full" style={{ boxShadow: "0px 0px 1.02411px 0px #8FFFF2, 0px 0px 2.04821px 0px #8FFFF2, 0px 0px 7.16874px 0px #8FFFF2, 0px 0px 14.33749px 0px #8FFFF2, 0px 0px 24.57855px 0px #8FFFF2, 0px 0px 43.01246px 0px #8FFFF2" }}>
                        <Button
                            title="I changed my mind"
                            height="h-16"
                            className="border-smoothGreen hover:bg-smoothGreen hover:border-smoothGreen dark:border-paradiseBlue dark:hover:bg-paradiseBlue dark:hover:border-paradiseBlue"
                            titleClassName="!text-base font-medium"
                            type="button"
                            handleClick={onUpgrade}
                        />
                    </div>
                    <Button title="Yes, Cancel" height="h-16" className="border-neutral3 text-neutral3" titleClassName="!text-base font-medium" loading={isLoading} handleClick={handleSubmit} />
                </div>
            </form>

        </div>
    )
}

export default CancelationEnterEmail