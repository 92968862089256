import { ReactComponent as Language } from "assets/icons/language.svg";
import HoveredIcon from "components/HoveredIcon";
import ChooseLanguage from "components/Modal/ChooseLanguage";
import useModal from "hooks/useModal";
import useOutsideClick from "hooks/useOutsideClick";

const HeaderLanguage = () => {
  const modal = useModal();
  const [ref] = useOutsideClick<HTMLDivElement>(modal.close);

  return (
    <div className="relative" ref={ref}>
      <HoveredIcon
        Icon={Language}
        handleClick={modal.isOpen ? modal.close : modal.open}
        className={modal.isOpen ? "!border-primaryPurple dark:!border-purple500" : ""}
        iconColor={modal.isOpen ? "fill-primaryPurple dark:fill-purple500" : "dark:fill-grey100 fill-darkBlue"}
        iconClassName="w-[21px] h-[21px]"
        width="w-9"
        height="h-9"
      />
      <ChooseLanguage helpers={modal} />
    </div>
  );
};

export default HeaderLanguage;
