import Card from "components/Card";
import Search from "components/Search";

import { aliGrowthCols } from "assets/tableColumns/aliGrowth";
import AnalyzeAliexpress from "components/AnalyzeAliexpress";
import ScrollToTop from "components/ScrollToTop";
import Table from "components/Table";
import { TableStyles } from "components/Table/Table";
import useModal from "hooks/useModal";
import useTable from "hooks/useTable";
import { FC, useState } from "react";
import { UseDebaunceSearchResponse } from "types/common";
import { AliGrowthList } from "types/services/zikPro";

interface Props {
  list: AliGrowthList[];
  isLoading: boolean;
  debauncedSearch: UseDebaunceSearchResponse;
}
const tableStyles: TableStyles = {
  px: "px-0",
  head: { contHeight: "h-[37px]", rowHeight: "h-[61px]" },
  body: { contHeight: "h-[37px]", rowHeight: "h-[88px]" },
  style: "overflow-x-auto"
};
const initialSelectedItem = {
  aliid: 0,
  storeName: "",
  aliexpresStoreID: 0,
  itemRating: 0,
  ePacket: false,
  dateRegistered: null,
  feedbackCount: 0,
  itemFeedback: 0,
  title: "",
  price: 0,
  mainImage: "",
  communication: null,
  itemAsDescribed: 0,
  shippingSpeed: 0,
  selectedColumnSales: 0,
  revenueTotal: 0,
  countryName: "",
  itemUrl: "",
  slope: 0,
  currency: "$",
  totalRows: 500,
};

const AliGrowthScannerList: FC<Props> = ({
  list,
  isLoading,
  debauncedSearch,
}) => {
  const { immediateValue, onChange } = debauncedSearch;
  const [selectedItem, setSelectedItem] =
    useState<AliGrowthList>(initialSelectedItem);
  const [rowSelection, setRowSelection] = useState({});
  const modal = useModal();

  const handleAnalyzeClick = (item: AliGrowthList) => {
    setSelectedItem(item);
    modal.open();
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getRowId = (row: any, relativeIndex: any, parent: any) =>
    parent ? [parent.id, row.aliid].join(".") : row.aliid

  const { table } = useTable({
    data: list,
    dataDeps: [list],
    enableRowSelection: true,
    rowSelection,
    sort: true,
    onRowSelectionChange: setRowSelection,
    columns: aliGrowthCols(handleAnalyzeClick),
    getRowId: getRowId
  });

  return (
    <Card className="px-4 py-6 w-full flex-1 overflow-x-hidden" shadow={false}>
      <div className="flex justify-end mb-5 gap-4 max-lg:flex-col">
        <Search
          search={immediateValue}
          handleChange={onChange}
          maxWidth="18.8125rem"
          size="sm"
          htmlFor="table-search"
        />
      </div>
      <div className="mb-5">
        <Table table={table} isLoading={isLoading} styles={tableStyles} />
      </div>

      <ScrollToTop scrollTo={265} hideUntill={400} />
      <AnalyzeAliexpress
        helpers={modal}
        itemID={selectedItem.aliid.toString()}
        storeName={selectedItem.storeName}
        countryName={selectedItem.countryName}
        feedbackCount={selectedItem.feedbackCount}
        dateRegistered={selectedItem.dateRegistered}
        communication={selectedItem.communication}
        itemAsDescribed={selectedItem.itemAsDescribed}
        revenueTotal={selectedItem.revenueTotal}
        title={selectedItem.title}
        price={selectedItem.price}
        feedback={selectedItem.itemFeedback}
        totalSales={selectedItem.selectedColumnSales}
        rating={selectedItem.itemRating}
        img={selectedItem.mainImage}
        itemUrl={selectedItem.itemUrl}
        isAliGrowth={true}
      />
    </Card>
  );
};

export default AliGrowthScannerList;
