export const sidebarData = [
  {
    title: "Help Videos",
    buttons: [
      {
        name: "Guides",
        source: "guides",
        type: "video",
      },
      {
        name: "Product research training for eBay sellers that sell their own stock",
        source: "prStock",
        type: "video",
      },
      {
        name: "Product research training for eBay dropshippers",
        source: "prDropshipper",
        type: "video",
      },
      {
        name: "Listing Optimization Training",
        source: "listingOptimisation",
        type: "video",
      },
      {
        name: "Webinars",
        source: "webinars",
        type: "video",
      },
      {
        name: "Tips and Tricks",
        source: "tips",
        type: "video",
      },
    ],
  },
  {
    title: "Courses",
    buttons: [
      {
        name: "Dropshipping Product Research",
        source: "dropshippingCourse",
        type: "course",
      },
      {
        name: "Sellers Product Research Tools",
        source: "sellerCourse",
        type: "course",
      },
    ],
  },
];

export const tutorialSidebarData = [
  {
    name: "Market insights dashboard",
    id: "01",
    translation: "Market insights dashboard",
  },
  {
    name: "Competitor Research",
    id: "02",
    translation: "CompetitorResearch",
  },
  {
    name: "Product Research",
    id: "03",
    translation: "ProductResearch",
  },
  {
    name: "Category Research",
    id: "04",
    translation: "CategoryResearch",
  },
  {
    name: "Store Analytics",
    id: "05",
    translation: "StoreAnalytics",
  },
  {
    name: "Manual Scanner / Item Finder",
    id: "06",
    translation: "ManualScanner",
  },
  {
    name: "Advanced Tools",
    id: "07",
    translation: "Advanced Tools",
  },
  {
    name: "Settings & Billing",
    id: "08",
    translation: "SettingsBilling",
  },
  {
    name: "Others",
    id: "09",
    translation: "Others",
  },
  {
    name: "Product Research Integrations",
    id: "10",
    translation: "ProductResearchIntegrations",
  },
  {
    name: "Market Insights",
    id: "11",
    translation: "Market Insights",
    isShopify: true,
  },
  {
    name: "Sales Tracker",
    id: "12",
    translation: "Sales Tracker",
    isShopify: true,
  },
  {
    name: "StoreFinder",
    id: "13",
    translation: "Store Finder",
    isShopify: true,
  },
  {
    name: "Product Explorer",
    id: "14",
    translation: "Product Explorer",
    isShopify: true,
  },
];
