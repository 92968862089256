export const languageList = {
  US: {
    name: "United States",
    code: "US",
    language:"en"
  },
  DE: {
    name: "Germany",
    code: "DE",
    language:"de"
  },
  ES: {
    name: "Spain",
    code: "ES",
    language:"es"
  },
  FR: {
    name: "France",
    code: "FR",
    language:"fr"
  },

  IT: {
    name: "Italy",
    code: "IT",
    language:"it"
  },
  CH: {
    name: "China",
    code: "CN",
    language:"cn"
  },
};
