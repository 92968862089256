import { nichesLocations } from "assets/data/filters";
import Card from "components/Card";
import InfoTooltip from "components/Card/InfoTooltip";
import LanguageSelect from "components/LanguageSelect";
import { FC, ReactNode } from "react";
import { SHIPPED_MODEL, SOURCE_MARKET_PLACES } from "types/sellerSettings";

interface Props {
  infoTitle?: string;
  title?: string;
  subtitle?: string;
  className?: string;
  headerClassName?: string;
  titleClassName?: string;
  titleFontWeight?: string;
  tooltipTop?: string;
  tooltipRight?: string;
  showCountryDropdown?: boolean;
  dropdownSelectedValue?: string | null;
  handleDropdownChange?: (value: SOURCE_MARKET_PLACES | SHIPPED_MODEL | null | string) => void;
  children: ReactNode;
}
const MainDashboardCard: FC<Props> = ({
  infoTitle,
  title,
  subtitle,
  className,
  headerClassName,
  titleClassName,
  titleFontWeight,
  showCountryDropdown,
  dropdownSelectedValue,
  handleDropdownChange,
  children,
}) => (
  <Card className={`relative w-full ${className || ""}`}>

    {(title || subtitle) && (
      <div className={`flex gap-[3px] justify-center ${headerClassName}`}>
        <div className="flex gap-3.5 px-2.5">
          <div className="text-center">
            {title && (
              <h6
                className={`flex items-center gap-2.5 dark:text-grey100 text-sm ${titleFontWeight || "font-bold "
                  } ${titleClassName}`}
              >
                {title}
                {infoTitle && !subtitle && (
                  <InfoTooltip title={infoTitle} />
                )}
              </h6>
            )}
            {subtitle &&
              <p className={`mt-0.5 flex justify-center items-center gap-2.5 text-xs text-neutral3`}>
                {subtitle}
                {infoTitle && (
                  <InfoTooltip title={infoTitle} />
                )}
              </p>
            }
          </div>

          {showCountryDropdown &&
            <LanguageSelect
              options={nichesLocations}
              selectedValue={nichesLocations.find(x => x.value === (dropdownSelectedValue || "us"))?.value || "us"}
              handleCountryChange={handleDropdownChange}
            />
          }
        </div>
      </div>
    )}
    {children}
  </Card>
);

export default MainDashboardCard;
