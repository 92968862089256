interface Props {
    className?: string;
}

const index = ({ className }: Props) => {
    return (
        <div className={`w-full bg-secondary dark:bg-grey600 animate-[pulse_2s_infinite] rounded-[3px] ${className || ""}`}></div>
    )
}

export default index