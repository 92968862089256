import { ReactComponent as EbayLogo } from "assets/icons/ebay.svg";
import { ReactComponent as ShopifyLogo } from "assets/icons/shopify.svg";
import VideoCard from "pages/AcademyAndTutorials/Academy/components/VideoCard";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Scrollspy from 'react-scrollspy';
import { playList } from "types/academy";

interface Props {
  tutorials: playList[];
}

const TutorialList = ({ tutorials }: Props) => {
  const { t } = useTranslation(['home']);
  const ref = useRef<HTMLElement>(null)
  const navigate = useNavigate()
  const { search } = useLocation()
  useEffect(() => {
    const element = document.querySelector('#sect-03')

    if (search.includes("product-research")) {
      if (element) {
        element.scrollIntoView()
      }

    }
  }, [search])

  return (
    <Scrollspy items={tutorials.map((item => `sect-${item.id}`))} className="flex flex-col w-full gap-4" currentClassName="" onUpdate={(e) => navigate("#" + e.id)}>
      {tutorials.map((item) => (
        <section
          id={`sect-${item.id}`}
          key={item.id}
          ref={item.id === "03" ? ref : undefined}
          className={`${item.id === "10" ? "pb-[220px]" : ""}`}

        >

          <div className="observer-item px-[16px] py-3 bg-darkBlue text-white dark:bg-black900 dark:text-grey100 rounded-[15px] shadow-lg mb-5 font-semibold flex items-center gap-[24px]">
            <div className="px-[10px] h-[32px] rounded-full bg-neutral2 dark:bg-deepSpace900 flex items-center justify-center">{item.isShopify ? <ShopifyLogo className="w-[64px] h-[16px] text-black900 dark:text-white" /> : <EbayLogo className="w-[40px] h-[21px]" />}</div>
            <span>{t(item.playlistTitleTranslation)}</span>
          </div>


          <div className="grid grid-cols-3 gap-4 max-[1465px]:grid-cols-2 max-[1127px]:grid-cols-1">
            {item.videos.map((video) => (
              <VideoCard key={video.source} title={t(video.translation)} source={video.source} />
            ))}
          </div>
        </section>
      ))}
    </Scrollspy>
  );
};

export default TutorialList;
