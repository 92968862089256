import Modal from "components/Modal";
import Plans from "components/Plans/Index";
import PlansComparison from "components/Plans/PlansComparison";
import { useAppDispatch } from "hooks/useAppDispatch";
import useDecodeToken from "hooks/useDecodeToken";
import useModal from "hooks/useModal";
import usePlans from "hooks/usePlans";
import SubscriptionUpgrade from "pages/ManagementPanel/components/ManagePayments/SubscriptionUpgrade";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { onSelectPlan } from "store/slices/planSlice";
import { TSelectedPlan } from "types/plans";
import { getProToolsContent, getProToolsPlans } from "utils/proTools";
interface Props {
  currentPlan: string;
}

const ProTools = () => {
  const modal = useModal();
  const [searchParam] = useSearchParams();
  const content: {
    headingPlan?: string;
    title: string;
    description: string;
    video?: string;
    image?: string;
    bullets: string[];
  } = getProToolsContent(searchParam.get("tab") || "");
  const userDetails = useDecodeToken();
  const dispatch = useAppDispatch();
  const planType = getProToolsPlans(searchParam.get("tab") || "");
  const { plans, handlePlanChange } = usePlans(planType, true);

  const handleSelectPlan = (newVal: TSelectedPlan) => {
    dispatch(onSelectPlan(newVal));
  };

  const handleProToolUpgrade = () => {
    modal.open();
  };

  useEffect(() => {
    handlePlanChange(planType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planType]);

  return (
    <>
      <div className="pt-[9px]">
        <div className="max-w-[1024px] mx-auto flex justify-center">
          <div>
            <div className="text-center text-[16px] font-medium dark:text-grey100">Upgrade to ZIK's <span className="text-primaryPurple dark:text-purple500">{content.headingPlan} Plan</span></div>
            <div
              className="text-base text-center font-medium mt-[15px] dark:text-grey100"
              dangerouslySetInnerHTML={{ __html: content.title }}
            />

            <p className="text-center text-sm font-medium mb-2  dark:text-grey100">
              {content.description}
            </p>
            <div>
              {userDetails.active_trail !== "True" && <div className="text-center font-medium  dark:text-grey100">
                Upgrade now and we'll refund remaining credits from your current
                plan
              </div>}
            </div>
          </div>
        </div>

        <div className="flex justify-center mt-[43px]">
          <Plans
            plans={plans}
            handleSelectPlan={handleSelectPlan}
            handleProToolUpgrade={handleProToolUpgrade}
            buttonFilled
            alwaysUpgrade={true}
          />
        </div>

        <PlansComparison
          plans={plans}
          hideFooter
        />
      </div>

      <Modal
        className="max-w-[1300px] p-t-[30px]"
        maxWidth="900"
        helpers={modal}
        buttonCloseClassName="top-[12px]"
      >
        <SubscriptionUpgrade />
      </Modal>
    </>
  );
};

export default ProTools;
