import { createSlice } from "@reduxjs/toolkit";
import { crApi } from "store/api/crApi";
import {
  crAliData,
  crAliFilters,
  crEbayData,
  crEbayFilters,
} from "store/initialValues/cr";
import {
  PostCrAliRequest,
  PostCrAliResponse,
  PostCrEbayRequest,
  PostCrEbayResponse,
} from "types/services/cr";

export interface CrSortEbayPayload {
  column: string;
  dir: string;
}

export interface CrFilterChangePayload {
  marketplace: "aliFilters" | "ebayFilters";
  field: keyof typeof crAliFilters | keyof typeof crEbayFilters;
  newVal: string | number | boolean | null;
}

export const initialState = {
  aliFilters: crAliFilters as PostCrAliRequest,
  ebayFilters: crEbayFilters as PostCrEbayRequest,
  ebayData: { result: crEbayData, success: false } as PostCrEbayResponse,
  aliData: { result: crAliData, success: false } as PostCrAliResponse,
};

const crSlice = createSlice({
  name: "crSlice",
  initialState,
  reducers: {
    handleCrFilterChange: (
      state,
      { payload }: { payload: CrFilterChangePayload }
    ) => {
      // @ts-ignore
      state[payload.marketplace][payload.field] = payload.newVal;
    },
    handleResetCrFilters: (state) => {
      state.ebayFilters = crEbayFilters;
    },
    handleResetCrEbayData: (state) => {
      state.ebayData = {
        result: crEbayData,
        success: false,
      } as PostCrEbayResponse;
    },
    handleCrEbaySortChange: (
      state,
      { payload }: { payload: CrSortEbayPayload }
    ) => {
      state.ebayFilters = {
        ...state.ebayFilters,
        sortColumn: payload.column,
        sortColumnDirection: payload.dir,
      };
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      crApi.endpoints.crEbay.matchFulfilled,
      (state, { payload }) => {
        if (payload.result.getDetails) {
          state.ebayData = payload;
        } else {
          state.ebayData.result.recordsFiltered =
            payload.result.recordsFiltered;
          state.ebayData.result.data = payload.result.data;
        }

        return state;
      }
    );
    builder.addMatcher(
      crApi.endpoints.crAli.matchFulfilled,
      (state, { payload }) => {
        state.aliData = payload;

        return state;
      }
    );
  },
});

export const {
  handleCrFilterChange,
  handleResetCrFilters,
  handleCrEbaySortChange,
  handleResetCrEbayData,
} = crSlice.actions;

export default crSlice.reducer;
