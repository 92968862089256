
import { KLAVIYO_PUBLIC_API } from 'config/config';
import { useAppSelector } from 'hooks/useAppSelector';
import { DecodedUser } from 'hooks/useDecodeToken';
import jwt from "jwt-decode";
import React, { useEffect, useState } from 'react';
import { GetAccessToken } from 'utils/localStorage';

const TrackingComponent: React.FC = () => {
  const [userDetails, setUserDetails] = useState<DecodedUser | null>(null);
  const { accessToken } = useAppSelector((state) => state.user.result);

  const token = accessToken || GetAccessToken();
  useEffect(() => {
    if (token) {
      setUserDetails(jwt(token))
    }
  }, [token]);
  useEffect(() => {
    const trackingScript = document.createElement('script');
    trackingScript.type = 'text/javascript';
    trackingScript.src = KLAVIYO_PUBLIC_API;
    document.body.appendChild(trackingScript);

    if (userDetails) {
      if ((window as any).klaviyo) {
        (window as any).klaviyo.identify({
          '$email': userDetails.email,
        });
      }
    }

    return () => {
      document.body.removeChild(trackingScript);
    };
  }, [userDetails]);

  return (
    <></>
  );
}

export default TrackingComponent;
