const formatString = (title: string) =>
  (title || "")
    .toString()
    .replaceAll("+", " ")
    .replaceAll("%2c", " ")
    .replaceAll("%2f", " ")
    .replaceAll("%27s", " ")
    .replaceAll("%22", " ")
    .replaceAll("%09", " ")
    .replaceAll("%26", " ")
    .replaceAll("%5b", " ")
    .replaceAll("%5d", " ")
    .replaceAll("%27", " ")
    .replaceAll("%25", " ");

export default formatString;
