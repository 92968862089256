import InfoTooltip from "components/Card/InfoTooltip";
import useHover from "hooks/useHover";
import { FC, useEffect, useRef, useState } from "react";
import { NumericFormat } from "react-number-format";
import { toast } from "react-toastify";

interface Props {
    value: {
        min?: string | number | null;
        max?: string | number | null;
    };
    name: string;
    handleChange: (newValue: number | string | null, type: "min" | "max") => void;
    inputWrapperClassName?: string;
    inputWidth?: string;
    inputGap?: string;
    hideMax?: boolean;
    hideMin?: boolean;
    allowNegative?: boolean;
    className?: string;
    customInputWrapperStyles?: string;
    Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
    title?: string;
    infoTitle?: string;
    titleWrapperMaxWidth?: string;
    tooltipMaxWidth?: string;
    iconClassName?: string;
    isInvalid?: boolean
    maxLimit?: number;
    isFromTo?: boolean
    disabled?: boolean
    palceholder?: {
        min: string;
        max: string;
    }
    inputPrefix?: string;
    minInputLimit?: number
    maxInputLimit?: number
    inputClassName?: string;

}

const isMinGreaterThenMax = (min: string | number | undefined | null, max: string | number | undefined | null) => {
    if (min && max && min !== "" && max !== "") {
        if (Number(min) > Number(max)) {
            return true
        }
    }
    return false;
}

const MinMaxFilterAutoScale: FC<Props> = ({
    value,
    className,
    inputWidth,
    inputGap,
    name,
    allowNegative,
    inputWrapperClassName,
    hideMax,
    hideMin,
    customInputWrapperStyles,
    isFromTo,
    handleChange,
    Icon,
    title,
    infoTitle,
    tooltipMaxWidth,
    titleWrapperMaxWidth,
    iconClassName,
    isInvalid,
    maxLimit,
    disabled,
    palceholder,
    inputPrefix,
    minInputLimit,
    maxInputLimit,
    inputClassName

}) => {
    const htmlFor = (type: "min" | "max") => name + type;
    const [minFocusRef, minFocused] = useHover<HTMLInputElement>("focus");
    const [maxFocusRef, maxFocused] = useHover<HTMLInputElement>("focus");
    const [max, setMax] = useState(value.max != null && value.max !== "" ? Number(value.max) : "")
    const [min, setMin] = useState(value.min != null && value.min !== "" ? Number(value.min) : "")
    const minRef = useRef<HTMLSpanElement>(null);
    const maxRef = useRef<HTMLSpanElement>(null);
    const [minInputWidth, setMinInputWidth] = useState(25);
    const [maxInputWidth, setMaxInputWidth] = useState(25);
    const [error, setError] = useState(false);
    const inputWrapperStyles = (isFocused: boolean) => `flex flex-col items-center gap-1 px-[6.75px] relative after:content-[''] 
  after:absolute after:bottom-[17px] after:left-1/2 after:-translate-x-1/2 after:w-[calc(100%-16px)] after:h-[2px]
    ${disabled ? "after:bg-grey100 text-grey100" : isInvalid || error ? "after:bg-errorRed text-errorRed" :
            `${isFocused ?
                `after:bg-primaryPurple text-primaryPurple dark:after:bg-purple500 dark:text-purple500` :
                "after:bg-darkBlue text-darkBlue hover:text-primaryPurple dark:text-grey100 after:rounded-full dark:after:bg-grey100 dark:hover:text-purple500"} after:bg-darkBlue hover:after:bg-primaryPurple dark:hover:after:bg-purple500`} 
    ${customInputWrapperStyles || ""}`;
    const inputStyles = `outline-none text-center pt-2 ${inputWidth || "w-full"
        } bg-transparent min-w-[1px] ${inputClassName || ""}`;


    const checkIsMinGreaterThenMax = () => {
        if (isMinGreaterThenMax(value.min, value.max)) {
            handleChange("", "min");
            handleChange("", "max");
            setError(true);
            toast.warning(`Value min cannot be greater then value max for ${name} filter`);
        }
    }
    useEffect(() => {
        setMax(value.max != null && value.max !== "" ? Number(value.max) : "")
        setMaxInputWidth(maxRef?.current?.offsetWidth || 25)
    }, [value.max])
    useEffect(() => {
        setMin(value.min != null && value.min !== "" ? Number(value.min) : "")
        setMinInputWidth(minRef?.current?.offsetWidth || 25)
    }, [value.min])

    const regex = new RegExp(`[\\${inputPrefix?.split('').join('\\')}, ]+`, 'g');

    return (
        <div
            className={`flex flex-col items-center justify-center gap-2 w-full relative ${className}`}
        >
            {(Icon || title) && (
                <div
                    className={`flex gap-3 justify-between w-full items-center h-6 ${titleWrapperMaxWidth || "max-w-[160px]"
                        }`}
                >
                    {Icon && <Icon className={`${iconClassName || ""} dark:fill-grey100 fill-darkBlue`} />}
                    {title && <h4 className="text-sm font-bold dark:text-grey100">{title}</h4>}
                    {infoTitle && (
                        <InfoTooltip
                            title={infoTitle}
                            maxWidth={tooltipMaxWidth}
                            place="left"
                        />
                    )}
                </div>
            )}
            <div
                className={`flex items-center ${inputGap || "gap-5"} justify-center ${inputWrapperClassName || ""
                    }`}
            >
                {!hideMin && <div
                    className={inputWrapperStyles(minFocused)}
                >


                    <NumericFormat
                        value={min}
                        thousandSeparator={true}
                        allowNegative={true}
                        disabled={disabled}
                        prefix={inputPrefix}
                        onKeyDown={(e) => {
                            setError(false);
                            ["e", "E", "+", !allowNegative && "-"].includes(e.key) &&
                                e.preventDefault()
                        }
                        }
                        getInputRef={minFocusRef}
                        placeholder={palceholder?.min || "0"}
                        className={inputStyles}
                        id={htmlFor("min")}
                        onChange={(e) => handleChange(e.target.value ? Number(e.target.value.replace(regex, "")) : "", "min")
                            // here since we have commas now in number and prefix sign like $ , we need to replace "," and prefix with empty string in handle change so we dont get NaN error

                        }
                        isAllowed={(values) => {
                            const { floatValue } = values;
                            return minInputLimit && floatValue ? floatValue <= minInputLimit : true;
                        }}
                        onBlur={checkIsMinGreaterThenMax}
                    />

                    <label
                        className="text-xs"
                        htmlFor={htmlFor("min")}
                    >
                        {isFromTo ? "from" : "min"}
                    </label>
                </div>
                }
                {!hideMax && (
                    <div
                        className={inputWrapperStyles(maxFocused)}
                    >
                        <NumericFormat
                            value={max}
                            thousandSeparator={true}
                            allowNegative={true}
                            disabled={disabled}
                            prefix={inputPrefix}
                            onKeyDown={(e) => {
                                setError(false);
                                ["e", "E", "+", !allowNegative && "-"].includes(e.key) &&
                                    e.preventDefault()
                            }
                            }
                            getInputRef={maxFocusRef}
                            placeholder={palceholder?.max || "0"}
                            className={inputStyles}
                            id={htmlFor("max")}
                            onChange={(e) => handleChange(e.target.value ? Number(e.target.value.replace(regex, "")) : "", "max")
                                // here since we have commas now in number and prefix sign like $ , we need to replace "," and prefix with empty string in handle change so we dont get NaN error
                            }
                            isAllowed={(values) => {
                                const { floatValue } = values;
                                return maxInputLimit && floatValue ? floatValue <= maxInputLimit : true;
                            }}
                            onBlur={checkIsMinGreaterThenMax}
                        />

                        <label
                            className="text-xs"
                            htmlFor={htmlFor("max")}
                        >
                            {isFromTo ? "to" : "max"}
                        </label>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MinMaxFilterAutoScale;
