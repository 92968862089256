import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import { HTMLProps, useEffect, useRef } from "react";

const TableCheckbox = ({
  indeterminate,
  className = "",
  wrapperClassName,
  ...rest
}: {
  indeterminate?: boolean;
  wrapperClassName?: string;
} & HTMLProps<HTMLInputElement>) => {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, indeterminate]);

  return (
    <div className={`relative h-6 cursor-pointer ${wrapperClassName || ""}`}>
      {rest.checked ? (
        <Checkmark
          className={`fill-white z-[1] absolute top-[7px] left-1/2 -translate-x-1/2 w-3 h-[9px] pointer-events-none ${className || ""
            }`}
        />
      ) : (
        ""
      )}
      <input
        type="checkbox"
        ref={ref}
        {...rest}
        className={`border-2 border-darkBlue cursor-pointer checked:bg-primaryPurple bg-white dark:bg-deepSpace900 dark:border-grey100 dark:hover:border-purple500 dark:checked:bg-purple500 dark:hover:checked:border-lilac400 dark:checked:border-lilac400 relative appearance-none 
        hover:border-primaryPurple rounded-full w-6 h-6`}
      />
    </div>
  );
};

export default TableCheckbox;
