import { ReactComponent as Checkmark } from "assets/images/img_checkmark.svg";
import React from "react";

type ModalFunnelStartWithZikCheckmarkProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  Partial<{
    checkmarkStyles: string;
  }>;

const ModalFunnelStartWithZikCheckmark: React.FC<
  ModalFunnelStartWithZikCheckmarkProps
> = (props) => (
  <>
    <Checkmark className={props.checkmarkStyles} />
  </>
);

ModalFunnelStartWithZikCheckmark.defaultProps = {};

export default ModalFunnelStartWithZikCheckmark;
