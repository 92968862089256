import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as LeftArrow } from "assets/icons/arrow-left-extended.svg";
import { ReactComponent as Close } from "assets/icons/clear-input.svg";
import { ReactComponent as Ebay } from "assets/icons/ebay.svg";
import { ReactComponent as Shopify } from "assets/icons/shopify.svg";
import Button from "components/Button";
import Modal from "components/Modal";
import { useAppDispatch } from "hooks/useAppDispatch";
import useModal from "hooks/useModal";
import { FC, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { managePaymentsApi, useCancelSubscriptionMutation, useGetUserPlanQuery } from "store/api/managePaymentApi";
import { queryTags } from "store/api/queryTags";
import { formatDate } from "utils/formatTime";
import { object, string } from "yup";
import CancelSuccess from "./CancelSuccess";
import CancelationEnterEmail from "./EnterEmail";
import GetFeedback from "./GetFeedback";
import CancellationQuestionnaire, { cancelationQuestionnaireOptions } from "./Questionnaire";
import SaveUptoHalf from "./SaveUptoHalf";


interface FormValues {
    email: string
}
let schema = object({
    email: string().email("Please provide valid email").required("Please provide your email!"),
});


interface Props {
    helpers: ReturnType<useModal>;
    onUpgrade: () => void;
    isShopify?: boolean;
}
const CancelationFunnel: FC<Props> = ({ helpers, onUpgrade, isShopify }) => {
    const dispatch = useAppDispatch();
    const [step, setStep] = useState(0);
    const hideBackBtn = step === 0 || step === 3;
    const { data: planData } = useGetUserPlanQuery(null);
    const plan = planData?.result.find((item) => isShopify ? item.isShopify : !item.isShopify);
    const renewalDate = plan?.nextPaymentDate
    const [questionnaire, setQuestionnaire] = useState("");
    const [cancelSubs, { isLoading, data }] = useCancelSubscriptionMutation();
    const [otherAnswer, setOtherAnswer] = useState("");
    const onSelectOption = (newVal: string) => setQuestionnaire(newVal);
    const onClose = () => {
        if (step === 3) {
            dispatch(
                managePaymentsApi.util.invalidateTags([queryTags.managePayments.subscription])
            );
        }
        helpers.close()
    }
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<FormValues>({
        resolver: yupResolver(schema),
    });

    const onCancelClick = () => setStep(prev => prev + 1)
    const onBackClick = () => {
        if (step === 2) {
            setStep(hasDiscount ? 1 : 0)
            setOtherAnswer("");
        } else if (step === 4) {
            setStep(0);
        }
        else {
            setStep(prev => prev - 1);
        }
    }

    const handleOtherAnswer = (val: string) => {
        if (val === "") {
            toast.error("Please provide answer");
            return;
        }
        setOtherAnswer(val);
        setStep(2);
    }

    const onSubmitEmail: SubmitHandler<FormValues> = (values) => {
        cancelSubs({
            mainAnswer: questionnaire,
            email: values.email,
            otherFeedback: otherAnswer,
            SubscriptionType: isShopify ? "shopify" : "ebay"
        }).unwrap().then(() => {
            setStep(3);
            toast.success("Your subscription successfully canceled");
        }).catch((error) => toast.error(error.data))
    }
    const hasDiscount = plan?.billingInterval === 1 && (
        questionnaire === cancelationQuestionnaireOptions[0] ||
        questionnaire === cancelationQuestionnaireOptions[5] ||
        questionnaire === cancelationQuestionnaireOptions[4]);

    useEffect(() => {
        if (!helpers.isOpen) {
            setStep(0);
            setQuestionnaire("");
            setValue("email", "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [helpers.isOpen])

    return (
        <Modal
            helpers={helpers}
            shouldCloseOnOverlayClick={false}
            className="w-full max-w-[1055px]"
            cardClassName="h-full w-full p-5.5 relative"
            maxWidth="max-w-[1055px]"
            hideCloseIcon
            hideBorder
            overlay
        >
            <div className={`flex h-9 mb-[19px] w-full items-center ${hideBackBtn ? "justify-end" : "justify-between"}`}>
                <Button
                    title="Back"
                    height="h-9"
                    titleClassName="!font-bold"
                    iconClassName="w-[13.33px] h-[11.67px]"
                    contClassName="!gap-[9.33px]"
                    className={`z-40 max-w-[104px] w-full bg-white dark:bg-deepSpace900 ${hideBackBtn ? "invisible" : ""}`}
                    handleClick={onBackClick}
                    StartIcon={LeftArrow}
                />
                <div className="flex items-center justify-end w-full gap-[39px]">
                    {isShopify ? (
                        <div className="flex justify-center items-center w-full max-w-[84.45px] h-8 bg-neutral2 rounded-full">
                            <Shopify className="w-[64.45px] h-[16.74px]" />
                        </div>
                    ) : (
                        <div className="flex justify-center items-center w-[62px] h-8 bg-neutral2 rounded-full">
                            <Ebay className="w-[41px] h-[21.75px]" />
                        </div>
                    )}
                    <button className={`flex w-[30px] h-[30px] items-center justify-center group`} onClick={onClose}>
                        <Close className="w-[15px] h-[15px] group-hover:fill-primaryPurple  dark:group-hover:fill-purple500 fill-darkBlue dark:fill-grey100" />
                    </button>
                </div>

            </div>

            {step === 0 && <CancellationQuestionnaire
                hasDiscount={hasDiscount}
                onNextClick={(step: number) => setStep(step)}
                selectedOption={questionnaire}
                onSelect={onSelectOption}
            />}
            {step === 1 && <SaveUptoHalf
                onUpgrade={onUpgrade}
                onCancel={onCancelClick}
            />}
            {step === 2 && <CancelationEnterEmail
                register={register}
                isShopify={isShopify}
                renewalDate={formatDate(renewalDate, "MMM DD YYYY")}
                handleSubmit={handleSubmit(onSubmitEmail)}
                errors={errors}
                onUpgrade={onUpgrade}
                isLoading={isLoading}
            />}
            {step === 3 && <CancelSuccess date={data?.result.renewalDate} />}
            {step === 4 && <GetFeedback handleSubmit={handleOtherAnswer} />}
        </Modal >
    )
}

export default CancelationFunnel