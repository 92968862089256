
import { ReactComponent as LeftArrow } from "assets/icons/arrow-left.svg";
import { FC, LegacyRef } from "react";

interface Props {
    activeSlide: number;
    isShopify?: boolean;
    wrapperStyles?: string;
    prev: LegacyRef<HTMLDivElement>;
    next: LegacyRef<HTMLDivElement>;
}
const arrowCont = "flex items-center justify-center w-8 h-8 bg-neutral2 rounded-full hover:bg-primaryPurple group max-sm:hidden";
const arrowStyle = "fill-darkBlue group-hover:fill-white"
const arrowFilter = "drop-shadow(0px 8px 16px rgba(20, 23, 26, 0.04)) drop-shadow(0px 6px 8px rgba(20, 23, 26, 0.06))"

const NextPrevSlider: FC<Props> = ({ activeSlide, isShopify, wrapperStyles, next, prev }) => {
    return (
        <div className={`flex justify-center gap-[56px] w-full ${wrapperStyles} ${isShopify ? "min-[700px]:hidden" : "lg:hidden"}`}>
            <div ref={prev} className={arrowCont} style={{ filter: arrowFilter }}>
                <LeftArrow className={arrowStyle} />
            </div>
            <div ref={next} className={arrowCont} style={{ filter: arrowFilter }}>
                <LeftArrow className={`${arrowStyle} rotate-180`} />
            </div>
            <div
                className="flex items-center justify-between w-full px-[30px] sm:hidden max-w-[330px] "
            >
                <span
                    className="text-xs font-medium"
                >
                    Scroll
                </span>

                <div
                    className="relative w-[186px] h-2 rounded-full bg-secondary"
                >
                    <div
                        className={`h-full bg-smoothGreen rounded-full 
                          ${isShopify ? (activeSlide === 1 ? "!w-full" : "w-1/3") : (activeSlide == 0 ? "w-1/3" : activeSlide === 1 ? "w-2/3" : "w-full")}`}
                    />
                </div>
            </div>
        </div>
    )
}

export default NextPrevSlider