import Button from "components/Button";
import useModal from "hooks/useModal";
import { FC } from "react";
import Modal from ".";

interface Props {
  helpers: ReturnType<useModal>;
  onConfirm: () => void;
  title: string;
  confirmLoading?: boolean;
}
const ConfirmDelete: FC<Props> = ({
  helpers,
  onConfirm,
  confirmLoading,
  title,
}) => (
  <Modal
    helpers={helpers}
    className="flex flex-col items-center"
    cardClassName="pt-[57px] pb-[39px]"
    maxWidth="max-w-[546px]"
    buttonCloseClassName="!top-[22px] right-[21px]"
  >
    <h3 className="font-medium text-center  mb-[35px]">{title}</h3>
    <div className="flex gap-7 justify-center">
      <Button
        title="Yes"
        height="h-9"
        className="max-w-[9rem]"
        handleClick={onConfirm}
        loading={confirmLoading}
      />
      <Button
        title="No"
        height="h-9"
        className="max-w-[9rem]"
        handleClick={helpers.close}
      />
    </div>
  </Modal>
);

export default ConfirmDelete;
