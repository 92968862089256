import Header from "components/Header";
import HeaderIcon, { HeaderVariants } from "components/Header/Icon";

import Button from "components/Button";
import StatsCard from "components/Card/StatsCard";
import HeaderTab from "components/Header/Tab";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  CURRENCY_SIGNS,
  formatCurrency,
  formatNumber,
  formatPercent
} from "utils/formatCurrency";

import { ReactComponent as BackArrow } from "assets/icons/back-arrow.svg";
import { ReactComponent as Dollar } from "assets/icons/dollar.svg";
import { ReactComponent as Watchers } from "assets/icons/eye.svg";
import { ReactComponent as Flag } from "assets/icons/flag.svg";
import { ReactComponent as Hammer } from "assets/icons/hammer.svg";
import { ReactComponent as Menu } from "assets/icons/menu.svg";
import { ReactComponent as Rocket } from "assets/icons/rocket.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";

import { helpVideos } from "assets/data/helpVideos";
import HeaderHelpVideo from "components/Header/HelpVideo";
import Toast from "components/Toast";
import { ErrorCodes } from "config/errorMsgs";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import LoadingPage from "pages/Loading";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { PATH } from "routes/path";
import { useGetCatResMutation } from "store/api/catResApi";
import { useGetSettingsQuery } from "store/api/quickSettingsApi";
import { catResFilterChange } from "store/slices/catResSlice";
import { CURRENCY } from "types/currency";
import { PostCatResRequest } from "types/services/catRes";
import CategoryResearchList from "./components/CatResList";

const CategoryResearchResults = () => {
  const { catResData, filters } = useAppSelector((state) => state.catResSlice);
  const [searchParams, setSearchParams] = useSearchParams();
  const dataFetchedRef = useRef(false);

  const { data: settings } = useGetSettingsQuery()
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [getCatRes, { isLoading, error }] = useGetCatResMutation();
  const { t } = useTranslation(['home']);
  const filterChange = (
    newVal: string | number | Date | null,
    field: keyof PostCatResRequest
  ) => {
    dispatch(catResFilterChange({ newVal, field }));
  };

  useEffect(() => {
    // @ts-ignore
    if (error?.status === ErrorCodes.fetchError) {
      navigate(PATH.categoryResearch.root + `?error=${ErrorCodes.fetchError}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onSearchClick = async (loc?: string) => {
    await getCatRes({
      ...filters,
      page: searchParams.get("page") || "1",
      keywords: searchParams.get("keywords") || "",
      type: searchParams.get("type") || "all",
      location: loc || searchParams.get("location") || "US",
      condition: searchParams.get("condition") || "New",
      minPrice: searchParams.get("minPrice") || "",
      maxPrice: searchParams.get("maxPrice") || "",
      minFeedback: searchParams.get("minFeed") || "",
      maxFeedback: searchParams.get("maxFeed") || "",
      exclude: searchParams.get("exclude") || "",
      categoryId: searchParams.get("catId") || "",
      categoryText: searchParams.get("catTxt") || "",
      subCategoryText: searchParams.get("subCatTxt") || "",
    });
  };

  useEffect(() => {
    if (searchParams.get("newReq") && settings) {
      searchParams.delete("newReq");
      setSearchParams(searchParams);
      onSearchClick(settings?.result.shippingLocation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, settings])


  const getFilters = () => {
    filterChange(searchParams.get("page"), "page");
    filterChange(searchParams.get("keywords") || "", "keywords");
    filterChange(searchParams.get("type"), "type");
    filterChange(searchParams.get("location"), "location");
    filterChange(searchParams.get("condition"), "condition");
    filterChange(searchParams.get("minPrice") || "", "minPrice");
    filterChange(searchParams.get("maxPrice") || "", "maxPrice");
    filterChange(searchParams.get("minFeed") || "", "minFeedback");
    filterChange(searchParams.get("maxFeed") || "", "maxFeedback");
    filterChange(searchParams.get("exclude") || "", "exclude");
    filterChange(searchParams.get("catId") || "", "categoryId");
    filterChange(searchParams.get("catTxt") || "", "categoryText");
    filterChange(searchParams.get("subCatTxt") || "", "subCategoryText");
  };

  useEffect(() => {
    if (catResData.ebayDataViewmodelList.length === 0) {
      getFilters();
      if (!dataFetchedRef.current) {
        onSearchClick();
        dataFetchedRef.current = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoadingPage loading={isLoading}>
      <Header
        leftContent={
          <>
            <Button
              color="primaryPurple"
              title="Go Back"
              className="max-w-[8.125rem]"
              height="h-9"
              StartIcon={BackArrow}
              iconClassName="w-[13.33px] h-[11.67px]"
              isLink
              redirect={PATH.categoryResearch.root}
            />
            <HeaderTab title={t('CategoryResearch')} isActive={true} className="!h-10" />
          </>
        }
        rightContent={
          <>
            <HeaderHelpVideo videos={helpVideos.category} />
            <HeaderIcon variant={HeaderVariants.settings} handleClick={() =>
              navigate(`${PATH.quickSettings.root}?tab=search-preferences`)
            } />
          </>
        }
        leftContentClassName="w-full !gap-10"
        className="!pl-5"
      />

      <div className="grid grid-flow-row md:grid-cols-2 lg:grid-cols-4 gap-y-3.5 gap-x-[1.3125rem] mb-3.5 font-medium">
        <StatsCard
          title={t('SellTrough')}
          value={formatPercent(catResData.successItem) || "-"}
          valueClassName="text-primaryPurple dark:text-purple500"
          Icon={Rocket}
          info="The sell-through % is a measurement of how fast items are selling. For example, a sell-through rate of 500% means that on average each listing has gained 5 sales. The higher the sell-through % the better!"
        />
        <StatsCard
          title={t('Listings')}
          value={formatNumber(catResData.totalListing)}
          Icon={Menu}
          info="The amount of listings you have chosen to analyze"
        />
        <StatsCard
          title={t('SoldItems')}
          value={formatNumber(catResData.totalSales)}
          Icon={Hammer}
          info="The total amount of items sold amongst the listings being analyzed"
        />
        <StatsCard
          title={t('TotalWatchers')}
          value={formatNumber(catResData.totalWatch)}
          Icon={Watchers}
          info="The total amount of watchers that are keeping an eye on product."
        />
      </div>
      <div className="grid grid-cols-6 gap-x-[1.3125rem] gap-y-3.5 mb-5">
        <StatsCard
          title={t('SuccessfulListings')}
          value={formatPercent(catResData.successfulListings) || "-"}
          isSmall
          className="lg:col-span-2 md:col-span-3 col-span-6"
          Icon={Flag}
          info="The percentage of listings that have at least one sale"
        />
        <StatsCard
          title={t('AverageProductPrice')}
          value={formatCurrency(
            catResData.averagePrice,
            CURRENCY.none,
            catResData.currency as CURRENCY_SIGNS
          )}
          isSmall
          className="lg:col-span-2 md:col-span-3 col-span-6"
          iconClassName="w-5 h-5"
          Icon={Dollar}
        />
        <StatsCard
          title={t('SelectedCategory')}
          value={`${filters.categoryText}${filters.keywords ? " -> " : " "}${filters.keywords
            }`}
          valueClassName={
            filters.categoryText.length + filters.keywords.length > 45
              ? "!text-xl"
              : ""
          }
          isSmall
          className="lg:col-span-2 md:col-span-6 col-span-6"
          iconClassName="w-[19px] h-[19px]"
          Icon={SearchIcon}
          info="The category you've selected"
        />
      </div>
      <CategoryResearchList />
      <Toast />
    </LoadingPage>
  );
};

export default CategoryResearchResults;
