import { createApi } from "@reduxjs/toolkit/query/react";
import {
  GetTrendingShopifyProductsRes,
  GetTrendingShopifyStoresRes,
} from "types/services/shopifyMarketInsights";
import baseQuery from "./baseQuery";
import { queryKeys } from "./queryKeys";
import { queryTags } from "./queryTags";

export const shopifyMarketInsightsApi = createApi({
  reducerPath: "shopifyMarketInsightsApi",
  baseQuery,
  tagTypes: [queryTags.dashboardInsight.favoritesMenu],
  endpoints: (builder) => ({
    getTrendingShopifyStores: builder.query<GetTrendingShopifyStoresRes, void>({
      query: () => ({
        url: queryKeys.shopifyMarketInsights.trendingStores,
        method: "GET",
      }),
    }),
    getTrendingShopifyProducts: builder.query<
      GetTrendingShopifyProductsRes,
      void
    >({
      query: () => ({
        url: queryKeys.shopifyMarketInsights.trendingProducts,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetTrendingShopifyStoresQuery,
  useGetTrendingShopifyProductsQuery,
} = shopifyMarketInsightsApi;
