import LaunchDiscountText from "components/Plans/LaunchDiscountText";
import Toaster from "components/Toast";
import useDecodeToken from "hooks/useDecodeToken";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useGetUserPlanQuery } from "store/api/managePaymentApi";
import ShopifyBillingHeader from "./components/BillingHeader";
import ShopifyBillingEbayPlans from "./components/EbayPlans";
import ShopifyPlansComparison from "./components/plans/Comparison";
import ShopifyPlansCont from "./components/plans/PlansContainer";
import SelectMarketplace from "./components/SelectMarketplace";


const ShopifyPlans = () => {
    const user = useDecodeToken();
    const { search } = useLocation();
    const isEbayActive = search.includes("marketplace=ebay")
    const [activeTab, setActiveTab] = useState<"ebay" | "shopify">(isEbayActive ? "ebay" : "shopify");
    const onMarketplaceChange = (tab: "ebay" | "shopify") => setActiveTab(tab);
    const currentPlan = user.shopify_package_name === "ShopProY" ? "yearly" :
        user.shopify_package_name === "ShopProM" ? "monthly" : "";
    const { data } = useGetUserPlanQuery(null);
    const shopifyPlan = data?.result.find((item) => item.isShopify);
    const isActivePlan = !shopifyPlan?.isCanceled && currentPlan !== "";
    const isPayNow = !isActivePlan;
    const ebayPlan = data?.result.find((item) => !item.isShopify)
    const isTrial = /^true$/i.test(user.active_trail);
    const paymentStatus = ebayPlan?.status || 0;
    const isCanceled = ebayPlan?.isCanceled;
    const isEbayTrial = (paymentStatus === 0 || isCanceled) && !isTrial

    return (
        <div className="dark:text-grey100">
            <ShopifyBillingHeader />
            <div className="flex flex-col gap-4">
                <h4 className="text-center text-lg font-medium">Pick your plan</h4>
                <h6 className="text-center font-bold dark:text-grey100">Select your Marketplace</h6>
                <SelectMarketplace marketplace={activeTab} onMarketplaceChange={onMarketplaceChange} />
                <LaunchDiscountText activeTab={activeTab} ebayDiscountPercent={isEbayTrial ? 40 : 50} />
            </div>
            {activeTab === "ebay" ? (
                <ShopifyBillingEbayPlans />
            ) :
                (<div className="w-full pt-[26px]">
                    <ShopifyPlansCont isPayNow={isPayNow} />
                    <ShopifyPlansComparison isPayNow={isPayNow} />
                </div>)}
            <Toaster />
        </div>
    )
}

export default ShopifyPlans