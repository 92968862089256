export const calculateAmazonProfit = (
  itemPrice: number,
  similarItemPrice: number,
  breakEven: number
) => {
  if (itemPrice === null) {
    return "0";
  } else {
    return (
      itemPrice -
      ((itemPrice / 100) * breakEven + 0.3) -
      similarItemPrice
    ).toString();
  }
};

export const calculateWalmartProfit = (
  price: number,
  selectedItemPrice: number,
  breakEven: number
) => (price - ((price / 100) * breakEven + 0.3) - selectedItemPrice).toFixed(2);

export const calculateAlibabaProfit = (
  price: string,
  selectedItemPrice: number,
  breakEven: number
) => {
  // const profit = price - ((price / 100) * breakEven + 0.3) - price;
  let prices = price
    .replace(/[^0-9.]/g, " ")
    .match(/\d+(?:\.\d+)?/g)
    ?.map(Number);
  let profits = (
    selectedItemPrice -
    ((selectedItemPrice / 100) * breakEven + 0.3) -
    (prices ? prices[0] : 0)
  ).toFixed(2);

  return profits.toString();
};

export const calculateCjdProfit = (
  productPrice: string,
  itemPrice: number,
  breakEven: number
) => {
  var formatPrice = productPrice.replace("--", "-");
  var profit = formatPrice.includes("-")
    ? "Variation"
    : `$${(
        itemPrice -
        ((itemPrice / 100) * breakEven + 0.3) -
        Number(formatPrice)
      ).toFixed(2)}`;

  return profit;
};

export const calculateAliexpressProfit = (
  productPrice: string,
  itemPrice: number,
  breakEven: number
) => {
  var price = productPrice.replace(/[^\d.-]/g, "");
  var profit = (
    itemPrice -
    ((itemPrice / 100) * breakEven + 0.3) -
    Number(price)
  ).toFixed(2);

  return profit;
};
