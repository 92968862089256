import Button from "components/Button";
import { FC } from "react";
interface Props {
  uploadProgress: boolean;
  isSuccess: boolean;
}
const UploadStatus: FC<Props> = ({ uploadProgress, isSuccess }) =>
  uploadProgress || isSuccess ? (
    <Button
      title={uploadProgress ? "Pending" : "Success"}
      tooltipTitle={isSuccess ? "Products uploaded successfully" : undefined}
      titleClassName="text-xs"
      className={`max-w-[200px] text-white !max-w-[158px] cursor-default font-medium ${uploadProgress
        ? "bg-errorOrange border-errorOrange hover:bg-errorOrange hover:border-errorOrange"
        : "bg-success border-success hover:bg-success hover:border-success"
        }`}
      height="h-[26px]"
    />
  ) : (
    <></>
  );

export default UploadStatus;
