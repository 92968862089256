import { ReactComponent as Rating } from "assets/icons/rating.svg";
import Button from "components/Button";
import Card from "components/Card";
import { FC, useState } from "react";
import AnimateHeight from "react-animate-height";
import { Level2Cat } from "types/services/catRes";

interface CategoryItemProps extends Level2Cat {
  handleClick?: (newCategory: string, newSearch: string) => void;
}
interface Props {
  title: string;
  categoryItems: Level2Cat[];
  id: string;
  handleCategoryChange: (newVal: string) => void;
  handleSearchChange: (newVal: string) => void;
}
const CategorySubItem: FC<CategoryItemProps> = ({
  rank,
  handleClick,
  name,
  level1CatId,
}) => {
  const nRank = Number(rank);
  const noOfStars =
    nRank > 3 ? nRank - 1 : nRank === 3 ? 2.5 : nRank === 1 ? 1.5 : nRank;
  const handleItemClick = () => {
    handleClick && handleClick(level1CatId, name);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center group">
        <button
          className="text-sm cursor-pointer hover:text-primaryPurple dark:hover:text-purple500 dark:text-grey100 mr-2 text-left relative"
          onClick={handleItemClick}
        >
          {name}
          <div className={`w-[107px] h-[30px] z-10 justify-center items-center text-xs bg-darkBlue text-white dark:bg-lilac400 dark:text-deepSpace900 rounded-[5px] absolute top-[25px] left-[80%] hidden group-hover:flex`}>
            <span>
              Click to paste
            </span>
          </div>
        </button>
      </div>
      <div className="flex gap-2.5">
        {Array.from(Array(Math.floor(noOfStars)).keys()).map((item) => (
          <Rating key={item} className="fill-[#E4C210]" />
        ))}
      </div>
    </div>
  );
};

const CategoryCard: FC<Props> = ({
  title,
  categoryItems,
  handleCategoryChange,
  handleSearchChange,
}) => {
  const cardHeight = 270;
  const duration = 400;
  const [height, setHeight] = useState<number | "auto">(cardHeight);
  const [showMore, setShowMore] = useState(false);

  return (
    <Card className="py-6 px-5 h-fit" rounded="rounded-[20px]">
      <AnimateHeight
        id="example-panel"
        duration={duration}
        style={{ flexShrink: 0 }}
        onHeightAnimationEnd={() =>
          height === cardHeight ? setShowMore(false) : ""
        }
        height={height}
      >
        <h6 className="font-bold mb-3.5 dark:text-grey100">{title}</h6>
        <div className={`flex flex-col gap-2.5 mb-6 `}>
          {[...(showMore ? categoryItems : categoryItems.slice(0, 6))].map(
            (item) => (
              <CategorySubItem
                {...item}
                key={item.level2CatId}
                handleClick={(newCategory, newSearch) => {
                  handleSearchChange(newSearch);
                  handleCategoryChange(newCategory);
                }}
              />
            )
          )}
        </div>
        {categoryItems.length > 6 ? (
          <div className="w-full flex justify-center">
            <Button
              title={showMore ? "Less" : "More"}
              handleClick={() => {
                height === cardHeight && setShowMore(true);
                setHeight((prev) => (prev === "auto" ? cardHeight : "auto"));
              }}
              color="primaryPurple"
              height="h-[34px]"
              className="max-w-[12.875rem] mx-auto"
            />
          </div>
        ) : (
          ""
        )}
      </AnimateHeight>
    </Card>
  );
};

export default CategoryCard;
