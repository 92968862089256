import Button from "components/Button";
import VideoEmbed from "components/VideoEmbed/Index";
import { sidebarItems } from "types/academy";

interface Props {
  sidebarTitle: string;
  sidebarData: sidebarItems[];
  hasCtaButton: boolean;
  ctaButtonText?: string;
  handleSourceChange: (newVal: string, name: "playlistSource") => void;
  handleTypeChange: (newVal: string, name: "type") => void;
  playlistSource: string;
  smMenuOpen?: boolean;
}

const forSmallerScreen = "max-[1200px]:z-[99] max-[1200px]:fixed max-[1200px]:top-0 max-[1200px]:left-0 max-[1200px]:h-[100vh] max-[1200px]:rounded-[0] max-[1200px]:overflow-y-scroll max-[1200px]:translate-x-[-100%] transition ease duration-200"

const Sidebar = ({
  sidebarTitle,
  sidebarData,
  handleSourceChange,
  handleTypeChange,
  playlistSource,
  smMenuOpen
}: Props) => (
  <>
    <div className={`max-w-[341px] w-full rounded-10 dark:bg-deepSpace900 dark:text-grey100 bg-white shadow-lg px-4 pt-3 pb-4.5 flex flex-col h-fit shrink-0 ${forSmallerScreen}`} style={smMenuOpen ? { transform: "translateX(0)" } : {}}>
      <div className="text-center font-bold mb-[15px] dark:text-paradiseBlue">{sidebarTitle}</div>
      <VideoEmbed
        source="https://player.vimeo.com/video/770692473?h=1469f24206"
        className="rounded-10 mb-[15px]"
      />
      <Button
        title="Join the VIP group Now"
        color="smoothGreen"
        className="shrink-0 mb-[23px] max-w-[260px] mx-auto"
        height="h-[51px]"
        isLink
        target="_blank"
        redirect="https://www.facebook.com/groups/373557291131694"
      />
      <div className="flex flex-col gap-5">
        {sidebarData.map((item, i) => (
          <div key={i} className="flex flex-col gap-3 items-center">
            <div className="font-bold dark:text-paradiseBlue">{item.title}</div>
            {item.buttons.map((button, i) => (
              <span
                key={i}
                onClick={() => {
                  handleSourceChange(button.source, "playlistSource");
                  handleTypeChange(button.type, "type");
                }}
                className={`block text-left flex  items-center text-sm leading-[1.2] h-14 w-full pl-[30.5px]  font-medium rounded-[50px] border-2 
                ${playlistSource === button.source ? "border-darkBlue bg-transparent dark:border-grey100 cursor-default" : "bg-neutral2 border-transparent dark:bg-deepSpace800 cursor-pointer dark:hover:bg-purple500 hover:text-white dark:hover:border-purple500 hover:bg-primaryPurple hover:border-primaryPurple dark:text-grey100"
                  }`}
              >
                {button.name}
              </span>
            ))}
          </div>
        ))}
      </div>
    </div>
  </>
);

export default Sidebar;
