import InfoTooltip from "components/Card/InfoTooltip";
import { FC, ReactNode } from "react";

interface Props {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  className?: string;
  wrapperClassName?: string;
  iconClassName?: string;
  infoTitle?: string;
  infoTooltipClassName?: string;
  bordered?: boolean;
  children: ReactNode;
}
const MinMaxFilterCard: FC<Props> = ({
  Icon,
  title,
  wrapperClassName,
  className,
  iconClassName,
  bordered,
  infoTitle,
  infoTooltipClassName,
  children,
}) => (
  <div className={`flex flex-col justify-center items-center relative ${wrapperClassName || ""}`}>
    <div
      className={`flex items-center justify-between w-full h-6 ${className || ""}`}
    >
      <Icon className={`fill-darkBlue dark:fill-grey100 ${iconClassName || ""}`} />
      <p className="text-sm font-bold dark:text-grey100">{title}</p>
      <div>
        {infoTitle && (
          <InfoTooltip title={infoTitle} className={infoTooltipClassName} />
        )}
      </div>
    </div>
    {children}
    {bordered && (
      <div className="absolute right-0 w-[2px] top-1/2 -translate-y-1/2 bg-secondary h-[90%] " />
    )}
  </div>
);

export default MinMaxFilterCard;
