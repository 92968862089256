import { ColumnDef } from "@tanstack/react-table";
import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";
import { ReactComponent as Heart } from "assets/icons/heart.svg";
import { ReactComponent as Trash } from "assets/icons/trash.svg";
import HoveredIcon from "components/HoveredIcon";
import ProductNameCell from "components/ProductNameCell";
import DualRowCell from "components/Table/DualRowCell";
import Tooltip from "components/Tooltip";
import dayjs from "dayjs";
import SalesSpyEditComment from "pages/Shopify/SalesSpy/components/EditComment";
import { Link } from "react-router-dom";
import { SalesSpyList } from "types/services/salesSpy";
import { newCutLongTitle } from "utils/cutLongTitle";
import { formatCurrency, formatNumber } from "utils/formatCurrency";
import { getCountryFlag } from "utils/getCountryFlag";

const linkClassName = "bg-neutral2 dark:bg-deepSpace900 text-xs px-[9px] py-[5px] rounded-[5px]"
export const salesSpyCols = (type: "salesHistory" | "watchlist" | "inProgress", handleDeleteClick?: (domainId: number) => void): ColumnDef<SalesSpyList>[] => ([
    {
        header: "Store",
        accessorKey: "store",
        enableSorting: false,
        cell: ({ row, row: { original } }) => (
            <div className="flex justify-between items-center">
                <div className="flex items-center gap-2.5 ml-[13px]">
                    <div className="w-[5rem]">
                        <ProductNameCell
                            img={original.favIcon}
                            imgWrapperClassName="ml-0"
                            hoveredImgWidth={800}
                            height="59px"
                            adjustFirstandLastItemsAuto
                            width="75px"
                            maxHeight="max-h-[59px]" />
                    </div>
                    <div className="flex flex-col gap-2.5">
                        <div className="flex items-center gap-2.5">
                            <img src={getCountryFlag(original.country || "")} className="w-4 h-4" alt="" />
                            {original.inQueue === true ? <p data-tooltip-id={original.storeName + "title" + row.index} className={linkClassName}>{newCutLongTitle(original.storeName || "", 27)}</p> : <Link to={`${original.similarwebDomainsId.toString()}?storeDomain=${original.domain}`} target="_blank" data-tooltip-id={original.storeName + "title" + row.index} className={linkClassName}>{newCutLongTitle(!original.storeName || original.storeName === "" || original.storeName === "undefined" ? original.domain.split(/\.(?=[^\.]+$)/)[0] : original.storeName || "", 27)}</Link>}
                        </div>
                        <a data-tooltip-id={original.domain + row.index} href={original.domain.includes("https://") ? original.domain : `https://${original.domain}`} target="_blank" rel="noreferrer" className="text-electricBlue underline dark:text-lilac400 min-w-[240px]">{newCutLongTitle(original.domain.replace("https://", "") || "", 30)}</a>
                        {original.storeName?.length > 28 ? <Tooltip title={original.storeName} anchorId={original.storeName + "title" + row.index} /> : ""}
                        {original.domain?.length > 31 ? <Tooltip title={original.domain.replace("https://", "")} anchorId={original.domain + row.index} /> : ""}
                    </div>
                </div>
                {original.addedFavoutire && type !== "watchlist" ? (
                    <Heart className="fill-primaryPurple dark:fill-purple500 color-primary my-auto h-[10.52px] w-[14.67px]" />
                ) : ""}
            </div>

        ),
        meta: {
            align: "justify-start"
        }
    },
    ...(type !== "watchlist" ? [{
        header: "Search Date",
        accessorKey: "searchDate",
        cell: ({ row: { original } }) => (
            <p className="pl-2">{dayjs(original.searchDate).format('D MMM YYYY')}</p>
        ),
        meta: {
            align: "justify-start"
        }
    } as ColumnDef<SalesSpyList>] : []),
    {
        header: "Products",
        accessorKey: "totalProducts",
        cell: ({ row: { original } }) => original.inQueue === true || (original.inQueue === false && original.addedByUser === true && type === "inProgress") ? (
            <div className={`absolute w-full h-10 flex ${original.addedByUser === true ? "justify-center" : ""} items-center bg-white dark:bg-black900 max-w-[47.5%] right-[8.9%] max-[1370px]:max-w-[580px] max-[1370px]:min-w-[580px] max-[1370px]:left-[544px]`}>
                {original.inQueue === false && original.addedByUser === true ? <div className="w-6 h-6 flex items-center justify-center bg-smoothGreen rounded-full">
                    <Checkmark className="fill-white w-3 h-[9px]" />
                </div> : (
                    <div className="w-full border-yellow bg-yellow h-[26px] rounded-full flex items-center justify-center text-xs text-darkBlue">
                        <p>In Progress</p>
                    </div>
                )}
            </div>
        ) : (
            <p className="font-bold pl-2">{formatNumber(original.totalProducts)}</p>
        ),
        meta: {
            showHeaderBorder: true,
            align: "justify-start"

        },
    },
    {
        header: "Sales Today",
        accessorKey: "todaySales",
        cell: ({ row: { original } }) => original.inQueue === true || (original.inQueue === false && original.addedByUser === true && type === "inProgress") ? "" : (
            <DualRowCell
                firstData={formatNumber(original.todaySales)}
                secondData={formatCurrency(Math.round(original.revenueToday))}
                title={'Products sold and revenue generated today.'}
            />
        ),
        meta: {
            showHeaderBorder: true,
            align: "justify-start"
        }
    },
    {
        header: "Sales Yesterday",
        accessorKey: "yesterdaySales",
        cell: ({ row: { original } }) => original.inQueue === true || (original.inQueue === false && original.addedByUser === true && type === "inProgress") ? "" : (
            <DualRowCell
                firstData={formatNumber(original.yesterdaySales)}
                secondData={formatCurrency(Math.round(original.revenueYesterday))}
                title={'Products sold and revenue generated yesterday.'}
            />
        ),
        meta: {
            showHeaderBorder: true,
            align: "justify-start"
        }
    },
    {
        header: "Sales 7 Days",
        accessorKey: "last7DaysSales",
        cell: ({ row: { original } }) => original.inQueue === true || (original.inQueue === false && original.addedByUser === true && type === "inProgress") ? "" : (
            <DualRowCell
                firstData={formatNumber(original.last7DaysSales)}
                secondData={formatCurrency(Math.round(original.revenue7DaysSales))}
                title={'Products sold and revenue generated over the past 7 days.'}
            />
        ),
        meta: {
            showHeaderBorder: true,
            align: "justify-start"
        }
    },
    {
        header: "Total Sold",
        accessorKey: "totalSales",
        cell: ({ row: { original } }) => original.inQueue === true || (original.inQueue === false && original.addedByUser === true && type === "inProgress") ? "" : (
            <DualRowCell
                firstData={formatNumber(original.totalSales)}
                secondData={formatCurrency(Math.round(original.revenueTotal))}
                title={'Total number of products sold and revenue generated.'}
            />
        ),
        meta: {
            align: "justify-start"
        }
    },
    ...(type === "watchlist" ? [{
        header: "Comments",
        accessorKey: "comment",
        minSize: 80,
        cell: ({ row: { original } }) => (
            <SalesSpyEditComment
                comment={original.comment || ""}
                domainId={original.similarwebDomainsId}
                title="Notes and observations about your favorite stores."
            />
        ),
        meta: {
            align: "justify-start"
        }
    } as ColumnDef<SalesSpyList>] : []),
    {
        header: () => <CompetitorResearch className="fill-darkBlue w-[17.5px] h-[18.96px] dark:fill-grey100" />,
        accessorKey: "competitors",
        enableSorting: false,
        minSize: 80,
        cell: ({ row: { original } }) => original.inQueue === true ? (<HoveredIcon Icon={CompetitorResearch} iconClassName="w-[17.5px] h-[18.96px]"
            iconColor="fill-grey200 group-hover:!fill-grey200 dark:!fill-grey400 dark:group-hover:!fill-grey400"
            className="border-grey200 hover:!border-grey200 hover:!bg-transparent dark:!border-grey400 dark:hover:!border-grey400 dark:hover:bg-transparent !cursor-default"
            title="You will be able to see the store data once we start tracking its sales" variant="outlined" width="w-[35px]" height="h-[35px]"
        />) : (
            <Link target="_blank" to={`${original.similarwebDomainsId.toString()}?storeDomain=${original.domain}`} rel="noreferrer">
                <HoveredIcon Icon={CompetitorResearch} iconClassName="w-[17.5px] h-[18.96px]" variant="outlined" width="w-[35px]" height="h-[35px]" />
            </Link>

        ),
        meta: {
            alignHead: "justify-center",
            tooltipTitle: "Scan Store"
        }
    },
    ...(type === "watchlist" ? [{
        header: () => <Trash className="fill-darkBlue w-[17.5px] h-[18.96px] dark:fill-grey100" />,
        accessorKey: "id",
        enableSorting: false,
        minSize: 80,
        cell: ({ row: { original } }) => (
            <HoveredIcon Icon={Trash} iconClassName="w-[17.5px] h-5" variant="outlined" handleClick={() => handleDeleteClick && handleDeleteClick(original.similarwebDomainsId)} width="w-[35px]" height="h-[35px]" />
        ),
        meta: {
            alignHead: "justify-center",
            tooltipTitle: "Delete Store"
        }
    } as ColumnDef<SalesSpyList>] : [])
])