import {
  amazonUkCategories,
  amazonUsCategories,
  walmartCategories,
} from "assets/data/autopilotCategories";
import { useEffect, useState } from "react";

const useSupplierCategories = (supplier: string) => {
  const [categories, setCategories] = useState<any>([]);
  useEffect(() => {
    supplier === "US"
      ? setCategories(amazonUsCategories)
      : supplier === "UK"
      ? setCategories(amazonUkCategories)
      : supplier === "WAL"
      ? setCategories(walmartCategories)
      : setCategories([]);
  }, [supplier]);

  const getCategoriesByIds = (supplier: string, ids: string[]) => {
    const cats =
      supplier === "US"
        ? amazonUsCategories
        : supplier === "UK"
        ? amazonUkCategories
        : walmartCategories;

    const filteredCategories = cats
      .filter((cat) => ids.some((id) => cat.value === id))
      .map((itm) => itm.text);

    return filteredCategories;
  };

  return {
    categories,
    getCategoriesByIds,
  };
};

export default useSupplierCategories;
