import ShopifyPlansComparison from "pages/Shopify/Billing/components/plans/Comparison";
import ShopifyPlansCont from "pages/Shopify/Billing/components/plans/PlansContainer";
import { FC } from "react";
interface Props {
    isPayNow?: boolean;
}

const ShopifyPlans: FC<Props> = ({ isPayNow }) => (
    <div>
        <ShopifyPlansCont isPayNow={isPayNow} />
        <ShopifyPlansComparison isPayNow={isPayNow} />
    </div>
)

export default ShopifyPlans