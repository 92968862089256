import HoveredIcon from "components/HoveredIcon";
import { ChangeEvent, FC, useId, useState } from "react";

import { ReactComponent as Cancel } from "assets/icons/clear-input.svg";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import { ReactComponent as Save } from "assets/icons/save.svg";
import Tooltip from "components/Tooltip";
import useHover from "hooks/useHover";
import { toast } from "react-toastify";
import { useUpdateWatchlistCommentMutation } from "store/api/salesSpy";
import { newCutLongTitle } from "utils/cutLongTitle";

interface Props {
    comment: string;
    domainId: number;
    className?: string;
    title?: string;
}

const iconStlyes = {
    width: "w-5",
    height: "h-5",
}

const SalesSpyEditComment: FC<Props> = ({
    className,
    comment,
    domainId,
    title
}) => {
    const [hoverRef, isHovered] = useHover<HTMLDivElement>()
    const [isEdit, setIsEdit] = useState(false);
    const [updateComment, { isLoading }] = useUpdateWatchlistCommentMutation()
    const [txt, setTxt] = useState(comment);
    const id = useId();
    const handleChange = (
        e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
    ) => setTxt(e.target.value);
    const onEdit = () => setIsEdit(true);
    const onCancel = () => {
        setIsEdit(false);
        setTxt(comment);
    };
    const onSave = () => {
        updateComment({ domainId, comment: txt }).unwrap()
            .then(() => { toast.success("Store comment updated successfully"); setIsEdit(false) })
            .catch((err) => toast.error(err.data === "not found" ? "Domain not found" : "Something went wrong"));
    }

    return (
        <div
            data-tooltip-id={id}
            className={`mx-2 flex justify-between relative rounded-[10px] py-2 max-h-[80px] border-2 border-solid  w-[190px] dark:bg-deepSpace900
            ${isEdit ? "dark:border-purple500 border-primaryPurple bg-neutral2" : "cursor-pointer bg-secondary dark:hover:border-purple500 hover:border-primaryPurple dark:border-deepSpace900 border-secondary"} ${className || ""}`}
            onClick={() => {
                if (!isEdit) {
                    setIsEdit(true)
                }
            }}
            ref={hoverRef}
        >
            {isEdit ? (
                <textarea
                    value={txt?.toString()}
                    onChange={handleChange}
                    maxLength={250}
                    className={`w-[160px] text-xs overflow-hidden pl-2 font-medium white-scrollbar resize-none bg-neutral2 dark:bg-deepSpace900 overflow-y-auto h-[64px] dark:text-grey100 rounded-[5px]`}
                />
            ) : (
                <p className={`pl-2 font-medium ${txt.length > 50 && !isEdit ? "overflow-y-scroll overflow-x-hidden" : ""}`}>{newCutLongTitle(txt, 100)}</p>
            )}

            {isEdit ? (
                <div className="flex flex-col items-center gap-1 mr-1.5">
                    <HoveredIcon
                        Icon={Cancel}
                        handleClick={onCancel}
                        title="Cancel"
                        iconClassName="w-2 h-2"
                        className="!border-0"
                        iconColor="fill-darkBlue dark:fill-purple500 dark:group-hover:fill-grey100"
                        {...iconStlyes}
                    />
                    <HoveredIcon
                        Icon={Save}
                        handleClick={onSave}
                        title="Save"
                        isLoading={isLoading}
                        iconClassName="w-[13.33px] h-[7.5px]"
                        iconColor="fill-darkBlue dark:fill-purple500 dark:group-hover:fill-grey100"
                        className="!border-0"
                        {...iconStlyes}
                    />
                </div>
            ) : (
                <div>
                    <HoveredIcon
                        Icon={Edit}
                        handleClick={onEdit}
                        title="Edit"
                        iconClassName="w-[9.93px] h-[10.37px]"
                        iconColor={isHovered ? "fill-primaryPurple" : ""}
                        {...iconStlyes}
                        className=" mr-1.5"
                    />
                </div>
            )}
            {title && <Tooltip anchorId={id} title={title} />}
        </div>)
}
export default SalesSpyEditComment;