import { ReactComponent as Left } from 'assets/icons/arrow-left.svg';
import { ReactComponent as Right } from 'assets/icons/arrow-right.svg';
import { SLIDER_PROPS } from 'config/config';
import { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import VideoCard from "./VideoCard";


interface Props {
  playList: {
    title: string;
    source: string;
  }[];
}

const VideoSlider = ({ playList }: Props) => {
  const settings = {
    ...SLIDER_PROPS(),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };
  const sliderRef = useRef<Slider>(null);

  const prevNextStyles =
    "w-8 h-8 bg-white dark:bg-deepSpace800 shadow-lg rounded-full flex items-center justify-center absolute  top-[50%] z-10 translate-y-[-50%] cursor-pointer";

  return (
    <div className="relative">

      <div onClick={() => sliderRef?.current?.slickPrev()} className={prevNextStyles}>
        <Left className="fill-darkBlue dark:fill-grey100" />
      </div>

      <Slider {...settings} ref={sliderRef}>

        {playList.map((video, k) => (
          <div key={k} className="px-[8px] pb-[16px]">
            <VideoCard title={video.title} source={video.source} />
          </div>
        ))}

      </Slider>
      <div onClick={() => sliderRef?.current?.slickNext()} className={`${prevNextStyles} right-0 slick-disabled`}>
        <Right className="fill-darkBlue dark:fill-grey100" />
      </div>
    </div>
  );
};

export default VideoSlider;
