import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";
import Dropshipper from "assets/icons/dropshipper";
import { ReactComponent as PrintOnDemand } from "assets/icons/print-on-demand.svg";
import { ReactComponent as SellerScan } from "assets/icons/seller-scan.svg";

import CutLongTitle from "components/CutLongTitle";
import HoveredIcon from "components/HoveredIcon";
import Loading from "components/Loading";
import Scrollbar from "components/Scrollbar";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TrendingEbaySellerList } from "types/services/newDashboard";
import { formatCurrency, formatNumber } from "utils/formatCurrency";
import { getCountryFlag } from "utils/getCountryFlag";

import Tooltip from "components/Tooltip";
import TrendingSellersSortableTitle from "components/TrendingSellers/SortableTitle";
import { CURRENCY } from "types/currency";

interface Props {
    isShopify?: boolean;
    isAli?: boolean
    isFetching?: boolean
    data: TrendingEbaySellerList[];
    getStoreLink: (item: TrendingEbaySellerList) => string;
    getSpyRoute: (seller: string, storeDomain?: string) => string;
    currency: CURRENCY
}

const TrendingSellersList: FC<Props> = ({ isShopify, isAli, data, isFetching, getStoreLink, getSpyRoute, currency }) => {
    const [ascending, setAscending] = useState(false);
    const [sortedColKey, setSortedColKey] = useState("")
    const [sortedSellers, setSortedSellers] = useState<TrendingEbaySellerList[]>(
        []
    );

    useEffect(() => {
        if (data) {
            setSortedSellers(data);
        }
    }, [data]);

    const handleSort = (by: string) => {
        setSortedColKey(by)
        const col = (by === "Seller" && "sellerName") || by.toLocaleLowerCase()
        if (data && data.length > 0) {
            setAscending((prev) => !prev);
            const sortedList = [...data].sort((a: Record<string, any>, b: Record<string, any>) => {
                const valueA = a[col];
                const valueB = b[col];
                if (typeof valueA === 'string' && typeof valueB === 'string') {
                    return ascending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
                } else if (typeof valueA === 'number' && typeof valueB === 'number') {
                    return ascending ? valueA - valueB : valueB - valueA;
                } else {
                    return 0;
                }
            });
            setSortedSellers(sortedList);
        }
    };

    const tableHeadProps = {
        sortedColTitle: sortedColKey,
        handleSort,
        ascending
    }

    return (

        <div className="mt-4 pl-4 text-darkBlue dark:text-grey100 w-full">
            <table className="text-xs block w-full text-center text-[10px]">
                <thead className=" block w-full relative">
                    <tr className={`sticky top-0 bg-white dark:bg-transparent z-[9] font-medium w-full flex items-center justify-center ${isShopify ? "pr-3.5" : ""}`}>
                        <TrendingSellersSortableTitle title="Seller" {...tableHeadProps} titleSpanClassName={`${isShopify ? "pl-3" : ""}`} />
                        <TrendingSellersSortableTitle title="Scan" {...tableHeadProps} titleClassName={`hover:!text-darkBlue !cursor-default dark:hover:!text-grey100`} titleSpanClassName={`${isShopify ? "" : ""}`} />
                        <TrendingSellersSortableTitle title={isShopify ? "Sales" : "Feedback"} {...tableHeadProps} titleClassName={isShopify ? "!justify-start" : ""} />
                        <TrendingSellersSortableTitle sortColKey="feedback" title={isShopify ? "Revenue" : "Sales"} {...tableHeadProps} titleClassName={isShopify ? "!justify-start" : ""} />
                    </tr>
                </thead>

                <Scrollbar as="tbody" className={`${isShopify ? "h-[684px]" : "h-[331px]"} block w-full`}>
                    {isFetching || sortedSellers?.length < 1 ? (
                        <tr className="w-full flex justify-center items-center h-full">
                            <td>
                                {isFetching ? (
                                    <Loading fill="fill-primaryPurple dark:fill-purple500" width={40} height={40} />
                                ) : (
                                    <span className="text-neutral3 text-base block">No Data</span>
                                )}
                            </td>
                        </tr>
                    ) : (
                        sortedSellers.map((item, index) => (
                            <tr
                                key={index}
                                className="w-full flex items-center justify-center"
                            >
                                <td className="py-3.5 basis-full grow-[2] min-w-[60px]">
                                    <div
                                        className="flex flex-col items-center"

                                    >
                                        {!isAli && (
                                            <div className="flex items-center gap-[7px]">
                                                <span className="bg-greyWhite rounded-full w-4 h-4 overflow-hidden block">
                                                    <img
                                                        src={getCountryFlag(item?.sellerLocation || "")}
                                                        alt={item?.sellerLocation || ""}
                                                    />
                                                </span>
                                                {item.storeType ? (
                                                    <div className="w-6 h-6 flex items-center justify-center" data-tooltip-id={item.sellerName + item.searchedUserID}>
                                                        {item.storeType === 1 ? <div className="w-4.5 h-4.5 flex items-center justify-center border boder-solid border-darkBlue dark:border-grey100 rounded-full"> <Dropshipper color="darkBlue" className="fill-darkBlue min-w-[12.73px] h-[10.93px] fill-darkBlue dark:fill-grey100" /> </div> :
                                                            item.storeType === 2 ? <div className="w-4.5 h-4.5 flex items-center justify-center text-white"><PrintOnDemand className="text-darkBlue dark:text-grey100 w-[17px] h-3.5" /></div> : <div className="bg-darkBlue dark:bg-grey100 dark:text-black900 w-4.5 h-4.5 rounded-full flex items-center justify-center text-white">1</div>
                                                        }
                                                        <Tooltip title={item.storeType === 1 ? "Dropshipper" : item.storeType === 2 ? "Print On Demand" : "One Product Store"} anchorId={item.sellerName + item.searchedUserID} />
                                                    </div>
                                                ) : ""}
                                            </div>
                                        )}
                                        <Link
                                            to={getStoreLink(item)}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <CutLongTitle
                                                title={item?.sellerName}
                                                titleClassName="font-normal cursor-pointer text-[9px]"
                                                length={10}
                                            />
                                        </Link>
                                    </div>
                                </td>
                                <td className="basis-full grow-[2]">
                                    <span className="flex justify-center">
                                        <Link
                                            to={getSpyRoute(isShopify ? (item?.searchedUserID?.toString() || "") : item.sellerName, isShopify ? item?.domain : undefined)}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <HoveredIcon
                                                iconClassName={index < 3 ? "w-5.5 h-5" : "w-4.5 h-4.5"}
                                                title={item.sellerName}
                                                Icon={item.isFire ? SellerScan : CompetitorResearch}
                                                width="w-[34px]"
                                                height="h-[34px]"
                                            />
                                        </Link>
                                    </span>
                                </td>
                                <td className={`basis-full grow-[2] ${isShopify ? "text-left" : ""}`}>
                                    {formatNumber(item[isShopify ? "sales" : "feedback"])}
                                </td>
                                <td className={`basis-full grow-[2] ${isShopify ? "text-left" : ""}`}>
                                    {formatCurrency(isShopify ? item.feedback : item.sales, item.currencySymbol as CURRENCY || currency)}
                                </td>
                            </tr>

                        ))
                    )}
                </Scrollbar>
            </table>
        </div >
    );
};

export default TrendingSellersList;
