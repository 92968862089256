import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import { Dispatch, FC, SetStateAction } from "react";

export interface Props {
  isChecked: boolean;
  setIsChecked?: Dispatch<SetStateAction<boolean>>;
  handleChange?: () => void;
  noCheckmarkIcon?: boolean;
  wrapperClassName?: string;
}
const Switch: FC<Props> = ({ isChecked, setIsChecked, handleChange, noCheckmarkIcon, wrapperClassName }) => {
  const toggle = () => {
    handleChange && handleChange();
    setIsChecked && setIsChecked((prev) => !prev);
  };

  return (
    <button
      className={`w-16 h-6 rounded-full ${wrapperClassName} ${isChecked ? "bg-primaryPurple dark:bg-purple500" : "bg-secondary"
        } flex items-center transition duration-300 focus:outline-none`}
      onClick={toggle}
    >
      <div
        id="switch-toggle"
        className={`w-[29px] h-4.5 relative rounded-full bg-white transition dark:bg-deepSpace900 duration-500 transform ${isChecked ? " translate-x-[110%]" : " translate-x-1"
          }`}
      >
        {noCheckmarkIcon ? "" : (
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            {isChecked ? <Checkmark className="fill-primaryPurple dark:fill-purple500 w-3" /> : ""}
          </div>
        )}
      </div>
    </button>
  );
};

export default Switch;
