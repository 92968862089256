import { ReactComponent as Dollar } from "assets/icons/dollar.svg";
import { ReactComponent as Hammer } from "assets/icons/hammer.svg";
import { ReactComponent as Menu } from "assets/icons/menu.svg";
import { ReactComponent as Rocket } from "assets/icons/rocket.svg";
import { ReactComponent as Shipping } from "assets/icons/shipping.svg";
import { ReactComponent as Store } from "assets/icons/store.svg";
import Button from "components/Button";
import Header from "components/Header";
import HeaderIcon, { HeaderVariants } from "components/Header/Icon";
import HeaderTab from "components/Header/Tab";
import ConfirmDelete from "components/Modal/ConfirmDelete";
import { useAppDispatch } from "hooks/useAppDispatch";
import useModal from "hooks/useModal";
import LoadingPage from "pages/Loading";
import RushDay from "pages/NewDashboard/components/myDashboard/RushDay";
import SellAndRevenue from "pages/NewDashboard/components/myDashboard/SellAndRevenue";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import {
  useDisconnectStoreMutation,
  useGetStoresQuery,
  useGetUserStoreDetailsQuery
} from "store/api/storeApi";
import { setUserAccessToken } from "store/slices/userSlice";
import { CURRENCY } from "types/currency";
import {
  CURRENCY_SIGNS,
  formatCurrency,
  formatNumber,
  formatPercent
} from "utils/formatCurrency";
import { SetAccessToken } from "utils/localStorage";
import StoreProductList from "./components/StoreProductList";
import StoreStatsCard from "./components/StoreStatsCard";
import StoreUpdate from "./components/StoreUpdate";

const StoreAnalytics = () => {
  const dispatch = useAppDispatch();
  const [disconnect, { isLoading }] = useDisconnectStoreMutation();
  const { t } = useTranslation(['home']);
  const modal = useModal();
  const navigate = useNavigate();
  const { data, isFetching } = useGetStoresQuery();



  const { data: storeDetails, isFetching: storeFetching } =
    useGetUserStoreDetailsQuery(
      {
        storeName: data ? data.result?.[0]?.ebayUsername : "",
      },
      { skip: !data }
    );


  //if no store added , return
  data?.result.length === 0 && navigate(PATH.managementPanel.storeAnalytics);

  const store = data?.result[0];
  const onDisconnect = () => {
    if (data) {
      disconnect({ storeName: data.result[0].ebayUsername }).unwrap().then((res) => {
        SetAccessToken(res.result.token);
        dispatch(setUserAccessToken(res.result.token));
        navigate(PATH.managementPanel.storeAnalytics);
      });
    }
  };

  return (
    <LoadingPage loading={isFetching || storeFetching}>
      <Header
        leftContent={
          <>
            <HeaderTab
              title="Store Analytics"
              className="max-w-[126px]"
              isActive={true}
            />
            <div className="flex items-center justify-center gap-[10px]">
              <div className="flex items-center justify-center w-[30px] h-[30px]">
                <Store className="fill-smoothGreen dark:fill-paradiseBlue w-[17px] h-[15px]" />
              </div>
              <div className="text-[16px] font-medium dark:text-grey100">{data?.result[0]?.ebayUsername}</div>
            </div>
          </>
        }
        rightContent={
          <>
            <Button
              title={t('DisconnectStore')}
              handleClick={modal.open}
              loading={isLoading}
              height="h-9.5"
              className="max-w-[150px] dark:bg-deepSpace900"
            />
            <HeaderIcon
              variant={HeaderVariants.settings}
              tooltipTitle={t('StoreSettings').toString()}
              handleClick={() => navigate(PATH.quickSettings.storeSettings)}
            />
          </>
        }
        leftContentClassName="w-full"
        rightContentClassName="w-full justify-end"
      />
      {storeDetails?.result.scanned === "false" ?
        <StoreUpdate />
        :
        <div className="dark:text-grey100">
          <div className="flex max-lg:flex-col gap-9 mb-5">
            <div className="flex flex-col gap-4.5 min-w-[294px]">
              <StoreStatsCard
                title={t('SellTrough')}
                Icon={Rocket}
                value={formatPercent(storeDetails?.result.sellThrough || 0)}
              />
              <StoreStatsCard
                title={t('TotalRevenue')}
                Icon={Shipping}
                value={formatCurrency(store?.thirtyDaysEarnings || 0, CURRENCY.none,
                  storeDetails?.result.currencySymbol as CURRENCY_SIGNS)}
              />
              <StoreStatsCard
                title={t('SoldItems')}
                Icon={Hammer}
                value={formatNumber(store?.thirtyDaySales || 0)}
              />
              <StoreStatsCard
                title={t('ActiveListings')}
                Icon={Menu}
                value={formatNumber(store?.totalActiveListings || 0)}
              />
              <StoreStatsCard
                title={t('AveragePrice')}
                Icon={Dollar}
                value={formatCurrency(storeDetails?.result.averagePrice || 0, CURRENCY.none,
                  storeDetails?.result.currencySymbol as CURRENCY_SIGNS)}
              />
            </div>
            <div className="w-full flex flex-col gap-4">
              <SellAndRevenue storeDetails={storeDetails} isFetching={storeFetching} />
              <RushDay storeDetails={storeDetails} isFetching={storeFetching} />
            </div>
          </div>

          <StoreProductList store={store?.ebayUsername || ""} currency={storeDetails?.result.currencySymbol || "$"} />
        </div>}

      <ConfirmDelete
        title="Are you sure you want to disconnect store?"
        helpers={modal}
        onConfirm={onDisconnect}
        confirmLoading={isLoading}
      />
    </LoadingPage>
  );
};

export default StoreAnalytics;
