import { playList } from "types/academy";
import VideoCard from "./VideoCard";
import VideoSlider from "./VideoSlider";

interface Props {
  playList: playList[];
}

const PlayList = ({ playList }: Props) => (
  <>
    {playList.length > 1 ? (
      <div className="flex flex-col w-full w-full min-w-0">
        <>
          {playList.map((item, i) => (
            <div key={i}>
              <div className="px-[25px] py-3 bg-white dark:bg-deepSpace900 dark:text-grey100 rounded-[15px] shadow-lg mb-[20px] font-semibold">
                {item.playlistTitle}
              </div>
              <div>
                <VideoSlider key={i} playList={item.videos} />
              </div>

            </div>
          ))}
        </>
      </div>
    ) : (
      <div className="flex flex-col w-full gap-4">
        {playList.map((item, i) => (
          <div key={i}>
            <div className="px-[25px] py-3 bg-white dark:bg-black900 dark:text-grey100 rounded-[15px] shadow-lg mb-5 font-semibold">
              {item.playlistTitle}
            </div>
            <div className="grid grid-cols-3 gap-4 max-[1465px]:grid-cols-2 max-[1127px]:grid-cols-1">
              {item.videos.map((video, k) => (
                <VideoCard
                  key={k}
                  title={video.title}
                  source={video.source}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    )}
  </>
);

export default PlayList;
