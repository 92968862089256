
import UpcomingEvents from "components/upcomingEvents"
import { useAppSelector } from "hooks/useAppSelector"
import LoadingPage from "pages/Loading"
import SellerSettings from "pages/NewDashboard/components/SellerSettings"
import { useEffect, useState } from "react"
import { useGetInsightsPreferencesQuery } from "store/api/newDashboardApi"
import { DASHBOARD_MARKET_PLACES } from "store/slices/marketplaceSlice"
import { MARKET_PLACES } from "types/sellerSettings"
import ShopifyMarketInsightsHeader from "./components/Header"
import TrendingProducts from "./components/TrendingProducts"
import TrendingStores from "./components/TrendingStores"

const ShopifyMarketInsights = () => {
    const { isFetching, isSuccess, refetch } = useGetInsightsPreferencesQuery(undefined);
    const { dashboardMarketplace } = useAppSelector((state) => state.marketplaceSlice);
    const { marketplace } = useAppSelector(
        (state) => state.sellerSettings
    );

    const [loading, setLoading] = useState(true)
    const [refetchCount, setRefetchCount] = useState(5)

    useEffect(() => {
        if (dashboardMarketplace === DASHBOARD_MARKET_PLACES.shopify && marketplace !== MARKET_PLACES.shopify) {
            refetch();
        }
    }, [])

    useEffect(() => {
        if (loading && !isFetching && isSuccess) {
            if (refetchCount > 0)
                if (dashboardMarketplace === DASHBOARD_MARKET_PLACES.shopify && marketplace !== MARKET_PLACES.shopify) {
                    setRefetchCount(refetchCount - 1)

                    refetch();
                }
                else {
                    setLoading(false)
                }
            else {
                setLoading(false)
            }
        }

    }, [isFetching, isSuccess])

    return (
        <LoadingPage loading={isFetching || loading}>
            <div>
                <ShopifyMarketInsightsHeader />
                <div className="flex gap-4 w-full max-xl:flex-wrap">
                    <div className="w-[22.36%] max-xl:w-[100%] flex sm:flex-row flex-col xl:flex-col gap-5">
                        <TrendingStores />
                        <div className="xl:hidden w-1/2 max-sm:hidden">
                            <UpcomingEvents isShopify />
                        </div>
                    </div>
                    <div className="w-[53.52%]  max-xl:w-[100%] flex flex-col gap-5">
                        <SellerSettings />
                        <TrendingProducts />
                    </div>
                    <div className="w-[24.12%] max-xl:hidden max-sm:block max-xl:w-[100%] flex flex-col max-md:flex-col max-xl:flex-row gap-5">
                        <UpcomingEvents isShopify />
                    </div>
                </div>
            </div>
        </LoadingPage>
    )
}

export default ShopifyMarketInsights