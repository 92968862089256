import risky_keywords from "assets/data/riskKeywords";
import vero_brands from "assets/data/veroBrands";
import { FindKeywords } from "hooks/useFindKeywords";

const findKeywords: FindKeywords = (name, check) => {
  const vero =
    (name &&
      check &&
      vero_brands.filter((veroKeyword) =>
        (" " + name + " ")
          .toLowerCase()
          .includes(" " + veroKeyword.toLowerCase() + " ")
      )) ||
    [];
  const risky =
    (name &&
      check &&
      risky_keywords.filter((riskKeyword) =>
        (" " + name + " ")
          .toLowerCase()
          .includes(" " + riskKeyword.toLowerCase() + " ")
      )) ||
    [];

  const foundVero = vero.length > 0;
  const foundRisky = risky.length > 0;

  return {
    risky,
    vero,
    foundRisky,
    foundVero,
    noKeywords: !foundVero && !foundRisky,
  };
};

export default findKeywords;
