import { sidebarData } from "assets/data/academy/sidebarData";
import { ReactComponent as Hamburger } from "assets/icons/menu.svg";
import Header from "components/Header";
import HeaderTab from "components/Header/Tab";
import usePlaylist from "hooks/usePlaylist";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import { course, playList } from "types/academy";
import Course from "./components/Course";
import PlayList from "./components/PlayList";
import Sidebar from "./components/Sidebar";

export const academyState = {
  playList: [] as playList[],
  courseList: [] as course,
  type: "video",
  playlistSource: "index",
};

const Academy = () => {
  const navigate = useNavigate();
  const { academy, handleSourceChange, handleTypeChange } =
    usePlaylist(academyState);
  const [smMenuOpen, setSmMenuOpen] = useState(false);

  return (
    <>
      <Header
        className="max-[1200px]:!flex-row"
        leftContent={
          <>
            <HeaderTab title="Academy" isActive={true} tooltipText="Learn more about ZIK and how to improve your business " id="Academy" handleClick={() => { }} className="!min-w-[86px]" />
            <HeaderTab
              title="Tutorials"
              isActive={false}
              handleClick={() => navigate(PATH.tutorials)}
              tooltipText="Learn how to use ZIK tools"
              className="!min-w-[81px]"
              id="Tutorials"
            />
          </>
        }
        rightContent={
          <>
            <div className="hidden max-[1200px]:block" onClick={() => setSmMenuOpen(!smMenuOpen)}>
              <Hamburger className="fill-darkBlue dark:fill-primaryPurple" />
            </div>
          </>
        }
        leftContentClassName="gap-[2.9375rem] max-[1200px]:!flex-row"
      />

      <div className="flex gap-5">
        <Sidebar
          sidebarTitle="Learn more about ZIK VIP group"
          sidebarData={sidebarData}
          hasCtaButton
          handleSourceChange={handleSourceChange}
          playlistSource={academy.playlistSource}
          handleTypeChange={handleTypeChange}
          smMenuOpen={smMenuOpen}
        />

        <>
          {academy.type === "video" ? (
            <PlayList playList={academy.playList} />
          ) : (
            <Course courseList={academy.courseList} />
          )}
        </>
      </div>
    </>
  );
};

export default Academy;
