const cutLongTitle = (title: string, length: number) => {
  if (title.length - 3 >= length) {
    return title.substring(0, length) + "...";
  } else return title;
};

const newCutLongTitle = (title: string, length: number) => {
  if (title.length - 1 > length) {
    return title.substring(0, length - 3) + "...";
  } else return title;
};
export { newCutLongTitle };
export default cutLongTitle;
