import Card from "components/Card";
import InfoTooltip from "components/Card/InfoTooltip";
import CustomRechartsTooltip from "components/CustomRechartsTooltip";
import { THEME_COLORS } from "config/constants";
import { useAppSelector } from "hooks/useAppSelector";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import "styles/scrollbar.css";
import { PriceRangeGraph } from "types/services/pr";
import { formatPercent } from "utils/formatCurrency";
interface Props {
  data: PriceRangeGraph[];
  title?: string;
  tooltipTitle: string;
  infoTitle: string;
  valueType?: "percentage" | "number";
}
const PriceAnalyticsBar: FC<Props> = ({
  data,
  title,
  valueType,
  infoTitle,
  tooltipTitle,
}) => {
  const { t } = useTranslation(['home']);
  const { isDarkMode } = useAppSelector((state) => state.themeSlice);

  return (
    <Card
      className="w-full h-[21.25rem] pt-8 pr-7  text-xs text-darkBlue dark:text-grey100 overflow-y-hidden scrollbar relative"
      rounded="rounded-xl"
      bordered
    >
      <div className="min-w-[30rem]">
        {title ? (
          <h5 className="text-darkBlue dark:text-grey100 text-base font-bold  ml-8 mb-7">
            {t(title)}
          </h5>
        ) : (
          ""
        )}
        <InfoTooltip title={infoTitle} className="absolute right-8 top-8" />
        <ResponsiveContainer width="100%" height={236}>
          <BarChart
            data={data}
            margin={{
              top: 10,
              right: 0,
              left: 0,
              bottom: 12,
            }}
          >
            <CartesianGrid stroke={THEME_COLORS[isDarkMode ? "deepSpace800" : "secondary"]} strokeWidth="2" vertical={false} />
            <XAxis
              dataKey="device"
              strokeWidth={0}
              style={{
                fill: THEME_COLORS[isDarkMode ? "grey100" : "darkBlue"],
                textAnchor: "middle",
              }}
              tickLine={false}
              tickMargin={15}
            />
            <YAxis
              strokeWidth={0}
              tickMargin={valueType === "percentage" ? 8 : 16}
              style={{ fill: THEME_COLORS[isDarkMode ? "grey100" : "darkBlue"] }}
              tickFormatter={(label) =>
                valueType === "percentage" ? formatPercent(label) : label
              }
            />
            <Tooltip
              content={
                <CustomRechartsTooltip
                  valueLabel={tooltipTitle}
                  valueType={valueType}
                />
              }
              cursor={false}
              wrapperStyle={{ outline: "none" }}
            />
            <Bar
              dataKey="geekBench"
              fill={isDarkMode ? "#09665C" : "#B0F8EF"}
              strokeWidth={2}
              stroke={THEME_COLORS[isDarkMode ? "grey100" : "darkBlue"]}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Card>
  )
};

export default PriceAnalyticsBar;
