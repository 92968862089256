import { useAppSelector } from "hooks/useAppSelector";
import PriceAnalyticsBar from "../charts/PriceAnalyticsBar";

const PriceAnalyticsContent = () => {
  const { priceRangeGraphOne, priceRangeGraphTwo } = useAppSelector(
    (state) => state.prSlice.ebayData.result
  );

  return (
    <div className="flex flex-col gap-[15px]">
      <PriceAnalyticsBar
        data={priceRangeGraphOne || []}
        title="PriceRangeAnalytics"
        infoTitle="See the amount of sales each listing has, based on the price that the item sold for. Use this to work out how to price your product."
        tooltipTitle="Sales"
      />
      <PriceAnalyticsBar
        data={priceRangeGraphTwo || []}
        title="PriceSellTrough"
        infoTitle="See how the price range affects how fast items sell. For example, a sell-through rate of 500% means that on average each listing at the chosen price range has gained 5 sales."
        tooltipTitle="Sell-through"
        valueType="percentage"
      />
    </div>
  );
};

export default PriceAnalyticsContent;
