import { ReactComponent as LogoHovered } from "assets/icons/logo-hovered.svg";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { ReactComponent as Logout } from "assets/icons/logout.svg";
import { ReactComponent as ManagementPanel } from "assets/icons/management-panel.svg";
import { ReactComponent as MarketplaceSwitch } from "assets/icons/marketplace-switch.svg";
import { ReactComponent as Light } from "assets/icons/switch-day-custom.svg";
import { ReactComponent as Night } from "assets/icons/switch-night-custom.svg";
import DarkModeSwitch from "components/DarkModeSwitch";
import Scrollbar from "components/Scrollbar";
import Tooltip from "components/Tooltip";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useAppSelector } from "hooks/useAppSelector";
import useDecodeToken from "hooks/useDecodeToken";
import useLogout from "hooks/useLogout";
import useOutsideClick from "hooks/useOutsideClick";
import useSidebarData from "hooks/useSidebarData";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { PATH } from "routes/path";
import { queryKeys } from "store/api/queryKeys";
import { GetSavedCity, GetSavedLang, SetSavedCity } from "utils/localStorage";
import ExpandToggle from "./ExpandToggle";
import { sidebarCountries } from "./SidebarConfig";
import SidebarItem from "./SidebarItem";
import SubmenuCities from "./SubmenuCities";

interface Country {
  location: { name: string; tz_id: string; country: string };
  current: {
    temp_c: number;
    condition: { text: string; icon: string; code: number };
  };
}
dayjs.extend(utc);
dayjs.extend(timezone);

const Sidebar = () => {
  const { loading, isDarkMode, isExpand } = useAppSelector((state) => state.themeSlice)
  const logout = useLogout();
  const [submenuOpen, setSubmenuOpen] = useState<string | null>(null);
  const [selectedCity, setSelectedCity] = useState("");
  const location = useLocation();
  const { i18n } = useTranslation(['home']);
  const userDetails = useDecodeToken();
  const { store } = userDetails;
  const onOpenChange = (newVal: string | null) => setSubmenuOpen(newVal);
  const [ref] = useOutsideClick<HTMLDivElement>(() => onOpenChange(null));
  const [fetchIpAddress, setIp] = useState();
  const { dashboardMarketplace } = useAppSelector((state) => state.marketplaceSlice);
  const [getLocalCountry, setLocalCoutry] = useState<Country>();
  const [, setTime] = useState("");
  const [fetchWeather, setWeather] = useState<Country>();
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const isAffiliate = environment === "affiliate" ? true : false;

  const { sidebarData, sidebarDataChange } = useSidebarData(dashboardMarketplace)

  useEffect(() => {
    sidebarDataChange(dashboardMarketplace);
  }, [dashboardMarketplace])

  useEffect(() => {
    const interval = setInterval(() => {
      if (fetchWeather !== undefined) {
        setTime(dayjs().tz(fetchWeather.location.tz_id).format("HH:mm"));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [fetchWeather]);

  const handleClose = () => {
    onOpenChange(null);
  };
  const checkCountry = (country: string) =>
    country !== "United States of America" &&
    country !== "United Kingdom" &&
    country !== "Germany" &&
    country !== "Philippines" &&
    country !== "Portugal" &&
    country !== "Israel";

  useEffect(() => {
    let storedLang = GetSavedLang();
    if (storedLang) {
      i18n.changeLanguage(storedLang);
    }
    //TODO - use here redux query toolkit
    const fetchData = async () => {
      try {
        const response = await fetch(queryKeys.ipify);
        const data = await response.json();
        setIp(data.ip);
        let storedData = GetSavedCity();
        if (storedData) {
          const weather = await fetchWeatherData(storedData);
          setSelectedCity(storedData);
          setWeather(weather);
          const _data = await fetchWeatherData(data.ip);
          if (checkCountry(_data.location.country)) {
            setLocalCoutry(_data);
          }
        } else {
          const _data = await fetchWeatherData(data.ip);
          if (checkCountry(_data.location.country)) {
            setLocalCoutry(_data);
          }
          setWeather(_data);
        }
      } catch (error) { }
    };

    if (fetchIpAddress === undefined) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchWeatherData = async (ip: string) => {
    try {
      const response = await fetch(queryKeys.getWhether + ip);
      const data = await response.json();

      return data;
    } catch (error) { }
  };
  const handleSelectCity = async (id: string) => {
    if (id !== "USA") {
      setSelectedCity(id);
      onOpenChange(null);
      const weather = await fetchWeatherData(id);
      setWeather(weather);
      SetSavedCity(id);
    }
  };

  useEffect(() => {
    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, selectedCity]);

  const hideMyCompetitors = (itemPath: string) => {
    return (location.pathname === PATH.competitorResearch.root || location.pathname === PATH.competitorResearch.ebay) && ((dashboardMarketplace === "ebay" && location.search !== "?marketplace=aliexpress") ||
      (dashboardMarketplace === "aliexpress" && location.search === "?marketplace=ebay")) && itemPath === PATH.myCompetitors || false
  }

  return (
    <Scrollbar
      as="aside"
      className={`h-full pt-5 fixed z-50  overflow-y-visible`}
      variant="transparent"
    >
      <div
        className="w-[74px] ml-[0.6875rem]"
      >
        <div
          className="w-14"
        >
          <div
            className={`transition-width duration-300 relative bg-white dark:bg-deepSpace900 pb-[35px] pr-[3px] rounded-[15px] gap-[5px] flex flex-col items-left pl-[10px] justify-center py-[1.125rem] mb-[15px] ${isExpand ? "w-[193px]" : "w-14"}`}
          >
            <div className={`absolute top-0 left-0 h-full w-[56px] shadow-shadow2 rounded-[15px] dark:bg-black900`}></div>
            {process.env.REACT_APP_ENVIRONMENT !== 'affiliate' ? (
              <>
                <SidebarItem
                  Icon={MarketplaceSwitch}
                  path={"marketplaceswitch"}
                  smIconStyles="w-[15.17px] h-[15.17px]"
                  iconStyles="w-[24px] h-[22px]"
                  title="Marketplace"
                  translate="Change Marketplace"
                  isExpand={isExpand}
                  isOpen={submenuOpen === "Marketplace"}
                  handleChange={() => onOpenChange("Marketplace")}
                  handleClose={handleClose}
                  marketplace
                  hasBottomDivider
                />
                <SidebarItem
                  Icon={Logo}
                  path={dashboardMarketplace === "shopify" ? PATH.shopify.marketInsights : PATH.dashboard.root}
                  smIconStyles="w-[15.17px] h-[15.17px]"
                  iconStyles="w-[24px] h-[22px]"
                  IconHovered={LogoHovered}
                  title="Market Insights"
                  translate="Market Insights Dashboard"
                  isExpand={isExpand}
                />
                {sidebarData.map((item) => {
                  return hideMyCompetitors(item.path)
                    ?
                    ""
                    :
                    <SidebarItem
                      {...item}
                      key={item.path}
                      submenu={item.submenu}
                      quickSettings={item.quickSettings}
                      isOpen={submenuOpen === item.title}
                      handleChange={() => onOpenChange(item.title)}
                      handleClose={handleClose}
                      translate={item.translate}
                      store={store}
                      isExpand={isExpand}
                    />

                })}
              </>
            ) : (
              <>
                <SidebarItem
                  Icon={Logo}
                  path={PATH.managementPanel.affiliateManager}
                  iconStyles="w-[24px] h-[22px]"
                  smIconStyles="w-[15.17px] h-[15.17px]"
                  IconHovered={LogoHovered}
                  title="Dashboard"
                  translate="Dashboard"
                  isExpand={isExpand}
                />
                <SidebarItem
                  Icon={ManagementPanel}
                  smIconStyles="w-[15.17px] h-[15.17px]"
                  path={PATH.quickSettings.account}
                  iconStyles="w-[24px] h-[22px]"
                  title="Management Panel"
                  translate="Management Panel"
                  isExpand={isExpand}
                />
              </>
            )}
            <SidebarItem
              Icon={Logout}
              translate="Log out"
              path="logout"
              smIconStyles="w-[13px] h-[15.17px]"
              iconStyles="w-[18px] h-[21px]"
              handleCustomClick={logout}
              title="Log out"
              isExpand={isExpand}
            />
            <SidebarItem
              Icon={isDarkMode ? Night : Light}
              translate="Toggle Light Mode/Dark Mode"
              path="themeswitch"
              smIconStyles="w-[13px] h-[15.17px] text-canaryYellow dark:text-paradiseBlue"
              iconStyles="w-[14px] h-[14px] text-canaryYellow dark:text-paradiseBlue"
              handleCustomClick={() => { }}
              title="Toggle between light and dark mode"
              isExpand={isExpand}

            />
            <ExpandToggle />
          </div>


          {!isAffiliate && userDetails.active_trail === "True" && dashboardMarketplace !== "shopify" ?
            <div data-tooltip-id={userDetails.days_for_trial}
              className="bg-primaryPurple dark:bg-purple500 rounded-full w-[36px] h-[36px] border-2 border-darkBlue m-auto text-xl text-white text-center flex items-center justify-center mb-[15px]"
            >
              <Tooltip place="left" title="Reminder: Your Trial is Expiring Soon! Your account will be charged for your chosen plan. If you do not wish to continue, please cancel your subscription in account settings." anchorId={userDetails.days_for_trial} />
              <span className="">
                {userDetails.days_for_trial}
              </span>
            </div>
            :
            !isAffiliate && userDetails.shopify_active_trail === "True" && dashboardMarketplace === "shopify" ?
              <div data-tooltip-id={userDetails.shopify_days_for_trial}
                className="bg-primaryPurple dark:bg-purple500 rounded-full w-[36px] h-[36px] border-2 border-darkBlue m-auto text-xl text-white text-center flex items-center justify-center mb-[15px]"
              >
                <Tooltip place="left" title="Reminder: Your Trial is Expiring Soon! Your account will be charged for your chosen plan. If you do not wish to continue, please cancel your subscription in account settings." anchorId={userDetails.shopify_days_for_trial} />
                <span className="">
                  {userDetails.shopify_days_for_trial}
                </span>
              </div>
              : ""

          }
        </div>


        <div className="w-[56px] relative">
          <div className={`mb-[6px] flex justify-center ${isExpand ? "invisible opacity-0 absolute z-[-1]" : "visible opacity-1"}`}>
            <DarkModeSwitch />
          </div>
          <div
            className={`flex items-center flex-col text-xs cursor-pointer ${submenuOpen === "city" ? "pointer-events-none" : ""
              }`}
            onClick={() => onOpenChange("city")}
          >
            {fetchWeather && (
              <div className={`text-center ${loading ? "text-white" : "text-darkBlue dark:text-lilac400"}`}>
                <img src={fetchWeather.current?.condition?.icon} alt="" className="w-9 h-[auto] mx-auto" />
                <div >
                  <p>
                    {fetchWeather.location.country === "United States of America"
                      ? (sidebarCountries.find((item) => item.id === "USA")?.cities?.find((city) => city.id == selectedCity)?.title ?? "USA")
                      : fetchWeather.location.country}
                  </p>
                  <p>{fetchWeather.current.temp_c} &deg;C</p>
                  <p>{dayjs().tz(fetchWeather.location.tz_id).format("HH:mm")}</p>
                </div>

              </div>
            )}
            {submenuOpen === "city" ? (
              <div ref={ref}>
                <SubmenuCities
                  selectedCity={selectedCity}
                  handleSelectCity={handleSelectCity}
                  localCountry={getLocalCountry}
                />
              </div>
            ) : (
              ""
            )}
          </div>

        </div>
      </div>
    </Scrollbar>

  );
};

export default Sidebar;
