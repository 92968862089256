import { useEffect, useState } from "react";
import { HISTORY_KEYS, SetHistory } from "utils/localStorage";
import { GetHistory, RemoveHistoryItem } from "../utils/localStorage";

const useSearchHistory = (type: HISTORY_KEYS, search: string) => {
  const [historyList, setHistoryList] = useState<string[]>([]);

  const add = () => {
    const findItem = historyList?.includes(search);
    if (!findItem && search.length > 0) {
      SetHistory(type, search);
      setHistoryList((prev) => [search, ...prev]);
    }
  };

  const remove = (item: string) => {
    setHistoryList(
      (prev) => prev && prev.filter((history: string) => item !== history)
    );
    RemoveHistoryItem(type, item);
  };

  const removeAll = () => {
    setHistoryList([]);
    RemoveHistoryItem(type, "", true);
  };

  useEffect(() => {
    setHistoryList(GetHistory(type));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { history: historyList, add, remove, removeAll };
};

export default useSearchHistory;
