import { ReactComponent as IconYoutube } from "assets/icons/video.svg";
import Scrollbar from "components/Scrollbar";
import { course } from "types/academy";

interface Props {
  courseList: course;
  handleVideoChange: (url: string, title: string, lesson: string) => void;
  currentVideo: string;
}

const CourseSidebar = ({
  courseList,
  handleVideoChange,
  currentVideo,
}: Props) => (
  <div className="w-[310px] bg-darkBlue dark:bg-deepSpace900 py-[21px] rounded-10 shadow-lg shrink-0 h-[calc(100vh-100px)] max-[1000px]:w-full max-[1000px]:h-[400px]">
    <Scrollbar className="pl-[21px] pr-2 mr-[5px] h-full">
      <div className="flex flex-col gap-7">
        {courseList.map((item, i) => (
          <div key={i}>
            <div className="text-smoothGreen dark:text-paradiseBlue uppercase text-center">
              {item.lesson}
            </div>
            <IconYoutube className="fill-white dark:fill-paradiseBlue w-6 my-[11px] mx-auto" />
            <div>
              {item.videos.map((video, k) => (
                <>
                  <div
                    key={k}
                    onClick={() =>
                      handleVideoChange(video.source, video.name, item.lesson)
                    }
                    className={` flex justify-center items-center ${currentVideo === video.source ? "border-smoothGreen dark:border-paradiseBlue hover:border-white" : "border-transparent"
                      } text-white dark:text-grey100 h-12 w-full px-2.5  leading-[1] text-center text-[13px] cursor-pointer dark:hover:text-black900 dark:hover:bg-paradiseBlue rounded-[50px] hover:bg-white border-2 hover:text-smoothGreen`}
                  >
                    {video.name}
                  </div>
                  {k !== item.videos.length - 1 && (
                    <div className="h-[2px] w-full bg-secondary my-[11px] opacity-50" />
                  )}
                </>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Scrollbar>
  </div>
);

export default CourseSidebar;
