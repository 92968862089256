import { mySearchCols } from "assets/tableColumns/mySearches";
import Card from "components/Card";
import UpdateSearch from "components/SaveSearch/UpdateSearch";

//Icons
import Search from "components/Search";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableStyles } from "components/Table/Table";
import useDebaunceSearch from "hooks/useDebaunceSearch";
import useModal from "hooks/useModal";
import usePagination from "hooks/usePagination";
import useSort from "hooks/useSort";
import useTable from "hooks/useTable";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  useLoadWatchListQuery,
  useWatchListDeleteMutation
} from "store/api/watchListApi";
import { WatchList } from "types/services/watchList";

const tableStyles: TableStyles = {
  px: "px-0",
  style: "mb-8",
  body: {
    rowHeight: "h-[55px]",
    contHeight: "h-[37px]",
  },
  empty: {
    rowHeight: "h-[80px]",
    contHeight: "h-[37px]",
  },
};

const AliexpressCompetitors = () => {
  const modal = useModal();
  const { delayedValue, immediateValue, onChange } = useDebaunceSearch();
  const { t } = useTranslation(['home']);
  const [selectedItem, setSelectedItem] = useState<WatchList | null>(null);
  const pagination = usePagination(0, 10);
  const [deleteItem, { isLoading: deleteLoading, isSuccess: deleteSuccess }] =
    useWatchListDeleteMutation();
  const { handleSort, isAsc, sort } = useSort();
  useEffect(() => {
    if (deleteSuccess) {
      toast.success("User removed from watchlist.");
    }
  }, [deleteSuccess]);
  const { data, isFetching } = useLoadWatchListQuery({
    start: pagination.current * 10,
    sortByColumnName: sort.column,
    sortByColumnDirection: isAsc ? "asc" : "des",
    filterKeywords: delayedValue,
    type: 4,
  });

  useEffect(() => {
    if (data) {

      pagination.setTotal(data.result.totalRecords);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.result.totalRecords]);

  const onAction = (item: WatchList, isEdit?: boolean) => {
    setSelectedItem(item);
    if (isEdit) {
      modal.open();
    } else {
      deleteItem({ id: item.watchListId.toString() });
    }
  };
  const { table } = useTable({
    data: data?.result.items || [],
    dataDeps: [data],
    columnDeps: [deleteLoading],
    columns: mySearchCols(
      onAction,
      deleteLoading,
      selectedItem,
      handleSort,
      true
    ),
    search: true,
    pagination: true,
    sort: true,
    initialPageSize: 10,
  });

  return (
    <Card className="pt-4.5 pb-6 px-4 min-h-[400px]">
      <div className="flex justify-between items-center w-full mb-4">
        <h5 className="font-bold ml-[37px] dark:text-grey100">{t('AliExpressCompetitorList')}</h5>
        <Search
          search={immediateValue}
          handleChange={onChange}
          size="sm"
          placeholder="Enter keyword"
          htmlFor="aliSearch"
          maxWidth="301px"
        />
      </div>
      <Table table={table} styles={tableStyles} isLoading={isFetching} sort={sort} />
      <Pagination pagination={pagination} labeled />
      {selectedItem && <UpdateSearch modal={modal} item={selectedItem} />}
    </Card>
  );
};

export default AliexpressCompetitors;
