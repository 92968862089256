import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg";
import { ReactComponent as Date } from "assets/icons/exp-date.svg";
import Button from "components/Button";
import Select from "components/Select";
import dayjs from "dayjs";
import useOutsideClick from "hooks/useOutsideClick";
import { FC, useEffect, useState } from "react";
import { PatternFormat } from "react-number-format";
import { formatDate } from "utils/formatTime";
import DatePickerOpen from "./DatePickerOpen";

interface SelDates {
    start: Date | null;
    end: Date | null;
}
interface Props {
    selDates: SelDates;
    setSelDates: React.Dispatch<React.SetStateAction<SelDates>>;
    dateTrackingStarted?: string | null;
}



const dateFormat = "DD/MM/YYYY"

// Error Types - "Future Date Not Allowed" | "Date should be valid" | "Start date should be before end Date"
const DateRangeSelect: FC<Props> = ({ selDates, setSelDates, dateTrackingStarted }) => {
    const [savedDateRange, setSavedDateRange] = useState("30");
    const [dateRange, setDateRange] = useState(savedDateRange);
    const trackingStartedIn30Days =
        dateTrackingStarted ? dayjs().diff(dayjs(dateTrackingStarted), "days") <= 30 : false;
    const [dateErrors, setDateErrors] = useState<{
        start: string | false, end: string | false
    }>({ start: false, end: false })
    const [newDates, setNewDates] = useState<SelDates>({
        start: null,
        end: null
    });
    const [dateInpVal, setDateInpVal] = useState({
        start: dayjs().subtract(30, "days").format(dateFormat),
        end: dayjs().format(dateFormat)
    });
    const getDateTrackingStartedDiff = (days: number) => {
        return dateTrackingStarted && dayjs().diff(dayjs(dateTrackingStarted), "days") < days ? "none" : days.toString()
    }
    const salesDateRange = [
        { value: getDateTrackingStartedDiff(30), text: "Last 30 Days", isActive: false },
        { value: getDateTrackingStartedDiff(21), text: "Last 21 Days" },
        { value: getDateTrackingStartedDiff(14), text: "Last 14 Days" },
        { value: getDateTrackingStartedDiff(7), text: "Last 7 Days" },
        { value: "custom", text: "Custom Date" },
    ]

    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(prev => !prev);
        setDateErrors({ start: false, end: false })
    };

    const handleCloseUnsaved = () => {
        setIsOpen(false);
        setNewDates(selDates);
        setDateRange(savedDateRange);
        if (savedDateRange !== "custom") {
            setDateInpVal({ start: "", end: "" })
        }
        setDateErrors({ start: false, end: false })
    }

    const onDateRangeChange = (val: string) => {
        setDateRange(val);
        if (val !== "custom") {
            setDateInpVal({
                start: dayjs().subtract(Number(val), "days").format(dateFormat),
                end: dayjs().format(dateFormat)
            })
        }

    }

    useEffect(() => {
        if (trackingStartedIn30Days) {
            const dates = {
                start: dayjs(dateTrackingStarted).toDate(),
                end: dayjs().toDate()
            }
            setDateRange("custom");
            setSavedDateRange("custom");
            setDateInpVal({
                start: dayjs(dateTrackingStarted).format(dateFormat),
                end: dayjs().format(dateFormat)
            })
            setSelDates(dates);
            setNewDates(dates);
        }
    }, [trackingStartedIn30Days])

    useEffect(() => {
        if (savedDateRange !== "custom") {
            setSelDates({
                start: dayjs().subtract(Number(savedDateRange), "days").toDate(),
                end: dayjs().toDate()
            })
        }
    }, [savedDateRange])

    const formattedDate = (value: string) => dayjs(value.substring(3, 6) + value.substring(0, 3) + value.substring(6));

    const dateInpBlur = (type: "start" | "end") => {
        const isInValidLength = dateInpVal[type].includes(" ");
        const val = dateInpVal[type];
        const isNotFuture = formattedDate(val).isBefore(dayjs());
        const endBiggerThenStart = formattedDate(dateInpVal.start).isBefore(formattedDate(dateInpVal.end)) || formattedDate(dateInpVal.start).isSame(formattedDate(dateInpVal.end));
        if (!isInValidLength && isNotFuture && endBiggerThenStart) {
            setNewDates(prev => ({
                ...prev,
                [type]: formattedDate(val).toDate()
            }))

            setDateErrors(prev => ({
                ...prev,
                [type]: false
            }))
        } else {
            const errorMsg = isInValidLength ? "Insert full date" : "Insert valid date"
            if (!endBiggerThenStart) {
                setDateErrors(prev => ({
                    ...prev,
                    end: errorMsg
                }))
            } else {
                setDateErrors(prev => ({
                    ...prev,
                    [type]: errorMsg,
                }))
            }
        }
    }
    const onDateInpValChange = (newVal: string, field: "start" | "end") => {
        setDateInpVal(prev => ({
            ...prev,
            [field]: newVal
        }));

        if (dateRange !== "custom") {
            setDateRange("custom");
        }
    }

    const onChange = (dates: [Date, Date]) => {
        const [start, end] = dates;

        setNewDates({
            start,
            end
        });
        setDateInpVal({
            start: formatDate(start),
            end: formatDate(end)
        })
        setDateRange("custom");
        setDateErrors({
            start: false,
            end: false
        })
    };
    const onCancel = () => {
        handleCloseUnsaved();
    }


    useEffect(() => {
        if (dateRange !== "custom") {
            setNewDates({
                start: null,
                end: null
            });
        }
    }, [dateRange]);

    const [ref] = useOutsideClick<HTMLDivElement>(onCancel);


    const onSaveDate = () => {
        if (dateErrors.start || dateErrors.end) {

        } else {
            setSavedDateRange(dateRange)
            setSelDates(newDates);
            setIsOpen(false)
        }
    }
    const todayTitle = "Today"
    const getDateForTitle = (date: Date | null) => dayjs(date).isSame(dayjs(), "day") ? todayTitle : formatDate(date)
    const datePickerFormat = "##/##/####"
    const datePickerInpStyles = "text-sm border-2 border-solid rounded-[7px] pl-1.5 h-[35px] border-secondary focus:text-primaryPurple focus:border-primaryPurple hover:text-primaryPurple hover:border-primaryPurple dark:hover:border-purple500 dark:border-grey100 dark:focus:text-purple500 dark:focus:border-purple500 dark:bg-deepSpace900 dark:hover:text-purple500"
    const datePickerTitle = savedDateRange === "custom" ?
        (getDateForTitle(selDates.start) === todayTitle && getDateForTitle(selDates.start) === todayTitle) ?
            todayTitle :
            getDateForTitle(selDates.start) + " - " + getDateForTitle(selDates.end)
        : `Last ${savedDateRange} Days`;

    return (
        <div ref={ref} className={`${savedDateRange === "custom" ? "max-w-[396px]" : "max-w-[330px]"} w-full relative dark:text-grey100`}>
            <div className="bg-white dark:bg-black900 dark:border-grey100 flex items-center w-fit h-14 border-2 border-secondary rounded-[10px]  px-[13px] w-full">
                <Date className="fill-darkBlue dark:fill-grey100 mr-4" />
                <span className="text-sm font-bold mr-[11px] dark:text-grey100">
                    Sales Date Range
                </span>
                <button onClick={handleOpen} className={`group flex items-center justify-between ${savedDateRange === "custom" ? "max-w-[200px]" : "max-w-[134px]"} h-9 relative w-full 
                    after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-full after:h-[3px] after:rounded-full 
                    ${isOpen ? "after:bg-primaryPurple" : "after:bg-darkBlue hover:after:bg-primaryPurple dark:after:bg-grey100 dark:hover:after:bg-purple500"}`}>
                    <span
                        className={`text-sm font-medium  
                            ${isOpen ? "text-primaryPurple dark:text-purple500" : "group-hover:text-primaryPurple dark:group-hover:text-purple500"}`}>{datePickerTitle}</span>
                    <div className="flex items-center justify-center w-4 h-4">
                        <ArrowDown className={`w-2.5 h-1.5 transition-transform duration-600  ${isOpen ? "rotate-180 fill-primaryPurple" : "group-hover:fill-primaryPurple fill-darkBlue dark:fill-grey100"} `} />
                    </div>
                </button>
                {isOpen ? (
                    <div className="w-[323px] bg-neutral2 absolute top-[68px] left-[0px] rounded-10 border-2 border-secondary dark:bg-grey700 dark:border-grey500 z-10 py-2 px-[13px]">
                        <div className="flex items-center bg-white border-2 border-secondary gap-[11px] py-2 px-[13px] rounded-10 mb-4 dark:bg-black900 dark:border-grey100">
                            <div className="flex items-center justify-center w-6 h-6">
                                <Date className="fill-darkBlue dark:fill-grey100 w-3.5 h-3.5 dark:fill-grey100" />
                            </div>
                            <Select
                                noDisabledOptionTooltip
                                value={dateRange}
                                options={salesDateRange}
                                handleChange={onDateRangeChange}
                                btnClassName="rounded-10 !h-9 w-full !pb-1.5"
                                className="w-full"
                                listWrapperClassName="!top-[44px]"
                            />
                        </div>
                        <div className="flex justify-between">
                            <div className="flex flex-col gap-2.5 max-w-[118.5px] relative">
                                <label htmlFor="startDate" className="text-sm font-medium dark:text-grey200">Start Date</label>
                                <PatternFormat
                                    id="date-input"
                                    format={datePickerFormat}
                                    className={`${datePickerInpStyles} ${dateErrors.start ? "!border-errorRed" : ""}`}
                                    placeholder="22/02/2024"
                                    onBlur={(e) => dateInpBlur("start")}
                                    value={dateInpVal.start || ""}
                                    onChange={(e) => onDateInpValChange(e.target.value, "start")}
                                />
                                {dateErrors.start ? (<span className="text-xs text-errorRed absolute -bottom-[20px]">{dateErrors.start}</span>) : ""}

                            </div>

                            <div className="flex flex-col gap-2.5 max-w-[118.5px] relative">
                                <label htmlFor="endDate" className="text-sm font-medium dark:text-grey200">End Date</label>
                                <PatternFormat
                                    id="date-input"
                                    format={datePickerFormat}
                                    className={`${datePickerInpStyles} ${dateErrors.end ? "!border-errorRed" : ""}`}
                                    placeholder="22/02/2024"
                                    onBlur={(e) => dateInpBlur("end")}
                                    value={dateInpVal.end || ""}
                                    onChange={(e) => onDateInpValChange(e.target.value, "end")}
                                />
                                {dateErrors.end ? (<span className="text-xs text-errorRed absolute -bottom-[20px]">{dateErrors.end}</span>) : ""}
                            </div>

                        </div>
                        <div className="flex w-full justify-center mb-2.5">
                            <DatePickerOpen
                                selDates={dateRange === "custom" ? newDates : {
                                    start: null,
                                    end: null
                                }} onDateChange={onChange}
                                dateTrackingStarted={dateTrackingStarted} />
                        </div>
                        <div className="flex items-center gap-[15px]">
                            <Button title="Cancel" height="h-9" className="bg-white dark:bg-deepSpace900" handleClick={onCancel} />
                            <Button
                                title="Save Date"
                                height="h-9"
                                className="bg-darkBlue !font-bold dark:bg-lilac400 !border-darkBlue hover:!border-smoothGreen dark:hover:!border-paradiseBlue dark:!border-lilac400"
                                color="smoothGreen"
                                titleClassName="text-smoothGreen dark:text-darkBlue !font-bold group-hover:!text-darkBlue"
                                handleClick={onSaveDate}
                            />
                        </div>
                    </div>
                ) : ""}
            </div>
        </div>

    )
}

export default DateRangeSelect