import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";
import { ReactComponent as Dollar } from "assets/icons/dollar.svg";
import { ReactComponent as Globe } from "assets/icons/globe.svg";
import { ReactComponent as Sales } from "assets/icons/sales.svg";
import Card from "components/Card";
import HoveredIcon from "components/HoveredIcon";
import Loading from "components/Loading";
import ProductNameCell from "components/ProductNameCell";
import Scrollbar from "components/Scrollbar";
import Tooltip from "components/Tooltip";
import { FC } from "react";
import { Link } from "react-router-dom";
import { PATH } from "routes/path";
import { useGetEbayWeeklyBestSellersQuery } from "store/api/dashboardApi";
import { formatCurrency, formatNumber } from "utils/formatCurrency";
import { makeQueryParams } from "utils/makeQueryParams";

interface Props {
  className?: string;
}
const ProductList: FC<Props> = ({ className }) => {
  const { data, isFetching } = useGetEbayWeeklyBestSellersQuery();

  return (
    <Card bordered className={`p-2.5 pr-1.5 ${className}`}>
      {isFetching ? (
        <div className="flex items-center justify-center h-[12.45rem]">
          <Loading fill="fill-primaryPurple dark:fill-purple500" height={48} width={48} />
        </div>
      ) : (
        <div className=" w-full pt-3.5">
          <div className=" relative z-50 mb-3.5 mr-4">
            <div className="flex  items-center text-xs text-darkBlue dark:text-grey100 sticky top-0 bg-white dark:bg-black900">
              <div className="pl-4 my-auto w-[52%]">
                <p className="text-sm font-bold">Weekly Best Sellers</p>
              </div>
              <div className="w-[16%] pl-2">
                <CompetitorResearch
                  className="fill-darkBlue dark:fill-grey100 w-4"
                  data-tooltip-id="ebayUsername"
                />
                <Tooltip title="Seller" anchorId="ebayUsername" />
              </div>
              <div className="w-[16%]">
                <Globe className="fill-darkBlue dark:fill-grey100 w-4" data-tooltip-id="country" />
                <Tooltip title="Country" anchorId="country" />
              </div>
              <div className="w-[16%]">
                <Sales className="fill-darkBlue dark:fill-grey100 w-5" data-tooltip-id="sales" />
                <Tooltip title="Sales" anchorId="sales" />
              </div>
              <div className="w-[16%]">
                <Dollar className="fill-darkBlue dark:fill-grey100 w-4.5" data-tooltip-id="price" />
                <Tooltip title="Price" anchorId="price" />
              </div>
            </div>
          </div>

          <Scrollbar className="h-[19.8rem] pr-1.5 flex flex-col gap-2">
            {data &&
              data.result.map((item) => (
                <div
                  className="text-xs text-darkBlue dark:text-grey100 flex items-center border-secondary border-2 dark:border-deepSpace800 dark:bg-deepSpace800 rounded-10 w-full"
                  key={item.id}
                >
                  <div className="py-2 w-[52%]">
                    <ProductNameCell
                      name={item.title}
                      cutLongTitle={15}
                      titleClassName="font-normal text-[10px] mb-0"
                      titleWrapperClassName="ml-6"
                      className="my-[12.5px]"
                      hoveredImgClassName="left-[50px]"
                      width="40px"
                      height="41px"
                      maxHeight="max-h-[45px]"
                      url={item.ebayItemUrl}
                      img={item.ebayImage}
                    />
                  </div>

                  <Link
                    to={makeQueryParams(PATH.competitorResearch.ebay, {
                      search: item.ebayUsername,
                    })}
                    target="_blank"
                    className="w-[16%]"
                    rel="noreferrer"
                  >
                    <HoveredIcon
                      Icon={CompetitorResearch}
                      height="h-8"
                      width="w-8"
                      iconClassName="w-4.5 h-4.5"
                    />
                  </Link>

                  <div className="w-[16%]">
                    <p>{item.globalId}</p>
                  </div>

                  <div className="w-[16%]">
                    <p>{formatNumber(item.sevenDaySales)}</p>
                  </div>

                  <div className="w-[16%]">
                    <p>{formatCurrency(item.currentPrice)}</p>
                  </div>
                </div>
              ))}
          </Scrollbar>
        </div>
      )}
    </Card>
  );
};

export default ProductList;
