import { ReactComponent as BackArrow } from "assets/icons/back-arrow.svg";
import Button from "components/Button";
import Card from "components/Card";
import Toaster from "components/Toast";
import { FC, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { PATH } from "routes/path";
import InvoiceContent from "./InvoiceContent";
import InvoicePaymentContent from "./InvoicePaymentContent";
import InvoiceToPrint from "./InvoiceToPrint";

interface Props {
  id: string;
  isShopify?: boolean;
  paymentType?: string | null;
}
const Invoice: FC<Props> = ({ id, paymentType, isShopify }) => {
  const [searchParams] = useSearchParams();
  const invoiceType = searchParams.get("type");
  const ref = useRef(null);
  const redirBack = isShopify ? PATH.shopify.billing : invoiceType === "affiliate" ?
    PATH.managementPanel.affiliateManager + "&subtab=payment-history" : PATH.managementPanel.managePayments;

  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });


  return (
    <Card className="px-7 py-8 pb-4 ">
      <div className="w-full overflow-x-auto scrollbar pb-3 flex gap-[74px] max-xl:gap-5 max-xl:flex-col">
        <div className={`flex ${isShopify ? "gap-4" : "gap-5"}`}>
          <Button
            title="Back"
            StartIcon={BackArrow}
            isLink
            iconClassName="w-[13px] h-[11px]"
            titleClassName={isShopify ? "hidden" : ""}
            className={`${isShopify ? "min-w-[36px] max-w-[36px] w-full" : "min-w-[104px] max-w-[104px]"} dark:bg-deepSpace900`}
            height="h-9"
            redirect={redirBack}
          />

          <Button
            title="Print"
            className="min-w-[104px] max-w-[104px] dark:bg-deepSpace900"
            height="h-9"
            handleClick={handlePrint}
          />
        </div>
        {
          invoiceType === "payment" || isShopify ?
            (<InvoicePaymentContent id={id} paymentType={paymentType} />)
            :
            (<InvoiceContent id={id} />)
        }
      </div>
      <div className="hidden">
        <InvoiceToPrint id={id} ref={ref} invoiceType={invoiceType} paymentType={paymentType} isShopify={isShopify} />
      </div>
      <Toaster />
    </Card>
  );
};

export default Invoice;
