import { ReactComponent as AliExpress } from "assets/icons/aliexpress-text.svg";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import { ReactComponent as Ebay } from "assets/icons/ebay-text.svg";
import { ReactComponent as ShopifyIcon } from "assets/icons/shopify-text.svg";
import DarkModeSwitch from "components/DarkModeSwitch";
import { useAppSelector } from "hooks/useAppSelector";
import { DASHBOARD_MARKET_PLACES } from "store/slices/marketplaceSlice";

interface Props {
    title: string;
    handleClick: () => void;
    path: string;
    isActive: boolean;
    isSmallSidebar: boolean;
    hasSubmenu?: boolean;
    isOpen?: boolean;
}

const SidebarItemExpandTitle = ({ title, handleClick, path, isActive, isSmallSidebar, hasSubmenu, isOpen }: Props) => {
    const { isDarkMode } = useAppSelector((state) => state.themeSlice);
    const { dashboardMarketplace } = useAppSelector((state) => state.marketplaceSlice);
    return (
        <>
            {path === "themeswitch" ?
                <div className={`w-[132px] ${isSmallSidebar ? "h-[26px]" : "h-[36px]"} flex items-center justify-around text-[10px] text-primaryPurple dark:text-paradiseBlue leading-[12px] font-normal  shrink-0 `}>
                    <div>{isDarkMode ? "Dark" : "Light"}</div>
                    <DarkModeSwitch />
                </div>
                :
                path === "marketplaceswitch" ?
                    <div onClick={handleClick} className={`${isActive || isOpen ? "shadow-[0_0_0_2px_#6831FF_inset]" : ""} group gap-[10px] relative w-[132px] ${isSmallSidebar ? "h-[26px]" : "h-[36px]"}  shrink-0  rounded-full hover:bg-secondary dark:hover:bg-lilac400 px-[15px] flex items-center justify-between cursor-pointer`}>
                        <div>
                            {dashboardMarketplace === DASHBOARD_MARKET_PLACES.shopify ?
                                <ShopifyIcon className={`${isDarkMode ? "fill-white  dark:group-hover:fill-primaryPurple" : "fill-darkBlue"}`} />
                                : dashboardMarketplace === DASHBOARD_MARKET_PLACES.ebay ?
                                    <Ebay className={`${isDarkMode ? "fill-white  dark:group-hover:fill-primaryPurple" : "fill-darkBlue"}`} />
                                    :
                                    <AliExpress className={`${isDarkMode ? "fill-white  dark:group-hover:fill-primaryPurple" : "fill-darkBlue"}`} />
                            }
                        </div>
                        <div className="bg-neutral2 dark:bg-deepSpace800 group-hover:bg-primaryPurple w-[15px] h-[15px] rounded-full flex items-center justify-center shrink-0">
                            <ArrowRight className="fill-darkBlue group-hover:fill-white dark:fill-lilac400 w-[7.26px] h-[7px]" />
                        </div>

                    </div>
                    :
                    hasSubmenu ?
                        <div onClick={handleClick} className={`group gap-[10px] relative w-[132px] ${isSmallSidebar ? "h-[26px]" : "h-[36px]"}  shrink-0 rounded-full hover:bg-secondary dark:hover:bg-lilac400 px-[15px] flex items-center cursor-pointer ${isActive || isOpen ? "shadow-[0_0_0_2px_#6831FF_inset]" : ""}`}>
                            <div className="text-darkBlue dark:text-grey100 dark:group-hover:text-primaryPurple text-[10px] leading-[12px] font-medium">{title}</div>
                            <div className={`bg-neutral2 dark:bg-deepSpace800 ml-auto group-hover:bg-primaryPurple w-[15px] h-[15px] rounded-full flex items-center justify-center shrink-0`}>
                                <ArrowRight className={`fill-darkBlue dark:fill-lilac400 group-hover:fill-white w-[7.26px] h-[7px]`} />
                            </div>

                        </div>
                        :
                        <div onClick={handleClick} className={`${isActive || isOpen ? "shadow-[0_0_0_2px_#6831FF_inset]" : ""} group relative w-[132px] ${isSmallSidebar ? "h-[26px]" : "h-[36px]"}  shrink-0  rounded-full hover:bg-secondary dark:hover:bg-lilac400 px-[15px] flex items-center cursor-pointer`}>
                            <div className="text-darkBlue dark:text-grey100 dark:group-hover:text-primaryPurple text-[10px] leading-[12px] font-medium">{title}</div>
                        </div>
            }

        </>

    )
}

export default SidebarItemExpandTitle