import Card from "components/Card";
import InfoTooltip from "components/Card/InfoTooltip";
import CustomRechartsTooltip from "components/CustomRechartsTooltip";
import { THEME_COLORS } from "config/constants";
import { useAppSelector } from "hooks/useAppSelector";
import { useTranslation } from "react-i18next";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import "styles/recharts.css";

const ProductGraphChart = () => {
  const { t } = useTranslation(['home']);
  const { isDarkMode } = useAppSelector((state) => state.themeSlice);

  const { lineGraph } = useAppSelector(
    (state) => state.prSlice.ebayData.result
  );

  return (


    <Card
      className="w-full h-[19.5rem] p-4 pr-6 text-xs dark:text-grey100"
      bordered
      rounded="rounded-xl"
    >
      <div className="pl-[5em] flex items-center justify-between">
        <h4 className={`text-sm font-bold `}>{t('SalesTrend')}</h4>
        <InfoTooltip title="Shows the number of sales made everyday within the selected Sales Date Range" />
      </div>
      <div className="w-[100%] h-[100%] pt-2 pb-3">
        <ResponsiveContainer width="100%">
          <AreaChart
            width={500}
            height={400}
            className="product-graph-chart"
            data={lineGraph || []}
            innerRadius={100}
            margin={{
              top: 10,
              right: 8,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid
              stroke={THEME_COLORS[isDarkMode ? "deepSpace800" : "secondary"]}
              strokeWidth="2"
              rx={25}
              ry={250}
              vertical={false}
              radius="40px"
            />
            <XAxis
              dataKey="date"
              strokeWidth={0}
              style={{
                fill: isDarkMode ? THEME_COLORS.grey100 : THEME_COLORS.darkBlue,
                textAnchor: "middle",
              }}
              interval="preserveStartEnd"
              tickLine={false}
              tickMargin={11}
            />
            <YAxis strokeWidth={0} tickMargin={16} style={{ fill: isDarkMode ? THEME_COLORS.grey100 : THEME_COLORS.darkBlue }} />
            <Tooltip
              content={<CustomRechartsTooltip valueLabel="Sales" />}
              cursor={false}
              wrapperStyle={{ outline: "none" }}
            />
            <Area
              type="monotone"
              dataKey="quantityPurchased"
              activeDot={{
                style: {
                  stroke: isDarkMode ? THEME_COLORS.grey100 : THEME_COLORS.darkBlue,
                  zIndex: "10",
                },
              }}
              unit="date"
              strokeWidth={2}
              stroke={isDarkMode ? THEME_COLORS.grey100 : THEME_COLORS.darkBlue}
              fill={"#66E7D7"}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </Card>

  );
};

export default ProductGraphChart;
