// Components
import Header from "components/Header";
import HeaderTab from "components/Header/Tab";

import Tools from "components/tools";

// Icons
import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg";
import HeaderLanguage from "components/Header/Language";
import HeaderNewsFeed from "components/Header/NewsFeed";
import HeaderNotifications from "components/Header/Notifications";
import useDecodeToken from "hooks/useDecodeToken";
import useModal from "hooks/useModal";
import useTabs from "hooks/useTabs";
import { useEffect, useRef, useState } from "react";
import { GetNyBanner } from "utils/localStorage";

enum TABS {
  marketInsights = "market-insights",
  myDashboard = "my-dashboard",
}

const MyDashboardHeader = () => {
  const [hideBanner, setHideBanner] = useState(true)
  const { activeTab, onTabChange } = useTabs<TABS>(TABS.marketInsights);
  const { isOpen, close, toggle } = useModal();
  const dataFetchedRef = useRef(false);
  const user = useDecodeToken();
  const hiddenForPlans = user.package_name.includes("LT") || user.package_name.includes("EnterpriseY") || user.package_name.includes("StandardY")

  // const isSmallSidebar = height < 800
  // const closeBanner = () => {
  //   setHideBanner(true);
  //   HideNyBanner();
  //   bannerClosed(null);
  // };


  useEffect(() => {
    if (hiddenForPlans) {
      setHideBanner(true)
    } else {
      setHideBanner(GetNyBanner())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (!hideBanner && !dataFetchedRef.current && !hiddenForPlans) {
      //   bannerViewed(null)
      dataFetchedRef.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideBanner])

  return (
    <>
      <Header
        leftContent={
          <>
            <HeaderTab
              title="Market Insights"
              isActive={activeTab === TABS.marketInsights}
              handleClick={() => onTabChange(TABS.marketInsights)}
            />
            <div className="relative text-sm">
              <button
                onClick={toggle}
                className={`
                ${isOpen
                    ? "text-primaryPurple border-primaryPurple dark:text-purple500 dark:border-purple500 pointer-events-none after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-full after:h-[3px] after:rounded after:bg-primaryPurple dark:after:bg-purple500"
                    : "hover:rounded-[100px] dark:text-grey100  hover:bg-neutral1 hover:text-primaryPurple dark:bg-transparent dark:hover:text-purple500"} 
                    relative h-9 min-w-[6.25rem] flex items-center justify-center gap-6.5 group`}
              >
                <p>Tools</p>
                <ArrowDown
                  className={`${isOpen ? "fill-primaryPurple dark:fill-purple500 rotate-180" : "fill-darkBlue dark:fill-grey100 group-hover:fill-primaryPurple dark:group-hover:fill-purple500"
                    } transition-transform duration-600 w-2.5 h-1.5`}
                />
              </button>
              {isOpen && <Tools handleClose={close} />}
            </div>

            <HeaderTab
              title="My Dashboard"
              handleClick={() => onTabChange(TABS.myDashboard)}
              isActive={activeTab === TABS.myDashboard}
            />
          </>
        }
        rightContent={
          <>
            {/* {hideBanner ? "" : (
              <div className="relative">
                <Link className={`w-[604px] block ${isSmallSidebar ? "h-10" : "h-12"} bg-[linear-gradient(270deg,_#A180FB_0%,_#5124CF_47.63%,_#150D3F_89.67%)] rounded-[5px]`} to={bannerPath} onClick={() => bannerClicked(null)}>
                  <div className="flex items-center justify-between px-2.5 h-full">
                    <div className="gap-1 flex items-center">
                      <p className="text-white font-normal">New Year Special! <span className="text-canaryYellow">Save Up to $449</span> on Yearly plans
                      </p>
                      <img src={Present} alt="present" className="w-5 h-5" />

                    </div>

                    <Button title="Upgrade Now" className="max-w-[105px] !text-smoothGreen hover:!text-darkBlue bg-darkBlue !border-darkBlue hover:!border-smoothGreen" titleClassName="text-xs !font-bold" color="smoothGreen" height="h-7" />
                    <div className="w-5" />
                  </div>
                </Link>
                <button className="w-5 h-5 flex items-center justify-center border border-darkBlue rounded-full hover:bg-primaryPurple hover:border-primaryPurple group absolute top-1/2 -translate-y-1/2 right-[10px]" onClick={closeBanner}>
                  <Close className="fill-darkBlue w-2.5 h-2.5 group-hover:fill-white" />
                </button>
              </div>)
            } */}
            <HeaderLanguage />
            <HeaderNewsFeed />
            <HeaderNotifications />
          </>
        }
      />
      <div className="flex gap-4 w-full max-xl:flex-wrap" />
    </>
  );
};

export default MyDashboardHeader;
