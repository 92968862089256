
import Button from 'components/Button';
import Logo from 'components/Logo';
import Modal from 'components/Modal';
import LaunchDiscountText from 'components/Plans/LaunchDiscountText';
import PlanSwitch from 'components/Plans/PlanSwitch';
import SlidedPlansWithComparison from 'components/Plans/SlidedPlansWithComparison';
import Toaster from 'components/Toast';
import { BLUESNAP_SCRIPT } from 'config/config';
import { useAppDispatch } from "hooks/useAppDispatch";
import useDecodeToken from 'hooks/useDecodeToken';
import useExternalScript from 'hooks/useExternalScript';
import useLogout from 'hooks/useLogout';
import useModal from "hooks/useModal";
import usePlans from 'hooks/usePlans';
import SubscriptionPurchase from 'pages/ManagementPanel/components/ManagePayments/SubscriptionPurchase';
import Trusted from 'pages/RegistrationFunnel/components/Trusted';
import ShopifyPlansComparison from 'pages/Shopify/Billing/components/plans/Comparison';
import ShopifyPlansCont from 'pages/Shopify/Billing/components/plans/PlansContainer';
import SelectMarketplace from 'pages/Shopify/Billing/components/SelectMarketplace';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLazyGetPfTokenQuery, useLazyJourneyCompleteQuery } from 'store/api/managePaymentApi';
import { useEbaySubCountQuery, useIsShopifyUserQuery, useShopifySubCountQuery } from 'store/api/quickSettingsApi';
import { setUserAccessToken } from 'store/slices/userSlice';
import { TSelectedPlan } from 'types/plans';
import { SetAccessToken } from 'utils/localStorage';



declare global {
    interface Window {
        gpfToken: any;
    }
}

const NoSubscription = () => {
    const { data } = useIsShopifyUserQuery();
    const { data: shopifySubCount } = useShopifySubCountQuery()
    const { data: ebaySubCount } = useEbaySubCountQuery()
    const { search } = useLocation();
    const isShopifyActive = search.includes("marketplace=shopify")
    const [activeTab, setActiveTab] = useState<"ebay" | "shopify">(isShopifyActive ? "shopify" : "ebay");
    const [comparisonPlan, setComparisonPlan] = useState(0)
    const onMarketplaceChange = (tab: "ebay" | "shopify") => setActiveTab(tab)
    const [getPfToken] = useLazyGetPfTokenQuery();
    const [journeyComplete] = useLazyJourneyCompleteQuery();
    const dispatch = useAppDispatch();
    const userDetails = useDecodeToken();
    const user = useDecodeToken();
    const { plans, handlePlanChange } = usePlans("monthly");
    const isShopifyTrial = search.includes("isShopify");
    const noEbayPlan = ebaySubCount?.result === 0;
    const noShopifyPlan = shopifySubCount?.result === 0;
    const shopifyAlreadyTrial = user.shopify_already_trial === "1";
    const ebayAlreadyTrial = user.already_trial === "1"
    const ebayUpgrade = isShopifyTrial ? false : !noEbayPlan || ebayAlreadyTrial || user.shopify_attempt === "False"
    const shopifyUpgrade = isShopifyTrial || !noShopifyPlan || shopifyAlreadyTrial || (noShopifyPlan && data?.result);
    const ebayIsPayNow = ebayUpgrade || plans.period === "lifetime";

    useEffect(() => {
        if (shopifyUpgrade && !ebayUpgrade) {
            setActiveTab("shopify")
        }
    }, [shopifyUpgrade, ebayUpgrade])

    useEffect(() => {
        getPfToken(null)
            .unwrap()
            .then((data) => {
                window.gpfToken = data.message;
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useExternalScript(BLUESNAP_SCRIPT, "");
    const modal = useModal();
    const [selectedPlan, setSelectedPlan] = useState<TSelectedPlan>({
        type: "",
        planName: "",
        planPrice: 0,
        skip: false
    });
    useEffect(() => {
        if (userDetails?.journey_completed.toLowerCase() !== "true") {
            journeyComplete(null).unwrap().then((data) => {
                SetAccessToken(data.message);
                dispatch(setUserAccessToken(data.message));
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const logout = useLogout();
    const handleSelectPlan = (newVal: TSelectedPlan) => {
        setSelectedPlan(newVal);
        modal.open();
    };

    const planProps = {
        plans,
        handleSelectPlan,
        fromNoSubscription: true,
        fromRegistration: !ebayIsPayNow,
        isPayNow: ebayIsPayNow,
    }

    return (
        <>
            <div className="h-[90px] w-full bg-white px-[102px] flex items-center justify-between max-md:px-[20px]">
                <Logo className="gap-[19px]" imageClassName="w-[38px] h-[38px]" lettersClassName="w-[52px] h-[23px]" />
                <Button title="Log Out" className="!w-[124px] !h-[44px]" handleClick={logout} />
            </div>
            <h1 className={`text-center text-[28px] font-bold  leading-[32px] max-sm:text-[20px] max-sm:py-3 py-6`}>Great to have you back!</h1>
            <div className="flex flex-col gap-4 pb-4 max-sm:px-[30px]">
                <h4 className="text-center text-[24px] max-sm:text-base font-medium">Unlock Unlimited Market Insight With ZIK</h4>
                <SelectMarketplace marketplace={activeTab} onMarketplaceChange={onMarketplaceChange} />
                <LaunchDiscountText activeTab={activeTab} ebayDiscountPercent={ebayIsPayNow ? 40 : 50} />
            </div>
            {activeTab === "ebay" ? (
                <div>
                    <PlanSwitch
                        activePlan={plans.period}
                        handlePlanChange={handlePlanChange}
                        longSwitchStyles="py-6 max-sm:py-4 max-w-[1058px] max-lg:px-[60px]"
                        showLifetime
                    />
                    <div className="max-sm:mb-6">
                        <SlidedPlansWithComparison
                            {...planProps}
                            plansWrapperClassName='mx-auto !max-lg:max-w-[1120px]'
                            comparisonContStyles='!mt-0'
                        />
                    </div>
                </div>
            ) : <div className='w-full mt-4 max-sm:mt-0'>
                <ShopifyPlansCont
                    handleSelectPlan={handleSelectPlan}
                    isRegFunnel
                    onSlide={(idx) => setComparisonPlan(idx)}
                    isPayNow={shopifyUpgrade}
                    isResponsive
                    nextPrevStyles='max-sm:py-7 py-4'
                />
                <div className='flex justify-center'>
                    <Trusted wrapperClassName='pt-8 pb-6 max-lg:pt-[24px] max-sm:pt-0 max-lg:pb-1' trustpilotStyles='bg-white' paymentMethodsWrapper='border-2 border-solid border-white' />
                </div>
                <ShopifyPlansComparison handleSelectPlan={handleSelectPlan} isPayNow={shopifyUpgrade} wrapperStyles="" mobileActivePlan={comparisonPlan} />
            </div>}

            <Modal helpers={modal} maxWidth="max-w-[1200px]" cardClassName='overflow-y-auto max-h-[600px]'>
                <SubscriptionPurchase selectedPlan={selectedPlan} isShopify={activeTab === "shopify"} hideAlreadyTrialWarning isUpgrade={activeTab === "shopify" ? shopifyUpgrade : ebayUpgrade} />
            </Modal >
            <div className='h-8' />
            <Toaster />
        </>

    )
}

export default NoSubscription

