import DashboardLayout from "layouts/dashboard";
import Logout from "pages/logout";
import AffiliateManager from "pages/ManagementPanel/tabs/AffiliateManager";
import NotFound from "pages/NotFound";
import QuickSettings from "pages/Shopify/QuickSettings";
import { Navigate } from "react-router-dom";
import { PATH } from "./path";

export const affiliateRoutes = [
    {
        path: PATH.managementPanel.affiliateManager,
        element: <Navigate to={PATH.managementPanel.affiliateManager} />,
    },
    {
        path: PATH.root,
        element: <Navigate to={PATH.managementPanel.affiliateManager} />,
    },
    {
        path: PATH.login,
        element: <Navigate to={PATH.managementPanel.affiliateManager} />,
    },
    {
        path: PATH.logout,
        element: <Logout />,
    },
    {
        path: PATH.affiliateCreateAccount,
        element: <Navigate to={PATH.managementPanel.affiliateManager} />,
    },
    {
        path: PATH.root,
        element: <DashboardLayout />,
        children: [
            {
                path: PATH.managementPanel.root,
                element: <AffiliateManager />,
            },
            {
                path: PATH.managementPanel.affiliateManager,
                element: <AffiliateManager />,
            },
            {
                path: PATH.quickSettings.root,
                element: <QuickSettings />,
            },
        ],
    },
    {
        path: "*",
        element: <NotFound />
    },
];
