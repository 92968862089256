import { ReactComponent as Question } from "assets/images/question.svg";
import Button from "components/Button";
import Loading from "components/Loading";
import { useGetOnBoardingQuestionQuery, useSaveSalesChannelMutation } from "store/api/onboardingApi";
import SalesChannelOption from "../components/SalesChannelOption";

interface Props {
  mainChannel: number | null;
  onMainChannelChange: (newChan: number | null) => void;
  onChannelChange: (newChans: number[]) => void;
  channels: number[];
  handleNext: () => void;
}
const SalesChannel = ({
  mainChannel,
  onMainChannelChange,
  onChannelChange,
  handleNext,
  channels,
}: Props) => {
  const [saveSalesChannel, { isLoading: loadingChannel }] =
    useSaveSalesChannelMutation();

  const { data, isFetching: questionLoading } = useGetOnBoardingQuestionQuery(
    { stepNumber: 2 },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const channelList = data?.result[0].onBoardingQuestionOptions;

  const handleSaveChannel = () => {
    saveSalesChannel({ mainChannel, salesChannels: channels }).unwrap().then(() => {
      handleNext();
    });
  };

  const handleChannelSelection = (channel: number) => {
    if (mainChannel === null) onMainChannelChange(channel);
    if (!channels.includes(channel)) {
      var newChannels = [...channels, channel];
      onChannelChange(newChannels);
    } else {
      var indexToRemove = channels.indexOf(channel);
      const newChannelsList = [
        ...channels.slice(0, indexToRemove),
        ...channels.slice(indexToRemove + 1),
      ];
      onChannelChange(newChannelsList);
      if (mainChannel === channel) {
        onMainChannelChange(newChannelsList[0] || null);
      }
    }
  };




  return (
    <div className="flex flex-col !overflow-visible justify-between max-lg:max-w-[536px] max-lg:mx-auto pt-4 max-lg:pt-6 max-lg:h-[calc(100vh-244px)] max-sm:h-[calc(100vh-132px)] relative pb-[35px] max-lg:pb-[0px] max-sm:max-w-[330px] max-sm:pt-0">
      <div className="mb-[30px]  max-lg:mb-10 max-sm:mb-0">
        <Question className="self-center mb-[35px] max-lg:mb-4 mx-auto max-sm:mb-6" />
        {!questionLoading && data ? (
          <>
            <h1 className="max-lg:text-2xl text-xl text-center font-bold text-darkBlue dark:text-grey100 mb-[25px] max-lg:mb-8 max-sm:text-[20px] max-sm:mb-6">
              {data?.result[0].questionLabel}
            </h1>
            <div className="flex justify-center space-between">
              <div className="gap-[25px] max-lg:gap-8  max-sm:gap-4 grid max-lg:w-full grid-cols-4 max-lg:grid-cols-1 items-center justify-center">
                {channelList?.map((option) => <SalesChannelOption
                  {...option}
                  isChecked={channels.includes(option.optionValue)}
                  key={option.id}
                  handleChannelSelection={handleChannelSelection}
                />)}
              </div>
            </div>
            <div className="flex justify-center">
            </div>
          </>
        ) : <Loading fill="fill-primaryPurple dark:fill-purple500 mx-auto" width={40} height={40} />}
      </div>
      <Button
        title="Next"
        height="min-h-[64px] max-sm:min-h-[56px]"
        titleClassName="font-medium !text-base"
        className={`max-w-[214px] max-lg:max-w-[100%] mx-auto max-sm:fixed max-sm:bottom-[32px] max-sm:max-w-[330px]`}
        handleClick={handleSaveChannel}
        loading={loadingChannel}
        disabled={channels.length <= 0}
      />
    </div>
  );
};

export default SalesChannel;
