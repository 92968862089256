import AlreadyTrial from "pages/AlreadyTrial";
import Maintenance from "pages/Maintenance";
import NoSubscription from "pages/NoSubscription";
import { PATH } from "./path";
import registrationCompleteRoutes from "./registrationComplete";

export const noSubscriptionRoutes = [
  {
    path: PATH.alreadyTrial,
    element: <AlreadyTrial />,
  },
  {
    path: PATH.root,
    element: <NoSubscription />,
  },
  {
    path: PATH.maintenance,
    element: <Maintenance />,
  },
  {
    path: "*",
    element: <NoSubscription />,
  },
  ...registrationCompleteRoutes
];
