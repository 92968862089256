export const getMarketplaceName = (marketplace: string) => {
  switch (marketplace) {
    case "US":
      return "ebay.com";
    case "UK":
      return "ebay.co.uk";
    case "DE":
      return "ebay.de";
    case "FR":
      return "ebay.fr";
    case "CA":
      return "ebay.ca";
    case "AU":
      return "ebay.com.au";
    case "IT":
      return "ebay.it";
    case "ES":
      return "ebay.es";
    case "MY":
      return "ebay.com.my";
    default:
      return "ebay.com";
  }
};

export const getAmazonDomain = (marketplace: string) => {
  switch (marketplace) {
    case "US":
      return ".com";
    case "UK":
      return ".co.uk";
    case "DE":
      return ".de";
    case "FR":
      return ".fr";
    case "CA":
      return ".ca";
    case "AU":
      return ".com.au";
    case "IT":
      return ".it";
    case "ES":
      return ".es";
    case "MY":
      return ".com.my";
    default:
      return ".com";
  }
};

export const getCountryFromMarketplace = (marketplace: string) => {
  switch (marketplace) {
    case "ebay.com":
      return "US";
    case "ebay.co.uk":
      return "UK";
    case "ebay.de":
      return "DE";
    case "ebay.fr":
      return "FR";
    case "ebay.ca":
      return "CA";
    case "ebay.com.au":
      return "AU";
    case "ebay.it":
      return "IT";
    case "ebay.es":
      return "ES";
    case "ebay.com.my":
      return "MY";
    default:
      return "US";
  }
};
