import { ReactComponent as Mouse } from "assets/icons/scroll-to-top.svg";
import Tooltip from "components/Tooltip";
import { FC, useEffect, useState } from "react";
interface Props {
  hideUntill?: number;
  bottom?: string;
  right?: string;
  scrollTo?: number;
}
const ScrollToTop: FC<Props> = ({ hideUntill, bottom, right, scrollTo }) => {
  const [show, setShow] = useState(false);
  const scrollY = hideUntill ? hideUntill : 1000;
  const top = scrollTo || 0;
  const handleScrollToTop = () => {
    window.scrollTo({
      top,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > scrollY) {
        setShow(true);
      } else {
        setShow(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <button
      className={`bg-darkBlue dark:bg-lilac400 hover:bg-primaryPurple dark:hover:bg-purple500 w-9 h-9 flex justify-center items-end pb-2 rounded-full fixed ${bottom || "bottom-[25px]"
        } ${right || "right-[100px]"} z-50 ${show ? "" : "hidden"}`}
      onClick={handleScrollToTop}
      data-tooltip-id="scrollToTop"
    >
      <Mouse className="w-2.5 h-5 animate-bounce fill-white dark:fill-deepSpace900" />
      <Tooltip title="Scroll To Top" anchorId="scrollToTop" />
    </button>
  );
};

export default ScrollToTop;
