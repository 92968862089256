import Input from "components/Input";
import { FC, useState } from "react";

interface Props {
    isWal: boolean;
    setValue: (newVal: string) => void;
    itemID: string;
    changeInputFocus: () => void
    newValue: string | null;
}
const TableAsinInput: FC<Props> = ({ isWal, itemID, setValue, changeInputFocus, newValue }) => {
    const [val, setVal] = useState(newValue || "")
    const handleChange = (newVal: string) => {
        const value = newVal.toUpperCase().trim()
        if (newVal.length <= 10) {
            setVal(value)
        }
    }


    return (
        <Input
            value={val}
            placeholder={isWal ? "Item ID" : "ASIN"}
            className="text-center w-[5rem] mx-4 !pb-2"
            name={itemID}
            onBlur={() => { setValue(val) }}
            inputClassName="text-center !text-xs"
            handleChange={handleChange}
        />
    )
}

export default TableAsinInput