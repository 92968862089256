import Tooltip from "components/Tooltip";
import useHover from "hooks/useHover";
import { FC, useEffect, useId } from "react";

interface Props {
  word: string;
  type: "vero" | "risky";
  onHover: (hov: boolean) => void
}

const HighlightedKeyword: FC<Props> = ({ word, type, onHover }) => {
  const [hoverRef, isHovered] = useHover<HTMLSpanElement>()
  const id = useId();
  const tooltipTitle =
    type === "vero"
      ? "VeRO Brand. The item is very risky to list."
      : "Please make sure that your item doesn't contain some forbidden words, ingredients, components or other factor that is forbidden to list";

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { onHover(isHovered) }, [isHovered])

  return (
    <>
      <span
        className={`${type === "vero" ? "bg-errorRed" : "bg-[#E9BA1F]"
          } text-white px-1 py-[2px]  rounded-[5px]`}
        data-tooltip-id={id}
        ref={hoverRef}
      >
        <span>{word}</span>
      </span>
      <Tooltip anchorId={id} title={tooltipTitle} />
    </>
  );
};

export default HighlightedKeyword;
