import Tooltip from "components/Tooltip";
import { FC, useId } from "react";
import { UpcomingEventList } from "types/services/newDashboard";
import getEventIcon from "utils/getEventIcon";

interface Event extends UpcomingEventList {
  isFilled: boolean;
}
interface Props {
  event: Event;
  isSelected: boolean;
  calendarLang: "US" | "UK"
}
const EventIcon: FC<Props> = ({ event, isSelected, calendarLang }) => {
  const id = useId();
  const eventIcon = getEventIcon(event.eventName, calendarLang);
  const isStringIcon = typeof eventIcon === "string";

  return (
    <div
      data-tooltip-id={id}
      className={`flex justify-center items-center ${event.isFilled ? "bg-smoothGreen dark:bg-paradiseBlue" : ""
        }`}
    >
      {isStringIcon ? (
        <img
          data-tooltip-id={id}
          alt={event.eventDate}
          src={eventIcon}
          className={`${isSelected ? "fill-white" : "fill-smoothGreen dark:fill-paradiseBlue"} w-4/5`}
        />
      ) : (
        <>
          <img
            data-tooltip-id={id}
            alt={event.eventDate}
            src={eventIcon?.first}
            className={`${isSelected ? "fill-white" : "fill-smoothGreen dark:fill-paradiseBlue"} w-4`}
          />
          <img
            data-tooltip-id={id}
            alt={event.eventDate}
            src={eventIcon?.second}
            className={`${isSelected ? "fill-white" : "fill-smoothGreen  dark:fill-paradiseBlue"} w-4`}
          />
        </>
      )}

      <Tooltip anchorId={id} title="Hello world" />
    </div>
  );
};

export default EventIcon;
