import { ColumnDef } from "@tanstack/react-table";
import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";
import { ReactComponent as Heart } from "assets/icons/heart.svg";
import { ReactComponent as Trash } from "assets/icons/trash.svg";
import HoveredIcon from "components/HoveredIcon";
import dayjs from "dayjs";
import EbayCrEditComment from "pages/CompetitorResearch/components/EditComment";
import { FC } from "react";
import { Link } from "react-router-dom";
import { PATH } from "routes/path";
import { CrHistoryList } from "types/services/cr";
import { newCutLongTitle } from "utils/cutLongTitle";
import { formatCurrency, formatNumber } from "utils/formatCurrency";
import { getCountryFlag } from "utils/getCountryFlag";
const linkClassName = "bg-neutral2 dark:bg-deepSpace900 text-xs px-[9px] py-[5px] rounded-[5px]"
interface BarProps {
    addedByUser: boolean;
    inQueue: boolean;
    wrapperClassName?: string;
    width?: string;
}

const defaultColSize = (isWatchlist: boolean) => (isWatchlist ? {} : {
    minSize: 100,
    maxSize: 100,
    size: 100
})
const InProgressBar: FC<BarProps> = ({ addedByUser, inQueue, wrapperClassName, width }) => <div style={{ width }} className={`h-10 flex ${addedByUser === true ? "justify-center" : ""} ${wrapperClassName} px-[12px] items-center bg-white dark:bg-black900`}>
    {inQueue === false && addedByUser === true ? <div className="w-6 h-6 flex items-center justify-center bg-smoothGreen rounded-full">
        <Checkmark className="fill-white w-3 h-[9px]" />
    </div> : (
        <div className="w-full border-yellow bg-yellow h-[26px] rounded-full flex items-center justify-center text-xs text-darkBlue">
            <p>In Progress</p>
        </div>
    )}
</div>

export const crInitialCols = (type: "search" | "progress" | "watchlist", handleDeleteClick?: (searchedUserId: number) => void): ColumnDef<CrHistoryList>[] => ([
    {
        header: "Store",
        accessorKey: "ebayUsername",
        enableSorting: false,
        cell: ({ row, row: { original } }) => (
            <div className="flex justify-between items-center w-full">
                <div className="flex items-center gap-2.5">
                    <img src={getCountryFlag(original.countryCode || "")} className="w-4 h-4" alt="" />
                    {original.inQueue === true ?
                        <p data-tooltip-id={original.ebayUsername + "title" + row.index} className={linkClassName}>{newCutLongTitle(original.ebayUsername || "", 27)}</p> :
                        <Link to={`${PATH.competitorResearch.ebay}?search=${original.ebayUsername}`} target="_blank" data-tooltip-id={original.ebayUsername + "title" + row.index} className={linkClassName}>
                            {newCutLongTitle(original.ebayUsername, 27)}
                        </Link>}
                </div>
                {original.isAddedFavourite ? (
                    <div className="w-6 h-6 flex items-center justify-center">
                        <Heart className="fill-primaryPurple dark:fill-purple500 color-primary my-auto h-[10.52px] w-[14.67px]" />
                    </div>
                ) : ""}
            </div>

        ),
        meta: {
            align: "justify-start"
        }
    },
    ...(type === "search" ? [{
        header: "Search Date",
        accessorKey: "searchDate",
        cell: ({ row: { original } }) => (
            <p>{dayjs(original.searchDate).format('D MMM YYYY')}</p>
        ),
        meta: {
            align: "justify-start"
        }
    } as ColumnDef<CrHistoryList>] : []),
    ...(type !== "search" ? [{
        header: "Date Added",
        accessorKey: "lastUpdatedAt",
        cell: ({ row: { original } }) => (
            <p>{dayjs(original.lastUpdatedAt).format('D MMM YYYY')}</p>
        ),
        meta: {
            align: "justify-start"
        }
    } as ColumnDef<CrHistoryList>] : []),
    ...(type !== "progress" ? [{
        header: "Feedback",
        ...defaultColSize(type === "watchlist"),
        accessorKey: "feedbackScore",
        sortingFn: "alphanumeric",
        cell: ({ row: { original }, column }) => {
            const colWidth = `${(Number(column.getFilterValue()) * 4) - 34}px`
            if ((original.inQueue && type !== "watchlist")) {
                return (
                    <InProgressBar addedByUser={original.addedByUser} inQueue={original.inQueue} width={colWidth}
                        wrapperClassName={`absolute !w-[${colWidth}`} />
                )
            } else {
                return <p className="font-[700]">{Number(original.feedbackScore).toLocaleString("en-US")}</p>
            }
        },
        meta: {
            align: "justify-start",
        }
    } as ColumnDef<CrHistoryList>] : []),
    ...(type !== "progress" ? [
        {
            header: "Active Listings",
            ...defaultColSize(type === "watchlist"),
            accessorKey: "activeListings",
            cell: ({ row: { original } }) => (
                <p className="font-[700]">{formatNumber(original.activeListings)}</p>
            ),
            meta: {
                align: "justify-start"
            }
        } as ColumnDef<CrHistoryList>,
        {
            header: "Sales 30 Days",
            accessorKey: "thirtyDaySales",
            ...defaultColSize(type === "watchlist"),
            cell: ({ row: { original } }) => (
                <p className="font-[700]">{original.thirtyDaySales && original.thirtyDaySales.toLocaleString('en-US')}</p>
            ),
            meta: {
                align: "justify-start"
            }
        } as ColumnDef<CrHistoryList>,

    ] : []),
    ...(type === "progress" ? [{
        header: "Status",
        minSize: 300,
        accessorKey: "inQueue",
        cell: ({ row: { original } }) => <InProgressBar addedByUser={original.addedByUser} inQueue={original.inQueue} wrapperClassName="w-full" />,
        meta: {
            align: "justify-start"
        }
    } as ColumnDef<CrHistoryList>] : []),
    ...(type !== "progress" ? [{
        header: "Revenue 30 Days",
        ...defaultColSize(type === "watchlist"),
        accessorKey: "thirtyDaysEarnings",
        cell: ({ row: { original } }) => (
            <p className="font-[700]">{formatCurrency(Math.round(original.thirtyDaysEarnings))}</p>
        ),
        meta: {
            align: "justify-start"
        }
    } as ColumnDef<CrHistoryList>] : []),
    ...(type === "watchlist" ? [{
        header: "Comment",
        enableSorting: false,
        minSize: 170,
        maxSize: 170,
        size: 170,
        accessorKey: "comment",
        cell: ({ row: { original } }) => (
            <EbayCrEditComment
                comment={original.comment || ""}
                searchedUserId={original.searchedUserId}
                title="Notes and observations about your favorite stores."
            />
        ),
        meta: {
            align: "justify-start",
        }
    } as ColumnDef<CrHistoryList>] : []),
    {
        header: () => <CompetitorResearch className="fill-darkBlue w-[17.5px] h-[18.96px] dark:fill-grey100" />,
        accessorKey: "competitors",
        enableSorting: false,
        minSize: 80,
        cell: ({ row: { original } }) => original.inQueue === true ? (<HoveredIcon Icon={CompetitorResearch} iconClassName="w-[17.5px] h-[18.96px]"
            iconColor="fill-grey200 group-hover:!fill-grey200 dark:!fill-grey400 dark:group-hover:!fill-grey400"
            className="border-grey200 hover:!border-grey200 hover:!bg-transparent dark:!border-grey400 dark:hover:!border-grey400 dark:hover:bg-transparent !cursor-default"
            title="Store details will be available once we've collected all relevant data" variant="outlined" width="w-[35px]" height="h-[35px]"
        />) : (
            <Link target="_blank" to={`${PATH.competitorResearch.ebay}?search=${original.ebayUsername}`} rel="noreferrer">
                <HoveredIcon Icon={CompetitorResearch} iconClassName="w-[17.5px] h-[18.96px]" variant="outlined" width="w-[35px]" height="h-[35px]" />
            </Link>

        ),
        meta: {
            alignHead: "justify-center",
            tooltipTitle: "Scan Store"
        }
    },
    ...(type === "watchlist" ? [{
        header: () => <Trash className="fill-darkBlue w-[17.5px] h-[18.96px] dark:fill-grey100" />,
        accessorKey: "id",
        enableSorting: false,
        minSize: 80,
        cell: ({ row: { original } }) => (
            <HoveredIcon Icon={Trash} iconClassName="w-[17.5px] h-5" variant="outlined" handleClick={() => handleDeleteClick && handleDeleteClick(original.searchedUserId)} width="w-[35px]" height="h-[35px]" />
        ),
        meta: {
            alignHead: "justify-center",
            tooltipTitle: "Delete Store"
        }
    } as ColumnDef<CrHistoryList>] : [])
])