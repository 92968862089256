import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { gtmOnboardingCcClick } from "utils/googleTagManager";
import CreditCard from "./CreditCard";
import PaymentTab from "./PaymentTab";



interface Props {
  isShopify?: boolean;
  className?: string;
  selectedPlan: string;
  coupon: string;
  handleMethod?: (type: string) => void;
  skip: boolean;
  payLoading?: boolean;
  fromRegistration: boolean;
  handleAlreadyTrial?: (isTrial: boolean) => void;
  handleApplyCoupon?: (newVal: string) => void;
  isPayNow?: boolean;
  handleStopPay?: () => void;
  onTabClick?: (type: string) => void;
  selectedMethod?: string;
  onCCPaymentLoading?: (isLoading: boolean) => void;
  alreadyTrial?: boolean;
  isFirstPaypal?: boolean;
  amount?: number
}

const PaymentMethod = ({
  isShopify,
  className,
  handleMethod,
  selectedMethod,
  selectedPlan,
  coupon,
  skip,
  payLoading,
  fromRegistration,
  handleAlreadyTrial,
  handleApplyCoupon,
  onCCPaymentLoading,
  onTabClick,
  isFirstPaypal,
  isPayNow,
  handleStopPay,
  alreadyTrial = false,
  amount
}: Props) => {
  const { pathname } = useLocation();
  const [tab, setTab] = useState("");
  const handleSetTab = (type: string) => {
    if (tab === "cc") {
      gtmOnboardingCcClick();
    }
    setTab(type);
    onTabClick && onTabClick(type)
    handleMethod && handleMethod(type);
  };

  useEffect(() => {
    setTab(selectedMethod || "")
  }, [selectedMethod]);

  const tabProps = {
    handleClick: handleSetTab,
    tab,
  }
  return (
    <div>
      <div className={`flex items-center max-sm:flex-col gap-[29px] max-sm:gap-[15px] sm:h-[70px] ${className || ""}`}>
        <PaymentTab
          type={isFirstPaypal ? "pp" : "cc"}
          isLoading={isFirstPaypal ? payLoading : undefined}
          {...tabProps}
        />
        <PaymentTab
          type={isFirstPaypal ? "cc" : "pp"}
          isLoading={isFirstPaypal ? undefined : payLoading}
          {...tabProps}

        />
        <PaymentTab
          type="py"
          isLoading={payLoading}
          {...tabProps}
        />
      </div>

      {tab === "cc" && (
        <CreditCard
          isShopify={isShopify}
          selectedPlan={selectedPlan}
          coupon={coupon} skip={skip}
          fromRegistration={fromRegistration}
          handleAlreadyTrial={handleAlreadyTrial}
          handleApplyCoupon={handleApplyCoupon}
          isPayNow={isPayNow}
          onCCPaymentLoading={onCCPaymentLoading}
          handleStopPay={handleStopPay}
          alreadyTrial={alreadyTrial}
          amount={amount}
        />
      )}
    </div>
  );
};

export default PaymentMethod;
