import {
  aliGrowthScanner,
  autoPilot,
  bestSellingItems,
  bulkScanner,
  turboScanner,
} from "assets/data/plans/proToolsContent";

export const getProToolsContent = (tool: string) => {
  switch (tool) {
    case "bulk-scanner":
      return bulkScanner;
    case "best-selling-items":
      return bestSellingItems;
    case "ali-growth-scanner":
      return aliGrowthScanner;
    case "turbo-scanner":
      return turboScanner;
    case "autopilot":
      return autoPilot;
    default:
      return bulkScanner;
  }
};

export const getProToolsPlans = (tool: string) => {
  switch (tool) {
    case "bulk-scanner":
      return "proplus";
    case "best-selling-items":
      return "proplus";
    case "ali-growth-scanner":
      return "proplus";
    case "turbo-scanner":
      return "enterprise";
    case "autopilot":
      return "enterprise";
    default:
      return "proplus";
  }
};
