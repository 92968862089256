import { helpVideos } from "assets/data/helpVideos";
import Header from "components/Header";
import HeaderHelpVideo from "components/Header/HelpVideo";
import HeaderTab from "components/Header/Tab";
import useDecodeToken from "hooks/useDecodeToken";
import useTabs from "hooks/useTabs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PATH } from "routes/path";
import Invoice from "./components/Invoice";
import AffiliateManager from "./tabs/AffiliateManager";
import ManagePayments from "./tabs/ManagePayments";
import SavedSearches from "./tabs/SavedSearches";
import StoreAnalytics from "./tabs/StoreAnalytics";

enum TABS {
  index = "",
  savedSearches = "saved-searches",
  managePayments = "manage-payments",
  affiliateManager = "affiliate-manager",
  storeAnalytics = "store-analytics",
}

const tabContent = {
  [TABS.index]: {
    content: <SavedSearches />,
    helpVideo: helpVideos.savedSearches,
  },
  [TABS.savedSearches]: {
    content: <SavedSearches />,
    helpVideo: helpVideos.savedSearches,
  },
  [TABS.managePayments]: {
    content: <ManagePayments />,
    helpVideo: helpVideos.managePayments,
  },
  [TABS.affiliateManager]: {
    content: <AffiliateManager />,
    helpVideo: helpVideos.affiliateManagement,
  },
  [TABS.storeAnalytics]: {
    content: <StoreAnalytics />,
    helpVideo: helpVideos.storeAnalytics,
  },
};

const Index = () => {
  const { activeTab, onTabChange } = useTabs<TABS | null>(null);
  const [invoiceId, setInvoiceId] = useState<string | null>(null);
  const [paymentType, setPaymentType] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const userDetails = useDecodeToken();
  const { store } = userDetails;
  const { t } = useTranslation(['home']);
  useEffect(() => {
    if (searchParams.get("invoice")) {
      setInvoiceId(searchParams.get("invoice"));
      setPaymentType(searchParams.get("paymentType"))
    } else {
      setInvoiceId(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get("invoice")]);

  return (
    <>
      {process.env.REACT_APP_ENVIRONMENT !== 'affiliate' ? (
        <Header
          leftContent={
            <>
              {activeTab === TABS.savedSearches && (
                <HeaderTab
                  title={t('MySavedSearches')}
                  isActive={true}
                  handleClick={() => onTabChange(TABS.savedSearches)}
                />
              )}
              {activeTab === TABS.managePayments && (
                <HeaderTab
                  title={t('ManagePayments')}
                  className="!min-w-[65px]"
                  isActive={true}
                  handleClick={() => onTabChange(TABS.managePayments)}
                />
              )}
              {activeTab === TABS.affiliateManager && (
                <HeaderTab
                  title={t('AffiliateManager')}
                  isActive={true}
                  handleClick={() => onTabChange(TABS.affiliateManager)}
                />
              )}
              {activeTab === TABS.storeAnalytics && (
                <HeaderTab
                  title={t('StoreAnalytics')}
                  isActive={true}
                  handleClick={() => {
                    store ? navigate(`${PATH.store.root}/${store}`) : onTabChange(TABS.storeAnalytics);
                  }}
                />
              )}
            </>
          }
          rightContent={
            <>
              <HeaderHelpVideo
                videos={
                  tabContent[activeTab ? activeTab : TABS.savedSearches]
                    ?.helpVideo
                }
              />
            </>
          }
        />
      ) : (<Header
        leftContent={
          <>
            <HeaderTab
              title={t('AffiliateManager')}
              isActive={activeTab === TABS.affiliateManager}
              handleClick={() => onTabChange(TABS.affiliateManager)}
            />
          </>
        }
        rightContent={
          <>
            <HeaderHelpVideo
              videos={
                tabContent[activeTab ? activeTab : TABS.savedSearches]
                  ?.helpVideo
              }
            />
          </>
        }
      />)}


      {invoiceId ? (
        <Invoice id={invoiceId} paymentType={paymentType} />
      ) : activeTab ? (
        tabContent[activeTab].content
      ) : (
        ""
      )}
    </>
  );
};

export default Index;
