import { ReactComponent as LeftArrow } from "assets/icons/arrow-left-extended.svg";
import { ReactComponent as Starts } from "assets/images/stars.svg";
import Button from "components/Button";
import CheckoutOrderSummary from "components/CheckoutOrderSummary/Index";
import CheckoutCouponInput from "components/Input/CheckoutCouponInput";
import Loading from "components/Loading";
import PaymentMethod from "components/PaymentMethod/Index";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  useGetDefaultCouponQuery,
  useLazyCheckRegistrationQuery, useLazyPayoneerPurchaseQuery,
  useLazyPaypalRegistrationPurchaseQuery,
  useLazySubmbitCreditCardQuery
} from "store/api/managePaymentApi";
import { TSelectedPlan } from "types/plans";
import { gtmOnboardingPaymantScreenView, gtmOnboardingPaymentSubmit, gtmOnboardingPaymentSubmitAttempt, gtmOnboardingPayoneerClick, gtmOnboardingPaypalClick } from "utils/googleTagManager";
import Trusted from "../components/Trusted";

interface Props {
  isPricingFlow: boolean
  selectedPlan: TSelectedPlan;
  method: string;
  handleBack: () => void;
  handleMethod: (type: string) => void
}
const Payment = ({ selectedPlan, method, handleMethod, handleBack }: Props) => {
  const [ccPaymentLoading, setCCPaymentLoading] = useState(false)
  const [couponValidating, setCouponValidating] = useState(true);
  const [couponUsed, setCouponUsed] = useState(false);
  const [payNowLoading, setPayNowLoading] = useState(false)
  const [coupon, setCoupon] = useState<string>();
  const [alreadyTrial, setAlreadyTrial] = useState(false);
  const [isPayNow, setIsPayNow] = useState(false);
  const { data: defaultCoupon, isFetching: couponLoading } = useGetDefaultCouponQuery(selectedPlan.type);
  const [checkRegistration, { data, isFetching }] = useLazyCheckRegistrationQuery();
  const [paypalPurchase, { isFetching: paypalFetching }] = useLazyPaypalRegistrationPurchaseQuery();
  const [payoneerPurchase, { isFetching: payoneerFetching }] = useLazyPayoneerPurchaseQuery();
  const [, { isLoading }] = useLazySubmbitCreditCardQuery();
  const isShopifyPlanSelected = selectedPlan.type.includes("ShopPro")
  const hidePayNowBtn = method !== "cc" || alreadyTrial || couponUsed;
  const changeCouponUsed = (isUsed: boolean) => setCouponUsed(isUsed)
  const handleApplyCoupon = (newVal: string) => setCoupon(newVal);
  const handleAlreadyTrial = (isTrial: boolean) => { setAlreadyTrial(isTrial); setCouponUsed(false); }
  const checkRegister = () => checkRegistration({
    coupon: coupon || "",
    package: selectedPlan.type,
    skipTrial: selectedPlan.skip,
  }).unwrap().then().catch((error) => toast.error(error.data.message));

  useEffect(() => {
    if (!couponLoading) {
      if (defaultCoupon?.message === "") {
        setCouponValidating(false);
      }
    }
  }, [defaultCoupon, couponLoading])

  useEffect(() => {
    if (selectedPlan?.type != null && !couponLoading && !couponValidating) {
      checkRegister();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coupon, selectedPlan.type, couponValidating, selectedPlan.skip]);

  const handleVatNumberUpdate = () => {
    if (selectedPlan?.type != null) {
      checkRegister();
    }
  }


  const handlePurchase = (selectedTab?: string) => {
    if (selectedTab === "") {
      toast.error("Please, select a payment method.");

      return;
    }
    if (selectedTab === "cc") {
      setIsPayNow(true)
    }
    if (selectedTab === "pp") {
      gtmOnboardingPaypalClick()
      paypalPurchase({
        couponName: coupon || "",
        requestedSubscriptionType: selectedPlan.type,
        skipTrial: selectedPlan.skip
      })
        .unwrap()
        .then((data) => {
          gtmOnboardingPaymentSubmit(); // send event to gtm 
          window.location.replace(`${data.message}`);
        }).catch(() => setPayNowLoading(false));
    }
    if (selectedTab === "py") {
      gtmOnboardingPayoneerClick();
      payoneerPurchase({
        couponName: coupon || "",
        type: selectedPlan.type,
        skipTrial: selectedPlan.skip
      })
        .unwrap()
        .then((data) => {
          gtmOnboardingPaymentSubmit(); // send event to gtm
          window.location.replace(`${data.message}`);
        }).catch(() => setPayNowLoading(false));
    }
  };

  const handleTabClick = (tab: string) => {
    gtmOnboardingPaymentSubmitAttempt();
    if (tab !== "cc") {
      setPayNowLoading(true)
      handlePurchase(tab);
    }
  }

  useEffect(() => {
    gtmOnboardingPaymantScreenView(); // google tag manager event that fires when user land on this page
  }, [])

  return (
    <div className="overflow-y-auto scrollbar max-lg:pb-10 pb-[19px] max-sm:p-0 pt-6 px-6">
      <div className="flex flex-col gap-2">
        <Button
          title="Back"
          height="h-9"
          titleClassName="!font-bold"
          iconClassName="w-[13.33px] h-[11.67px]"
          contClassName="!gap-[9.33px]"
          handleClick={handleBack}
          className={`z-40 max-w-[104px] w-full bg-white max-lg:hidden`}
          StartIcon={LeftArrow}
        />
        <h1 className="text-lg text-center font-bold text-darkBlue dark:text-grey100 w-full max-sm:text-base mx-auto">
          You’re almost done!
        </h1>
      </div>
      <Starts className="mx-auto max-sm:mb-2 max-sm:w-6 max-sm:mt-1 mt-2 mb-[48px]" />
      <div className={`flex justify-center  max-lg:flex-col max-sm:flex-col-reverse max-lg:items-center max-sm:gap-5 gap-8`}>
        <div className="max-w-[660px] max-lg:max-w-[624px] max-sm:max-w-[330px] w-full border-[1px] border-secondary rounded-[15px] max-sm:p-0  max-sm:p-[15px] max-sm:mb-8 p-8 max-lg:p-6">
          <div className="flex flex-col justify-center w-full">

            <h1 className={`${method !== "" ? "max-sm:hidden" : ""} text-lg max-sm:text-base text-center font-bold text-darkBlue`}>
              Choose your Payment Method
            </h1>
            <PaymentMethod
              className={`mt-2.5 mb-[26px] max-lg:my-2.5 ${method === "cc" ? "max-sm:my-0" : "max-sm:my-5"} justify-center`}
              handleMethod={handleMethod}
              selectedPlan={selectedPlan.type}
              isFirstPaypal
              selectedMethod={method}
              payLoading={payNowLoading}
              onTabClick={handleTabClick}
              coupon={coupon || ""}
              skip={selectedPlan.skip}
              onCCPaymentLoading={(isLoading) => setCCPaymentLoading(isLoading)}
              fromRegistration
              isPayNow={isPayNow}
              isShopify={isShopifyPlanSelected}
              handleStopPay={() => setIsPayNow(false)}
              handleAlreadyTrial={handleAlreadyTrial}
              amount={data?.result?.totalToday}
            />
          </div>
          {!couponLoading && (
            <CheckoutCouponInput
              handleApplyCoupon={handleApplyCoupon}
              selectedPlan={selectedPlan.type}
              fromRegistration
              changeCouponUsed={changeCouponUsed}
              preloadCoupon={defaultCoupon?.message}
              couponValidating={setCouponValidating}
            />
          )}
          {hidePayNowBtn ? "" : <div className="flex justify-center mt-[25px] max-sm:mt-4 sm:hidden">
            <Button
              title="PAY NOW"
              color="smoothGreen"
              height="h-16"
              titleClassName="!text-base"
              className="font-bold bg-darkBlue !border-darkBlue text-smoothGreen hover:!border-smoothGreen hover:!text-darkBlue w-full  shadow-[2px_5px_30px_-4px_rgba(28,_163,_147,_0.15)]"
              handleClick={() => { handlePurchase("cc"); gtmOnboardingPaymentSubmitAttempt(); }}
            />
          </div>}
        </div>


        <div className="w-full max-lg:max-w-[618px] border-[1px] border-secondary rounded-[15px] flex  max-sm:max-w-[330px] max-sm:p-[15px] max-w-[422.63px] p-8 max-lg:p-6">
          <div className="mx-auto w-full flex flex-col justify-between">
            {!couponLoading && !couponValidating && data ?
              <CheckoutOrderSummary
                titleStyles="!font-bold !mb-4"
                cancelAnytimeClassName="font-normal !text-sm"
                selectedPlan={selectedPlan}
                isLoading={isFetching}
                data={data}
                fromRegistration
                isShopify={isShopifyPlanSelected}
                handleVatNumberUpdate={handleVatNumberUpdate}
                handleAlreadyTrialChange={handleAlreadyTrial}
                alreadyTrial={alreadyTrial}
                couponUsed={couponUsed}
              />
              : <Loading fill="fill-primaryPurple dark:fill-purple500 mx-auto mb-10" width={40} height={40} />
            }
            {hidePayNowBtn ? "" : (
              <div className={`flex justify-center max-sm:hidden mt-[53px] max-lg:mt-8`}>
                <Button
                  title="COMPLETE PAYMENT"
                  color="smoothGreen"
                  height="h-16"
                  titleClassName="!font-bold !text-base"
                  loading={paypalFetching || payoneerFetching || isLoading || ccPaymentLoading}
                  className="max-w-[358px] text-white bg-smoothGreen font-bold  hover:!border-smoothGreen hover:!text-darkBlue w-full shadow-[2px_5px_30px_-4px_rgba(28,_163,_147,_0.15)]"
                  handleClick={() => { handlePurchase("cc"); gtmOnboardingPaymentSubmitAttempt(); }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-center max-sm:pt-0 pt-[52px] pb-5 max-lg:hidden">
        <Trusted trustedPaymentsTxtStyles="hidden" />
      </div>
    </div>

  );
};
export default Payment;
