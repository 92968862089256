import Button from "components/Button";
import FilterCheckbox from "components/Filters/FilterCheckbox";
import Modal from "components/Modal";
import useModal from "hooks/useModal";

import { exclude } from "pages/ZikPro/AutopilotScan";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useExportFolderMutation } from "store/api/foldersApi";
import {
  useExportFolderItemsMutation,
  useExportProductsMutation
} from "store/api/productManagementApi";
import { FolderFilters } from "types/services/productMangement";

interface Props {
  helpers: ReturnType<useModal>;
  type: "Turbo" | "AutoPilot" | "FolderItems" | "Folder";
  folderId?: number;
  folderFilters?: FolderFilters;
  selectedItems?: number[];
  btnTitle?: string;
  allChecked: boolean;
}

export const ExportItems = ({
  helpers,
  type,
  folderId,
  selectedItems,
  folderFilters,
  btnTitle,
  allChecked,
}: Props) => {
  const [value, setValue] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [exportProducts, { isSuccess: exportProductsSuccess }] =
    useExportProductsMutation();
  const [exportFolderItems, { isSuccess: exportItemsSuccess }] =
    useExportFolderItemsMutation();
  const [exportFolder, { isSuccess: exportFolderSuccess }] =
    useExportFolderMutation();

  const { id } = useParams();
  const onChange = (newVal: string) => {
    const find = value.includes(newVal);
    const remove = value.filter((item) => item !== newVal);
    setValue((prev) => (find ? remove : [...prev, newVal]));
  };
  const isSuccess =
    type === "Folder"
      ? exportFolderSuccess
      : type === "FolderItems"
        ? exportItemsSuccess
        : exportProductsSuccess;

  const onError = () => {
    toast.error("Something went wrong, please try again");
    setValue([]);
    helpers.close();
  };

  const onSuccess = () => {
    toast.success("Products has been exported successfully.");
    setValue([]);
    helpers.close();
    setIsLoading(false);
  };

  const onExport = () => {
    const commonPayload = {
      isRestrictVeroWords: Boolean(value.includes("vero")),
      isRestrictRiskyWords: Boolean(value.includes("restricted")),
    };

    const productsCommonPayload = {
      singleScannedItemIds: allChecked ? [] : selectedItems || [],
      allChecked,
    };

    setIsLoading(true);
    if (type === "Folder") {
      exportFolder({
        folderId: folderId || 0,
        ...commonPayload,
      })
        .unwrap()
        .then((result) => {
          toast.success("Products has been exported successfully.");
          if (result?.message) {
            window.open(result.message, '_self');
          }

          setValue([]);
          helpers.close();
          setIsLoading(false);
        })
        .catch(onError);
    } else if (type === "FolderItems" && folderFilters) {
      exportFolderItems({
        folderId: Number(id) || 0,
        ...productsCommonPayload,
        ...commonPayload,
        ...(folderFilters.ebayMaxPrice !== 0 && {
          ebayMaxPrice: folderFilters.ebayMaxPrice,
        }),
        ...(folderFilters.ebayMinPrice !== 0 && {
          ebayMinPrice: folderFilters.ebayMinPrice,
        }),
        ...(folderFilters.multiMinPrice !== 0 && {
          multiMinPrice: folderFilters.multiMinPrice,
        }),
        ...(folderFilters.multiMaxPrice !== 0 && {
          multiMaxPrice: folderFilters.multiMaxPrice,
        }),
        ...(folderFilters.minProfit !== 0 && {
          minProfit: folderFilters.minProfit,
        }),
        ...(folderFilters.maxProfit !== 0 && {
          maxProfit: folderFilters.maxProfit,
        }),
        ...(folderFilters.minSales !== 0 && {
          minSales: folderFilters.minSales,
        }),
        ...(folderFilters.maxSales !== 0 && {
          maxSales: folderFilters.maxSales,
        }),
        ...(folderFilters.searchText !== "" && {
          searchText: folderFilters.searchText,
        }),
      })
        .unwrap()
        .then((result) => {
          toast.success("Products has been exported successfully.");
          if (result?.message) {
            window.open(result.message, '_self');
          }

          setValue([]);
          helpers.close();
          setIsLoading(false);
        })
        .catch(onError);
    } else {
      exportProducts({
        type: type === "AutoPilot" ? "autopilot" : "turboscanner",
        scanId: Number(id) || 0,
        ...productsCommonPayload,
        ...commonPayload,
      })
        .unwrap()
        .then((result) => {
          toast.success("Products has been exported successfully.");
          if (result?.message) {
            window.open(result.message, '_self');
          }

          setValue([]);
          helpers.close();
          setIsLoading(false);
        })
        .catch(onError);
    }
  };

  // useSignalRConnection(isSuccess, onSuccess);

  return (
    <Modal
      helpers={helpers}
      className="flex flex-col items-center "
      cardClassName="pt-[21px] pb-[26px]"
      maxWidth="max-w-[617px]"
      title={allChecked ? "Export all Items" : "Export selected Items"}
      buttonCloseClassName="!top-5 !right-4"
      alwaysOpen={isLoading}
    >
      <div className="px-5 mb-[27px]">
        <FilterCheckbox
          options={exclude(true)}
          multiselect
          value={value}
          className="w-full flex !flex-row !gap-6"
          handleChange={(newVal) => onChange(newVal.toString())}
        />
      </div>
      <div className="w-full flex justify-center">
        <Button
          title={btnTitle || "Export to CSV"}
          handleClick={onExport}
          loading={isLoading}
          height="h-9"
          className="max-w-[188px] mx-auto dark:bg-deepSpace900"
          color="smoothGreen"
        />
      </div>

      {isLoading && <div className="font-medium  text-success text-center mt-5 px-5 text-sm	">We're working on your export. Please remain on this page until it's ready.</div>}

    </Modal>
  );
};
