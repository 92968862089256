import { useEbaySubCountQuery } from "store/api/quickSettingsApi";
import { formatCurrency } from "utils/formatCurrency";
import EbayPlanPeriodSelect from "./EbayPeriodSelect";

interface Props {
  activePlan: string;
  handlePlanChange: (type: string) => void;
  shortSwitchStyles?: string;
  longSwitchStyles?: string;
  showLifetime?: boolean;
}
const switches = ["monthly", "yearly", "lifetime"];

const PlanSwitch = ({ activePlan, handlePlanChange, shortSwitchStyles, longSwitchStyles, showLifetime }: Props) => {
  const { data: ebaySubCount } = useEbaySubCountQuery();

  const hasEbayPlan = ebaySubCount?.result !== 0;
  return hasEbayPlan || showLifetime ? (
    <div className={`rounded-full flex max-sm:flex-wrap max-sm:justify-center row-reverse justify-end gap-2 w-full mx-auto ${longSwitchStyles}`}>
      {switches.map((itm, i) => (
        <label key={i} htmlFor={itm} className="mx-2 flex items-center gap-2 cursor-pointer hover:text-primaryPurple capitalize group">
          <button className={` w-4 h-4 border-box absolute rounded-full ${itm === activePlan ?
            "border-smoothGreen bg-transparent dark:border-paradiseBlue  border-[1.5px]" :
            "dark:group-hover:bg-purple500 group-hover:bg-primaryPurple dark:border-grey100 dark:group-hover:border-purple500 dark:bg-grey300 group-hover:border-primaryPurple bg-white border-darkBlue border"} 
       `} onClick={() => handlePlanChange(itm)}>
            {itm === activePlan ? (
              <span className="w-2.5 h-2.5 bg-smoothGreen rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
            ) : ""}
          </button>
          <input type="radio" id={itm} name={itm} value={itm}
            className="w-4 h-4 cursor-pointer invisible"
            checked={activePlan === itm} onChange={() => handlePlanChange(itm)}
          />
          <span className="cursor-pointer font-medium dark:text-grey100">{itm}</span>
          {itm === "yearly" ? <span className="text-xs text-primaryPurple bg-grey100 py-1 px-2 rounded-[4px] dark:text-purple500">Save up to {formatCurrency(359)}</span> : ""}
        </label>
      ))}
    </div>
  ) : <EbayPlanPeriodSelect selectedPeriod={activePlan} handleClick={handlePlanChange} wrapperStyles={shortSwitchStyles} />
};

export default PlanSwitch;
