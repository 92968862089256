const for1stMonth =
  "<span class='text-neutral3 text-[10px] font-normal ml-[7px]'>for 1st month</span>";
const lifetimeAccess =
  "<span class='text-[18px] font-medium text-smoothGreen dark:text-paradiseBlue ml-[10px]'>lifetime access</span>";

export const proPlus = {
  period: "",
  planList: [
    {
      type: "StandardM",
      planName: "Pro+",
      planId: "pro-plus",
      planDescription:
        "Use big data and automations for increasing market share to the next level.",
      isDiscount: true,
      planPrice: 59.99,
      planDiscountPrice: 35.99,
      planPeriod: for1stMonth,
      popularBadge: false,
      planNote: "",
      highlight: true,
    },
    {
      type: "StandardY",
      planName: "Pro+",
      planId: "pro-plus",
      planDescription:
        "Use big data and automations for increasing market share to the next level.",
      isDiscount: true,
      planPrice: 599,
      planDiscountPrice: 419.91,
      planPeriod: "",
      popularBadge: false,
      planNote: "",
      highlight: true,
    },
    {
      type: "ProPlusLT",
      planName: "Pro+",
      planId: "pro-plus",
      planDescription:
        "Use big data and automations for increasing market share to the next level.",
      isDiscount: true,
      planPrice: 3594,
      planDiscountPrice: 1797,
      planPeriod: lifetimeAccess,
      popularBadge: false,
      planNote: "",
      highlight: true,
    },
  ],
};

export const enterprise = {
  period: "",
  planList: [
    {
      type: "EnterpriseM",
      planName: "Enterprise",
      planId: "enterprise",
      planDescription:
        "Provides complete automation and maximum data to capture all possible opportunities.",
      isDiscount: true,
      planPrice: 89.99,
      planDiscountPrice: 44.99,
      planPeriod: for1stMonth,
      popularBadge: false,
      planNote: "",
      highlight: true,
    },
    {
      type: "EnterpriseY",
      planName: "Enterprise",
      planId: "enterprise",
      planDescription:
        "Provides complete automation and maximum data to capture all possible opportunities.",
      isDiscount: true,
      planPrice: 899,
      planDiscountPrice: 539.92,
      planPeriod: "",
      popularBadge: false,
      planNote: "",
      highlight: true,
    },
    {
      type: "EnterpriseLT",
      planName: "Enterprise",
      planId: "enterprise",
      planDescription:
        "Provides complete automation and maximum data to capture all possible opportunities.",
      isDiscount: true,
      planPrice: 5994,
      planDiscountPrice: 2997,
      planPeriod: lifetimeAccess,
      popularBadge: false,
      planNote: "",
      highlight: true,
    },
  ],
};
