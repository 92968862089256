import { ReactComponent as CategoryResearch } from "assets/icons/category-research.svg";
import { ReactComponent as RatingEmpty } from "assets/icons/rating-empty.svg";
import Button from "components/Button";
import { FC } from "react";
import { SalesSpyConnectAppsList } from "types/services/salesSpy";



const tagStyles = "p-[5px] bg-grey100 dark:bg-grey500 rounded-[5px] font-medium text-sm w-fit flex items-center"
const StoreAppsItem: FC<SalesSpyConnectAppsList> = ({
    appName,
    category,
    iconUrl,
    rating,
    subCategory,
    shopifyAppStoreUrl,
    reviewCount,
}) => (
    <div className="flex items-center justify-between border-[1px] border-solid border-secondary rounded-10 bg-neutral1 dark:bg-grey700 dark:border-grey500 py-[9px] px-5">
        <div className="flex gap-5 items-center">
            <div className="bg-darkBlue dark:bg-grey200 w-[75px] h-[75px] rounded-10">
                <img className="w-full h-full rounded-10" src={iconUrl} alt={appName} /></div>
            <div className="flex flex-col">
                <h5 className="font-bold p-[5px] mb-1.5">{appName}</h5>
                <div className="flex gap-2.5 mb-[5px]">
                    <p className={`${tagStyles} gap-[5px]`}><RatingEmpty className="fill-[#E4C210] w-[7.5px] h-[7px] m-[2.5px]" /><span>{rating} Rating</span></p>
                    <p className={tagStyles}>{reviewCount} <span className="text-grey500 dark:text-grey800 ml-1">Reviews</span></p>
                </div>
                <p className={tagStyles}>
                    <span className="w-3 h-3 flex items-center justify-center mr-[5px]">
                        <CategoryResearch className="fill-darkBlue w-1.5 h-[5.5px]" />
                    </span>
                    {subCategory}</p>
            </div>
        </div>
        <Button title="View App" height="h-9" className="max-w-[128px] bg-white dark:bg-deepSpace900" isLink target="_blank" redirect={shopifyAppStoreUrl} color="smoothGreen" />
    </div>
)

export default StoreAppsItem;