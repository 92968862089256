import { ReactComponent as AliExpress } from "assets/icons/aliexpress-text.svg";
import { ReactComponent as Amazon } from "assets/icons/amazon-coming-soon.svg";
import { ReactComponent as Ebay } from "assets/icons/ebay-text.svg";
import { ReactComponent as Shopify } from "assets/icons/shopify-text.svg";
import Card from "components/Card";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import useDecodeToken from "hooks/useDecodeToken";
import useOutsideClick from "hooks/useOutsideClick";
import useResetApi from "hooks/useResetApi";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import { useGetSettingsQuery, useSaveSettingsMutation } from "store/api/quickSettingsApi";
import { DASHBOARD_MARKET_PLACES, DASHBOARD_MARKET_PLACES as MARKET_PLACES, setDashboardMarketplace } from "store/slices/marketplaceSlice";
import { setUserAccessToken } from "store/slices/userSlice";
import { GetMarketplace, SetAccessToken, SetMarketplace } from "utils/localStorage";

interface Props {
    isExpand?: boolean;
    handleClose?: () => void;
}

const Marketplace = ({ isExpand, handleClose }: Props) => {
    const [ref] = useOutsideClick<HTMLDivElement>(handleClose);
    const { isDarkMode } = useAppSelector((state) => state.themeSlice);
    const { data } = useGetSettingsQuery()
    const { dashboardMarketplace } = useAppSelector((state) => state.marketplaceSlice);
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();
    const navigate = useNavigate()
    const [saveSettings, { isSuccess }] = useSaveSettingsMutation();
    const user = useDecodeToken();
    const shopifyLocked = user.shopify_package_name.length < 1;
    const ebayLocked = user.package_name.length < 1;
    const onMarketplaceChange = (marketplace: MARKET_PLACES) => dispatch(setDashboardMarketplace(marketplace))
    const reset = useResetApi();
    const localMarketplace = GetMarketplace();
    useEffect(() => {
        if (isSuccess) {
            reset()
            navigate(PATH.dashboard.root)

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);
    const changeMarketplace = (marketplace: MARKET_PLACES) => {
        if (marketplace === MARKET_PLACES.shopify && shopifyLocked) {
            navigate(PATH.shopifyLocked)
        } else if ((marketplace === MARKET_PLACES.ebay || marketplace === MARKET_PLACES.aliexpress) && ebayLocked) {
            navigate(PATH.ebayLocked);
        } else {
            onMarketplaceChange(marketplace)

            saveSettings({
                //  sellerSite: marketplace === MARKET_PLACES.ebay ? ".com" : marketplace === MARKET_PLACES.aliexpress ? "aliexpress" : "shopify",
                amazonSite: marketplace === MARKET_PLACES.ebay ? "US" : marketplace === MARKET_PLACES.aliexpress ? "Ali" : "Ali",
                shippingLocation: marketplace === MARKET_PLACES.ebay ? "US" : "CN",
                competitionLocation: marketplace === MARKET_PLACES.aliexpress ? "AliExpress" : marketplace === MARKET_PLACES.shopify ? "shopify" : "US",
                breakEven: data?.result.breakEven,
                userId: 0,
            }).unwrap().then((res) => {
                SetAccessToken(res.message)
                dispatch(setUserAccessToken(res.message));
                handleClose && handleClose();
            });
            SetMarketplace(marketplace);

            if (marketplace === DASHBOARD_MARKET_PLACES.shopify) {
                navigate(PATH.shopify.marketInsights)
            }
            if (marketplace !== DASHBOARD_MARKET_PLACES.shopify && (dashboardMarketplace === DASHBOARD_MARKET_PLACES.shopify || shopifyLocked)) {
                if (marketplace === DASHBOARD_MARKET_PLACES.ebay && localMarketplace == DASHBOARD_MARKET_PLACES.aliexpress) {
                    return;
                }
                if (marketplace === DASHBOARD_MARKET_PLACES.aliexpress && localMarketplace == DASHBOARD_MARKET_PLACES.ebay) {
                    return;
                }
                navigate(PATH.dashboard.root)
            }


        }
    }

    const ebayActive = pathname === PATH.ebayLocked || (dashboardMarketplace === MARKET_PLACES.ebay && pathname !== PATH.shopifyLocked);
    const shopifyActive = pathname === PATH.shopifyLocked || (dashboardMarketplace === MARKET_PLACES.shopify && pathname !== PATH.ebayLocked)
    const itemContStyles = (isActive: boolean) => `${isActive ? "shadow-[0_0_0_2px_#6831FF_inset]" : ""} hover:bg-primaryPurple w-[155px] h-9 px-2 py-2.5 group rounded-full cursor-pointer`
    const iconStyles = isDarkMode ? "fill-white  group-hover:fill-white" : "fill-darkBlue group-hover:fill-white"

    return (
        <div
            className={`absolute ${isExpand ? "left-[182px]" : "left-[36px]"} top-[-20px]  text-xs z-50 pointer-events-auto`}
            ref={ref}
        >
            <Card
                className={`bg-white border-primaryPurple dark:border-purple500 dark:bg-deepSpace900 w-14rem p-[8px] flex items-center flex-col gap-[3px]`}
                rounded="rounded-[20px]"
                shadow="shadow-shadow4"
                bordered
            >
                <button onClick={() => changeMarketplace(MARKET_PLACES.ebay)}
                    className={itemContStyles(localMarketplace === MARKET_PLACES.ebay)}>
                    <Ebay className={iconStyles} />
                </button>
                <button onClick={() => changeMarketplace(MARKET_PLACES.shopify)}
                    className={itemContStyles(localMarketplace === MARKET_PLACES.shopify)}>
                    <Shopify className={iconStyles} />
                </button>
                <button onClick={() => changeMarketplace(MARKET_PLACES.aliexpress)}
                    className={itemContStyles(localMarketplace === MARKET_PLACES.aliexpress)}>
                    <AliExpress className={iconStyles} />
                </button>
                <button className="w-[155px] h-[36px] px-[8px] py-[10px] rounded-full cursor-pointer bg-[#F6F6F6] dark:bg-black900">
                    <Amazon className="fill-[#9F9F9F] dark:fill-[#535353]" />
                </button>
            </Card>
        </div>
    )
}

export default Marketplace