import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/Button";
import LabeledInput from "components/Input/LabeledInput";
import Select from "components/Select";
import { FC, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { PATH } from "routes/path";
import { useAddStoreMutation } from "store/api/quickSettingsApi";
import { SetAutoDsPage } from "utils/localStorage";
import { object, string } from "yup";

interface Props {
  isAutoDS: boolean
  onAutoDSChange: (val: boolean) => void
}
interface FormValues {
  token: string;
  store: string;
  type: string;
}

const listers = [
  { text: "Auto DS", value: "0" },
  { text: "Sale Freaks", value: "1" },
];

let integrationsSchema = (isAutoDS: boolean) =>
  object({
    token: isAutoDS
      ? string()
      : string()
        .required("Token is Required")
        .min(10, "Token must be minimum 10 characters"),
    store: isAutoDS ? string() : string().required("Store is Required"),
    type: string().required("Type is Required"),
  });

const AddStore: FC<Props> = ({ isAutoDS, onAutoDSChange }) => {
  const [addStore, { isLoading: addLoading }] = useAddStoreMutation();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(integrationsSchema(isAutoDS)),
    defaultValues: {
      token: "",
      store: "",
      type: "0",
    },
  });

  const onAutoDsConnect = (url: string) => {
    SetAutoDsPage(window.location.pathname + window.location.search);
    window.location.href = url;
  };

  useEffect(() => {
    if (watch("type") === "0") {
      onAutoDSChange(true);
    } else {
      onAutoDSChange(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("type")]);

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    if (values.type === "0") {
      onAutoDsConnect(PATH.auth.connectautods);
    } else {
      addStore({
        ...values,
        userId: 0,
      })
        .unwrap()
        .then(() => toast.success("Store added successfully"));
    }
  };

  return (
    <form
      className="px-8 flex flex-col items-center"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        control={control}
        name="type"
        render={({ field: { onChange, value } }) => (
          <>
            <Select
              className={`max-w-[192px] w-full ${isAutoDS ? "mb-7" : "mb-8"}`}
              options={listers}
              btnClassName="w-full"
              value={value}
              handleChange={onChange}
            />
            {errors.type?.message && (
              <p className={`text-errorRed text-xs mt-2`}>
                {errors.type?.message?.toString()}
              </p>
            )}
          </>
        )}
      />

      {!isAutoDS && (
        <div className="flex gap-[33px] mb-7">
          <Controller
            control={control}
            name="store"
            render={({ field: { onChange, value } }) => (
              <LabeledInput
                value={value}
                handleChange={onChange}
                placeholder="Type Store name"
                name="sfStoreName"
                variant="contained"
                labelClassName="text-sm ml-4"
                defaultBorderColor="border-darkBlue dark:border-grey100"
                className="border-2 rounded-[50px] !py-[7.34px] !px-5 after:hidden dark:bg-deepSpace900"
                wrapperClassName="!gap-2.5"
                label="Store name"
                message={errors.store?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="token"
            render={({ field: { onChange, value } }) => (
              <LabeledInput
                value={value}
                handleChange={onChange}
                placeholder="Add a Token"
                labelClassName="text-sm ml-4"
                wrapperClassName="!gap-2.5"
                variant="contained"
                defaultBorderColor="border-darkBlue dark:border-grey100"
                className="border-2 rounded-[50px] !py-[7.34px] after:hidden !px-5 dark:bg-deepSpace900"
                name="sfKey"
                label="Add Token"
                message={errors.token?.message}
              />
            )}
          />
        </div>
      )}

      <Button
        title={isAutoDS ? "Connect to AutoDS Store" : "Connect SaleFreaks Store"}
        type="submit"
        height="h-9"
        loading={addLoading}
        className="max-w-[233px] dark:bg-deepSpace900"
        color="smoothGreen"
      />
    </form>
  );
};

export default AddStore;
