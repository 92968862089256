import Tooltip from "components/Tooltip";
import { FC, ReactNode, useId } from "react";


interface Props {    
    firstData: string;
    secondData: string;
    title?: ReactNode | string;
}
const DualRowCell: FC<Props> = ({ firstData, secondData,title }) => {
    const id = useId();
    return (
        <>
            <div className="pl-2" data-tooltip-id={id}>
                <p className="font-bold">{firstData}</p>
                <p className="text-[10px]">{secondData}</p>
            </div>
            {title && <Tooltip anchorId={id} title={title} />}            
        </>
    )
}

export default DualRowCell