import { useGetFoldersCountQuery } from "store/api/foldersApi";
import { formatNumber } from "utils/formatCurrency";

interface Props {
    listId: number;
}

function FolderItemCount({ listId }: Props) {
    const { data } = useGetFoldersCountQuery(listId);


    return <p>{formatNumber(data?.result ? data.result : 0)}</p>;
}

export default FolderItemCount;
