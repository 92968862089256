import {
  BUSINESS_MODEL,
  MARKET_PLACES,
  SHIPPED_MODEL,
  SOURCE_MARKET_PLACES,
} from "types/sellerSettings";

export const eBaySites = [
  { value: MARKET_PLACES.ebay, text: "eBay.com" },
  { value: MARKET_PLACES.ebayUk, text: "eBay.co.uk" },
  { value: MARKET_PLACES.ebayDe, text: "eBay.de" },
  { value: MARKET_PLACES.ebayAu, text: "eBay.com.au" },
  { value: MARKET_PLACES.ebayFr, text: "eBay.fr" },
  { value: MARKET_PLACES.ebayIt, text: "eBay.it" },
  { value: MARKET_PLACES.ebayEs, text: "eBay.es" },
  { value: MARKET_PLACES.ebayCa, text: "eBay.ca" },
  { value: MARKET_PLACES.ebayMy, text: "eBay.com.my" },
];
export const marketplacesList = [
  { value: "eBay", text: "eBay" },
  { value: MARKET_PLACES.aliexpress, text: "Aliexpress" },
  { value: MARKET_PLACES.woocommerce, text: "Woocommerce" },
  { value: MARKET_PLACES.shopify, text: "Shopify" },
  { value: MARKET_PLACES.none, text: "Amazon" },
];

export const businessModelsList = (marketplace: MARKET_PLACES) => [
  { value: BUSINESS_MODEL.dropshipping, text: "Dropshipping" },
  { value: BUSINESS_MODEL.arbitrage, text: "Arbitrage" },
  { value: BUSINESS_MODEL.wholesale, text: "Wholesale" },
  { value: BUSINESS_MODEL.brandOwner, text: "Brand Owner" },
  ...(marketplace === MARKET_PLACES.ebayIt ||
  marketplace === MARKET_PLACES.ebayEs ||
  marketplace === MARKET_PLACES.ebayAu
    ? []
    : [
        {
          value: BUSINESS_MODEL.reseller,
          text: "Reseller",
        },
      ]),
];

const options = {
  AmazonUS: {
    value: SOURCE_MARKET_PLACES.amazon,
    text: "Amazon.com",
  },
  AmazonAU: {
    value: SOURCE_MARKET_PLACES.amazonAu,
    text: "Amazon.co.au",
  },
  AmazonUK: {
    value: SOURCE_MARKET_PLACES.amazonUk,
    text: "Amazon.co.uk",
  },
  Aliexpress: { value: SOURCE_MARKET_PLACES.aliexpress, text: "AliExpress" },
  Walmart: { value: SOURCE_MARKET_PLACES.walmart, text: "Walmart" },
  Alibaba: { value: SOURCE_MARKET_PLACES.alibaba, text: "Alibaba" },
  Ebay: { value: SOURCE_MARKET_PLACES.ebay, text: "eBay" },
  UnitedStates: { value: SHIPPED_MODEL.unitedStates, text: "United States" },
  Germany: { value: SHIPPED_MODEL.germany, text: "Germany" },
  UnitedKingdom: { value: SHIPPED_MODEL.unitedKingdom, text: "United Kingdom" },
  Australia: { value: SHIPPED_MODEL.australia, text: "Australia" },
  China: { value: SHIPPED_MODEL.china, text: "China" },
  France: { value: SHIPPED_MODEL.france, text: "France" },
  Italy: { value: SHIPPED_MODEL.italy, text: "Italy" },
  Spain: { value: SHIPPED_MODEL.spain, text: "Spain" },
  Canada: { value: SHIPPED_MODEL.canada, text: "Canada" },
  Malaysia: { value: SHIPPED_MODEL.malaysia, text: "Malaysia" },
};

export const sourceMarketplacesList = (marketplace: MARKET_PLACES) => {
  if (marketplace === MARKET_PLACES.ebay) {
    return [options.AmazonUS, options.Aliexpress, options.Walmart];
  } else if (marketplace === MARKET_PLACES.ebayUk) {
    return [options.AmazonUK, options.Aliexpress];
  } else if (
    marketplace === MARKET_PLACES.ebayAu ||
    marketplace === MARKET_PLACES.ebayDe
  ) {
    return [options.Aliexpress, options.Alibaba];
  } else if (marketplace === MARKET_PLACES.woocommerce) {
    return [options.Aliexpress, options.Ebay];
  } else if (marketplace === MARKET_PLACES.shopify) {
    return [options.Aliexpress, options.Alibaba];
  } else {
    return [options.Aliexpress, options.Alibaba];
  }
};
export const shippedPlaceList = (marketplace: MARKET_PLACES) => {
  if (marketplace === MARKET_PLACES.ebay) {
    return [options.UnitedStates, options.China];
  } else if (marketplace === MARKET_PLACES.ebayUk) {
    return [options.UnitedKingdom, options.China];
  } else if (marketplace === MARKET_PLACES.ebayDe) {
    return [options.Germany, options.China];
  } else if (marketplace === MARKET_PLACES.ebayAu) {
    return [options.Australia, options.China];
  } else if (marketplace === MARKET_PLACES.ebayFr) {
    return [options.France, options.China];
  } else if (marketplace === MARKET_PLACES.ebayIt) {
    return [options.Italy, options.China];
  } else if (marketplace === MARKET_PLACES.ebayEs) {
    return [options.Spain, options.China];
  } else if (marketplace === MARKET_PLACES.ebayCa) {
    return [options.Canada, options.China];
  } else if (marketplace === MARKET_PLACES.ebayMy) {
    return [options.Malaysia, options.China];
  } else {
    return [options.UnitedStates, options.China];
  }
};
