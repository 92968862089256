export const bulkScannerItemsFlags = {
  US: {
    name: "Amazon.com",
    tooltip: "Amazon.com",
    code: "US",
    allowClick: true,
  },
  UK: {
    name: "Amazon.co.uk",
    tooltip: "Amazon.co.uk",
    code: "UK",
    allowClick: true,
  },
  DE: {
    name: "Amazon.de",
    tooltip: "Coming Soon",
    code: "DE",
    allowClick: false,
  },
  FR: {
    name: "Amazon.fr",
    tooltip: "Coming Soon",
    code: "FR",
    allowClick: false,
  },
  CA: {
    name: "Amazon.ca",
    tooltip: "Coming Soon",
    code: "CA",
    allowClick: false,
  },
  AU: {
    name: "Amazon.au",
    tooltip: "Coming Soon",
    code: "AU",
    allowClick: false,
  },
  IT: {
    name: "Amazon.it",
    tooltip: "Coming Soon",
    code: "IT",
    allowClick: false,
  },
  WAL: {
    name: "Walmart.com",
    tooltip: "Walmart.com",
    code: "WAL",
    allowClick: true,
  },
};
