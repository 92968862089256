import { ReactComponent as Dollar } from "assets/icons/dollar.svg";
import { ReactComponent as Menu } from "assets/icons/menu.svg";
import { ReactComponent as Pen } from "assets/icons/pen.svg";
import { ReactComponent as ShippingLocation } from "assets/icons/shipping-location.svg";
import { ReactComponent as Star } from "assets/icons/star.svg";

import {
  conditionList,
  listingsList,
  shippingLocations
} from "assets/data/filters";
import Card from "components/Card";
import FilterCheckbox from "components/Filters/FilterCheckbox";
import FiltersCard from "components/Filters/FiltersCard";
import MinMaxFilter from "components/Filters/MinMaxFilter";
import Input from "components/Input";
import Select from "components/Select";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGetSettingsQuery } from "store/api/quickSettingsApi";
import { PostCatResRequest } from "types/services/catRes";

interface Props {
  filters: PostCatResRequest;
  handleFilterChange: (
    newVal: string | number | Date | null,
    name: keyof PostCatResRequest,
    type?: "min" | "max"
  ) => void;
}
const CategoryResearchFilters = ({ filters, handleFilterChange }: Props) => {
  const { data } = useGetSettingsQuery();
  const { t } = useTranslation(['home']);

  useEffect(() => {
    if (data) {
      handleFilterChange(data.result.listingType, "type");
      handleFilterChange(data.result.condition, "condition");
      handleFilterChange(data.result.shippingLocation, "location");
      handleFilterChange(data.result.minFeedback, "minFeedback");
      handleFilterChange(data.result.maxFeedback, "maxFeedback");
      handleFilterChange(data.result.productMinPrice, "minPrice");
      handleFilterChange(data.result.productMaxPrice, "maxPrice");
      handleFilterChange(data.result.excludePhrase, "exclude");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Card className=" px-3.5 py-5 mb-5" bg="dark:bg-[#242424] bg-white" rounded="rounded-[20px]">
      <div className="grid xl:grid-cols-6 lg:grid-cols-3 md:grid-cols-2 grid-flow-row gap-x-5 gap-y-8">
        <FiltersCard
          Icon={Menu}
          title={t('ListingType')}
          infoTitle="Select if you want to see fixed price listings, auctions or both."
          iconClassName="w-[17px] h-[11px]"
        >
          <FilterCheckbox
            options={listingsList}
            handleChange={(newValue) => handleFilterChange(newValue, "type")}
            value={filters.type}
          />
        </FiltersCard>
        <FiltersCard
          Icon={Star}
          infoTitle="Choose the condition of the items you want to analyze."
          title={t('Condition')}
          iconClassName="w-4"
        >
          <FilterCheckbox
            options={conditionList}
            handleChange={(newValue) =>
              handleFilterChange(newValue, "condition")
            }
            value={filters.condition}
          />
        </FiltersCard>
        <FiltersCard
          Icon={ShippingLocation}
          title={t('ShippingLocation')}
          titleWrapperClassName="mb-[8px]"
          infoTitle="Filter data to items shipped from a specific location"
          iconClassName="w-4.5 h-[11px]"
        >
          <Select
            options={shippingLocations}
            btnClassName="w-full"
            value={filters.location}
            handleChange={(newValue) =>
              handleFilterChange(newValue, "location")
            }
          />
        </FiltersCard>
        <FiltersCard
          Icon={Dollar}
          title={t('Price')}
          infoTitle="Filter items based on their current eBay selling price."
          iconClassName="w-4 h-4"
        >
          <MinMaxFilter
            value={{ min: filters.minPrice, max: filters.maxPrice }}
            inputWidth="w-16"
            name="price"
            handleChange={(newVal, type) =>
              handleFilterChange(
                newVal,
                type === "min" ? "minPrice" : "maxPrice"
              )
            }
          />
        </FiltersCard>
        <FiltersCard
          Icon={Star}
          title={t('Feedback')}
          infoTitle="The total amount of feedback that the seller has"
          iconClassName="w-4"
        >
          <MinMaxFilter
            value={{ min: filters.minFeedback, max: filters.maxFeedback }}
            name="feedbacks"
            handleChange={(newVal, type) =>
              handleFilterChange(
                newVal,
                type === "min" ? "minFeedback" : "maxFeedback"
              )
            }
            inputWidth="w-16"
          />
        </FiltersCard>
        <FiltersCard
          Icon={Pen}
          titleWrapperClassName="!mb-8"
          title={t('ExcludePhrase')}
          infoTitle="Filter out items with specific keywords. For example, if you're searching the smartphone niche but you dont want to see Apple products, write Apple. To exclude more phrases, seperate each with a comma (,)."
          iconClassName="w-4 h-4"
        >
          <Input
            value={filters.exclude}
            handleChange={(newVal) => handleFilterChange(newVal, "exclude")}
            erase
            placeholder="e.g iphone 4 or s4 s5 s6"
          />
        </FiltersCard>
      </div>
    </Card >
  );
};

export default CategoryResearchFilters;
