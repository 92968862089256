export const crAliFilters = {
  storeName: "",
  selectedRange: 180,
  minSales: null,
  maxSales: null,
  minRating: null,
  maxRating: null,
  minPrice: null,
  maxPrice: null,
  minVotes: null,
  maxVotes: null,
};

export const crEbayFilters = {
  competitor: "",
  dateRange: "30,14,7",
  sevenDaysMinSales: null,
  sevenDaysMaxSales: null,
  fourteenDaysMinSales: null,
  fourteenDaysMaxSales: null,
  twentyOneDaysMinSales: null,
  twentyOneDaysMaxSales: null,
  thirtyDaysMinSales: null,
  thirtyDaysMaxSales: null,
  minCurrentPrice: null,
  maxCurrentPrice: null,
  totalSalesMin: null,
  totalSalesMax: null,
  searchText: "",
  sortColumn: "",
  start: 0,
  sortColumnDirection: "des",
  refreshPool: false,
  sourceWebsite: "",
  marketplace: "",
  getDetails: true,
};

export const crEbayData = {
  activeListings: 0,
  isAddedFavourite: false,
  averagePrice: 0,
  quantitySold: 0,
  breakEven: 0,
  countryCode: "",
  countryName: "",
  currency: "$",
  data: [],
  feedback: "",
  hasBulk: false,
  isAddedToBulkList: false,
  isAliDroppship: false,
  isAmzDropshipUK: false,
  isAmzDropshipUS: false,
  isWalmartDropship: false,
  maxLimit: 0,
  message: "",
  username: "",
  recordsFiltered: 0,
  recordsTotal: 0,
  sellThrough: 0,
  sellerName: "",
  site: "",
  totalEarnings: 0,
  searchedUserId: 0,
  getDetails: true,
};

export const crAliData = {
  data: [],
  store: {
    aliexpresStoreId: 0,
    averagePrice: 0,
    communication: null,
    countryName: "",
    dateRegistered: null,
    dateUpdatedNew: null,
    feedbackCount: 0,
    itemAsDescribed: 0,
    lastUpdatedAt: null,
    ownermemberId: 0,
    positiveFeedback: 0,
    revenue14Days: 0,
    revenueMonth: 0,
    revenueThreeMonth: 0,
    revenueTotal: 0,
    revenueWeek: 0,
    sales2Week: 0,
    salesMonth: 0,
    salesThreeMonth: 0,
    salesTotal: 0,
    salesWeek: 0,
    shippingSpeed: 0,
    statsLastUpdatedAt: "",
    storeName: "",
  },
  added: false,
  message: "",
  sellThrough: false,
  successfulListings: 0,
};
