import ProductNameCell from "components/ProductNameCell";
import {
  CURRENCY_SIGNS,
  formatCurrency,
  formatNumber
} from "utils/formatCurrency";

import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";
import { ReactComponent as Export } from "assets/icons/export.svg";
import { ReactComponent as LineGraph } from "assets/icons/line-graph.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";

import Button from "components/Button";
import HoveredIcon from "components/HoveredIcon";
import { CURRENCY } from "types/currency";

import { ColumnDef } from "@tanstack/react-table";
import InfoTooltip from "components/Card/InfoTooltip";
import CutLongTitle from "components/CutLongTitle";
import TableCheckbox from "components/Table/TableCheckbox";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { PATH } from "routes/path";
import { AliGrowthList } from "types/services/zikPro";
import { makeQueryParams } from "utils/makeQueryParams";

export const aliGrowthCols = (
  handleAnalyzeClick: (item: AliGrowthList) => void
): ColumnDef<AliGrowthList>[] => [
    {
      header: ({ table }) => (
        <TableCheckbox
          {...{
            checked: table.getIsAllPageRowsSelected(),
            indeterminate: table.getIsSomePageRowsSelected(),
            onChange: table.getToggleAllPageRowsSelectedHandler(),
          }}
        />
      ),
      minSize: 50,
      accessorKey: "aliid",
      cell: ({ row }) => (
        <TableCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
      enableSorting: false,
      meta: {
        hideBorder: true,
      },
    },

    {
      header: ({ table }) =>
        table.getSelectedRowModel().flatRows.length > 0 ? (
          <CSVLink
            className="w-full "
            filename="AliGrowthScanner"
            data={table.getSelectedRowModel().rows.map(({ original: item }) => ({
              AliId: item.aliid,
              Title: item.title,
              Shipping: "Standard",
              Rating: item.itemRating,
              Votes: item.feedbackCount,
              Sales: item.selectedColumnSales,
              Price: item.price,
            }))}
          >
            <Button
              title="Export"
              Icon={Export}
              height="h-9"
              className="min-w-[117px] font-normal dark:bg-deepSpace900"
            />
          </CSVLink>
        ) : (
          "Seller"
        ),
      accessorKey: `storeName`,
      minSize: 120,
      cell: ({ row, table, row: { original } }) =>
        row.getIsSelected() && !table.getIsAllPageRowsSelected() ?
          <CSVLink
            className="w-full flex justify-center"
            filename="AliGrowthScanner"
            data={[
              {
                AliId: original.aliid,
                Title: original.title,
                Shipping: "Standard",
                Rating: original.itemRating,
                Votes: original.feedbackCount,
                Sales: original.selectedColumnSales,
                Price: original.price,
              },
            ]}
          >
            <Button
              title="Export"
              Icon={Export}
              height="h-9"
              className=" max-w-[117px] mx-auto font-normal dark:bg-deepSpace900"
            />
          </CSVLink> : (
            <div className="flex items-center">
              <div className="w-[110px]">
                <CutLongTitle title={original.storeName} length={15} />
              </div>
              <Link
                to={`${PATH.competitorResearch.aliexpress}?search=${original.storeName}`}
                target="_blank"
                rel="noreferrer"
              >
                <HoveredIcon
                  Icon={CompetitorResearch}
                  iconClassName="h-4"
                  title="Scan Seller"
                />
              </Link>
            </div>
          )
      ,
      meta: {
        className: "max-xl:min-w-[150px]",
        align: "justify-start",
        alignHead: "justify-between"
      },
    },
    {
      header: "Image",
      enableSorting: false,
      accessorKey: "mainImage",
      cell: (image) => (
        <div className="w-[115px] pl-5">
          <ProductNameCell
            img={String(image.getValue())}
            height="65px"
            width="72px"
          />
        </div>
      ),
      meta: {
        tooltipTitle: "Image",
        align: "justify-center"
      }
    },
    {
      header: "Title",
      enableSorting: true,
      accessorKey: "title",
      minSize: 200,
      cell: ({ row: { original } }) => (
        <div className="px-4">
          <Link
            className="text-left cursor-pointer hover:text-primaryPurple dark:hover:text-purple500 max-xl:"
            to={original.itemUrl}
            target="_blank"
            rel="noreferrer"
          >
            <CutLongTitle title={original.title} length={60} />
          </Link>
        </div>
      ),
      meta: {
        className: "max-xl:min-w-[400px]",
        align: "justify-center",
        alignHead: "justify-between"
      },
    },
    {
      header: "Shipping",
      enableSorting: true,
      minSize: 70,
      accessorKey: "shippingSpeed",
      cell: () => "Standard",
      meta: {
        align: "justify-center"
      },
    },
    {
      header: "Store Feedback",
      enableSorting: true,
      accessorKey: "feedbackCount",
      cell: (count) => formatNumber(String(count.getValue())),
      meta: {
        tooltipTitle:
          "Feedback of store on AliExpress",
        align: "justify-center"
      },
    },
    {
      header: "Reviews",
      enableSorting: true,
      minSize: 60,
      accessorKey: "itemFeedback",
      cell: (sales) => formatNumber(String(sales.getValue())),
      meta: {
        tooltipTitle:
          "Reviews are the total amount of reviews left by customers on Aliexpress.",
        align: "justify-center"
      },
    },
    {
      header: "Rating",
      enableSorting: true,
      accessorKey: "itemRating",
      minSize: 60,
      cell: (rating) => formatNumber(String(rating.getValue())),
      meta: {
        tooltipTitle:
          "The average product rating that customers have given this product (between 0 to 5).",
        align: "justify-center"
      },
    },
    {
      header: () => <div className="flex gap-1">
        <span>Sales</span>
        <InfoTooltip title="Total Sales from the last 6 months" />
      </div>,
      enableSorting: true,
      accessorKey: "currency",
      cell: ({ row: { original } }) => formatNumber(original.selectedColumnSales),
      meta: {
        align: "justify-center",
        tooltipTitle: "Total Sales from the last 6 months"
      }
    },

    {
      header: "Price",
      enableSorting: true,
      accessorKey: "price",
      minSize: 50,
      cell: ({ row: { original } }) =>
        formatCurrency(
          original.price,
          CURRENCY.none,
          original.currency as CURRENCY_SIGNS
        ),
      meta: {
        tooltipTitle:
          "Price of item",
        align: "justify-center"
      },
    },
    {
      header: () => <SearchIcon className="fill-darkBlue dark:fill-grey100" />,
      enableSorting: false,
      minSize: 40,
      accessorKey: "itemAsDescribed",
      cell: ({ row: { original } }) => (
        <HoveredIcon
          Icon={SearchIcon}
          iconClassName="w-3.5"
          handleClick={() => handleAnalyzeClick(original)}
        />
      ),
      meta: {
        tooltipTitle:
          "Click the icon to see more product information using the product analyzer.",
        alignHead: "justify-center"
      },
    },
    {
      header: () => <LineGraph className="fill-darkBlue dark:fill-grey100" />,
      enableSorting: false,
      accessorKey: "slope",
      minSize: 40,
      cell: ({ row: { original } }) => (
        <Link
          to={makeQueryParams(PATH.productResearch.ebay, { search: encodeURIComponent(original.title) })}
          target="_blank"
          rel="noreferrer"
        >
          <HoveredIcon Icon={LineGraph} iconClassName="w-3.5" />
        </Link>
      ),
      meta: {
        tooltipTitle:
          "Search the product title on eBay using the product research tool",
        alignHead: "justify-center"
      },
    },
  ];
