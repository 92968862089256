import Autopilot from "assets/images/autopilot.png";

export const bulkScanner = {
  headingPlan: "Pro+",
  title:
    "Use ZIK's <span class='text-primaryPurple dark:text-purple500'>Bulk Scanner</span> to scan your competitors' items and automatically find their best selling products on Amazon.Com, Amazon.co.uk and Walmart.",
  description:
    "You will also gain access to ZIK's 500 Best Selling eBay Items and ZIK's Ali Growth Scanner.",
  video: "https://player.vimeo.com/video/758635251?h=749aa182e2",
  bullets: [
    "Scan eBay sellers and source items they've setting from Amazon and Walmart",
    "Filter 1000's of items by sales, price and estimated profit in seconds",
    "Filter your store with the hottest items in just a few clicks.<br />This advanced tool is perfect for those with large inventoriesor<br /> those who want to scale fast",
  ],
};
export const bestSellingItems = {
  headingPlan: "Pro+",
  title:
    "Use ZIK's<span class='text-primaryPurple dark:text-purple500'> 500 Best Selling eBay Items</span> to uncover trending products selling in the last 48 hours - before everyone else.",
  description:
    "You will also gain access to ZIK's Bulk Scanner and ZIK's Ali Growth Scanner.",
  image: Autopilot,
  bullets: [
    "Scan eBay sellers and source items they've setting from Amazon and Walmart",
    "Filter 1000's of items by sales, price and estimated profit in seconds",
    "Filter your store with the hottest items in just a few clicks.<br />This advanced tool is perfect for those with large inventoriesor<br /> those who want to scale fast",
  ],
};
export const aliGrowthScanner = {
  headingPlan: "Pro+",
  title:
    "Use ZIK's <span class='text-primaryPurple dark:text-purple500'>Ali Growth Scanner</span> to unlock the world's biggest AliExpress products database with over 50M products.",
  description:
    "You will also gain access to ZIK's 500 Best Selling eBay Items and ZIK's Bulk Scanner.",
  video: "https://www.youtube.com/embed/R4Ablp3VfVI",
  bullets: [
    "Scan eBay sellers and source items they've setting from Amazon and Walmart",
    "Filter 1000's of items by sales, price and estimated profit in seconds",
    "Filter your store with the hottest items in just a few clicks.<br />This advanced tool is perfect for those with large inventoriesor<br /> those who want to scale fast",
  ],
};
export const turboScanner = {
  headingPlan: "Enterprise",
  title:
    "Use ZIK's <span class='text-primaryPurple dark:text-purple500'>Turbo Scanner</span> to find thousands of proftable items on Amazon.com, Amazon.com.uk and Walmart and automatically upload them to your store with Zik's integrations.",
  description: "You will also gain access to all ZIK's Enterprise features.",
  video: "https://player.vimeo.com/video/747644015?h=e97eaff9d3",
  bullets: [
    "See large quantity of items being dropshiped",
    "Easily find similar products",
    "List of products on the go, direcly from Autopilot ",
  ],
};
export const autoPilot = {
  headingPlan: "Enterprise",
  title:
    "Use ZIK's  <span class='text-primaryPurple dark:text-purple500'>Autopilot</span> to find thousands of profitable items from Amazon.com, Amazon.co.uk, Walmart and AliExpress and automatically upload them to your store with ZIK’s integrations.",
  description: "You will also gain access to all ZIK's Enterprise features.",
  image: Autopilot,
  bullets: [
    "See large quantity of items being dropshiped",
    "Easily find similar products",
    "List of products on the go, direcly from Autopilot ",
  ],
};
