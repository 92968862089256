import { FindKeywords } from "hooks/useFindKeywords";
import { FC } from "react";
import ProductNameIcon from "./ProductNameIcon";


interface Props {
  keywords: ReturnType<FindKeywords>;
  isDropshipper: boolean;
  checkKeywords?: boolean;
  isAliDropship?: boolean;
  isAmzDropshipUK?: boolean;
  isWalmartDropship?: boolean;
  isAmzUSDropship?: boolean;
}
const ProductIcons: FC<Props> = ({ isDropshipper, keywords, checkKeywords, isAliDropship,
  isAmzDropshipUK,
  isWalmartDropship,
  isAmzUSDropship, }) =>
  isDropshipper || keywords.foundVero || keywords.foundRisky ? (
    <div className="flex items-center gap-[9px] mt-[5px]">
      {isDropshipper && <ProductNameIcon type="dropshipper" />}
      {checkKeywords && keywords.foundVero && <ProductNameIcon type="vero" />}
      {checkKeywords && keywords.foundRisky && <ProductNameIcon type="risk" />}
      {(isAmzUSDropship || isAmzDropshipUK) && <ProductNameIcon type="amazondropship" />}
      {isWalmartDropship && <ProductNameIcon type="walmartdropship" />}
      {isAliDropship && <ProductNameIcon type="alidropship" />}
    </div>
  ) : (
    <></>
  );
export default ProductIcons;
