import StatsCard from "components/Card/StatsCard";
import Header from "components/Header";
import HeaderIcon, { HeaderVariants } from "components/Header/Icon";
import HeaderTab from "components/Header/Tab";
import Search from "components/Search";
import { useEffect, useRef, useState } from "react";
import {
  formatCurrency,
  formatNumber,
  formatPercent
} from "utils/formatCurrency";
import ProductResearchAliexpressList from "./components/lists/Aliexpress";
//Icons
import { helpVideos } from "assets/data/helpVideos";
import { ReactComponent as Dollar } from "assets/icons/dollar.svg";
import { ReactComponent as Flag } from "assets/icons/flag.svg";
import { ReactComponent as Hammer } from "assets/icons/hammer.svg";
import { ReactComponent as Menu } from "assets/icons/menu.svg";
import { ReactComponent as Rocket } from "assets/icons/rocket.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import HeaderHelpVideo from "components/Header/HelpVideo";
import SaveSearch from "components/SaveSearch";
import Toaster from "components/Toast";
import { ErrorCodes } from "config/errorMsgs";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import useModal from "hooks/useModal";
import useSearchHistory from "hooks/useSearchHistory";
import LoadingPage from "pages/Loading";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PATH } from "routes/path";
import { usePrAliFilterMutation } from "store/api/prApi";
import { useGetSettingsQuery } from "store/api/quickSettingsApi";
import { handlePrSearchChange, setAliInitialFilters } from "store/slices/prSlice";
import { HISTORY_KEYS } from "utils/localStorage";
import { makeQueryParams } from "utils/makeQueryParams";
import AliexpressFilters from "./components/filters/Aliexpress";

const ProductResearchAliexpress = () => {
  const {
    aliData: { result },
    filters: allFilters,
  } = useAppSelector((state) => state.prSlice);
  const data = result;

  const { search, aliexpress: filters } = allFilters;
  const [hideFilters, setHideFilters] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchHistory = useSearchHistory(HISTORY_KEYS.prAliSearch, search);
  const dataFetchedRef = useRef(false);
  const { data: settings } = useGetSettingsQuery();
  const { t } = useTranslation(['home']);
  const dispatch = useAppDispatch();
  const modal = useModal();
  const [postedSearch, setPostedSearch] = useState(search)
  const navigate = useNavigate();
  const onHideFilters = () => setHideFilters((prev) => !prev);
  const [postAliFilters, { isLoading, data: aliData, isError }] =
    usePrAliFilterMutation();
  const aliexpessFiltersPath = makeQueryParams(PATH.productResearch.root, { tab: "aliexpress" });
  const onSearchChange = (newVal: string) =>
    dispatch(handlePrSearchChange(newVal));

  useEffect(
    () => {
      if (data?.message !== "" || isError) {
        if (data?.message !== ErrorCodes.Success) {
          navigate(aliexpessFiltersPath + `&error=Empty`);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isError, aliData]
  );

  useEffect(() => {
    var refreshNiche = searchParams.get("action") === "refresh"
    var nichesLocation = searchParams.get("location")
    if (search === "" && settings) {
      if (searchParams.get("search")) {
        const keywords = searchParams.get("search");
        onSearchChange(keywords || "");
        if (!dataFetchedRef.current) {
          const { rating, reviews, sales } = filters;
          dispatch(
            setAliInitialFilters({
              ...filters,
              price: {
                min: settings?.result.productMinPrice,
                max: settings?.result.productMaxPrice,
              },
              feedback: {
                min: settings?.result.minFeedback,
                max: settings?.result.maxFeedback,
              },
            })
          );
          postAliFilters({
            minRating: rating.min,
            maxRating: rating.max,
            keywords: searchParams.get("search") || "",
            saleRange: 180,
            minPrice: settings?.result.productMinPrice || null,
            maxPrice: settings?.result.productMaxPrice || null,
            minFeed: settings?.result.minFeedback || null,
            maxFeed: settings?.result.maxFeedback || null,
            minSales: sales.min,
            maxSales: sales.max,
            minItemVotes: reviews.min,
            maxItemVotes: reviews.max,
            shippingType: null,
            refreshNiche: refreshNiche,
            sourceWebsite: nichesLocation,
            userId: 0,
          });
          setPostedSearch(searchParams.get("search") || "")
          dataFetchedRef.current = true;
          searchParams.delete('action');
          searchParams.delete('location');
          setSearchParams(searchParams);

        }
      } else {
        navigate(aliexpessFiltersPath);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, settings]);

  const onSearchClick = async (searchVal?: string) => {
    const { price, feedback, rating, reviews, sales } = filters;

    await postAliFilters({
      minRating: rating.min,
      maxRating: rating.max,
      keywords: searchVal || search,
      saleRange: 180,
      minPrice: price.min,
      maxPrice: price.max,
      minFeed: feedback.min,
      maxFeed: feedback.max,
      minSales: sales.min,
      maxSales: sales.max,
      minItemVotes: reviews.min,
      maxItemVotes: reviews.max,
      shippingType: null,
      userId: 0,
    });
    setPostedSearch(search);
    searchHistory.add();
  };


  useEffect(() => {
    if (searchParams.get("newReq") && settings) {
      searchParams.delete("newReq");
      setSearchParams(searchParams);
      const { rating, reviews, sales } = filters;
      dispatch(
        setAliInitialFilters({
          ...filters,
          price: {
            min: settings?.result.productMinPrice,
            max: settings?.result.productMaxPrice,
          },
          feedback: {
            min: settings?.result.minFeedback,
            max: settings?.result.maxFeedback,
          },
        })
      );
      postAliFilters({
        minRating: rating.min,
        maxRating: rating.max,
        keywords: search,
        saleRange: 180,
        minPrice: settings?.result.productMinPrice || null,
        maxPrice: settings?.result.productMaxPrice || null,
        minFeed: settings?.result.minFeedback || null,
        maxFeed: settings?.result.maxFeedback || null,
        minSales: sales.min,
        maxSales: sales.max,
        minItemVotes: reviews.min,
        maxItemVotes: reviews.max,
        shippingType: null,
        userId: 0,
      });
      setPostedSearch(search)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, settings])

  return (
    <LoadingPage loading={isLoading}>
      <Header
        leftContent={
          <HeaderTab title="Product Research" isActive className="w-full" />
        }
        middleContent={
          <Search
            search={search}
            handleChange={onSearchChange}
            queryParams={{ search }}
            handleSearchClick={onSearchClick}
            searchButton
            required
            searchHistory={searchHistory}
            size="sm"
            htmlFor="search"
          />
        }
        middleContentClassName="w-full"
        className="gap-10"
        leftContentClassName="max-w-[11rem] w-full"
        rightContent={
          <>
            <HeaderIcon
              variant={HeaderVariants.filters}
              handleClick={onHideFilters}
              tooltipTitle="Show/Hide Filters"
            />
            <HeaderHelpVideo videos={helpVideos.prAli} />
          </>
        }
      />
      {hideFilters ? "" : <AliexpressFilters bordered />}

      <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-y-3.5 gap-x-[1.1875rem] mb-6 font-medium">
        <StatsCard
          title={t('SellTrough')}
          value={formatPercent(data.sellthrough || 0)}
          info="The sell-through % is a measurement of how fast items are selling. For example, a sell-through rate of 500% means that on average each listing has gained 5 sales. The higher the sell-through %, the better!"
          Icon={Rocket}
        />
        <StatsCard
          title={t('Listings')}
          value={formatNumber(data.totalListings)}
          info="The amount of products found based on your searched keywords."
          Icon={Menu}
        />
        <StatsCard
          title={t('SoldItems')}
          value={formatNumber(data.totalSales)}
          info="The total amount of items sold in the last 6 months."
          Icon={Hammer}
        />
        <StatsCard
          title={t('SaleEarnings')}
          value={formatCurrency(data.totalRevenue)}
          info="Revenue generated by the sold items in the last 6 months."
          Icon={Dollar}
        />
        <StatsCard
          title={t('SuccessfulListings')}
          value={formatPercent(data.successfulItems || 0)}
          isSmall
          info="The percentage of listings that have at least 1 sale"
          Icon={Flag}
        />
        <StatsCard
          title={t('AveragePrice')}
          value={formatCurrency(data.averagePrice)}
          isSmall
          Icon={Dollar}
        />
        <StatsCard
          title={<div className="text-center">{t('Searched on:')} <p className="text-primaryPurple dark:text-paradiseBlue font-normal inline">AliExpress.com</p></div>}
          value={postedSearch}
          info="This is the platform you searched on"
          iconClassName="w-6 h-6"
          valueClassName="text-base dark:!text-grey100 font-medium max-w-[250px] mx-auto mb-1.5"
          contClassName="gap-2.5"
          isSmall
          Icon={SearchIcon}
        />
        <div className="w-full flex items-center justify-center">
          <SaveSearch
            modal={modal}
            type={2}
            btnClassName="max-w-[11rem]"
            searchedWord={search}
            isAdded={data.isKeywordAdded}
          />
        </div>
      </div>
      <ProductResearchAliexpressList />
      <Toaster />
    </LoadingPage>
  );
};

export default ProductResearchAliexpress;
