import { FC, useEffect, useState } from "react";

import { bulkScannerSimilarItems } from "assets/tableColumns/bulkScannerItems";
import Table from "components/Table";
import { TableStyles } from "components/Table/Table";
import useSort, { SortDir } from "hooks/useSort";
import useTable from "hooks/useTable";
import { useGetMatchItemsListQuery } from "store/api/bulkScannerApi";
import { Option, UsePaginationResponse } from "types/common";
import {
  MatchedItemsList,
  PostBulkScannerItemsListReq
} from "types/services/bulkScanner";
import ItemList from "./ItemList";

const tableStyles: TableStyles = {
  px: "px-0",
  head: { contHeight: "h-[37px]", rowHeight: "h-[60px]" },
  body: { contHeight: "h-[63px]", rowHeight: "h-[95px]" },
};

interface Props {
  payload: PostBulkScannerItemsListReq;
  pagination: UsePaginationResponse;
  currency: string;
  updateLoading?: boolean;
  domain: string;
  isWalmart: boolean;
}

const MatchedItems: FC<Props> = ({
  payload,
  pagination,
  currency,
  updateLoading,
  domain,
  isWalmart,
}) => {
  const [isAllChecked, setIsAllChecked] = useState(false)
  const [selectedFolder, setSelectedFolder] = useState<Option | null>(null);
  const [rowSelection, setRowSelection] = useState({});
  const [competitorsAsc, setCompetitorsAsc] = useState<boolean | null>(null);
  const [competitors, setCompetitors] = useState<any>({});
  const { sort, handleSort, isAsc } = useSort({
    direction: SortDir.des,
    column: "Profit",
  });
  const [openedPages, setOpenedPages] = useState<MatchedItemsList[]>([])
  const [list, setList] = useState<MatchedItemsList[]>([])
  const onFolderSelect = (opt: Option | null) => setSelectedFolder(opt);
  const { data, isFetching } = useGetMatchItemsListQuery({
    ...payload,
    orderByAsc: isAsc,
    orderByValue: sort.column,
    pageNumber: pagination.current + 1,
  });

  const customHandleSort = (column: string) => {
    if (column === "competitorCount") {
      setCompetitorsAsc(prev => prev === null || prev === true ? false : true);
    } else {
      handleSort(column);
      setCompetitorsAsc(null);
    }
  }

  const handleCountChange = (count: number, id: string) => {
    // @ts-ignore
    setCompetitors(prev => ({ [id]: count, ...prev }))
  }


  useEffect(() => {
    if (data?.result && data?.result.length > 0) {
      setList(data?.result)
    } else {
      setList([])
    }
  }, [data]);

  useEffect(() => {
    if (competitorsAsc === true || competitorsAsc === false) {
      const newList = list.map((item) => (({ ...item, competitorCount: competitors[item.itemID] || 0 })))
        .sort((a, b) => competitorsAsc ? b.competitorCount - a.competitorCount : a.competitorCount - b.competitorCount)
      setList(newList);
    }
  }, [competitorsAsc])

  useEffect(() => {
    if (list && data) {
      pagination.setTotal(data.result[0]?.totalRows || 0);
      const newOpenedPages = openedPages;

      list.forEach((item) => {
        if (!newOpenedPages.some(page => page.itemID === item.itemID)) {
          newOpenedPages.push(item);
        }
      });

      setOpenedPages(newOpenedPages);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  const onAllCheckedChange = () => setIsAllChecked(prev => !prev)
  const unSelectAll = () => {
    setRowSelection({});
    setIsAllChecked(false)
  }
  const getRowId = (row: any, relativeIndex: any, parent: any) =>
    // In row object you have access to data.
    // Yon choose parameter. In this example I used uniqueId
    parent ? [parent.id, row.itemID].join(".") : row.itemID

  const { table } = useTable({
    columns: bulkScannerSimilarItems(currency, domain, isWalmart, isAllChecked, handleCountChange, customHandleSort),
    data: list || [],
    columnDeps: [isWalmart, domain, isAllChecked, competitorsAsc],
    enableRowSelection: true,
    rowSelection,
    onRowSelectionChange: setRowSelection,
    dataDeps: [list],
    pagination: true,
    initialPageSize: 100,
    sort: true,
    getRowId
  });

  const selectedRows = () => {
    const selectedIds = Object.keys(rowSelection);
    const selectedData = (openedPages || []).filter(item => selectedIds.includes(item.itemID));
    return selectedData;
  }

  return (
    <ItemList
      pagination={pagination}
      selectedRows={selectedRows().map((item) => ({
        ebayTitle: item.title,
        ebayPrice: item.currentPrice,
        ebayItemId: item.itemID,
        amazonTitle: item.amazonTitle,
        amazonPrice: item.price,
        amazonImageUrl: item.pictureURL,
        asin: item.asin,
        profit: item.profit,
        sales: item.sales,
      }))}
      payload={payload}
      unSelectAll={unSelectAll}
      selectedFolder={selectedFolder}
      onFolderSelect={onFolderSelect}
      isAllChecked={isAllChecked}
      onAllCheckedChange={onAllCheckedChange}
    >
      <Table<MatchedItemsList>
        table={table}
        sort={competitorsAsc !== null ? { direction: competitorsAsc ? "des" as SortDir : "asc" as SortDir, column: "competitorCount" } : sort}
        styles={tableStyles}
        isLoading={isFetching || updateLoading}
      />
    </ItemList>
  );
};

export default MatchedItems;
