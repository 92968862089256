import { ColumnDef } from "@tanstack/react-table";
import { ReactComponent as Save } from "assets/icons/save.svg";
import HoveredIcon from "components/HoveredIcon";
import ProductNameCell from "components/ProductNameCell";
import { CURRENCY } from "types/currency";
import {
  IconFinderItem,
  IconFinderSimilarList,
  MarketPlaceSimilarItemsList
} from "types/services/iconFinder";
import {
  CURRENCY_SIGNS,
  formatCurrency,
  formatNumber
} from "utils/formatCurrency";

export const similarItemsCols = (
  onSaveClick: () => void,
  isWalmart: boolean,
  isLoading?: boolean,
  currency?: CURRENCY_SIGNS
): ColumnDef<IconFinderSimilarList>[] => [
    {
      header: "",
      accessorKey: "title",
      cell: ({ row: { original } }) => (
        <ProductNameCell
          img={original.pictureUrl}
          name={original.title}
          cutLongTitle={110}
          url={original.url === "" ? `https://${isWalmart ? "walmart.com/ip" : "amazon.com/dp"}/${original.asin}` : original.url}
          height="59px"
          width="57px"
          titleWrapperClassName="min-w-[40px]"
          maxHeight="max-h-[60px]"
          className="justify-start"
        />
      ),
    },
    {
      header: "",
      accessorKey: "price",
      cell: (price) => (
        <div className="text-center mx-4">
          <p>Price</p>
          <p>
            {formatCurrency(
              String(price.getValue()),
              CURRENCY.none,
              currency || "$"
            )}
          </p>
        </div>
      ),
    },

    {
      header: "",
      accessorKey: "profit",
      cell: (profit) => (
        <div className="text-center mx-4">
          <p>Profit</p>
          <p>
            {formatCurrency(
              String(profit.getValue()),
              CURRENCY.none,
              currency || "$"
            )}
          </p>
        </div>
      ),
    },
    {
      header: "",
      accessorKey: "asin",
      cell: () => (
        <HoveredIcon
          Icon={Save}
          handleClick={onSaveClick}
          isLoading={isLoading}
          title="Save to Folder"
          width="w-6"
          height="h-6"
          tooltipClassName="dark:!bg-deepSpace900"
          iconClassName={`w-3 h-1.5`}
          className="mx-2"
        />

      ),
    },
  ];

export const aliSimilarItemsCols = (
  onSaveClick: (similarItem: MarketPlaceSimilarItemsList) => void,
  isLoading?: boolean
): ColumnDef<MarketPlaceSimilarItemsList>[] => [
    {
      header: "",
      accessorKey: "title",
      cell: ({ row, row: { original } }) => (
        <ProductNameCell
          img={original?.img}
          name={original.title}
          cutLongTitle={110}
          url={original.url}
          height="59px"
          width="57px"
          isFirst={row.index === 0}
          titleWrapperClassName="min-w-[40px]"
          maxHeight="max-h-[60px]"
          className="justify-start"
        />
      ),
    },
    {
      header: "",
      accessorKey: "price",
      minSize: 120,
      cell: (price) => (
        <div className="text-center mx-4">
          <p>Price</p>
          <p>${String(price.getValue()).replace("--", "-")}</p>
        </div>
      ),
    },

    {
      header: "",
      accessorKey: "profit",
      cell: (profit) => (
        <div className="text-center mx-4">
          <p>Profit</p>
          <p>{String(profit.getValue())}</p>
        </div>
      ),
    },
    {
      header: "",
      accessorKey: "id",
      cell: ({ row: { original } }) => (
        <HoveredIcon
          Icon={Save}
          handleClick={() => onSaveClick(original)}
          isLoading={isLoading}
          title="Save to Folder"
          width="w-6"
          height="h-6"
          tooltipClassName="dark:!bg-deepSpace900"
          iconClassName="w-3 h-1.5"
          className="mx-3"
        />
      ),
    },
  ];

export const selectedItemsCols = (
  currency?: CURRENCY_SIGNS
): ColumnDef<IconFinderItem>[] => [
    {
      header: "",
      accessorKey: "title",
      minSize: 200,
      cell: ({ row: { original } }) => (
        <ProductNameCell
          url={original.url}
          img={original.img}
          height="59px"
          width="57px"
          maxHeight="max-h-[60px]"
          name={original.title}
          className="justify-start"
        />
      ),
      meta: {
      }
    },
    {
      header: "",
      accessorKey: "price",
      cell: (price) => (
        <div className="text-center mx-4">
          <p>Price</p>
          <p>
            {formatCurrency(
              String(price.getValue()),
              CURRENCY.none,
              currency || "$"
            )}
          </p>
        </div>
      ),
    },
    {
      header: "",
      accessorKey: "sold",
      cell: (sold) => (
        <div className="text-center mx-4">
          <p>Sold</p>
          <p>{formatNumber(String(sold.getValue()))}</p>
        </div>
      ),
    },
  ];
