const currencies = {
  USD: "Dollars",
  EUR: "Euro",
  GBP: "Pound Sterling",
  JPY: "Yen",
  AUD: "Australian Dollar",
  CAD: "Canadian Dollar",
  CHF: "Swiss Franc",
  CNY: "Yuan",
  SEK: "Swedish Krona",
  NZD: "New Zealand Dollar",
  KRW: "Won",
  SGD: "Singapore Dollar",
  NOK: "Norwegian Krone",
  MXN: "Mexican Peso",
  INR: "Indian Rupee",
  RUB: "Russian Ruble",
  BRL: "Brazilian Real",
  ZAR: "Rand",
  HKD: "Hong Kong Dollar",
  TRY: "Turkish Lira",
  AED: "UAE Dirham",
  SAR: "Saudi Riyal",
  IDR: "Indonesian Rupiah",
  THB: "Thai Baht",
  MYR: "Malaysian Ringgit",
  PHP: "Philippine Peso",
  VND: "Vietnamese Dong",
  IQD: "Iraqi Dinar",
  EGP: "Egyptian Pound",
  COP: "Colombian Peso",
  CLP: "Chilean Peso",
  ARS: "Argentine Peso",
  DKK: "Danish Krone",
  CZK: "Czech Koruna",
  PLN: "Polish Zloty",
  ILS: "Israeli Shekel",
  HUF: "Hungarian Forint",
  QAR: "Qatari Riyal",
  KWD: "Kuwaiti Dinar",
  OMR: "Omani Rial",
  BHD: "Bahraini Dinar",
  DZD: "Algerian Dinar",
  LYD: "Libyan Dinar",
  MAD: "Moroccan Dirham",
  TND: "Tunisian Dinar",
  RSD: "Serbian Dinar",
  UAH: "Ukrainian Hryvnia",
  BYN: "Belarusian Ruble",
  XAF: "Central African CFA Franc",
  XOF: "West African CFA Franc",
  XCD: "Eastern Caribbean Dollar",
  XPF: "CFP Franc",
  BSD: "Bahamian Dollar",
  BBD: "Barbadian Dollar",
  BZD: "Belize Dollar",
  BMD: "Bermudian Dollar",
  FJD: "Fijian Dollar",
  GYD: "Guyanese Dollar",
  JMD: "Jamaican Dollar",
  KID: "Kiribati Dollar",
  LRD: "Liberian Dollar",
  NAD: "Namibian Dollar",
  SRD: "Surinamese Dollar",
  TTD: "Trinidad & Tobago Dollar",
  TWD: "New Taiwan Dollar",
};

export default currencies;
