import { ReactComponent as Light } from "assets/icons/switch-day.svg";
import { ReactComponent as Night } from "assets/icons/switch-night.svg";
import { COOKIE_DOMAIN } from "config/constants";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import { useGetSettingsQuery, useToggleDarkModeMutation } from "store/api/quickSettingsApi";
import { handleDarkModeChange } from "store/slices/themeSlice";

const DarkModeSwitch = () => {
  const [cookie, setCookie] = useCookies(['darkMode']);
  const { isDarkMode } = useAppSelector((state) => state.themeSlice)
  const [toggleDarkMode] = useToggleDarkModeMutation()
  const dispatch = useAppDispatch();
  const { data } = useGetSettingsQuery()
  const dataFetchedRef = useRef(false);
  const toggleTheme = () => {
    dispatch(handleDarkModeChange(!isDarkMode));
    setCookie("darkMode", isDarkMode ? "false" : "true", COOKIE_DOMAIN);
    isDarkMode ? document.body.classList.remove("dark") : document.body.classList.add("dark");
    toggleDarkMode(null);
  };
  useEffect(() => {
    if (data && !dataFetchedRef.current) {
      setCookie("darkMode", data?.result.darkEnabled ? "true" : "false", COOKIE_DOMAIN)
      dispatch(handleDarkModeChange(data?.result.darkEnabled))
      dataFetchedRef.current = true
      data?.result.darkEnabled ? document.body.classList.add("dark") : document.body.classList.remove("dark");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    dispatch(handleDarkModeChange(cookie.darkMode === "true"));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <button
      className="w-[52px] h-6 rounded-full bg-secondary dark:bg-black900 flex items-center transition duration-300 focus:outline-none"
      onClick={toggleTheme}
    >
      <div
        id="switch-toggle"
        className={`w-[29px] h-[18px] relative rounded-full  transition duration-500 transform  dark:bg-primaryPurple dark:translate-x-[19px] bg-white dark:bg-purple500 translate-x-1 p-1 text-white`}
      >
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          {isDarkMode ? <Night className="" /> : <Light className="" />}
        </div>
      </div>
    </button>
  );
};

export default DarkModeSwitch;
