import Card from "components/Card";

// Icons
import { paymentHistoryCols } from "assets/tableColumns/paymentHistory";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableStyles } from "components/Table/Table";
import useTable from "hooks/useTable";

import Scrollbar from "components/Scrollbar";
import { FC } from "react";
import { useGetSubscriptionHistoryQuery } from "store/api/managePaymentApi";

interface Props {
  isShopify?: boolean
}
const PaymentHistory: FC<Props> = ({ isShopify }) => {
  const tableStyles: TableStyles = {
    minWidth: "min-w-[925px]",
    px: "px-0",
    head: { th: "font-bold" },
    style: isShopify ? "mb-2" : "mb-7",
    body: { contHeight: "h-[37px]", rowHeight: "h-[55px]", cont: `font-medium ${isShopify ? "!justify-start pl-3" : ""}`, },

  };
  const { data, isFetching } = useGetSubscriptionHistoryQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const { table } = useTable({
    columns: paymentHistoryCols(isShopify),
    data: data?.result.blueSnapPayments.filter((item) => {
      if (isShopify) {
        return item.blueSnapSubscription.isShopify === true
      } else {
        return !item.blueSnapSubscription.isShopify
      }
    }) || [],
    dataDeps: [data],
    pagination: true,
  });

  return (
    <>
      <Card className={`${isShopify ? "p-8" : "px-4 py-7"} mb-4`}>
        <span className={`font-bold block dark:text-grey100 ${isShopify ? "ml-0 mb-4" : "ml-11 mb-6"}`}>Billing History</span>
        <Scrollbar className="overflow-x-auto">
          <Table table={table} styles={tableStyles} isLoading={isFetching} />
        </Scrollbar>
        <Pagination table={table} className="justify-between" labeled />
      </Card>
    </>
  );
};

export default PaymentHistory;
