import { ReactComponent as LeftIllustration } from "assets/illustrations/turbo-scanner-left.svg";
import { ReactComponent as RightIllustration } from "assets/illustrations/turbo-scanner-right.svg";
import Card from "components/Card";

const TurboScannerInfo = () => (
  <Card className="relative flex justify-around items-center py-9 min-h-[386px] dark:text-grey100">
    <div className="absolute left-0 top-1/2 -translate-y-1/2">
      <LeftIllustration />
    </div>
    <div className="max-w-[30rem] ">
      <h3 className="font-bold text-[28px] mb-4 text-center">
        Welcome to the Turbo Scanner!
      </h3>
      <p className="text-sm mb-6">
        Firstly choose your supplier, how many products you want in the result,
        then choose the filters you want to apply. Using filters will help you
        get better results. For example, high amount of sales from the category
        you want, or high amount of sales from item with a very good Average
        Rating in the supplier’s site. Finally, click Search and we’ll display
        all the items that match your filters in our database. Click the
        checkboxes next any products to save and export them to your listing
        software.
      </p>
    </div>
    <div className="absolute right-0 top-1/2 -translate-y-1/2">
      <RightIllustration />
    </div>
  </Card>
);

export default TurboScannerInfo;
