import Button from "components/Button";
import Card from "components/Card";
import FiltersCard from "components/Filters/FiltersCard";
import MinMaxFilter from "components/Filters/MinMaxFilter";
import Search from "components/Search";
import useFilters from "hooks/useFilters";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { FolderItemsFilterState } from "../Product";

interface Props {
  filters: FolderItemsFilterState;
  onFilterClick: (newVal: FolderItemsFilterState) => void;
  filterLoading: boolean;
  isAliexpress: boolean;
}
const FolderFilters: FC<Props> = ({
  filters: postedFilters,
  onFilterClick,
  filterLoading,
  isAliexpress
}) => {
  const { filters, handleFilterChange: handleChange } =
    useFilters(postedFilters);
  const { t } = useTranslation(['home']);

  return (
    <Card className="flex py-6 px-9 max-xl:p-4 max-xl:flex-wrap max-xl:justify-center gap-4" >
      <div className="flex gap-5 max-lg:flex-col">
        <div className="flex gap-5 max-sm:flex-col">
          <FiltersCard
            title={t('EbayPrice')}
            infoTitle="Filter by eBay product price"
            className="w-full !py-3 !px-2.5"
            titleWrapperClassName="!mb-0"
          >
            <MinMaxFilter
              value={filters.ebayPrice}
              name="ebayPrice"
              inputWidth="w-16"
              handleChange={(newVal, type) =>
                handleChange(newVal, "ebayPrice", type)
              }
            />
          </FiltersCard>
          <FiltersCard
            title={isAliexpress ? "AliExpress Price" : "Supplier price"}
            infoTitle={isAliexpress ? "Filter by AliExpress product price" : "Filter by supplier product price"}
            className="w-full !py-3 !px-2.5"
            titleWrapperClassName="!mb-0"
          >
            <MinMaxFilter
              value={filters.aliexpressPrice}
              name="aliexpressPrice"
              inputWidth="w-16"
              handleChange={(newVal, type) =>
                handleChange(newVal, "aliexpressPrice", type)
              }
            />
          </FiltersCard>
        </div>
        <div className="flex gap-5 max-sm:flex-col">
          <FiltersCard
            title={t('Profit')}
            className="w-full !py-3 !px-2.5"
            titleWrapperClassName="!mb-0"
            infoTitle="Filter by profit"
          >
            <MinMaxFilter
              value={filters.profit}
              name="profit"
              inputWidth="w-16"
              handleChange={(newVal, type) =>
                handleChange(newVal, "profit", type)
              }
            />
          </FiltersCard>
          <FiltersCard
            title={t('Sales')}
            infoTitle="Filter by sales"
            className="w-full !py-3 !px-2.5"
            titleWrapperClassName="!mb-0"
          >
            <MinMaxFilter
              value={filters.sales}
              name="sales"
              inputWidth="w-16"
              handleChange={(newVal, type) =>
                handleChange(newVal, "sales", type)
              }
            />
          </FiltersCard>
        </div>
      </div>
      <div className="flex flex-col gap-4.5 w-full items-end max-xl:justify-center max-xl:flex-row max-sm:items-center max-sm:flex-col my-auto">
        <Search
          handleChange={(newVal) => handleChange(newVal, "search")}
          search={filters.search}
          maxWidth="301px"
          className="w-full"
          placeholder="Search Text"
          toolTip="Enter the keyword to find your product easier"
        />
        <Button
          title="Filter"
          handleClick={() => onFilterClick(filters)}
          height="h-9"
          loading={filterLoading}
          className="max-w-[148px] dark:bg-deepSpace900"
        />
      </div>
    </Card>
  );
};

export default FolderFilters;
