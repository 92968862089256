import Card from "components/Card";
import { useState } from "react";

import { longTailKeywordsCols } from "assets/tableColumns/titleBuilder";
import TitleInput from "components/Input/TitleInput";

//icons
import GenericKeywords from "./components/lists/GenericKeywords";
import LongTailKeywords from "./components/lists/LongTailKeywords";
import TitleBuilderLayout from "./components/TitleBuilderLayout";

const TitleBuilderResults = () => {
  const onTitleCopy = (newVal: string) =>
    handleTitleChange(title + " " + newVal.substring(0, 80 - title.length));

  const [title, setTitle] = useState("");
  const [tableColumns, setTableColumns] = useState(longTailKeywordsCols(onTitleCopy));
  const [selectedMarketplaces, setSelectedMarketplaces] = useState([]);

  const handleTitleChange = (newVal: string) => {
    newVal.length <= 80 && setTitle(newVal);
  };

  return (
    <TitleBuilderLayout
      onTitleCopy={onTitleCopy}
      setTableColumns={setTableColumns}
      selectedMarketplaces={selectedMarketplaces}
      setSelectedMarketplaces={setSelectedMarketplaces}
      filtersCard={<TitleInput title={title} handleChange={handleTitleChange} error={false} />}
    >
      <div className="flex gap-3 max-lg:flex-col">
        <Card className="w-full p-4 pt-6 h-full overflow-x-auto scrollbar" shadow={false}>
          <LongTailKeywords tableColumns={tableColumns} />
        </Card>
        {selectedMarketplaces.length === 0 && <GenericKeywords onTitleCopy={onTitleCopy} />}
      </div>
    </TitleBuilderLayout>
  );
};

export default TitleBuilderResults;
