import {
  genericKeywordsCols,
  longTailKeywordsCols
} from "assets/tableColumns/prTitleAnalytics";
import Card from "components/Card";
import Search from "components/Search";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableStyles } from "components/Table/Table";
import { useAppSelector } from "hooks/useAppSelector";
import useTable from "hooks/useTable";
import { FC } from "react";
import "styles/scrollbar.css";
import { GenericSingleKeywords, LongTailKeywords } from "types/services/pr";

interface Props {
  onTitleCopy: (newVal: string) => void;
  title: string;
}

const tableStyles: TableStyles = {
  px: "px-[0px]",
  style: "mb-6 max-[1400px]:!overflow-y-hidden",
  head: {
    contHeight: "h-[37px]",
    rowHeight: "h-[55px]",
  },
  body: {
    contHeight: "h-[37px]",
    rowHeight: "h-[55px]",
  },
};

const tableConfigs = {
  pagination: true,
  initialPageSize: 10,
  sort: true,
  search: true,
  minMax: true,
};

const TitleAnalyticsLists: FC<Props> = ({ onTitleCopy, title }) => {
  const { longTailKeywords, genericSingleKeywords } = useAppSelector(
    (state) => state.prSlice.ebayData.result
  );

  const largestScore = (longTailKeywords && longTailKeywords?.[0]?.score) || 0;

  const { table: ltKeywords } = useTable<LongTailKeywords>({
    columns: longTailKeywordsCols(onTitleCopy, largestScore),
    data: longTailKeywords || [],
    columnDeps: [title],
    ...tableConfigs,
  });

  const { table: gsKeywords } = useTable<GenericSingleKeywords>({
    columns: genericKeywordsCols(onTitleCopy),
    data: genericSingleKeywords || [],
    columnDeps: [title],
    ...tableConfigs,
  });

  return (
    <div className="flex gap-2.5 max-lg:flex-col">
      <Card
        className="w-full p-4 pt-4.5 h-full overflow-x-auto scrollbar"
        shadow={false}
      >
        <Search
          search={ltKeywords.getState().globalFilter}
          htmlFor="longTailKeywordsSearch"
          className="mb-3.5 ml-auto"
          maxWidth="18.75rem"
          size="sm"
          handleChange={ltKeywords.setGlobalFilter}
        />

        <Table<LongTailKeywords> table={ltKeywords} styles={tableStyles} />
        <Pagination<LongTailKeywords> table={ltKeywords} labeled />
      </Card>

      <Card
        className="w-full p-4 pt-4.5 h-full overflow-x-auto scrollbar"
        shadow={false}
      >
        <Search
          search={gsKeywords.getState().globalFilter}
          className="mb-3.5 ml-auto"
          size="sm"
          maxWidth="18.75rem"
          htmlFor="genericKeywordsSearch"
          handleChange={gsKeywords.setGlobalFilter}
        />
        <Table<GenericSingleKeywords> table={gsKeywords} styles={tableStyles} />
        <Pagination<GenericSingleKeywords> table={gsKeywords} labeled />
      </Card>
    </div>
  );
};

export default TitleAnalyticsLists;
