import { ReactComponent as Upload } from "assets/icons/upload.svg";
import Button from "components/Button";
import useModal from "hooks/useModal";
import { FC } from "react";
import { toast } from "react-toastify";
import { FolderFilters } from "types/services/productMangement";
import ConnectLister from "./ConnectLister";

interface Props {
  type: "Turbo" | "AutoPilot" | "Folder";
  selectedItems: number[];
  onUploadProgress: () => void;
  folderFilters?: FolderFilters;
  allChecked: boolean;
}
const UploadSelected: FC<Props> = ({
  type,
  selectedItems,
  onUploadProgress,
  folderFilters,
  allChecked,
}) => {
  const modal = useModal();

  return (
    <>
      <Button
        title="Upload selected"
        handleClick={
          selectedItems.length > 0
            ? modal.open
            : () => toast.error("No item has been selected for uploading!")
        }
        color="primaryPurple"
        className={"max-w-[174px] w-full shrink-0 dark:bg-deepSpace900"}
        Icon={Upload}
      />
      <ConnectLister
        helpers={modal}
        type={type}
        folderFilters={folderFilters}
        selectedItems={selectedItems}
        allChecked={allChecked}
        onUploadProgress={onUploadProgress}
      />
    </>
  );
};

export default UploadSelected;
