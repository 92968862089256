import { ReactComponent as Copy } from "assets/icons/copy.svg";
import { ReactComponent as Star } from "assets/icons/star.svg";
import Card from "components/Card";
import InfoTooltip from "components/Card/InfoTooltip";
import Scrollbar from "components/Scrollbar";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
interface Props {
  titles: string[];
  handleTitleChange: (newVal: string) => void;
}

interface ItemProps {
  title: string;
  isCopied: boolean;
  handleClick: () => void;
}

const TopSellingTitleItem: FC<ItemProps> = ({
  title,
  handleClick,
  isCopied,
}) => {

  const onItemClick = () => {
    navigator.clipboard.writeText(title);
    handleClick();
  };

  return (
    <div
      className={`${isCopied
        ? "bg-neutral2 border-transparent dark:border-grey100 dark:bg-deepSpace900" : "hover:bg-primaryPurple dark:hover:bg-purple500 hover:border-transparent hover:text-white border-neutral2 dark:border-deepSpace900 dark:hover:border-purple500 dark:bg-deepSpace900"
        } pl-4 py-[15px] lg:pr-7 pr-4 rounded-full flex items-center justify-between border-2 cursor-pointer min-w-[600px] w-full group`}
      onClick={onItemClick}
    >
      <p className="text-sm dark:text-grey100">{title}</p>
      <div className="flex items-center lg:gap-4 dark:text-grey100">
        <p className={`text-[10px] ${isCopied ? "" : "hidden group-hover:block"}`}>{isCopied ? "copied to clipboard!" : "copy to clipboard!"}</p>
        <Copy
          className={`h-6 ${isCopied
            ? "fill-primaryPurple dark:fill-purple500" : "fill-darkBlue group-hover:fill-white dark:fill-grey100"
            } `}
        />
      </div>
    </div>
  );
};

const TopSellingTitles: FC<Props> = ({ titles, handleTitleChange }) => {
  const [copiedTitle, setCopiedTitle] = useState<number | null>(null);
  const { t } = useTranslation(['home']);

  return (
    <Card bordered className="py-5.5 lg:pl-[37px] pl-4 overflow-x-auto">
      <div className="flex items-center gap-4 mb-5">
        <Star className="fill-darkBlue dark:fill-white" />
        <h5 className="text-base font-bold dark:text-white">{t('Top10Selling')}</h5>
        <InfoTooltip title="The titles used by the top 10 selling items" />
      </div>
      <Scrollbar className="flex flex-col gap-2.5 max-h-[13.25rem] pr-3 mr-4">
        {titles.map((title, index) => (
          <TopSellingTitleItem
            title={title}
            key={index}
            handleClick={() => {
              setCopiedTitle(index);
              handleTitleChange(title);
            }}
            isCopied={copiedTitle === index}
          />
        ))}
      </Scrollbar>
    </Card>
  );
};

export default TopSellingTitles;
