import Scrollbar from "components/Scrollbar";
import { FC, ReactNode } from "react";
import { Option } from "types/common";
import SelectOption from "./Option";

interface Props {
  handleOptionClick: (value: string, text: string) => void;
  CustomOptions?: ReactNode;
  value?: string;
  options?: Option[];
  listItemClassName?: string;
  listHeight?: string;
  listWrapperClassName?: string;
  hideOptions?: boolean;
  cutLongOption?: number;
  noDisabledOptionTooltip?: boolean;
}

const SelectOptionsCard: FC<Props> = ({
  listWrapperClassName,
  hideOptions,
  listHeight,
  options,
  value,
  cutLongOption,
  listItemClassName,
  handleOptionClick,
  noDisabledOptionTooltip,
  CustomOptions,
}) => (
  <div
    className={`pr-1 p-2 bg-white absolute top-16 rounded-2xl z-20 shadow-lg w-full border border-[#CBD5E0]  dark:border-grey700 text-sm dark:bg-deepSpace900 dark:text-grey100  ${listWrapperClassName || ""
      }`}
  >
    {!hideOptions ? (
      <Scrollbar as="ul" className={`${listHeight || "max-h-[20rem]"} overflow-x-hidden pr-1`}>
        {options &&
          options.map((item, i) => (
            <SelectOption item={item} key={item.value + i} listItemClassName={listItemClassName} cutLongOption={cutLongOption} handleOptionClick={handleOptionClick} value={value} noDisabledOptionTooltip={noDisabledOptionTooltip} />
          ))}
      </Scrollbar>
    ) : (
      ""
    )}
    {CustomOptions || ""}
  </div>
);

export default SelectOptionsCard;
