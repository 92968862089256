import { ReactComponent as Reload } from "assets/icons/reload.svg";
import { uploadHistoryCols } from "assets/tableColumns/uploadHistory";
import Button from "components/Button";
import Modal from "components/Modal";
import Table from "components/Table";
import { useAppDispatch } from "hooks/useAppDispatch";
import useModal from "hooks/useModal";
import useTable from "hooks/useTable";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { productManagementApi, useLoadScanHistoryQuery } from "store/api/productManagementApi";
import { queryTags } from "store/api/queryTags";
import UploadLoading from "./UploadStatus";
interface Props {
  type: "Turbo" | "AutoPilot" | "Folder";
  uploadProgress: boolean;
  onUploadSuccess: () => void;
}
const UploadHistory: FC<Props> = ({
  type,
  uploadProgress,
  onUploadSuccess,
}) => {
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const modal = useModal();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { data: loadHistory, isFetching } = useLoadScanHistoryQuery(
    {
      taskId: id || "",
      type,
    },
    { skip: !modal.isOpen }
  );

  useEffect(() => {
    if (loadHistory && uploadProgress) {
      if (loadHistory.result?.[0].status === 1) {
        setUploadSuccess(true);
        onUploadSuccess();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadHistory]);
  const { table } = useTable({
    columns: uploadHistoryCols,
    data: loadHistory?.result || [],
    dataDeps: [loadHistory],
  });
  const handleClick = () => {
    dispatch(
      productManagementApi.util.invalidateTags([
        queryTags.productMangement
      ])
    )
    modal.open();
  };

  return (
    <>
      <Button
        title="Upload history"
        handleClick={handleClick}
        color="primaryPurple"
        className={"max-w-[174px] w-full shrink-0 dark:bg-deepSpace900"}
        Icon={Reload}
      />
      <UploadLoading
        uploadProgress={uploadProgress}
        isSuccess={uploadSuccess}
      />
      <Modal
        helpers={modal}
        className="flex flex-col items-center "
        cardClassName="pt-5.5 pb-8"
        titleClassName="!mb-7"
        maxWidth="max-w-[1120px]"
        title="Upload History"
        buttonCloseClassName="!top-4 !right-4"
      >
        <div className="px-3">
          <Table
            table={table}
            styles={{ px: "px-2", style: "max-h-[550px] overflow-y-auto" }}
            isLoading={isFetching}
          />
        </div>
      </Modal>
    </>
  );
};

export default UploadHistory;
