import { createApi } from "@reduxjs/toolkit/query/react";
import {
  ProductExplorerReq,
  ProductExplorerRes,
} from "types/services/productExplorer";
import {
  ShopifyOptions,
  StoreFinderSearchReq,
  StoreFinderSearchRes,
} from "types/services/storeFinder";
import baseQuery from "./baseQuery";
import { queryKeys } from "./queryKeys";

export const shopifyApi = createApi({
  reducerPath: "shopifyApi",
  baseQuery,
  endpoints: (builder) => ({
    getShopifyOptions: builder.query<ShopifyOptions, null>({
      query: (params) => ({
        url: queryKeys.shopify.storeFinderOptions,
        method: "GET",
        params,
      }),
    }),
    storeFinderSearch: builder.query<
      StoreFinderSearchRes,
      StoreFinderSearchReq
    >({
      query: (params) => ({
        url: queryKeys.shopify.storeFinderSearch,
        method: "POST",
        body: params,
      }),
    }),
    productExplorerSearch: builder.query<
      ProductExplorerRes,
      ProductExplorerReq
    >({
      query: (params) => ({
        url: queryKeys.shopify.productExplorerSearch,
        method: "POST",
        body: params,
      }),
    }),
  }),
});

export const {
  useLazyGetShopifyOptionsQuery,
  useLazyStoreFinderSearchQuery,
  useLazyProductExplorerSearchQuery,
} = shopifyApi;
