import { ReactComponent as Smiley } from "assets/images/smiley.svg";
import Button from "components/Button";
import FilterCheckbox from "components/Filters/FilterCheckbox";
import Loading from "components/Loading";
import Scrollbar from "components/Scrollbar";
import { useGetOnBoardingQuestionQuery, useSaveFunnelMutation } from "store/api/onboardingApi";

interface Props {
  isEbaySelected?: boolean;
  isShopifySelected?: boolean
  selectedAnswers: string[];
  onAnswerChange: (answere: string[]) => void;
  handleNext: () => void;
}
const Questionnaire = ({
  isEbaySelected,
  isShopifySelected,
  selectedAnswers,
  onAnswerChange,
  handleNext,
}: Props) => {
  const [saveQuestionaire, { isLoading: funnelLoading }] = useSaveFunnelMutation();
  const { data, isFetching: questionLoading } =
    useGetOnBoardingQuestionQuery({ stepNumber: 3, ...(isShopifySelected ? { isShopify: true, } : isEbaySelected ? { isEbay: true } : {}) }, { refetchOnMountOrArgChange: true });
  const checkboxOptions = data?.result[0].onBoardingQuestionOptions.map((item) => ({
    value: item.optionValue.toString(),
    text: item.optionLabel
  }))

  const handleSave = () => {
    saveQuestionaire({ funnelValues: selectedAnswers }).unwrap().then(() => {
      handleNext();
    });
  }

  const handleSelectAnswer = (answerId: string) => {
    const indexToRemove = selectedAnswers.indexOf(answerId);
    const newAnswers = !selectedAnswers.includes(answerId) ? [...selectedAnswers, answerId] : [
      ...selectedAnswers.slice(0, indexToRemove),
      ...selectedAnswers.slice(indexToRemove + 1),
    ];
    onAnswerChange(newAnswers);
  };

  return (
    <div className="max-lg:max-w-[536px] mx-auto max-sm:max-w-[95%]">
      <div className="flex flex-col justify-center  mb-6 lg:pt-[3px]">
        <Smiley className="self-center mb-6 max-lg:hidden" />
        {questionLoading ? <Loading fill="fill-primaryPurple dark:fill-purple500 mx-auto max-lg:h-[calc(100vh-400px)]" width={40} height={40} /> :
          data && (
            <>
              <h1 className="max-lg:text-2xl text-xl text-center font-bold text-darkBlue dark:text-grey100 mb-[25px] max-lg:mb-4 max-sm:text-[20px]">
                {data.result[0].questionLabel}
              </h1>
              <Scrollbar className="max-h-[300px] max-lg:h-[calc(100vh-476px)] max-sm:h-[100%] max-lg:max-h-[100%] max-sm:h-[]">
                <FilterCheckbox
                  options={checkboxOptions}
                  multiselect
                  rectangleCheckbox
                  value={selectedAnswers}
                  optionLabelClassName={"min-h-[48px] max-lg:min-h-[64px] !text-sm gap-[20px]"}
                  className={`w-full grid lg:px-8 max-lg:max-w-[100%] gap-y-3.5 gap-x-[19px] max-lg:gap-y-4 max-lg:grid-cols-1
                  ${checkboxOptions && checkboxOptions.length <= 4 ? "grid-cols-1 max-w-[416px] mx-auto" : "grid-cols-2"}`}
                  handleChange={(newVal) => handleSelectAnswer(newVal.toString())}
                />
              </Scrollbar>
            </>
          )}
      </div>
      <div className="w-full flex justify-center mb-[30px]">
        <Button
          title="Next"
          height="min-h-[64px] max-sm:min-h-[56px]"
          className={`max-w-[216px] max-lg:max-w-[100%] self-center mx-auto`}
          titleClassName="!text-base"
          handleClick={handleSave}
          loading={funnelLoading}
          disabled={selectedAnswers.length <= 0}
        />
      </div>
    </div>
  );
};
export default Questionnaire;
