import Card from "components/Card";
import CenteredLoading from "components/Loading/CenteredLoading";
import { FC } from "react";

interface Props {
  iconClassName?: string;
  title: string;
  value: string | number;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  isLoading?: boolean;
}

const StoreStatsCard: FC<Props> = ({
  title,
  Icon,
  value,
  isLoading,
  iconClassName,
}) => (
  <Card
    bordered
    className="w-full text-darkBlue dark:text-grey100 py-[18.5px] px-[23px] h-[98px]"
  >
    <CenteredLoading loading={isLoading || false} height={36} width={36}>
      <div className="flex justify-between items-center h-full">
        <div className="flex justify-between items-center">
          <Icon className={`fill-primaryPurple dark:fill-paradiseBlue ${iconClassName || ""}`} />
        </div>
        <div className="flex flex-col gap-[5px]">
          <h5 className="text-center text-2xl font-bold text-end">{value}</h5>
          <h4 className="text-sm font-normal text-end ">{title}</h4>
        </div>
      </div>
    </CenteredLoading>
  </Card>
);

export default StoreStatsCard;
