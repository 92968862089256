import shortcutsAndSupports from "assets/data/dashboard/shortcutsAndSupports";
import { ReactComponent as InfoDarkBlue } from "assets/icons/info-dark-blue.svg";
import Card from "components/Card";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import { handleBootIntercom } from "store/slices/intercomSlice";

const BottomCard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['home']);
  const dispatch = useAppDispatch()
  const handleRedirect = (href?: string, path?: string) => {
    if (href) {
      window.open(href, "_blank");
    } else {
      navigate(path || PATH.root);
    }
  };

  return (
    <Card
      className="flex flex-col w-full items-center pt-9 pb-5 h-fit"
      bordered
    >
      <div className="flex gap-5.5 w-full justify-center mb-7">
        <p className="font-bold dark:text-grey100">{t('ShortcutsSupport')}</p>
        <InfoDarkBlue className="fill-darkBlue dark:fill-grey100" />
      </div>

      <div className="max-w-[23.875rem] w-full border border-neutral2 dark:border-deepSpace800 mx-auto mb-6" />

      <div className="flex flex-col items-center mb-4 gap-1">
        {shortcutsAndSupports.slice(0, 4).map((item) => (
          <p
            key={item.id}
            className="text-neutral3 font-normal hover:text-darkBlue hover:dark:text-lilac400 cursor-pointer"
            onClick={() => {
              if (item.id === "need_help") {
                dispatch(handleBootIntercom(true))
              } else { handleRedirect(item.href, item.path) }
            }}
          >
            {t(item.translate)}
          </p>
        ))}
      </div>
      <div className="flex flex-col items-center gap-1">
        {shortcutsAndSupports.slice(4).map((item) => (
          <p
            key={item.id}
            className="text-neutral3 font-normal hover:text-darkBlue hover:dark:text-lilac400 cursor-pointer"
            onClick={() => handleRedirect(item.href, item.path)}
          >
            {t(item.translate)}
          </p>
        ))}
      </div>
    </Card>
  );
};

export default BottomCard;
