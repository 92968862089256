import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/path";

const PurchaseFailed = () => {
    const navigate = useNavigate();

    const goToDashboard = () => {
        setTimeout(() => {
            navigate(PATH.dashboard.root);
        }, 3000);
    };

    useEffect(() => {
        goToDashboard();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="flex justify-center items-center h-[100vh]">
            <div className="flex flex-col max-w-[1068px] w-full bg-white border-[2px] border-errorRed rounded-[10px] pt-[60px] pb-[42px] justify-center items-center shadow-lg">

                <div className="font-bold text-[24px] mb-[20px] text-errorRed">
                    Payment Failed
                </div>


                <div className="flex gap-[11px] items-center w-[58px]">
                    <div className="w-[12px] h-[12px] rounded-full bg-secondary" />
                    <div className="w-[12px] h-[12px] rounded-full bg-errorRed translate-y-[-10px] animate-[bounce_1s_ease_infinite]" />
                    <div className="w-[12px] h-[12px] rounded-full bg-secondary" />
                </div>
            </div>
        </div>
    )
}

export default PurchaseFailed