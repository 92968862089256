import FiltersCard from "components/Filters/FiltersCard";

// icons
import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import { ReactComponent as Dollar } from "assets/icons/dollar.svg";
import { ReactComponent as Megaphone } from "assets/icons/megaphone.svg";
import { ReactComponent as Star } from "assets/icons/star.svg";
import { ReactComponent as Store } from "assets/icons/store.svg";
import Card from "components/Card";
import MinMaxFilter from "components/Filters/MinMaxFilter";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { handlePrFilterChange } from "store/slices/prSlice";

interface Props {
  bordered?: boolean;
}
const AliexpressFilters: FC<Props> = ({ bordered }) => {
  const { aliexpress: filters } = useAppSelector(
    (state) => state.prSlice.filters
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['home']);


  const handleFilterChange = (
    value: string | number,
    field: keyof typeof filters,
    type: "min" | "max"
  ) => {
    dispatch(
      handlePrFilterChange({
        marketplace: "aliexpress",
        value,
        field,
        type,
      })
    );
  };

  return (
    <Card
      className="py-5 px-11 mb-4"
      rounded="rounded-[20px]"
      bg="dark:bg-[#242424] bg-white"
      bordered={bordered}
    >
      <div className="grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2 grid-flow-row xl:gap-x-8 gap-x-8 gap-y-8">
        <FiltersCard
          Icon={Megaphone}
          title={t('Sales')}
          className="py-[11.5px] !px-4"
          infoTitle="Filter results based on the number of sales"
          iconClassName="w-3.5 h-[13px]"
          titleWrapperClassName="!mb-[7px]"
        >
          <MinMaxFilter
            value={filters.sales}
            name="sales"
            handleChange={(newVal, type) =>
              handleFilterChange(newVal, "sales", type)
            }
          />
        </FiltersCard>
        <FiltersCard
          Icon={Dollar}
          title={t('Price')}
          infoTitle="Filter results based on their current selling price."
          iconClassName="w-4 h-4"
          className="py-[11.5px] !px-4"
          titleWrapperClassName="!mb-[7px]"
        >
          <MinMaxFilter
            value={filters.price}
            name="price"
            handleChange={(newVal, type) =>
              handleFilterChange(newVal, "price", type)
            }
          />
        </FiltersCard>
        <FiltersCard
          Icon={Checkmark}
          title={t('Reviews')}
          iconClassName="w-[15px] h-2.5"
          className="py-[11.5px] !px-4"
          infoTitle="Filter results based on the total amount of reviews that the seller has"
          titleWrapperClassName="!mb-[7px]"
        >
          <MinMaxFilter
            value={filters.reviews}
            name="reviews"
            handleChange={(newVal, type) =>
              handleFilterChange(newVal, "reviews", type)
            }
          />
        </FiltersCard>
        <FiltersCard
          Icon={Star}
          title={t('Rating')}
          className="py-[11.5px] !px-4"
          infoTitle="Filter results based on rating"
          iconClassName="w-3.5 h-[13px]"
          titleWrapperClassName="!mb-[7px]"
        >
          <MinMaxFilter
            value={filters.rating}
            name="rating"
            handleChange={(newVal, type) =>
              handleFilterChange(newVal, "rating", type)
            }
          />
        </FiltersCard>
        <FiltersCard
          Icon={Store}
          title={t('StoreFeedback')}
          className="py-[11.5px] !px-4"
          infoTitle="Filter results based on the total amount of feedback a seller has."
          iconClassName="w-4 h-3.5"
          titleWrapperClassName="!mb-[7px]"
        >
          <MinMaxFilter
            value={filters.feedback}
            name="feedback"
            handleChange={(newVal, type) =>
              handleFilterChange(newVal, "feedback", type)
            }
          />
        </FiltersCard>
      </div>
    </Card>
  );
};

export default AliexpressFilters;
