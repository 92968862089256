import { FC } from "react";


interface Props {
    title: string;
    isActive?: boolean;
    handleClick: () => void;
}
const TableTab: FC<Props> = ({ title, isActive, handleClick }) => {
    return (
        <button className={`p-2.5 relative text-xs font-medium 
            ${isActive ? "dark:text-purple500 dark:after:bg-purple500 text-primaryPurple after:absolute after:bottom-0 after:content-[''] after:left-0 after:w-full after:h-[3px] after:bg-primaryPurple after:rounded-full" : ""}`}
            onClick={handleClick}
        >
            {title}
        </button>
    )
}

export default TableTab