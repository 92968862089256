import Button from "components/Button";
import useModal from "hooks/useModal";
import { FC } from "react";
import { HideTrackingInitiatedForever, SetHideTrackingInitiated } from "utils/localStorage";
import Modal from ".";

interface Props {
    helpers: ReturnType<useModal>;
    trackingStarted: string;
    storeName: string;
    isRecentStore?: boolean;
}

const TrackingInitiated: FC<Props> = ({ helpers, storeName, trackingStarted, isRecentStore }) => {
    const onBtnClick = (isDontShow?: boolean) => {
        if (isDontShow) {
            HideTrackingInitiatedForever();
        } else {
            SetHideTrackingInitiated(storeName);
        }
        helpers.close();
    }
    return (
        <Modal helpers={helpers} maxWidth="max-w-[741px]" cardClassName="pt-[38px] pb-[18px] p-[14px]"
            overlay
            buttonCloseClassName="w-6 h-6 top-[16px] right-[16px]"
        >
            <div className="flex flex-col items-center gap-6 text-center max-w-[560px] mx-auto">
                <h6 className="font-bold">{isRecentStore ? "Tracking Recently Started!" : "Tracking Initiated"}</h6>
                {isRecentStore ? (
                    <p>Live Sales Tracker for <span className="font-bold capitalize">{storeName}</span> began on <span className="font-bold">{trackingStarted}</span>.</p>
                ) : (<p>We have started tracking sales for <span className="font-bold capitalize">{storeName}</span> as of <span className="font-bold">{trackingStarted}</span>.
                    You can check back soon for updated sales data.</p>
                )}
                <div className="flex items-center w-full justify-center gap-4">
                    <Button title="Got it!"
                        className="max-w-[200px] w-full bg-darkBlue text-smoothGreen hover:bg-smoothGreen hover:border-smoothGreen hover:!text-darkBlue dark:bg-lilac400 dark:!text-darkBlue dark:hover:!bg-paradiseBlue dark:hover:!border-paradiseBlue"
                        height="h-11" titleClassName="!text-base !font-bold" handleClick={() => onBtnClick(false)} />
                    <Button title="Don’t show again" className="max-w-[200px] w-full" height="h-11" titleClassName="!text-base" handleClick={() => onBtnClick(true)} />
                </div>
            </div>
        </Modal >
    )
}

export default TrackingInitiated