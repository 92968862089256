import { FC } from "react";
import { BabaDetails } from "types/services/iconFinder";
interface Props {
  details: BabaDetails;
}
const BabaCardFooter: FC<Props> = ({ details }) => (
  <div className="pt-[5px]">
    <div className="flex items-center gap-6">
      <div className="flex item-center gap-1">
        <div>{details.supplier}</div>
        <div>{details.crown}</div>
      </div>
      <div className="flex item-center gap-1">
        {Array.isArray(details.diamonds) ? (
          details.diamonds.map((element, index) => (
            <div key={index}>{element}</div>
          ))
        ) : (
          <div>{details.diamonds}</div>
        )}
      </div>
      <div className="bg-gray-300 rounded-full py-[2px] px-2.5 text-center dark:text-black900">
        <span className="text-[14px] font-semibold mr-[5px]">2</span>
        <span className="text-[10.5px] leading-tight">YRS</span>
      </div>
    </div>
  </div>
);

export default BabaCardFooter;
