import { useNavigate, useSearchParams } from "react-router-dom";

// Components

// Icons
import { PATH } from "routes/path";
import { DASHBOARD_MARKET_PLACES } from "store/slices/marketplaceSlice";
import { GetMarketplace } from "utils/localStorage";
import MyDashboardContent from "./MyDashboardContent";
import NewDashboardContent from "./NewDashboardContent";
import MyDashboardHeader from "./components/Header";

enum TABS {
  marketInsights = "market-insights",
  myDashboard = "my-dashboard",
}
const Dashboard = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate()

  const localMarketplace = GetMarketplace();

  if (localMarketplace === DASHBOARD_MARKET_PLACES.shopify) {
    navigate(PATH.shopify.marketInsights)
  }

  return (
    <>
      <MyDashboardHeader />
      {searchParams.get("tab") === TABS.myDashboard ? (
        <MyDashboardContent />
      ) : (
        <NewDashboardContent />
      )}
    </>
  );
};

export default Dashboard;
