import HoveredIcon from "components/HoveredIcon";

//icons
import { ColumnDef } from "@tanstack/react-table";
import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";
import { ReactComponent as Calendar } from "assets/icons/exp-date.svg";
import { ReactComponent as GlobeFlag } from "assets/icons/globe-flag.svg";
import { ReactComponent as Location } from "assets/icons/globe.svg";
import { ReactComponent as Hammer } from "assets/icons/hammer.svg";
import { ReactComponent as Menu } from "assets/icons/menu.svg";
import { ReactComponent as Star } from "assets/icons/star.svg";
import { ReactComponent as Trash } from "assets/icons/trash.svg";
import TableCheckbox from "components/Table/TableCheckbox";
import { Link } from "react-router-dom";
import { PATH } from "routes/path";
import { BulkScannedSellerList } from "types/services/bulkScanner";
import { formatNumber } from "utils/formatCurrency";
import { formatDate } from "utils/formatTime";
import { getCountryFlag } from "utils/getCountryFlag";

export const scannedSellersCols = (
  handleDelete: (id: string) => void,
  onDeleteSelected: () => void,
  handleSort: (column: string) => void
): ColumnDef<BulkScannedSellerList>[] => [
    {
      header: () => <Location className="fill-darkBlue dark:fill-grey100 h-[17.5px]" />,
      accessorKey: "countryCode",
      minSize: 40,
      cell: (countryCode) => (
        <>
          {countryCode.getValue() !== null ? (
            <img
              src={getCountryFlag(String(countryCode.getValue()))}
              alt={String(countryCode.getValue()) || "-"}
              className="w-[22px] h-[22px]"
            />
          ) : (
            <GlobeFlag className="fill-darkBlue dark:fill-grey100 w-5 h-5" />
          )}
        </>
      ),
      meta: {
        tooltipTitle: "Country",
        customSort: () => handleSort("countryCode"),
        sortColumnId: "countryCode",
        align: "justify-center",
        alignHead: "justify-between"
      }
    },
    {
      header: () => <CompetitorResearch className="fill-darkBlue dark:fill-grey100 w-[15px] h-[16.5px]" />,
      accessorKey: "ebayUsername",
      cell: ({ row: { original } }) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          to={
            PATH.zikPro.bulkScanner.root +
            `/${original.searchedUserID}?tab=matched`
          }
          className="font-bold hover:text-primaryPurple dark:hover:text-purple500"
        >
          {original.ebayUsername}
        </Link>
      ),
      meta: {
        tooltipTitle: "Seller",
        customSort: () => handleSort("ebayUsername"),
        sortColumnId: "ebayUsername",
        align: "justify-center",
        alignHead: "justify-between"
      },
    },
    {
      header: () => <Calendar className="w-[17.5px] h-[17.5px] fill-darkBlue dark:fill-grey100" />,
      accessorKey: "dateRequested",
      cell: (date) => formatDate(String(date.getValue())),
      meta: {
        tooltipTitle: "Date Added",
        customSort: () => handleSort("dateRequested"),
        sortColumnId: "dateRequested",
        align: "justify-center",
        alignHead: "justify-between"
      },
    },
    {
      header: () => <Menu className="fill-darkBlue dark:fill-grey100 w-[17px] h-[11px]" />,
      accessorKey: "totalActiveListings",
      cell: (listings) => formatNumber(String(listings.getValue())),
      meta: {
        tooltipTitle: "Active Listings",
        customSort: () => handleSort("totalActiveListings"),
        sortColumnId: "totalActiveListings",
        align: "justify-center",
        alignHead: "justify-between"
      },
    },
    {
      header: () => <Hammer className="fill-darkBlue dark:fill-grey100 w-[18.75px] h-[17.5px]" />,
      accessorKey: "thirtyDaySales",
      cell: (thirtyDaySales) => formatNumber(String(thirtyDaySales.getValue())),
      meta: {
        customSort: () => handleSort("thirtyDaySales"),
        sortColumnId: "thirtyDaySales",
        align: "justify-center",
        alignHead: "justify-between",
        tooltipTitle: "Sold Items",
      },
    },
    {
      header: () => <Star className="fill-darkBlue dark:fill-grey100 w-[18.75px] h-[17.5px]" />,
      accessorKey: "feedbackScore",
      cell: (feedbackScore) =>
        formatNumber(String(feedbackScore.getValue()) || 0),
      meta: {
        customSort: () => handleSort("feedbackScore"),
        sortColumnId: "feedbackScore",
        align: "justify-center",
        tooltipTitle: "Feedback score",
        alignHead: "justify-between"
      },
    },
    {
      accessorKey: "userSellerScanRequestId",
      header: () => (
        <div className="flex gap-4 px-3.5">
          <div className="w-6" />
          <HoveredIcon
            variant="outlined"
            Icon={Trash}
            width="w-6"
            height="h-6"
            handleClick={onDeleteSelected}
            iconClassName="w-[10px]"
          />
        </div>
      ),
      enableSorting: false,
      cell: ({ row: { original }, row }) => (
        <div className="flex gap-4 px-3.5">
          <TableCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
          <HoveredIcon
            variant="outlined"
            Icon={Trash}
            width="w-6"
            height="h-6"
            handleClick={() =>
              handleDelete(original.userSellerScanRequestId.toString())
            }
            iconClassName={"w-[10px] "}
          />
        </div>
      ),
      meta: {
        alignHead: "justify-center"
      }
    },
  ];
