import { FC } from "react";
import { PlansType, TSelectedPlan } from "types/plans";
import RegistrationFunnelBEbayPlans from "./EbayBPlans";
import RegistrationFunnelBShopifyPlans from "./ShopifyBPlans";

interface Props {
    plans: PlansType;
    isEbay?: boolean;
    handleSelectPlan: (plan: TSelectedPlan) => void;
}

const PlansContentB: FC<Props> = ({ plans, isEbay, handleSelectPlan }) => isEbay ?
    <RegistrationFunnelBEbayPlans plans={plans} handleSelectPlan={handleSelectPlan} /> :
    <RegistrationFunnelBShopifyPlans handleSelectPlan={handleSelectPlan} />

export default PlansContentB