import { ReactComponent as EbayLogo } from "assets/icons/ebay.svg";
import { ReactComponent as ShopifyLogo } from "assets/icons/shopify.svg";
import useWindowDimensions from "hooks/useWindowDimensions";
import { createRef, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { tutorialSidebar } from "types/academy";

interface Props {
  sidebarTitle: string;
  sidebarData: tutorialSidebar;
}

const Sidebar = ({
  sidebarTitle,
  sidebarData,
}: Props) => {
  const location = useLocation()
  const { t } = useTranslation(['home']);
  const { height } = useWindowDimensions();
  const sidebarRef = useRef<HTMLDivElement>(null);
  const sbh = sidebarRef.current?.clientHeight;
  return (
    <>
      <div ref={sidebarRef} className="max-w-[293px] w-full rounded-10 bg-white dark:bg-black900 shadow-lg p-[15px] flex flex-col h-fit shrink-0 sticky top-5 max-[1000px]:hidden">
        <div className="text-center font-bold dark:text-grey100">{sidebarTitle}</div>
        <div className="w-full h-[2px] bg-secondary dark:opacity-[0.5] dark:bg-grey200 my-[10px] mx-auto rounded-full" />

        <div className="scrollbar overflow-y-auto pr-[10px] mr-[-10px]" style={{ maxHeight: sbh && sbh > height ? "80vh" : "" }}>
          <div className="flex items-center mb-[10px] justify-center rounded-[5px] bg-white dark:bg-deepSpace900 border-[1px] border-secondary dark:border-purple500 w-full h-[42px] shadow-[0px_8px_32px_-6px_rgba(20,_23,_26,_0.08)] px-[12px]">
            <ShopifyLogo className="w-[64px] h-auto ml-auto text-darkBlue dark:text-white" />
            <div className="h-[21px] w-[42px] rounded-[4px] bg-secondary dark:bg-purple500 text-darkBlue dark:text-grey100 text-[10px] font-bold leading-[12px] flex items-center justify-center ml-auto">New!</div>
          </div>
          <div className="flex flex-col gap-2.5">
            {sidebarData.filter(x => x.isShopify).map((item) => (
              <a
                href={`#sect-${item.id}`}
                ref={createRef()}
                key={item.id}
                className={`${location.hash.includes(item.id) ?
                  "border-darkBlue bg-transparent dark:border-grey100 cursor-default" : "bg-neutral2 border-transparent dark:bg-deepSpace800 cursor-pointer dark:hover:bg-purple500 hover:text-white dark:hover:border-purple500 hover:bg-primaryPurple hover:border-primaryPurple dark:text-grey100"
                  } w-full block flex mx-auto items-center pl-3.5 text-sm h-10 w-full font-medium rounded-[50px] 
                dark:text-grey100 border-2`}
              >
                <span>

                  {t(item.translation)}
                </span>
              </a>
            ))}
          </div>
          <div className="w-full h-[2px] bg-secondary dark:opacity-[0.5] dark:bg-grey200 my-[10px] mx-auto rounded-full" />
          <div className="flex items-center mb-[10px] justify-center rounded-[5px] border-[1px] border-secondary dark:border-purple500 bg-white dark:bg-deepSpace900 w-full h-[42px] shadow-[0px_8px_32px_-6px_rgba(20,_23,_26,_0.08)] px-[12px]">
            <EbayLogo className="w-[40px] h-[21px] " />
          </div>
          <div className="flex flex-col gap-2.5">
            {sidebarData.filter(x => !x.isShopify).map((item) => (
              <a
                href={`#sect-${item.id}`}
                ref={createRef()}
                key={item.id}
                className={`${location.hash.includes(item.id) ?
                  "border-darkBlue bg-transparent dark:border-grey100 cursor-default" : "bg-neutral2 border-transparent dark:bg-deepSpace800 cursor-pointer dark:hover:bg-purple500 hover:text-white dark:hover:border-purple500 hover:bg-primaryPurple hover:border-primaryPurple dark:text-grey100"
                  } w-full block flex mx-auto items-center pl-3.5 text-sm h-10 w-full font-medium rounded-[50px] 
                dark:text-grey100 border-2`}
              >
                <span>

                  {t(item.translation)}
                </span>
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  )
};

export default Sidebar;
