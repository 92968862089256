export const keywordsProvided = [
  {
    title: "Pot Hanger",
    competitionLevel: 17,
    sales: 25,
    averageSearches: 1887,
    id: "01",
  },
];

export const longTailKeywords = [
  {
    id: "01",
    title: "Stainless Steel Quartz",
    averageSearches: 1887,
    competition: 17,
    sales: 25,
    scores: 908,
    ebaySearches: 67,
    ebayCompetition: 45,
    ebaySales: 5674,
    aliexpressSearches: 67,
    aliexpressCompetition: 45,
    aliexpressSales: 5674,
    amazonSearches: 67,
    amazonCompetition: 45,
    amazonSales: 5674,
  },
  {
    id: "02",
    title: "Men’s Watch",
    averageSearches: 1887,
    competition: 17,
    sales: 25,
    scores: 908,
    ebaySearches: 67,
    ebayCompetition: 45,
    ebaySales: 5674,
    aliexpressSearches: 67,
    aliexpressCompetition: 45,
    aliexpressSales: 5674,
    amazonSearches: 67,
    amazonCompetition: 45,
    amazonSales: 5674,
  },
];

export const genericKeywords = [
  {
    title: "Hanger",
    sales: 25,
    averageSearches: 1998,
    id: "01",
  },
  {
    title: "Hanger",
    sales: 25,
    averageSearches: 1998,
    id: "02",
  },
  {
    title: "Hanger",
    sales: 25,
    averageSearches: 1998,
    id: "03",
  },
  {
    title: "Hanger",
    sales: 25,
    averageSearches: 1998,
    id: "04",
  },
  {
    title: "Hanger",
    sales: 25,
    averageSearches: 1998,
    id: "05",
  },
  {
    title: "Hanger",
    sales: 25,
    averageSearches: 1998,
    id: "06",
  },
];
