import { ReactComponent as Upload } from "assets/icons/upload.svg";
import Button from "components/Button";
import useModal from "hooks/useModal";
import { FC } from "react";
import { FolderFilters } from "types/services/productMangement";
import ConnectLister from "./ConnectLister";

interface Props {
  type: "Turbo" | "AutoPilot" | "Folder";
  folderFilters?: FolderFilters;
  onUploadProgress: () => void;
}

const UploadAll: FC<Props> = ({ type, folderFilters, onUploadProgress }) => {
  const modal = useModal();

  return (
    <>
      <Button
        title="Upload all"
        handleClick={modal.open}
        color="primaryPurple"
        className={"max-w-[174px] dark:bg-deepSpace900"}
        Icon={Upload}
      />
      <ConnectLister
        helpers={modal}
        type={type}
        onUploadProgress={onUploadProgress}
        folderFilters={folderFilters}
        selectedItems={[]}
        allChecked={true}
      />
    </>
  );
};

export default UploadAll;
