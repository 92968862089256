import { ReactComponent as Alibaba } from "assets/icons/alibaba.svg";
import { ReactComponent as Aliexpress } from "assets/icons/aliexpress.svg";
import { ALIEXPRESS_SITE, CJ_DROPSHIPPING, GOOGLE_LENS, WALMART_SITE } from "config/constants";
import useModal from "hooks/useModal";
import { FC } from "react";
import { useGetSettingsQuery } from "store/api/quickSettingsApi";
import { IconFinderItem } from "types/services/iconFinder";
import findBoxIcon from "utils/findBoxIcon";
import { CURRENCY_SIGNS } from "utils/formatCurrency";
import { makeQueryParams } from "utils/makeQueryParams";
import { getAmazonDomain } from "utils/settings";
import AliexpressSimilarItems from "./AliexpressSimilarItems";
import IconFinderSelectedItem from "./IconFinderSelectedItem";
import ItemFinderWrapper from "./ItemFinderWrapper";

interface Props {
    helpers: ReturnType<useModal>;
    item: IconFinderItem
    currency?: CURRENCY_SIGNS
    marketplace?: string
}
const ShopifyItemFinder: FC<Props> = ({ helpers, item, currency, marketplace }) => {
    const { data: settings } = useGetSettingsQuery();
    const sourceMarketplace = findBoxIcon(marketplace || settings?.result.amazonsite || "")?.title;
    const isAliexpress = sourceMarketplace === "AliExpress";
    const onSaveItem = () => { };
    const redirectTo = {
        google: makeQueryParams(`${GOOGLE_LENS}/uploadbyurl`, {
            url: item.img,
        }),
        amazon: makeQueryParams(`https://www.amazon${getAmazonDomain("US")}/s`, { k: item.title }),
        aliexpress: `${ALIEXPRESS_SITE}/af/${item.title}.html?switch_new_app=y&origin=n&dida=y`,
        walmart: makeQueryParams(`${WALMART_SITE}/search`, {
            query: item.title,
        }),
        cj: makeQueryParams(
            `${CJ_DROPSHIPPING}/list/wholesale-all-categories-l-all.html`,
            { search: item.title }
        ),
    };

    return (
        <ItemFinderWrapper redirectTo={redirectTo} helpers={helpers} type="shopify">
            <IconFinderSelectedItem item={item} currency={currency} />
            <div className="flex justify-between items-center mb-5.5">
                <div className="flex items-center gap-3">
                    {isAliexpress ? <Aliexpress /> : <Alibaba />}
                    <h6 className="text-xs font-bold dark:text-grey100 capitalize">
                        {`Similar Products on ${sourceMarketplace}`}
                    </h6>
                </div>
            </div>
            {helpers.isOpen ? (
                <AliexpressSimilarItems
                    isShopify
                    item={item}
                    helpers={helpers}
                    marketplace={isAliexpress ? "Ali" : "Baba"}
                    onSaveItem={onSaveItem}
                />
            ) : <div />}
        </ItemFinderWrapper>
    )
}

export default ShopifyItemFinder