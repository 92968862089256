import ShimmerLoading from "components/ShimmerLoading"

interface Props {
    numberOfRows: number
}


const LoadingRow = () => {
    return (
        <tr className="">
            <td className="p-0">
                <div className="border-y-2 border-secondary dark:border-deepSpace800 dark:bg-black900 border-l-2 rounded-l-10 h-[81px] flex items-center">
                    <div className="border-r-2 px-3.5 border-secondary dark:border-deepSpace800 w-full flex items-center gap-[16px]">
                        <ShimmerLoading className="max-w-[75px] h-[40px] ml-[13px]" />
                        <div className="w-full">
                            <ShimmerLoading className="h-[17px] mb-[8px]" />
                            <ShimmerLoading className="max-w-[71px] h-[17px]" />
                        </div>
                    </div>
                </div>
            </td>
            <td className="p-0">
                <div className="flex items-center border-y-2 border-secondary dark:border-deepSpace800  dark:bg-black900 h-[81px]">
                    <div className="flex w-full border-secondary px-1 dark:border-deepSpace800 border-r-2 px-3.5 justify-center">
                        <ShimmerLoading className="max-w-[100px] h-[17px]" />
                    </div>

                </div>
            </td>
            <td className="p-0">
                <div className="flex items-center border-y-2 border-secondary dark:border-deepSpace800  dark:bg-black900 h-[81px]">
                    <div className="flex w-full border-secondary px-1 dark:border-deepSpace800 border-r-2 px-3.5  justify-center">
                        <ShimmerLoading className="max-w-[100px] h-[17px]" />
                    </div>
                </div>
            </td>
            <td className="p-0">
                <div className="flex items-center border-y-2 border-secondary dark:border-deepSpace800  dark:bg-black900 h-[81px]">
                    <div className="flex w-full border-secondary px-1 dark:border-deepSpace800 border-r-2 px-3.5  justify-center">
                        <ShimmerLoading className="max-w-[100px] h-[17px]" />
                    </div>
                </div>
            </td>
            <td className="p-0">
                <div className="flex items-center border-y-2 border-secondary dark:border-deepSpace800  dark:bg-black900 h-[81px]">
                    <div className="flex  w-full border-secondary px-1 dark:border-deepSpace800 border-r-2 px-3.5  justify-center">
                        <ShimmerLoading className="max-w-[100px] h-[17px]" />
                    </div>
                </div>
            </td>
            <td className="p-0">
                <div className="flex items-center border-y-2 border-secondary dark:border-deepSpace800  dark:bg-black900 h-[81px]">
                    <div className="flex  w-full border-secondary px-1 dark:border-deepSpace800 border-r-2 px-3.5  justify-center">
                        <ShimmerLoading className="max-w-[100px] h-[17px]" />
                    </div>
                </div>
            </td>
            <td className="p-0">
                <div className="flex items-center border-y-2 border-secondary dark:border-deepSpace800  dark:bg-black900 h-[81px]">
                    <div className="flex w-full flex-col border-secondary px-1 dark:border-deepSpace800 border-r-2 px-3.5 items-center">
                        <ShimmerLoading className="max-w-[100px] h-[17px] mb-[8px]" />
                        <ShimmerLoading className="max-w-[100px] h-[17px]" />
                    </div>
                </div>
            </td>
            <td className="p-0">
                <div className="flex items-center border-y-2 border-secondary dark:border-deepSpace800  dark:bg-black900 h-[81px] border-r-2 rounded-r-10 shrink-0">
                    <div className="flex w-full px-1 px-3.5 shrink-0 justify-center">
                        <ShimmerLoading className="max-w-[34px] h-[34px] !rounded-full" />
                    </div>
                </div>

            </td>

        </tr>)

}

const TableLoading = ({ numberOfRows }: Props) => {
    const rows = Array.from({ length: numberOfRows }, (v, k) => k + 1);;
    return (
        <>
            {rows.map((row, i) => (<LoadingRow key={i + Date.now()} />))}
        </>)


}

export default TableLoading