import Loading from "components/Loading";
import Modal from "components/Modal";
import useModal from "hooks/useModal";
import { useState } from "react";

import {
  useGetBulkStoresQuery,
  useGetUploadSettingsQuery
} from "store/api/quickSettingsApi";
import { FolderFilters } from "types/services/productMangement";
import AddStore from "./AddStore";
import UploadListingsForm from "./UploadListingsForm";

interface Props {
  helpers: ReturnType<useModal>;
  type: "Turbo" | "AutoPilot" | "Folder";
  selectedItems: number[];
  allChecked: boolean;
  folderFilters?: FolderFilters;
  onUploadProgress: () => void;
}

const ConnectLister = ({
  helpers,
  type,
  selectedItems,
  folderFilters,
  onUploadProgress,
  allChecked,
}: Props) => {
  const [isAutoDS, setIsAutoDS] = useState(false);
  const { data, isLoading } = useGetBulkStoresQuery(undefined, {
    skip: !helpers.isOpen,
  });
  const { isLoading: uploadHistoryLoading } = useGetUploadSettingsQuery(
    undefined,
    {
      skip: !helpers.isOpen,
    }
  );

  const isNotEmpty = data && data?.result.bulkStores.length > 0;

  return (
    <Modal
      helpers={helpers}
      className="flex flex-col items-center"
      cardClassName={`${isAutoDS ? "pt-8" : "pt-6"} pb-[30px]`}
      maxWidth={isNotEmpty ? "max-w-[663px]" : isAutoDS ? "max-w-[434px]" : "max-w-[509px]"}
      title={isNotEmpty ? "Upload to your Store" : "Choose your preferred Integration"}
      titleClassName={isNotEmpty ? "" : "!mb-2"}
      buttonCloseClassName={isAutoDS ? "top-[12px] right-[11px]" : "top-[20px] right-[26px]"}
    >
      {isLoading || uploadHistoryLoading ? (
        <div className="w-full h-[10rem] flex items-center justify-center">
          <Loading fill="fill-primaryPurple dark:fill-purple500" width={48} height={48} />
        </div>
      ) : isNotEmpty ? (
        <UploadListingsForm
          type={type}
          helpers={helpers}
          onUploadProgress={onUploadProgress}
          selectedItems={selectedItems}
          allChecked={allChecked}
          folderFilters={folderFilters}
        />
      ) : (
        <AddStore isAutoDS={isAutoDS} onAutoDSChange={(val) => setIsAutoDS(val)} />
      )}
    </Modal>
  );
};

export default ConnectLister;
