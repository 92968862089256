import useWindowDimensions from "hooks/useWindowDimensions";
import { Dispatch, FC, SetStateAction, useRef, useState } from "react";

interface ImagePosType {
  top: number;
  left: number;
}

interface Props {
  imgWrapperClassName?: string;
  height?: string;
  img: string;
  noTitle: boolean;
  hoveredImgClassName?: string;
  width?: string;
  maxHeight?: string;
  hoveredImgWidth?: number
  isFirst?: boolean;
  isLast?: boolean;
  adjustFirstandLastItemsAuto?: boolean;
  responsiveImageWidth?: number;
  setImageLoadError: Dispatch<SetStateAction<boolean>>
}
const ProductImage: FC<Props> = ({
  imgWrapperClassName,
  height,
  img,
  hoveredImgClassName,
  noTitle,
  width,
  maxHeight,
  hoveredImgWidth,
  isFirst,
  adjustFirstandLastItemsAuto,
  isLast,
  responsiveImageWidth,
  setImageLoadError
}) => {
  const divRef = useRef<HTMLDivElement>(null)
  const [showHoveredImage, setShowHoveredImage] = useState(false);
  const [renderImageFromBottom, setRenderImageFromBottom] = useState(false);
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  const imageRect = divRef.current?.getBoundingClientRect();
  const [imagePos, setImagePos] = useState<ImagePosType>({ top: 0, left: 0 });

  const handleImageLoad = (event: any) => {
    const imageHeight = event.target.clientHeight;
    const willCutOnBottom = imageRect?.top + imageHeight > windowHeight;
    setImagePos({
      top: (imageRect?.top || 0) - imageHeight,
      left: imageRect && imageRect?.left + imageRect?.width || 0
    })
    setRenderImageFromBottom(willCutOnBottom || false);
  }




  return (
    <div ref={divRef} className="group" onMouseOver={() => { setShowHoveredImage(true) }} onMouseLeave={() => { setShowHoveredImage(false) }}>
      <div className={`${noTitle ? "" : "mr-10"} w-full`}>
        <div
          className={`flex items-center relative rounded-[7px]
          ${imgWrapperClassName || ""} ${noTitle ? "" : "ml-4"}`}

        >
          <div>
            <img
              src={img}
              alt=""
              className={`my-auto rounded-[7px] max-h-[4.5rem] shadow cursor-zoom-in  ${maxHeight || "max-h-[60px]"} group-hover:border-2 group-hover:border-primaryPurple dark:group-hover:border-purple500`}
              width={width || 60}
              height={height || 60}
              referrerPolicy="no-referrer"
              onError={() => img !== "" && setImageLoadError(true)}
            />
          </div>
        </div>
        {showHoveredImage && (
          <div
            className={`cursor-zoom-in fixed hidden group-hover:block block z-50 pl-2 left-[70px] opacity-0 animate-[showin_80ms_ease-in_forwards] ${hoveredImgClassName || ""}`}
            style={renderImageFromBottom ? { left: imagePos.left, top: imagePos.top, } : { left: imagePos.left, top: imageRect?.top, }}
          >
            <img
              src={img}
              alt="product"
              className="my-auto rounded-[7px] shadow-lg border-primaryPurple dark:border-purple500 border-2 bg-white"
              width={hoveredImgWidth || 190}
              height={"auto"}
              referrerPolicy="no-referrer"
              onLoad={handleImageLoad}
              style={responsiveImageWidth ? { width: `${responsiveImageWidth}vw` } : {}}
            />
          </div>)}

      </div>
    </div>
  )
};

export default ProductImage;
