import { ColumnDef } from "@tanstack/react-table";
import { turboScannerSuppliers } from "assets/data/turboScanner";
import { ReactComponent as Watchers } from "assets/icons/eye.svg";
import { ReactComponent as InfoOrange } from "assets/icons/info-orange.svg";
import { ReactComponent as Trash } from "assets/icons/trash.svg";
import HoveredIcon from "components/HoveredIcon";
import Label from "components/Label";
import ProductNameCell from "components/ProductNameCell";
import TableCheckbox from "components/Table/TableCheckbox";
import Tooltip from "components/Tooltip";
import { Link } from "react-router-dom";
import { PATH } from "routes/path";
import { CURRENCY } from "types/currency";
import {
  TuboResultsList,
  TurboScanHistoryList
} from "types/services/turboScanner";
import {
  formatCurrency,
  formatNumber
} from "utils/formatCurrency";
import { formatDate } from "utils/formatTime";

export const turboScannerCols = (
  onViewClick: (id: string) => void,
  onDelete: (id: number) => void,
  deleteLoading: boolean,

  selectedItem: number
): ColumnDef<TurboScanHistoryList>[] => [
    {
      header: "View Scan",
      accessorKey: "id",
      cell: ({ row: { original } }) => (
        <Link to={`${PATH.zikPro.turboScanner.turboScanner}/${original.id}${original.supplier === 4 ? "?isWalmart=true" : ""}`}
        > <HoveredIcon
            Icon={Watchers}
            variant="outlined"
            handleClick={() => onViewClick(String(original.id))}
          /></Link>
      ),
    },
    {
      header: "Supplier",
      accessorKey: "supplier",
      cell: ({ row: { original } }) =>
        turboScannerSuppliers[
        original.supplier as keyof typeof turboScannerSuppliers
        ],
    },
    {
      header: "Products Found",
      accessorKey: "productsFound",
      cell: ({ row: { original } }) =>
        original.productsFound && (
          <div className="flex gap-4 items-center">
            <p>{String(original.productsFound)}</p>
            <InfoOrange className="cursor-pointer" data-tooltip-id={original.id.toString()} />
            <Tooltip
              title="New Feature! Each scan excludes previously found products. 
            For including past results in future scans, delete the past scans. If you have low results, you can delete all scans."
              anchorId={original.id}
              place="top"
            />
          </div>
        ),
    },
    {
      header: "Date Created",
      accessorKey: "createdAt",
      cell: (date) => formatDate(String(date.getValue())),
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: ({ row: { original } }) => (
        <Label
          title={original.status ? "Completed!" : "Pending!"}
          bgColor={original.status ? "success" : "errorOrange"}
          className={`text-white mx-2`}
        />
      ),
    },
    {
      header: () => <Trash className="fill-darkBlue dark:fill-grey100" />,
      accessorKey: "isDeleted",
      meta: {
        alignHead: "justify-center"
      },
      cell: ({ row: { original } }) => (
        <HoveredIcon
          variant="outlined"
          Icon={Trash}
          isLoading={selectedItem === original.id && deleteLoading}
          iconClassName="w-3"
          handleClick={() => onDelete(original.id)}
        />
      ),
    },
  ];

export const turboResultCols = (
  isWalmart: boolean,
  handleSort: (column: string) => void
): ColumnDef<TuboResultsList>[] => [
    {
      header: ({ table }) => (
        <TableCheckbox
          {...{
            checked: table.getIsAllPageRowsSelected(),
            indeterminate: table.getIsSomePageRowsSelected(),
            onChange: table.getToggleAllPageRowsSelectedHandler(),
          }}

        />
      ),
      minSize: 70,
      accessorKey: "id",
      enableSorting: false,

      cell: ({ row }) => (
        <TableCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
      meta: {
        hideBorder: true,
      },
    },
    {
      header: "Image",
      accessorKey: "pictureURL",
      cell: ({ row: { original } }) => (
        <div className="w-[7rem]">
          <ProductNameCell
            img={original.pictureURL}
            maxHeight="max-h-[61px]"
            hoveredImgClassName="left-[75px]"
            width="80px"
            height="61px"
          />
        </div>
      ),
      enableSorting: false,
      minSize: 100,
      meta: {
        alignHead: 'justify-start',
        align: "justify-start",
      },
    },
    {
      header: "Title",
      accessorKey: "title",
      // maxSize: 400,
      minSize: 400,
      cell: ({ row: { original } }) => (
        <div className="px-4 w-full">
          <ProductNameCell
            name={original.title}
            checkKeywords
            url={original.productURL}
          />
        </div>
      ),
      meta: {
        alignHead: "justify-between",
        customSort: () => handleSort("3"),
        sortColumnId: "2",
      },
    },
    {
      header: "Product ID",
      minSize: 130,
      enableSorting: false,
      accessorKey: "asin",
    },
    {
      header: "Price",
      accessorKey: "price",
      cell: ({ row: { original } }) =>
        formatCurrency(
          String(original.price),
          original.supplier === 2 ? CURRENCY.pound : CURRENCY.dollar //2 is amazon.co.uk
        ),
      meta: {
        customSort: () => handleSort("4"),
        sortColumnId: "4",
        alignHead: "justify-between",
      },
    },
    {
      header: "30 Day Sales",
      accessorKey: "sales",
      cell: (sales) => formatNumber(String(sales.getValue())),
      meta: {
        customSort: () => handleSort("5"),
        sortColumnId: "5",
        alignHead: "justify-between",
      },
    },
    {
      header: "Rating",
      accessorKey: "rating",
      meta: {
        customSort: () => handleSort("6"),
        sortColumnId: "6",
        alignHead: "justify-between",
      },
    },
    ...(isWalmart ? [] : [{
      header: "BSR",
      accessorKey: "bsr",
      cell: (bsr: any) => formatNumber(String(bsr.getValue())),
      meta: {
        customSort: () => handleSort("7"),
        sortColumnId: "7",
        alignHead: "justify-between" as "justify-between",
      },
    }]),
    {
      header: "Reviews",
      accessorKey: "totReviews",
      cell: (reviews) => formatNumber(String(reviews.getValue())),
      meta: {
        customSort: () => handleSort("8"),
        sortColumnId: "8",
        alignHead: "justify-between",
      },
    },
    {
      header: "Category",
      accessorKey: "category",
      meta: {
        customSort: () => handleSort("9"),
        sortColumnId: "9",
        alignHead: "justify-between",
      },
      cell: (cat) => <p className="text-center">{String(cat.getValue())}</p>,
    },
  ];
