import { ColumnDef } from "@tanstack/react-table";
import CopyTitleColumn from "pages/TitleBuilder/components/CopyTitleColumn";
import { GenericKeyword, KeywordsProvided, LongTailKeywords } from "types/titleAnalytics";
import { formatNumber } from "utils/formatCurrency";

export const genericKeywordsCols = (
  onTitleCopy: (newVal: string) => void
): ColumnDef<GenericKeyword>[] => [
  {
    header: "Generic Keywords Ideas",
    accessorKey: "title",
    cell: ({ row: { original } }) => (
      <CopyTitleColumn
        key={original.id}
        title={original.title}
        handleClick={() => onTitleCopy(original.title)}
      />
    ),
    minSize: 200,
  },
  {
    header: "Avg. Searches",
    accessorKey: "averageSearches",
    cell: (searches) => formatNumber(String(searches.getValue())),
    minSize: 80,
  },
  {
    header: "Sales",
    accessorKey: "sales",
    cell: (sales) => formatNumber(String(sales.getValue())),
    minSize: 80,
  },
];

export const keywordsProvidedCols = (
  onTitleCopy: (newVal: string) => void
): ColumnDef<KeywordsProvided>[] => [
  {
    header: "Keywords Provided",
    accessorKey: "title",
    cell: ({ row: { original } }) => (
      <CopyTitleColumn
        key={original.id}
        title={original.title}
        handleClick={() => onTitleCopy(original.title)}
      />
    ),
  },
  {
    header: "Avg. Searches",
    accessorKey: "averageSearches",
    cell: (searches) => formatNumber(String(searches.getValue())),
  },
  {
    header: "Competition",
    accessorKey: "competitionLevel",
    cell: (level) => formatNumber(String(level.getValue())),
  },
  {
    header: "Sales",
    accessorKey: "sales",
    cell: (sales) => formatNumber(String(sales.getValue())),
  },
];

export const longTailKeywordsCols = (
  onTitleChange: (newVal: string) => void
): ColumnDef<LongTailKeywords>[] => [
  {
    header: "Keywords",
    accessorKey: "title",
    cell: ({ row: { original } }) => (
      <CopyTitleColumn
        key={original.id}
        title={original.title}
        handleClick={() => onTitleChange(original.title)}
      />
    ),
    minSize: 200,
  },
  {
    header: "Avg. Searches",
    accessorKey: "averageSearches",
    cell: (searches) => formatNumber(String(searches.getValue())),
    minSize: 80,
  },
  {
    header: "Competition",
    accessorKey: "competitionLevel",
    cell: (level) => formatNumber(String(level.getValue())),
    minSize: 80,
  },
  {
    header: "Sales",
    accessorKey: "sales",
    cell: (sales) => formatNumber(String(sales.getValue())),
    minSize: 80,
  },
];

const ebaySearches = [
  {
    header: "eBay Searches",
    accessorKey: "ebaySearches",
    cell: (ebaySearches: { getValue: () => any }) => formatNumber(String(ebaySearches.getValue())),
    minSize: 80,
  },
  {
    header: "Competition",
    accessorKey: "ebayCompetition",
    cell: (ebayCompetition: { getValue: () => any }) =>
      formatNumber(String(ebayCompetition.getValue())),
    minSize: 80,
  },
  {
    header: "Sales",
    accessorKey: "ebaySales",
    cell: (ebaySales: { getValue: () => any }) => formatNumber(String(ebaySales.getValue())),
    minSize: 80,
    meta: { extract: true },
  },
];
const aliexpressSearches = [
  {
    header: "Aliexpress Searches",
    accessorKey: "aliexpressSearches",
    cell: (aliexpressSearches: { getValue: () => any }) =>
      formatNumber(String(aliexpressSearches.getValue())),
    minSize: 80,
  },
  {
    header: "Competition",
    accessorKey: "aliexpressCompetition",
    cell: (aliexpressCompetition: { getValue: () => any }) =>
      formatNumber(String(aliexpressCompetition.getValue())),
    minSize: 80,
  },
  {
    header: "Sales",
    accessorKey: "aliexpressSales",
    cell: (aliexpressSales: { getValue: () => any }) =>
      formatNumber(String(aliexpressSales.getValue())),
    minSize: 80,
    meta: { extract: true },
  },
];

const amazonSearches = [
  {
    header: "Amazon Searches",
    accessorKey: "amazonSearches",
    cell: (amazonSearches: { getValue: () => any }) =>
      formatNumber(String(amazonSearches.getValue())),
    minSize: 80,
  },
  {
    header: "Competition",
    accessorKey: "amazonCompetition",
    cell: (amazonCompetition: { getValue: () => any }) =>
      formatNumber(String(amazonCompetition.getValue())),
    minSize: 80,
  },
  {
    header: "Sales",
    accessorKey: "amazonSales",
    cell: (amazonSales: { getValue: () => any }) => formatNumber(String(amazonSales.getValue())),
    minSize: 80,
    meta: { extract: true },
  },
];

export const keywordsWithEbay = (
  selected: string[],
  onTitleChange: (newVal: string) => void
): ColumnDef<LongTailKeywords>[] => [
  {
    header: "Keywords",
    accessorKey: "title",
    cell: ({ row: { original } }) => (
      <CopyTitleColumn
        key={original.id}
        title={original.title}
        handleClick={() => onTitleChange(original.title)}
      />
    ),
    minSize: 200,
    meta: { extract: selected.length !== 0 ? true : false },
  },
  ...(selected.length === 0
    ? [
        {
          header: "Average Searches",
          accessorKey: "averageSearches",
          cell: (averageSearches: { getValue: () => any }) =>
            formatNumber(String(averageSearches.getValue())),
          minSize: 120,
        },
        {
          header: "Competition",
          accessorKey: "competition",
          cell: (competition: { getValue: () => any }) =>
            formatNumber(String(competition.getValue())),
          minSize: 120,
        },
        {
          header: "Sales",
          accessorKey: "sales",
          cell: (sales: { getValue: () => any }) => formatNumber(String(sales.getValue())),
          minSize: 80,
        },
      ]
    : []),
  ...(selected.includes("ebay") ? [...ebaySearches] : []),
  ...(selected.includes("aliexpress") ? [...aliexpressSearches] : []),
  ...(selected.includes("amazon") ? [...amazonSearches] : []),
  ...(selected.length !== 0
    ? [
        {
          header: "Scores",
          accessorKey: "scores",
          cell: (scores: { getValue: () => any }) => formatNumber(String(scores.getValue())),
          minSize: 80,
        },
      ]
    : []),
];
