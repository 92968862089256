import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg";
import { ReactComponent as SortArrowUp } from "assets/icons/sort-arrow-up.svg";
import Tooltip from "components/Tooltip";
import { FC } from "react";
import { formatCurrency } from "utils/formatCurrency";

interface ColProps {
    colKey: string;
    colTitle?: string;
    onTitleClick: (key: string) => void;
    sortedColKey: string;
    currencySymbol?: string;
    ascending: boolean;
    value: string;
    convertedValue?: string;
    isTotalSold?: boolean;
    isShopify?: boolean;
    isCurrency?: boolean;
    tooltip?: string;
    index?: number;
}

const InnerTableCol: FC<ColProps> = ({ colKey, colTitle, currencySymbol, onTitleClick, sortedColKey, isCurrency, ascending, isTotalSold, value, convertedValue, isShopify, tooltip, index }) => (
    <div className={`flex flex-col justify-between gap-[21px] text-center h-16 w-full ${isShopify ? "" : "items-center"}`}>
        <div className={`flex items-center gap-1 text-xs font-semibold cursor-pointer group hover:text-primaryPurple dark:hover:text-purple500 ${isShopify ? "justify-start" : "justify-center"}`}
            onClick={() => onTitleClick(colKey)}>
            {isTotalSold ? (
                <div className="min-[1480px]:hidden" data-tooltip-id="tSold">
                    T. Sold
                </div>
            ) : ""}
            <span className={isTotalSold ? "max-[1480px]:hidden" : ""} data-tooltip-id={colTitle + String(index) || ""}>
                {colTitle || colKey}
            </span>
            <span className="w-2 h-2 flex justify-center items-center">
                {sortedColKey !== colKey ?
                    <ArrowDown className={`w-2 h-2 group-hover:fill-primaryPurple dark:group-hover:fill-purple500`} />
                    : <SortArrowUp className={`fill-darkBlue dark:fill-grey100 group-hover:fill-primaryPurple dark:group-hover:fill-purple500 ${ascending ? "" : "rotate-180"}`} />
                }
            </span>
        </div>
        <div className={`flex gap-1 ${isShopify ? "text-left justify-start" : "justify-center"}`}>
            <div className="min-h-[28px]">
                <p className="font-normal">{isShopify && colKey !== "Sales" ? formatCurrency(convertedValue || "") : value}</p>
                {isShopify && currencySymbol !== "USD" && isCurrency ? <p className="text-grey300 text-[10px] h-[16px]">{currencySymbol} {value}</p> : ""}
            </div>
            <div className="w-2 h-2 " />
        </div>
        {isTotalSold ? (
            <Tooltip title="Total Sold" anchorId="tSold" />
        ) : ""}
        {
            tooltip && <Tooltip title={tooltip} anchorId={colTitle + String(index) || ""} />
        }
    </div>
)

export default InnerTableCol