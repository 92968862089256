import { ColumnDef } from "@tanstack/react-table";
import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";
import { ReactComponent as Save } from "assets/icons/save.svg";
import HoveredIcon from "components/HoveredIcon";
import Loading from "components/Loading";
import SelectFolder from "components/Modal/SelectFolder";
import ProductNameCell from "components/ProductNameCell";
import TableCheckbox from "components/Table/TableCheckbox";
import TableAsinInput from "pages/BulkScannerItems/components/TableAsinInput";
import CompetitorCount from "pages/CompetitorResearch/components/CompetitorCount";
import { Option } from "types/common";
import { CURRENCY } from "types/currency";
import {
  MatchedItemsList,
  NotFoundItemsList
} from "types/services/bulkScanner";
import {
  CURRENCY_SIGNS,
  formatCurrency,
  formatNumber
} from "utils/formatCurrency";
import { formatDate } from "utils/formatTime";

export const bulkScannerSimilarItems = (
  currency: string,
  domain: string,
  isWalmart: boolean,
  isAllChecked: boolean,
  handleCountChange: (count: number, id: string) => void,
  handleSort: (column: string) => void,
): ColumnDef<MatchedItemsList>[] => [
    {
      header: ({ table }) => (
        <TableCheckbox
          {...{
            checked: isAllChecked ? true : table.getIsAllPageRowsSelected(),
            indeterminate: isAllChecked ? true : table.getIsSomePageRowsSelected(),
            onChange: table.getToggleAllPageRowsSelectedHandler(),
          }}
        />
      ),
      minSize: 70,
      accessorKey: "itemID",
      enableSorting: false,
      cell: ({ row }) => (
        <TableCheckbox
          {...{
            checked: isAllChecked ? true : row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: isAllChecked ? true : row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
      meta: {
        alignHead: "justify-center"
      }
    },
    {
      header: "eBay Image",
      accessorKey: "ebayImage",
      enableSorting: false,
      cell: (img) => (
        <div className="w-[7rem] pl-4">
          <ProductNameCell
            img={String(img.getValue())}
            maxHeight="max-h-[61px]"
            hoveredImgClassName="left-[75px]"
            width="80px"
            height="61px"
          />
        </div>
      ),
    },
    {
      header: `${isWalmart ? "Walmart" : "Amazon"} Image`,
      accessorKey: "pictureURL",
      enableSorting: false,
      cell: (img) => (
        <div className="w-[7rem] pl-4">
          <ProductNameCell
            img={String(img.getValue())}
            hoveredImgClassName="left-[75px]"
            maxHeight="max-h-[61px]"
            width="80px"
            height="61px"
          />
        </div>
      ),
    },
    {
      header: isWalmart ? "UPC" : "ASIN",
      accessorKey: "asin",
      enableSorting: false,
      cell: ({ row: { original } }) => (
        <p className="text-center px-4">
          {isWalmart && original.upc ? original.upc : original.asin}
        </p>
      ),
    },
    {
      header: "eBay Title",
      accessorKey: "title",
      minSize: 260,
      cell: ({ row: { original } }) => (
        <ProductNameCell
          name={original.title}
          className="px-2"
          url={`https://ebay${domain || ".com"}/itm/${original.itemID}`}
          checkKeywords
          cutLongTitle={80}

        />
      ),
      meta: {
        alignHead: "justify-between",
        customSort: () => handleSort("Title"),
        sortColumnId: "Title"
      },
    },
    {
      header: `${isWalmart ? "Walmart" : "Amazon"} Title`,
      accessorKey: "amazonTitle",
      minSize: 260,
      cell: ({ row: { original } }) => (
        <ProductNameCell
          name={original.amazonTitle}
          className="px-2"
          url={`https://${isWalmart
            ? `walmart.com/ip/${original.asin}`
            : `amazon${domain}/dp/${original.asin}`
            }`}
          checkKeywords
          cutLongTitle={80}
        />
      ),
      meta: {
        alignHead: "justify-between",
        customSort: () => handleSort("AmazonTitle"),
        sortColumnId: "AmazonTitle"
      },
    },
    {
      header: `${isWalmart ? "Walmart" : "Amazon"} Price`,
      accessorKey: "price",
      minSize: 70,
      cell: (price) =>
        formatCurrency(
          String(price.getValue()),
          CURRENCY.none,
          currency as CURRENCY_SIGNS
        ),
      meta: {
        alignHead: "justify-between",
        customSort: () => handleSort("Price"),
        sortColumnId: "Price"
      },
    },
    {
      header: "eBay Price",
      accessorKey: "currentPrice",
      minSize: 70,
      cell: (price) =>
        formatCurrency(
          String(price.getValue()),
          CURRENCY.none,
          currency as CURRENCY_SIGNS
        ),
      meta: {
        alignHead: "justify-center",
        customSort: () => handleSort("CurrentPrice"),
        sortColumnId: "CurrentPrice"
      },
    },
    {
      header: "Profit",
      accessorKey: "profit",
      minSize: 70,
      cell: (profit) =>
        formatCurrency(
          String(profit.getValue()),
          CURRENCY.none,
          currency as CURRENCY_SIGNS
        ),
      meta: {
        alignHead: "justify-between",
        customSort: () => handleSort("Profit"),
        sortColumnId: "Profit"
      },
    },
    {
      header: "Sales",
      accessorKey: "sales",
      minSize: 70,
      cell: (sales) => formatNumber(Number(sales.getValue())),
      meta: {
        alignHead: "justify-between",
        customSort: () => handleSort("Sales"), sortColumnId: "Sales"
      },
    },
    {
      header: () => <CompetitorResearch className="fill-darkBlue dark:fill-grey100 w-4" />,
      minSize: 70,
      accessorKey: "type",

      cell: ({ row: { original } }) => (
        <CompetitorCount title={original.title} ebayId={Number(original.itemID)}
          handleCountChange={(count) => handleCountChange(count, original.itemID)}
        />
      ),
      meta: {
        customSort: () => handleSort("competitorCount"), sortColumnId: "competitorCount",
        tooltipTitle: "Number of Competitors",
        alignHead: "justify-between",
      }
    },
  ];

export const notFoundeItemsCols = (
  tableValues: {
    asin: { [key: string]: string };
    folder: { [key: string]: Option | null };
  },
  handleChange: (
    newVal: string | Option | null,
    id: string,
    type: "asin" | "folder"
  ) => void,
  addItem: (item: NotFoundItemsList) => void,
  currency: string,
  loadingItems: string[],
  savedItems: string[],
  domain: string,
  isWal: boolean,
  changeInputFocus: () => void
): ColumnDef<NotFoundItemsList>[] => [
    {
      header: "Image",
      accessorKey: "ebayImage",
      enableSorting: false,
      cell: (img) => (
        <div className="w-[6rem] pl-2.5">
          <ProductNameCell
            img={String(img.getValue())}
            width="76px"
            height="77px"
          />
        </div>
      ),
    },

    {
      header: "Title",
      accessorKey: "title",
      maxSize: 300,
      minSize: 250,
      meta: {
        align: "justify-start",
        className: "pl-4",
      },
      cell: ({ row: { original } }) => (
        <ProductNameCell
          url={`https://ebay${domain}/itm/${original.itemID}`}
          name={original.title}
          checkKeywords
        />
      ),
    },
    {
      header: "Upload Date",
      accessorKey: "uploadDate",
      cell: (date) => formatDate(String(date.getValue()), "DD MMM YYYY"),
    },
    {
      header: "Price",
      minSize: 60,
      accessorKey: "currentPrice",
      cell: (price) =>
        formatCurrency(
          String(price.getValue()),
          CURRENCY.none,
          currency as CURRENCY_SIGNS
        ),
    },
    {
      header: "Total Sold",
      minSize: 60,
      accessorKey: "quantitySold",
      cell: (sold) => formatNumber(String(sold.getValue())),
    },
    {
      header: "30 Days",
      minSize: 60,
      accessorKey: "thirtyDaySales",
      cell: (sales) => formatNumber(String(sales.getValue())),
    },
    {
      header: "21 Days",
      minSize: 60,
      accessorKey: "twentyOneDaySales",
      cell: (sales) => formatNumber(String(sales.getValue())),
    },
    {
      header: "14 Days",
      minSize: 60,
      accessorKey: "fourteenDaySales",
      cell: (sales) => formatNumber(String(sales.getValue())),
    },
    {
      header: "7 Days",
      minSize: 60,
      accessorKey: "sevenDaySales",
      cell: (sales) => formatNumber(String(sales.getValue())),
    },
    {
      header: isWal ? "Item ID" : "ASIN",
      accessorKey: "ean",
      enableSorting: false,
      cell: ({ row: { original } }) =>
        loadingItems.includes(original.itemID) ? (
          <div className="w-[7rem] flex justify-center">
            <Loading fill="fill-primaryPurple dark:hover:fill-purple500" />
          </div>
        ) : savedItems.includes(original.itemID) ? (
          <div className="w-[7rem] flex justify-center">
            <div className="border-2 border-smoothGreen dark:border-paradiseBlue h-8 w-8 flex justify-center items-center rounded-full">
              <Checkmark className="fill-smoothGreen dark:fill-paradiseBlue" />
            </div>
          </div>
        ) : (
          <TableAsinInput
            isWal={isWal}
            changeInputFocus={changeInputFocus}
            setValue={(newVal) => { handleChange(newVal, original.itemID, "asin") }}
            itemID={original.itemID}
            newValue={tableValues.asin[original.itemID]}
          />
        ),
    },
    {
      header: "Folder",
      accessorKey: "upc",
      enableSorting: false,
      minSize: 150,
      cell: ({ row: { original } }) => (
        <SelectFolder
          listWrapperClassName="!top-9"
          type="eBay"
          addNewFolderBtnTitle="New"
          cutLongOption={7}
          hideFolderIcon
          isOpen
          cutTitle={10}
          titleClassName="!text-xs !mr-2"
          className="max-w-[125px]"
          btnClassName=" !h-[27px] !pb-1 min-w-[120px]"
          selectedFolder={tableValues.folder[original.itemID]}
          onFolderSelect={(newVal) => {
            handleChange(newVal, original.itemID, "folder")
          }
          }
        />
      ),
    },
    {
      header: "Save",
      minSize: 50,
      enableSorting: false,
      accessorKey: "itemID",
      cell: ({ row: { original } }) => (
        <div className="flex gap-2">
          <HoveredIcon
            Icon={Save}
            handleClick={() => addItem(original)}
            iconClassName="w-3"
            variant="outlined"
            width="w-6"
            height="h-6"
          />
        </div>
      ),
    },
  ];
