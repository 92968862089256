import HoveredIcon from "components/HoveredIcon";

import { ColumnDef } from "@tanstack/react-table";
import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";
import { ReactComponent as Calendar } from "assets/icons/exp-date.svg";
import { ReactComponent as FolderIcon } from "assets/icons/folder-icon.svg";
import { ReactComponent as Globe } from "assets/icons/globe.svg";
import { ReactComponent as ReloadInvert } from "assets/icons/reload-invert.svg";
import { ReactComponent as Reload } from "assets/icons/reload.svg";
import { ReactComponent as Trash } from "assets/icons/trash.svg";
import CutLongTitle from "components/CutLongTitle";
import Label from "components/Label";
import { Link } from "react-router-dom";
import { PATH } from "routes/path";
import { BulkMultiScannerList } from "types/services/bulkScanner";
import { formatDate } from "utils/formatTime";


export const multiScannerCols = (
  onDelete: (id: string) => void,
  onEdit: (item: BulkMultiScannerList) => void
): ColumnDef<BulkMultiScannerList>[] => [
    {
      header: () => <CompetitorResearch className="fill-darkBlue dark:fill-grey100 w-[15px] h-[16.25px]" />,
      accessorKey: "sellerNames",
      cell: (sellerName) => (
        <p className="font-bold text-center px-2">
          <CutLongTitle title={String(sellerName.getValue()).replaceAll(",", ", ")} length={80} />
        </p>
      ),
      meta: {
        tooltipTitle: "Sellers",
        align: "justify-center",
        alignHead: "justify-between"
      }
    },
    {
      header: () => <Calendar className="fill-darkBlue dark:fill-grey100 w-[17.5px] h-[17.5px]" />,
      accessorKey: "dateRequested",
      cell: (date) => <p className="px-2">{formatDate(String(date.getValue()), "MM/DD/YYYY")}</p>,
      meta: {
        tooltipTitle: "Date Added",
        align: "justify-center",
        alignHead: "justify-between"
      }
    },
    {
      header: () => <Globe className="fill-darkBlue dark:fill-grey100 w-[17.5px] h-[17.5px]" />,
      minSize: 100,
      accessorKey: "siteiD",
      cell: ({ row: { original } }) => <p>{original.siteName}</p>,
      meta: {
        tooltipTitle: "Source Website",
        align: "justify-center",
        alignHead: "justify-between"
      }
    },
    {
      header: () => <FolderIcon className="fill-darkBlue dark:fill-grey100 w-[18.75px] h-[13.75px]" />,
      minSize: 80,
      accessorKey: "folderName",
      cell: ({ row: { original } }) => (
        <Link to={PATH.myProducts.root + `/${original.folderId}`} className="text-center text-electricBlue dark:text-grey100 hover:text-primaryPurple dark:hover:text-purple500">{original.folderName}</Link>
      ),
      meta: {
        tooltipTitle: "Folder",
        align: "justify-center",
        alignHead: "justify-between"
      },
    },
    {
      header: () => <Checkmark className="fill-darkBlue dark:fill-grey100 w-[15px] h-[11.25px]" />,
      minSize: 80,
      enableSorting: false,
      accessorKey: "status",
      cell: ({ row: { original } }) => (
        <div className="flex flex-col items-center justify-center">
          {original.status ? original.statusText :
            <Label bgColor="errorOrange" className="text-white" maxWidth="min-w-[100px]" title="Pending" />
          }
        </div>
      ),
      meta: {
        tooltipTitle: "Status",
        align: "justify-center",
        alignHead: "justify-between",
        className: "text-center"
      }
    },

    {
      accessorKey: "multiBulkScanRequestId",
      minSize: 90,
      enableSorting: false,
      header: () => <div className="flex gap-4">
        <ReloadInvert className="fill-darkBlue dark:fill-grey100 -rotate-[180deg]" />
        <Trash className="fill-darkBlue dark:fill-grey100" />

      </div>,
      cell: ({ row: { original } }) => (
        <div className="flex gap-[13px]">
          <HoveredIcon
            variant="outlined"
            Icon={Reload}
            HoveredIcon={ReloadInvert}
            width="w-6"
            height="h-6"
            handleClick={() => onEdit(original)}
            title="Scan Preferences"
          />
          <HoveredIcon
            variant="outlined"
            Icon={Trash}
            handleClick={() =>
              onDelete(original.multiBulkScanRequestId.toString())
            }
            width="w-6"
            height="h-6"
            iconClassName={"w-[10px]"}
          />
        </div>
      ),
      meta: {
        tooltipTitle: "Options",
        alignHead: "justify-center"
      }
    },
  ];
