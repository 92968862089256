
import { ReactComponent as EbayTxt } from "assets/icons/ebay-txt-with-color.svg";
import { ReactComponent as ShopifyTxt } from "assets/icons/shopify-text-with-color.svg";
import { FC } from "react";
import { useSearchParams } from "react-router-dom";

interface Props {
    marketplace: "shopify" | "ebay";
    onMarketplaceChange?: (tab: "shopify" | "ebay") => void;
    hideEbay?: boolean;
    hideShopify?: boolean;
    wrapperClassName?: string;
}
const SelectMarketplace: FC<Props> = ({ marketplace, onMarketplaceChange, hideEbay, hideShopify, wrapperClassName }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const onChange = (tab: "ebay" | "shopify") => {
        onMarketplaceChange && onMarketplaceChange(tab)
        searchParams.set("marketplace", tab);
        setSearchParams(searchParams);
    }
    const marketplaceStyle = (isActive: boolean) => `flex items-center relative justify-center border-[1px] border-solid rounded-[16px] group
    ${isActive ?
            "border-primaryPurple bg-white h-[75px] w-[75px] after:absolute after:h-[2px] after:w-[75px] after:bg-primaryPurple after:bottom-[-9px] after:left-[0px] after:rounded-full dark:shadow-[5px_6px_80px_0px_rgba(160,_189,_225,_0.30)]" :
            "border-white bg-neutral1 h-[65px] w-[65px] hover:w-[75px] hover:h-[75px] hover:border-primaryPurple hover:bg-neutral2 transition-all cursor-pointer"}`
    const iconStyle = "fill-darkBlue dark:fill-grey100 transition-all"

    return (
        <div className={`flex items-center gap-6 justify-center pb-2 ${wrapperClassName}`}>
            <button className={`${marketplaceStyle(marketplace === "ebay")} ${hideEbay ? "hidden" : ""}`} onClick={() => onChange("ebay")}>
                <EbayTxt className={`${iconStyle} ${marketplace === "ebay" ? "w-[49.41px] h-5" : "w-[35px] h-[14.17px] group-hover:w-[49.41px] group-hover:h-5"}`} />
            </button>
            <button className={`${marketplaceStyle(marketplace === "shopify")} ${hideShopify ? "hidden" : ""}`} onClick={() => onChange("shopify")}>
                <ShopifyTxt className={`${iconStyle} ${marketplace === "shopify" ? "w-[55px] h-[15px]" : "w-[45px] h-[13px] group-hover:w-[55px] group-hover:h-[15px]"}`} />
            </button>
        </div>
    )
}

export default SelectMarketplace