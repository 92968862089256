import SadEmoji from "assets/images/sad-emoji.png";
import Button from "components/Button";
import FilterCheckbox from "components/Filters/FilterCheckbox";
import { FC } from "react";
import { toast } from "react-toastify";

export const cancelationQuestionnaireOptions = ([
    "I can’t afford it",
    "There isn’t a feature that I need",
    "There are too many bugs / errors",
    "I don’t understand how to use ZIK",
    "I prefer using other software",
    "7 days was not long enough to try ZIK",
    "I have stopped selling products online",
    "Other",
]);

interface Props {
    onNextClick: (step: number) => void;
    selectedOption: string;
    onSelect: (newVal: string) => void;
    hasDiscount: boolean;
}

const CancellationQuestionnaire: FC<Props> = ({ onNextClick, selectedOption, onSelect, hasDiscount }) => (
    <div className="flex flex-col items-center">
        <img src={SadEmoji} alt="" className="text-center mb-4 w-10 h-10" />
        <h3 className="text-center text-xl font-semibold mb-2.5">We’re sorry that you’re thinking of cancelling...</h3>
        <p className="text-center mb-[19px] text-lg font-medium">May we ask why?</p>
        <div className="">
            <FilterCheckbox
                options={cancelationQuestionnaireOptions.map((item) => ({
                    text: item,
                    value: item
                }))}
                value={selectedOption}
                optionLabelClassName={"min-h-[41px] min-w-[330px] max-[360px]:min-w-[200px] max-sm:!text-sm"}
                className="w-full grid grid-cols-2 sm:px-8 gap-y-3.5 gap-x-6 max-md:grid-cols-1 mb-[32px]"
                handleChange={(newVal) => onSelect(newVal.toString())}
            />
        </div>
        <Button
            title="Next"
            handleClick={() => {
                if (selectedOption) {
                    onNextClick(hasDiscount ? 1 : selectedOption === "Other" ? 4 : 2)
                } else {
                    toast.error("Please select any option")
                }
            }} className="max-w-[200px] dark:bg-deepSpace900" height="h-16" />
    </div >
)

export default CancellationQuestionnaire