import Switch from "components/Input/Switch";
import { YEARLY_EBAY_PLAN_SAVE_AMOUNT } from "config/constants";
import { FC } from "react";
import { formatCurrency } from "utils/formatCurrency";


interface Props {
    selectedPeriod: string;
    handleClick: (tab: string) => void;
    wrapperStyles?: string;
}
const EbayPlanPeriodSelect: FC<Props> = ({ selectedPeriod, handleClick, wrapperStyles }) => {


    const cmnTabStyles = (period: "yearly" | "monthly") => `text-lg max-sm:text-base transition-all text-center ${period === "monthly" ? "w-[77px]" : "w-[55px]"}
    ${selectedPeriod === period ? "font-bold text-primaryPurple dark:text-purple500" : "font-medium text-grey300 dark:text-grey100"}`
    return (
        <div className={`flex w-full items-center justify-end gap-2 mx-auto  max-lg:px-[60px] max-sm:justify-center max-sm:!px-[30px] max-sm:flex-wrap ${wrapperStyles}`}>
            <div className="flex items-center gap-2">
                <button className={cmnTabStyles("monthly")} onClick={() => handleClick("monthly")}>Monthly</button>
                <Switch isChecked={selectedPeriod === "yearly"} handleChange={() => handleClick(selectedPeriod === "monthly" ? "yearly" : "monthly")} noCheckmarkIcon
                    wrapperClassName="transition-all !bg-primaryPurple dark:!bg-purple500" />
                <button className={cmnTabStyles("yearly")} onClick={() => handleClick("yearly")}>Yearly </button>
            </div>
            <div className="min-[390px]:w-fit w-full flex justify-end">
                <div className="py-1 px-2 text-primaryPurple dark:text-purple500 block w-fit text-sm font-medium bg-grey100 rounded-[4px] max-sm:text-xs max-sm:px-1 max-sm">Save up to {formatCurrency(YEARLY_EBAY_PLAN_SAVE_AMOUNT)}</div>
            </div>
        </div>

    )
}

export default EbayPlanPeriodSelect;