import { Column, HeaderGroup } from "@tanstack/react-table";
import { SortDir } from "hooks/useSort";
import { TableHeadStyles } from "./Table";
import TableHeadCell from "./TableHeadCell";

interface Sort {
  direction: SortDir;
  column: string;
}

interface Props<T extends object> {
  headerGroup: HeaderGroup<T>;
  styles?: TableHeadStyles;
  sort?: Sort;
  updateColWidth?: boolean;
  getColumn: (columnId: string) => Column<T, unknown> | undefined;
}

const TableHeadRow = <T extends object>({
  headerGroup,
  updateColWidth,
  styles,
  sort,
  getColumn,
}: Props<T>) => (
  <tr className={`w-full sticky top-0 bg-white dark:bg-transparent z-10 mb-4 ${styles?.row || ""}`}>
    {headerGroup.headers.map((header, i, colRow) => (
      <TableHeadCell
        header={header}
        updateColWidth={updateColWidth}
        sort={sort}
        getColumn={getColumn}
        key={header.id}
        isFirstCol={i === 0}
        isLastCol={i + 1 === colRow.length}
        styles={styles}
        previousCellExtracted={
          headerGroup.headers[i - 1]?.column?.columnDef?.meta?.extract
        }
      />
    ))}
  </tr>
);

export default TableHeadRow;
