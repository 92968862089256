const getProperDomain = (
  imageLink: string | null,
  domain: string,
  asin: string
) => {
  if (imageLink == null) {
    return "";
  }
  if (imageLink.indexOf("wal") >= 0) {
    return "https://www.walmart.com/ip/" + asin;
  }
  if (imageLink.indexOf("amazon") >= 0) {
    return "https://www.amazon" + domain + "/dp/" + asin;
  }
  if (imageLink.indexOf("homedepot") >= 0) {
    return "https://www.homedepot.com/p/" + asin;
  }
  if (imageLink.indexOf("alicdn") >= 0) {
    return "https://www.aliexpress.com/item/" + asin + ".html";
  }
};

export default getProperDomain;
