import ProTools from "pages/ProTools/Index";

interface Props {
  isLocked: boolean;
  children: React.ReactNode;
  currentPlan: string;
}

const ProToolsWrapper = ({ children, isLocked, currentPlan }: Props) =>
  isLocked ? <ProTools /> : <>{children}</>;

export default ProToolsWrapper;
