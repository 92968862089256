import { noOfEntries } from "assets/data/filters";
import { ReactComponent as Menu } from "assets/icons/menu.svg";
import { ReactComponent as Trash } from "assets/icons/trash.svg";
import { myProductItems } from "assets/tableColumns/myProductItems";
import Button from "components/Button";
import Card from "components/Card";
import HoveredIcon from "components/HoveredIcon";
import ConfirmDelete from "components/Modal/ConfirmDelete";
import SelectFolder from "components/Modal/SelectFolder";
import Select from "components/Select";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableStyles } from "components/Table/Table";
import useModal from "hooks/useModal";
import { SortDir } from "hooks/useSort";
import useTable from "hooks/useTable";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useDeleteFolderItemsMutation,
  useMoveFolderItemsMutation
} from "store/api/foldersApi";
import { Option, UsePaginationResponse } from "types/common";
import { FolderItemsList } from "types/services/folders";
import { FolderItemsFilterState } from "../Product";

interface Props {
  data: FolderItemsList[];
  isLoading: boolean;
  sort: {
    direction: "asc" | "desc";
    column: string
  }
  filters: FolderItemsFilterState;
  pagination: UsePaginationResponse;
  onChangeSelectedItems: (newList: number[]) => void;
  allChecked: boolean;
  setAllChecked: Dispatch<SetStateAction<boolean>>;
  selectedIds: number[];
  handleSort: (col: string) => void

}
const tableStyles: TableStyles = {
  style: "w-full overflow-x-auto",
  px: "px-0",
  body: {
    contHeight: "h-[54px]",
    rowHeight: "h-[105px]",
  },
  head: {
    contHeight: "h-[37px]",
    rowHeight: "h-[61px]",
  },
};
const FolderList: FC<Props> = ({
  data,
  isLoading,
  pagination,
  filters,
  handleSort,
  sort,
  allChecked,
  setAllChecked,
  onChangeSelectedItems,
  selectedIds
}) => {
  const [rowSelection, setRowSelection] = useState({});
  const [selectedFolder, setSelectedFolder] = useState<Option | null>(null);
  const [searchParams] = useSearchParams();
  const [competitors, setCompetitors] = useState<any>({});
  const [list, setList] = useState<FolderItemsList[]>([])
  const [competitorsAsc, setCompetitorsAsc] = useState<boolean | null>(null)
  const isAliexpress = searchParams.get("tab") === "aliexpress";
  const deleteModal = useModal();
  const { id } = useParams();
  const onFolderSelect = (newVal: Option | null) => setSelectedFolder(newVal);
  const [deleteFolderItems, { isLoading: deleteLoading }] =
    useDeleteFolderItemsMutation();
  const [moveFolder, { isLoading: moveLoading }] = useMoveFolderItemsMutation();

  const handleCustomSort = (col: string) => {
    if (col === "competitorCount") {
      setCompetitorsAsc(prev => prev === null || prev === true ? false : true);
    } else {
      handleSort(col);
      if (competitorsAsc !== null) {
        setCompetitors(null)
      }
    }
  }

  const payload = {
    ...(filters.ebayPrice.min !== 0 && {
      ebayMinPrice: filters.ebayPrice.min,
    }),
    ...(filters.ebayPrice.max !== 0 && {
      ebayMaxPrice: filters.ebayPrice.max,
    }),
    ...(filters.aliexpressPrice.min !== 0 && {
      multiMinPrice: filters.aliexpressPrice.min,
    }),
    ...(filters.aliexpressPrice.max !== 0 && {
      multiMaxPrice: filters.aliexpressPrice.max,
    }),
    ...(filters.profit.min !== 0 && {
      minProfit: filters.profit.min,
    }),
    ...(filters.profit.max !== 0 && {
      maxProfit: filters.profit.max,
    }),
    ...(filters.sales.min !== 0 && {
      minSales: filters.sales.min,
    }),
    ...(filters.sales.max !== 0 && {
      maxSales: filters.sales.max,
    }),
    ...(filters.search !== "" && {
      searchText: filters.search,
    }),
  };


  const onMoveSuccess = () => {
    toast.success("Selected Items moved successfully");
  };

  const onMoveFolder = () => {
    moveFolder({
      allChecked,
      singleScannedItemIds: allChecked ? [] : selectedIds,
      destinationFolder: Number(selectedFolder?.value) || 0,
      sourceFolder: Number(id),
      ...payload,
    })
      .unwrap()
      .then(onMoveSuccess);
    setRowSelection({});
    setAllChecked(false);
  };

  const onDeleteSuccess = () => {
    toast.success("Selected Items deleted Successfully");
    deleteModal.close();
  };

  const deleteSelectedItems = async () => {
    await deleteFolderItems({
      allChecked,
      folderId: Number(id) || 0,
      singleScannedItemIds: allChecked ? [] : selectedIds,
      isRestrictVeroWords: false,
      isRestrictRiskyWords: false,
      ebayMinPrice: filters.ebayPrice.min,
      ebayMaxPrice: filters.ebayPrice.max,
      multiMinPrice: filters.aliexpressPrice.min,
      multiMaxPrice: filters.aliexpressPrice.max,
      minProfit: filters.profit.min,
      maxProfit: filters.profit.max,
      minSales: filters.sales.max,
      maxSales: filters.sales.max,
      searchText: filters.search,
    })
      .unwrap()
      .then(onDeleteSuccess);
    setRowSelection({});
    setAllChecked(false);
  };



  const handleCountChange = (count: number, id: string) => {
    // @ts-ignore
    setCompetitors(prev => ({ [id]: count, ...prev }))
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getRowId = (row: any, relativeIndex: any, parent: any) =>
    // In row object you have access to data.
    // Yon choose parameter. In this example I used uniqueId
    parent ? [parent.id, row.singleScannedItemId].join(".") : row.singleScannedItemId



  const { table } = useTable({
    columns: myProductItems(isAliexpress, handleCountChange, handleCustomSort),
    dataDeps: [list],
    columnDeps: [sort, pagination.current, competitorsAsc],
    enableRowSelection: true,
    rowSelection,
    onRowSelectionChange: setRowSelection,
    data: list,
    sort: true,
    search: true,
    getRowId
  });

  useEffect(() => {
    if (competitorsAsc === true || competitorsAsc === false) {
      const newList = list.map((item) => (({ ...item, competitorCount: competitors[item.ebayItemId] || 0 })))
        .sort((a, b) => competitorsAsc ? b.competitorCount - a.competitorCount : a.competitorCount - b.competitorCount)
      setList(newList);
    }
  }, [competitorsAsc])

  useEffect(() => {
    setList(data)
  }, [data])

  useEffect(() => {
    const selectedNumbers = allChecked ? list?.map(x => x.singleScannedItemId) : Object.keys(rowSelection).map(Number);
    onChangeSelectedItems(selectedNumbers)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection]);

  useEffect(() => {
    table.toggleAllRowsSelected(allChecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allChecked]);


  useEffect(() => {
    if (allChecked && !isLoading) {
      const rows: { [key: number]: boolean } = {};
      selectedIds.forEach(id => {
        rows[id] = true;
      });
      setRowSelection(rows)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIds, isLoading]);



  return (
    <Card className="py-5 px-6 pt-[13px] ">
      <div className="flex justify-between items-end max-xl:flex-col gap-4 max-xl:items-center mb-5">
        <div className="flex items-end w-full gap-12 max-xl:justify-center max-md:flex-wrap">
          <HoveredIcon
            Icon={Trash}
            variant="outlined"
            className="ml-4"
            width="w-[30px]"
            height="h-[30px]"
            iconClassName="w-[11.67px] h-[13.33px]"
            handleClick={() =>
              Object.keys(rowSelection).length > 0
                ? deleteModal.open()
                : toast.error("No item has been selected for deleting!")
            }
          />
          <Select
            options={noOfEntries}
            value={pagination.pageSize.toString()}
            Icon={Menu}
            btnClassName="min-w-[148px]"
            handleChange={(newVal) => pagination.changePageSize(Number(newVal))}
          />
          <SelectFolder
            type={isAliexpress ? "aliexpress" : "eBay"}
            className="min-w-[148px]"
            cutLongOption={30}
            cutTitle={30}
            selectedFolder={selectedFolder}
            isOpen={true}
            onFolderSelect={onFolderSelect}
            listWrapperClassName="w-max"
          />
          <Button
            title="Move Between Folders"
            height="h-9"
            className="max-w-[199px] w-full dark:bg-deepSpace900"
            loading={moveLoading}
            handleClick={() =>
              Object.keys(rowSelection).length > 0
                ? onMoveFolder()
                : toast.error("No item has been selected for moving!")
            }
          />
        </div>
        <Pagination pagination={pagination} />
      </div>
      <Table table={table} styles={tableStyles} isLoading={isLoading} sort={competitorsAsc !== null ? { direction: competitorsAsc ? "des" as SortDir : "asc" as SortDir, column: "competitorCount" } : { ...sort, direction: sort.direction === "asc" ? "asc" as SortDir : "des" as SortDir }} />
      <ConfirmDelete
        helpers={deleteModal}
        title="Are you sure you want to delete selected items"
        onConfirm={deleteSelectedItems}
        confirmLoading={deleteLoading}
      />
    </Card>
  );
};

export default FolderList;
