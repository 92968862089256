import { FC } from "react";
import { TooltipProps } from "recharts";
import {
  NameType
} from "recharts/types/component/DefaultTooltipContent";
import { CURRENCY } from "types/currency";
import {
  CURRENCY_SIGNS,
  formatCurrency,
  formatNumber,
  formatPercent
} from "utils/formatCurrency";

type ValueType = "percentage" | "number" | "currency"

interface Props extends TooltipProps<ValueType, NameType> {
  valueLabel: string;
  valueType?: ValueType;
  secondValueType?: ValueType;
  secondLabel?: string;
  currency?: string,
}
const lbStyles = "label font-medium z-10"
const CustomRechartsTooltip: FC<Props> = (data) => {
  const { active, payload, label, valueLabel, valueType, currency, secondLabel, secondValueType } = data;
  const value = payload && payload[0]?.value ? payload[0].value.toString() : "";
  const showValue = (val: string, type?: ValueType) => type === "percentage"
    ? formatPercent(val)
    : type === "currency"
      ? formatCurrency(val, CURRENCY.none,
        (currency || "$") as CURRENCY_SIGNS)
      : formatNumber(val)

  if (active && payload && payload.length) {
    return (
      <div>
        <div
          className={`custom-tooltip relative text-darkBlue dark:text-grey100 bg-white dark:bg-black900 px-3 py-3 border-2 border-darkBlue rounded-lg outline-none text-xs dark:border-paradiseBlue`}
        >
          <p className={lbStyles}>
            {valueLabel} -{" "}
            {showValue(value, valueType)}
          </p>
          {secondLabel ? (<p className={lbStyles}>{secondLabel} - {showValue(payload[1]?.value || "", secondValueType)}</p>) : ""}
          <p className="z-10">{label}</p>
        </div>
      </div>
    );
  }

  return null;
};

export default CustomRechartsTooltip;
