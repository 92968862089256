import { countryList } from "assets/data/countryList";
import IntercomApp from "components/Intercom/Intercom";
import TrackingComponent from "components/TrackingCode/TrackingCode";
import { COOKIE_DOMAIN, GTM_TRACKING_ID } from "config/constants";
import { TooltipProvider } from "context/TooltipContext";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { DecodedUser } from "hooks/useDecodeToken";
import jwt from "jwt-decode";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import TagManager from 'react-gtm-module';
import Router from "routes";
import { handleDarkModeChange } from "store/slices/themeSlice";
import { setUserAccessToken } from "store/slices/userSlice";
import { initDataLayer } from "utils/googleTagManager";
import isPaymentPath from "utils/isPaymentPath";
import { GetAccessToken, SetAccessToken, SetUserDetails } from "utils/localStorage";

const tagManagerArgs = {
  gtmId: GTM_TRACKING_ID
}

TagManager.initialize(tagManagerArgs)

const App = () => {
  const [userDetails, setUserDetails] = useState<DecodedUser | null>(null);
  const [isReg, setIsReg] = useState(false);
  const { accessToken } = useAppSelector((state) => state.user.result);
  const dispatch = useAppDispatch()
  const [cookie, , removeCookie] = useCookies(['darkMode', 'CAT']);
  const isLoggedIn = accessToken || GetAccessToken();
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const isAffiliate = environment === "affiliate" ? true : false;
  const journeyIncomplete = userDetails?.journey_completed.toLowerCase() === "false"
  const isDev = environment === "dev"
  useEffect(() => {
    const token = cookie.CAT
    if (token !== null && token !== undefined) {
      const user: DecodedUser = jwt(token);
      const splittedName = user.unique_name?.split(" ");
      dispatch(setUserAccessToken(token));
      setUserDetails(user);
      SetUserDetails({
        userId: user.nameid || 0,
        firstName: splittedName?.[0] || "",
        lastName: splittedName?.[1] || "",
        email: user.email,
        countryId: countryList.find((country) =>
          country.text.toLowerCase() === user.country.toLowerCase()) || null,
        roleName: user.role || "",
      });
      SetAccessToken(token)
      removeCookie("CAT", COOKIE_DOMAIN);
    }
  }, []);

  useEffect(() => {
    if (userDetails) {
      initDataLayer(userDetails);
    }

    setIsReg(journeyIncomplete && !isPaymentPath() && !isAffiliate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);



  useEffect(() => {
    if (isLoggedIn) {
      setUserDetails(jwt(isLoggedIn))
    } else {
      dispatch(handleDarkModeChange(!isLoggedIn || isReg ? false : cookie.darkMode === "true"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, isReg])

  return (
    <TooltipProvider>
      {isDev ? "" : (
        <TrackingComponent />
      )}
      <IntercomApp />
      <Router />
    </TooltipProvider>
  )
};
export default App;


