
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { ReactComponent as LeftArrow } from "assets/icons/arrow-left.svg";
import dayjs from "dayjs";
import { FC } from "react";
import "./date-picker-open.css";
interface SelDates {
    start: Date | null;
    end: Date | null
}
interface Props {
    selDates: SelDates;
    onDateChange: (date: [Date, Date]) => void;
    dateTrackingStarted?: string | null;
}


const arrowStyles = "group-hover:fill-white fill-darkBlue dark:fill-lilac400 dark:group-hover:fill-lilac400 w-[12px] h-[12px]"
const arrowContStyles = "group w-6 h-6 flex items-center rounded-full justify-center bg-neutral2 hover:bg-primaryPurple dark:bg-deepSpace900 dark:hover:!bg-purple500"

const DatePickerOpen: FC<Props> = ({ selDates, onDateChange, dateTrackingStarted }) => {
    const dateAlreadyClicked = (date: Date) => (dayjs(date).isAfter(selDates.start) || dayjs(date).isSame(selDates.start, "day")) && dayjs(date).isBefore(selDates.end);
    const tileClassName = ({ date }: { date: Date }) => {
        if (dateTrackingStarted && dayjs(date).isSame(dateTrackingStarted, "day")) {
            return `sales-tracker-animation`
        }
        if (dateAlreadyClicked(date)) return `dark:!bg-grey100 dark:text-deepSpace900 dark:hover:!bg-purple500 !bg-lilac400 !rounded-[0px] hover:!bg-primaryPurple`

    }

    const minDate = dateTrackingStarted ? dayjs(dateTrackingStarted).toDate() : dayjs().set("year", 2000).toDate()

    return (
        <div className="shopify-date-picker w-full block mb-1.5 flex gap-10">
            <DateRangePicker
                isOpen={true}
                onChange={(val) => onDateChange(val as [Date, Date])}
                value={[selDates.start, selDates.end]}
                onDragEnter={() => { }}
                clearIcon={false}
                goToRangeStartOnSelect
                calendarIcon={false}
                nativeInputAriaLabel="date-input"
                className="w-full"
                calendarClassName="w-full"
                view="month"
                minDate={minDate}
                prev2Label={null}
                format="dd/MM/yyyy"
                dayPlaceholder="22"
                monthPlaceholder="06"
                maxDate={new Date()}
                showLeadingZeros={true}
                yearPlaceholder="2024"
                required
                closeCalendar={false}
                next2Label={null}
                tileClassName={tileClassName}
                minDetail="month"
                maxDetail="month"
                navigationLabel={({ date }) => (
                    <div className="flex justify-between w-full pl-1 items-center">
                        <p className="text-darkBlue dark:text-grey100 text-medium text-sm font-bold leading-[20px]">
                            {date.toLocaleString("en-US", { month: "long", year: "numeric" })}
                        </p>
                    </div>
                )}
                prevLabel={<div className={arrowContStyles}>
                    <LeftArrow
                        className={arrowStyles}
                    />
                </div>}
                nextLabel={<div className={arrowContStyles}>
                    <LeftArrow
                        className={`rotate-180 ${arrowStyles}`}
                    />
                </div>} />
        </div>


    )
}

export default DatePickerOpen