import { createApi } from "@reduxjs/toolkit/query/react";
import {
  AddFolderRequest,
  AddFolderResponse,
  DeleteFolderItemsReq,
  EditFolderItemReq,
  ExportFolderReq,
  ExportFolderRes,
  FolderCountRes,
  GetFolderItemsReq,
  GetFolderItemsRes,
  GetFoldersPreviewRequest,
  GetFoldersPreviewRes,
  GetFoldersRequest,
  GetFoldersResponse,
  MoveFolderItemsReq,
} from "types/services/folders";
import { makeQueryParams } from "../../utils/makeQueryParams";
import baseQuery from "./baseQuery";
import { queryKeys } from "./queryKeys";
import { queryTags } from "./queryTags";

const { folders } = queryTags.folders;
export const foldersApi = createApi({
  reducerPath: "folders",
  baseQuery,
  tagTypes: [folders],
  endpoints: (builder) => ({
    getFolders: builder.query<GetFoldersResponse, GetFoldersRequest>({
      query: (body) => ({
        url: makeQueryParams(queryKeys.folders.get, body),
        method: "GET",
      }),
      providesTags: [folders],
    }),
    getFoldersPreview: builder.query<
      GetFoldersPreviewRes,
      GetFoldersPreviewRequest
    >({
      query: (body) => ({
        url: makeQueryParams(queryKeys.folders.getPreview, body),
        method: "GET",
      }),
      providesTags: [folders],
    }),
    deleteFolder: builder.mutation<null, string>({
      query: (folderId) => ({
        url: makeQueryParams(queryKeys.folders.deleteFolder, { folderId }),
        method: "DELETE",
      }),
      invalidatesTags: [folders],
    }),
    exportFolder: builder.mutation<ExportFolderRes, ExportFolderReq>({
      query: (body) => ({
        url: queryKeys.folders.exportFolder,
        method: "POST",
        body,
      }),
    }),
    getFolderItems: builder.query<GetFolderItemsRes, GetFolderItemsReq>({
      query: (body) => ({
        url: queryKeys.folders.getFolderItems,
        method: "POST",
        body: body,
      }),
      providesTags: [folders],
    }),
    addFolder: builder.mutation<AddFolderResponse, AddFolderRequest>({
      query: (body) => ({
        url: queryKeys.folders.add,
        method: "POST",
        body: body,
      }),
      invalidatesTags: [folders],
    }),
    editFolderItem: builder.mutation<null, EditFolderItemReq>({
      query: (body) => ({
        url: queryKeys.folders.editFolderItem,
        method: "PATCH",
        body: body,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        try {
          // `updateQueryData` requires the endpoint name and cache key arguments,
          // so it knows which piece of cache state to update

          await queryFulfilled;

          const postsCache = foldersApi.util.selectInvalidatedBy(getState(), [
            { type: "Folders" },
          ]);

          postsCache
            .filter(({ endpointName }) => endpointName === "getFolderItems")
            .forEach(({ originalArgs }) => {
              dispatch(
                foldersApi.util.updateQueryData(
                  "getFolderItems",
                  originalArgs,
                  (draft) => {
                    // The `draft` is Immer-wrapped and can be "mutated" like in createSlice
                    const post = draft.result.items.find(
                      (post) => post.singleScannedItemId === args.id
                    );
                    if (post) {
                      post.ebayTitle = args.ebayTitle;
                      post.ebayPrice = args.ebayPrice;
                      post.calculatedProfit = args.profit;
                      post.asin = args.asin;
                    }
                  }
                )
              );
            });
        } catch {}
      },
      //invalidatesTags: [folders],
    }),
    duplicateItem: builder.mutation<null, string>({
      query: (itemId) => ({
        url: makeQueryParams(queryKeys.folders.duplicateItem, { itemId }),
        method: "POST",
      }),
      invalidatesTags: [folders],
    }),
    deleteItem: builder.mutation<null, string>({
      query: (itemId) => ({
        url: makeQueryParams(queryKeys.folders.deleteItem, { itemId }),
        method: "DELETE",
      }),
      invalidatesTags: [folders],
    }),
    deleteFolderItems: builder.mutation<null, DeleteFolderItemsReq>({
      query: (body) => ({
        url: queryKeys.folders.deleteFolderItems,
        method: "DELETE",
        body,
      }),
      invalidatesTags: [folders],
    }),
    uploadFolderItems: builder.mutation<null, DeleteFolderItemsReq>({
      query: (body) => ({
        url: queryKeys.folders.uploadFolderItems,
        method: "POST",
        body,
      }),
      invalidatesTags: [folders],
    }),
    exportFolderItems: builder.mutation<null, DeleteFolderItemsReq>({
      query: (body) => ({
        url: queryKeys.folders.exportFolderItems,
        method: "POST",
        body,
      }),
    }),
    moveFolderItems: builder.mutation<null, MoveFolderItemsReq>({
      query: (body) => ({
        url: queryKeys.folders.moveFolderItems,
        method: "POST",
        body,
      }),
      invalidatesTags: [folders],
    }),
    getFoldersCount: builder.query<FolderCountRes, number>({
      query: (listId) => ({
        url: queryKeys.folders.folderCount(listId),
        method: "GET",
      }),
    }),
  }),
});

export const {
  useAddFolderMutation,
  useGetFolderItemsQuery,
  useDeleteFolderMutation,
  useExportFolderMutation,
  useDuplicateItemMutation,
  useDeleteFolderItemsMutation,
  useDeleteItemMutation,
  useEditFolderItemMutation,
  useGetFoldersQuery,
  useMoveFolderItemsMutation,
  useExportFolderItemsMutation,
  useUploadFolderItemsMutation,
  useGetFoldersPreviewQuery,
  useGetFoldersCountQuery,
} = foldersApi;
