import { ReactComponent as ArrowLeft } from "assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { handleNavbarChange } from "store/slices/themeSlice";




const ExpandToggle = () => {
    const { isExpand } = useAppSelector((state) => state.themeSlice);
    const dispatch = useAppDispatch();
    const handleExpand = () => {
        dispatch(handleNavbarChange(!isExpand));
    }
    return (
        <div onClick={handleExpand} className={`absolute bottom-[5px] flex items-center justify-center right-[5px] w-[20px] h-[20px] ${isExpand ? "bg-primaryPurple dark:bg-purple500" : "bg-neutral2 dark:bg-deepSpace800"}  group hover:bg-primaryPurple dark:hover:bg-purple500 rounded-full cursor-pointer`}>
            {isExpand ?
                <ArrowLeft className="fill-white w-[9px] h-[9px]" />
                :
                <ArrowRight className="fill-darkBlue dark:fill-lilac400 group-hover:fill-white w-[9px] h-[9px]" />}
        </div>
    )
}

export default ExpandToggle