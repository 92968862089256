import { ReactComponent as LeftArrow } from "assets/icons/arrow-left-extended.svg";
import { ReactComponent as RegistrationFunnelLogo } from "assets/icons/registration-funnel-logo.svg";
import Button from "components/Button";
import { FC } from "react";

interface Props {
    handleBack: () => void;
    isRegistration?: boolean
    introStep?: number;
    step?: number
}
const RegistrationFunnelNavbar: FC<Props> = ({ handleBack, isRegistration, introStep, step }) => (
    <div className={`flex justify-between items-center lg:hidden min-h-[84px] max-sm:h-16 max-sm:min-h-[64px] ${isRegistration ? "" : `px-10 ${step === 3 ? "max-sm:px-[30px]" : "max-sm:px-2.5"}`}`}>
        <RegistrationFunnelLogo />
        <Button
            title=""
            height="h-[36px]"
            titleClassName="hidden"
            variant="contained"
            iconClassName="w-[24px] h-[24px] !fill-darkBlue"
            className={`z-40 w-[36px] bg-white border-none hover:bg-white max-w-[36px] lg:hidden  ${introStep === 0 ? "invisible" : ""} ${isRegistration ? "hidden" : ""}`}
            handleClick={handleBack}
            StartIcon={LeftArrow}
        />
    </div>
)

export default RegistrationFunnelNavbar