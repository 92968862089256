import { FC } from "react";

interface Props {
  title?: string;
  handleClick: () => void;
}

const CopyTitleColumn: FC<Props> = ({ title, handleClick }) => (
  <span
    className="hover:text-primaryPurple dark:hover:text-purple500 cursor-pointer relative select-none group"
    onClick={handleClick}
  >
    {title}
    <div className={`w-[107px] h-[30px] justify-center items-center text-xs bg-darkBlue text-white dark:bg-lilac400 dark:text-deepSpace900 rounded-[5px] absolute top-[25px] left-[20%] hidden group-hover:flex`}>
      <span>
        Click to paste
      </span>
    </div>
  </span>
);

export default CopyTitleColumn;
