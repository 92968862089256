import { helpVideos } from "assets/data/helpVideos";
import Header from "components/Header";
import HeaderHelpVideo from "components/Header/HelpVideo";
import HeaderIcon, { HeaderVariants } from "components/Header/Icon";
import Loading from "components/Loading";
import Search from "components/Search";
import Select from "components/Select";
import Toaster from "components/Toast";
import { ErrorCodes, ErrorMsgs } from "config/errorMsgs";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import LoadingPage from "pages/Loading";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PATH } from "routes/path";
import { useGetCatQuery, useGetCatResMutation } from "store/api/catResApi";
import { catResFilterChange } from "store/slices/catResSlice";
import { PostCatResRequest } from "types/services/catRes";
import CategoryCard from "./components/CatResCard";
import CategoryResearchFilters from "./components/CatResFilters";

const CategoryResearch = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { filters } = useAppSelector((state) => state.catResSlice);
  const { data: categories, isLoading: categoriesLoading } = useGetCatQuery();
  const [getCatRes, { isLoading, isSuccess, error, status }] =
    useGetCatResMutation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [hideFilters, setHideFilters] = useState(false);
  const onHideFilters = () => setHideFilters((prev) => !prev);
  const dispatch = useAppDispatch();
  const [message, setMessage] = useState("");
  const filterChange = (
    newVal: string | number | Date | null,
    field: keyof PostCatResRequest
  ) => {
    dispatch(catResFilterChange({ newVal, field }));
  };

  useEffect(() => {
    if (filters.subCategoryText.length > 0) {
      filterChange("", "subCategoryText");
      filterChange("", "keywords");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isFetchError = searchParams.get("error") === ErrorCodes.fetchError;
    if (
      // @ts-ignore
      error?.status === ErrorCodes.fetchError ||
      isFetchError
    ) {
      setMessage(ErrorCodes.fetchError);
      if (isFetchError) {
        setSearchParams("");
      }
    }
    else if (
      // @ts-ignore
      error?.data === ErrorCodes.empty
    ) {
      toast.warning(ErrorMsgs[ErrorCodes.empty]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, searchParams]);

  useEffect(() => {
    if (message === ErrorCodes.fetchError) {
      toast.error(
        "There is a problem with the resource you are looking for, and it cannot be displayed."
      );
    }
  }, [message, status]);

  useEffect(() => {
    if (isSuccess && pathname.includes(PATH.categoryResearch.root)) {
      navigate(
        PATH.categoryResearch.results +
        `?page=1${filters.keywords?.length > 0 ? `&keywords=${filters.keywords}` : ""
        }&type=${filters.type}&location=${filters.location}&condition=${filters.condition
        }${Number(filters.minPrice) > 0 ? `&minPrice=${filters.minPrice}` : ""
        }${Number(filters.maxPrice) > 0 ? `&maxPrice=${filters.maxPrice}` : ""
        }${Number(filters.minFeedback) > 0
          ? `&minFeed=${filters.minFeedback}`
          : ""
        }${Number(filters.maxFeedback) > 0
          ? `&maxFeed=${filters.maxFeedback}`
          : ""
        }${filters.exclude?.length > 0 ? `&exclude=${filters.exclude}` : ""}${filters.categoryId?.length > 0 ? `&catId=${filters.categoryId}` : ""
        }${filters.categoryText?.length > 0
          ? `&catTxt=${filters.categoryId === "" ? "" : filters.categoryText
          }`
          : ""
        }${filters.subCategoryText?.length > 0
          ? `&subCatTxt=${filters.subCategoryText}`
          : ""
        }`
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const handleCatChange = (newVal: string, txt: string) => {
    filterChange(newVal, "categoryId");
    filterChange(newVal === "" ? "" : txt || "", "categoryText");
  };
  const onSearchClick = async () => {
    if (filters.keywords || filters.categoryId) {
      await getCatRes({
        ...filters,
        page: "1",
        categoryText: filters.categoryId === "" ? "" : filters.categoryText,
        minPrice: (filters.minPrice !== null ? filters.minPrice.toString() : ""),
        maxPrice: (filters.maxPrice !== null ? filters.maxPrice.toString() : ""),
        minFeedback: (filters.minFeedback !== null ? filters.minFeedback.toString() : ""),
        maxFeedback: (filters.maxFeedback !== null ? filters.maxFeedback.toString() : ""),
      });
    } else {
      toast.warning("Please enter the search term!");
    }
  };

  return (
    <LoadingPage loading={isLoading}>
      <Header
        leftContent={
          <Select
            options={
              categories?.result.map((item) => ({
                text: item.catsName,
                value: item.catId,
              })) || []
            }
            listWrapperClassName="!top-11"
            title="Select Category"
            value={filters.categoryId}
            handleChange={handleCatChange}
            btnClassName={`${" max-h-9"} !w-full min-w-[243px]`}
            cutTitle={25}
            listItemClassName="text-xs font-normal"
            listHeight="max-h-[32rem]"
          />
        }
        middleContent={
          <Search
            search={filters.keywords}
            handleChange={(newValue: string) =>
              filterChange(newValue, "keywords")
            }
            btnMaxWidth="max-w-[7.85rem]"
            className="max-w-[686px] mx-auto !gap-2.5"
            searchButton
            handleSearchClick={onSearchClick}
            htmlFor="search"
          />
        }
        rightContent={
          <>
            <HeaderIcon
              variant={HeaderVariants.filters}
              handleClick={onHideFilters}
            />
            <HeaderHelpVideo videos={helpVideos.category} />
            <HeaderIcon variant={HeaderVariants.settings} handleClick={() =>
              navigate(`${PATH.quickSettings.root}?tab=search-preferences`)
            } />
          </>
        }
        className="!px-5"
        middleContentClassName="w-full"
      />
      {!hideFilters ? (
        <CategoryResearchFilters
          filters={filters}
          handleFilterChange={filterChange}
        />
      ) : (
        ""
      )}
      {categoriesLoading ? (
        <div className="w-full mt-10 flex justfiy-center">
          <Loading
            fill="fill-primaryPurple dark:fill-purple500"
            className="mx-auto"
            width={64}
            height={64}
          />
        </div>
      ) : (
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-flow-row gap-x-8 gap-y-5 max-lg:gap-4">
          {categories &&
            categories.result.map((item, index) => (
              <CategoryCard
                title={item.catsName}
                key={item.catId}
                handleCategoryChange={(newCatId) =>
                  handleCatChange(newCatId, item.catsName)
                }
                handleSearchChange={(newVal) => {
                  filterChange(newVal, "subCategoryText");
                  filterChange(newVal, "keywords");
                }}
                id={String(index)}
                categoryItems={item.level2Cats}
              />
            ))}
        </div>
      )}
      <Toaster />
    </LoadingPage>
  );
};

export default CategoryResearch;
