import { createApi } from "@reduxjs/toolkit/query/react";
import {
  EbayCrDeleteReq,
  EbayCrSaveReq,
  LoadWatchListReq,
  LoadWatchListRes,
  SaveSearchReq,
  WatchListGetByIdReq,
  WatchListUpdateReq,
} from "types/services/watchList";
import baseQuery from "./baseQuery";
import { queryKeys } from "./queryKeys";
import { queryTags } from "./queryTags";

export const watchListApi = createApi({
  reducerPath: "watchList",
  baseQuery,
  tagTypes: [queryTags.watchList.list],
  endpoints: (builder) => ({
    saveSearch: builder.mutation<void, SaveSearchReq>({
      query: (body) => ({
        url: queryKeys.watchList.save,
        method: "POST",
        body: body,
      }),
      invalidatesTags: [queryTags.watchList.list],
    }),
    ebayCrSave: builder.mutation<void, EbayCrSaveReq>({
      query: (body) => ({
        url: `${queryKeys.watchList.ebayCrSave}?comment=${body.comment}&searchedUserId=${body.searchedUserId}`,
        method: "POST",
      }),
      invalidatesTags: [queryTags.watchList.list],
    }),
    ebayCrCommentUpdate: builder.mutation<void, EbayCrSaveReq>({
      query: (body) => ({
        url: `${queryKeys.watchList.ebayCrCommentUpdate}?comment=${body.comment}&searchedUserId=${body.searchedUserId}`,
        method: "PATCH",
      }),
      invalidatesTags: [queryTags.watchList.list],
    }),
    ebayCrDelete: builder.mutation<void, EbayCrDeleteReq>({
      query: (body) => ({
        url: `${queryKeys.watchList.ebayCrDelete}?searchedUserId=${body.searchedUserId}`,
        method: "DELETE",
      }),
      invalidatesTags: [queryTags.watchList.list],
    }),
    loadWatchList: builder.query<LoadWatchListRes, LoadWatchListReq>({
      query: (body) => ({
        url: queryKeys.watchList.load,
        method: "POST",
        body,
      }),
      providesTags: [queryTags.watchList.list],
    }),
    loadWatchListById: builder.query<null, WatchListGetByIdReq>({
      query: (params) => ({
        url: queryKeys.watchList.getById,
        method: "GET",
        params,
      }),
      providesTags: [queryTags.watchList.list],
    }),
    updateWatchList: builder.mutation<null, WatchListUpdateReq>({
      query: (params) => ({
        url: queryKeys.watchList.update,
        method: "PATCH",
        params,
      }),
      invalidatesTags: [queryTags.watchList.list],
    }),
    watchListDelete: builder.mutation<null, WatchListGetByIdReq>({
      query: (params) => ({
        url: queryKeys.watchList.delete,
        method: "DELETE",
        params,
      }),
      invalidatesTags: [queryTags.watchList.list],
    }),
  }),
});

export const {
  useSaveSearchMutation,
  useLoadWatchListQuery,
  useLoadWatchListByIdQuery,
  useUpdateWatchListMutation,
  useWatchListDeleteMutation,
  useEbayCrSaveMutation,
  useEbayCrDeleteMutation,
  useEbayCrCommentUpdateMutation,
} = watchListApi;
