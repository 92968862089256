import { ReactComponent as FolderIcon } from "assets/icons/folder-icon.svg";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import Button from "components/Button";
import Input from "components/Input";
import Search from "components/Search";
import Select from "components/Select";
import SelectOption from "components/Select/Option";
import useDebaunceSearch from "hooks/useDebaunceSearch";
import useFilters from "hooks/useFilters";
import useModal from "hooks/useModal";
import { FC, useEffect } from "react";
import { toast } from "react-toastify";
import { useAddFolderMutation, useGetFoldersPreviewQuery } from "store/api/foldersApi";
import { Option } from "types/common";
import Modal from ".";

interface Props {
  selectedFolder: Option | null;
  onFolderSelect: (newVal: Option | null) => void;
  className?: string;
  btnClassName?: string;
  titleClassName?: string;
  selectFirstOption?: boolean;
  listWrapperClassName?: string;
  isOpen: boolean;
  cutTitle?: number;
  cutLongOption?: number
  hideFolderIcon?: boolean;
  type: "aliexpress" | "eBay";
  hideAddNewFolder?: boolean;
  addNewFolderBtnTitle?: string;
}

const initialValues = {
  name: "",
  description: "",
};
const SelectFolder: FC<Props> = ({
  selectedFolder,
  onFolderSelect,
  hideAddNewFolder,
  listWrapperClassName,
  selectFirstOption,
  btnClassName,
  titleClassName,
  type,
  hideFolderIcon,
  addNewFolderBtnTitle,
  isOpen,
  cutLongOption,
  cutTitle,
  className,
}) => {
  const helpers = useModal();
  const { delayedValue, immediateValue, onChange } = useDebaunceSearch();
  const modal = useModal();
  const [addFolder, { isLoading, isSuccess }] = useAddFolderMutation();
  const { filters: fields, handleFilterChange: handleChange } =
    useFilters(initialValues);
  const marketplaceType = type === "aliexpress" ? 2 : 1;
  const { currentData } = useGetFoldersPreviewQuery({ type: marketplaceType, Search: delayedValue }, {
    skip: !isOpen,
  });

  useEffect(() => {
    if (currentData && selectFirstOption) {
      const index = currentData.result.length > 1 ? 1 : 0
      onFolderSelect({
        text: currentData?.result[index]?.title,
        value: currentData?.result[index]?.listID?.toString(),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Folder added Successfully");
      modal.close();
      handleChange("", "name");
      handleChange("", "description");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const onAddFolder = async (e: React.FormEvent) => {
    e.preventDefault();
    if (fields.name === "") {
      toast.error("Please fill the Folder Name");
    }
    else if (fields.name.length > 30) {
      toast.error("Please provide max 30 characters for folder name")
    }
    else if (fields.description.length > 200) {
      toast.error("Please provide max 200 characters for folder description")
    }
    else {
      await addFolder({
        title: fields.name,
        description: fields.description,
        type: marketplaceType,
      });
    }
  };

  return (
    <div className={`${className || ""} ]`}>
      <Select
        listWrapperClassName={`${listWrapperClassName} !p-[7px]`}
        cutTitle={cutTitle}
        cutLongOption={cutLongOption}
        titleClassName={titleClassName}
        className="w-full"
        helpers={helpers}
        btnClassName={btnClassName}
        handleChange={() => { }}
        value={selectedFolder?.value || ""}
        iconClassName="ml-[5px] mr-[14px] !w-[15px] !h-[11px]"
        Icon={hideFolderIcon ? undefined : FolderIcon}
        CustomOptions={
          <div className="flex flex-col gap-[3px] max-h-[292px] h-full">
            <Search search={immediateValue}
              hideClear
              handleChange={onChange} placeholder="Search" searchIconClassName="!w-3 !h-3 !ml-[0px]"
              searchIconWrapper="min-w-[20px] ml-[2.21px] h-5 flex justify-center items-center" inputClassName="!pl-2 pr-1"
            />
            <div className="overflow-y-auto scrollbar">
              {currentData?.result.map((item) => (
                <SelectOption
                  listItemClassName="!mb-[3px] !pl-2.5 !font-normal"
                  key={item.listID?.toString()} item={{ text: item?.title, value: item?.listID?.toString() }}
                  handleOptionClick={(newVal, text) => {
                    helpers.close();
                    onChange(text);
                    onFolderSelect(
                      newVal
                        ? {
                          text,
                          value: newVal,
                        }
                        : null
                    );
                  }} />
              ))}
            </div>

            {
              !hideAddNewFolder ? (
                <div className="">
                  <Button
                    title={addNewFolderBtnTitle || "New Folder"}
                    handleClick={modal.open}
                    color="smoothGreen"
                    height="h-9"
                    iconClassName="!w-3.5 !h-3.5"
                    Icon={Plus}
                  />
                </div>
              ) : (
                ""
              )
            }
          </div>

        }
        title={selectedFolder ? selectedFolder.text : "Select Folder"}
      />
      <Modal
        helpers={modal}
        cardClassName="py-7"
        maxWidth="max-w-[580px]"
        title="Add New Folder"
        titleClassName="text-xs font-bold mt-1"
      >
        <form onSubmit={onAddFolder}>
          <div className="flex items-center justify-center gap-12 mb-8">
            <Input
              value={fields.name}
              className="min-w-[15rem]"
              handleChange={(newVal) => handleChange(newVal, "name")}
              erase
              placeholder="Name"
            />
            <Input
              value={fields.description}
              className="min-w-[15rem]"
              handleChange={(newVal) => handleChange(newVal, "description")}
              erase
              placeholder="Description"
            />
          </div>
          <div className="flex justify-center">
            <Button
              title="New Folder"
              loading={isLoading}
              className="max-w-[11rem] w-full mx-auto"
              type="submit"
              color="smoothGreen"
              Icon={Plus}
            />
          </div>

        </form>
      </Modal>
    </div>
  );
};

export default SelectFolder;
