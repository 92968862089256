import VideoEmbed from "components/VideoEmbed/Index";
import { useState } from "react";
import { course } from "types/academy";
import CourseSidebar from "./CourseSidebar";

interface Props {
  courseList: course;
}

const Course = ({ courseList }: Props) => {
  const [video, setVideo] = useState(courseList[0].videos[0].source);
  const [title, setTitle] = useState(courseList[0].videos[0].name);
  const [lesson, setLesson] = useState(courseList[0].lesson);

  const handleVideoChange = (url: string, title: string, lesson: string) => {
    setVideo(url);
    setTitle(title);
    setLesson(lesson);
  };

  return (
    <div className="w-full flex gap-[34px] pl-[14px] max-[1000px]:flex-col">
      <div className="w-full">
        <div className="flex dark:bg-paradiseBlue dark:text-black900 justify-center items-center bg-darkBlue rounded-[15px] h-[46px] w-[122px] text-smoothGreen font-bold uppercase">
          {lesson}
        </div>
        <div className="dark:border-grey100 dark:text-grey100 h-[46px] px-6 py-[11px] border-2 border-darkBlue rounded-[15px] mt-[11px] mb-[13px] font-medium flex justify-content items-center">
          {title}
        </div>
        <VideoEmbed className="shadow-lg rounded-[10px]" source={video} />
      </div>
      <CourseSidebar
        courseList={courseList}
        handleVideoChange={handleVideoChange}
        currentVideo={video}
      />
    </div>
  );
};

export default Course;
