import InfoTooltip from "components/Card/InfoTooltip";
import Select from "components/Select";
import { FC } from "react";
import { Option } from "types/common";

interface Props {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  value: string | number;
  handleChange: (newValue: string) => void;
  className?: string;
  bordered?: boolean;
  infoTitle?: string;
  infoTitleClassName?: string;
  iconClassName?: string,
  options: Option[];
}

const FilterSelect: FC<Props> = ({
  Icon,
  title,
  value,
  bordered,
  className,
  iconClassName,
  infoTitle,
  options,
  handleChange,
  infoTitleClassName
}) => (
  <div
    className={`flex flex-col gap-2 relative ${bordered ? "xl:border-r-2 xl:border-r-[#C8E1FF]" : ""
      } ${className}`}
  >
    <div className="flex gap-3 justify-between items-center h-6">
      <Icon className={`fill-darkBlue ${iconClassName || ""}`} />
      <h4 className="font-bold text-sm">{title}</h4>
      {infoTitle ? (
        <InfoTooltip title={infoTitle} className={` ${infoTitleClassName || ""}`} />
      ) :
        <div className="w-1" />
      }
    </div>
    <div className="flex items-center gap-[1.875rem] justify-center">
      <Select
        btnClassName="!h-[35px] w-full min-w-[143px]"
        className="mx-4"
        handleChange={handleChange}
        value={value.toString()}
        options={options}
      />
    </div>

  </div>
);

export default FilterSelect;
