import Card from "components/Card";
import CheckoutOrderSummary from "components/CheckoutOrderSummary/Index";
import Toaster from "components/Toast";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import useDecodeToken from "hooks/useDecodeToken";
import ShopifyBillingHeader from "pages/Shopify/Billing/components/BillingHeader";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PATH } from "routes/path";
import {
    useGetDefaultCouponQuery,
    useLazyCheckUpgradeQuery,
    useLazyUpgradeQuery
} from "store/api/managePaymentApi";
import { setUserAccessToken } from "store/slices/userSlice";
import { SetAccessToken } from "utils/localStorage";

import { ReactComponent as IconLeft } from "assets/icons/arrow-left-extended-bold.svg";
import { ReactComponent as IconRight } from "assets/icons/arrow-right-extended-bold.svg";
import Button from "components/Button";
import CheckoutCouponInput from "components/Input/CheckoutCouponInput";
import Loading from "components/Loading";

import EbayMarketplaceIcon from "components/MarketplaceIcon/Ebay";
import { onSelectPlan } from "store/slices/planSlice";
import { getPlanDetails, getPlanName, getPlanSuffix, isCurrentPlan, isDowngrade } from "utils/planUtils";

const SubscriptionUpgradePage = () => {
    const user = useDecodeToken();
    const [searchParams] = useSearchParams()
    const { selectedPlan } = useAppSelector((state) => state.planSlice);
    const currentPlan = user.package_name || "";
    const isTrial = /^true$/i.test(user.active_trail);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [coupon, setCoupon] = useState("");
    const [couponApplying, setCouponApplying] = useState(true);
    const changeCouponApplying = (applying: boolean) => setCouponApplying(applying);
    const handleApplyCoupon = (newVal: string) => setCoupon(newVal);
    const { data: defaultCoupon, isFetching: couponLoading } =
        useGetDefaultCouponQuery(selectedPlan.type);
    const [checkUpgrade, { data, isFetching: checkUpgradeFetching, isUninitialized: checkUpgradeUninitialized }] =
        useLazyCheckUpgradeQuery();
    const [makeUpgrade, { isFetching: upgradeFetching }] = useLazyUpgradeQuery();
    const [checkoutData, setCheckoutData] = useState(data);
    const checkCurrent = isCurrentPlan(currentPlan || "", selectedPlan.type);

    useEffect(() => {
        if (selectedPlan.type === "" && searchParams.get("type")) {
            const planType = searchParams.get("type") || ""
            const plan = getPlanDetails(planType);
            const skip = searchParams.get("skip") || false
            dispatch(onSelectPlan({
                type: planType,
                planName: plan?.planName || "",
                planPrice: plan?.planPrice || 0,
                skip
            }))
        }
    }, [selectedPlan, searchParams]);

    const handleRefetchUpgrade = () => {
        checkUpgrade({
            package: selectedPlan.type,
            coupon: coupon,
        })
            .unwrap()
            .then((data) => setCheckoutData(data))
            .catch((error) => toast.error(error.data.message));
    }


    useEffect(() => {
        if (!couponLoading) {
            handleRefetchUpgrade()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coupon]);

    const handleVatNumberUpdate = () => {
        if (selectedPlan?.type != null) {
            handleRefetchUpgrade();
        }
    }



    const handleUpgrade = () => {
        makeUpgrade({
            type: selectedPlan.type,
            couponName: coupon,
        })
            .unwrap()
            .then((data) => {
                if (data.result.token != null && data.result.token != "") {
                    SetAccessToken(data.result.token);
                    dispatch(setUserAccessToken(data.result.token));
                }
                if (data.result.trial) {
                    navigate(PATH.purchaseComplete.changed);
                } else if (data.result.downgrade) {
                    navigate(PATH.purchaseComplete.downgrade);
                } else if (data.result.message === "redirect" && data.result.additionalInformation !== null) {
                    window.location.replace(data.result.additionalInformation!)
                }
                else {
                    navigate(PATH.purchaseComplete.upgrade);
                }

            })
            .catch((error) => toast.error(error.data));
    };


    const isLifeTime = selectedPlan.type.includes("LT")
    const isEnterprise = selectedPlan.type.toLowerCase().includes("enterprise")
    const isDowngradePlan = isDowngrade(currentPlan, selectedPlan.type)
    const cardStyles = "w-full border-[1px] border-solid border-secondary  dark:text-grey100 dark:border-grey700"
    return (
        <>
            <ShopifyBillingHeader />
            <div className="flex gap-5 max-lg:flex-col">
                <Card className={`p-8 flex flex-col gap-6 ${cardStyles}`}>
                    <div className="flex w-full justify-between items-center">
                        <h6 className="font-medium">Billing Details</h6>
                        <EbayMarketplaceIcon />
                    </div>
                    {isDowngradePlan ? (
                        <>
                            <div className="flex flex-col mt-2 md:flex-row items-center">
                                <div className="h-[100px] w-full px-6 py-5 bg-grey100 text-grey300 dark:bg-grey700 dark:text-grey300 rounded-[15px] flex items-start justify-center flex-col gap-[10px]">
                                    <div className="flex items-center text-[20px]">
                                        <span className="font-bold uppercase">
                                            {selectedPlan.planName}
                                        </span>
                                        <span className="font-medium">
                                            {getPlanSuffix(selectedPlan.type)}
                                        </span>
                                    </div>
                                    <div className="text-sm font-medium">Downgrading to</div>
                                </div>
                                <div className="min-w-[46px] h-[46px] flex justify-center items-center rotate-90 md:rotate-0">
                                    <IconLeft className="w-[23px] h-[26.86px fill-grey300 dark:fill-grey300" />
                                </div>
                                <div className="h-[140px] w-full px-6 py-5 bg-darkBlue dark:bg-paradiseBlue rounded-[15px] flex items-start justify-center flex-col gap-[10px]">
                                    <div className="flex text-white items-center dark:text-deepSpace900 text-[36px]">
                                        <span className="font-bold uppercase">
                                            {getPlanName(currentPlan)}
                                        </span>
                                        <span className="font-medium">
                                            {getPlanSuffix(currentPlan)}
                                        </span>
                                    </div>
                                    <div className="text-[16px] text-white font-medium dark:text-deepSpace900">
                                        Current Plan
                                    </div>
                                </div>
                            </div>
                            {
                                upgradeFetching ?
                                    <div className="mt-[25px] mb-2 flex justify-center">
                                        <Loading fill={"fill-primaryPurple dark:fill-purple500"} />
                                    </div>
                                    :
                                    <button
                                        disabled={checkUpgradeFetching}
                                        className="w-full mb-2 py-4 hover:bg-primaryPurple dark:text-lilac400 rounded-full dark:hover:bg-purple500 dark:text-sm font-bold dark:font-medium hover:text-white"
                                        onClick={handleUpgrade}>{isTrial ? "Change Now" : "Downgrade"}</button>
                            }
                        </>
                    ) : (
                        <>
                            <div className="flex flex-col md:flex-row mt-2 items-center max-sm:gap-2.5">
                                <div className="h-[100px] max-sm:h-auto max-sm:p-2.5 w-full px-[25px] py-[20px] bg-neutral2 dark:bg-grey100 dark:text-deepSpace900 rounded-[15px] flex items-start justify-center flex-col gap-[10px]">
                                    <div className="flex items-center text-[24px] max-sm:text-lg">
                                        <span className="font-bold uppercase">
                                            {getPlanName(currentPlan)}
                                        </span>
                                        <span className="font-medium">
                                            {getPlanSuffix(currentPlan)}
                                        </span>
                                    </div>
                                    <div className="text-[14px] font-medium">Current Plan</div>
                                </div>
                                <div className="min-w-[46px] h-[46px] flex justify-center items-center rotate-90 md:rotate-0">
                                    <IconRight className="w-[23px] h-[26.86px] fill-smoothGreen dark:fill-paradiseBlue" />
                                </div>
                                <div className={`h-[140px] max-sm:h-auto max-sm:p-2.5 w-full px-[25px] py-5 bg-darkBlue dark:bg-paradiseBlue rounded-[15px] flex items-start justify-center flex-col ${isLifeTime ? "gap-[5px]" : "gap-2.5"}`}>

                                    <div className="flex text-white dark:text-deepSpace900 items-center text-[28px] max-sm:text-lg">
                                        <span className="font-bold uppercase">
                                            {selectedPlan.planName}
                                        </span>
                                        <span className="font-medium ml-1">
                                            {isLifeTime && !isEnterprise ? "/life" : getPlanSuffix(selectedPlan.type)}
                                        </span>
                                    </div>
                                    {isLifeTime && isEnterprise ?
                                        <span className="font-medium text-white dark:text-deepSpace900">
                                            Lifetime
                                        </span>
                                        : ""}

                                    <div className="text-sm text-white font-medium dark:text-deepSpace900">
                                        Upgrading to
                                    </div>
                                </div>
                            </div>

                            {(isTrial && !checkCurrent) ? (upgradeFetching ?
                                <div className="mt-[25px] mb-2 flex justify-center">
                                    <Loading fill={"fill-primaryPurple dark:fill-purple500"} />
                                </div>
                                :
                                <button
                                    disabled={checkUpgradeFetching}
                                    className="w-full mb-2 py-4 hover:bg-primaryPurple dark:text-lilac400 rounded-full dark:hover:bg-purple500 dark:text-sm font-bold dark:font-medium hover:text-white"
                                    onClick={handleUpgrade}>Change Now</button>
                            ) : (
                                <Button
                                    className="mb-2 h-[60px] w-full mx-auto border-smoothGreen bg-smoothGreen dark:border-lilac400 dark:bg-deepSpace900 hover:bg-primaryPurple hover:border-primaryPurple dark:hover:!bg-purple500 dark:hover:!border-purple500 shadow-[2px_5px_30px_-4px_rgba(28,163,147,0.15)]"
                                    titleClassName="text-base !font-bold dark:!font-medium text-base dark:text-lilac400 text-white"
                                    title="Upgrade now"
                                    disabled={checkUpgradeFetching}
                                    loading={upgradeFetching}
                                    handleClick={handleUpgrade}
                                />
                            )}

                        </>
                    )}

                    {!couponLoading && (
                        <CheckoutCouponInput
                            handleApplyCoupon={handleApplyCoupon}
                            selectedPlan={selectedPlan.type}
                            preloadCoupon={defaultCoupon?.message}
                            handleRefetchUpgrade={handleRefetchUpgrade}
                            couponValidating={changeCouponApplying}
                        />
                    )}
                </Card>
                <Card className={cardStyles}>
                    <CheckoutOrderSummary
                        wrapperStyles="!border-0 !max-w-[100%] !p-8"
                        selectedPlan={selectedPlan}
                        isLoading={checkUpgradeFetching || couponLoading || couponApplying || checkUpgradeUninitialized}
                        data={checkoutData}
                        handleVatNumberUpdate={handleVatNumberUpdate}
                    />
                </Card>
            </div>
            <Toaster />

        </>
    )
}

export default SubscriptionUpgradePage