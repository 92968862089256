import {
  sidebarItemsEbay,
  sidebarItemsShopify,
} from "layouts/dashboard/sidebar/SidebarConfig";
import { useState } from "react";
import { DASHBOARD_MARKET_PLACES } from "store/slices/marketplaceSlice";

const useSidebarData = (marketplace: DASHBOARD_MARKET_PLACES) => {
  const [sidebarData, setSidebarData] = useState(
    marketplace === "shopify" ? sidebarItemsShopify : sidebarItemsEbay
  );

  const sidebarDataChange = (marketplace: DASHBOARD_MARKET_PLACES) => {
    marketplace === "shopify"
      ? setSidebarData(sidebarItemsShopify)
      : setSidebarData(sidebarItemsEbay);
  };

  return {
    sidebarData,
    sidebarDataChange,
  };
};

export default useSidebarData;
