import { useNavigate, useSearchParams } from "react-router-dom";

import Header from "components/Header";
import HeaderIcon, { HeaderVariants } from "components/Header/Icon";
import HeaderTab from "components/Header/Tab";
import Search from "components/Search";

import PriceAnalyticsContent from "./components/ebayContents/PriceAnalytics";
import ProductResearchContent from "./components/ebayContents/ProductResearch";
import TitleAnalyticsContent from "./components/ebayContents/TitleAnalytics";

//Icons
import { helpVideos } from "assets/data/helpVideos";
import HeaderHelpVideo from "components/Header/HelpVideo";
import Toaster from "components/Toast";
import { ErrorCodes } from "config/errorMsgs";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import useSearchHistory from "hooks/useSearchHistory";
import useTabs from "hooks/useTabs";
import LoadingPage from "pages/Loading";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PATH } from "routes/path";
import { usePrEbayFilterMutation } from "store/api/prApi";
import { useGetSettingsQuery } from "store/api/quickSettingsApi";
import {
  handlePrFilterChange,
  handlePrSearchChange,
  setPrEbayFilters
} from "store/slices/prSlice";
import { HISTORY_KEYS } from "utils/localStorage";
import EbayFilters from "./components/filters/Ebay";

enum TABS {
  productResearch = "product-research",
  titleAnalytics = "title-analytics",
  priceAnalytics = "price-analytics",
}
const staticValues = {
  dropshiper: true,
  search: true,
  titleFromPopup: "",
  userid: 0,
}
const ProductResearch = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation(['home']);
  const dataFetchedRef = useRef(false);
  const navigate = useNavigate();
  const { activeTab } = useTabs<TABS>(TABS.productResearch);
  const dispatch = useAppDispatch();
  const { data: settings } = useGetSettingsQuery()
  const [hideFilters, setHideFilters] = useState(true);
  const {
    ebayData: data,
    filters: { ebay: ebayFilters, search },
  } = useAppSelector((state) => state.prSlice);
  const [postedSearch, setPostedSearch] = useState(search);

  const [postPrFilters, { isLoading, data: dataResponse, isError }] =
    usePrEbayFilterMutation();

  const searchHistory = useSearchHistory(HISTORY_KEYS.prEbaySearch, search);
  const onHideFilters = () => setHideFilters((prev) => !prev);
  const onSearchChange = (newVal: string) =>
    dispatch(handlePrSearchChange(newVal));

  const onSearchClick = async (searchVal?: string) => {
    const {
      price,
      feedback,
      listingType,
      eBaySite,
      condition,
      shippingLocation,
      saleDateRange,
      excludePhrase,
    } = ebayFilters;
    await postPrFilters({
      minPrice: price.min,
      maxPrice: price.max,
      minFeedback: feedback.min,
      maxFeedback: feedback.max,
      keywords: searchVal || search,
      listingType: listingType,
      ebaySite: `${eBaySite.toUpperCase()}`,
      condition,
      shippingLocation,
      saleDateRange: Number(saleDateRange),
      excludePhrase,
      ...staticValues
    });

    setPostedSearch(search)
    dispatch(
      handlePrFilterChange({
        marketplace: "ebay",
        value: saleDateRange,
        field: "ebaySearchedPeriod",
      })
    );
    dispatch(
      handlePrFilterChange({
        marketplace: "ebay",
        value: eBaySite,
        field: "searchedEBaySite",
      })
    );

    searchHistory.add();
  };

  useEffect(() => {
    if (data?.result.message !== "" || isError) {
      if (data?.result.message !== ErrorCodes.Success) {
        navigate(
          PATH.productResearch.root +
          `?error=${isError ? "error" : data?.result.message}`
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, dataResponse]);

  useEffect(() => {

    if (searchParams.get("newReq") && settings) {
      const {
        price,
        feedback,
        listingType,
        condition,
        saleDateRange,
        excludePhrase,
      } = ebayFilters;

      searchParams.delete("newReq");

      setSearchParams(searchParams)
      dispatch(setPrEbayFilters({
        ...ebayFilters,
        eBaySite: settings?.result.productLocation,
        shippingLocation: settings?.result.shippingLocation,
      }));
      postPrFilters({
        minPrice: price.min,
        maxPrice: price.max,
        minFeedback: feedback.min,
        maxFeedback: feedback.max,
        keywords: search,
        listingType: listingType,
        ebaySite: `${settings?.result.productLocation.toUpperCase()}`,
        condition,
        shippingLocation: settings?.result.shippingLocation,
        saleDateRange: Number(saleDateRange),
        excludePhrase,
        ...staticValues
      });

      setPostedSearch(searchParams.get("newReq") || "")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, settings])

  useEffect(() => {
    var refreshNiche = searchParams.get("action") === "refresh"
    var nichesLocation = searchParams.get("location")
    if (search === "" && settings) {
      if (searchParams.get("search")) {
        const keywords = searchParams.get("search");
        dispatch(handlePrSearchChange(keywords));
        if (!dataFetchedRef.current) {
          const { result } = settings
          postPrFilters({
            minPrice: result.productMinPrice,
            maxPrice: result.productMaxPrice,
            minFeedback: result.minFeedback,
            maxFeedback: result.maxFeedback,
            keywords: keywords || "",
            listingType: result.listingType,
            ebaySite: result.productLocation || "EBAY-US",
            condition: result.condition,
            shippingLocation: result.shippingLocation,
            saleDateRange: Number(result.dateRange),
            excludePhrase: result.excludePhrase,
            refreshNiche: refreshNiche,
            sourceWebsite: nichesLocation,
            ...staticValues
          });
          setPostedSearch(keywords || "")

          searchParams.delete('action');
          searchParams.delete('location')
          setSearchParams(searchParams);

          dispatch(
            setPrEbayFilters({
              ...ebayFilters,
              eBaySite: result.productLocation || "EBAY-US",
              searchedEBaySite: result.productLocation || "EBAY-US",
              condition: result.condition,
              excludePhrase: result.excludePhrase,
              shippingLocation: result.shippingLocation,
              listingType: result.listingType,
              saleDateRange: result.dateRange,
              price: {
                min: result.productMinPrice,
                max: result.productMaxPrice,
              },
              feedback: {
                min: result.minFeedback,
                max: result.maxFeedback,
              },
            })
          );
          dataFetchedRef.current = true;
        }
      } else {
        navigate(PATH.productResearch.root);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, settings]);

  return (
    <LoadingPage loading={isLoading}>
      <div>
        <Header
          leftContent={
            <>
              <div className="flex gap-5.5 max-sm:flex-col max-lg:justify-center">
                <HeaderTab
                  title={t('ProductResearch')}
                  className="w-[160px]"
                  handleClick={() => setSearchParams({})}
                  isActive={activeTab === TABS.productResearch}
                />
                <HeaderTab
                  title={t('TitleAnalytics')}
                  className="min-w-[160px]"
                  handleClick={() =>
                    setSearchParams({ tab: TABS.titleAnalytics })
                  }
                  isActive={activeTab === TABS.titleAnalytics}
                />
                <HeaderTab
                  title={t('PriceAnalytics')}
                  className="min-w-[160px]"
                  handleClick={() =>
                    setSearchParams({ tab: TABS.priceAnalytics })
                  }
                  isActive={activeTab === TABS.priceAnalytics}
                />
              </div>
            </>
          }
          middleContent={
            <Search
              search={search}
              handleChange={onSearchChange}
              searchHistory={searchHistory}
              loading={isLoading}
              size="sm"
              required
              handleSearchClick={onSearchClick}
              searchButton
              htmlFor="search"
            />
          }
          rightContent={
            <>
              <HeaderIcon
                variant={HeaderVariants.filters}
                handleClick={onHideFilters}
                tooltipTitle="Show/Hide Filters"
              />
              <HeaderHelpVideo videos={helpVideos.pr} />

              <HeaderIcon
                variant={HeaderVariants.settings}
                tooltipTitle="Product Research Settings"
                handleClick={() =>
                  navigate(`${PATH.quickSettings.root}?tab=search-preferences`)
                }
              />
            </>
          }
          leftContentClassName="!w-fit"
          middleContentClassName="w-full"
          className="gap-6"
        />
        {hideFilters ? "" : <EbayFilters bordered />}

        {activeTab === TABS.productResearch ? (
          <ProductResearchContent postedSearch={postedSearch} />
        ) : activeTab === TABS.titleAnalytics ? (
          <TitleAnalyticsContent />
        ) : (
          <PriceAnalyticsContent />
        )}
        <Toaster />
      </div>
    </LoadingPage>
  );
};

export default ProductResearch;
