

const NoImage = () => {
    return (
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width="32" height="32" rx="16" fill="currentColor" />
            <path className="stroke-neutral1 dark:stroke-grey300" d="M23.9781 11.2789V22.9374C23.9781 23.2393 23.7334 23.4839 23.4316 23.4839H11.7731C11.4712 23.4839 11.2266 23.2393 11.2266 22.9374V11.2789C11.2266 10.9771 11.4712 10.7324 11.7731 10.7324H23.4316C23.7334 10.7324 23.9781 10.9771 23.9781 11.2789Z" stroke-width="1.02467" stroke-linecap="round" strokeLinejoin="round" />
            <path className="stroke-neutral1 dark:stroke-grey300" d="M21.2456 8H9.04063C8.73881 8 8.49414 8.24467 8.49414 8.54649V20.7515" stroke-width="1.02467" stroke-linecap="round" strokeLinejoin="round" />
            <path className="stroke-neutral1 dark:stroke-grey300" d="M11.2266 19.658L16.1854 18.0186L23.9781 20.751" stroke-width="1.02467" stroke-linecap="round" strokeLinejoin="round" />
            <path className="stroke-neutral1 dark:stroke-grey300" d="M19.8789 16.1973C19.1244 16.1973 18.5127 15.5856 18.5127 14.8311C18.5127 14.0766 19.1244 13.4648 19.8789 13.4648C20.6335 13.4648 21.2452 14.0766 21.2452 14.8311C21.2452 15.5856 20.6335 16.1973 19.8789 16.1973Z" stroke-width="1.02467" stroke-linecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default NoImage