import { createSlice } from "@reduxjs/toolkit";
import { TSelectedPlan } from "./../../types/plans";

export const initialSelectedPlanSlice = {
  selectedPlan: {
    type: "",
    planName: "",
    planPrice: 0,
    skip: false,
  },
  isShopify: false,
  isUpgrade: false,
} as { selectedPlan: TSelectedPlan };

const planSlice = createSlice({
  name: "selectedPlan",
  initialState: initialSelectedPlanSlice,
  reducers: {
    onSelectPlan: (state, { payload }) => {
      state.selectedPlan = payload;
    },
  },
});

export default planSlice.reducer;

export const { onSelectPlan } = planSlice.actions;
