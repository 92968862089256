import {
  AnalyseAliexpressItem,
  analyzeAliexpressCols
} from "assets/tableColumns/analyzeAliexpress";
import Drawer from "components/Drawer";
import SelectFolder from "components/Modal/SelectFolder";
import Table from "components/Table";
import { TableStyles } from "components/Table/Table";
import { ErrorCodes, ErrorMsgs } from "config/errorMsgs";
import useModal from "hooks/useModal";
import useTable from "hooks/useTable";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useSaveItemMutation } from "store/api/iconFinderApi";
import { Option } from "types/common";
import { formatCurrency } from "utils/formatCurrency";
import formatString from "utils/formatString";
import { formatDate } from "utils/formatTime";

type Props = {
  helpers: ReturnType<useModal>;
  storeName: string;
  countryName: string;
  feedbackCount: number;
  dateRegistered: string | null;
  communication: null;
  itemID: string;
  itemAsDescribed: number;
  revenueTotal: number;
  itemUrl?: string;
  isAliGrowth?: boolean;
} & AnalyseAliexpressItem;

const tableStyles: TableStyles = {
  style: "w-full",
  minWidth: "min-w-[364px]",
  px: "px-0",
  body: {
    contHeight: "h-[37px]",
    rowHeight: "h-[66px]",
  },
};

const AnalyzeAliexpress: FC<Props> = ({
  helpers,
  storeName,
  countryName,
  feedbackCount,
  dateRegistered,
  communication,
  itemAsDescribed,
  revenueTotal,
  title,
  price,
  itemID,
  feedback,
  totalSales,
  rating,
  itemUrl,
  img,
  isAliGrowth,
}) => {
  const [selectedFolder, setSelectedFolder] = useState<Option | null>(null);
  const onFolderSelect = (newVal: Option | null) => setSelectedFolder(newVal);
  const [saveItem, { error, isLoading }] = useSaveItemMutation();

  useEffect(() => {
    setSelectedFolder(null)
  }, [helpers.close])
  useEffect(() => {
    // @ts-ignore
    if (error?.data === ErrorCodes.itemAlreadyAdded) {
      toast.error(ErrorMsgs.ItemAlreadyAdded(selectedFolder?.text || ""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onSaveClick = async () => {
    if (selectedFolder?.value === "" || selectedFolder === null) {
      toast.error("Please Select Folder");
    } else {
      await saveItem({
        sendBulkItems: [
          {
            ebayTitle: title,
            ebayPrice: price,
            ebayItemId: itemID,
            amazonTitle: "",
            amazonPrice: 0,
            amazonImageUrl: "",
            asin: itemID,
            profit: 0,
            sales: Number(totalSales),
          },
        ],
        listid: selectedFolder?.value,
        requestId: itemID,
        type: "Ali",
        userID: 0,
      })
        .unwrap()
        .then((payload) => {
          if (payload) {
            toast.success("Item Saved!");
          }
        }).catch(() => toast.error("Something went wrong!"));
    }
  };

  const rows = [
    { title: "Seller Name", value: formatString(storeName) },
    { title: "Country", value: countryName },
    { title: "Total Feedback", value: feedbackCount },
    {
      title: "Store Open Since",
      value: dateRegistered ? formatDate(dateRegistered) : "",
    },
    { title: "Communication", value: communication },
    { title: "Item Described", value: itemAsDescribed },
    { title: "Revenue", value: formatCurrency(String(revenueTotal)) },
  ];
  const { table } = useTable({
    columns: analyzeAliexpressCols(onSaveClick, isLoading, isAliGrowth),
    dataDeps: [price, feedback, img, totalSales, rating, title],
    columnDeps: [selectedFolder, isLoading],
    data: [
      {
        price,
        feedback,
        img,
        totalSales,
        rating,
        title,
      },
    ],
    search: true,
  });

  return (
    <Drawer
      helpers={helpers}
      className={`text-sm dark:text-grey100 rounded-tl-10 ${helpers.isOpen ? "border-2 border-primaryPurple dark:border-transparent border-b-0 border-r-0 pr-[90px]" : ""}`}
    >
      <div className="flex items-center mb-5.5 text-sm ">
        <img
          src={img}
          alt="product"
          className={`my-auto rounded-[7px] max-h-[4.5rem] shadow border-2 border-secondary dark:border-0 mr-3`}
          width={71}
          height={73}
        />
        {itemUrl ? (
          <Link
            className="cursor-pointer hover:text-primaryPurple dark:hover:text-purple500"
            to={itemUrl}
            target="_blank"
            rel="noreferrer"
          >
            {formatString(title)}
          </Link>
        ) : (
          <p>{formatString(title)}</p>
        )}
      </div>

      <div className="flex justify-between items-center mb-[15px]">
        <h6 className="font-bold">Product Info</h6>
        {!isAliGrowth &&
          <SelectFolder
            btnClassName="!h-9"
            className="max-w-[172px] "
            cutLongOption={13}
            cutTitle={13}
            listWrapperClassName="!top-11"
            isOpen={helpers.isOpen}
            selectedFolder={selectedFolder}
            onFolderSelect={onFolderSelect}
            type="aliexpress"
          />
        }
      </div>
      <div className="flex items-center gap-5.5 mb-4.5 max-sm:flex-col max-sm:items-start max-sm:gap-2">
        <Table<AnalyseAliexpressItem>
          hideTableHead
          table={table}
          styles={tableStyles}
        />

      </div>
      <h6 className="mb-2 font-bold">Seller Info</h6>
      <div className="border-2 border-secondary dark:border-deepSpace900 rounded-10 p-3 w-[81%]">
        <table>
          <tbody>
            {rows.map((item, index) => (
              <tr key={index} className="">
                <td className="text-neutral3 py-2 w-[40%]">
                  <div className="">{item.title}</div>
                </td>
                <td className="w-1/2">{item.value || "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Drawer>
  );
};

export default AnalyzeAliexpress;
