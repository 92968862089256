import { ReactComponent as IconLeft } from "assets/icons/arrow-left-extended-bold.svg";
import { ReactComponent as IconRight } from "assets/icons/arrow-right-extended-bold.svg";
import Button from "components/Button";
import Card from "components/Card";
import CheckoutOrderSummary from "components/CheckoutOrderSummary/Index";
import CheckoutCouponInput from "components/Input/CheckoutCouponInput";
import Loading from "components/Loading";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import useDecodeToken from "hooks/useDecodeToken";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PATH } from "routes/path";
import {
  useGetDefaultCouponQuery,
  useLazyCheckUpgradeQuery,
  useLazyUpgradeQuery
} from "store/api/managePaymentApi";
import { setUserAccessToken } from "store/slices/userSlice";
import { SetAccessToken } from "utils/localStorage";
import { getPlanName, getPlanSuffix, isDowngrade } from "utils/planUtils";

const SubscriptionUpgrade = () => {
  const user = useDecodeToken();
  const { selectedPlan } = useAppSelector((state) => state.planSlice);
  const currentPlan = user.package_name || "";
  const isTrial = /^true$/i.test(user.active_trail);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [coupon, setCoupon] = useState("");
  const handleApplyCoupon = (newVal: string) => setCoupon(newVal);
  const { data: defaultCoupon, isFetching: couponLoading } =
    useGetDefaultCouponQuery(selectedPlan.type);
  const [checkUpgrade, { data, isFetching: checkUpgradeFetching }] =
    useLazyCheckUpgradeQuery();
  const [makeUpgrade, { isFetching: upgradeFetching }] = useLazyUpgradeQuery();
  const [checkoutData, setCheckoutData] = useState(data);

  const handleRefetchUpgrade = () => {
    checkUpgrade({
      package: selectedPlan.type,
      coupon: coupon,
    })
      .unwrap()
      .then((data) => setCheckoutData(data))
      .catch((error) => toast.error(error.data.message));
  }


  useEffect(() => {
    if (!couponLoading) {
      handleRefetchUpgrade()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coupon]);


  const handleVatNumberUpdate = () => {
    if (selectedPlan?.type != null) {
      handleRefetchUpgrade();
    }
  }



  const handleUpgrade = () => {
    makeUpgrade({
      type: selectedPlan.type,
      couponName: coupon,
    })
      .unwrap()
      .then((data) => {
        if (data.result.token != null && data.result.token != "") {
          SetAccessToken(data.result.token);
          dispatch(setUserAccessToken(data.result.token));
        }
        if (data.result.trial) {
          navigate(PATH.purchaseComplete.changed);
        } else if (data.result.downgrade) {
          navigate(PATH.purchaseComplete.downgrade);
        } else if (data.result.message === "redirect" && data.result.additionalInformation !== null) {
          window.location.replace(data.result.additionalInformation!)
        }
        else {
          navigate(PATH.purchaseComplete.upgrade);
        }

      })
      .catch((error) => toast.error(error.data));
  };

  const isLifeTime = selectedPlan.type.includes("LT")
  const isEnterprise = selectedPlan.type.toLowerCase().includes("enterprise")

  return (
    <Card className="px-[33px] py-[26px] max-sm:p-2 dark:text-grey100 dark:bg-deepSpace700">
      <div className="flex items-center justify-center xl:items-stretch xl:flex-row w-full flex-col m-auto gap-[54px] mt-5.5 max-md:flex-col max-w-[1100px]">
        <div className="rounded-[10px] border-[2px] border-[#EBF4FE] px-[26px] py-[30px] flex flex-col gap-6 max-sm:p-4 w-full dark:bg-black900 dark:border-grey700">
          <div className="font-bold">Billing Details</div>
          {isDowngrade(currentPlan, selectedPlan.type) ? (
            <>
              <div className="flex flex-col mt-2 md:flex-row items-center">
                <div className="h-[100px] w-full px-6 py-5 bg-grey100 text-grey300 dark:bg-grey700 dark:text-grey300 rounded-[15px] flex items-start justify-center flex-col gap-[10px]">
                  <div className="flex items-center text-[20px]">
                    <span className="font-bold uppercase">
                      {selectedPlan.planName}
                    </span>
                    <span className="font-medium">
                      {getPlanSuffix(selectedPlan.type)}
                    </span>
                  </div>
                  <div className="text-sm font-medium">Downgrading to</div>
                </div>
                <div className="min-w-[46px] h-[46px] flex justify-center items-center rotate-90 md:rotate-0">
                  <IconLeft className="w-[23px] h-[26.86px fill-grey300 dark:fill-grey300" />
                </div>
                <div className="h-[140px] w-full px-6 py-5 bg-darkBlue dark:bg-paradiseBlue rounded-[15px] flex items-start justify-center flex-col gap-[10px]">
                  <div className="flex text-white items-center dark:text-deepSpace900 text-[36px]">
                    <span className="font-bold uppercase">
                      {getPlanName(currentPlan)}
                    </span>
                    <span className="font-medium">
                      {getPlanSuffix(currentPlan)}
                    </span>
                  </div>
                  <div className="text-[16px] text-white font-medium dark:text-deepSpace900">
                    Current Plan
                  </div>
                </div>
              </div>
              {
                checkUpgradeFetching || upgradeFetching ?
                  <div className="mt-[25px] mb-2 flex justify-center">
                    <Loading fill={"fill-primaryPurple dark:fill-purple500"} />
                  </div>
                  :
                  <button
                    className="w-full mb-2 py-4 hover:bg-primaryPurple dark:text-lilac400 rounded-full dark:hover:bg-purple500 dark:text-sm font-bold dark:font-medium hover:text-white"
                    onClick={handleUpgrade}>{isTrial ? "Change Now" : "Downgrade"}</button>
              }
            </>
          ) : (
            <>
              <div className="flex flex-col md:flex-row mt-2 items-center max-sm:gap-2.5">
                <div className="h-[100px] max-sm:h-auto max-sm:p-2.5 w-full px-[25px] py-[20px] bg-neutral2 dark:bg-grey100 dark:text-deepSpace900 rounded-[15px] flex items-start justify-center flex-col gap-[10px]">
                  <div className="flex items-center text-[24px] max-sm:text-lg">
                    <span className="font-bold uppercase">
                      {getPlanName(currentPlan)}
                    </span>
                    <span className="font-medium">
                      {getPlanSuffix(currentPlan)}
                    </span>
                  </div>
                  <div className="text-[14px] font-medium">Current Plan</div>
                </div>
                <div className="min-w-[46px] h-[46px] flex justify-center items-center rotate-90 md:rotate-0">
                  <IconRight className="w-[23px] h-[26.86px] fill-smoothGreen dark:fill-paradiseBlue" />
                </div>
                <div className={`h-[140px] max-sm:h-auto max-sm:p-2.5 w-full px-[25px] py-5 bg-darkBlue dark:bg-paradiseBlue rounded-[15px] flex items-start justify-center flex-col ${isLifeTime ? "gap-[5px]" : "gap-2.5"}`}>

                  <div className="flex text-white dark:text-deepSpace900 items-center text-[28px] max-sm:text-lg">
                    <span className="font-bold uppercase">
                      {selectedPlan.planName}
                    </span>
                    <span className="font-medium ml-1">
                      {isLifeTime && !isEnterprise ? "/life" : getPlanSuffix(selectedPlan.type)}
                    </span>
                  </div>
                  {isLifeTime && isEnterprise ?
                    <span className="font-medium text-white dark:text-deepSpace900">
                      Lifetime
                    </span>
                    : ""}

                  <div className="text-sm text-white font-medium dark:text-deepSpace900">
                    Upgrading to
                  </div>
                </div>
              </div>
              {
                checkUpgradeFetching ?
                  <div className="h-[60px] flex justify-center mb-2">
                    <Loading fill={"fill-primaryPurple dark:fill-purple500"} />
                  </div>
                  :
                  <Button
                    className="mb-2 h-[60px] w-full mx-auto border-smoothGreen bg-smoothGreen dark:border-lilac400 dark:bg-deepSpace900 hover:bg-primaryPurple hover:border-primaryPurple dark:hover:!bg-purple500 dark:hover:!border-purple500 shadow-[2px_5px_30px_-4px_rgba(28,163,147,0.15)]"
                    titleClassName="text-base !font-bold dark:!font-medium text-base dark:text-lilac400 text-white"
                    title={isTrial ? "Change now" : "Upgrade now"}
                    loading={upgradeFetching}
                    handleClick={handleUpgrade}
                  />
              }

            </>
          )}

          {!couponLoading && (
            <>
              <CheckoutCouponInput
                handleApplyCoupon={handleApplyCoupon}
                selectedPlan={selectedPlan.type}
                preloadCoupon={defaultCoupon?.message}
                handleRefetchUpgrade={handleRefetchUpgrade}
              />
            </>
          )}
        </div>

        <CheckoutOrderSummary
          selectedPlan={selectedPlan}
          isLoading={checkUpgradeFetching || couponLoading}
          data={checkoutData}
          handleVatNumberUpdate={handleVatNumberUpdate}
        />
      </div>

    </Card>
  );
};

export default SubscriptionUpgrade;
