import { FC } from "react";

interface Props {
    isDisabled?: boolean;
}
const EbayMarketplaceIcon: FC<Props> = ({ isDisabled }) => {
    return (
        <div className={`flex justify-center items-center h-8 w-[61px] bg-neutral2 rounded-full dark:bg-deepSpace900 ${isDisabled ? "dark:opacity-50" : ""}`}>
            <svg width="57" height="25" viewBox="0 0 57 25" fill="none" xmlns="http://www.w3.org/2000/svg" className={`w-[41px] h-[21.75px] ${isDisabled ? "dark:opacity-100 opacity-50" : ""}`}>
                <path d="M7.37472 5.74651C3.35794 5.74651 0 7.53256 0 12.9353C0 17.2219 2.2528 19.9233 7.48098 19.9233C13.623 19.9233 14.0268 15.6591 14.0268 15.6591H11.0515C11.0515 15.6591 10.4139 17.9586 7.31096 17.9586C4.78188 17.9586 2.95414 16.1726 2.95414 13.6498H14.3456V12.0647C14.3244 9.56419 12.8367 5.74651 7.37472 5.74651ZM7.26846 7.75581C9.67002 7.75581 11.3277 9.29628 11.3277 11.6181H3.0179C3.0179 9.16233 5.16443 7.75581 7.26846 7.75581Z"
                    className="fill-[#E0262C]" />
                <path d="M14.3244 0.5V17.1995C14.3244 18.1372 14.2606 19.4767 14.2606 19.4767H17.1085C17.1085 19.4767 17.2148 18.5167 17.2148 17.646C17.2148 17.646 18.6174 19.9456 22.443 19.9456C26.4597 19.9456 29.2013 17.0209 29.2013 12.8237C29.2013 8.91674 26.6935 5.76884 22.4642 5.76884C18.4899 5.76884 17.2573 8.02372 17.2573 8.02372V0.5H14.3244ZM21.6991 7.84512C24.4195 7.84512 26.1622 9.96605 26.1622 12.8237C26.1622 15.8823 24.1644 17.8916 21.7204 17.8916C18.8087 17.8916 17.236 15.5028 17.236 12.846C17.236 10.3902 18.6387 7.84512 21.6991 7.84512Z"
                    className="fill-[#385FAA]" />
                <path d="M36.236 5.74651C30.179 5.74651 29.7964 9.2293 29.7964 9.78744H32.8143C32.8143 9.78744 32.9631 7.75581 36.0235 7.75581C38.0213 7.75581 39.5515 8.71581 39.5515 10.5465V11.194H36.0235C31.3266 11.194 28.8613 12.6451 28.8613 15.5474C28.8613 18.4274 31.1566 19.9902 34.2383 19.9902C38.4463 19.9902 39.8065 17.5344 39.8065 17.5344C39.8065 18.5167 39.8702 19.4767 39.8702 19.4767H42.5481C42.5481 19.4767 42.4418 18.2935 42.4418 17.5344V10.9484C42.4843 6.63953 39.1689 5.74651 36.236 5.74651ZM39.5727 13.1586V14.0293C39.5727 15.1679 38.9139 18.0033 34.9608 18.0033C32.8143 18.0033 31.8792 16.8647 31.8792 15.5698C31.8792 13.1809 35.0034 13.1586 39.5727 13.1586Z"
                    className="fill-[#F4B100]" />
                <path d="M40.8479 6.28233H44.2271L49.094 16.5074L53.9396 6.28233H57L48.1588 24.5H44.9284L47.4787 19.4321L40.8479 6.28233Z"
                    className="fill-[#8CB625]" />
            </svg>

        </div>
    )
}

export default EbayMarketplaceIcon