
import { ReactComponent as Dollar } from "assets/icons/dollar.svg";
import { ReactComponent as ExpDate } from "assets/icons/exp-date.svg";
import { ReactComponent as Filters } from "assets/icons/filters.svg";
import { ReactComponent as Sales } from "assets/icons/sales.svg";
import Card from "components/Card";
import MinMaxDateFilter from "components/Filters/MinMaxDateFilter";
import MinMaxFilter from "components/Filters/MinMaxFilter";
import MinMaxFilterAutoScale from "components/Filters/MinMaxFilterAutoScale";
import MinMaxFilterCard from "components/Filters/MinMaxFilterCard";
import { FC } from "react";
import { ShopifyCRFilters } from "../CompetitorResearch";

interface Props {
    filters: ShopifyCRFilters,
    handleFilterChange: (newVal: string | number | Date | null,
        name: keyof ShopifyCRFilters,
        type?: "min" | "max") => void
}
const verticalLineStyles = "max-h-[67px] h-full  rounded-full bg-secondary dark:bg-deepSpace900 max-lg:hidden my-auto  !min-w-[3px]"

const SalesSpyFiltersCard: FC<Props> = ({ filters, handleFilterChange }) => (
    <Card
        className="flex max-xl:flex-wrap max-xl:gap-4 py-[10px] px-[24px] mb-5"
        bordered
    >
        <div className="flex w-full max-lg:flex-wrap gap-[8px]">
            <div className="flex items-center justify-center gap-[8px]">
                <div className="flex items-center justify-center w-[24px] h-[24px]">
                    <Filters fill="" className="w-[17px] dark:fill-grey100" />
                </div>

                <span className="text-[14px] font-bold dark:text-grey100">Filters</span>
            </div>
            <div className="flex justify-between w-full max-lg:flex-wrap max-lg:gap-y-8 px-[32px]">
                <MinMaxDateFilter
                    datePickerClassName="text-[14px] w-[90px]"
                    value={filters.uploadDate}
                    inputWidth="w-[8rem]"
                    iconClassName="w-[14px] h-[14px] dark:fill-grey100"
                    inputWrapperClassName="mx-8 gap-[21px]"
                    title='Upload Date'
                    className="max-md:w-full max-lg:w-1/2 dark:text-grey100 max-w-[200px] w-full"
                    type="date"
                    Icon={ExpDate}
                    handleChange={(newVal, type) =>
                        handleFilterChange(newVal, "uploadDate", type)
                    }
                    infoTitle="Filter products by the date they were uploaded."
                />
                <div className={verticalLineStyles} />
                <MinMaxFilterCard title={'Sales'} Icon={Sales} className="gap-4" wrapperClassName="max-w-[200px] w-full" infoTitle="Filter products by the number of sales."
                    iconClassName="w-[14px] h-[13px]"
                >
                    <MinMaxFilter
                        value={filters.sales}
                        inputWidth="w-16"
                        inputGap="gap-[21px]"
                        inputWrapperClassName="mt-[10px]"
                        name="Sales"
                        handleChange={(newVal, type) => {
                            handleFilterChange(newVal, "sales", type);
                        }}
                        inputClassName="text-[14px]"
                    />
                </MinMaxFilterCard>
                <div className={verticalLineStyles} />
                <MinMaxFilterCard title={'Revenue'} Icon={Dollar} className="gap-4" wrapperClassName="max-w-[200px] w-full" infoTitle="Filter products by revenue generated."
                    iconClassName="w-[14px] h-[14px]"
                >
                    <MinMaxFilterAutoScale
                        value={filters.revenue}
                        inputWidth="max-w-[64px]"
                        inputGap="gap-[21px]"
                        inputWrapperClassName="mt-[10px]"
                        customInputWrapperStyles="px-0 w-[77px]"
                        name="Revenue"
                        handleChange={(newVal, type) => {
                            handleFilterChange(newVal, "revenue", type);
                        }}
                        palceholder={{ min: "$0", max: "$0" }}
                        inputClassName="text-[14px]"
                    />
                </MinMaxFilterCard>
                <div className={verticalLineStyles} />
                <MinMaxFilterCard title='Price' Icon={Dollar} iconClassName="w-[14px] h-[14px]" className="gap-4" wrapperClassName="max-w-[200px] w-full" infoTitle="Filter products by price.">
                    <MinMaxFilterAutoScale
                        value={filters.price}
                        name="Price"
                        inputGap=" gap-[21px]"
                        inputWrapperClassName="mt-[10px]"
                        customInputWrapperStyles="px-0 w-[77px]"
                        inputWidth="max-w-[64px]"
                        handleChange={(newVal, type) => {
                            handleFilterChange(newVal, "price", type);
                        }}
                        palceholder={{ min: "$0", max: "$0" }}
                        inputClassName="text-[14px]"
                    />
                </MinMaxFilterCard>
            </div>


        </div>
    </Card>
)

export default SalesSpyFiltersCard;