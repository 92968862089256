import { ReactComponent as Revenue } from "assets/icons/average-price.svg";
import { ReactComponent as LineGraph } from "assets/icons/line-graph.svg";
import Card from "components/Card";
import { THEME_COLORS } from "config/constants";

import CustomRechartsTooltip from "components/CustomRechartsTooltip";
import dayjs from "dayjs";
import { useAppSelector } from "hooks/useAppSelector";
import { FC } from "react";
import { useParams } from "react-router-dom";
import {
    Bar, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis,
    YAxis
} from 'recharts';
import { useGetSalesAndRevenueQuery, useGetStoreDetailsQuery } from "store/api/salesSpy";
import { formatCurrency, formatNumber } from "utils/formatCurrency";
import { formatDate } from "utils/formatTime";

interface SelDates {
    start: Date | null;
    end: Date | null;
}

interface Props {
    selDates: SelDates;
}
const SalesSpySalesGraph: FC<Props> = ({ selDates }) => {
    const { isDarkMode } = useAppSelector((state) => state.themeSlice);
    const { id } = useParams();
    const params = {
        startDate: formatDate(selDates.start),
        endDate: formatDate(selDates.end),
        similarwebdomainid: Number(id)
    }
    const { data: salesAndRevenue } = useGetSalesAndRevenueQuery(params)
    const { data: storeDetails } = useGetStoreDetailsQuery(params)
    const dataFormatter = (number: number): string => {
        if (number > 1000000000) {
            return (number / 1000000000).toString() + 'B';
        } else if (number > 1000000) {
            return (number / 1000000).toString() + 'M';
        } else if (number > 1000) {
            return (number / 1000).toString() + 'K';
        } else {
            return number.toString();
        }
    };
    return (
        <Card className="col-span-4  max-xl:col-span-2 max-lg:col-span-2 max-md:col-span-1  p-2.5" shadow="shadow-none">
            <div className="bg-secondary dark:bg-grey700 dark:text-grey100 ml-5 w-fit rounded-10 pl-2.5 py-[5px] pr-10 flex gap-5">
                <div className="flex gap-[5px] font-bold">
                    <div className="flex h-6 items-center">
                        <Revenue className="fill-primaryPurple w-4 h-2.5" />
                    </div>
                    <div className="">
                        <span className="leading-[20px] text-sm">
                            Revenue
                        </span>
                        <p className="text-xl">
                            {formatCurrency(Math.round(storeDetails?.result.revenueConverted || 0))}
                        </p>
                    </div>
                </div>

                <div className="flex gap-[5px] font-bold">
                    <div className="flex h-5 items-center">
                        <LineGraph className="fill-smoothGreen" />
                    </div>
                    <div>
                        <span className="font-bold leading-[20px] text-sm">
                            Sold Items
                        </span>
                        <p className="text-xl">
                            {formatNumber(storeDetails?.result.sales || "")}
                        </p>
                    </div>
                </div>
                {/* @ts-ignore */}
                <p className="font-medium text-sm">

                    {dayjs(selDates.start).format("MMM DD, YYYY")} - {dayjs(selDates.end).format("MMM DD, YYYY")}
                </p>
            </div>
            <div className="h-[234px]">
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                        width={500}
                        height={400}
                        data={salesAndRevenue?.result.map(item => ({ date: dayjs(item.date).format("DD/MM"), revenue: Math.round(item.revenue), sales: Math.round(item.sales) })) || []}
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 20,
                            left: -15,
                        }}
                    >
                        <CartesianGrid
                            stroke={THEME_COLORS[isDarkMode ? "deepSpace800" : "secondary"]}
                            strokeWidth="1"
                            rx={25}
                            ry={250}
                            vertical={false}
                            radius="40px"
                        />
                        <XAxis dataKey="date"
                            style={{
                                fill: isDarkMode ? THEME_COLORS.grey100 : THEME_COLORS.darkBlue,
                                textAnchor: "middle",
                                fontSize: 12
                            }}
                            tickLine={false}
                            tickMargin={10}
                            strokeWidth={0}
                        />

                        <Tooltip
                            content={<CustomRechartsTooltip valueLabel="Sales" secondLabel="Revenue" secondValueType="currency" />}
                            cursor={false}
                            wrapperStyle={{ outline: "none" }}
                        />
                        <Bar dataKey="sales" barSize={20} fill={THEME_COLORS.smoothGreen} yAxisId="right" />
                        <Line
                            type="monotone"
                            dataKey="revenue"
                            strokeWidth={3}
                            fill={THEME_COLORS.primaryPurple}
                            stroke={isDarkMode ? THEME_COLORS.purple500 : THEME_COLORS.primaryPurple}
                            dot={false}
                            activeDot={{
                                stroke: isDarkMode ? THEME_COLORS.purple500 : THEME_COLORS.primaryPurple
                            }}
                            yAxisId="left"
                        />
                        <YAxis
                            tickCount={10}
                            yAxisId="right"
                            orientation="right"
                            strokeWidth={0} tickMargin={4} style={{
                                fill: isDarkMode ? THEME_COLORS.grey100 : THEME_COLORS.darkBlue,
                                fontSize: 12
                            }} />
                        <YAxis
                            tickCount={10}
                            tickFormatter={dataFormatter}
                            yAxisId="left"
                            strokeWidth={0} tickMargin={4} style={{
                                fill: isDarkMode ? THEME_COLORS.grey100 : THEME_COLORS.darkBlue,
                                fontSize: 12
                            }}
                        />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>

        </Card>
    )
}
export default SalesSpySalesGraph