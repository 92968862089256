import PurchaseComplete from "pages/ManagementPanel/components/ManagePayments/PurchaseComplete"
import { PATH } from "./path"

const registrationCompleteRoutes = [
    {
        path: PATH.purchaseComplete.paypal,
        element: <PurchaseComplete isPaypal />,
    },
    {
        path: PATH.purchaseComplete.payoneer,
        element: <PurchaseComplete isPayoneer />,
    },
    {
        path: PATH.purchaseComplete.shopifyPayoneer,
        element: <PurchaseComplete isPayoneer isShopify />,
    },
    {
        path: PATH.purchaseComplete.upgrade,
        element: <PurchaseComplete isUpgrade />,
    },
    {
        path: PATH.purchaseComplete.shopifyUpgrade,
        element: <PurchaseComplete isUpgrade isShopify />,
    },
    {
        path: PATH.purchaseComplete.downgrade,
        element: <PurchaseComplete isDowngrade />,
    },
    {
        path: PATH.purchaseComplete.shopifyDowngrade,
        element: <PurchaseComplete isDowngrade isShopify />,
    },
    {
        path: PATH.purchaseComplete.changed,
        element: <PurchaseComplete isChanged />,
    },
    {
        path: PATH.purchaseComplete.creditcard,
        element: <PurchaseComplete isCreditCard />,
    },
    {
        path: PATH.purchaseComplete.shopifyCreditCard,
        element: <PurchaseComplete isCreditCard isShopify />,
    },
    {
        path: PATH.purchaseComplete.shopifyPaypal,
        element: <PurchaseComplete isPaypal isShopify />,
    }
]

export default registrationCompleteRoutes