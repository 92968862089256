import { ReactComponent as Store } from "assets/icons/store.svg";
import Card from "components/Card";

const StoreUpdate = () => (
    <>
        <Card className={`flex-1 pt-[42px] pb-[49px] px-[25px]`}>
            <div className="flex justify-center items-center gap-[20px] mb-[30px]">
                <Store className="fill-smoothGreen dark:fill-paradiseBlue w-[32px] h-[28px]" />
                <div className="text-[16px] font-bold">Store Update</div>
            </div>
            <div className="text-center text-[14px] font-medium">
                Thank you! <br />We are updating this store, please check back soon for results.
            </div>
        </Card>
    </>
)

export default StoreUpdate