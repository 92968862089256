import { ReactComponent as Error } from "assets/icons/attention.svg";
import Dropshipper from "assets/icons/dropshipper";
import { ReactComponent as AliIcon } from "assets/icons/supplier-logo-icons/aliexpress.svg";
import { ReactComponent as AmazonIcon } from "assets/icons/supplier-logo-icons/amazon.svg";
import { ReactComponent as WalmartIcon } from "assets/icons/supplier-logo-icons/walmart.svg";
import Tooltip from "components/Tooltip";
import { FC, useId } from "react";

interface Props {
  type: "vero" | "risk" | "dropshipper" | "amazondropship" | "walmartdropship" | "alidropship";
  wrapperClassName?: string;
}
const ProductNameIcon: FC<Props> = ({ type, wrapperClassName }) => {
  const anchorId = useId();
  const styles = {
    vero: {
      className: "border-errorRed",
      iconClassName: "fill-errorRed w-3.5 h-[13px] top-[1px]",
      title: "VeRO Brand.",
    },
    risk: {
      className: "border-yellow ",
      iconClassName: "fill-yellow w-3.5 h-[13px] top-[1px]",
      title: "Restricted Keyword",
    },
    dropshipper: {
      className: "border-electricBlue ",
      iconClassName: "fill-electricBlue w-[15.5px] h-[13.31px] -translate-y-1/2 top-1/2 color-electricBlue",
      title: "Dropshipper",
    },
    amazondropship: {
      className: "!border-0",
      iconClassName: "",
      title: "Amazon Dropshipper",
    },
    walmartdropship: {
      className: "!border-0",
      iconClassName: "",
      title: "Walmart Dropshipper",
    },
    alidropship: {
      className: "!border-0",
      iconClassName: "",
      title: "AlieExpress Dropshipper",
    }
  };

  const iconStyles = `${styles[type].iconClassName} absolute left-1/2 -translate-x-1/2  `

  return (
    <div
      className={`w-5 h-5 border-[1px] rounded-full relative ${styles[type].className} ${wrapperClassName}`}
      data-tooltip-id={anchorId}
    >
      {type === "dropshipper" ? (
        <Dropshipper
          className={iconStyles}
          color="electricBlue"
        />
      ) :
        type === "amazondropship" ?
          (<AmazonIcon />) :
          type === "walmartdropship" ?
            (<WalmartIcon />)
            :
            type === "alidropship" ?
              (<AliIcon />)
              :
              (
                <Error className={iconStyles} />
              )}
      <Tooltip anchorId={anchorId} title={styles[type].title} />
    </div>
  );
};

export default ProductNameIcon;
