import { ReactComponent as Smiley } from "assets/images/smiley.svg";
import Button from "components/Button";
import useDecodeToken from "hooks/useDecodeToken";
import { FC } from "react";

interface Props {
    contStyles: string;
    onGoClick: () => void;
}
const WelcomeStep: FC<Props> = ({ contStyles, onGoClick }) => {
    const userDetails = useDecodeToken();
    const firstName = userDetails.unique_name.split(" ")[0] || ""
    return (
        <div className={`${contStyles} flex flex-col justify-between max-lg:items-between max-lg:h-[calc(100vh-160px)] max-sm:h-[calc(100vh-72px)] lg:pt-10 lg:pb-[42px] relative`}>
            <div>
                <Smiley className="mx-auto w-[55px] h-[55px] max-sm:w-[48px] max-sm:h-[48px] mb-[27px] max-lg:mb-11 max-sm:mb-4" />
                <h1 className="max-lg:text-[36px] text-2xl max-sm:text-xl text-center font-bold text-darkBlue dark:text-grey100 mb-2.5 max-lg:mb-[32px]">
                    Welcome, <span className="capitalize">{` ${firstName}!`}</span>
                </h1>
                <h2 className="text-base max-lg:text-xl max-sm:text-base text-center text-darkBlue dark:text-grey100 mb-[43px] max-lg:mb-[0px]">
                    You're a few steps away from the full ZIK experience. <br className="max-sm:hidden" />
                    Let's tailor your experience and activate your account!
                </h2>
            </div>
            <Button title="Let’s Go!" className="max-w-[214px] max-lg:max-w-[100%] mx-auto max-sm:fixed max-sm:bottom-[32px] max-sm:max-w-[330px]" height="h-16 max-sm:h-14" titleClassName="!text-base" handleClick={onGoClick} />
        </div>
    )
}

export default WelcomeStep