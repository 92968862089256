import AutumnPrep from "assets/icons/calendar/AutumnPrep.png";
import BlackFriday from "assets/icons/calendar/BlackFriday.png";
import BookDay from "assets/icons/calendar/BookDay.png";
import ChineseNewYear from "assets/icons/calendar/ChineseNewYear.png";
import Christmas from "assets/icons/calendar/Christmas.png";
import CyberMonday from "assets/icons/calendar/CyberMonday.png";
import EasterSunday from "assets/icons/calendar/EasterSunday.png";
import FathersDay from "assets/icons/calendar/FathersDay.png";
import Halloween from "assets/icons/calendar/Halloween.png";
import IndependenceDay from "assets/icons/calendar/IndependenceDay.png";
import Juneteenth1 from "assets/icons/calendar/Juneteenth1.png";
import Juneteenth2 from "assets/icons/calendar/Juneteenth2.png";
import KidsReturnSchool from "assets/icons/calendar/KidsReturnSchool.png";
import KingCharles from "assets/icons/calendar/KingCharles.png";
import MemorialDay from "assets/icons/calendar/MemorialDay.png";
import MothersDay1 from "assets/icons/calendar/MothersDay1.png";
import MothersDay2 from "assets/icons/calendar/MothersDay2.png";
import NewYearDay from "assets/icons/calendar/NewYearDay.png";
import PetDay from "assets/icons/calendar/PetDay.png";
import SpringPrep from "assets/icons/calendar/SpringPrep.png";
import StPatrickDay from "assets/icons/calendar/StPatrickDay.png";
import SummerPrep from "assets/icons/calendar/SummerPrep.png";
import SuperbowlSunday from "assets/icons/calendar/SuperbowlSunday.png";
import Thanksgiving from "assets/icons/calendar/Thanksgiving.png";
import ValentinesDay from "assets/icons/calendar/ValentinesDay.png";
import WineDay from "assets/icons/calendar/WineDay.png";
import WomenDay from "assets/icons/calendar/WomenDay.png";

const getEventIcon = (eventName: string, calendarLang: "US" | "UK") => {
  switch (eventName) {
    // Month 1
    case "New Year Day":
      return NewYearDay;

    case "Chinese New Year":
      return ChineseNewYear;

    // Month 2
    case "Superbowl Sunday":
      return SuperbowlSunday;

    case "Valentine's Day":
      return ValentinesDay;

    case "Spring Preparations":
      return SpringPrep;

    // Month 3
    case "Book Day":
      return BookDay;

    case "International Women's Day":
      return WomenDay;

    case "St. Patrick's Day":
      return StPatrickDay;

    case "Mother's Day":
      return calendarLang === "UK" ? MothersDay1 : MothersDay2;

    // Month 4
    case "Summer Preparations":
      return SummerPrep;

    case "Easter":
      return EasterSunday;

    case "National Pet Day":
      return PetDay;

    // Month 5
    case "Coronation of King Charles":
      return KingCharles;

    case "National Wine Day":
      return WineDay;

    case "Memorial Day":
      return MemorialDay;

    // Month 6
    case "Father's Day":
      return FathersDay;

    case "Juneteenth":
      return { first: Juneteenth1, second: Juneteenth2 };

    // Month 7
    case "Independence Day":
      return IndependenceDay;

    // Month 8
    case "Autumn/Winter Preparations":
      return AutumnPrep;

    // Month 9
    case "Kids' Return to School":
      return KidsReturnSchool;

    // Month 10
    case "Halloween":
      return Halloween;

    // Month 11
    case "Thanksgiving":
      return Thanksgiving;

    case "Black Friday":
      return BlackFriday;

    case "Cyber Monday":
      return CyberMonday;

    // Month 12
    case "Christmas":
      return Christmas;

    default:
      return Juneteenth1;
  }
};

export default getEventIcon;
