import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "config/config";
import { queryKeys } from "./queryKeys";

export const testingApi = createApi({
  reducerPath: "testingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  endpoints: (builder) => ({
    yorayReset: builder.mutation<null, null>({
      query: () => ({
        url: queryKeys.test.yorayReset,
        method: "POST",
      }),
    }),
    endTrial: builder.mutation<null, number>({
      query: (param) => ({
        url: queryKeys.test.endTrial + `?userId=${param}`,
        method: "POST",
      }),
    }),
    resetCC: builder.mutation<null, null>({
      query: () => ({
        url: queryKeys.test.resetCC,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useYorayResetMutation,
  useEndTrialMutation,
  useResetCCMutation,
} = testingApi;
