import { ReactComponent as Dollar } from "assets/icons/dollar.svg";
import { ReactComponent as Reviews } from "assets/icons/feedback-icon.svg";
import { ReactComponent as Hammer } from "assets/icons/hammer.svg";
import { ReactComponent as Like } from "assets/icons/like.svg";
import { ReactComponent as Reload } from "assets/icons/reload.svg";
import { ReactComponent as Star } from "assets/icons/star.svg";

import Button from "components/Button";
import Card from "components/Card";
import MinMaxFilter from "components/Filters/MinMaxFilter";
import useDebaunceSearch from "hooks/useDebaunceSearch";
import useFilters from "hooks/useFilters";
import { useEffect, useState } from "react";
import { useGetAliGrowthQuery } from "store/api/zikProApi";
import AliGrowthScannerList from "./components/lists/AliGrowthScanner";

const minMaxStyles = {
  style: "max-sm:w-full max-xl:w-1/3 max-md:w-1/2 !gap-1 mb-1",
  inputWidth: "w-16",
  inputWrapper: "gap-0"
}
const initialFilterState = {
  sales: {
    min: null,
    max: null,
  },
  price: {
    min: null,
    max: null,
  },
  rating: {
    min: null,
    max: null,
  },
  reviews: {
    min: null,
    max: null,
  },
  feedback: {
    min: null,
    max: null,
  },
  searchText: "",
  isFilter: false,
};

const AliGrowthScanner = () => {
  const { filters, handleFilterChange, setFilters } =
    useFilters(initialFilterState);
  const [invalidField, setInvalidField] = useState<any>({})
  const [postedFilters, setPostedFilters] = useState(initialFilterState);
  const debauncedSearch = useDebaunceSearch();
  const { data, isLoading, isFetching } = useGetAliGrowthQuery({
    page: 0,
    minRating: postedFilters.rating.min,
    maxRating: postedFilters.rating.max,
    minPrice: postedFilters.price.min,
    maxPrice: postedFilters.price.max,
    minFeed: postedFilters.feedback.min,
    maxFeed: postedFilters.feedback.max,
    minSales: postedFilters.sales.min,
    maxSales: postedFilters.sales.max,
    minItemVotes: postedFilters.reviews.min,
    maxItemVotes: postedFilters.reviews.max,
    shippingType: false,
    growthRate: 0,
    orderBy: 0,
    searchText: postedFilters.searchText,
    isFilter: postedFilters.isFilter,
  });

  const showClear =
    filters.price.min !== null ||
    filters.price.max !== null ||
    filters.sales.min !== null ||
    filters.sales.max !== null ||
    filters.rating.min !== null ||
    filters.rating.max !== null ||
    filters.reviews.min !== null ||
    filters.reviews.max !== null ||
    filters.feedback.min !== null ||
    filters.feedback.max !== null;

  const onClear = () => {

    setFilters(initialFilterState);
  };

  const handleSearchClick = () => {
    let errors: any = {}
    const {
      rating,
      price,
      feedback,
      sales,
      reviews
    } = filters;
    if (rating.max && rating.min && rating.min > rating.max) {
      errors.rating = true
    }
    if (price.max && price.min && price.min > price.max) {
      errors.price = true
    }
    if (feedback.max && feedback.min && feedback.min > feedback.max) {
      errors.feedback = true
    }
    if (sales.max && sales.min && sales.min > sales.max) {
      errors.sales = true
    }
    if (reviews.max && reviews.min && reviews.min > reviews.max) {
      errors.reviews = true
    }
    setInvalidField(errors)
    if (Object.keys(errors).length === 0) {
      setPostedFilters({ ...filters, isFilter: true });
    }
  };

  useEffect(() => {
    if (postedFilters.searchText !== debauncedSearch.delayedValue) {
      setPostedFilters((prev) => ({
        ...prev,
        searchText: debauncedSearch.delayedValue,
        page: 0,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debauncedSearch.delayedValue]);

  return (
    <div className="flex gap-3 max-xl:flex-col relative ">
      <Card
        className="h-fit pt-[17.5px] pb-[16.5px] px-[13px] py-5 flex xl:flex-col xl:gap-5 max-xl:gap-y-4 flex-wrap xl:sticky xl:top-5 xl:max-w-[164px]"
        shadow={false}
      >
        <MinMaxFilter
          value={filters.sales}
          isInvalid={invalidField.sales}
          tooltipMaxWidth="max-w-[120px]"
          inputWidth={minMaxStyles.inputWidth}
          className={minMaxStyles.style}
          name="Sales"
          title="Sales"
          inputGap="gap-0"
          minInputLimit={10000000}
          maxInputLimit={10000000}
          infoTitle="Total Sales from the last 6 months"
          inputWrapperClassName={minMaxStyles.inputWrapper}
          iconClassName="w-[12.5px] h-[11.67px]"
          Icon={Hammer}
          handleChange={(newVal, type) =>
            handleFilterChange(newVal, "sales", type)
          }
        />
        <MinMaxFilter
          value={filters.price}
          isInvalid={invalidField.price}
          tooltipMaxWidth="max-w-[130px]"
          inputWidth={minMaxStyles.inputWidth}
          inputWrapperClassName={minMaxStyles.inputWrapper}
          className={minMaxStyles.style}
          infoTitle="Filter by minimum/maximum selling price on AliExpress."
          name="Price"
          minInputLimit={10000000}
          maxInputLimit={10000000}
          title="Price"
          inputGap="gap-0"
          iconClassName="w-[11.67px] h-[11.67px]"
          Icon={Dollar}
          handleChange={(newVal, type) =>
            handleFilterChange(newVal, "price", type)
          }
        />
        <MinMaxFilter
          value={filters.rating}
          isInvalid={invalidField.rating}
          tooltipMaxWidth="max-w-[120px]"
          inputWidth={minMaxStyles.inputWidth}
          inputWrapperClassName={minMaxStyles.inputWrapper}
          className={minMaxStyles.style}
          infoTitle="You can filter by the average product rating from customers (between 0 and 5)."
          name="Rating"
          title="Rating"
          inputGap="gap-0"
          iconClassName="w-[13.33px] h-[10px]"
          Icon={Like}
          handleChange={(newVal, type) =>
            handleFilterChange(newVal, "rating", type)
          }
          minInputLimit={5}
          maxInputLimit={5}
        />
        <MinMaxFilter
          value={filters.reviews}
          isInvalid={invalidField.reviews}
          inputWidth={minMaxStyles.inputWidth}
          inputWrapperClassName={minMaxStyles.inputWrapper}
          className={minMaxStyles.style}
          tooltipMaxWidth="max-w-[110px]"
          infoTitle="Reviews are the total amount of reviews left by customers on AliExpress."
          name="Reviews"
          minInputLimit={10000000}
          maxInputLimit={10000000}
          title="Reviews"
          inputGap="gap-0"
          iconClassName="w-[11.67px] h-[11.67px]"
          Icon={Reviews}
          handleChange={(newVal, type) =>
            handleFilterChange(newVal, "reviews", type)
          }
        />
        <MinMaxFilter
          value={filters.feedback}
          isInvalid={invalidField.feedback}
          inputWidth={minMaxStyles.inputWidth}
          inputWrapperClassName={minMaxStyles.inputWrapper}
          className={minMaxStyles.style}
          tooltipMaxWidth="max-w-[125px]"
          infoTitle="Filter by minimum/maximum store feedback on AliExpress."
          name="Feedback"
          minInputLimit={10000000}
          maxInputLimit={10000000}
          title="Feedback"
          inputGap="gap-0"
          iconClassName="w-[12.5px] h-[11.67px]"
          Icon={Star}
          handleChange={(newVal, type) =>
            handleFilterChange(newVal, "feedback", type)
          }
        />
        <div className="max-w-[400px] mx-auto w-full flex flex-col gap-2.5">
          <Button
            title="Clear"
            handleClick={onClear}
            Icon={Reload}
            iconClassName="w-[11.67px] h-[11.67px] my-auto"
            height="h-9"
            contClassName="!gap-2.5"
            className={` ${showClear ? "" : "hidden"}`}
            color="primaryPurple"
          />
          <Button
            title="Search"
            handleClick={handleSearchClick}
            loading={isFetching && !isLoading}
            height="h-9"
            className="dark:!border-lilac400 dark:hover:!border-purple500 dark:hover:!bg-purple500 dark:hover:text-lilac400 dark:bg-deepSpace900"
            color="smoothGreen"
          />
        </div>

      </Card>
      <AliGrowthScannerList
        debauncedSearch={debauncedSearch}
        list={data?.result.items || []}
        isLoading={isFetching}
      />
    </div>
  );
};

export default AliGrowthScanner;
