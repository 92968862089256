import { ReactComponent as BackArrow } from "assets/icons/back-arrow.svg";
import Button from "components/Button";
import Card from "components/Card";
import Header from "components/Header";
import CenteredLoading from "components/Loading/CenteredLoading";
import ExportAll from "components/ProductManagementButtons/ExportAll";
import ExportSelected from "components/ProductManagementButtons/ExportSelected";
import UploadAll from "components/ProductManagementButtons/UploadAll";
import UploadHistory from "components/ProductManagementButtons/UploadHistory";
import UploadSelected from "components/ProductManagementButtons/UploadSelect";
import Toaster from "components/Toast";
import usePagination from "hooks/usePagination";
import useSort, { SortDir } from "hooks/useSort";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PATH } from "routes/path";
import {
  useTurboLoadResultsQuery,
  useTurboScanFiltersQuery
} from "store/api/turboScannerApi";
import { TurboScanFiltersList } from "types/services/autopilot";
import TurboScannerItemList from "./components/lists/TurboScannerItem";
import PrevScanFilter from "./components/ScanFilters";

const filterTitles = {
  supplierType: "Supplier",
  keywords: "Keywords",
  "30DaySales": "30 Days Sales",
  reviewCount: "Review Count",
  price: "Price",
  rating: "Minimum Rating",
  bsr: "BSR",
  includeBooks: "Include Books",
  includeDVDs: "Include DVDs",
  productsCount: "No. Products",
  onlyPrime: "Prime Only",
  categories: "Categories",
};

const TurboScannerItem = () => {
  const { id } = useParams();
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState<TurboScanFiltersList[]>([]);
  const pagination = usePagination(total, 100);
  const [searchParams, setSearchParams] = useSearchParams();
  const [uploadProgress, setUploadProgress] = useState(false);
  const onUploadProgress = () => setUploadProgress(true);
  const onUploadSuccess = () => setUploadProgress(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const { data: scanFilters, isFetching: scanFiltersLoading } =
    useTurboScanFiltersQuery(id || "");
  const toasterShown = useRef(false);
  const { sort, handleSort, isAsc } = useSort({
    direction: SortDir.des,
    column: "5",
  });
  const navigate = useNavigate();

  const { data, isFetching, error } = useTurboLoadResultsQuery({
    historyId: Number(id) || 0,
    startPage: pagination.current + 1,
    pageLength: 100,
    userId: 0,
    sortDirection: isAsc ? "asc" : "desc",
    orderBy: Number(sort.column),
  });
  const filtersData = scanFilters?.result || [];
  useEffect(() => {
    if (
      searchParams &&
      searchParams.get("autods") === "1" &&
      !toasterShown.current
    ) {
      toast.success("AutoDs Store successfully Added");
      setSearchParams({});
      toasterShown.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    // @ts-ignore
    if (error?.data === "scan not exists") {
      navigate(PATH.zikPro.turboScanner.root);
    }
    if (data) {
      setTotal(data.result.totalRow || 0);
    }
  }, [data, error]);


  useEffect(() => {
    if (scanFilters?.result) {
      const supplierFilter = filtersData.find(f => f.filterName === "supplierType");
      const supplier = supplierFilter?.filterValue[0];
      const isWalmart = supplier === "Walmart"
      const filteredFilters = filtersData
        .filter((item) => {
          if (item.filterName === "categories" && item.filterValue.length < 1) {
            return false;
          } else if (item.filterName === "keywords" && item.filterValue[0] === "") {
            return false;
          } else if (
            (item.filterName === "30DaySales" ||
              item.filterName === "rating" ||
              item.filterName === "productsCount") &&
            item.filterValue[0] === ""
          ) {
            return false;
          } else if (
            (item.filterName === "reviewCount" ||
              item.filterName === "price" ||
              item.filterName === "bsr") &&
            item.filterValue[0] === "" &&
            item.filterValue[1] === ""
          ) {
            return false;
          } else if (
            item.filterName === "includeBooks" ||
            item.filterName === "includeDVDs" ||
            item.filterName === "onlyPrime"
          ) {
            return false;
          }
          else if (item.filterName === "bsr" && isWalmart) {
            return false;
          }
          else {
            return true;
          }
        })
        .map((item) => {
          let { filterValue } = item;

          // Check if the filterName is "rating" and add "Rating" to each value
          if (item.filterName === "rating") {
            filterValue = filterValue.map((value) => {
              const intValue = parseInt(value as string); // Convert value to an integer
              if (!isNaN(intValue)) {
                return intValue > 1 || intValue === 0 ? intValue + " stars" : intValue + " star";
              } else {
                return "";
              }
            });
          }

          return {
            filterName:
              filterTitles[item.filterName as keyof typeof filterTitles],
            filterValue,
          };
        });

      const filteredInclusions = filtersData
        .filter(
          (item) =>
            (item.filterName === "includeBooks" ||
              item.filterName === "includeDVDs" ||
              item.filterName === "onlyPrime") &&
            item.filterValue[0] !== "0"
        )
        .map((item) => item.filterName);

      setFilters([
        ...(filteredInclusions.length > 0
          ? [
            {
              filterName: "",
              filterValue: [
                ...(filteredInclusions.includes("includeBooks") && !isWalmart
                  ? ["Include Books"]
                  : []),
                ...(filteredInclusions.includes("includeDVDs") && !isWalmart
                  ? ["Include DVD/CDs"]
                  : []),
                ...(filteredInclusions.includes("onlyPrime") && !isWalmart
                  ? ["Prime Only"]
                  : []),
                ...(filteredInclusions.includes("onlyPrime") && isWalmart
                  ? ["Sold by Walmart"]
                  : []),
              ] as string[],
            },
          ]
          : []),
        ...filteredFilters,
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersData]);


  return (
    <>
      <Header
        leftContent={
          <Button
            color="primaryPurple"
            title="Return to Scans"
            height="h-9.5"
            iconClassName="w-3.5"
            className="max-w-[170px]"
            StartIcon={BackArrow}
            isLink
            redirect={PATH.zikPro.turboScanner.root}
          />
        }
        leftContentClassName="w-full"
      />
      <Card className="mb-5 py-5 px-6">
        <CenteredLoading
          loading={scanFiltersLoading}
          contMinHeight="min-h-[100px]"
        >
          <div className="grid grid-cols-9 max-lg:grid-cols-5 max-sm:grid-cols-1 gap-y-4.5 mb-[26px] max-md:grid-cols-2">
            <PrevScanFilter filtersData={filters} />
          </div>
        </CenteredLoading>

        <div className="flex items-center justify-start gap-10 flex-wrap max-[1229px]:gap-5">
          <UploadAll type="Turbo" onUploadProgress={onUploadProgress} />
          <UploadSelected
            type="Turbo"
            selectedItems={selectedItems}
            onUploadProgress={onUploadProgress}
            allChecked={false}
          />
          <ExportAll type="Turbo" />
          <ExportSelected
            selectedItems={selectedItems}
            type="Turbo"
            allChecked={false}
          />
          <UploadHistory
            type="Turbo"
            uploadProgress={uploadProgress}
            onUploadSuccess={onUploadSuccess}
          />
        </div>
      </Card>
      <TurboScannerItemList
        sort={sort}
        data={data?.result.getTurboScanResultsWithCurencies || []}
        pagination={pagination}
        isLoading={isFetching}
        changeSelectedItems={(selectedIds) => setSelectedItems(selectedIds)}
        handleSort={handleSort}
        selectedIds={selectedItems}
      />
      <Toaster />
    </>
  );
};

export default TurboScannerItem;
