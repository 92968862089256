import { ReactComponent as Trophy } from "assets/icons/trophy.svg";
import Dots from "components/Dots";
interface UpgradeProps {
    isUpgrade?: boolean;
    isDowngrade?: boolean;
    isChanged?: boolean;
    isRegistration?: boolean;
}
const Upgrade: React.FC<UpgradeProps> = ({ isUpgrade = false, isChanged = false, isDowngrade = false, isRegistration = false }) => (
    <div className="min-h-screen bg-white flex items-center justify-center dark:bg-black900">
        <div className="text-center">
            {isDowngrade ? "" : (
                <div>
                    <Trophy className="w-8 h-8 mx-auto mb-[39px]" />
                    <h2 className="text-[28px] text-darkBlue dark:text-grey100 font-bold">Congratulations!</h2>
                </div>
            )}
            <p className="text-[28px] text-darkBlue dark:text-grey100 font-medium">
                You’ve {isDowngrade ? "downgraded" : `successfully ${isChanged
                    ? "changed"
                    : isUpgrade
                        ? "upgraded"
                        : isRegistration
                            ? "activated"
                            : "activated"
                    }`} your ZIK Analytics package
            </p>
            <p className="text-xl text-darkBlue dark:text-grey100 font-medium mt-[39px]">We're now redirecting you to your dashboard</p>
            <Dots />
        </div>
    </div>
);

export default Upgrade;