import { MARKET_PLACES } from "types/sellerSettings";

export const settingsFlags = [
  "US",
  "UK",
  "DE",
  "FR",
  "CA",
  "AU",
  "IT",
  "ES",
  "MY",
];

export const quickSettingsEbaySites = [
  { value: ".com", text: "eBay.com" },
  { value: ".co.uk", text: "eBay.co.uk" },
  { value: ".de", text: "eBay.de" },
  { value: ".com.au", text: "eBay.com.au" },
  { value: ".fr", text: "eBay.fr" },
  { value: ".it", text: "eBay.it" },
  { value: ".es", text: "eBay.es" },
  { value: ".ca", text: "eBay.ca" },
  { value: ".com.my", text: "eBay.com.my" },
];

export const quickSettingsToCountry = [
  { value: "US", text: ".com" },
  { value: "UK", text: ".co.uk" },
  { value: "DE", text: ".de" },
  { value: "AU", text: ".com.au" },
  { value: "FR", text: ".fr" },
  { value: "IT", text: ".it" },
  { value: "ES", text: ".es" },
  { value: "CA", text: ".ca" },
  { value: "MY", text: ".com.my" },
  { value: "AliExpress", text: "AliExpress" },
  { value: "shopify", text: "shopify" },
];

export const quickSettingsMarketplace = [
  { value: "eBay", text: "eBay" },
  { value: "shopify", text: "shopify" },
  { value: "AliExpress", text: "AliExpress" },
  { value: MARKET_PLACES.none, text: "Amazon Soon!" },
];

export const settingsSourceAmazonSites = [
  { text: "amazon.com", value: "US" },
  { text: "amazon.co.uk", value: "UK" },
  { text: "amazon.de", value: "DE" },
  { text: "amazon.fr", value: "FR" },
  { text: "amazon.ca", value: "CA" },
  { text: "amazon.com.au", value: "AU" },
  { text: "amazon.it", value: "IT" },
];

export const settingsSourceWebsites = [
  { text: "Amazon", value: "Amazon" },
  { text: "AliExpress.com", value: "Ali" },
  { text: "cjdropshipping.com", value: "Cjd" },
  { text: "Alibaba.com", value: "Baba" },
];

export const ebaySettingsSourceWebsites = [
  { text: "Amazon", value: "Amazon" },
  { text: "Walmart.com", value: "WAL" },
  { text: "AliExpress.com", value: "Ali" },
  { text: "cjdropshipping.com", value: "Cjd" },
  { text: "Alibaba.com", value: "Baba" },
];

export const shopifySettingsSourceWebsites = [
  { text: "AliExpress.com", value: "Ali" },
  { text: "Alibaba.com", value: "Baba" },
];

export const settingsShippingLocation = [
  { text: "All Locations", value: "Worldwide" },
  { text: "United States", value: "US" },
  { text: "United Kingdom", value: "GB" },
  { text: "Canada", value: "CA" },
  { text: "Australia", value: "AU" },
  { text: "China", value: "CN" },
  { text: "France", value: "FR" },
  { text: "Germany", value: "DE" },
  { text: "Italy", value: "IT" },
  { text: "Spain", value: "ES" },
  { text: "Malaysia", value: "MY" },
];
