import "./dots.css";
const Dots = () => {
    const classNames = "inline-block mr-[10px] w-[12px] h-[12px] rounded-full";

    return (
        <div className="mt-[39px]">
            <div className="flex justify-center items-center"
            >
                <div className={classNames}
                    style={{
                        backgroundColor: '#EBF4FE',
                        animation: 'loading 1s infinite alternate',
                        animationDelay: '0.4s',
                    }}
                />
                <div className={classNames}
                    style={{
                        backgroundColor: "#6831FF",
                        animation: 'loading 1s infinite alternate',
                        animationDelay: '0.2s',
                    }}
                />
                <div className={classNames}
                    style={{
                        backgroundColor: '#EBF4FE',
                        animation: 'loading 1s infinite alternate',
                    }}
                />
            </div>
        </div>
    )
};

export default Dots;