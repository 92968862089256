import { ColumnDef } from "@tanstack/react-table";
import { ReactComponent as Box } from "assets/icons/boxes/box.svg";
import IconFinderBox from "components/IconFinderBox";
import ProductNameCell from "components/ProductNameCell";
import TableCheckbox from "components/Table/TableCheckbox";
import { CURRENCY } from "types/currency";
import { ScanResultList as List } from "types/services/autopilot";
import { IconFinderItem } from "types/services/iconFinder";
import { formatCurrency } from "utils/formatCurrency";
export const autopilotScanCols = (
  handleSort: (column: string) => void
): ColumnDef<List>[] => [
    {
      header: ({ table }) => (
        <TableCheckbox
          {...{
            checked: table.getIsAllPageRowsSelected(),
            indeterminate: table.getIsSomePageRowsSelected(),
            onChange: table.getToggleAllPageRowsSelectedHandler(),
          }}
        />
      ),
      minSize: 50,
      accessorKey: "autoScanDetailId",
      cell: ({ row }) => (
        <TableCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
      enableSorting: false,
      meta: {
        alignHead: "justify-between"
      }
    },
    {
      header: "eBay Photo",
      accessorKey: "ebayImage",
      minSize: 100,
      cell: (img) => (
        <div className="relative w-[80px]">
          <ProductNameCell
            img={String(img.getValue())}
            imgWrapperClassName="ml-0"
            height="77px"
            width="68px"
            maxHeight="max-h-[77px]"
          />
        </div>
      ),
      enableSorting: false,
    },
    {
      header: "Supplier Photo",
      accessorKey: "supplierImage",
      minSize: 100,
      cell: (img) => (
        <div className="w-[80px]">
          <ProductNameCell
            img={String(img.getValue())}
            imgWrapperClassName="ml-2"
            height="77px"
            width="68px"
            maxHeight="max-h-[77px]"
          />
        </div>
      ),
      enableSorting: false,
    },
    {
      header: "eBay Title",
      minSize: 300,
      accessorKey: "ebayTitle",
      cell: ({ row: { original } }) => (
        <ProductNameCell
          name={original.ebayTitle}
          checkKeywords
          cutLongTitle={150}
          url={original.ebayLink}
          className="px-[10px]"
        />
      ),
      enableSorting: false,
      meta: {
      }
    },
    {
      header: "Supplier Title",
      accessorKey: "suppliertitle",
      minSize: 300,
      cell: ({ row: { original } }) => (
        <ProductNameCell
          name={original.suppliertitle}
          cutLongTitle={150}
          checkKeywords
          url={original.supplierLink}
          className="px-[10px]"
        />
      ),
      enableSorting: false,
    },
    {
      header: "eBay Price",
      minSize: 100,

      accessorKey: "ebayPrice",
      cell: ({ row: { original } }) =>
        formatCurrency(
          String(original.ebayPrice),
          original.supplier === "UK" ? CURRENCY.pound : CURRENCY.dollar
        ),
      meta: {
        customSort: () => handleSort("EbayPrice"),
        sortColumnId: "EbayPrice",
      },
    },
    {
      header: "Supplier Price",
      minSize: 100,
      accessorKey: "supplierPrice",
      cell: ({ row: { original } }) =>
        formatCurrency(
          String(original.supplierPrice),
          original.supplier === "UK" ? CURRENCY.pound : CURRENCY.dollar
        ),
      meta: {
        customSort: () => handleSort("SupplierPrice"),
        sortColumnId: "SupplierPrice"
      },
    },
    {
      header: "Sell Through",
      minSize: 100,
      accessorKey: "sellthrough",
      cell: (sellThrough) => `${String(sellThrough.getValue())}%`,
      meta: {
        customSort: () => handleSort("Sellthrough"),
        sortColumnId: "Sellthrough"
      },
    },
    {
      header: "Success Rate",
      minSize: 100,
      accessorKey: "sucessRate",
      cell: (rate) => `${String(rate.getValue())}%`,
      meta: {
        customSort: () => handleSort("SucessRate"),
        sortColumnId: "SucessRate",
        alignHead: "justify-between",
      },
    },
    {
      header: "Sales",
      accessorKey: "sales",
      meta: {
        customSort: () => handleSort("Sales"),
        sortColumnId: "Sales",
        alignHead: "justify-between",
        align: "justify-center"
      },
    },
    {
      header: "Competition",
      accessorKey: "compition",
      minSize: 100,
      meta: {
        customSort: () => handleSort("Compition"),
        sortColumnId: "Compition",
        alignHead: "justify-between",
      },
    },
    {
      header: "Profit",
      accessorKey: "profit",
      cell: ({ row: { original } }) =>
        formatCurrency(
          String(original.profit),
          original.supplier === "UK" ? CURRENCY.pound : CURRENCY.dollar
        ),
      minSize: 60,
      meta: {
        customSort: () => handleSort("Profit"),
        sortColumnId: "Profit",
        alignHead: "justify-between",
      },
    },
    {
      header: "Profit %",
      accessorKey: "roi",
      minSize: 100,
      meta: {
        customSort: () => handleSort("Roi"),
        sortColumnId: "Roi",
        alignHead: "justify-between",
      },
    },
  ];

export const autopilotAliScanCols = (
  openIconFinder: (item: IconFinderItem) => void,
  handleSort: (column: string) => void
): ColumnDef<List>[] => [
    {
      header: ({ table }) => (
        <TableCheckbox
          {...{
            checked: table.getIsAllPageRowsSelected(),
            indeterminate: table.getIsSomePageRowsSelected(),
            onChange: table.getToggleAllPageRowsSelectedHandler(),
          }}
        />
      ),
      minSize: 50,
      accessorKey: "autoScanDetailId",
      cell: ({ row }) => (
        <TableCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
      enableSorting: false,
    },
    {
      header: "eBay Photo",
      accessorKey: "ebayImage",
      minSize: 100,
      cell: (img) => (
        <div className="relative w-[80px]">
          <ProductNameCell
            img={String(img.getValue())}
            imgWrapperClassName="ml-0"
            height="77px"
            width="68px"
            maxHeight="max-h-[77px]"
          />
        </div>
      ),
      enableSorting: false,
    },
    {
      header: "eBay Title",
      minSize: 110,
      accessorKey: "ebayTitle",
      cell: ({ row: { original } }) => (
        <ProductNameCell
          name={original.ebayTitle}
          checkKeywords
          url={original.ebayLink}
          className="px-[10px]"
        />
      ),
      //<p className="ml-2">{String(title.getValue())}</p>,
      // minWidth: "70px",
      enableSorting: false,
    },
    {
      header: "eBay Price",
      minSize: 100,

      accessorKey: "ebayPrice",
      cell: ({ row: { original } }) =>
        formatCurrency(
          String(original.ebayPrice),
          original.supplier === "UK" ? CURRENCY.pound : CURRENCY.dollar
        ),
      meta: {
        customSort: () => handleSort("EbayPrice"),
        sortColumnId: "EbayPrice"
      },
    },
    {
      header: "Sell Through",
      minSize: 100,
      accessorKey: "sellthrough",
      cell: (sellThrough) => `${String(sellThrough.getValue())}%`,
      meta: {
        customSort: () => handleSort("Sellthrough"),
        sortColumnId: "Sellthrough"
      },
    },
    {
      header: "Success Rate",
      minSize: 100,
      accessorKey: "sucessRate",
      cell: (rate) => `${String(rate.getValue())}%`,
      meta: {
        customSort: () => handleSort("SucessRate"),
        sortColumnId: "SucessRate",
        alignHead: "justify-between"
      },
    },
    {
      header: "Sales",
      accessorKey: "sales",
      meta: {
        customSort: () => handleSort("Sales"),
        sortColumnId: "Sales",
        alignHead: "justify-between",
      },
    },
    {
      header: "Competition",
      accessorKey: "compition",
      minSize: 100,
      meta: {
        customSort: () => handleSort("Compition"),
        sortColumnId: "Compition",
        alignHead: "justify-between"
      },
    },
    {
      header: () => <Box className="fill-darkBlue dark:fill-grey100" />,
      enableSorting: false,
      minSize: 50,
      accessorKey: "productUrl",
      cell: ({ row: { original } }) => (
        <IconFinderBox
          openIconFinder={openIconFinder}
          isGreen={false}
          isSimilar={false}
          sales={original.sales}
          currentPrice={original.ebayPrice}
          title={original.ebayTitle}
          itemID={original.ebayId}
          images={original.ebayImage}
          productUrl={original.ebayLink}
          alwaysShowAli={true}
        />
      ),
      meta: {
        tooltipTitle:
          "Use the item finder to locate products from your chosen supplier. To switch suppliers, go to Quick Settings on the left-side menu",
      },
    },
  ];
