import OkayEmoji from "assets/images/okay-emoji.png";
import Button from "components/Button";
import { FC } from "react";

interface Props {
    onUpgrade: () => void;
    onCancel: () => void;
}
const SaveUptoHalf: FC<Props> = ({ onUpgrade, onCancel }) => (
    <div className="flex flex-col items-center w-full pb-2">
        <h2 className="text-darkBlue dark:text-grey100 text-center text-2xl font-medium mb-4">
            Did you know that if you upgrade to an Annual Plan,<br />
            you could <span className="text-primaryPurple dark:text-purple500 font-semibold">save up to 50% every year?</span>
        </h2>
        <img src={OkayEmoji} alt="" className="text-center mb-[19px] w-10 h-10" />
        <div className="flex gap-[35px] w-full max-w-[535px]">
            <div className="rounded-full w-full" style={{ boxShadow: "0px 0px 1.02411px 0px #8FFFF2, 0px 0px 2.04821px 0px #8FFFF2, 0px 0px 7.16874px 0px #8FFFF2, 0px 0px 14.33749px 0px #8FFFF2, 0px 0px 24.57855px 0px #8FFFF2, 0px 0px 43.01246px 0px #8FFFF2" }}>
                <Button
                    title="Upgrade my Plan!"
                    height="h-16"
                    className="border-smoothGreen hover:bg-smoothGreen hover:border-smoothGreen dark:border-paradiseBlue dark:hover:bg-paradiseBlue dark:hover:border-paradiseBlue"
                    titleClassName="!text-base font-medium"
                    handleClick={onUpgrade}
                />
            </div>
            <Button title="Cancel" height="h-16" className="border-neutral3 text-neutral3 dark:bg-deepSpace900" titleClassName="!text-base font-medium" handleClick={onCancel} />
        </div>
    </div>
)

export default SaveUptoHalf