import { useAppSelector } from "hooks/useAppSelector";
import useDecodeToken from "hooks/useDecodeToken";
import RegistrationFunnel from "pages/RegistrationFunnel";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import isPaymentPath from "utils/isPaymentPath";
import Sidebar from "./sidebar";


interface Props {
  title?: string;
}
const DashboardLayout: FC<Props> = () => {
  const { isDarkMode, isExpand } = useAppSelector((state) => state.themeSlice);
  const User = useDecodeToken();
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const isAffiliate = environment === "affiliate" ? true : false;
  const isReg = User.journey_completed.toLowerCase() === "false" && !isPaymentPath() && !isAffiliate

  const getLayout = () => {
    if (isReg) return <RegistrationFunnel />;

    return (
      <div className="w-full min-h-screen dark:bg-grey900">
        <Sidebar />
        <main className={`w-full pr-4 mt-5 mb-5 duration-[300ms] ${isExpand ? "pl-[222px]" : "pl-[85px]"} `}>
          <Outlet />
        </main>
      </div>
    );
  };

  return <div className={`flex min-h-full ${isDarkMode ? "dark" : ""}`}>{getLayout()}</div>;
};

export default DashboardLayout;
