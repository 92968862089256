import { ReactComponent as LeftArrow } from "assets/icons/arrow-left.svg";
import { FC } from "react";
import ReactDatePicker, {
  ReactDatePickerCustomHeaderProps
} from "react-datepicker";
import "styles/datePicker.css";


const CustomHeader: FC<ReactDatePickerCustomHeaderProps> = ({
  date,
  decreaseMonth,
  increaseMonth,
}) => (
  <div className="flex w-full justify-between items-center">
    <button className="p-1" onClick={decreaseMonth}>
      <LeftArrow
        className={`fill-darkBlue hover:fill-primaryPurple dark:hover:fill-purple500 ml-4 w-2`}
      />
    </button>
    <p className="text-primaryPurple dark:text-purple500 text-base font-semibold">
      {date.toDateString().substring(3, 7) + date.toDateString().substring(10)}
    </p>
    <button className="p-1" onClick={increaseMonth}>
      <LeftArrow
        className={`fill-darkBlue rotate-180 mr-4  hover:fill-primaryPurple dark:hover:text-purple500 w-2`}
      />
    </button>
  </div>
);

interface Props {
  value: Date | null;
  handleChange: (newDate: Date | null) => void;
  maxDate?: Date;
  className?: string;
}
const DatePicker: FC<Props> = ({ value, handleChange, maxDate, className }) => {
  const handleRawChange = (e: any) => {
    const inputValue = e.target.value;
    const validInputRegex = /^$|^\/?(\d+\/?)*$/;
    if (!(validInputRegex.test(inputValue))) {
      e.preventDefault();
    }
  };

  return (
    <div className={`relative after:content-[''] dark:after:bg-grey100 after:absolute after:bottom-0 after:left-0 after:w-full after:h-[2px] after:rounded-full after:bg-darkBlue hover:after:bg-primaryPurple dark:hover:after:bg-purple500`}>
      <ReactDatePicker
        calendarClassName="!shadow-2xl"
        className={`text-center focus:outline-none pb-1  dark:bg-transparent ${className}`}
        maxDate={maxDate}
        dateFormat="dd/MM/yyyy"
        placeholderText="dd/mm/yyyy"
        renderCustomHeader={CustomHeader}
        selected={value}
        onChange={(date) => handleChange(date)}
        onChangeRaw={handleRawChange}
      />
    </div>
  )
};

export default DatePicker;
