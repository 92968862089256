import Button from "components/Button";
import FilterCheckbox from "components/Filters/FilterCheckbox";
import Modal from "components/Modal";
import Scrollbar from "components/Scrollbar";
import { autopilotFilterState } from "../lists/Autopilot";

// Icons
import { ReactComponent as Close } from "assets/icons/clear-input.svg";
import useModal from "hooks/useModal";

interface Props {
  filters: typeof autopilotFilterState;
  helpers: ReturnType<useModal>;
  filterHelpers: ReturnType<useModal>;
  categories: any;
  handleListChange: (
    newValue: string | number,
  ) => void;
}

const Categories = ({
  helpers,
  filterHelpers,
  filters,
  categories,
  handleListChange,
}: Props) => (
  <Modal
    helpers={helpers}
    className="flex flex-col items-center "
    cardClassName="py-7"
    maxWidth="max-w-[636px]"
    title="Select Category"
    buttonCloseClassName="!hidden"
  >
    <button
      className="absolute top-8 p-1 right-8 group"
      onClick={() => {
        helpers.close();
        filterHelpers.open();
      }}
    >
      <Close className="fill-darkBlue dark:fill-grey100 w-3 group-hover:fill-primaryPurple dark:group-hover:fill-purple500" />
    </button>
    <Scrollbar
      className={`max-h-[548px] flex justify-center gap-2.5 mr-3 pr-3 mb-6 pl-8 max-md:flex-col`}
    >
      <div className="w-full flex gap-[9px] flex-col">
        <FilterCheckbox
          options={[{ text: "Select All", value: "all" }]}
          value={categories?.length === filters.categories.length ? "all" : ""}
          className="w-full"
          handleChange={(newValue: string | number) => {
            handleListChange(newValue.toString());
          }}
        />
        <FilterCheckbox
          options={categories.slice(0, categories.length / 2)}
          multiselect
          value={filters.categories}
          className="w-full !gap-[9px]"
          handleChange={(newValue: string | number) =>
            handleListChange(newValue)
          }
        />
      </div>
      <FilterCheckbox
        options={categories.slice(categories.length / 2)}
        multiselect
        value={filters.categories}
        className="w-full !gap-[9px]"
        handleChange={(newValue: string | number) =>
          handleListChange(newValue)
        }
      />
    </Scrollbar>
    <div className="w-full flex justify-center">
      <Button
        title="Save"
        handleClick={() => {
          helpers.close();
          filterHelpers.open();
        }}
        color="primaryPurple"
        height="h-9"
        className="max-w-[129px] mx-auto"
      />
    </div>

  </Modal>
);

export default Categories;
