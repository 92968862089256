export const getRescanFilterState = (filters: any) => {
  let includes = [];

  const cats =
    filters.category != null && filters.category !== ""
      ? filters.category.split(",")
      : [];

  filters.onlyPrime === 1 && includes.push("onlyPrime");
  filters.similar && includes.push("includeSimilarItems");

  const rescanFilters = {
    name: filters.name,
    supplier: filters.supplier,
    saleRange: String(filters.dateRange),
    categories: cats as string[],
    inclusions: includes as string[],
    rating: filters.rating,
    reviewCount: {
      min: filters.minTotalReviews,
      max: filters.maxTotalReviews,
    },
    sellerRanking: {
      min: filters.minBsr,
      max: filters.maxBsr,
    },
    competition: {
      min: filters.compitionfrom,
      max: filters.compitionto,
    },
    sales: {
      min: filters.salesFrom,
      max: filters.salesTo,
    },
    price: {
      min: filters.priceFrom,
      max: filters.priceTo,
    },
    profit: {
      min: filters.profitfrom,
      max: filters.profitto,
    },
    roi: {
      min: filters.roiFrom,
      max: filters.roito,
    },
    sellThrough: {
      min: filters.sellThroughFrom,
      max: filters.sellThroughTo,
    },
    successRate: {
      min: filters.sucessRatefrom,
      max: filters.sucessRateto,
    },
    numberOfProducts: {
      max: filters.numberofproduct,
    },
  };

  return rescanFilters;
};
