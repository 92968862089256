import { languageList } from "assets/data/myDashboard";
import Card from "components/Card";
import useModal from "hooks/useModal";
import { FC, useState } from "react";
import { useTranslation } from 'react-i18next';
import { getCountryFlag } from "utils/getCountryFlag";
import { GetSavedLang, SetSavedLang } from "utils/localStorage";
interface Props {
  helpers: ReturnType<useModal>;
}

const ChooseLanguage: FC<Props> = ({ helpers }) => {
  const [selectedLang, setSelectedLang] = useState(Object.values(languageList).find((item) => item.language === GetSavedLang())?.code);
  const { i18n } = useTranslation(['home']);
  const handleCountryChange = (newLang: string, language: string) => {
    SetSavedLang(language);
    setSelectedLang(newLang);
    i18n.changeLanguage(language);



  };

  return helpers.isOpen ? (
    <Card className="min-w-[324px] absolute right-0 px-3.5 py-[9px] z-10 border-primaryPurple dark:border-purple500 border-2">
      <div className="flex w-full justify-between">
        {Object.values(languageList).map((item) => (
          <div
            key={item.code}
            onClick={() => handleCountryChange(item.code, item.language)}
            className={`cursor-pointer ${selectedLang === item.code
              ? "border-b-2 border-b-primaryPurple  dark:boder-b-purple500"
              : ""
              }`}
          >
            <img
              src={getCountryFlag(item.code)}
              alt={`${item.code}-flag`}
              className="h-[38px] max-md:h-5"
            />
          </div>
        ))}
      </div>
    </Card>
  ) : (
    <></>
  );
};

export default ChooseLanguage;
