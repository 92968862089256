import { useSearchParams } from "react-router-dom";

// Components

import UpcomingEvents from "../../components/upcomingEvents";
import ZikProTools from "./components/ZikProTools";

// Icons
import Toaster from "components/Toast";
import { useAppSelector } from "hooks/useAppSelector";
import LoadingPage from "pages/Loading";
import { useEffect, useState } from "react";
import { useGetInsightsPreferencesQuery } from "store/api/newDashboardApi";
import { DASHBOARD_MARKET_PLACES } from "store/slices/marketplaceSlice";
import {
  BUSINESS_MODEL,
  MARKET_PLACES,
  SOURCE_MARKET_PLACES
} from "types/sellerSettings";
import SellerSettings from "./components/SellerSettings";
import TrendingNiches from "./components/TrendingNiches";
import TrendingProducts from "./components/TrendingProducts";
import TrendingSellers from "./components/TrendingSellers";

enum TABS {
  marketInsights = "market-insights",
  myDashboard = "my-dashboard",
}
const NewDashboardContent = () => {
  const { marketplace, businessModel, sourceMarketplace } = useAppSelector(
    (state) => state.sellerSettings
  );
  const [searchParams] = useSearchParams();
  const isMyDashboard = searchParams.get("tab") === TABS.myDashboard;
  const { isFetching, isSuccess, refetch } = useGetInsightsPreferencesQuery((undefined), {
    skip: isMyDashboard,
  });

  const isDropshipper = businessModel === BUSINESS_MODEL.dropshipping;
  const isAliexpress = sourceMarketplace === SOURCE_MARKET_PLACES.aliexpress;
  const isShopify = marketplace === MARKET_PLACES.shopify;
  const isWoocommerce = marketplace === MARKET_PLACES.woocommerce;
  const isMarketplaceAliexpress = marketplace === MARKET_PLACES.aliexpress;
  const { dashboardMarketplace } = useAppSelector((state) => state.marketplaceSlice);
  const [loading, setLoading] = useState(true)
  const [refetchCount, setRefetchCount] = useState(5)

  const trendingNichesTitle =
    isWoocommerce || isShopify
      ? `Trending ${isAliexpress || isMarketplaceAliexpress ? "AliExpress" : "eBay"} Niches`
      : `Trending ${isMarketplaceAliexpress ? "AliExpress" : "eBay"} Niches`;

  const trendingSellersTitle =
    isWoocommerce || isShopify
      ? `Trending ${isAliexpress ? "AliExpress" : "eBay"} Suppliers`
      : isDropshipper
        ? "Trending Dropshippers"
        : businessModel
          ? `Trending eBay Sellers`
          : `Trending ${isMarketplaceAliexpress ? "AliExpress" : ""} Sellers`;

  const subtitle =
    isShopify || isWoocommerce || isMarketplaceAliexpress
      ? "Last 6 Months"
      : "Last 30 Days";


  useEffect(() => {

    if (dashboardMarketplace === DASHBOARD_MARKET_PLACES.aliexpress && !isMarketplaceAliexpress) {
      refetch();
    }
    else if (dashboardMarketplace === DASHBOARD_MARKET_PLACES.ebay && (marketplace === MARKET_PLACES.aliexpress || marketplace === MARKET_PLACES.shopify)) {
      refetch();
    }
  }, [])

  useEffect(() => { // should be replaced with selector
    if (loading && !isFetching && isSuccess) {
      if (refetchCount > 0)
        if (dashboardMarketplace === DASHBOARD_MARKET_PLACES.aliexpress && !isMarketplaceAliexpress) {
          setRefetchCount(refetchCount - 1)
          refetch();
        }
        else if (dashboardMarketplace === DASHBOARD_MARKET_PLACES.ebay && (marketplace === MARKET_PLACES.aliexpress || marketplace === MARKET_PLACES.shopify)) {
          setRefetchCount(refetchCount - 1)
          refetch();
        }
        else {
          setLoading(false)
        }

      else {
        setLoading(false)
      }

    }
  }, [isFetching, isSuccess])

  return (
    <LoadingPage loading={(isFetching || loading) && !isMyDashboard}>
      <div className="flex gap-4 w-full max-xl:flex-wrap">
        <div className="w-[22.36%] max-xl:w-[100%] flex sm:flex-row flex-col xl:flex-col gap-5">
          <TrendingNiches subtitle={subtitle} title={trendingNichesTitle} />
          <TrendingSellers subtitle={subtitle} title={trendingSellersTitle} />
        </div>
        <div className="w-[53.52%]  max-xl:w-[100%] flex flex-col gap-5">
          <SellerSettings />
          <TrendingProducts subtitle={subtitle} />
        </div>
        <div className="w-[24.12%] max-xl:w-[100%] flex flex-col max-md:flex-col max-xl:flex-row gap-5">
          <ZikProTools />
          <UpcomingEvents />
        </div>
      </div>
      <Toaster />
    </LoadingPage>
  );
};

export default NewDashboardContent;
