import MultiScanner from "./components/lists/MultiScanner";
import ScannedSellers from "./components/lists/ScannedSellers";

const BulkScanner = () => (
  <div className="flex gap-[9px] max-xl:flex-col">
    <ScannedSellers />
    <MultiScanner />
  </div>
);

export default BulkScanner;
