export const queryKeys = {
  auth: {
    login: "auth/login",
    requestPassword: "Account/SendPasswordInstructions",
    resetPassword: "/Account/ResetPassword",
  },
  test: {
    yorayReset: "test/reset/paypal/yoray",
    endTrial: "test/trial/end",
    resetCC: "test/reset/cc",
  },
  onboarding: {
    register: "Account/RegisterUser",
    getOnBoardingQuestion: "Account/onboarding/question",
    postSellerType: "Account/onboarding/sellerType",
    postSalesChannel: "Account/onboarding/salesChannel",
    getSalesChannels: "Account/salesChannels",
    postFunnel: "Account/onboarding/funnel",
    postFeedback: "Account/onboarding/feedback",
    postPotentialUser: "Account/PotentialUser",
    getFeedback: "Account/onboarding/feedback",
  },
  banners: {
    impression: "banners/impression",
    close: "banners/close",
    click: "banners/click",
    getUsesRemaining: "/banners/coupon",
  },
  pr: {
    ebay: "productResearch/getProductResearch",
    aliexpress: "aliProductSearch/GetSearchByKeyword",
  },
  cr: {
    getEbay: "CompetitiorResearch/ebay/store-search",
    getHistory: "CompetitiorResearch/history",
    getAli: "competitiorResearch/AliCompetitorResearch",
    loadCompetitors: "competitiorResearch/loadCompetitors",
    saveBulk: "competitiorResearch/saveBulkRequest",
  },
  catRes: {
    get: "categoryResearch/getCategoryResearch",
    getCategory: "categoryResearch/getCategory",
  },
  folders: {
    get: "itemManagement/GetFolders",
    getPreview: "itemManagement/folders/preview",
    getItemList: "myList/GetMyItemList",
    duplicateItem: "itemManagement/DuplicateSelectedItem",
    deleteItem: "itemManagement/SelectedItem",
    deleteFolderItems: "itemManagement/FolderItems",
    editFolderItem: "itemManagement/FolderItem",
    deleteFolder: "itemManagement/DeleteFolder",
    exportFolder: "itemManagement/ExportFolder",
    add: "myList/CreateNewFolder",
    getItem: "itemManagement/GetFoldersItems",
    getFolderItems: "itemManagement/GetFolderItems",
    uploadFolderItems: "itemManagement/UploadFolderItems",
    moveFolderItems: "itemManagement/MoveFolderItems",
    exportFolderItems: "itemManagement/ExportFolderItems",
    folderCount: (listId: number) => `itemManagement/folders/count/${listId}`,
  },
  zikPro: {
    getCompetitionCount: "zikCoin/GetCompetitionCount",
    getAliGrowth: "ZikPro/GetAliGrowthItems",
    getBestSellingItems: "ZikPro/GetBestSellingItems",
    bulkScanner: {
      saveAllItems: "BulkScanner/SaveAllItems",
      scannedSellerList: "BulkScanner/ScannedSellerList",
      multiBulkList: "BulkScanner/MultiBulkScanRequestList",
      deleteScannedSeller: "BulkScanner/RemoveSellerRequest",
      saveBulk: "BulkScanner/SaveBulk",
      editMultiBulk: "BulkScanner/UpdateMultipleRequest",
      deleteMultiBulkSeller: "BulkScanner/RemoveMultipleRequest",
      matchItems: "BulkScanner/MatchItems",
      matchItemsTableData: "BulkScanner/MatchItemsTableData",
      similarItemsTableData: "BulkScanner/SimilarItemTableData",
      notFoundItemTableData: "BulkScanner/NotFoundItemTableData",
    },
    turboScanner: {
      load: "TurboScanner/LoadTurboscanner",
      loadResults: "TurboScanner/LoadTurboScanResults",
      scanHistory: "TurboScanner/GetTurboScanHistory",
      loadHistory: "TurboScanner/LoadScanUploadHistory",
      deleteAllScans: "TurboScanner/DeleteAllScanHistory",
      deleteScan: "TurboScanner/DeleteScanHistory",
      deleteScanItem: "TurboScanner/DeleteSelectedScanItem",
      getScanFilters: "TurboScanner/ScanFilters",
      getPrevScanFilters: "TurboScanner/PreviousScanFilters",
      getCategories: "TurboScanner/GetSuppliersCategoriesByType",
    },
    autopilot: {
      loadScans: "AutoPilot/LoadScans",
      startScan: "AutoPilot/StartScan",
      loadScan: "AutoPilot/loadScan",
      uploadProducts: "AutoPilot/UploadProducts",
      exportProducts: "AutoPilot/ExportProducts",
      loadScanHistory: "AutoPilot/LoadScanUploadHistory",
      deleteAllScans: "AutoPilot/DeleteAllScans",
      deleteScan: "AutoPilot/DeleteScan",
      deleteSelectedScan: "AutoPilot/DeleteSelectedScanItem",
      getScanFilters: "Autopilot/GetScanFilters",
      getPreviousScanFilters: "Autopilot/GetLatestAutoPilotParameters",
    },
  },
  watchList: {
    save: "WatchList/SaveWatch",
    load: "WatchList/Load",
    getById: "WatchList/GetById",
    update: "WatchList/Update",
    delete: "WatchList/Delete",
    ebayCrSave: "CompetitiorResearch/Favourite",
    ebayCrDelete: "CompetitiorResearch/Favourite",
    ebayCrCommentUpdate: "CompetitiorResearch/WatchListComment",
  },
  store: {
    getStores: "store/GetStores",
    updateStore: "store",
    addStore: "store/add",
    getUserStoreDetails: "/store/GetUserStoreDetails",
    loadUserStore: "store/LoadUserStore",
    disconnect: "store/disconnect",
  },
  waitingList: {
    load: "WaitingList/LoadWaitingList",
    delete: "WaitingList/Delete",
    deleteAll: "WaitingList/DeleteList",
    deletePage: "WaitingList/DeletePage",
  },
  iconFinder: {
    saveItemPr: "singleScannedItem/SaveItemPR",
    saveItem: "singleScannedItem/SaveItem",
    addManually: {
      amazon: "thirdParty/findItemByAsin",
      wal: "thirdParty/findItemOnWalmartManually",
    },
    findItems: {
      thirdPartyData: "thirdParty/FindItemOnThirdPartyData",
      amazonData: "DashboardInsight/AmazonItem",
      WAL: "thirdParty/FindItemOnWalmart",
      Cjd: "thirdParty/FindItemsOnCJ",
      Baba: "thirdParty/FindItemOnAliBaba",
      Ali: "thirdParty/FindItemsOnAliExpress",
      aliSalesById: "thirdParty/GetAliSalesById",
      aliseekProduct: "thirdParty/GetAliseekProduct",
    },
  },
  user: {
    isShopifyUser: "user/isshopify",
    shopifySubCount: "user/ShopifySubCount",
    ebaySubCount: "user/EbaySubCount",
    getSettings: "/QuickSettings/GetSettings",
    saveSettings: "/QuickSettings/SaveQuickSettings",
    toggleDarkMode: "QuickSettings/ToggleDarkMode",
    checkPreferences: "User/CheckUserPreferences",
    getUserDetails: "User/GetUserDetails",
    updateSite: "User/UpdateSite",
    getStore: "User/GetStore",
    deleteStore: "User/DeleteStore",
    updateAccountDetails: "User/UpdateAccountDetails",
    updateSearchPreference: "User/UpdateSearchPreference",
    updateCompetitorSetting: "User/UpdateCompetitorSetting",
    updateBreakEven: "User/UpdateBreakEven",
    updateAffiliate: "User/UpdateAffilate",
    updatePassword: "User/UpdatePassword",
    updateStore: "User/UpdateStore",
    getUploadSettings: "User/GetUploadSettings",
    updateSupplier: "User/UpdateSupplierSetting",
    updateStoreSettings: "User/StoreSettings",
    getPlan: "User/GetUserPlan",
    authAutods: "User/AuthAutoDs",
    getBulkStores: "User/GetBulkStores",
    addVatNumber: "User/VatNumber",
  },
  affiliateManager: {
    getUserInvoiceDetails: "Affiliate/GetUserInvoiceDetails",
    getReferralStats: "Affiliate/GetReferralsStats",
    getEarningStats: "Affiliate/GetEarningStats",
    getReferredUser: "Affiliate/GetReferredUser",
    getPaidRequests: "Affiliate/GetPaidRequests",
    getUnPaidRequests: "Affiliate/GetUnPaidRequests",
    getCampaigns: "Affiliate/GetCampaigns",
    getCampaignGraph: "Affiliate/GetCampaignGraph",
    addCampaign: "Affiliate/AddCampaign",
    requestPayout: "Affiliate/RequestPayout",
    changePaypal: "Affiliate/ChangePaypal",
    getPaymentDetails: "Affiliate/GetPaymentRequestDetails",
    generateInvoice: "Affiliate/GenerateInvoice",
  },
  productManagement: {
    uploadProducts: "ProductsManagement/UploadProducts",
    uploadFolderItems: "ItemManagement/UploadFolderItems",
    exportFolderItems: "ItemManagement/ExportFolderItems",
    exportProducts: "ProductsManagement/ExportProducts",
    loadHistory: "UploadHistory/LoadScanUploadHistory",
  },
  dashboard: {
    getEbayWeeklyBestSellers: "Dashboard/GetEbayWeeklyBestSellers",
    getCompetitors: "Dashboard/GetCompetitors",
    getEbayTrends: "Dashboard/GetEbayTrends",
    getStore: "Dashboard/GetStore",
    getChallange: "Dashboard/GetChallenge",
    getStats: "Dashboard/GetStats",
    announcements: "Dashboard/GetAnnouncements",
    deleteAnnouncement: "Dashboard/GetAnnouncementById",
    getUserRelatedInfo: "Dashboard/GetUserRelatedInfo",
    readAnnouncements: "Dashboard/announcements/read",
    unreadAnnouncements: "Dashboard/announcements/unread",
  },
  dashboardInsight: {
    trendingProducts: "DashboardInsight/TrendingProducts",
    insightsPreferences: "DashboardInsight/UserInsightsPreferences",
    favoritesMenu: "DashboardInsight/FavoritesMenu",
    deleteFavoritesMenu: "DashboardInsight/FavoritesMenu",
    saveFavoritesMenu: "DashboardInsight/SaveFavoritesMenu",
    trendingEbayNiches: "DashboardInsight/TrendingeBayNiches",
    trendingEbaySellers: "DashboardInsight/TrendingeBaySeller",
    upcomingEvents: "DashboardInsight/UpcomingEvent",
    amazonItem: "DashboardInsight/AmazonItem",
    updateNichePreference: "DashboardInsight/preferences/niche",
    updatePreferences: "DashboardInsight/preferences/product",
  },
  manageSubscription: {
    getSubscriptionHistory: "ManageSubscriptions/GetSubscriptionHistory",
    cancelSubscription: "ManageSubscriptions/CancelUserSubscription",
    checkSubscriptionUpgrade: "subscriptions/price/upgrade",
    checkSubscriptionRegistration: "subscriptions/price/registration",
    validateCoupon: "subscriptions/coupon/validate",
    upgrade: "payments/upgrade",
    shopifyUpgrade: "payments/shopify/upgrade",
    paypalPurchase: "payments/paypal/billingagreement/upgrade",
    paypalPurchaseRegistration: "payments/paypal/billingagreement",
    payoneerPurchase: "payments/payoneer/initiate",
    paypalSubscription: "payments/paypal/subscription",
    shopifyPaypalSubscription: "payments/shopify/paypal/subscription",
    payoneerSubscription: "payments/payoneer/subscription",
    payoneerShopifySubscription: "payments/shopify/payoneer/subscription",
    getPfToken: "payments/creditcard/hostedpaymentfield",
    submitCreditCard: "payments/creditcard/subscription",
    shopifySubmitCreditCard: "payments/shopify/creditcard/subscription",
    getDefaultCoupon: "subscriptions/coupon/default",
    getPaymentInvoice: "/ManageSubscriptions/GeneratePaymentInvoice",
    journeyComplete: "/payments/journey/complete",
    getlatestsubscription: "/subscriptions/GetLatestSubscription",
  },
  salesSpy: {
    search: "salesSpy/search",
    history: "salesSpy/history",
    watchlistComment: "salesSpy/WatchListComment",
    favorite: "salesSpy/favourite",
    storeInfo: (domainId: number) => `salesspy/store/${domainId}/info`,
    storeConnectedApps: (domainId: number) =>
      `SalesSpy/store/${domainId}/connectedapps`,
    salesAndRevenue: (domainId: number) =>
      `salesspy/store/${domainId}/sales-and-revenue`,
    details: (domainId: number) => `salesspy/store/${domainId}/details`,
    topSellers: (domainId: number) => `salesSpy/store/${domainId}/topsellers`,
    newProducts: (domainId: number) =>
      `salesSpy/store/${domainId}/new-products`,
    salesHistory: (domainId: number) =>
      `salesSpy/store/${domainId}/sales-history`,
  },
  shopifyMarketInsights: {
    trendingStores: "DashboardInsight/shopify/trendingsellers",
    trendingProducts: "DashboardInsight/shopify/trendingproducts",
  },
  shopify: {
    storeFinderOptions: "storefinder/options",
    storeFinderSearch: "storefinder/search",
    productExplorerSearch: "productExplorer/search",
  },
  getWhether:
    "https://api.weatherapi.com/v1/current.json?key=fe8db380196847faac4111927233005&q=",
  ipify: "https://api.ipify.org?format=json",
};
