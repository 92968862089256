import SellerGraph from "assets/images/selling-graph.png";
import Button from "components/Button";
import Loading from "components/Loading";
import { FC, useState } from "react";
import { useGetOnBoardingQuestionQuery, useSaveSellerTypeMutation } from "store/api/onboardingApi";


interface Props {
    contStyles: string;
    step: number;
    onStepChange: (newStep: number) => void;
}
const YesOrNoQuestion: FC<Props> = ({ contStyles, onStepChange, step }) => {
    const [yesOrNo, setYesOrNo] = useState<"yes" | "no" | null>(null);
    const { data, isLoading } = useGetOnBoardingQuestionQuery({ stepNumber: 1 });
    const [saveSellerType, { isLoading: addLoading }] =
        useSaveSellerTypeMutation();

    const saveSeller = async (seller: boolean) => {
        await saveSellerType({ isSeller: seller }).unwrap().then(() => {
            onStepChange(step + 1);
        });
    };


    const handleBtnClick = (seller: boolean) => {
        setYesOrNo(seller ? "yes" : "no");
        saveSeller(seller)
    }

    return (
        <div className={`${contStyles} max-lg:pt-[37px] sm:pb-11 max-lg:h-[calc(100vh-200px)] max-sm:h-[calc(100vh-132px)] relative max-lg:max-w-[536px] max-sm:max-w-[330px] max-lg:w-full max-lg:mx-auto max-lg:justify-between`}>
            {isLoading ? (
                <Loading fill="primaryPurple mb-10" />
            ) : (
                <div className="">
                    <img src={SellerGraph} alt="" className="w-[45px] h-[45px] max-lg:w-[55px] max-lg:h-[55px] max-sm:w-[48px] max-sm:h-[48px] mx-auto mb-[35px] sm:mt-[7px] max-lg:mb-11  max-sm:mb-6" />
                    <h1 className="mb-[25px] max-sm:mb-6 text-xl text-center font-bold text-darkBlue dark:text-grey100 max-lg:text-2xl max-lg:mb-8 max-sm:text-xl">
                        {data?.result[0].questionLabel}
                    </h1>
                    <p className="lg:mb-[25px] text-sm text-center text-darkBlue dark:text-grey100 max-lg:text-lg max-sm:text-base">
                        {data?.result[0].questionDescription}
                    </p>
                </div>
            )}

            <div className="flex flex-row max-lg:flex-col max-lg:items-center justify-center space-between gap-6 max-lg:gap-5 w-full max-sm:fixed bottom-[32px] max-sm:max-w-[300px]">
                <Button
                    title="Yes"
                    height="h-16 max-sm:h-14"
                    titleClassName="!text-base"
                    className="max-w-[240px] max-lg:max-w-[100%]"
                    value={true}
                    handleClick={handleBtnClick}
                    loading={addLoading && yesOrNo === "yes"}
                />
                <Button
                    title="No"
                    height="h-16 max-sm:h-14"
                    titleClassName="!text-base"
                    className="max-w-[240px] max-lg:max-w-[100%]"
                    value={false}
                    handleClick={handleBtnClick}
                    loading={addLoading && yesOrNo === "no"}
                />
            </div>
        </div>
    )
}

export default YesOrNoQuestion