import { ReactComponent as Globe } from 'assets/icons/globe.svg';
import Button from "components/Button";
import Select from "components/Select";
import Toaster from "components/Toast";
import useFilters from "hooks/useFilters";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  useGetSettingsQuery,
  useUpdateBreakEvenMutation,
  useUpdateSupplierMutation
} from "store/api/quickSettingsApi";

const sourceWebsite = [
  { text: "Aliexpress.com", value: "Ali" },
  { text: "Alibaba.com", value: "Baba" },
];

const initialState = {
  sourceWebsite: "Ali",
  breakEven: "",
};

const SupplierSettings = () => {
  const { data } = useGetSettingsQuery();
  const { filters, handleFilterChange, setFilters } = useFilters(initialState);
  const [updateBreakEven, { isLoading }] = useUpdateBreakEvenMutation();
  const [updateSupplier, { isLoading: supplierLoading }] =
    useUpdateSupplierMutation();
  const { t } = useTranslation(['home']);
  const onBreakEvenSave = () => {
    updateBreakEven({
      breakEven: Number(filters.breakEven),
    })
      .unwrap()
      .then((res) => res && toast.success("Break Even successfully updated."));
  };

  const onSupplierSave = () => {
    updateSupplier(filters.sourceWebsite)
      .unwrap()
      .then(
        (res) => res && toast.success("Source Website successfully updated.")
      );
  };
  useEffect(() => {
    if (data) {
      setFilters({
        sourceWebsite: data?.result.amazonsite,
        breakEven: data?.result.breakEven.toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <div className="flex justify-center gap-[139px] max-[1300px]:gap-[30px] max-[1000px]:flex-col">
        <div className="border-2 dark:border-[1px] border-neutral2 dark:border-grey700 dark:bg-grey800 rounded-[10px] p-[25px] max-w-[616px] w-full max-[1000px]:max-w-full dark:border-black700">
          <div className="flex items-center mb-[27px]">
            <Globe className="fill-darkBlue w-[14px] mr-5 dark:fill-grey100" />
            <div className="font-bold ">
              {t('SourceWebsite')}
            </div>
          </div>
          <div className="flex items-center justify-between gap-[50px] max-[660px]:flex-col">
            <div className="w-full max-w-[213px]">
              <Select
                options={sourceWebsite}
                value={filters.sourceWebsite}
                handleChange={(newVal) => {
                  handleFilterChange(newVal, "sourceWebsite");
                }}
                className="w-full"
                btnClassName="w-full"
              />
            </div>

            <div className="w-full max-w-[250px]">
              <Button
                title="Save"
                loading={supplierLoading}
                handleClick={onSupplierSave}
                height="h-[36px]"
                className="max-w-[250px] ml-auto max-[660px]:w-full dark:bg-deepSpace900"
              />
            </div>
          </div>
        </div>

        {/*   <div className="border-2 dark:border-[1px] border-neutral2 dark:border-grey700 dark:bg-grey800 rounded-[10px] p-[25px] max-w-[415px] w-full max-[1000px]:max-w-full">
          <div className="flex items-center mb-[27px]">
            <Caluclations className="fill-darkBlue w-[14px] mr-[20px] dark:fill-grey100" />
            <div className="font-bold ">
              Break Even Calculation
            </div>
            <InfoTooltip title="Set your calculated estimated profit. The break-even point is the point at which total cost and total revenue are equal" className="ml-auto" />
          </div>

          <div className="flex items-center gap-[50px] max-[660px]:flex-col">
            <div className="w-full">
              <Input
                className='after:!h-[2px]'
                value={filters.breakEven}
                type="number"
                handleChange={(newVal) => {
                  handleFilterChange(newVal, "breakEven");
                }}
                inputClassName="text-center"
                placeholder="14"
              />
              <div className={`text-neutral3 text-center text-xs mt-[2px]`}>
                {t('BreakEven%')}
              </div>
            </div>

            <div className="w-full">
              <Button
                title="Save"
                loading={isLoading}
                handleClick={onBreakEvenSave}
                height="h-[36px]"
                className="max-w-[164px] ml-auto max-[660px]:w-full dark:bg-deepSpace900"
              />
            </div>
          </div>
        </div> */}
      </div>
      <Toaster />
    </>
  );
};

export default SupplierSettings;
