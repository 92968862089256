import Invoice from "pages/ManagementPanel/components/Invoice";
import { useParams, useSearchParams } from "react-router-dom";
import ShopifyBillingHeader from "./components/BillingHeader";


const ShopifyInvoice = () => {
    const [searchParams] = useSearchParams();
    const { id } = useParams();

    return (
        <>
            <ShopifyBillingHeader />
            <Invoice id={id?.toString() || ""} paymentType={searchParams.get("paymentType")} isShopify />
        </>

    )
}

export default ShopifyInvoice;