import { createSlice } from "@reduxjs/toolkit";

export enum DASHBOARD_MARKET_PLACES {
  ebay = "ebay",
  shopify = "shopify",
  aliexpress = "aliexpress",
}
interface MarketplaceState {
  dashboardMarketplace: DASHBOARD_MARKET_PLACES;
}

export const initialState: MarketplaceState = {
  dashboardMarketplace: DASHBOARD_MARKET_PLACES.ebay,
};
export const marketplaceSlice = createSlice({
  name: "dashboardMarketplace",
  initialState,
  reducers: {
    setDashboardMarketplace: (state, action) => {
      state.dashboardMarketplace = action.payload;
    },
  },
});

export const { setDashboardMarketplace } = marketplaceSlice.actions;

export default marketplaceSlice.reducer;
