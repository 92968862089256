// Hooks

// Utils
import { formatCurrency } from "utils/formatCurrency";

// Types
import { CURRENCY } from "types/currency";
import { formatDate } from "utils/formatTime";

// Icons
import { ColumnDef } from "@tanstack/react-table";
import { ReactComponent as Info } from "assets/icons/info-i.svg";
import { getPlanDuration, getPlanName } from "utils/planUtils";

// Icons
import { ReactComponent as ArrowRightShort } from "assets/icons/arrow-right-extended-bold.svg";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right-extended.svg";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { PATH } from "routes/path";
import {
  PaidRequestList,
  PaymentDetailsList
} from "types/services/affiliateManager";
import { BluesnapPayment } from "types/services/managePayments";
import { makeQueryParams } from "utils/makeQueryParams";

export const paymentHistoryCols = (isShopify?: boolean): ColumnDef<BluesnapPayment>[] => [
  {
    header: () => <div className="text-left min-w-[120px] w-full">
      <span className="!text-left !max-w-[120px] w-full">Subscription Type</span>
    </div>,
    accessorKey: "blueSnapSubscription.type",
    cell: ({ row: { original: { blueSnapSubscription: payment } } }) =>
      <span className={`${isShopify ? "max-w-[230px]" : "max-w-[120px]"} w-full`}>{payment.type === "ShopProM" ? "Shopify Pro Monthly" : payment.type === "ShopProY" ? "Shopify Pro Yearly" : (getPlanName(payment.type) + ` ` + getPlanDuration(payment.type, payment.billingInterval))}</span>,
  },
  {
    header: "Merchant Type",
    accessorKey: "paymentMethod",
    cell: (method) => <span className="capitalize">{String(method.getValue()).replace("_", " ").toLowerCase()}</span>,

  },
  {
    header: "Date Paid",
    accessorKey: "",
    cell: ({ row: { original } }) => formatDate(original.transactionDate, "DD MMM YYYY"),

  },
  {
    header: "Amount",
    accessorKey: "amount",
    cell: (amount) =>
      formatCurrency(String(amount.getValue()), CURRENCY.dollar),

  },
  {
    header: "VAT",
    minSize: 50,
    accessorKey: "vatamount",
    cell: (vat) => formatCurrency(String(vat.getValue()), CURRENCY.dollar),
  },
  {
    header: "Total Amount",
    accessorKey: "totalAmount",
    cell: ({ row: { original } }) =>
      formatCurrency(
        String(original.amount + original.vatamount),
        CURRENCY.dollar
      ),
  },
  {
    header: "Invoice",
    accessorKey: "id",
    cell: ({ row: { original } }) => isShopify ? <Link className="flex items-center group transition hover:text-primaryPurple dark:hover:text-purple500 cursor-pointer" to={makeQueryParams(`${PATH.shopify.invoice.root}/${original.id}`, {
      paymentType: original.paymentMethod,
      ...(original.blueSnapSubscription.isShopify && { isShopify: original.blueSnapSubscription.isShopify })
    })}>
      <span>Invoice</span>
      <div className="h-6 w-6 flex items-center justify-center">
        <ArrowRightShort className="fill-darkBlue dark:fill-grey100 w-3 h-3.5 ml-[13px] transition-transform group-hover:translate-x-[8px] group-hover:fill-primaryPurple dark:group-hover:fill-purple500" />
      </div>

    </Link> : (
      <Link
        className="text-primaryPurple dark:text-paradiseBlue hover:underline flex items-center gap-2.5 font-bold "
        to={makeQueryParams(PATH.managementPanel.root, {
          tab: "manage-payments",
          invoice: String(original.id),
          type: "payment",
          paymentType: original.paymentMethod,
          ...(original.blueSnapSubscription.isShopify && { isShopify: original.blueSnapSubscription.isShopify })
        })}
      >
        <span>Invoice</span>
        <ArrowRight className="fill-primaryPurple dark:fill-paradiseBlue" />
      </Link>
    ),
    meta: {
      // @ts-ignore
      align: `!justify-center px-3`,
    }
  },
];

export const paidRequestsCols = (
  onInfoClick: (id: string) => void
): ColumnDef<PaidRequestList>[] => [
    {
      header: "Amount",
      accessorKey: "amount",
      cell: (amount) =>
        formatCurrency(String(amount.getValue()), CURRENCY.dollar),
    },
    {
      header: "Full Name",
      accessorKey: "fullName",
    },
    {
      header: "Email",
      accessorKey: "paypalEmail",
      cell: (email) => <p>{String(email.getValue())}</p>,
    },

    {
      header: "Requested Date",
      accessorKey: "requestDate",
      cell: (date) => formatDate(String(date.getValue())),
    },
    {
      header: "Payout Date",
      accessorKey: "requestDate",
      cell: (date) => (
        <div>
          {dayjs(String(date.getValue())).add(1, "month").format("DD/MM/YYYY")}
        </div>
      ),
    },
    {
      header: "Paid",
      minSize: 50,
      accessorKey: "paid",
      cell: (paid) => (Boolean(paid.getValue()) ? "Yes" : "No"),
    },
    {
      header: "Invoice",
      accessorKey: "id",
      cell: (id) => (
        <Link
          className="text-primaryPurple dark:text-paradiseBlue hover:underline flex items-center gap-2.5"
          to={makeQueryParams(PATH.managementPanel.root, {
            tab: "affiliate-manager",
            subtab: "payment-history",
            invoice: String(id.getValue()),
            type: "affiliate"
          })}
        >
          <p>Invoice</p>
          <ArrowRight className="fill-primaryPurple dark:fill-paradiseBlue" />
        </Link>
      ),
    },
    {
      header: "Details",
      accessorKey: "details",
      cell: ({ row: { original } }) => (
        <button onClick={() => onInfoClick(original.id.toString())}>
          <Info className="fill-darkBlue dark:fill-grey100 hover:fill-primaryPurple dark:hover:fill-purple500 cursor-pointer" />
        </button>
      ),
    },
  ];

export const requestPayout: ColumnDef<PaymentDetailsList>[] = [
  {
    header: "Referral Email",
    accessorKey: "referredEmail",
  },
  {
    header: "Transaction ID",
    accessorKey: "forTransactionId",
  },
  {
    header: "Transaction Date",
    accessorKey: "createdOn",
    cell: (date) => formatDate(String(date.getValue())),
  },
  {
    header: "Amount Earned",
    accessorKey: "earnedAmount",
    cell: (amount) => formatCurrency(String(amount.getValue())),
  },
];
