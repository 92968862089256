
import { ColumnDef } from "@tanstack/react-table";
import { ReactComponent as Box } from "assets/icons/boxes/box.svg";
import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";
import { ReactComponent as GlobeFlag } from "assets/icons/globe-flag.svg";
import { ReactComponent as LineGraph } from "assets/icons/line-graph.svg";
import { ReactComponent as LocationIcon } from "assets/icons/location.svg";
import { ReactComponent as Shipping } from "assets/icons/shipping.svg";
import { ReactComponent as Star } from "assets/icons/star.svg";

import CutLongTitle from "components/CutLongTitle";
import HoveredIcon from "components/HoveredIcon";
import IconFinderBox from "components/IconFinderBox";
import ProductNameCell from "components/ProductNameCell";
import Tooltip from "components/Tooltip";
import { Link } from "react-router-dom";
import { PATH } from "routes/path";
import { CURRENCY } from "types/currency";
import { IconFinderItem } from "types/services/iconFinder";
import { PrEbaySellerList } from "types/services/pr";
import {
  CURRENCY_SIGNS,
  formatCurrency,
  formatNumber
} from "utils/formatCurrency";
import { getCountryFlag } from "utils/getCountryFlag";

export const prEbayCols = (
  onSellerClick: (name: string) => void,
  currency: string,
  openIconFinder: (item: IconFinderItem) => void,
  dataRange: string,
  shippingLocation: string,
): ColumnDef<PrEbaySellerList>[] => [
    {
      header: () => <CompetitorResearch className="fill-darkBlue dark:fill-grey100 w-[17.5px] h-[19.6px]" />,
      accessorKey: "countryCode",
      enableSorting: false,
      minSize: 50,
      cell: ({ row: { original } }) =>
        original.countryCode ? (
          <div>
            <img
              src={original.flagUrl}
              alt={original.countryCode}
              className="w-[18px] h-[18px]"
              data-tooltip-id={original.itemID + "001"}
            />
            <Tooltip
              title={original.countryName}
              anchorId={original.itemID + "001"}
              place="top"
            />
          </div>
        ) : (
          <GlobeFlag className="fill-darkBlue dark:fill-grey100 w-5 h-5" />
        ),
      meta: {
        hideBorder: true,
      },
    },
    {
      header: "Seller",
      accessorKey: "itemID",
      cell: ({ row: { original } }) => (
        <div className="flex items-center justify-between w-full mr-2">
          <CutLongTitle
            titleClassName="ml-2 cursor-pointer hover:text-primaryPurple dark:hover:text-purple500 min-w-[130px]"
            title={original.sellerName}
            length={15}
            handleClick={() => onSellerClick(original.sellerName)}
          />
          <Link
            to={PATH.competitorResearch.ebay + `?search=${original.sellerName}`}
            target="_blank"
            rel="noreferrer"
          >
            <HoveredIcon
              Icon={CompetitorResearch}
              iconClassName="w-[1.3125rem] h-[1.1875rem]"
              className="w-[2.0625rem] h-[2rem]"
              title="Scan Seller"
            />
          </Link>
        </div>

      ),
      meta: {
        alignHead: "justify-between",
        align: "justify-start",
        sortIconClassName: "mr-2",
        tooltipTitle: "The username of the seller",
      },
    },
    {
      header: () => <Star className="fill-darkBlue dark:fill-grey100" />,
      accessorKey: "feedBackScore",
      cell: (feedback) => formatNumber(String(feedback.getValue())),
      meta: {
        tooltipTitle: "Amount of Feedback",
      },
    },
    {
      header: () => <Shipping className="fill-darkBlue dark:fill-grey100" />,
      accessorKey: "sales",
      cell: (sales) => formatNumber(String(sales.getValue())),
      meta: {
        tooltipTitle: `${dataRange} Day Sales`,
      },
    },
    {
      header: "Product Name",
      accessorKey: "title",
      cell: ({
        row: {
          original: {
            title,
            images,
            productUrl,
            isAliDropship,
            isAmzDropshipUK,
            isWalmartDropship,
            isAmzUSDropship,
          },
        },
      }) => (
        <ProductNameCell
          name={title}
          img={images}
          checkKeywords
          url={"https://" + productUrl}
          imgWrapperClassName=""
          isDropshipper={
            isAliDropship ||
            isAmzDropshipUK ||
            isWalmartDropship ||
            isAmzUSDropship
          }
          isAliDropship={isAliDropship}
          isAmzDropshipUK={isAmzDropshipUK}
          isWalmartDropship={isWalmartDropship}
          isAmzUSDropship={isAmzUSDropship}
          height="59px"
          width="57px"
        />
      ),
      meta: {
        align: "justify-start",
        alignHead: "justify-between"
      },
    },
    {
      header: () => <Shipping className="fill-darkBlue dark:fill-grey100" />,
      accessorKey: "upc",
      cell: ({ row: { original } }) => formatNumber(original.sales),
      meta: {
        tooltipTitle: `${dataRange} Day Sales`,
        sortColumnId: "sales",
      },
    },
    {
      header: "Total Sold",
      accessorKey: "totalQuantitySold",
      minSize: 109,
      cell: (totalSold) => formatNumber(String(totalSold.getValue())),
    },
    {
      header: "Price",
      accessorKey: "currentPrice",
      cell: (price) =>
        formatCurrency(
          String(price.getValue()),
          CURRENCY.none,
          currency as CURRENCY_SIGNS
        ),
    },
    {
      header: () => <LocationIcon className="fill-darkBlue dark:fill-grey100" />,
      enableSorting: false,
      accessorKey: "location",
      minSize: 50,
      cell: ({ row: { original } }) => (
        <div data-tooltip-id={original.itemID.toString()}>
          <Tooltip
            title={original.location}
            anchorId={original.itemID}
            place="top"
          />
          <img
            src={getCountryFlag(original.shippingCountry || shippingLocation)}
            alt={original.countryCode}
            className="w-[18px] h-[18px]"
          />
        </div>
      ),
      meta: {
        tooltipTitle: "The item Location",
        alignHead: "justify-center"
      },
    },
    {
      header: () => <Box className="fill-darkBlue dark:fill-grey100" />,
      enableSorting: false,
      minSize: 50,
      accessorKey: "productUrl",
      cell: ({ row: { original } }) => (
        <IconFinderBox
          openIconFinder={openIconFinder}
          isGreen={original.isGreen}
          isSimilar={original.isSimilar}
          sales={original.sales}
          currentPrice={original.currentPrice}
          title={original.title}
          itemID={original.itemID}
          images={original.images}
          productUrl={original.productUrl}
        />
      ),
      meta: {
        tooltipTitle:
          "Use the item finder to locate products from your chosen supplier. To switch suppliers, go to Quick Settings on the left-side menu",
        alignHead: "justify-center"
      },
    },
    {
      header: () => <LineGraph className="fill-darkBlue dark:fill-grey100" />,
      minSize: 50,
      enableSorting: false,
      accessorKey: "isGreen",
      cell: ({ row: { original } }) => (
        <Link to={`?search=${original.title}`} target="_blank" rel="noreferrer">
          <HoveredIcon Icon={LineGraph} title="Scan Product" />
        </Link>
      ),
      meta: {
        tooltipTitle: "Search the product Title using the Product Research Tool",
        alignHead: "justify-center"
      },
    },
  ];
