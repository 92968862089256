import { FC } from "react";
interface Props {
  img: string;
  isFirst?: boolean;
  isLast?: boolean;
}
const SimiliarImage: FC<Props> = ({ img, isFirst, isLast }) => (
  <div className="basis-[12%] group">
    <img
      src={img}
      alt=""
      className={`my-auto rounded-[7px] max-h-[4.5rem] shadow cursor-zoom-in max-h-[60px] group-hover:border-2 group-hover:border-primaryPurple dark:group-hover:border-purple500 `}
      width={60}
      height={60}
      referrerPolicy="no-referrer"
    />
    {img !== "" ? (
      <div
        className={`cursor-zoom-in absolute hidden group-hover:block ${isFirst
          ? "top-0"
          : isLast
            ? "-bottom-2"
            : "top-1/2 -translate-y-1/2"
          } z-50 pl-2 left-[50px]`}
      >
        <img
          src={img}
          alt="product"
          className="my-auto rounded-[7px] shadow-lg border-primaryPurple dark:border-purple500 ml-1 border-2 bg-white"
          width={190}
          height={"auto"}
          referrerPolicy="no-referrer"
        />
      </div>
    ) : (
      ""
    )}
  </div>
);

export default SimiliarImage;
