
import AmericanExpress from "assets/images/payment-methods/american-express.png";
import Mastercard from "assets/images/payment-methods/mastercard.png";
import PayPal from "assets/images/payment-methods/paypal.png";
import Visa from "assets/images/payment-methods/visa.png";
import Payoneer from "assets/images/payoneer.png";
import Trustpilot from "assets/images/trustpilot.png";
import { FC } from "react";

const paymentMethods = [
    {
        id: "paypal",
        img: PayPal,
        width: 36,
        height: 9
    },
    {
        id: "payoneer",
        img: Payoneer,
        width: 33.65,
        height: 6.36
    },
    {
        id: "visa",
        img: Visa,
        width: 27,
        height: 16
    },
    {
        id: "masterCard",
        img: Mastercard,
        width: 28,
        height: 16
    },
    {
        id: "americanExpress",
        img: AmericanExpress,
        width: 35,
        height: 20
    },

]

interface Props {
    wrapperClassName?: string;
    trustedPaymentsTxtStyles?: string;
    trustpilotStyles?: string;
    paymentMethodsWrapper?: string;
}
const Trusted: FC<Props> = ({ wrapperClassName, trustedPaymentsTxtStyles, trustpilotStyles, paymentMethodsWrapper }) => {
    return (
        <div className={`flex items-center gap-6 max-sm:flex-col ${wrapperClassName}`}>
            <div className={`py-[3px] px-[15px] shadow-[5px_6px_80px_0px_rgba(160,_189,_225,_0.30)] rounded-[5px] ${trustpilotStyles}`}>
                <img src={Trustpilot} alt="trustpilot" className="w-[115px] h-[49px]" />
            </div>
            <div className={`bg-neutral2 p-2.5 rounded-10 ${paymentMethodsWrapper}`}>
                <h6 className={`text-center text-[10px] mb-2.5 font-medium ${trustedPaymentsTxtStyles}`}>Trusted Payment Methods</h6>
                <div className="flex items-center gap-2">
                    {paymentMethods.map((item) => <div key={item.id} className="bg-white w-12 h-8 flex items-center justify-center">
                        <img src={item.img} width={item.width} height={item.height} />
                    </div>)}
                </div>
            </div>

        </div>
    )
}

export default Trusted