// Icons
import { ColumnDef } from "@tanstack/react-table";
import { ReactComponent as Export } from "assets/icons/export.svg";
import { ReactComponent as FolderIcon } from "assets/icons/folder-icon.svg";
import { ReactComponent as Trash } from "assets/icons/trash.svg";
import HoveredIcon from "components/HoveredIcon";
import FolderItemCount from "pages/MyProducts/components/FolderItemCount";
import FolderProductName from "pages/MyProducts/components/FolderProductName";
import { FoldersList } from "types/services/folders";
import { formatDate } from "utils/formatTime";

export const myFoldersCols = (
  onDelete: (id: string) => void,
  onExport: (id: string) => void,
  deleteLoading: (id: string) => void,
  handleSort: (column: string) => void,
): ColumnDef<FoldersList>[] => [
    {
      header: () => <span className="flex items-center gap-[5px]">
        <span className="w-6 h-6 flex justify-center items-center">
          <FolderIcon className="fill-darkBlue dark:fill-grey100" />
        </span>
        <span className="leading-[24px]">Folder Name</span>
      </span>,
      accessorKey: "title",

      cell: ({ row: { original } }) => (
        <FolderProductName title={original.title} listID={original.listID} />
      ),
      meta: {
        sortColumnId: "title",
        customSort: () => handleSort("title"),
      }
    },

    {
      header: "Items",
      accessorKey: "numberOfItems",
      enableSorting: false,
      cell: ({
        row: {
          original: { listID },
        },
      }) => <FolderItemCount listId={listID}
        />,
      meta: {
      }
    },
    {
      header: "Description",
      accessorKey: "description",
      cell: (description) => (
        <div className="max-w-[290px]">
          <p className="text-neutral3 text-sm px-5">
            {String(description.getValue())}
          </p>
        </div>
      ),
      meta: {
        sortColumnId: "description",
        customSort: () => handleSort("description"),
      }
    },
    {
      header: "Last Added",
      accessorKey: "dateModified",
      cell: (date) => formatDate(String(date.getValue())),
      meta: {
        sortColumnId: "dateModified",
        customSort: () => handleSort("dateModified"),
      }
    },
    {
      header: () => <Trash className="fill-darkBlue dark:fill-grey100" />,
      id: "listID",
      minSize: 50,
      enableSorting: false,
      cell: ({ row: { original } }) => (
        original.title === "Unorganized folder" ? "" : <HoveredIcon
          Icon={Trash}
          variant="outlined"
          title="Delete"
          isLoading={Boolean(deleteLoading(original.listID.toString()))}
          handleClick={() => onDelete(original.listID.toString())}
        />
      ),
    },
    {
      header: () => <Export className="fill-darkBlue dark:fill-grey100" />,
      id: "export",
      enableSorting: false,
      minSize: 50,
      cell: ({ row: { original } }) => (
        <HoveredIcon
          Icon={Export}
          variant="outlined"
          title="Export"
          handleClick={() => onExport(original.listID.toString())}
        />
      ),
      meta: {
        tooltipTitle: "Export",
        alignHead: "justify-center"
      },
    },
  ];
