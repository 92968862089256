import { marketPlaces, noOfEntries } from "assets/data/filters";
import { ReactComponent as Menu } from "assets/icons/menu.svg";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import { prEbayCols } from "assets/tableColumns/prEbay";
import Card from "components/Card";
import FilterCheckbox from "components/Filters/FilterCheckbox";
import MinMaxFilter from "components/Filters/MinMaxFilter";
import IconFinder from "components/IconFinder";
import Scrollbar from "components/Scrollbar";
import ScrollToTop from "components/ScrollToTop";
import SearchTable from "components/Search/SearchTable";
import Select from "components/Select";
import Table from "components/Table";
import { TableStyles } from "components/Table/Table";
import { useAppSelector } from "hooks/useAppSelector";
import useModal from "hooks/useModal";
import useShowByField from "hooks/useShowByField";
import useTable from "hooks/useTable";
import { useEffect, useState } from "react";
import { IconFinderItem } from "types/services/iconFinder";
import { PrEbaySellerList } from "types/services/pr";
import { CURRENCY_SIGNS } from "utils/formatCurrency";

const initialSelectedItem = {
  title: "",
  price: 0,
  sold: 0,
  img: "",
  url: "",
  id: 0,
  isSimilar: false,
  isGreen: false,
};

const tableStyles: TableStyles = {
  minWidth: "min-w-[1150px]",
  px: "px-0",
  head: {
    contHeight: "h-[37px]",
    rowHeight: "h-[60px]",
  },
  body: {
    contHeight: "h-[50px]",
    rowHeight: "h-[85px]",
  },
};

const ProductResearchEbayList = () => {
  const modal = useModal();
  const [selectedItem, setSelectedItem] =
    useState<IconFinderItem>(initialSelectedItem);
  const { isExpand } = useAppSelector((state) => state.themeSlice);
  const {
    filters: { ebay },
    ebayData: {
      result: { sellers, currency },
    },
  } = useAppSelector((state) => state.prSlice);



  const { filteredList, itemType, changeItemType, options } = useShowByField(
    sellers || [],
    "title"
  );

  useEffect(() => {

    if (sellers) {
      if (sellers.every((item) => item.sales === 0)) {
        setSorting([{ id: "totalQuantitySold", desc: true }])
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellers])

  const onBoxClick = (item: IconFinderItem) => {
    modal.open();
    setSelectedItem({ ...item, url: "https://" + item.url });
  };

  const onSellerClick = (sellerName: string) => {
    const ebayLink = marketPlaces.find((item) => item.value === ebay.eBaySite);
    window.open(`${ebayLink?.link}/usr/${sellerName}`, "_blank");
  };

  const { table, minMaxFilter, setSorting } = useTable<PrEbaySellerList>({
    columns: prEbayCols(
      onSellerClick,
      currency,
      onBoxClick,
      ebay.ebaySearchedPeriod,
      ebay.shippingLocation
    ),
    dataDeps: [filteredList],
    data: filteredList,
    initialSortBy: "sales",
    desc: true,
    minMax: true,
    pagination: true,
    initialPageSize: 100,
    sort: true,
    search: true,
    columnDeps: [ebay.ebaySearchedPeriod],
  });

  return (
    <Card className="w-full  pt-1.5 pb-4 mb-4" shadow={false}>
      <div className="flex max-md:flex-col max-md:gap-4 items-end justify-between px-[1.1875rem] mb-4">
        <div className="flex gap-12 items-center max-sm:flex-col ml-4">
          <Select
            options={noOfEntries}
            value={table.getState().pagination.pageSize.toString()}
            Icon={Menu}
            handleChange={(value) => table.setPageSize(Number(value))}
          />
          <Select
            value=""
            Icon={Plus}
            btnClassName="min-w-[210px]"
            title="Filters"
            listWrapperClassName="dark:!bg-deepSpace900"
            handleChange={() => { }}
            CustomOptions={
              <div className="w-full">
                <FilterCheckbox
                  options={options}
                  multiselect
                  className="mb-4"
                  handleChange={(newVal) => changeItemType(newVal.toString())}
                  value={itemType}
                />
                <div className="mb-2.5">
                  <h6 className="text-xs font-bold pl-2.5">{ebay.ebaySearchedPeriod} Day Sales</h6>
                  <MinMaxFilter
                    value={minMaxFilter("sales")}
                    name="sales"
                    handleChange={minMaxFilter("sales").onChange}
                  />
                </div>
                <div>
                  <h6 className="text-xs font-bold pl-2.5">Price</h6>
                  <MinMaxFilter
                    value={minMaxFilter("currentPrice")}
                    name="currentPrice"
                    handleChange={minMaxFilter("currentPrice").onChange}
                  />
                </div>
              </div>
            }
          />
        </div>
        <SearchTable handleApply={table.setGlobalFilter} />
      </div>
      <div className="px-4">
        <Scrollbar className={isExpand ? "max-[1420px]:overflow-x-auto" : "max-[1310px]:overflow-x-auto"}>
          <Table<PrEbaySellerList> table={table} styles={tableStyles} />
        </Scrollbar>
      </div>


      <ScrollToTop scrollTo={715} />
      {selectedItem && (
        <IconFinder
          currency={currency as CURRENCY_SIGNS}
          helpers={modal}
          item={selectedItem}
          thirdParty={true}
        />
      )}
    </Card>
  );
};

export default ProductResearchEbayList;
