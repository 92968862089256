import { plansComparison } from "assets/data/plans/plans";
import NextPrevSlider from "components/Plans/NextPrevSlider";
import PlansCardB from "components/Plans/PlanCardB";
import SwiperWrapper from "components/SwiperWrapper";
import { FC, useRef, useState } from "react";
import { SwiperSlide } from "swiper/react";
import { PlanList, PlansType, TSelectedPlan } from "types/plans";
import PlansDetailedB from "./PlansDetailedB";
import Trusted from "./Trusted";

interface Props {
    plans: PlansType;
    handleSelectPlan: (plan: TSelectedPlan) => void;
}
interface PlanProps {
    plan: PlanList;
    handleSelectPlan: (plan: TSelectedPlan) => void;
    period: string
}

const PlanWithComparison: FC<PlanProps> = ({ plan, period, handleSelectPlan }) => {
    return (
        <div className="flex flex-col mb-10" >
            <PlansCardB
                wrapperClassName={` ${plan.popularBadge ? "" : " mt-2.5"} mb-[154px] max-lg:mb-[188px] max-sm:mb-[246px] mx-auto`}
                isMonthly={period === "monthly"}
                name={plan.planName}
                description={plan.planDescription}
                discountPrice={plan.planDiscountPrice}
                isDiscount={plan.isDiscount}
                handleSelectPlan={handleSelectPlan}
                type={plan.type}
                price={plan.planPrice}
                id={plan.planId}
                skipAmount={plan.skipAmout}
                popularBadge={plan.popularBadge}
            />
            <PlansDetailedB
                wrapperClassName={`${plan.popularBadge ? "-mt-2.5" : ""} mx-auto`}
                name={plan.planName}
                price={plan.planPrice}
                skipAmount={plan.skipAmout}
                id={plan.planId}
                type={plan.type}
                handleSelectPlan={handleSelectPlan}
                isPopular={plan.popularBadge}
                discountedPrice={plan.planDiscountPrice}
                period={period}
                isEbay
                features={plansComparison.map((item) => ({
                    Icon: item.Icon,
                    title: item.title,
                    subtitle: item.description,
                    width: item.width,
                    height: item.height,
                    pro: item.pro,
                    proPlus: item.proPlus,
                    enterprise: item.enterprise,
                }))}
            />
        </div>
    )
}
const RegistrationFunnelBEbayPlans: FC<Props> = ({ plans, handleSelectPlan }) => {
    const [activeSlide, setActiveSlide] = useState(1);
    const next = useRef(null);
    const prev = useRef(null);

    return <div className="max-w-[1026px] w-full mx-auto relative pt-2 max-lg:pt-0">
        <div className="w-full lg:hidden">
            <SwiperWrapper
                onActiveChange={(idx) => setActiveSlide(idx)}
                next={next.current}
                prev={prev.current}
            >
                {plans.planList.map((plan) => (
                    <SwiperSlide className="h-auto max-w-[310px]" content="fit" key={plan.planId}>
                        <PlanWithComparison plan={plan} key={plan.planId} handleSelectPlan={handleSelectPlan} period={plans.period} />
                    </SwiperSlide>)
                )}
            </SwiperWrapper>
        </div>
        <div className="w-full flex justify-between max-lg:hidden">
            {plans.planList.map((plan) => (
                <PlanWithComparison plan={plan} key={plan.planId} handleSelectPlan={handleSelectPlan} period={plans.period} />
            )
            )}
        </div>
        <div className={`w-full flex flex-col absolute left-0 ${plans.period === "yearly" ? "top-[321px]" : "top-[337px]"}`}>
            <NextPrevSlider prev={prev} next={next} activeSlide={activeSlide} wrapperStyles="pt-6" />
            <Trusted wrapperClassName={`w-full !justify-center pt-[19px] max-lg:pt-[24px]`} />
        </div>
    </div>

}

export default RegistrationFunnelBEbayPlans;