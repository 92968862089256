import { yupResolver } from "@hookform/resolvers/yup";
import EarEmoji from "assets/images/ear-emoji.png";
import Button from "components/Button";
import ControlledInput from "components/Input/ControlledInput";
import { FC } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useSaveFeedbackMutation } from "store/api/onboardingApi";
import { object, string } from "yup";

interface Props {
    contStyles: string;
    goNextStep: () => void
}
interface FormValues {
    feedback: string
}

let schema = object({
    feedback: string().required("Please provide feedback"),
});


const GetFeedback: FC<Props> = ({ contStyles, goNextStep }) => {
    const [saveFeedback, { isLoading }] = useSaveFeedbackMutation()
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm<FormValues>({
        resolver: yupResolver(schema),
    });



    const onSubmit: SubmitHandler<FormValues> = async (values) => {
        saveFeedback(values.feedback).unwrap().then(goNextStep).catch(() => toast.error("Something went wrong"))
    }

    return (
        <form className={`${contStyles} lg:py-[42px] !mb-0 flex flex-col max-lg:items-between max-lg:h-[calc(100vh-160px)] max-sm:h-[calc(100vh-72px)] relative`}
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off">
            <div className="w-full h-full">
                <img src={EarEmoji} alt="" className="w-[45px] h-[45px] max-sm:w-[48px] max-sm:h-[48px] max-lg:w-[55px] max-lg:h-[55px] mx-auto mb-[25px] max-lg:mb-11 max-sm:mb-4" />
                <h2 className="text-xl font-bold text-center mb-2.5 max-lg:mb-8 max-lg:text-2xl max-sm:text-xl max-sm:mb-[56px]">
                    How did your hear about us?
                </h2>
                <div className="w-full mb-[53px]  max-lg:mb-[0px] max-w-[302px] max-lg:max-w-[536px] mx-auto  max-sm:max-w-[330px]">
                    <ControlledInput<FormValues>
                        placeholder="Type here"
                        name="feedback"
                        register={register}
                        erase
                        getValues={watch}
                        clearInputValue={() => setValue("feedback", "")}
                        className="h-[47px] max-lg:h-[57px] !pb-0"
                        fontSize="text-base"
                        errorClassName="absolute"
                        errors={errors}
                        maxLength={80}
                    />
                </div>
            </div>
            <Button title="Next" height="min-h-[64px] max-sm:min-h-[56px]" className={`max-w-[214px] max-lg:max-w-[100%] max-sm:max-w-[330px] max-sm:fixed max-sm:bottom-[32px] mb-0`} type="submit" loading={isLoading} />
        </form>
    )
}

export default GetFeedback