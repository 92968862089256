import Button from "components/Button";
import Card from "components/Card";
import { FC } from "react";

interface Props {
  title: string;
  handleClick: () => void;
  buttonTitle: string;
}
const JoinNow: FC<Props> = ({ title, handleClick, buttonTitle }) => (
  <Card
    className="flex flex-col items-center justify-center px-10 gap-5 h-[9.7rem] max-lg:py-10"
    bordered
  >
    <p className="text-center dark:text-grey100">{title}</p>
    <Button
      title={buttonTitle}
      color="smoothGreen"
      height="h-9"
      className="max-w-[16.125rem] dark:bg-deepSpace900"
      handleClick={handleClick}
    />
  </Card>
);

export default JoinNow;
