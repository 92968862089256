import Button from "components/Button";
import Card from "components/Card";
import ShopifyMarketplaceIcon from "components/MarketplaceIcon/Shopify";
import Toaster from "components/Toast";
import useDecodeToken from "hooks/useDecodeToken";
import useModal from "hooks/useModal";
import LoadingPage from "pages/Loading";
import CancelationFunnel from "pages/ManagementPanel/components/CancelationFunnel";
import PaymentHistory from "pages/ManagementPanel/lists/ManagePayments/PaymentHistory";
import { Link, useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import { useGetRemainingUsesQuery } from "store/api/bannersApi";
import { useGetUserPlanQuery } from "store/api/managePaymentApi";
import { formatDate } from "utils/formatTime";
import ShopifyBillingHeader from "./components/BillingHeader";

const ShopifyBilling = () => {
    const user = useDecodeToken();
    const currentPlan = user.shopify_package_name === "ShopProY" ? "yearly" : user.shopify_package_name === "ShopProM" ? "monthly" : "";
    const { data: spotsLeft, isLoading } = useGetRemainingUsesQuery("UNLIMITED");
    const isMonthly = currentPlan === "monthly"
    const cancelModal = useModal()
    const { data, isFetching } = useGetUserPlanQuery(null);
    const shopifyPlan = data?.result.find((item) => item.isShopify);
    const paymentMethod = shopifyPlan?.paymentMethod.replace("_", " ").toLowerCase()
    const isActivePlan = !shopifyPlan?.isCanceled && currentPlan !== "";
    const isTrial = /^true$/i.test(user.shopify_active_trail);
    const renewalDate = shopifyPlan?.nextPaymentDate || "N/A";
    const navigate = useNavigate();
    const cardStyles = `w-full h-[216px] border-2 border-solid border-secondary  p-5.5`
    const btnShadow = "shadow-[0px_0px_43.012px_0px_#8FFFF2,0px_0px_24.579px_0px_#8FFFF2,0px_0px_14.337px_0px_#8FFFF2,0px_0px_7.169px_0px_#8FFFF2,0px_0px_2.048px_0px_#8FFFF2,0px_0px_1.024px_0px_#8FFFF2]"

    return (
        <LoadingPage loading={isFetching}>
            <ShopifyBillingHeader />
            <div className="flex items-center gap-3 mb-5 max-xl:flex-col">
                <Card className={cardStyles} bg={`${isActivePlan ? "bg-white dark:bg-black900 dark:text-grey100 dark:border-grey700" : " dark:border-grey800 bg-neutral2 dark:bg-grey800 dark:text-grey600 text-grey300"}`}>
                    <div className="flex justify-between mb-6">
                        <div className="">
                            <h5 className="mb-6 text-darkBlue dark:text-grey100">My Subscription</h5>
                            <h5 className={`text-[28px] font-bold leading-[36px] capitalize ${isActivePlan ? "text-darkBlue dark:text-grey100" : "text-grey300 dark:text-grey600 "}`}>{currentPlan || "-"}</h5>
                        </div>
                        <ShopifyMarketplaceIcon isDisabled={!isActivePlan} />
                    </div>

                    <div className="flex justify-between items-end">
                        <div className="flex flex-col font-bold text-sm">
                            <p className="">Renewal date: <span className="font-normal">{formatDate(renewalDate)}</span></p>
                            <p className="">Payment method: <span className="font-normal capitalize">{paymentMethod}</span></p>
                            <p className="">Subscription status: <span className={`font-normal ${isActivePlan ? "text-smoothGreen" : "text-errorRed"}`}>{isActivePlan ? "Active" : "Canceled"}</span></p>
                        </div>
                        {isActivePlan ? (
                            <div className="flex justify-end gap-[26px]">
                                <button className="dark:hover:text-grey100 hover:bg-primaryPurple rounded-full text-sm hover:text-white dark:hover:bg-purple500 h-9 text-neutral3 min-w-[80px]" onClick={cancelModal.open}>Cancel</button>
                                <Link to={PATH.shopify.plans} className="py-2 px-4 hover:bg-primaryPurple rounded-full text-sm hover:text-white dark:hover:bg-purple500 dark:hover:text-lilac400" >Manage Subscription</Link>
                            </div>
                        ) : ""}
                    </div>

                </Card>
                {isMonthly || !isActivePlan ? (<Card className={`${cardStyles} dark:border-neutral3 bg-[linear-gradient(90deg,_#6BD8BF_0%,_#98E5E2_40.85%,_#BEF0E8_74.54%,_#B1EEFC_100%)] flex flex-col justify-between`}>
                    <div className="flex min-[1391px]:items-center max-[1390px]:flex-col justify-between">
                        <div className="text-darkBlue w-fit text-xl rounded-10 font-bold py-2.5 px-[7px]">
                            Upgrade to Yearly Plan and Save $60
                        </div>
                    </div>

                    <Button
                        title={isTrial ? "Change Now" : isActivePlan ? "Upgrade Now" : "Subscribe Now"}
                        className={`bg-white max-w-[250px] border-smoothGreen hover:bg-smoothGreen hover:border-smoothGreen dark:border-paradiseBlue dark:hover:!bg-paradiseBlue 
                        dark:hover:!border-paradiseBlue ${btnShadow}`}
                        titleClassName="dark:text-darkBlue group-hover:text-white !text-base"
                        isLink
                        redirect={isActivePlan ? PATH.shopify.upgrade : PATH.shopify.plans}
                        height="h-12" />
                </Card>) : <div className="w-full" />}
            </div>
            <PaymentHistory isShopify />

            {isActivePlan ?
                <CancelationFunnel helpers={cancelModal} onUpgrade={() => navigate(PATH.shopify.plans)} isShopify />
                : ""}
            <Toaster />
        </LoadingPage>
    )
}

export default ShopifyBilling;