import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import { ReactComponent as Save } from "assets/icons/save.svg";
import Button from "components/Button";
import useModal from "hooks/useModal";
import { FC, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useEbayCrDeleteMutation, useEbayCrSaveMutation, useSaveSearchMutation } from "store/api/watchListApi";
import SaveSearchModal from "./Modal";

interface Props {
  modal: ReturnType<useModal>;
  type: 0 | 1 | 2 | 4;
  searchedWord: string;
  title?: string;
  btnTitle?: string;
  customSaveSearch?: (comment: string) => void;
  CustomIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  tooltipTitle?: ReactNode | string;
  btnClassName?: string;
  modalBtnTitle?: string;
  isAdded: boolean;
  ebayCrSave?: boolean;
  sellerId?: number,
  handleCrWatchListToggle?: (val: boolean) => void
}

const SaveSearch: FC<Props> = ({
  modal,
  type,
  searchedWord,
  title,
  tooltipTitle,
  modalBtnTitle,
  customSaveSearch,
  CustomIcon,
  btnTitle,
  btnClassName,
  isAdded,
  ebayCrSave,
  sellerId,
  handleCrWatchListToggle

}) => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const onCommentChange = (newVal: string) => setComment(newVal);
  const [isWatching, setIsWatching] = useState(false);
  const onRatingChange = (rate: number) => setRating(rate);
  const [saveSearch, { isLoading }] = useSaveSearchMutation();
  const [ebayCompetitorSave, { isLoading: ebayCrLoading }] = useEbayCrSaveMutation();
  const [ebayCompetitorDelete, { isLoading: ebayCrDeleteLoading }] = useEbayCrDeleteMutation()
  const { t, } = useTranslation(['home']);
  const onWatch = () => setIsWatching(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAdded) {
      onWatch();
    } else {
      setIsWatching(false)
    }
  }, [isAdded]);

  const onSaveSearch = async () => {
    if (customSaveSearch) {
      customSaveSearch(comment)
    }
    if (ebayCrSave && sellerId) {
      ebayCompetitorSave({
        comment,
        searchedUserId: sellerId
      }).unwrap().then(() => {
        handleCrWatchListToggle?.(true);
      })
    }
    else {
      await saveSearch({
        comment,
        rating,
        type,
        sentWord: searchedWord,
        userId: 0,
      });
    }
    modal.close();
    document.body.style.overflow = "unset"; // for some reason modal close doesn't remove overflow from body , so doing it manually
    onWatch();
  };


  const removeFromWatchlist = () => {
    if (ebayCrSave && sellerId) {
      ebayCompetitorDelete({
        searchedUserId: sellerId
      }).unwrap().then(() => {
        handleCrWatchListToggle?.(false)
      });
    }
  }

  return (
    <>
      <Button
        title={isWatching ? (t('Watching')) : btnTitle || (t('SaveSearch'))}
        color={isWatching ? "darkBlue" : "smoothGreen"}
        variant={isWatching ? "contained" : "outlined"}
        tooltipTitle={tooltipTitle}
        handleClick={isWatching ? removeFromWatchlist : modal.open}
        Icon={isWatching ? Checkmark : CustomIcon || Save}
        iconClassName={isWatching ? "dark:fill-deepSpace900" : ""}
        titleClassName={isWatching ? "dark:text-deepSpace900 dark:font-bold" : ""}
        className={`${btnClassName || ""} ${isWatching ? "dark:bg-lilac400" : "bg-white dark:bg-deepSpace900 dark:hover:bg-paradiseBlue"}`}
        loading={ebayCrDeleteLoading}
        loadingClassName="fill-white"
      />
      <SaveSearchModal
        helpers={modal}
        rating={rating}
        onRatingChange={onRatingChange}
        comment={comment}
        onCommentChange={onCommentChange}
        isLoading={isLoading}
        modalBtnTitle={modalBtnTitle}
        title={title}
        onSaveSearch={onSaveSearch}
        ebayCrSave={ebayCrSave}
      />
    </>
  );
};

export default SaveSearch;


