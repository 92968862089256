import { toast } from "react-toastify";
import Tag from "./Tag";

interface Props {
    PlaceHolderIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
    placeholder?: string;
    tags: string[];
    addTag: (val: string) => void;
    removeTag: (index: number) => void;
    removeWithBackspace?: () => void;
}



const InputTags = ({ PlaceHolderIcon, placeholder, tags, addTag, removeTag, removeWithBackspace }: Props) => {

    const handleKeyDown = (e: any) => {
        const value = e.target.value;
        if (e.key === "Backspace" && !value.trim()) {
            removeWithBackspace?.();
            return;
        }
        if (e.key !== "Enter") return;
        if (!value.trim()) return;
        if (value.length > 200) {
            toast.warn("Keywords should contain maximum 200 characters.")
            return;
        }
        const keywordExist = (keyword: string) => keyword.toLowerCase() === e.target.value.toLowerCase();
        if (tags.some(keywordExist)) {
            e.target.value = ""
            return;
        }

        addTag(value);
        e.target.value = ""
    }

    const addTagOnBlur = (e: any) => {
        const value = e.target.value;
        if (!value.trim()) return;
        const keywordExist = (keyword: string) => keyword.toLowerCase() === e.target.value.toLowerCase();
        if (tags.some(keywordExist)) {
            e.target.value = ""
            return;
        }
        addTag(value);
        e.target.value = ""
    }

    return (
        <div className={`flex items-center flex-wrap rounded-[10px] min-h-[48px] px-[8px] break-all  ${tags.length ? "py-[8px]" : "py-[12px]"} outline-2 outline outline-darkBlue dark:outline-grey100 hover:outline-primaryPurple dark:hover:outline-purple500 w-full gap-[8px]`}>
            {tags.length > 0 &&
                tags.map((tag, i) => (
                    <Tag key={i} handleRemoveTag={removeTag} index={i} title={tag} />
                ))
            }

            <div className="inline-flex items-center gap-3.5 flex-1">
                {PlaceHolderIcon && tags.length < 1 ? (
                    <div className="inline-flex justify-center items-center w-[20px] h-[20px]">
                        <PlaceHolderIcon className="fill-neutral3 w-3 h-3" />
                    </div>
                ) : ""}
                <input
                    onKeyDown={handleKeyDown}
                    onBlur={addTagOnBlur}
                    className="placeholder:text-neutral3 dark:placeholder:text-grey300 w-full h-[20px] text-[14px] bg-transparent dark:text-white"
                    type="text"
                    maxLength={200}
                    placeholder={tags.length > 0 ? "" : placeholder || "Multiple keywords"} />
            </div>

        </div>
    )
}

export default InputTags