import { createApi } from "@reduxjs/toolkit/query/react";
import { queryTags } from "store/api/queryTags";
import {
  AddToFavouriteReq,
  AddToFavouriteRes,
  GetNewProductsRes,
  GetSalesHistoryReq,
  GetSalesHistoryRes,
  GetSalesSpyHistoryReq,
  GetSalesSpyHistoryRes,
  GetSalesSpySalesAndRevenueReq,
  GetSalesSpySalesAndRevenueRes,
  GetSalesSpyStoreDetailsRes,
  GetTopSellersListReq,
  GetTopSellersListRes,
  RemoveFavouriteReq,
  RemoveFavouriteRes,
  SalesSpyConnectAppsRes,
  SalesSpyDomainRes,
  SearchSalesSpyReq,
  SearchSalesSpyRes,
  WatchlistCommentReq,
  WatchlistCommentRes,
} from "types/services/salesSpy";
import baseQuery from "./baseQuery";
import { queryKeys } from "./queryKeys";

export const salesSpyApi = createApi({
  reducerPath: "salesSpyApi",
  baseQuery,
  tagTypes: [queryTags.salesSpy.list, queryTags.salesSpy.details],
  endpoints: (builder) => ({
    salesSpyHistory: builder.query<
      GetSalesSpyHistoryRes,
      GetSalesSpyHistoryReq
    >({
      query: (params) => ({
        url: queryKeys.salesSpy.history,
        method: "GET",
        params,
      }),
      providesTags: [queryTags.salesSpy.list],
    }),
    searchSalesSpy: builder.mutation<SearchSalesSpyRes, SearchSalesSpyReq>({
      query: (params) => ({
        url: queryKeys.salesSpy.search,
        method: "POST",
        params,
      }),
      invalidatesTags: [queryTags.salesSpy.list],
    }),
    updateWatchlistComment: builder.mutation<
      WatchlistCommentRes,
      WatchlistCommentReq
    >({
      query: (params) => ({
        url: queryKeys.salesSpy.watchlistComment,
        method: "PATCH",
        params,
      }),
    }),
    addToFavourite: builder.mutation<AddToFavouriteRes, AddToFavouriteReq>({
      query: (params) => ({
        url: queryKeys.salesSpy.favorite,
        method: "POST",
        params,
      }),
      invalidatesTags: [queryTags.salesSpy.list],
    }),
    removeFavourite: builder.mutation<RemoveFavouriteRes, RemoveFavouriteReq>({
      query: (params) => ({
        url: queryKeys.salesSpy.favorite,
        method: "DELETE",
        params,
      }),
      invalidatesTags: [queryTags.salesSpy.list],
    }),
    getStoreInfo: builder.query<SalesSpyDomainRes, number>({
      query: (domainId) => ({
        url: queryKeys.salesSpy.storeInfo(domainId),
        method: "GET",
      }),
    }),
    getStoreConnectedApps: builder.query<SalesSpyConnectAppsRes, number>({
      query: (domainId) => ({
        url: queryKeys.salesSpy.storeConnectedApps(domainId),
        method: "GET",
      }),
    }),
    getSalesAndRevenue: builder.query<
      GetSalesSpySalesAndRevenueRes,
      GetSalesSpySalesAndRevenueReq
    >({
      query: (params) => ({
        url: queryKeys.salesSpy.salesAndRevenue(params.similarwebdomainid),
        method: "GET",
        params,
      }),
    }),
    getStoreDetails: builder.query<
      GetSalesSpyStoreDetailsRes,
      GetSalesSpySalesAndRevenueReq
    >({
      query: (params) => ({
        url: queryKeys.salesSpy.details(params.similarwebdomainid),
        method: "GET",
        params,
      }),
      providesTags: [queryTags.salesSpy.details],
    }),
    getTopSellersList: builder.query<
      GetTopSellersListRes,
      GetTopSellersListReq
    >({
      query: ({ id, body }) => ({
        url: queryKeys.salesSpy.topSellers(id),
        method: "POST",
        body,
      }),
    }),
    getNewProductsList: builder.query<GetNewProductsRes, GetTopSellersListReq>({
      query: ({ id, body }) => ({
        url: queryKeys.salesSpy.newProducts(id),
        method: "POST",
        body,
      }),
    }),
    getSalesHistoryList: builder.query<GetSalesHistoryRes, GetSalesHistoryReq>({
      query: ({ id, body }) => ({
        url: queryKeys.salesSpy.salesHistory(id),
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useSearchSalesSpyMutation,
  useSalesSpyHistoryQuery,
  useRemoveFavouriteMutation,
  useAddToFavouriteMutation,
  useUpdateWatchlistCommentMutation,
  useGetSalesAndRevenueQuery,
  useGetStoreConnectedAppsQuery,
  useGetStoreInfoQuery,
  useGetStoreDetailsQuery,
  useGetSalesHistoryListQuery,
  useGetNewProductsListQuery,
  useGetTopSellersListQuery,
} = salesSpyApi;
