
import { monthly, yearly } from "assets/data/plans/plans";
import { shopifyPlans } from "assets/data/plans/shopifyPlans";
import { COOKIE_DOMAIN } from "config/constants";
import useModal from "hooks/useModal";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { TSelectedPlan } from "types/plans";
import RegFunnelWrapper from "./components/Wrapper";
import Introduction from "./steps/Introduction";
import Payment from "./steps/Payment";
import Questionnaire from "./steps/Questionnaire";
import SalesChannel from "./steps/SalesChannel";
import SelectPlan from "./steps/SelectPlan";

const RegistrationFunnel = () => {
  const [introStep, setIntroStep] = useState<0 | 1 | 2>(0);
  const [step, setStep] = useState(0);
  const [method, setMethod] = useState("")
  const modal = useModal();
  const [mainChannel, setMainChannel] = useState<number | null>(null);
  const [channels, setChannels] = useState<number[]>([]);
  const [cookie, setCookie, removeCookie] = useCookies(['plan', 'skip', "step", "introStep"]);
  const [isPricingFlow, setIsPricingFlow] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<TSelectedPlan>({
    type: "",
    planName: "",
    planPrice: 0,
    skip: false
  });

  useEffect(() => {
    const numIntroStep = Number(cookie.introStep || 0);
    const numStep = Number(cookie.step || 0)
    if (cookie.introStep && introStep !== numIntroStep) {
      if (numStep === 0) {
        setIntroStep(numIntroStep as 0 | 1 | 2);
      } else {
        setIntroStep(2);
      }
    }
    if (cookie.step && step !== numStep) {
      if (numStep === 4) {
        setStep(3);
      } else {
        setStep(numStep);
      }
    }
  }, []);

  const onStepChange = (newStep: number) => {
    setStep(newStep);
    setCookie("step", newStep, COOKIE_DOMAIN);
  };

  const onIntroStepChange = (newStep: 0 | 1 | 2) => {
    setIntroStep(newStep);
    setCookie("introStep", newStep, COOKIE_DOMAIN);
  }

  const handleNext = () => {
    onStepChange(step + 1)
  };
  const handleBack = () => {
    if (step === 0 && introStep === 1) { onIntroStepChange(0) }
    if (step === 0 && introStep === 2) { onIntroStepChange(1) }
    if (step > 0 && step !== 4) { onStepChange(step - 1); }
    if (step === 4) {
      if (method === "") {
        onStepChange(3);
      } else {
        setMethod("");
      }
    }

  };

  const onChannelChange = (newChans: number[]) => setChannels(newChans);
  const onMainChannelChange = (newChan: number | null) => setMainChannel(newChan);
  const onAnswerChange = (answers: string[]) => setSelectedAnswers(answers);
  const handleMethod = (type: string) => setMethod(type);
  const handleSelectPlan = (newVal: TSelectedPlan) => {
    setSelectedPlan(newVal);
    if (isPricingFlow) {
      onStepChange(2);
      setCookie("plan", newVal.type, COOKIE_DOMAIN);
      setCookie("skip", newVal.skip ? "true" : "false", COOKIE_DOMAIN)
    } else {
      handleNext();
    }
  };

  const handlePaymentBack = () => onStepChange(3);

  const componProps = {
    wrapper: {
      isPricingFlow,
      step,
      channels,
      introStep,
      handleBack,
      onStepChange,
      helpers: modal,
    },
    intro: {
      isPricingFlow,
      step,
      introStep,
      onStepChange,
      onIntroStepChange,
    },
    salesChannel: {
      channels,
      onChannelChange,
      mainChannel,
      onMainChannelChange,
      handleNext,
    },
    questionnaire: {
      isPricingFlow,
      selectedAnswers,
      onAnswerChange,
      handleNext,
    },
    payment: {
      isPricingFlow,
      selectedPlan,
      method,
      handleMethod,
      handleBack: handlePaymentBack,
    },
    selectPlan: {
      mainChannel,
      selectedPlan,
      handleSelectPlan
    }
  }

  useEffect(() => {
    if (cookie.plan) {
      const skip = cookie.skip === "true" ? true : false
      setIsPricingFlow(true);
      if (cookie.plan.includes("ShopPro")) {
        const plan = shopifyPlans.find((item) => item.type === cookie.plan);
        setSelectedPlan({
          planName: plan?.name || "",
          planPrice: plan?.price || 0,
          type: cookie.plan,
          skip
        })
      } else {
        const plan = [...monthly.planList, ...yearly.planList].find((item) => item.type === cookie.plan);
        setSelectedPlan({
          planName: plan?.planName || "",
          planPrice: plan?.planPrice || 0,
          type: cookie.plan,
          skip
        });
      }
    }
  }, [cookie]);

  const isShowMobileOverflowAuto = (isPricingFlow && step === 1) || step === 2 || step === 3 || step === 4;
  return (
    <RegFunnelWrapper {...componProps.wrapper}>
      <div className={`overflow-y-auto sm:scrollbar w-full ${isShowMobileOverflowAuto ? "" : "max-sm:overflow-y-hidden"}`}>
        {step === 0 && <Introduction {...componProps.intro} />}
        {isPricingFlow ? <>
          {step === 1 && <Questionnaire {...componProps.questionnaire} isShopifySelected={cookie.plan.includes("ShopPro")} isEbaySelected={cookie.plan.length > 0 && !cookie.plan.includes("ShopPro")} />}
          {step === 2 && <Payment {...componProps.payment} />}
        </> : <>
          {step === 1 && <SalesChannel {...componProps.salesChannel} />}
          {step === 2 && <Questionnaire {...componProps.questionnaire} />}
        </>}
        {step === 3 && <SelectPlan {...componProps.selectPlan} />}
      </div>
      {step === 4 && !isPricingFlow && <Payment {...componProps.payment} />}
    </RegFunnelWrapper>

  );
};
export default RegistrationFunnel;
