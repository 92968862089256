export const sortFromMax = (array: string[] | number[]) => {
  let numArray = array.map(Number);
  numArray.sort(function (a, b) {
    return b - a;
  });
  return numArray;
};

export const sortFromMin = (array: string[] | number[]) => {
  let numArray = array.map(Number);
  numArray.sort(function (a, b) {
    return a - b;
  });
  return numArray;
};
