import { ReactComponent as ZikPro } from "assets/icons/zik-pro.svg";
import { FC, ReactNode } from "react";

interface RowProps {
  title: string;
  subtitle: string | ReactNode;
}
export const FeaturesRow: FC<RowProps> = ({ title, subtitle }) => {
  return (
    <div className="flex flex-col text-sm">
      <div className="flex items-center gap-[5px]">
        <div className="min-w-[30px] h-[30px] flex items-center justify-center">
          <ZikPro className="fill-primaryPurple dark:fill-purple500 w-[17px] shrink-0" />
        </div>
        <div className="font-bold">{title}</div>
      </div>
      <div className="text-xs ml-[35px]">{subtitle}</div>
    </div>
  )
}

const ProPlus = () => (
  <div className="flex flex-col gap-4">
    <div className="font-medium text-sm px-2">
      All PRO plan features, and:
    </div>
    <FeaturesRow title="500 Best Selling eBay Items" subtitle="Find out who the best sellers are" />
    <FeaturesRow title="Bulk Scanner" subtitle={
      <span>Instantly find thousands<br className="hidden max-[1060px]:block max-[965px]:hidden" /> of Amazon and Walmart<br className="hidden max-[1060px]:block max-[965px]:hidden" /> dropship items</span>
    } />
    <FeaturesRow title="Ali Growth Scanner" subtitle=" Easily gather data on trends coming from AliExpress" />
  </div>
);

export default ProPlus;
