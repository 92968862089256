import { ReactComponent as Globe } from "assets/icons/globe.svg";
import InfoTooltip from "components/Card/InfoTooltip";
import Scrollbar from "components/Scrollbar";
import { FC } from "react";
import { Option } from "types/common";
import { SHIPPED_MODEL, SOURCE_MARKET_PLACES } from "types/sellerSettings";

import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg";
import useModal from "hooks/useModal";
import useOutsideClick from "hooks/useOutsideClick";
interface DropdownProps {
  options: Option[];
  selectedValue?: SHIPPED_MODEL | null | SOURCE_MARKET_PLACES | string;
  handleCountryChange?: (
    value: SOURCE_MARKET_PLACES | SHIPPED_MODEL | null | string
  ) => void;
}

const LanguageSelect: FC<DropdownProps> = ({
  options,
  selectedValue,
  handleCountryChange,
}) => {
  const { isOpen, toggle, close } = useModal();
  const [ref] = useOutsideClick<HTMLDivElement>(close);

  const handleOptionClick = (val: string) => {
    close();
    handleCountryChange && handleCountryChange(val)
  }

  return (
    <div className="relative w-fit" ref={ref}>
      <button
        className={`group cursor-pointer w-fit flex items-center gap-2 px-2 py-[5px] pl-1.5 pr-2.5 rounded-full uppercase border-2 border-smoothGreen dark:border-paradiseBlue
        ${isOpen ? " bg-smoothGreen dark:bg-paradiseBlue" : "dark:bg-transparent dark:hover:bg-paradiseBlue hover:bg-smoothGreen bg-white"}`}
        onClick={toggle}
      >
        <Globe className={`w-2.5 h-2.5  ${isOpen ? "dark:fill-deepSpace900 fill-white" : "dark:group-hover:fill-deepSpace900 group-hover:fill-white fill-darkBlue dark:fill-grey100"}`} />
        <span className={`text-xs font-alexandria ${isOpen ? "dark:text-deepSpace900 text-white" : "dark:group-hover:text-deepSpace900 group-hover:text-white text-darkBlue dark:text-grey100"}`} >
          {selectedValue}
        </span>
        <ArrowDown
          className={`transition-transform duration-600 w-2 ${isOpen ? "rotate-180 dark:fill-deepSpace900 fill-white" : "dark:group-hover:fill-deepSpace900 group-hover:fill-white fill-darkBlue dark:fill-grey100"}`}
        />
      </button>

      {isOpen &&
        <div
          className={`py-2 px-[5px] bg-white absolute top-9 rounded-2xl  z-20 shadow-lg w-full border-[#CBD5E0] text-sm border dark:bg-deepSpace900 dark:border-grey700`}
        >
          <Scrollbar as="ul" className={`"max-h-[20rem]"} overflow-x-hidden pr-1`}>
            {options &&
              options.map((item, i) => (
                <li
                  key={item.value + i}
                  className={`py-1.5 pl-3 rounded-full hover:text-white border-2 cursor-pointer hover:bg-smoothGreen dark:hover:bg-paradiseBlue dark:text-grey100 hover:dark:text-deepSpace900
                hover:border-smoothGreen dark:hover:border-paradiseBlue mb-1 ${selectedValue === item.value ? "border-smoothGreen dark:border-paradiseBlue" : "border-transparent"}`}
                  onClick={() => item.value !== "none" && handleOptionClick(item.value)}
                >
                  <InfoTooltip title={item.text} place="right">
                    {item.value.toUpperCase()}
                  </InfoTooltip>
                </li>
              ))}
          </Scrollbar>

        </div>}
    </div>
  );
};

export default LanguageSelect;
