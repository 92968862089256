import HandEmoji from "assets/images/hand-emoji.png";
import Button from "components/Button";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import { managePaymentsApi } from "store/api/managePaymentApi";
import { queryTags } from "store/api/queryTags";
import { DASHBOARD_MARKET_PLACES } from "store/slices/marketplaceSlice";


interface Props {
    date?: string
}
const CancelSuccess: FC<Props> = ({ date }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    const { dashboardMarketplace } = useAppSelector((state) => state.marketplaceSlice);
    const homeRedir = dashboardMarketplace === DASHBOARD_MARKET_PLACES.shopify ? PATH.shopify.marketInsights : PATH.dashboard.root;
    const handleHomeClick = () => {
        dispatch(
            managePaymentsApi.util.invalidateTags([queryTags.managePayments.subscription])
        );
        navigate(homeRedir);
    }

    return (
        <div className="flex flex-col items-center w-full" >
            <img src={HandEmoji} alt="" className="text-center mb-4 w-10 h-10" />
            <h4 className="text-xl font-semibold mb-2.5 text-center">We’ve proceeded with your cancelation!</h4>
            <p className="text-center text-lg font-medium leading-[28px] mb-[47px]">
                Thank you for trying ZIK Analytics. <br />
                You’ll be able to continue using the software until your renewal date <span className="text-primaryPurple dark:text-purple500">{date || "-"}</span>
            </p>
            <Button title="My Dashboard" className="max-w-[250px] bg-darkBlue text-smoothGreen hover:bg-smoothGreen hover:border-smoothGreen dark:text-lilac400 dark:border-lilac400 dark:bg-deepSpace900 dark:hover:bg-paradiseBlue dark:hover:border-paradiseBlue dark:hover:text-paradiseBlue hover:!text-darkBlue font-bold" titleClassName="!text-base font-700" height="h-16"
                handleClick={handleHomeClick}
            />
        </div>
    )
}

export default CancelSuccess