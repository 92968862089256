import { ReactComponent as ExpDate } from "assets/icons/exp-date.svg";
import Button from "components/Button";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PATH } from "routes/path";
import { managePaymentsApi, useLazySubmbitCreditCardQuery } from "store/api/managePaymentApi";
import { queryTags } from "store/api/queryTags";
import { setDashboardMarketplace } from "store/slices/marketplaceSlice";
import { setUserAccessToken } from "store/slices/userSlice";
import { gtmOnboardingPaymentSubmit } from "utils/googleTagManager";
import { SetAccessToken, SetMarketplace } from "utils/localStorage";
var cardLogo = document.querySelector("#card-logo") as HTMLInputElement;

interface Props {
  selectedPlan: string;
  coupon: string;
  skip: boolean;
  fromRegistration: boolean;
  handleAlreadyTrial?: (isTrial: boolean) => void;
  handleApplyCoupon?: (newVal: string) => void;
  isPayNow?: boolean;
  handleStopPay?: () => void;
  onCCPaymentLoading?: (isLoading: boolean) => void;
  alreadyTrial?: boolean;
  amount?: number;
  isShopify?: boolean;
}

declare global {
  interface Window {
    bluesnap: any;
    gpfToken: any;
  }
}
interface cardUrl {
  AMEX: string;
  DINERS: string;
  DISCOVER: string;
  JCB: string;
  MASTERCARD: string;
  VISA: string;
}
interface FormError {
  firstName?: string,
  lastName?: string,
  address?: string,
  city?: string,
  zip?: string,
  cvv?: string
  exp?: string;
  ccn?: string
}

const validationErrors = {
  firstName: "First Name is required",
  lastName: "Last Name is required",
  address: "Address is required",
  addressLength: "Address should be between 2 and 100 characters long.",
  city: "City is required",
  zip: "Zip Code is required",
  ccn: "Credit Card Number is required",
  exp: "Please enter your credit card's expiration date",
  cvv: "Please enter the CVV/CVC of your card"
}

const getErrorText = (errorCode: string, tag: string) => {
  switch (errorCode) {
    case "10":
      if (tag === "ccn") return "Please enter a valid credit card number";
      if (tag === "exp")
        return "Please enter your credit card's expiration date";
      if (tag === "cvv") return "Please enter the CVV/CVC of your card";
      break;
    case "400":
      return "Session expired please refresh page to continue";
    case "14101":
      return "3D Secure authentication failed - please try using different credit card.";
    case "14100":
      return "3D Secure authentication failed - please try using different credit card.";
    case "14040":
      return "Security token expired, please refresh the page.";
    default:
      return "Internal server error please try again later";
  }
};

var cardsObj: cardUrl = {
  AMEX: "https://files.readme.io/97e7acc-Amex.png",
  DINERS: "https://files.readme.io/8c73810-Diners_Club.png",
  DISCOVER: "https://files.readme.io/caea86d-Discover.png",
  JCB: "https://files.readme.io/e076aed-JCB.png",
  MASTERCARD: "https://files.readme.io/5b7b3de-Mastercard.png",
  VISA: "https://files.readme.io/9018c4f-Visa.png",
};

const checkName = (name: string) => {
  let r = false;
  name.trim().indexOf(" ") != -1 ? (r = true) : (r = false);

  return r;
};

const CreditCard = ({ isShopify, selectedPlan, coupon, skip, fromRegistration, handleAlreadyTrial, handleApplyCoupon, isPayNow, handleStopPay, onCCPaymentLoading, alreadyTrial = false, amount }: Props) => {
  const dispatch = useAppDispatch();
  const dataFetchedRef = useRef(false);
  const navigate = useNavigate();
  const [focusedField, setFocusedField] = useState("")
  const [errors, setErrors] = useState<FormError>({});
  const [isSubmitted, setIsSubmitted] = useState(false)
  const { dashboardMarketplace } = useAppSelector((state) => state.marketplaceSlice);
  const [ccAlreadyUsed, setCcAlreadyUsed] = useState(alreadyTrial);
  const [cardHolderInfo, setCardHolderInfo] = useState({
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    zip: "",
    exp: ""
  });



  const handleInfoChange = (evt: any) => {
    const { value, name } = evt.target;
    const field = name as keyof FormError
    setCardHolderInfo({
      ...cardHolderInfo,
      [name]: value,
    });
    if (isSubmitted) {
      setErrors((prevErrors) => {
        const errs = prevErrors
        if (value) {
          delete errs[field]
        } else {
          errs[field] = validationErrors[field]
        }

        return errs
      })
    }
  };

  const [SubmitCard, { isLoading }] = useLazySubmbitCreditCardQuery();
  const onExclusiveCoupon = () => {
    dispatch(
      managePaymentsApi.util.invalidateTags([
        queryTags.managePayments.defaultCoupon,
      ])
    );
    handleApplyCoupon && handleApplyCoupon("");
    onCCPaymentLoading && onCCPaymentLoading(false)
  }

  var threeDSecureObj = {
    amount: amount || 0,
    currency: 'USD'
  };

  function submitCC() {
    window.bluesnap.hostedPaymentFieldsSubmitData(function (callback: any) {
      let auth_Res = "AUTHENTICATION_FAILED";
      auth_Res = callback.threeDSecure.authResult;
      if (auth_Res === "AUTHENTICATION_SUCCEEDED" || auth_Res === "AUTHENTICATION_BYPASSED" || auth_Res === "AUTHENTICATION_UNAVAILABLE") {
        if (null != callback.cardData) {
          SubmitCard({
            isShopify,
            fullName: `${cardHolderInfo.firstName} ${cardHolderInfo.lastName}`,
            pfToken: window.gpfToken,
            cardType: callback.cardData.ccType,
            lastFourDigits: callback.cardData.last4Digits,
            expirationDate: callback.cardData.exp,
            address: cardHolderInfo.address,
            zipCode: cardHolderInfo.zip,
            city: cardHolderInfo.city,
            requestedSubscriptionType: selectedPlan,
            issuingCountry: callback.cardData.issuingCountry,
            coupon: coupon,
            creditCardAlreadyUsed: ccAlreadyUsed,
            amount: amount || 0,
            skipTrial: skip,
          })
            .unwrap()
            .then((data) => {
              if (data?.result.message?.includes("Exclusive Coupon")) {
                onExclusiveCoupon()
              }
              else if (data?.result.message === "AlreadyTrial") {
                handleAlreadyTrial && handleAlreadyTrial(true);
                setCcAlreadyUsed(true);
                dispatch(
                  managePaymentsApi.util.invalidateTags([queryTags.managePayments.subscription])
                );
                dispatch(
                  managePaymentsApi.util.invalidateTags([
                    queryTags.managePayments.defaultCoupon,
                  ])
                );
                dispatch(
                  managePaymentsApi.util.invalidateTags([
                    queryTags.managePayments.checkSubscriptionRegistration,
                  ])
                );
                onCCPaymentLoading && onCCPaymentLoading(false)

              }
              else {
                if (fromRegistration) {
                  gtmOnboardingPaymentSubmit(); // this submit evenet to google tag manager
                }
                SetAccessToken(data?.result.claims);
                dispatch(setUserAccessToken(data?.result.claims));
                onCCPaymentLoading && onCCPaymentLoading(false);
                navigate(PATH.purchaseComplete[isShopify ? "shopifyCreditCard" : "creditcard"]);
                const changeMarketplace = (marketplace: "ebay" | "shopify") => {
                  dispatch(setDashboardMarketplace(marketplace))
                  SetMarketplace(marketplace);
                }
                if (!fromRegistration) {
                  if (dashboardMarketplace === "ebay" && isShopify) {
                    changeMarketplace("shopify")
                  }

                  if (dashboardMarketplace === "shopify" && !isShopify) {
                    changeMarketplace("ebay")
                  }
                }

              }

            })
            .catch((error) => {
              if (error.data.message?.includes("Exclusive Coupon")) {
                onExclusiveCoupon()
              }
              else {
                onCCPaymentLoading && onCCPaymentLoading(false)
                toast.error("Something went wrong! Please check the payment details and try again.");

              }
            });
        } else {

        }
      }
      else if (auth_Res === "AUTHENTICATION_FAILED") {
        toast.error("3D Secure authentication failed - please try again");
      }


    }, threeDSecureObj);
  }
  const validateForm = () => {
    let validation: FormError = {
      ...(errors.ccn && {
        ccn: errors.ccn
      }),
      ...(errors.exp && {
        exp: errors.exp
      }),
      ...(errors.cvv && {
        cvv: errors.cvv
      }),
    };
    setIsSubmitted(true)
    if (!cardHolderInfo.firstName.length || checkName(cardHolderInfo.firstName)) {
      validation.firstName = validationErrors.firstName;
    } else {
      delete validation.firstName
    }
    if (!cardHolderInfo.lastName.length) {
      validation.lastName = validationErrors.lastName;
    } else {
      delete validation.lastName
    }
    if (!cardHolderInfo.address) {
      validation.address = validationErrors.address;
    } else {
      delete validation.address
    }
    if (cardHolderInfo.address.length < 2 || cardHolderInfo.address.length > 100) {
      validation.address = validationErrors.addressLength;
    } else {
      delete validation.address
    }
    if (!cardHolderInfo.city) {
      validation.city = validationErrors.city;
    } else {
      delete validation.city
    }
    if (!cardHolderInfo.zip) {
      validation.zip = validationErrors.zip;
    } else {
      delete validation.zip
    }

    setErrors(validation);
    if (Object.values(validation).length < 1) {
      submitCC()
      onCCPaymentLoading && onCCPaymentLoading(true)
    }

  };

  useEffect(() => {
    if (isPayNow) {
      validateForm();
      // submitCC();
    }

    return () => handleStopPay && handleStopPay()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPayNow])

  var bsObj = {
    token: window.gpfToken,
    onFieldEventHandler: {
      setupComplete: function () {
      },
      threeDsChallengeExecuted: function () {
      },
      onFocus: function (tagId: any) {
        setFocusedField(tagId)
      },
      onBlur: function (tagId: any, errorCode: any) {
        setFocusedField("")
        if (errorCode === "10") {
          const validation = errors
          if (tagId === "ccn") {
            delete validation.ccn
          } else if (tagId === "exp") {
            delete validation.exp

          } else if (tagId === "cvv") {
            delete validation.cvv
          }
        }
      },
      onError: function (
        tagId: keyof FormError,
        errorCode: any
      ) {
        const errorText = getErrorText(errorCode, tagId);
        toast.error(errorText);
        onCCPaymentLoading && onCCPaymentLoading(false)
      },
      onEmpty: function (
        tagId: keyof FormError,
        errorCode: any
      ) {
        if (errorCode === "10") {
          const validation = errors
          if (tagId === "ccn") {
            delete validation.ccn

          } else if (tagId === "exp") {
            delete validation.exp

          } else if (tagId === "cvv") {
            delete validation.cvv
          }
        }
      },
      onType: function (tagId: keyof FormError, cardType: keyof typeof cardsObj) {
        cardLogo.src = cardsObj[cardType];
      },
      onEnter: function () {
      },
      onValid: function () {
      },
    },
    style: {
      input: {
        "font-size": "14px",
        "font-family": "Alexandria, sans-serif",
        "font-weight": "600",
        color: "#150d3f",
      },
      ":focus": {
        color: "#150d3f",
      },
      "::-webkit-input-placeholder": {
        //style only ccn element
        "color": "#B0B6BD",
      },
    },
    ccnPlaceHolder: "1234 5678 9012 3456",
    cvvPlaceHolder: "123",
    expPlaceHolder: "MM/YY",
    '3DS': true
  };

  const labelClassName = "text-base font-medium leading-[24px] cursor-pointer max-sm:text-sm"
  const inputContClassName = "flex flex-col w-full gap-2.5"
  const inputClassName = (hasError: boolean) => `h-11 border-2 dark:bg-deepSpace900 !pb-0 px-[15px] rounded-[7px] text-sm ${hasError ? "border-errorRed" : "focus:border-primaryPurple hover:border-primaryPurple dark:focus:border-purple500 dark:hover:border-purple500 dark:border-grey100 border-secondary"}`
  const errorClassName = "text-errorRed text-xs"


  useEffect(() => {
    if (!dataFetchedRef.current) {
      window.bluesnap?.hostedPaymentFieldsCreate(bsObj);
      dataFetchedRef.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h6 className="text-base font-bold leading-[24px] mb-[5px] max-sm:text-center">Add Payment Details</h6>
      <p className="text-sm leading-[20px] mb-2.5 max-sm:text-center">Please enter your payment details below</p>
      <form
        autoComplete="off"
        noValidate
        className="mb-4 flex flex-col mb-[25px]"
      >
        <div className="flex flex-col max-sm:flex-col-reverse gap-2.5">
          <div className="grid grid-cols-2 gap-[46px] max-lg:gap-[30px] max-sm:gap-2.5">
            <div className={inputContClassName}>
              <label htmlFor="firstName" className={labelClassName}>First Name</label>
              <input
                className={inputClassName(Boolean(errors?.firstName))}
                name="firstName"
                placeholder="Steve"
                id="firstName"
                onChange={handleInfoChange}
              />
              {errors?.firstName && (
                <p className={errorClassName}>
                  {errors.firstName}
                </p>
              )}
            </div>
            <div className={inputContClassName}>
              <label htmlFor="lastName" className={labelClassName}>Last Name</label>
              <input
                className={inputClassName(Boolean(errors?.lastName))}
                name="lastName"
                placeholder="Jobs"
                id="lastName"
                onChange={handleInfoChange}
              />
              {errors?.lastName && (
                <p className={errorClassName}>
                  {errors.lastName}
                </p>
              )}
            </div >
          </div>
          <div>
            <div className="flex gap-[46px] max-lg:gap-[30px] max-sm:gap-2.5 mb-2.5 max-sm:flex-col">
              <div className={inputContClassName}>
                <label className={labelClassName}>Card Number</label>
                <div
                  data-bluesnap="ccn"
                  className={`flex flex-row-reverse items-center max-sm:pl-[15px] max-[440px]:pl-[30px] pl-[35px] ${inputClassName(false)} ${focusedField === "ccn" ? "!border-primaryPurple dark:!border-purple500" : ""}`}
                >
                  <div className="w-5 shrink-0">
                    {/* eslint-disable-next-line jsx-a11y/alt-text */}
                    <img
                      id="card-logo"
                      src="https://files.readme.io/d1a25b4-generic-card.png"
                    />
                  </div>
                </div>
                {errors?.ccn && (
                  <p className={errorClassName}>
                    {errors.ccn}
                  </p>
                )}
              </div>

              <div className="flex gap-[46px] max-lg:gap-[30px] max-sm:gap-[10px] w-full">
                <div className={inputContClassName}>
                  <label className={`${labelClassName} flex items-center justify-between max-w-[110px]`}>
                    <span className="block">Exp. Date</span>
                    <div className="w-5.5 h-5.5 flex items-center justify-center">

                      <ExpDate className="fill-darkBlue dark:fill-grey100" />
                    </div>
                  </label>
                  <div
                    data-bluesnap="exp"
                    className={`${inputClassName(false)} ${focusedField === "exp" ? "!border-primaryPurple dark:!border-purple500" : ""}`}
                  />
                  {errors?.exp && (
                    <p className={errorClassName}>
                      {errors.exp}
                    </p>
                  )}
                </div>

                <div className={inputContClassName}>
                  <label htmlFor="cvv" className={`!leading-[22px] ${labelClassName}`}>CVV</label>
                  <div
                    id="cvv"
                    data-bluesnap="cvv"
                    className={`${inputClassName(false)}  ${focusedField === "cvv" ? "!border-primaryPurple !border-purple500" : ""}`}
                  />
                  {errors?.cvv && (
                    <p className={errorClassName}>
                      {errors.cvv}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-[46px] max-lg:gap-[30px] max-sm:grid-cols-1 max-sm:gap-2.5">
              <div className={`${inputContClassName}`}>
                <label htmlFor="address" className={labelClassName}>Address</label>
                <input
                  className={inputClassName(Boolean(errors?.address))}
                  name="address"
                  id="address"
                  placeholder="Avenue des Champs-Élysées"
                  onChange={handleInfoChange}
                />
                {errors?.address && (
                  <p className={errorClassName}>
                    {errors.address}
                  </p>
                )}
              </div>

              <div className="grid grid-cols-2 gap-[46px] max-lg:gap-[30px] max-sm:gap-2.5">
                <div className={inputContClassName}>
                  <label htmlFor="city" className={labelClassName}>City</label>
                  <input
                    type="text"
                    className={inputClassName(Boolean(errors.city))}
                    name="city"
                    id="city"
                    placeholder="France"
                    onChange={handleInfoChange}
                  />
                  {errors?.city && (
                    <p className={errorClassName}>
                      {errors.city}
                    </p>
                  )}
                </div>
                <div className={inputContClassName}>
                  <label htmlFor="zip" className={labelClassName}>Zip Code</label>
                  <input
                    type="text"
                    placeholder="2000-876"
                    className={inputClassName(Boolean(errors.zip))}
                    name="zip"
                    id="zip"
                    onChange={handleInfoChange}
                  />
                  {errors?.zip && (
                    <p className={errorClassName}>
                      {errors.zip}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          !fromRegistration ? (
            <Button
              className="mt-6 mb-[7px] w-full mx-auto bg-darkBlue !cursor-pointer hover:bg-smoothGreen hover:border-smoothGreen dark:bg-lilac400 dark:border-lilac400 dark:hover:!bg-paradiseBlue dark:hover:!border-paradiseBlue"
              titleClassName="!text-base !font-bold text-smoothGreen group-hover:text-darkBlue dark:text-darkBlue"
              height="h-[56px]"
              title="Subscribe"
              loading={isLoading}
              handleClick={validateForm}
            />
          ) : ""
        }
      </form >
    </>

  );
};

export default CreditCard;
