import { PlansType, TSelectedPlan } from "types/plans";
import Plan from "./Plan";

interface Props {
  plans: PlansType;
  isTrial?: boolean;
  plansWrapperClassName?: string;
  handleSelectPlan?: (newVal: TSelectedPlan) => void;
  handleProToolUpgrade?: () => void;
  fromRegistration?: boolean;
  fromNoSubscription?: boolean;
  buttonFilled?: boolean;
  alreadyTrial?: boolean;
  isPayNow?: boolean;
  isCouponUsed?: boolean;
  alwaysUpgrade?: boolean;
}

const Plans = ({
  plans,
  plansWrapperClassName,
  isTrial,
  handleSelectPlan,
  handleProToolUpgrade,
  fromRegistration = false,
  buttonFilled,
  isPayNow,
  isCouponUsed,
  fromNoSubscription = false,
  alwaysUpgrade = false
}: Props) => (
  <div
    className={`max-w-[1026px] mx-auto w-full flex justify-center gap-[40px] max-[1060px]:gap-[30px] max-[965px]:flex-wrap dark:text-darkBlue ${plansWrapperClassName || ""
      }`}
  >
    {plans.planList.map((plan, i) => (
      <Plan
        key={i}
        plan={plan}
        handleSelectPlan={handleSelectPlan}
        handleProToolUpgrade={handleProToolUpgrade}
        fromRegistration={fromRegistration}
        isTrial={isTrial}
        isPayNow={isPayNow}
        buttonFilled={buttonFilled}
        isCouponUsed={isCouponUsed}
        fromNoSubscription={fromNoSubscription}
        alwaysUpgrade={alwaysUpgrade}
      />
    ))}
  </div>
);

export default Plans;
