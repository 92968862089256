export const turboScannerSuppliers = {
  1: "Amazon US",
  2: "Amazon UK",
  3: "Amazon DE",
  5: "Amazon FR",
  4: "Walmart",
  6: "Amazon CA",
  9: "Amazon AU",
  10: "Amazon IT",
};

export const turboScannerInfo = [
  {
    filterName: "Supplier",
    filterValues: ["Amazon.co.uk"],
  },
  {
    filterName: "Minimum Rating",
    filterValues: ["1 star"],
  },
  {
    filterName: "No. Products",
    filterValues: ["200"],
  },
];
