import AffiliateCreateAccount from "pages/AffiliateCreateAccount";

import EndTrial from "pages/Loading/EndTrial";
import ResetCC from "pages/Loading/ResetCC";
import YorayReset from "pages/Loading/YorayReset";
import Login from "pages/Login";
import ResetPassword from "pages/Login/ResetPassword";
import Maintenance from "pages/Maintenance";
import PurchaseComplete from "pages/ManagementPanel/components/ManagePayments/PurchaseComplete";
import Registration from "pages/Registration";
import { Navigate } from "react-router-dom";
import { PATH } from "routes/path";

const handleRedir = (newRoute: string) => {
    if (newRoute.includes("redir")) return newRoute
    else return `${PATH.login}?redir=${newRoute.replace("?", "&")}`
}

export const authRoutes = (isTest: boolean, currLink: string) => [
    {
        path: PATH.login,
        children: [{ path: PATH.login, element: <Login /> }],
    },
    {
        path: PATH.registration,
        element: <Registration />,
    },
    {
        path: PATH.registrationB,
        element: <Registration />,
    },
    {
        path: PATH.affiliateCreateAccount,
        element: <AffiliateCreateAccount />,
    },
    {
        path: PATH.requestPassword,
        element: <ResetPassword isRequest={true} />,
    },
    {
        path: PATH.resetPassword,
        element: <ResetPassword isReset={true} />,
    },
    {
        path: PATH.maintenance,
        element: <Maintenance />
    },
    isTest ? {
        path: PATH.yorayppccreset,
        element: <YorayReset />
    } : {},
    isTest ? {
        path: PATH.endTrial,
        element: <EndTrial />
    } : {},
    isTest ? {
        path: PATH.resetcc,
        element: <ResetCC />
    } : {},
    {
        path: PATH.managementPanel.billing,
        element: <Navigate to={handleRedir(currLink)} />,
    },
    {
        path: PATH.managementPanel.upcampaign,
        element: <Navigate to={handleRedir(currLink)} />,
    },
    {
        path: "*",
        element: <Navigate to={handleRedir(currLink)} />,
    },
    {
        path: "/",
        element: <Login />
    },
    {
        path: PATH.purchaseComplete.payoneer,
        element: <PurchaseComplete isPayoneer={true} />,
    },
];
