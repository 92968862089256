export const risky_keywords = [
  "21 day fix",
  "ab lounge",
  "ak47",
  "amy carinn",
  "austin city limits",
  "aviator",
  "baby bandana drool",
  "babybotte",
  "bear armz tactical",
  "berenguer dolls",
  "blowgun",
  "boba",
  "bonnie plants",
  "bubby babies",
  "bushcraft essentials",
  "carlashes",
  "casablanca",
  "catalyst case",
  "Ear Cleaner",
  "tool kit",
  "channel network audio/video surround receiver with bluetooth and wi-fi",
  "chicwrap",
  "clarisonic",
  "cozy sack",
  "creatology",
  "cruise tags luggage",
  "crutcheze",
  "cryso-jad",
  "crystal allies gallery",
  "cuddle baby",
  "cushcins",
  "dark horse comics",
  "deep cleansing oil",
  "designer skin",
  "dreamwear nasal mask",
  "drill brush",
  "dt moto",
  "eames office",
  "ecosmart",
  "diaper",
  "cigarette",
  "elgy kitchen",
  "emjoi",
  "epionce",
  "ergobaby",
  "excelmark",
  "fat gripz",
  "flex belt",
  "foodvacbags",
  "for homegear",
  "fulvio bianconi",
  "gear tie",
  "go pro camera - woodman labs",
  "gourmet fruit and nut gift tray",
  "gozbit",
  "grill beast",
  "grill floss",
  "grillaholics",
  "grilldoc",
  "grillfloss",
  "grizzly",
  "gummy bear mold",
  "hatchery",
  "hausbell",
  "heirloom finds",
  "higear design",
  "hoop for select brother machines",
  "illumibowl",
  "ink and toner",
  "intervideo",
  "jdmastar",
  "lawn mower cover",
  "lego juris",
  "live animal trap",
  "lock pick",
  "locksmith",
  "lunchbots",
  "m16",
  "magic hue",
  "meal prep haven stackable",
  "meaningful beauty",
  "metropolitan lighting",
  "moen",
  "mountain buggy",
  "mpd digital",
  "neet cables",
  "neetcables",
  "new sunshine",
  "nicer dicer",
  "nuskin/ pharmanex",
  "online fabric store",
  "ontel products",
  "originalbedband",
  "packit",
  "packrats collectibles",
  "patu cable ties",
  "peleg",
  "plush lined desk eyeglass",
  "pmd personal microderm",
  "pocket hose",
  "pokemon go game tips, cheats, plus, download guide unofficial",
  "polybubble",
  "powernet",
  "pro g-flyer party toy",
  "procella",
  "prostar deals",
  "pugsterjewelry",
  "purneat",
  "real flame",
  "rean a brand of neutrik ag",
  "refurbished",
  "riorand",
  "rolbak",
  "roleff",
  "ropri",
  "sado-nation",
  "sandvik",
  "self balancing",
  "selk'bag",
  "serious steel assisted pull-up bands",
  "serious steel fitness",
  "sharkskinzz",
  "sivan health",
  "Surebonder",
  "solta medical",
  "sports hoop",
  "sports tek",
  "spyder",
  "squatty potty",
  "stable step",
  "star kids",
  "star shower",
  "star shower motion",
  "starshower",
  "studio banana things",
  "stx international",
  "summer escapes skimmer",
  "sunny health",
  "survival shovel",
  "swagway",
  "t-shirt bordello",
  "teeter",
  "teeter hang ups",
  "teeter hangups",
  "tens machine",
  "torero",
  "torero inflatables",
  "traeger",
  "training mask",
  "traxxas 6852x",
  "trollbeads",
  "trophy",
  "united scope",
  "urepair",
  "ulead",
  "wise foods",
  "weathertech",
  "web brands",
  "walkydog",
  "warner home video",
  "vortex optics",
  "voltseasy",
  "vivre cosmetiques",
  "viper",
  "visalus",
  "visalus vi-shape",
  "versipack",
  "varylala",
  "velvet skin coat",
  "woodstock amazing grace chime",
  "world by shotglass",
  "woss trainer",
  "x-tronic",
  "crema",
  "ammo",
  "Lubricant",
  "Himalayan",
  "police",
  "Fuel",
  "Pyure",
  "Cannabis",
  "KONG",
  "creme",
  "Military",
  "Treats",
  "Treat",
  "Growth Vitamins",
  "Vitamin",
  "lotion",
  "engine",
  "formula",
  "raw",
  "pistol",
  "sex",
  "tactical",
  "wyze",
  "assault",
  "assault rifle",
  "uncommon laundry",
  "useless box",
  "vampire squid cards",
  "Pull Ups",
  "Draper",
  "Testosterone",
  "sterols",
  "TESTOSTEROL",
  "BIOSTEROL",
  "slipping locks",
  "lock slipping",
  "Laughing Gas",
  "Milwaukee",
  "REDLITHIUM",
  "Smart Ear Cleaner",
  "Spiral Earpick",
  "Orthodontic",
  "patent",
  "patented",
  "trademark",
  "©",
  "Alcohol is not for sale",
  "This bladed product is not for sale",
  "drinkaware.co.uk",
  "facts about alcohol",
  "Prop Money",
  "bladed product",
  "Quad LNB",
  "Sky quad",
  "legacy satellite",
  "Hybrid LNB",
  "Sky installations",
  "Age Verification Required on Delivery",
  "This product is not for sale",
  "not for sale to people under the age of 18",
  "Testosterone Booster",
  "hydrogen peroxide",
  "zinc peroxide",
  "Rugby World Cup",
  "ANGLE-IZER",
  "Angleizer",
  "smoke detector",
  "carbon monoxide detector",
  "Smoke Alarm",
  "vaping products",
  "This product is not for sale to people under the age of 18",
  "Smok",
  "Netflix",
  "Catit Design Senses",
  "Ryan’s World",
  "CBD Oil",
  "Dan TDM",
  "Hill's Prescription Diet",
  "CND Vinylux",
  "Oxygen Mask",
  "Kids Safety Head Support Hugger",
  "Toddler Car Seat",
  "FSC Certified",
  "FSC-approved",
  "Lightning Cable",
  "Uno",
  "HDMI Cable",
  "HDMI Adapter",
  "HDMI Extension Cable",
  "HDMI Lead",
  "HDMI Splitter",
  "HDMI Switch",
  "HDMI to HDMI",
  "HDMI Converter",
  "HDMI To DVI Cable",
  "carbon monoxide",
  "Knife",
];

export default risky_keywords;
