import { shopifyHelpVideos } from "assets/data/helpVideos"
import { ReactComponent as DropshipperIcon } from "assets/icons/dropshipper.svg"
import { ReactComponent as PrintOnDemandIcon } from "assets/icons/print-on-demand.svg"
import { ReactComponent as SingleProductIcon } from "assets/icons/single-products.svg"
import { ReactComponent as InstagramIcon } from "assets/icons/social/instagram.svg"
import { ReactComponent as MetaIcon } from "assets/icons/social/meta.svg"
import { ReactComponent as PinterestIcon } from "assets/icons/social/pinterest.svg"
import { ReactComponent as SnapchatIcon } from "assets/icons/social/snapchat.svg"
import { ReactComponent as TikTokIcon } from "assets/icons/social/tiktok.svg"
import { ReactComponent as TwitterIcon } from "assets/icons/social/twitter.svg"
import { ReactComponent as YoutubeIcon } from "assets/icons/social/youtube.svg"
import { storeFinderCols } from "assets/tableColumns/storeFinder"
import Card from "components/Card"
import Header from "components/Header"
import HeaderHelpVideo from "components/Header/HelpVideo"
import HeaderIcon, { HeaderVariants } from "components/Header/Icon"
import HeaderTab from "components/Header/Tab"
import Table from "components/Table"
import Toaster from "components/Toast"
import { useAppDispatch } from "hooks/useAppDispatch"
import { useAppSelector } from "hooks/useAppSelector"
import useFilters from "hooks/useFilters"
import useScrollPosition from "hooks/useScrollPosition"
import { SortDir as SortDirection } from "hooks/useSort"
import useTable from "hooks/useTable"
import { useCallback, useEffect, useRef, useState } from "react"
import Scrollbars from "react-custom-scrollbars-2"
import { toast } from "react-toastify"
import { shopifyApi, useLazyStoreFinderSearchQuery } from "store/api/shopifyApi"
import { MinMax } from "types/common"
import { StoreFinderList } from "types/services/storeFinder"
import Filters from "./components/Filters"
import TableLoading from "./components/TableLoading"


export const storeFinderSalesDateRange = [
    { text: "7 Days", value: "7" },
    { text: "14 Days", value: "14" },
    { text: "30 Days", value: "30" },
    { text: "60 Days", value: "60" }
]

export const storeFinderStoreType = [
    { text: "Dropshipping", value: "dropshipping", icon: DropshipperIcon, iconClassName: "text-darkBlue dark:text-purple500 group-hover:text-white" },
    { text: "One Product Store", value: "singleproduct", icon: SingleProductIcon, iconClassName: "text-darkBlue dark:text-purple500 group-hover:text-white" },
    { text: "Print on Demand", value: "print", icon: PrintOnDemandIcon, iconClassName: "text-darkBlue dark:text-purple500 group-hover:text-white" },
    { text: "Others", value: "others" }
];

export const storeFinderSocialChannels = [
    { text: "Meta/Facebook", value: "meta", icon: MetaIcon, iconClassName: "w-[17px] h-[11px]" },
    { text: "Instagram", value: "instagram", icon: InstagramIcon, iconClassName: "w-[13px] h-[13px]" },
    { text: "TikTok", value: "tiktok", icon: TikTokIcon, iconClassName: "w-[10px] h-[12px]" },
    { text: "Twitter", value: "twitter", icon: TwitterIcon, iconClassName: "w-[12px] h-[11px] dark:fill-white fill-deepSpace900" },
    { text: "Youtube", value: "youtube", icon: YoutubeIcon, iconClassName: "w-[15px] h-[11px] fill-[#F61C0D]" },
    { text: "Pinterest", value: "pinterest", icon: PinterestIcon, iconClassName: "w-[13px] h-[13px] fill-[#CB2027]" },
];

export const storeFinderAdsChannel = [
    { text: "Meta/Facebook", value: "metaAds", icon: MetaIcon, iconClassName: "w-[17px] h-[11px]" },
    { text: "TikTok", value: "tiktokAds", icon: TikTokIcon, iconClassName: "w-[10px] h-[12px] dark:fill-white" },
    { text: "Pinterest", value: "pinterestAds", icon: PinterestIcon, iconClassName: "w-[13px] h-[13px]" },
    { text: "Snapchat", value: "snapchatAds", icon: SnapchatIcon, iconClassName: "w-[13px] h-[13px] fill-[#FFFC00]" },
]


export interface StoreFinderState {
    searchByKeywords: string[];
    excludeKeywords: string[];
    country: string[];
    language: string[];
    currency: string[];
    salesDateRange: string;
    sales: MinMax;
    revenue: MinMax;
    activeProducts: MinMax;
    storeType: string[];
    socialChannels: string[];
    adsChannels: string[];
}

export const storeFinderFiltersState: StoreFinderState = {
    searchByKeywords: [],
    excludeKeywords: [],
    country: [],
    language: [],
    currency: [],
    salesDateRange: "30",
    sales: {
        min: null,
        max: null
    },
    revenue: {
        min: null,
        max: null
    },
    activeProducts: {
        min: null,
        max: null
    },
    storeType: [],
    socialChannels: [],
    adsChannels: []

}


enum SortDir {
    asc = "asc",
    desc = "desc",
}

const initialSort = {
    direction: SortDir.desc,
    column: "SoldProducts",
};

const StoreFinder = () => {
    const { filters, setFilters, handleFilterChange } = useFilters(storeFinderFiltersState);
    const [sort, setSort] = useState(initialSort);
    const [storeFinderSearch, { isLoading, isFetching }] = useLazyStoreFinderSearchQuery()
    const [showFilters, setShowFilters] = useState(true);
    const toggleFilters = () => {
        setShowFilters(!showFilters);
    }
    const { isExpand } = useAppSelector((state) => state.themeSlice);
    const dispatch = useAppDispatch();
    const [pageNumber, setPageNumber] = useState(1);
    const [enableScrollTrigger, setEnableScrollTrigger] = useState(false);

    const [tableList, setTableList] = useState<StoreFinderList[]>([]);
    const tableRef = useRef<HTMLDivElement>(null)
    const scrollPos = useScrollPosition()
    const showHorizontalScrollbar = scrollPos > (tableRef.current?.getBoundingClientRect().top || 0);
    const scrollToTableTop = () => {
        let inter = setInterval(f, 10);
        function f() {
            if (tableRef.current !== null) {
                tableRef.current.scrollIntoView();
                clearInterval(inter);
            }
        }
    }

    const handleSort = (column: string) => {
        const dir =
            sort.column === column
                ? sort.direction === SortDir.desc
                    ? SortDir.asc
                    : SortDir.desc
                : SortDir.asc;

        setSort({
            column,
            direction: dir,
        });
    };

    const getData = (type: string) => {
        if (type === "click") {
            setPageNumber(1);
            setEnableScrollTrigger(false);
            dispatch(shopifyApi.util.resetApiState());
            setTableList([])
        }
        const {
            searchByKeywords,
            excludeKeywords,
            country,
            language,
            currency,
            salesDateRange,
            sales,
            revenue,
            activeProducts,
            storeType,
            socialChannels,
            adsChannels
        } = filters

        setTimeout(() => {
            storeFinderSearch({
                excludedKeywords: excludeKeywords.join() || null,
                keywords: searchByKeywords.join() || null,
                countries: country.join() || null,
                pageNumber: type === "click" ? 1 : pageNumber,
                pageSize: 20,
                orderBy: sort.column,
                direction: sort.direction,
                languages: language.join() || null,
                currencies: currency.join() || null,
                salesDateRange: Number(salesDateRange) || null,
                salesMin: sales.min,
                salesMax: sales.max,
                revenueMin: revenue.min,
                revenueMax: revenue.max,
                activeProductsMin: activeProducts.min,
                activeProductsMax: activeProducts.max,
                dropshipping: storeType.includes("dropshipping") || null,
                singleProductStore: storeType.includes("singleproduct") || null,
                printOnDemand: storeType.includes("print") || null,
                others: storeType.includes("others") || null,
                socialFacebook: socialChannels.includes("meta") || null,
                socialInstagram: socialChannels.includes("instagram") || null,
                socialTikTok: socialChannels.includes("tiktok") || null,
                socialTwitter: socialChannels.includes("twitter") || null,
                socialYouTube: socialChannels.includes("youtube") || null,
                socialPinterest: socialChannels.includes("pinterest") || null,
                adsFacebook: adsChannels.includes("metaAds") || null,
                adsTikTok: adsChannels.includes("tiktokAds") || null,
                adsPinterest: adsChannels.includes("pinterestAds") || null,
                adsSnapchat: adsChannels.includes("snapchatAds") || null

            }).unwrap().then(data => {
                if (!data.result.stores.length) {
                    if (type === "click") {
                        toast.warning("No stores have been found, please try using different filters");
                        setTableList([]);
                        setEnableScrollTrigger(false);

                        return;
                    }
                    toast.warning("No more data have been found under this filters");
                    setEnableScrollTrigger(false);

                    return;

                }

                if (type === "click") {
                    setTableList(data.result.stores);
                    scrollToTableTop();
                }
                if (type === "scroll") {
                    setTableList(prevData => [...prevData, ...data.result.stores]);
                }
                setEnableScrollTrigger(true);
            })
        }, 100)
    }


    const { table } = useTable({
        columns: storeFinderCols(handleSort),
        columnDeps: [sort],
        sort: true,
        data: tableList,
        dataDeps: [tableList],
    });


    const observer = useRef<IntersectionObserver | null>(null);
    const scrollTrigger = useCallback((node: any) => {
        if (isFetching) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && enableScrollTrigger) {
                setPageNumber(prevPageNumber => prevPageNumber + 1)
            }
        })
        if (node) observer.current.observe(node)
    }, [isFetching, enableScrollTrigger])


    useEffect(() => {
        if (enableScrollTrigger) {
            getData("scroll")
        }

    }, [pageNumber])

    useEffect(() => {
        if (tableList.length) { //prevent call on pageload
            getData("click")
        }

    }, [sort])

    return (
        <>

            <Header
                leftContent={
                    <HeaderTab
                        title="Store Finder"
                        isActive={true}
                        className="!min-w-[86px]"
                    />
                }
                rightContent={
                    <>
                        <HeaderIcon
                            variant={HeaderVariants.filters}
                            handleClick={toggleFilters}
                            tooltipTitle="Show or Hide Filters."
                        />
                        <HeaderHelpVideo videos={shopifyHelpVideos.storeFinder} tooltip={'Watch "Store Finder" Tutorial'} />
                    </>
                }
                middleContentClassName="max-w-[570px] w-full"
                className="!px-5 mb-[20px]"
            />

            {showFilters &&
                <Filters filters={filters} setFilters={setFilters} handleFilterChange={handleFilterChange} onSearchClick={getData} isLoading={isFetching} />
            }
            <div ref={tableRef}>
                <Card className="py-3.5 px-3.5" shadow="shadow-0">
                    <Scrollbars style={{ width: "100%", }} autoHeight autoHeightMax={"100%"}
                        renderTrackHorizontal={() => <div className="h-[10px] fixed right-[45px] bottom-[10px]" style={{ left: isExpand ? 230 : 95, display: showHorizontalScrollbar ? "block" : "none" }} />}
                        renderThumbHorizontal={() => <div className="h-[10px] relative rounded-full bg-secondary cursor-pointer" />}
                    >
                        <Table table={table}
                            isLoading={isFetching}
                            customLoading={<TableLoading numberOfRows={4} />}
                            sort={{ ...sort, direction: sort.direction === "asc" ? "asc" as SortDirection : "desc" as SortDirection }}
                            styles={{
                                px: "px-0",
                                tableClassName: "w-full min-w-[1240px]",
                                head: {
                                    cont: "px-[16px] pl-[16px]"
                                },
                                body: {
                                    rowHeight: "h-[81px]",
                                    cont: "px-3.5"

                                },

                            }} />


                    </Scrollbars>
                    <div ref={scrollTrigger} />
                </Card>
            </div>




            <Toaster />
        </>
    )
}

export default StoreFinder