import Button from "components/Button";

// Icons
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as Lock } from "assets/icons/lock.svg";
import ControlledInput from "components/Input/ControlledInput";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useGetUserDetailsQuery, useUpdatePasswordMutation } from "store/api/quickSettingsApi";
import { object, ref, string } from "yup";


interface FormValues {
  password: string;
  confirmPassword: string;
}

let passwordSchema = object({
  password: string()
    .required("Password is Required")
    .min(5, "Must be between 5 and 20 characters")
    .max(20, "Must be between 5 and 20 characters"),
  confirmPassword: string()
    .required("Confirm Password is Required")
    .min(5, "Must be between 5 and 20 characters")
    .max(20, "Must be between 5 and 20 characters")
    .oneOf([ref("password"), ""], "Passwords must be equal"),
});

const ChangePassword = () => {
  const { data } = useGetUserDetailsQuery();
  const userId = data?.result.userId || 0
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(passwordSchema),
  });
  const [updatePassword, { isLoading }] = useUpdatePasswordMutation();
  const { t } = useTranslation(['home']);
  const onSave: SubmitHandler<FormValues> = async (values) => {
    await updatePassword({ password: values.password, userId })
      .unwrap()
      .then((payload) => {
        if (payload) {
          toast.success("Password successfully changed");
        }
      });
  };
  const inputProps = (name: "password" | "confirmPassword") => ({
    className: `bg-white rounded-[7px] !py-2.5 pl-6 pr-3.5 dark:bg-deepSpace900 dark:border-grey100`,
    type: "password",
    variant: "contained" as "contained",
    togglePassword: true,
    name,
    register,
    inputClassName: "autofill:bg-transparent",
    errors,
  })

  return (
    <div className="p-6 bg-neutral2 rounded-[10px] max-w-[276px] w-full max-[1080px]:max-w-[50%] max-[1080px]:flex-1 max-[767px]:max-w-[100%]  max-[767px]:flex-auto dark:bg-grey800 dark:border-2 dark:border-grey700">
      <div className="flex items-center gap-[22px] font-bold text-darkBlue dark:text-grey100">
        <Lock width={11} className="fill-darkBlue dark:fill-grey100" />
        <div>{t('ChangePassword')}</div>
      </div>

      <form
        className="flex flex-col gap-[19px] mt-[46px]"
        onSubmit={handleSubmit(onSave)}
      >
        <ControlledInput<FormValues>
          placeholder="Password"
          {...inputProps("password")}
        />
        <ControlledInput
          placeholder="Confirm Password"
          {...inputProps("confirmPassword")}
        />
        <Button
          title="Change Password"
          loading={isLoading}
          type="submit"
          height="h-11"
          className="w-full ml-auto mt-[56px] bg-white dark:bg-deepSpace900"
        />
      </form>
    </div>
  );
};

export default ChangePassword;
