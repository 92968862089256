import Tooltip from "components/Tooltip";
import { COMING_SOON } from "config/constants";
import { FC, ReactNode } from "react";
import { Option } from "types/common";
import cutLongTitle from "utils/cutLongTitle";

interface Props {
    value?: string
    cutLongOption?: number
    listItemClassName?: string
    handleOptionClick: (value: string, text: string) => void;
    item: Option;
    imageOption?: ReactNode;
    isActive?: boolean;
    noDisabledOptionTooltip?: boolean;
}


const SelectOption: FC<Props> = ({ value, item, listItemClassName, handleOptionClick, cutLongOption, imageOption, isActive, noDisabledOptionTooltip }) => {
    const isComingSoon = item.value === "none"
    return (
        <li
            data-tooltip-id={item.value}
            className={`flex items-center pl-3 rounded-full border-2 min-h-[36px] text-sm hover:border-primaryPurple dark:hover:border-purple500 mb-1 ${listItemClassName || ""} ${isComingSoon ?
                "cursor-not-allowed !border-0 bg-neutral1 text-neutral3 dark:bg-black900" : `hover:bg-primaryPurple dark:hover:bg-purple500 dark:hover:text-lilac400 cursor-pointer hover:text-white 
                ${value === item.value || isActive ? "border-primaryPurple dark:border-purple500" : "border-transparent"}
                ${imageOption ? "group" : ""}
                `
                }`}
            onClick={() => !isComingSoon && handleOptionClick(item.value, item.text)}
        >
            {(
                cutLongOption ? cutLongTitle(item.text, cutLongOption) : imageOption ? imageOption : item.text
            )}
            {((cutLongOption && (item.text || "").length > cutLongOption) || isComingSoon) && !noDisabledOptionTooltip ? (
                <Tooltip
                    title={isComingSoon ? COMING_SOON : (item.text || "")}
                    anchorId={item.value}
                    place={isComingSoon ? "right" : "top"}
                />
            ) : ""}
        </li>
    )
}


export default SelectOption