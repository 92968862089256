import { ReactComponent as Notification } from "assets/icons/notification.svg";
import HoveredIcon from "components/HoveredIcon";
import Notifications from "components/Modal/Notifications";
import useModal from "hooks/useModal";
import useOutsideClick from "hooks/useOutsideClick";
import { useEffect, useState } from "react";
import { useUnreadAnnouncementsQuery } from "store/api/newDashboardApi";

const HeaderNotifications = () => {
  const modal = useModal();
  const [isEmpty, setIsEmpty] = useState(true)
  const [ref] = useOutsideClick<HTMLDivElement>(modal.close);
  const { data } = useUnreadAnnouncementsQuery()
  useEffect(() => {

    if (data) {
      setIsEmpty(data.result !== 0 ? false : true)
    }

  }, [data])

  return (
    <div className="relative" ref={ref}>
      <HoveredIcon
        Icon={Notification}
        handleClick={modal.isOpen ? modal.close : modal.open}
        className={`${modal.isOpen ? `!border-primaryPurple dark:!border-purple500` : ""} ${isEmpty ? "" : `hover:!border-errorRed ${modal.isOpen ? "!border-errorRed" : ""}`}`}
        iconColor={`${isEmpty ? modal.isOpen ? "fill-primaryPurple dark:fill-purple500" : "fill-darkBlue dark:fill-grey100" : "fill-errorRed group-hover:!fill-errorRed"} `}
        iconClassName="w-[21px] h-[21px]"
        width="w-9"
        height="h-9"
        title="Notifications"
      />
      {isEmpty ? "" : <div className={`w-1.5 h-1.5 bg-errorRed rounded-full absolute right-0 top-0`} />}
      <Notifications helpers={modal} />
    </div>
  );
};

export default HeaderNotifications;
