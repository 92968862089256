export const ENVIRONMENT_NAME =
  process.env.REACT_APP_ENVIRONMENT_NAME || "Unknown";

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const LANDING_URL = process.env.REACT_APP_REGISTRATION;

export const SLIDER_PROPS = (slidesToShow?: number) => ({
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: slidesToShow || 3,
  slidesToScroll: 1,
  arrows: false,
  className: "center",
})
export const SEARCH_DEBAUNCE_TIME = 500;
export const APP_URL = process.env.REACT_APP_URL;
export const LANDING_APP_URL = process.env.REACT_APP_LANDINGPAGE;
export const ORTTO_TRACKING_URL = process.env.REACT_APP_ORTTO_TRACKING_URL;
export const ORTTO_KEY = process.env.REACT_APP_ORTTO_KEY;
export const KLAVIYO_PUBLIC_API = process.env.REACT_APP_KLAVIYO_PUBLIC_API || "";
export const BLUESNAP_SCRIPT = process.env.REACT_APP_BLUESNAP_SCRIPT || "";
export const BEAMER_PRODUCT_ID = process.env.REACT_APP_BEAMER_PRODUCT_ID || "";

