import Button from "components/Button";
import FilterCheckbox from "components/Filters/FilterCheckbox";
import Loading from "components/Loading";
import Modal from "components/Modal";
import Scrollbar from "components/Scrollbar";
import useModal from "hooks/useModal";
import { FC } from "react";
import { useTurboGetCatsQuery } from "store/api/turboScannerApi";

interface Props {
  helpers: ReturnType<useModal>;
  value: string[];
  handleChange: (newVal: string) => void;
  type: string;
}

const TurboSelectCategories: FC<Props> = ({
  helpers,
  handleChange,
  value,
  type,
}) => {
  const { data, isFetching } = useTurboGetCatsQuery(type);
  // const { data: filterValues } = useTurboScanPrevFiltersQuery();
  const list = data
    ? data.result.map((item) => ({
      value: item.checkBoxValue.toString(),
      text: item.categoryName,
    }))
    : [];

  const col1List = list.slice(0, list.length / 2);
  const col2List = list.slice(list.length / 2);

  return (
    <Modal
      helpers={helpers}
      className="flex flex-col items-center "
      cardClassName="py-[26px]"
      maxWidth="max-w-[636px]"
      buttonCloseClassName="!top-[25px] right-[26px]"
      title="Select Category"
      shouldCloseOnOverlayClick={false}
    >
      <Scrollbar
        className={`max-h-[548px] min-h-[200px] flex justify-center gap-2.5 mr-3 pr-3 mb-6 pl-[30.5px] max-md:flex-col`}
      >
        {isFetching ? (
          <div className="!h-full flex justify-center items-center">
            <Loading fill="fill-primaryPurple" width={40} height={40} />
          </div>
        ) : (
          <>
            <div className="w-full flex gap-[9px] flex-col">
              <FilterCheckbox
                options={[{ text: "Select All", value: "all" }]}
                value={data?.result.length === value.length ? "all" : ""}
                className="w-full"
                handleChange={(newValue: string | number) => {
                  handleChange(newValue.toString());
                }}
              />
              <FilterCheckbox
                options={col1List}
                multiselect
                value={value}
                className="w-full !gap-[9px]"
                handleChange={(newValue: string | number) => {
                  handleChange(newValue.toString());
                }}
              />
            </div>

            <FilterCheckbox
              options={col2List}
              multiselect
              value={value}
              className="w-full !gap-[9px]"
              handleChange={(newValue: string | number) =>
                handleChange(newValue.toString())
              }
            />
          </>
        )}
      </Scrollbar>
      <div className="flex w-full justify-center">
        <Button
          title="Save"
          handleClick={helpers.close}
          color="primaryPurple"
          height="h-9"
          className="max-w-[129px]"
        />
      </div>
    </Modal>
  );
};

export default TurboSelectCategories;
