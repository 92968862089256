import { saleDateRangeArr } from "assets/data/filters";
import { ReactComponent as Dollar } from "assets/icons/dollar.svg";
import { ReactComponent as Hammer } from "assets/icons/hammer.svg";
import { ReactComponent as Sales } from "assets/icons/sales.svg";
import { ReactComponent as Shipping } from "assets/icons/shipping.svg";
import Button from "components/Button";

import Card from "components/Card";
import FilterSelect from "components/Filters/FilterSelect";
import MinMaxDateFilter from "components/Filters/MinMaxDateFilter";
import MinMaxFilter from "components/Filters/MinMaxFilter";
import MinMaxFilterCard from "components/Filters/MinMaxFilterCard";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface FilterState {
  salesRange: string;
  sales: {
    min: string;
    max: string;
  };
  price: {
    min: string;
    max: string;
  };
  profit: {
    min: string;
    max: string;
  };
  uploadDate: {
    min: null | Date;
    max: null | Date;
  };
}

interface Props {
  isNotFoundItems?: boolean;
  filters: FilterState;
  onFilter: () => void;
  handleFilterChange: (
    newVal: string | number | Date | null,
    name: keyof FilterState,
    type?: "min" | "max"
  ) => void;
}

const verticalLineStyles = "max-h-[67px] h-full  rounded-full bg-secondary dark:bg-deepSpace900 max-lg:hidden my-auto  !min-w-[3px]"
const FiltersCard: FC<Props> = ({
  isNotFoundItems,
  onFilter,
  filters,
  handleFilterChange,
}) => {
  const { t } = useTranslation(['home']);

  return (
    <Card
      className="flex max-xl:flex-wrap max-xl:gap-4 py-[11px] xl:px-2 mb-4"
      bordered
    >
      <div className="flex justify-evenly w-full max-lg:flex-wrap max-lg:gap-y-8">
        <FilterSelect
          title={`${filters.salesRange} Days Sales`}
          className="w-fit dark:text-grey100"
          Icon={Shipping}
          iconClassName="w-4.5 dark:fill-grey100"
          value={filters.salesRange}
          handleChange={(newVal) => handleFilterChange(newVal, "salesRange")}
          options={saleDateRangeArr}
          infoTitle="Filter items by sales range. 30 days, 21, 14 & 7 days period"
          infoTitleClassName="!right-0"
        />
        <div className={verticalLineStyles} />
        <MinMaxDateFilter
          value={filters.uploadDate}
          inputWidth="w-[8rem]"
          iconClassName="w-[15px] h-3.5 dark:fill-grey100"
          inputWrapperClassName="mx-8 gap-2.5"
          title={t('ItemUploadDate')}
          className="max-md:w-full max-lg:w-1/2 dark:text-grey100"
          type="date"
          Icon={Hammer}
          handleChange={(newVal, type) =>
            handleFilterChange(newVal, "uploadDate", type)
          }
          infoTitle="Filter items by upload date"
        />
        <div className={verticalLineStyles} />
        <MinMaxFilterCard title={t('Price')} Icon={Dollar} className="gap-4" infoTitle="Filter items by price"
          iconClassName="w-[15px]"
        >
          <MinMaxFilter
            value={filters.price}
            inputWidth="w-16"
            inputGap="gap-5"
            inputWrapperClassName="mt-0.5"
            name="price"
            handleChange={(newVal, type) => {
              handleFilterChange(newVal, "price", type);
            }}

          />
        </MinMaxFilterCard >
        <div className={verticalLineStyles} />
        {!isNotFoundItems && (
          <>
            <MinMaxFilterCard title={t('Profit')} Icon={Dollar} className="gap-4" infoTitle="Filter items by the profit"
              iconClassName="w-3.5 h-3.5"
            >
              <MinMaxFilter
                value={filters.profit}
                inputWrapperClassName="mt-0.5"
                name="price"
                inputWidth="w-16"
                inputGap="gap-5"
                handleChange={(newVal, type) => {
                  handleFilterChange(newVal, "profit", type);
                }}

              />
            </MinMaxFilterCard>
            <div className={verticalLineStyles} />
          </>
        )}

        <MinMaxFilterCard title={t('Sales')} Icon={Sales} iconClassName="w-3.5 h-[13px]" className="gap-4" infoTitle="Filter items by the total number of sales they've generated since being listed">
          <MinMaxFilter
            value={filters.sales}
            name="sales"
            inputGap="gap-5"
            inputWrapperClassName="mt-0.5"
            inputWidth="w-16"
            handleChange={(newVal, type) => {
              handleFilterChange(newVal, "sales", type);
            }}

          />
        </MinMaxFilterCard>
        <Button
          title="Apply Filters"
          color="primaryPurple"
          height="h-11"
          className="max-w-[10rem] my-auto dark:bg-deepSpace900"
          handleClick={onFilter}
        />
      </div>
    </Card>
  )
};

export default FiltersCard;
