export const locationList = [
  { text: "US", value: "EBAY-US" },
  { text: "UK", value: "EBAY-GB" },
  { text: "Australia", value: "EBAY-AU" },
  { text: "Austria", value: "EBAY-AT" },
  { text: "Belgium", value: "EBAY-NLBE" },
  { text: "Canada", value: "EBAY-ENCA" },
  { text: "eBayMotors", value: "EBAY-MOTOR" },
  { text: "France", value: "EBAY-FR" },
  { text: "Germany", value: "EBAY-DE" },
  { text: "Hong Kong", value: "EBAY-HK" },
  { text: "India", value: "EBAY-IN" },
  { text: "Ireland", value: "EBAY-IE" },
  { text: "Italy", value: "EBAY-IT" },
  { text: "All Location", value: "" },
  { text: "Netherlands", value: "EBAY-NL" },
  { text: "Philippines", value: "EBAY-PH" },
  { text: "Poland", value: "EBAY-PL" },
  { text: "Singapore", value: "EBAY-SG" },
  { text: "Spain", value: "EBAY-ES" },
  { text: "Switzerland", value: "EBAY-CH" },
];
