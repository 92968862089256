// Icons
import { ColumnDef } from "@tanstack/react-table";
import { CURRENCY_SIGNS, formatNumber } from "utils/formatCurrency";
// Icons
import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";
import { ReactComponent as More } from "assets/icons/more.svg";
import ProductNameCell from "components/ProductNameCell";
import TableCheckbox from "components/Table/TableCheckbox";
import CompetitorCount from "pages/CompetitorResearch/components/CompetitorCount";
import ProductItemMore from "pages/MyProducts/components/modal/ProductItemMore";
import ProductEditField from "pages/MyProducts/components/ProductEditField";
import { CURRENCY } from "types/currency";
import { FolderItemsList } from "types/services/folders";
import { formatCurrency } from "utils/formatCurrency";
import getProperDomain from "utils/getProperDomain";

export const myProductItems = (
  isAliexpress: boolean,
  handleCountChange: (count: number, id: string) => void,
  handleSort: (column: string) => void
): ColumnDef<FolderItemsList>[] => [
    {
      header: ({ table }) => (
        <TableCheckbox
          {...{
            checked: table.getIsAllPageRowsSelected(),
            indeterminate: table.getIsSomePageRowsSelected(),
            onChange: table.getToggleAllPageRowsSelectedHandler(),
          }}
        />
      ),
      minSize: 60,
      accessorKey: "singleScannedItemId",
      enableSorting: false,
      cell: ({ row }) => (
        <TableCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
      meta: {
        alignHead: "justify-center"
      }
    },
    {
      header: () => <More className="fill-darkBlue dark:fill-grey100" />,
      minSize: 60,
      enableSorting: false,
      accessorKey: "itemID",
      cell: ({ row: { original } }) => <ProductItemMore item={original} />,
      meta: {
        alignHead: "justify-center"
      },
    },
    {
      header: "eBay Image",
      accessorKey: "ebayImage",
      enableSorting: false,
      cell: ({ row: { original } }) => (
        <div className="w-[7rem] pl-4">
          <ProductNameCell
            img={original.ebayImage}
            maxHeight="max-h-[61px]"
            hoveredImgClassName="left-[75px]"
            width="80px"
            height="61px"
            imageAsLink
            url={`https://ebay${original.domain || ".com"}/itm/${original.ebayItemId}`}
          />
        </div>
      ),
    },
    {
      header: "Supplier Image",
      accessorKey: "amazonImageURL",
      enableSorting: false,
      cell: (img) => (
        <div className="w-[7rem] pl-4">
          <ProductNameCell
            img={String(img.getValue())}
            maxHeight="max-h-[61px]"
            hoveredImgClassName="left-[75px]"
            width="80px"
            height="61px"
          />
        </div>
      ),
    },
    {
      header: isAliexpress ? "Product ID" : "Asin",
      accessorKey: "asin",
      minSize: 60,
      enableSorting: false,
      cell: ({ row: { original } }) =>
        original.asin ? (
          <ProductEditField field="asin" item={original} inputWidth="w-[95px]" folderType={isAliexpress ? "2" : "1"} />
        ) : (
          "-"
        ),
    },
    {
      header: "eBay Title",
      accessorKey: "ebayTitle",
      minSize: 250,
      maxSize: 250,
      cell: ({ row: { original } }) => (
        <div className="max-w-[300px] mr-2 w-full">
          {original.ebayTitle ? (
            <ProductEditField
              className=" mx-1 !rounded-[10px] w-full"
              item={{ ...original, ebayTitle: original.ebayTitle }}
              checkKeywords={!isAliexpress}
              field="ebayTitle"
              folderType={isAliexpress ? "2" : "1"}
            />
          ) : (
            "-"
          )}
        </div>
      ),
      meta: {
        align: "justify-center",
        alignHead: "justify-between",
        customSort: () => handleSort("EbayTitle"),
        sortColumnId: "EbayTitle",

      }
    },
    {
      header: "Supplier Title",
      accessorKey: "amazonTitle",
      minSize: 230,
      maxSize: 230,
      cell: ({ row: { original } }) => (
        <div className="ml-4">
          {original.amazonTitle ? (
            <ProductNameCell
              name={original.amazonTitle}
              url={original.itemUrl || getProperDomain(original.amazonImageURL, original.domain, original.asin)}
              checkKeywords={!isAliexpress}
              cutLongTitle={80}
              titleClassName="!pr-1 w-full"
            />
          ) : (
            "-"
          )}
        </div>
      ),
      meta: {
        align: "justify-center",
        alignHead: "justify-between",
        customSort: () => handleSort("AmazonTitle"),
        sortColumnId: "AmazonTitle",
      }
    },
    {
      header: "Supplier Price",
      minSize: 100,
      accessorKey: "amazonPrice",
      cell: ({ row: { original } }) => formatCurrency(original.amazonPrice, CURRENCY.none, original.currency as CURRENCY_SIGNS),
      meta: {
        customSort: () => handleSort("AmazonPrice"),
        sortColumnId: "AmazonPrice",
        align: "justify-center",
        alignHead: "justify-between"
      }
    },
    {
      header: "Ebay Price",
      minSize: 100,
      accessorKey: "ebayPrice",
      cell: ({ row: { original } }) => (
        <ProductEditField
          field="ebayPrice"
          item={original}
          inputType="number"
          inputWidth="w-[60px]"
          folderType={isAliexpress ? "2" : "1"}
        />
      ),
      meta: {
        customSort: () => handleSort("EbayPrice"),
        sortColumnId: "EbayPrice",
        align: "justify-center",
        alignHead: "justify-between"
      }
    },
    {
      header: "Profit",
      minSize: 100,
      accessorKey: "calculatedProfit",
      cell: ({ row: { original } }) => (
        <ProductEditField
          field="calculatedProfit"
          inputType="number"
          item={original}
          inputWidth="w-[50px]"
          folderType={isAliexpress ? "2" : "1"}
        />
      ),
      meta: {
        customSort: () => handleSort("CalculatedProfit"),
        sortColumnId: "CalculatedProfit",
        align: "justify-center",
        alignHead: "justify-between"
      }
    },
    {
      header: "Sales",
      minSize: 50,
      accessorKey: "sales",
      cell: (sales) => formatNumber(String(sales.getValue())),
      meta: {
        customSort: () => handleSort("Sales"),
        sortColumnId: "Sales",
        alignHead: "justify-center",
        align: "justify-center",
      }
    },
    {
      header: () => <CompetitorResearch className="fill-darkBlue dark:fill-grey100 w-4" />,
      minSize: 50,
      accessorKey: "competitorCount",
      cell: ({ row: { original } }) => (
        <CompetitorCount
          title={original.ebayTitle}
          handleCountChange={(count) => handleCountChange(count, original.ebayItemId)}
          ebayId={Number(original.ebayItemId)}
        />
      ),
      meta: {
        tooltipTitle: "Number of Competitors",
        customSort: () => handleSort("competitorCount"),
        sortColumnId: "competitorCount",
        align: "justify-center",
        alignHead: "justify-center"
      },
    },
  ];
