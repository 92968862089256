import { FC } from "react"

interface Props {
    color: "darkBlue" | "electricBlue"
    className?: string
}

const Dropshipper: FC<Props> = ({ color, className }) => (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <path d="M10.3337 8.53857H5.56443H5.16699V13.3078H10.3337V8.53857Z" stroke={color === "darkBlue" ? "#150D3F" : "#1D41FF"} strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M5.29857 9.83376L1.32422 6.25684L1.85596 5.66602L5.83031 9.24293C5.99347 9.38977 6.00669 9.64106 5.85986 9.80422C5.71302 9.96737 5.46172 9.98059 5.29857 9.83376Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M10.2014 9.83376L14.1758 6.25684L13.644 5.66602L9.66969 9.24293C9.50653 9.38977 9.49331 9.64106 9.64014 9.80422C9.78698 9.96737 10.0383 9.98059 10.2014 9.83376Z" />
        <path d="M9.53871 1.58977C8.40318 2.55108e-05 7.02161 2.55108e-05 5.96179 1.58977C5.16692 2.78207 3.97461 5.16669 3.97461 5.96156H11.1284C11.1284 4.76925 10.2011 2.51712 9.53871 1.58977Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M7.72183 0.794871C7.28899 0.794871 6.7848 1.07168 6.29245 1.8102C5.90817 2.38662 5.42072 3.263 5.0297 4.0939C4.8345 4.5087 4.66656 4.90538 4.54832 5.24236C4.50621 5.36238 4.47228 5.46974 4.44585 5.5641H10.6996C10.6301 5.07304 10.4506 4.44935 10.2104 3.81745C9.90201 3.00582 9.51888 2.2458 9.21527 1.82075C8.68374 1.0766 8.15909 0.794871 7.72183 0.794871ZM7.72183 0C8.5242 0 9.25809 0.513141 9.86209 1.35874C10.2209 1.86104 10.6326 2.69076 10.9535 3.5351C11.2716 4.37243 11.5259 5.28978 11.5259 5.96153C11.5259 6.18103 11.3479 6.35897 11.1284 6.35897H3.97458C3.75509 6.35897 3.57715 6.18103 3.57715 5.96153C3.57715 5.68983 3.67296 5.33636 3.79828 4.97919C3.92844 4.60823 4.10825 4.1852 4.31049 3.75545C4.71435 2.89725 5.22048 1.98517 5.63107 1.36928C6.19855 0.518066 6.91506 0 7.72183 0Z" />
        <path d="M0.397741 5.96161C0.71569 4.37187 3.44475 1.98725 4.76953 1.19238C3.97466 1.98725 2.5174 4.7693 2.38492 5.96161H0.397741Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M5.08281 0.947797C4.95929 0.789589 4.73719 0.748317 4.56507 0.851585C3.86861 1.26947 2.82958 2.08662 1.92402 2.99894C1.4698 3.45655 1.04113 3.94609 0.702962 4.42981C0.368873 4.90769 0.103226 5.40776 0.00804567 5.88367C-0.015306 6.00042 0.0149212 6.1215 0.0904098 6.21358C0.165898 6.30566 0.278695 6.35904 0.397763 6.35904H2.38494C2.58745 6.35904 2.75758 6.20677 2.77995 6.0055C2.83745 5.488 3.20322 4.55133 3.68308 3.60818C4.16123 2.66836 4.7008 1.8232 5.05058 1.47341C5.19251 1.33148 5.20634 1.106 5.08281 0.947797ZM3.13099 2.94744C3.0779 3.0471 3.02568 3.14739 2.97462 3.24774C2.57147 4.04014 2.20457 4.90703 2.05252 5.56417H0.952347C1.05026 5.35511 1.18523 5.12725 1.35442 4.88524C1.65878 4.44989 2.05479 3.99552 2.48817 3.5589C2.6978 3.3477 2.91431 3.14252 3.13099 2.94744Z" />
        <path d="M15.1023 5.96161C14.7843 4.37187 12.0553 1.98725 10.7305 1.19238C11.5253 1.98725 12.9826 4.7693 13.1151 5.96161H15.1023Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M10.4172 0.947797C10.5407 0.789589 10.7628 0.748317 10.9349 0.851585C11.6314 1.26947 12.6704 2.08662 13.576 2.99894C14.0302 3.45655 14.4589 3.94609 14.797 4.42981C15.1311 4.90769 15.3968 5.40776 15.492 5.88367C15.5153 6.00042 15.4851 6.1215 15.4096 6.21358C15.3341 6.30566 15.2213 6.35904 15.1022 6.35904H13.1151C12.9125 6.35904 12.7424 6.20677 12.7201 6.0055C12.6626 5.488 12.2968 4.55133 11.8169 3.60818C11.3388 2.66836 10.7992 1.8232 10.4494 1.47341C10.3075 1.33148 10.2937 1.106 10.4172 0.947797ZM12.369 2.94744C12.4221 3.0471 12.4743 3.14739 12.5254 3.24774C12.9285 4.04014 13.2954 4.90703 13.4475 5.56417H14.5477C14.4497 5.35511 14.3148 5.12725 14.1456 4.88524C13.8412 4.44989 13.4452 3.99552 13.0118 3.5589C12.8022 3.3477 12.5857 3.14252 12.369 2.94744Z" />
    </svg>
)


export default Dropshipper