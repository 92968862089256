import { FC } from "react";

interface Props {
    activeTab: "ebay" | "shopify";
    ebayDiscountPercent?: number
}

const LaunchDiscountText: FC<Props> = ({ activeTab, ebayDiscountPercent }) => {

    return activeTab === "ebay" ?
        <h5 className="text-center text-[24px] max-sm:text-[18px] font-medium dark:text-grey100">Start Now with Up to <span className="font-bold text-[#24921A]">{ebayDiscountPercent || 50}% Off</span></h5> : <div />
}

export default LaunchDiscountText
