import { helpVideos } from "assets/data/helpVideos";
import { myFoldersCols } from "assets/tableColumns/myFolders";
import Card from "components/Card";
import InfoTooltip from "components/Card/InfoTooltip";
import Header from "components/Header";
import HeaderHelpVideo from "components/Header/HelpVideo";
import HeaderTab from "components/Header/Tab";
import { ExportItems } from "components/ProductManagementButtons/ExportItems";
import Search from "components/Search";
import SettingTabs from "components/SettingsTabs/Index";
import SettingsTab from "components/SettingsTabs/SettingsTab";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableStyles } from "components/Table/Table";
import Toaster from "components/Toast";
import useModal from "hooks/useModal";
import usePagination from "hooks/usePagination";
import { SortDir } from "hooks/useSort";
import useTable from "hooks/useTable";
import useTabs from "hooks/useTabs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  useDeleteFolderMutation,
  useGetFoldersQuery
} from "store/api/foldersApi";
import AddNewFolder from "./components/AddNewFolder";


enum TABS {
  amazon = "amazon",
  aliexpress = "aliexpress",
}

const tableStyles: TableStyles = {
  px: "px-10",
  body: {
    rowHeight: "h-[88px]",
    contHeight: "h-[54px]",
  },
  head: {
    rowHeight: "h-[60px]",
    contHeight: "h-[37px]",
  },
};

const initialSort = {
  direction: SortDir.des,
  column: "dateModified",
};

const MyProducts = () => {
  const [sort, setSort] = useState(initialSort);
  const [selectedItem, setSelectedItem] = useState<string>("");
  const exportFolderModal = useModal();
  const [itemCount, setItemCount] = useState(0)
  const { activeTab, onTabChange } = useTabs<TABS>(TABS.amazon);
  const isAliexpress = activeTab === "aliexpress";
  const type = isAliexpress ? 2 : 1;
  const pagination = usePagination(itemCount, 50, true);
  const [search, setSearch] = useState("");
  const [submittedSearch, setSubmittedSearch] = useState("");

  const onSearchChange = (newVal: string) => setSearch(newVal);
  const onSearchClick = () => {
    setSubmittedSearch(search);
    pagination.goto(0)
  }
  const handleSort = (column: string) => {
    const dir =
      sort.column === column
        ? sort.direction === SortDir.des
          ? SortDir.asc
          : SortDir.des
        : SortDir.asc;

    setSort({
      column,
      direction: dir,
    });
    pagination.goto(0);
  };


  const { currentData, isFetching } = useGetFoldersQuery({
    Type: type,
    Search: submittedSearch,
    PageNumber: pagination.current + 1,
    PageSize: pagination.pageSize,
    OrderBy: sort.column,
    Direction: sort.direction === SortDir.asc ? "asc" : "desc",
  });

  const [deleteFolder, { isLoading }] = useDeleteFolderMutation();
  const { t } = useTranslation(['home']);
  const onExport = async (id: string) => {
    setSelectedItem(id);
    exportFolderModal.open();
  };

  useEffect(() => {
    if (currentData?.result) {
      setItemCount(currentData?.result.total);
    }
  }, [currentData?.result.total]);

  const deleteLoading = (id: string) => {
    if (isLoading && id === selectedItem) {
      return true;
    } else {
      return false;
    }
  };
  const onDelete = (id: string) => {
    setSelectedItem(id);
    deleteFolder(id)
      .unwrap()
      .then(() => toast.success("Folder deleted successfully"));
  };
  const { table } = useTable({
    columns: myFoldersCols(onDelete, onExport, deleteLoading, handleSort),
    dataDeps: [currentData?.result.folders],
    columnDeps: [sort, isLoading, pagination.current],
    sort: true,
    data: currentData?.result.folders || [],
  });


  return (
    <>
      <Header
        leftContent={
          <>
            <HeaderTab
              title={t('MyProducts')}
              className="min-w-[9rem]"
              isActive={true}
            />
          </>
        }
        rightContent={
          <>
            <HeaderHelpVideo videos={helpVideos.myProducts} />
          </>
        }
      />
      <Card className="pt-7 pb-[31px] px-10 mb-5 relative" >
        <div className="flex gap-[85px]">
          <div className="flex w-full flex-col gap-[50px]">
            <Search
              searchButton
              handleSearchClick={onSearchClick}
              search={search}
              handleChange={onSearchChange}
              maxWidth="max-w-[542px]"
              className="w-full"
            />
            <SettingTabs
              className="max-w-[431px] w-full !p-[2px]"
              tabs={
                <>
                  <SettingsTab
                    title="Amazon & Walmart Products"
                    className={"min-w-[211px] h-12 !text-xs"}
                    isActive={activeTab === TABS.amazon}
                    handleClick={() => onTabChange(TABS.amazon)}
                  />
                  <SettingsTab
                    title="AliExpress Products"
                    className={"h-12 !text-xs"}
                    isActive={activeTab === TABS.aliexpress}
                    handleClick={() => onTabChange(TABS.aliexpress)}
                  />
                </>
              }
            />
          </div>
          <AddNewFolder type={type} />
        </div>
        <InfoTooltip className="absolute right-[30px] top-[30px]" title="To create a folder, enter the prefered name of the folder and create a short description. Click on create." />
      </Card>
      <Card className="py-6">
        <div className="flex justify-end mr-10 mb-5">
          <Pagination pagination={pagination} />
        </div>
        <Table table={table} isLoading={isFetching} styles={tableStyles}
          sort={sort}
        />
      </Card>
      <Toaster />
      <ExportItems
        helpers={exportFolderModal}
        type="Folder"
        allChecked={false}
        btnTitle="Export Listings"
        folderId={Number(selectedItem)}
      />
    </>
  );
};

export default MyProducts;
