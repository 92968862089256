import BottomCard from "./components/myDashboard/BottomCard";
import ButtonGroup from "./components/myDashboard/ButtonGroup";
import ProductList from "./components/myDashboard/ProductList";
import RushDay from "./components/myDashboard/RushDay";
import SellAndRevenue from "./components/myDashboard/SellAndRevenue";
import WeeklyTrend from "./components/myDashboard/WeeklyTrend";

import Toaster from "components/Toast";
import { FACEBOOK_GROUP } from "config/constants";
import LoadingPage from "pages/Loading";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import { useGetStoresQuery, useGetUserStoreDetailsQuery } from "store/api/storeApi";
import ButtonCard from "./components/myDashboard/ButtonCard";
import MyCompetitors from "./components/myDashboard/MyCompetitors";
import StoreAnalytics from "./components/myDashboard/StoreAnalytics";

const MyDashboardContent = () => {
  const { data, isFetching } = useGetStoresQuery();
  const { data: storeDetails, isFetching: storeFetching } =
    useGetUserStoreDetailsQuery(
      {
        storeName: data ? data.result?.[0]?.ebayUsername : "",
      },
      { skip: !data }
    );

  const navigate = useNavigate();

  return (
    <LoadingPage loading={isFetching}>
      <div className="mt-1.5">
        <ButtonGroup />
        <div className="flex max-lg:flex-col gap-4.5 mb-4.5">
          <div className="lg:w-2/3 flex flex-col gap-4.5">
            {data && data.result.length > 0 ? (
              <>
                <SellAndRevenue storeDetails={storeDetails} isFetching={storeFetching} />
                <RushDay storeDetails={storeDetails} isFetching={storeFetching} />
              </>
            ) : (
              <StoreAnalytics />
            )}
            <div className="flex gap-4.5">
              <MyCompetitors />
              <BottomCard />
            </div>
          </div>
          <div className="lg:w-1/3 flex flex-col gap-5">
            <WeeklyTrend />
            <ButtonCard
              title="Join the ZIK VIP Members group Mentor, Business Coach, Guides and Live Events"
              handleClick={() => window.open(FACEBOOK_GROUP, "_blank")}
              buttonTitle="Join Now"
            />
            <ButtonCard
              title="Get Product Research Training"
              handleClick={() => navigate(PATH.academy)}
              buttonTitle="Watch Webinar Now"
            />
            <ProductList />
          </div>
        </div>
      </div>
      <Toaster />
    </LoadingPage>
  );
};

export default MyDashboardContent;
