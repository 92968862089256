import StatsCard from "components/Card/StatsCard";
import {
  CURRENCY_SIGNS,
  formatCurrency,
  formatNumber,
  formatPercent
} from "utils/formatCurrency";

import { ReactComponent as Dollar } from "assets/icons/dollar.svg";
import { ReactComponent as Flag } from "assets/icons/flag.svg";
import { ReactComponent as Hammer } from "assets/icons/hammer.svg";
import { ReactComponent as Menu } from "assets/icons/menu.svg";
import { ReactComponent as Rocket } from "assets/icons/rocket.svg";
import { ReactComponent as Star } from "assets/icons/star.svg";
import { ReactComponent as UsernameIcon } from "assets/icons/username.svg";

import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CURRENCY } from "types/currency";
import { PostMatchItemsRes } from "types/services/bulkScanner";

interface Props {
  data?: PostMatchItemsRes;
  isLoading?: boolean;
}
const StatsCards: FC<Props> = ({ data, isLoading }) => {
  const { t } = useTranslation(['home']);

  return (
    <div className="grid grid-flow-row md:grid-cols-2 lg:grid-cols-4 gap-y-3.5 gap-x-[1.3125rem] mb-3.5 font-medium">
      <StatsCard
        title={t('SellTrough')}
        isLoading={isLoading}
        value={formatPercent(data?.result.sellthrough || 0) || "-"}
        Icon={Rocket}
        info="The sell-through % is a measurement of how fast items are selling. For example, a sell-through rate of 500% means that on average each listing has gained 5 sales. The higher the sell-through %, the better!"
      />
      <StatsCard
        title={t('ActiveListings')}
        isLoading={isLoading}
        value={formatNumber(data?.result.activeListings || 0)}
        Icon={Menu}
        info="The number of live listings the seller has"
      />
      <StatsCard
        title={t('SoldItems')}
        isLoading={isLoading}
        value={formatNumber(data?.result.totalSold || 0)}
        Icon={Hammer}
        info="The total amount of items sold amongst the listings being analyzed"
      />
      <StatsCard
        title={t('SaleEarnings')}
        isLoading={isLoading}
        value={formatCurrency(
          data?.result.earning || 0,
          CURRENCY.none,
          (data?.result.currency || "$") as CURRENCY_SIGNS
        )}
        Icon={Dollar}
        info="The total amount of income generated from the total sold items"
      />
      <StatsCard
        title={t('Seller')}
        isLoading={isLoading}
        value={data?.result.searchedUser?.ebayUsername || "-"}
        valueClassName="text-2xl"
        cutLongTitle={14}
        Icon={UsernameIcon}
        info="The name of user/store"
      />
      <StatsCard
        title={t('Feedback')}
        isLoading={isLoading}
        value={formatNumber(data?.result.searchedUser?.feedbackScore || 0)}
        Icon={Star}
        info="The total amount of feedback that the seller has"
      />
      <StatsCard
        title={t('SuccessfulListings')}
        isLoading={isLoading}
        iconClassName="w-3.5"
        value={formatNumber(data?.result.successful || 0)}
        Icon={Flag}
        info="The percentage of listings that have at least one sale"
      />
      <StatsCard
        title={t('AveragePrice')}
        isLoading={isLoading}
        value={formatCurrency(
          data?.result.averagePrice || 0,
          CURRENCY.none,
          (data?.result.currency || "$") as CURRENCY_SIGNS
        )}
        Icon={Dollar}
      />
    </div>
  )
};

export default StatsCards;
