import { formatCurrency, formatNumber } from "utils/formatCurrency";

//components
import HoveredIcon from "components/HoveredIcon";
import ProductNameCell from "components/ProductNameCell";

// Icons
import { ColumnDef } from "@tanstack/react-table";
import { ReactComponent as LineGraph } from "assets/icons/line-graph.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { Link } from "react-router-dom";
import { PATH } from "routes/path";
import { CURRENCY } from "types/currency";
import { CrAliList } from "types/services/cr";
import { makeQueryParams } from "utils/makeQueryParams";

export const crAliexpressCols = (
  handleAnalyzeClick: (item: CrAliList) => void
): ColumnDef<CrAliList>[] => [
    {
      header: "Product Name",
      accessorKey: "title",
      cell: ({ row: { original } }) => (
        <ProductNameCell
          name={original.title}
          img={original.mainImage}
          url={original.itemUrl}
        />
      ),
      meta: {
        align: "justify-start",
        alignHead: "justify-between"
      },
    },
    {
      header: "Shipping",
      accessorKey: "freeShipping",
      cell: (shipping) => (
        <p>{shipping.getValue() ? String(shipping.getValue) : "Standard"}</p>
      ),
    },
    {
      header: "Rating",
      accessorKey: "itemRating",
      cell: (rating) => (
        <p className="font-bold">{String(rating.getValue()) || 0}</p>
      ),
    },
    {
      header: "Reviews",
      accessorKey: "itemFeedback",
      cell: (feedback) => formatNumber(String(feedback.getValue()) || 0),
    },
    {
      header: "Sales",
      accessorKey: "totalSales",
      cell: (sales) => formatNumber(String(sales.getValue())),
    },
    {
      header: "Price",
      accessorKey: "price",
      cell: (price) => formatCurrency(Number(price.getValue()), CURRENCY.dollar),
    },
    {
      header: () => <SearchIcon className="fill-darkBlue dark:fill-grey100" />,
      accessorKey: "storeId",
      minSize: 50,
      enableSorting: false,
      cell: ({ row: { original } }) => (
        <HoveredIcon
          Icon={SearchIcon}
          handleClick={() => handleAnalyzeClick(original)}
          title="Analyze"
          iconClassName="w-3.5 h-[13px]"
        />
      ),
      meta: {
        alignHead: "justify-center"
      }
    },
    {
      header: () => <LineGraph className="fill-darkBlue dark:fill-grey100" />,
      accessorKey: "aliid",
      minSize: 50,
      enableSorting: false,
      cell: ({ row: { original } }) => (
        <Link
          to={makeQueryParams(PATH.productResearch.ebay, { search: original.title, setDefaultSettings: true })}
          target="_blank"
          rel="noreferrer"
        >
          <HoveredIcon Icon={LineGraph} title="Scan on Ebay" />
        </Link>
      ),
      meta: {
        alignHead: "justify-center"
      }
    },
  ];
