import Card from "components/Card";

import { ReactComponent as LineGraph } from "assets/icons/line-graph.svg";
import { turboScannerCols } from "assets/tableColumns/turboScanner";
import Button from "components/Button";
import ConfirmDelete from "components/Modal/ConfirmDelete";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableStyles } from "components/Table/Table";
import useModal from "hooks/useModal";
import useTable from "hooks/useTable";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PATH } from "routes/path";
import {
  useTurboDeleteAllMutation,
  useTurboDeleteMutation
} from "store/api/turboScannerApi";
import { TurboScanHistoryList } from "types/services/turboScanner";

interface Props {
  data?: TurboScanHistoryList[];
  isLoading: boolean;
}
const tableStyles: TableStyles = {
  empty: { rowHeight: "h-[80px]" },
};
const TurboScannerList: FC<Props> = ({ data, isLoading }) => {
  const [selectedItem, setSelectedItem] = useState(0);
  const deleteModal = useModal();
  const [deleteAll, { isLoading: deleteAllLoading }] =
    useTurboDeleteAllMutation();

  const [deleteItem, { isLoading: deleteItemLoading }] =
    useTurboDeleteMutation();

  const navigate = useNavigate();

  const onDelete = async (scanId: number | false) => {
    if (scanId) {
      setSelectedItem(scanId);
    }
    if (scanId === false) {
      await deleteAll()
        .unwrap()
        .then(() => {
          toast.success("All Scans Deleted");
          deleteModal.close();
        });
    } else {
      await deleteItem(Number(scanId)).then(
        (res) => res && toast.success("Scan Deleted")
      );
    }
  };

  const onViewClick = (id: string) => {
    navigate(PATH.zikPro.turboScanner.turboScanner + `/${id}`);
  };
  const { table } = useTable({
    columns: turboScannerCols(
      onViewClick,
      onDelete,
      deleteItemLoading,
      selectedItem
    ),
    data: data || [],
    dataDeps: [data],
    initialPageSize: 100,
    columnDeps: [deleteItemLoading],
    pagination: true,
  });

  return (
    <Card className="pt-5 py-6 w-full" shadow={false}>
      <Button
        title="Delete All Scans"
        handleClick={deleteModal.open}
        className="max-w-[10.75rem] mb-3 float-right mr-4 dark:bg-deepSpace900"
        iconClassName="w-[11px]"
        height="h-9"
        color="primaryPurple"
        Icon={LineGraph}
      />
      <Table table={table} styles={tableStyles} isLoading={isLoading} />
      <Pagination table={table} className="mt-4 mx-4" labeled />
      <ConfirmDelete
        title="Are you sure you want to delete all scans?"
        helpers={deleteModal}
        onConfirm={() => onDelete(false)}
        confirmLoading={deleteAllLoading}
      />
    </Card>
  );
};

export default TurboScannerList;
