

import { FC, LegacyRef, ReactNode } from "react";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Navigation, Pagination } from "swiper/modules";
import { Swiper } from "swiper/react";

interface Props {
    prev?: LegacyRef<HTMLDivElement>;
    next?: LegacyRef<HTMLDivElement>;
    onActiveChange: (idx: number) => void;
    children: ReactNode
}
const SwiperWrapper: FC<Props> = ({ next, prev, onActiveChange, children }) => {
    return (
        <Swiper
            modules={[Navigation, Pagination]}
            pagination={{
                clickable: true,
                el: ".pricing-progressbar",
                type: "progressbar",
            }}
            navigation={{
                nextEl: next,
                prevEl: prev,
            }}
            centeredSlides={true}
            initialSlide={1}
            spaceBetween={16}
            breakpoints={{
                500: {
                    spaceBetween: 10,
                },
            }}
            className="mySwiper"
            slidesPerView="auto"
            onSlideChange={(swiper: any) => {

                onActiveChange(swiper.realIndex as number);
            }}
        >
            {children}
        </Swiper>
    )
}

export default SwiperWrapper