import { ReactComponent as Diamond } from "assets/icons/diamond.svg";
import { ReactComponent as LockHovered } from "assets/icons/lock-hovered.svg";
import { ReactComponent as Lock } from "assets/icons/lock.svg";
import useDecodeToken, { UserPackages } from "hooks/useDecodeToken";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PATH } from "routes/path";
import MainDashboardCard from "./MainDashboardCard";
interface ItemProps {
  path: string;
  title: string;
  locked: boolean;
}

enum TOOLS {
  bulkScanner = "bulk-scanner",
  bestSellingItems = "best-selling-items",
  aliGrowthScanner = "ali-growth-scanner",
  turboScanner = "turbo-scanner",
  autopilot = "autopilot",
}


const toolsContent = (plan: UserPackages) => ({
  [TOOLS.bulkScanner]: {
    locked: plan === "Pro",
  },
  [TOOLS.bestSellingItems]: {
    locked: plan === "Pro",
  },
  [TOOLS.aliGrowthScanner]: {
    locked: plan === "Pro",
  },
  [TOOLS.turboScanner]: {
    locked: plan === "ProPlus" || plan === "Pro",
  },
  [TOOLS.autopilot]: {
    locked: plan === "ProPlus" || plan === "Pro",
  },
});


const ZikProToolsItem: FC<ItemProps> = ({ path, title, locked }) => {
  const { t } = useTranslation(['home']);

  return (
    <Link to={path} target="_blank">
      <div
        className="flex cursor-pointer gap-[8.75px] items-center group"
      >
        {locked ? (
          <>
            <LockHovered className="group-hover:block hidden" />
            <Lock className="fill-smoothGreen group-hover:hidden block dark:fill-paradiseBlue" />
          </>
        ) : (
          <Diamond
            className="group-hover:fill-primaryPurple dark:group-hover:fill-purple500 fill-darkBlue dark:fill-grey100"
          />
        )}
        <h6
          className="my-[3px] text-base group-hover:text-primaryPurple dark:group-hover:text-purple500 text-darkBlue dark:text-grey100"
        >
          {t(title)}
        </h6>
      </div>
    </Link>
  );
};

const ZikProTools = () => {
  const userDetails = useDecodeToken();
  const plan = userDetails.package;

  return (
    <MainDashboardCard
      className="py-4.5 px-3 text-sm"
      title={"Advanced Tools"}
      tooltipTop="top-5.5"
      tooltipRight="right-5.5"
      infoTitle="Advanced AI tools to help you find products, niches and trends automatically."
    >
      <div className="flex flex-col gap-2 mt-2">

        <ZikProToolsItem title="BulkScanner" path={PATH.zikPro.zikProBulkScanner} locked={toolsContent(plan)[TOOLS.bulkScanner].locked} />
        <ZikProToolsItem title="500BestsellingitemsonEbay" path={PATH.zikPro.bestSellingItems} locked={toolsContent(plan)[TOOLS.bestSellingItems].locked} />
        <ZikProToolsItem title="AliGrowthScanner" path={PATH.zikPro.aliGrowthScanner} locked={toolsContent(plan)[TOOLS.aliGrowthScanner].locked} />
        <ZikProToolsItem title="TurboScanner" path={PATH.zikPro.turboScanner.root} locked={toolsContent(plan)[TOOLS.turboScanner].locked} />
        <ZikProToolsItem title="Autopilot" path={PATH.zikPro.autopilot} locked={toolsContent(plan)[TOOLS.autopilot].locked} />

      </div>
    </MainDashboardCard>
  );
};

export default ZikProTools;
