import useWindowDimensions from "hooks/useWindowDimensions";

interface Props {
    isExpand?: boolean;
}

const BottomMenuDivider = ({ isExpand }: Props) => {
    const { height } = useWindowDimensions();
    const isSmallSidebar = height < 800
    return (
        <div className="relative flex items-center justify-start gap-[12px]">
            <div className={`w-[39px] h-[1px] bg-neutral2 dark:bg-grey500 shrink-0`}></div>
            {isExpand && <div className="w-[132px] h-[1px] bg-neutral2 dark:bg-grey500"></div>}

        </div>
    )
}

export default BottomMenuDivider