import { formatNumber } from "utils/formatCurrency";

import { ColumnDef } from "@tanstack/react-table";
import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";
import { ReactComponent as LineGraph } from "assets/icons/line-graph.svg";
import { ReactComponent as LocationIcon } from "assets/icons/location.svg";
import HoveredIcon from "components/HoveredIcon";
import ProductNameCell from "components/ProductNameCell";
import { Link } from "react-router-dom";
import { PATH } from "routes/path";
import { BestSellingItemsList } from "types/services/zikPro";
import { makeQueryParams } from "utils/makeQueryParams";

export const bestSellingItemsCols: ColumnDef<BestSellingItemsList>[] = [
  {
    header: "Image",
    accessorKey: "picture",
    enableSorting: false,
    cell: (img) => (
      <div className="w-[115px]">
        <ProductNameCell
          img={String(img.getValue())}
          width="115px"
          height="73px"
          hoveredImgClassName="!left-[110px]"
          maxHeight="max-h-[73px]"
        />
      </div>
    ),
    meta: {
      alignHead: "justify-between"
    }
  },
  {
    header: "Title",
    accessorKey: "title",
    cell: ({ row: { original } }) => (
      <Link
        className="text-left hover:text-primaryPurple dark:hover:text-purple500 cursor-pointer"
        to={`https://ebay${original.location}/itm/${original.itemID}`}
        target="_blank"
        rel="noreferrer"
      >
        {String(original.title)}
      </Link>
    ),
    meta: {
      className: "pl-6",
      align: "justify-start",
      alignHead: "justify-between"
    },
  },
  {
    header: "Price",
    accessorKey: "currentPrice",
    minSize: 50,
    meta: {
      tooltipTitle: "Click here to sort items by price (highest to lowest, or lowest to highest)",
      alignHead: "justify-between"
    }

  },
  {
    header: "Sales",
    minSize: 50,
    accessorKey: "numberOfSales",
    cell: (sales) => formatNumber(String(sales.getValue())),
    meta: {
      tooltipTitle: "Click here to sort items by generated sales",
      alignHead: "justify-between"
    }
  },
  {
    header: () => <LocationIcon className="fill-darkBlue dark:fill-grey100" />,
    minSize: 50,
    accessorKey: "globalID",
    meta: {
      tooltipTitle: "Click here to sort items by location",
    }
  },
  {
    header: () => <LineGraph className="fill-darkBlue dark:fill-grey100" />,
    meta: {
      tooltipTitle: "Scan by Title",
      alignHead: "justify-center"
    },
    minSize: 50,
    enableSorting: false,
    accessorKey: "itemID",
    cell: ({ row: { original } }) => (
      <Link
        to={makeQueryParams(PATH.productResearch.ebay, { search: encodeURIComponent(original.title), })}
        target="_blank"
        rel="noreferrer"
      >
        <HoveredIcon Icon={LineGraph} iconClassName="w-3.5" />
      </Link>
    ),
  },
  {
    header: () => <CompetitorResearch className="fill-darkBlue dark:fill-grey100 h-4" />,
    meta: {
      tooltipTitle: "Scan Competitor",
      alignHead: "justify-center"
    },
    minSize: 50,
    enableSorting: false,
    accessorKey: "ebayUsername",
    cell: (username) => (
      <Link
        to={PATH.competitorResearch.ebay + `?search=${username.getValue()}`}
        target="_blank"
        rel="noreferrer"
      >
        <HoveredIcon Icon={CompetitorResearch} iconClassName="h-4" />
      </Link>
    ),
  },
];
