import {
  dropshippingCourse,
  guides,
  indexPlaylist,
  listingOptimisation,
  prDropshipper,
  prStock,
  sellerCourse,
  tips,
  webinars,
} from "assets/data/academy/playList";
import { useEffect, useState } from "react";

const usePlaylist = <T>(initialState: T) => {
  const [academy, setAcademy] = useState(initialState);

  const getList = (pl: string) => {
    switch (pl) {
      case "prStock":
        return prStock;
      case "guides":
        return guides;
      case "webinars":
        return webinars;
      case "tips":
        return tips;
      case "prDropshipper":
        return prDropshipper;
      case "listingOptimisation":
        return listingOptimisation;
      case "index":
        return indexPlaylist;
      default:
        return indexPlaylist;
    }
  };

  const getCourse = (cr: string) => {
    switch (cr) {
      case "dropshippingCourse":
        return dropshippingCourse;
      case "sellerCourse":
        return sellerCourse;
      default:
        return dropshippingCourse;
    }
  };

  const handleTypeChange = (newVal: string) => {
    setAcademy((prev) => ({
      ...prev,
      type: newVal,
    }));
  };

  const handleSourceChange = (newVal: string, name: keyof typeof academy) => {
    setAcademy((prev) => ({
      ...prev,
      [name]: newVal,
      playList: getList(newVal),
      courseList: getCourse(newVal),
    }));
  };

  useEffect(() => {
    setAcademy((prev) => ({
      ...prev,
      playList: getList("index"),
    }));
  }, []);

  return {
    academy,
    handleSourceChange,
    handleTypeChange,
  };
};

export default usePlaylist;
