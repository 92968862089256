import { ReactComponent as BoxIcon } from "assets/icons/boxes/box.svg";
import { ReactComponent as CurrencyIcon } from "assets/icons/dollar-sign.svg";
import { ReactComponent as DollarIcon } from "assets/icons/dollar.svg";
import { ReactComponent as LanguageIcon } from "assets/icons/language.svg";
import { ReactComponent as LocationIcon } from "assets/icons/location.svg";
import { ReactComponent as MegaphoneIcon } from "assets/icons/megaphone.svg";
import { ReactComponent as Pen } from "assets/icons/pen.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { ReactComponent as TimeIcon } from "assets/icons/time.svg";
import Button from "components/Button";
import Card from "components/Card";
import FilterCheckbox from "components/Filters/FilterCheckbox";
import FiltersCard from "components/Filters/FiltersCard";
import MinMaxFilter from "components/Filters/MinMaxFilter";
import MinMaxFilterAutoScale from "components/Filters/MinMaxFilterAutoScale";
import InputTags from "components/InputTags";
import Select from "components/Select";
import SelectAdvanced from "components/SelectAdvanced";
import { useAppSelector } from "hooks/useAppSelector";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getCountryFlag } from "utils/getCountryFlag";
import { GetShopifyCountries, GetShopifyCurrencies, GetShopifyLanguages } from "utils/localStorage";
import { ProductExplorerState, productExplorerAdsChannel, productExplorerFiltersState, productExplorerProductType, productExplorerSalesDateRange } from "..";

interface Props {
    filters: ProductExplorerState;
    onSearchClick: (type: string) => void;
    setFilters: Dispatch<SetStateAction<ProductExplorerState>>;
    handleFilterChange: (newVal: any, name: keyof ProductExplorerState, type?: "min" | "max" | undefined) => void
    isLoading?: boolean;
}

const Filters = ({ filters, onSearchClick, setFilters, handleFilterChange, isLoading }: Props) => {
    const { isExpand } = useAppSelector((state) => state.themeSlice);
    const shopifyCountries = GetShopifyCountries();
    const shopifyCurrencies = GetShopifyCurrencies();
    const shopifyLanguages = GetShopifyLanguages();
    const [searchParams, setSearchParams] = useSearchParams()
    const searchQuery = searchParams.get("search") || ""
    const addSearchKeywords = (value: string) => {
        if (filters.searchByKeywords.length >= 10) {
            toast.warn("You have reached limit of provided keywords (10)");
            return;
        }
        setFilters((prev) => ({
            ...prev,
            searchByKeywords: [...filters.searchByKeywords, value]
        }))
    }

    useEffect(() => {
        const keywordExist = (keyword: string) => keyword.toLowerCase() === searchQuery.toLowerCase();
        if (searchQuery.length > 0 && !filters.searchByKeywords.some(keywordExist)) {
            addSearchKeywords(searchQuery);
        }
    }, [searchQuery])

    const removeSearchKeywords = (index: number) => {
        const remove = filters.searchByKeywords.filter((el, i) => i !== index)
        setFilters((prev) => ({
            ...prev,
            searchByKeywords: remove
        }))
    }

    const removeSearchKeywordsWithBackspace = () => {
        const remove = filters.searchByKeywords.slice(0, filters.searchByKeywords.length - 1);
        setFilters((prev) => ({
            ...prev,
            searchByKeywords: remove
        }))
    }
    const removeExcludedKeywordsWithBackspace = () => {
        const remove = filters.excludeKeywords.slice(0, filters.excludeKeywords.length - 1);
        setFilters((prev) => ({
            ...prev,
            excludeKeywords: remove
        }))
    }

    const addExcludeKeywords = (value: string) => {
        if (filters.excludeKeywords.length >= 10) {
            toast.warn("You have reached limit of provided keywords (10)");
            return;
        }
        setFilters((prev) => ({
            ...prev,
            excludeKeywords: [...filters.excludeKeywords, value]
        }))
    }
    const removeExcludeKeywords = (index: number) => {
        const remove = filters.excludeKeywords.filter((el, i) => i !== index)
        setFilters((prev) => ({
            ...prev,
            excludeKeywords: remove
        }))
    }


    const handleCounntryChange = (
        newValue: string | number,
    ) => {
        const find = filters.country.includes(newValue.toString());
        const remove = filters.country.filter((item) => item !== newValue);
        setFilters((prev) => ({
            ...prev,
            country: find ? remove : [...prev.country, newValue.toString()],
        }));
    };

    const handleLanguageChange = (
        newValue: string | number,
    ) => {
        const find = filters.language.includes(newValue.toString());
        const remove = filters.language.filter((item) => item !== newValue);
        setFilters((prev) => ({
            ...prev,
            language: find ? remove : [...prev.language, newValue.toString()],
        }));
    };

    const handleCurrencyChange = (
        newValue: string | number,
    ) => {
        const find = filters.currency.includes(newValue.toString());
        const remove = filters.currency.filter((item) => item !== newValue);
        setFilters((prev) => ({
            ...prev,
            currency: find ? remove : [...prev.currency, newValue.toString()],
        }));
    };

    const handleProductTypeChange = (
        newValue: string | number,
    ) => {
        const find = filters.productType.includes(newValue.toString());
        const remove = filters.productType.filter((item) => item !== newValue);
        setFilters((prev) => ({
            ...prev,
            productType: find ? remove : [...prev.productType, newValue.toString()],
        }));
    };

    const handleAdsChange = (
        newValue: string | number,
    ) => {
        const find = filters.adsChannels.includes(newValue.toString());
        const remove = filters.adsChannels.filter((item) => item !== newValue);
        setFilters((prev) => ({
            ...prev,
            adsChannels: find ? remove : [...prev.adsChannels, newValue.toString()],
        }));
    };

    const resetAdvancedFilters = (key: any) => {
        setFilters((prev) => ({
            ...prev,
            [key]: []
        }));
    }
    const resetFilters = () => {
        setFilters(productExplorerFiltersState)
    }
    return (
        <>
            <Card className="p-3.5 mb-[20px]" shadow="shadow-0">
                <div className="flex gap-3.5 max-[898px]:flex-wrap">
                    <div className="flex-1">
                        <FiltersCard
                            Icon={SearchIcon}
                            title="Search by Keywords"
                            className="!p-3.5 mb-3.5"
                            infoTitle="Enter keywords that best describe what you're searching for."
                            titleClassName="mr-auto ml-[21px]"
                            iconClassName="w-[14px] h-[14px]"
                        >
                            <InputTags
                                tags={filters.searchByKeywords}
                                PlaceHolderIcon={SearchIcon}
                                addTag={addSearchKeywords}
                                removeTag={removeSearchKeywords}
                                removeWithBackspace={removeSearchKeywordsWithBackspace}
                            />
                        </FiltersCard>
                        <FiltersCard
                            Icon={Pen}
                            title="Exclude Keyword"
                            className="!p-3.5 mb-3.5"
                            infoTitle={"Enter any keywords you want to exclude from your search. For example, if you're not looking for cat-related items, type " + '"Cat"' + " to filter those out."}
                            titleClassName="mr-auto ml-[21px]"
                            iconClassName="w-[14px] h-[14px]"
                        >
                            <InputTags
                                tags={filters.excludeKeywords}
                                PlaceHolderIcon={SearchIcon}
                                addTag={addExcludeKeywords}
                                removeTag={removeExcludeKeywords}
                                removeWithBackspace={removeExcludedKeywordsWithBackspace}
                            />
                        </FiltersCard>
                        <div className={`grid justify-between  grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))] gap-3.5`}>
                            <FiltersCard
                                Icon={LocationIcon}
                                iconClassName="w-[11px] h-[14px]"
                                title="Country"
                                className="!p-3.5 w-full"
                                infoTitle="Filter products sold by businesses located in a specific country."

                            >
                                <SelectAdvanced
                                    value={filters.country}
                                    options={shopifyCountries}
                                    handleChange={(newValue) => handleCounntryChange(newValue)}
                                    btnClassName="min-w-full"
                                    title="Select Options"
                                    img={filters.country.length === 1 ? getCountryFlag(filters.country[0]) : filters.country.length > 1 ? getCountryFlag("ALL") : ""}
                                    handleReset={() => resetAdvancedFilters("country")}
                                    cutTitle={17}
                                    dividerAfter={5}
                                    showCheckedFirst
                                />
                            </FiltersCard>
                            <FiltersCard
                                Icon={LanguageIcon}
                                iconClassName="w-[14px] h-[14px]"
                                title="Language"
                                className="!p-3.5 w-full"
                                infoTitle="Filter products by the main language used by the business."

                            >
                                <SelectAdvanced
                                    value={filters.language}
                                    options={shopifyLanguages}
                                    handleChange={(newValue) => handleLanguageChange(newValue)}
                                    btnClassName="min-w-full"
                                    title="Select Options"
                                    handleReset={() => resetAdvancedFilters("language")}
                                    dividerAfter={3}
                                    showCheckedFirst
                                />
                            </FiltersCard>
                            <FiltersCard
                                Icon={CurrencyIcon}
                                iconClassName="w-[20px] h-[11px] fill-none dark:fill-none text-darkBlue dark:text-grey100"
                                title="Currency"
                                className="!p-3.5 w-full"
                                infoTitle="Filter products by the main currency used by the business."


                            >
                                <SelectAdvanced
                                    value={filters.currency}
                                    options={shopifyCurrencies}
                                    handleChange={(newValue) => handleCurrencyChange(newValue)}
                                    btnClassName="min-w-full"
                                    title="Select Options"
                                    handleReset={() => resetAdvancedFilters("currency")}
                                    isCurrency
                                    dividerAfter={3}
                                    showCheckedFirst
                                />
                            </FiltersCard>

                            <FiltersCard
                                Icon={TimeIcon}
                                iconClassName="w-[12px] h-[14px]"
                                title="Sales Date Range"
                                className="!p-3.5"
                                infoTitle="Set the date range to analyze sales and revenue from a specific period."


                            >
                                <Select
                                    value={filters.salesDateRange}
                                    options={productExplorerSalesDateRange}
                                    handleChange={(newValue) => handleFilterChange(newValue, "salesDateRange")}
                                    btnClassName="w-full"
                                />
                            </FiltersCard>
                            <FiltersCard
                                Icon={MegaphoneIcon}
                                iconClassName="w-[14px] h-[13px]"
                                title="Sales"
                                className="!p-3.5"
                                infoTitle="Filter products by the number of sales within your selected date range."

                            >
                                <MinMaxFilter
                                    name="Sales"
                                    customInputWrapperStyles="px-0"
                                    value={filters.sales}
                                    inputWidth="max-w-[64px]"
                                    handleChange={(newValue, type) =>
                                        handleFilterChange(newValue, "sales", type)
                                    }
                                    minInputLimit={99999}
                                    maxInputLimit={999999}

                                />
                            </FiltersCard>
                            <FiltersCard
                                Icon={DollarIcon}
                                iconClassName="w-[14px] h-[14px]"
                                title="Revenue"
                                className="!p-3.5"
                                infoTitle="Filter products by the revenue generated within your selected date range."

                            >
                                <MinMaxFilterAutoScale
                                    name="Revenue"
                                    customInputWrapperStyles="px-0 w-[77px]"
                                    value={filters.revenue}
                                    inputWidth="max-w-[64px]"
                                    handleChange={(newValue, type) =>
                                        handleFilterChange(newValue, "revenue", type)
                                    }
                                    palceholder={{ min: "$0", max: "$0" }}
                                    inputPrefix="$"
                                    minInputLimit={9999999}
                                    maxInputLimit={9999999}
                                />
                            </FiltersCard>
                            <FiltersCard
                                Icon={DollarIcon}
                                iconClassName="w-[14px] h-[13px]"
                                title="Price"
                                className="!p-3.5"
                                infoTitle="Filter products by setting a minimum and maximum price to match your price target."

                            >
                                <MinMaxFilterAutoScale
                                    name="Price"
                                    customInputWrapperStyles="px-0 w-[77px]"
                                    value={filters.price}
                                    inputWidth="max-w-[64px]"
                                    handleChange={(newValue, type) =>
                                        handleFilterChange(newValue, "price", type)
                                    }
                                    palceholder={{ min: "$0", max: "$0" }}
                                    inputPrefix="$"
                                    minInputLimit={99999}
                                    maxInputLimit={999999}
                                />
                            </FiltersCard>
                            <div className={`col-span-2 flex justify-around items-center gap-[10px] ${isExpand ? "max-[1567px]:col-span-1" : "max-[1430px]:col-span-1"} min-[1697px]:col-span-full min-[1697px]:justify-between`}>
                                <Button
                                    color="primaryPurple"
                                    className="max-w-[150px] !border-transparent dark:text-lilac400 dark:hover:bg-purple500 dark:hover:border-purple500"
                                    height="h-[44px]"
                                    title="Reset Filters"
                                    handleClick={() => { resetFilters() }} />

                                <Button
                                    className="max-w-[150px] border-darkBlue hover:bg-primaryPurple hover:border-primaryPurple dark:bg-deepSpace900 dark:hover:bg-purple500 dark:hover:border-purple500"
                                    height="h-[44px]"
                                    title="Search"
                                    loading={isLoading}
                                    handleClick={() => onSearchClick("click")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="max-w-[500px] w-full max-[898px]:max-w-full">
                        <FiltersCard
                            Icon={BoxIcon}
                            iconClassName="w-[14px] h-[12px]"
                            title="Product Type"
                            className="!p-3.5 mb-3.5"
                            infoTitle="Use this filter to find products based on specific business models. Ideal for locating items sold through particular types of operations."

                        >
                            <FilterCheckbox
                                value={filters.productType}
                                options={productExplorerProductType}
                                className="grid grid-cols-2 max-[550px]:grid-cols-1"
                                checkboxClassName="w-[16px] h-[16px] !rounded-[4px]"
                                optionLabelClassName="py-[10px] pl-[12px] pr-[16px] h-[48px] group"
                                checkmarkClassName="w-[8px] h-[6px]"
                                multiselect
                                handleChange={(newValue) =>
                                    handleProductTypeChange(newValue)
                                } />

                        </FiltersCard>
                        <FiltersCard
                            Icon={MegaphoneIcon}
                            iconClassName="w-[14px] h-[13px]"
                            title="Ads Channels"
                            className="!p-3.5 mb-3.5"
                            infoTitle="Use this filter to find products based on the advertising channels they utilize."

                        >
                            <FilterCheckbox
                                value={filters.adsChannels}
                                multiselect={true}
                                options={productExplorerAdsChannel}
                                className="grid grid-cols-2 max-[550px]:grid-cols-1"
                                checkboxClassName="w-[16px] h-[16px] !rounded-[4px]"
                                optionLabelClassName="py-[10px] pl-[12px] pr-[16px] h-[48px]"
                                checkmarkClassName="w-[8px] h-[6px]"
                                handleChange={(newValue) =>
                                    handleAdsChange(newValue)
                                } />
                        </FiltersCard>
                    </div>
                </div>
            </Card>
        </>
    )
}

export default Filters