import useDecodeToken from "hooks/useDecodeToken";
import LoadingPage from "pages/Loading";
import {
  useGetUserPlanQuery
} from "store/api/managePaymentApi";
import Index from "../components/ManagePayments/Index";

declare global {
  interface Window {
    gpfToken: any;
  }
}

const ManagePayments = () => {
  const user = useDecodeToken();
  const { data, isFetching } = useGetUserPlanQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const ebayPlan = data?.result.find((item) => !item.isShopify)
  const currentPlan = user.package_name || "";
  const isTrial = /^true$/i.test(user.active_trail);
  const paymentMethod = ebayPlan?.paymentMethod || "";
  const paymentStatus = ebayPlan?.status || 0;
  const isCanceled = ebayPlan?.isCanceled;
  const renewalDate = ebayPlan?.nextPaymentDate || "N/A";

  return (
    <LoadingPage loading={isFetching}>
      <Index
        currentPlan={currentPlan}
        paymentMethod={paymentMethod}
        paymentStatus={paymentStatus}
        isCanceled={isCanceled}
        renewalDate={renewalDate}
        isTrial={isTrial}
      />
    </LoadingPage>

  );
};

export default ManagePayments;
