export const amazonUsCategories = [
  {
    text: "All Electronics",
    value: "1",
  },
  {
    text: "Amazon Devices",
    value: "2",
  },
  {
    text: "Apple Products",
    value: "3",
  },
  {
    text: "Appliances",
    value: "4",
  },
  {
    text: "Arts, Crafts & Sewing",
    value: "5",
  },
  {
    text: "Automotive",
    value: "6",
  },
  {
    text: "Baby",
    value: "7",
  },
  {
    text: "Books",
    value: "8",
  },
  {
    text: "Camera & Photo",
    value: "9",
  },
  {
    text: "Car Electronics",
    value: "10",
  },
  {
    text: "CDs & Vinyl",
    value: "11",
  },
  {
    text: "Cell Phones & Accessories",
    value: "12",
  },
  {
    text: "Collectible Coins",
    value: "13",
  },
  {
    text: "Collectibles & Fine Art",
    value: "14",
  },
  {
    text: "Computers",
    value: "15",
  },
  {
    text: "Digital Music",
    value: "16",
  },
  {
    text: "Entertainment",
    value: "17",
  },
  {
    text: "GPS & Navigation",
    value: "18",
  },
  {
    text: "Grocery & Gourmet Food",
    value: "19",
  },
  {
    text: "Handmade",
    value: "20",
  },
  {
    text: "Health & Personal Care",
    value: "21",
  },
  {
    text: "Home & Kitchen",
    value: "22",
  },
  {
    text: "Home Audio & Theater",
    value: "23",
  },
  {
    text: "Industrial & Scientific",
    value: "24",
  },
  {
    text: "Movies & TV",
    value: "25",
  },
  {
    text: "Musical Instruments",
    value: "26",
  },
  {
    text: "Office Products",
    value: "27",
  },
  {
    text: "Pet Supplies",
    value: "28",
  },
  {
    text: "Portable Audio & Accessories",
    value: "29",
  },
  {
    text: "Premium Beauty",
    value: "30",
  },
  {
    text: "Software",
    value: "31",
  },
  {
    text: "Sports & Outdoors",
    value: "32",
  },
  {
    text: "Sports Collectibles",
    value: "33",
  },
  {
    text: "Tools & Home Improvement",
    value: "34",
  },
  {
    text: "Toys & Games",
    value: "35",
  },
  {
    text: "Video Games",
    value: "36",
  },
  {
    text: "Wine",
    value: "37",
  },
];

export const amazonUkCategories = [
  {
    text: "Amazon Devices",
    value: "1",
  },
  {
    text: "Baby",
    value: "2",
  },
  {
    text: "Beauty",
    value: "3",
  },
  {
    text: "Books",
    value: "4",
  },
  {
    text: "Business, Industry & Science",
    value: "5",
  },
  {
    text: "Car",
    value: "6",
  },
  {
    text: "Baby",
    value: "7",
  },
  {
    text: "CDs & Vinyl",
    value: "8",
  },
  {
    text: "Clothing",
    value: "9",
  },
  {
    text: "Computers & Accessories",
    value: "10",
  },
  {
    text: "DIY & Tools",
    value: "11",
  },
  {
    text: "DVD & Blu-ray",
    value: "12",
  },
  {
    text: "Electronics",
    value: "13",
  },
  {
    text: "Garden & Outdoors",
    value: "14",
  },
  {
    text: "Grocery",
    value: "15",
  },
  {
    text: "Handmade",
    value: "16",
  },
  {
    text: "Health & Personal Care",
    value: "17",
  },
  {
    text: "HOME & GARDEN",
    value: "18",
  },
  {
    text: "Jewellery",
    value: "19",
  },
  {
    text: "Large Appliances",
    value: "20",
  },
  {
    text: "Luggage",
    value: "21",
  },
  {
    text: "Musical Instruments",
    value: "22",
  },
  {
    text: "Pet Supplies",
    value: "23",
  },
  {
    text: "Premium Beauty",
    value: "24",
  },
  {
    text: "Shoes & Bags",
    value: "25",
  },
  {
    text: "Sports & Outdoors",
    value: "26",
  },
  {
    text: "Stationery & Office Supplies",
    value: "27",
  },
  {
    text: "Toys",
    value: "28",
  },
  {
    text: "Video Games",
    value: "29",
  },
  {
    text: "Watches",
    value: "30",
  },
];

export const walmartCategories = [
  {
    text: "Arts Crafts & Sewing",
    value: "1",
  },
  {
    text: "Auto & Tires",
    value: "2",
  },
  {
    text: "Baby",
    value: "3",
  },
  {
    text: "Beauty",
    value: "4",
  },
  {
    text: "Books",
    value: "5",
  },
  {
    text: "Cell Phones",
    value: "6",
  },
  {
    text: "Clothing",
    value: "7",
  },
  {
    text: "Electronics",
    value: "8",
  },
  {
    text: "Feature",
    value: "9",
  },
  {
    text: "Food",
    value: "10",
  },
  {
    text: "Health",
    value: "11",
  },
  {
    text: "Home",
    value: "12",
  },
  {
    text: "Home Improvement",
    value: "13",
  },
  {
    text: "Household Essentials",
    value: "14",
  },
  {
    text: "Industrial & Scientific",
    value: "15",
  },
  {
    text: "Jewelry",
    value: "16",
  },
  {
    text: "Movies & TV Shows",
    value: "17",
  },
  {
    text: "Music",
    value: "18",
  },
  {
    text: "Musical Instruments",
    value: "19",
  },
  {
    text: "Office Supplies",
    value: "20",
  },
  {
    text: "Party & Occasions",
    value: "21",
  },
  {
    text: "Patio & Garden",
    value: "22",
  },
  {
    text: "Personal Care",
    value: "23",
  },
  {
    text: "Pets",
    value: "24",
  },
  {
    text: "Premium Beauty",
    value: "25",
  },
  {
    text: "Seasonal",
    value: "26",
  },
  {
    text: "Sports & Outdoors",
    value: "27",
  },
  {
    text: "Toys",
    value: "28",
  },
  {
    text: "UNNAV",
    value: "29",
  },
  {
    text: "Video Games",
    value: "30",
  },
  {
    text: "Walmart for Business",
    value: "31",
  },
];
