import Button from "components/Button";
import Input from "components/Input";
import { FC, useEffect, useState } from "react";
import { useAddVatNumberMutation } from "store/api/managePaymentApi";
import { UpgradeCheckRes } from "types/services/managePayments";

interface Props {
    hideInput: () => void;
    handleVatNumberUpdate?: () => void;
    data?: UpgradeCheckRes;
}
const AddVatNumberInput: FC<Props> = ({ hideInput, handleVatNumberUpdate, data }) => {
    const [vatNumber, setVatNumber] = useState<string>("");
    const [showVatError, setShowVatError] = useState(false)
    const [vatErrorMessage, setVatErrorMessage] = useState<string | null>(null)
    const [addVatNumber] = useAddVatNumberMutation();
    const onValChange = (val: string) => setVatNumber(val)

    useEffect(() => {
        if (data) {
            setVatNumber(data?.result?.countryCode || "")
        }
    }, [data?.result?.countryCode])

    const handleButtonClick = () => {
        addVatNumber(vatNumber).unwrap().then((res) => {
            if (res.result) {
                setVatErrorMessage(null)
                setShowVatError(false)
                handleVatNumberUpdate?.();
                hideInput()
            }
            else {
                setVatErrorMessage("An error occured")
                setShowVatError(true)
            }
        })
            .catch((err) => {
                setVatErrorMessage(err.data)
                setShowVatError(true)
            });
    };
    return (
        (
            <div>
                <div className="flex items-center gap-2.5">
                    <Input
                        className="border-[2px] rounded-[7px] max-w-[249px] h-9 w-full px-[17px] !py-[5px]"
                        value={vatNumber}
                        variant="contained"
                        placeholder="Add Vat Number"
                        handleChange={onValChange}
                        maxLength={20}
                    />
                    <Button
                        loading={false}
                        className="max-w-[103px] w-full"
                        height="h-9"
                        title="Save"
                        handleClick={handleButtonClick}
                    />
                </div>
                {showVatError && vatErrorMessage && (<div className="text-errorRed text-xs mt-2">{vatErrorMessage}</div>)}
            </div>
        )
    )
}

export default AddVatNumberInput