import { ReactComponent as Close } from "assets/icons/clear-input.svg";
import { ReactComponent as NotificationIcon } from "assets/icons/notification.svg";
import Card from "components/Card";
import Loading from "components/Loading";
import useModal from "hooks/useModal";
import { FC, useEffect } from "react";
import { useGetAnnouncementsQuery, useReadAnnouncementsMutation } from "store/api/newDashboardApi";

interface Props {
  helpers: ReturnType<useModal>;
}

interface ItemProps {
  title: string;
  hidden?: boolean;
  handleClick?: () => void;
  onHideClick?: () => void
}
const NotificationItem: FC<ItemProps> = ({ title, handleClick, hidden, onHideClick }) => (
  <div className={`relative ${hidden ? "hidden" : ""}`}>
    <div className={`relative pl-5 py-3 pr-14 flex rounded-10 bg-white dark:bg-deepSpace900 border-2 border-darkBlue dark:border-grey100 ${handleClick ? "cursor-pointer" : ""}`} onClick={handleClick}>
      <div className="flex items-center gap-5 h-fit">
        <div className="">
          <NotificationIcon className="fill-darkBlue w-5 h-5 dark:fill-grey100" />
        </div>
        <div className="text-sm font-normal dark:text-grey100" dangerouslySetInnerHTML={{
          __html: title
        }} />
      </div>
    </div>
    <button className="w-6 h-6 absolute group right-[9px] top-[7px] flex items-center justify-center" onClick={() => { onHideClick && onHideClick() }} >
      <Close className="fill-darkBlue group-hover:fill-primaryPurple w-3 h-3 dark:fill-grey100 dark:group-hover:fill-purple500" />
    </button>
  </div>

);

const Notifications: FC<Props> = ({ helpers }) => {
  const [readAnnouncements] = useReadAnnouncementsMutation()
  const isEmpty = false;
  const { data, isFetching } = useGetAnnouncementsQuery(undefined, {
    skip: !helpers.isOpen,
  });


  useEffect(() => {
    if (data?.result?.announcmentFeed && data?.result.announcmentFeed.length > 0) {
      readAnnouncements()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return helpers.isOpen ? (
    <Card
      className={`min-w-[608px] absolute right-0 max-xl:right-[-250px] max-sm:min-w-[400px] max-sm:right-[-120px] p-3 z-20 border-primaryPurple dark:border-purple500 border-2 dark:text-grey100`}
      bg={isEmpty ? "" : "bg-secondary dark:bg-black900"}
    >
      {isFetching ? (
        <div className="h-[8rem] w-full flex justify-center items-center">
          <Loading fill="primaryPurple" width={48} height={48} />
        </div>
      ) : isEmpty ? (
        <div className="flex items-center gap-3.5 py-1.5 pl-2">
          <NotificationIcon className="fill-darkBlue dark:fill-grey100 w-5 h-5" />
          <p className="text-sm font-normal">
            Nothing to show. Everything in order!
          </p>
        </div>
      ) : (
        <div className="flex flex-col gap-2.5">
          {data?.result?.announcmentFeed && data?.result?.announcmentFeed?.length > 0 ?
            data?.result?.announcmentFeed?.map((item, index) => (
              <NotificationItem title={item.description} key={index} />
            )) :
            <h3 className="py-5 text-center dark:text-grey100">No Notifications</h3>
          }
        </div>
      )}
    </Card>
  ) : (
    <></>
  );
};

export default Notifications;
