import { createSlice } from "@reduxjs/toolkit";

const initialState = { loading: false, isDarkMode: false, isExpand: false };

const themeSlice = createSlice({
  name: "themeSlice",
  initialState,
  reducers: {
    handleDarkModeChange: (state, action) => {
      state.isDarkMode = action.payload;
    },
    handleLoadingChange: (state, action) => {
      state.loading = action.payload;
    },
    handleNavbarChange: (state, action) => {
      state.isExpand = action.payload;
    },
  },
});

export const { handleLoadingChange, handleDarkModeChange, handleNavbarChange } =
  themeSlice.actions;
export default themeSlice.reducer;
