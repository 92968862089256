import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg";
import { ReactComponent as SortArrowUp } from "assets/icons/sort-arrow-up.svg";
import { FC } from "react";

interface Props {
    title: string;
    sortedColTitle: string;
    handleSort: (title: string) => void;
    ascending: boolean;
    titleClassName?: string;
    titleSpanClassName?: string;
    sortColKey?: string;
}

const TrendingSellersSortableTitle: FC<Props> = ({ sortedColTitle, title, handleSort, ascending, sortColKey, titleClassName, titleSpanClassName }) => {
    return (
        <th className="basis-full grow-[2] flex-1 text-center group p-0">
            <p className={`flex justify-center items-center gap-1 text-[10px] font-medium cursor-pointer group-hover:text-primaryPurple dark:group-hover:text-purple500 ${titleClassName}`}
                onClick={() => handleSort(sortColKey || title)}>
                <span className={titleSpanClassName}>
                    {title}
                </span>
                {title !== "Scan" && <span className="w-2 h-2 flex justify-center items-center block"> {sortedColTitle !== title ?
                    <ArrowDown className={`w-2 h-2 group-hover:fill-primaryPurple dark:group-hover:fill-purple500`} />
                    : <SortArrowUp className={`fill-darkBlue dark:fill-grey100 group-hover:fill-primaryPurple dark:group-hover:fill-purple500 ${ascending ? "" : "rotate-180"}`} />
                } </span>}



            </p>
        </th>
    )
}

export default TrendingSellersSortableTitle