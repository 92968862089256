import Plans from "components/Plans/Index";
import PlansComparison from "components/Plans/PlansComparison";
import { useAppDispatch } from "hooks/useAppDispatch";
import useDecodeToken from "hooks/useDecodeToken";
import usePlans from "hooks/usePlans";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import { useEbaySubCountQuery } from "store/api/quickSettingsApi";
import { onSelectPlan } from "store/slices/planSlice";
import { TSelectedPlan } from "types/plans";
import { makeQueryParams } from "utils/makeQueryParams";

interface Props {
    isPayNow?: boolean;
}
const EbayPlans: FC<Props> = ({ isPayNow }) => {
    const { plans } = usePlans("monthly");
    const { data: ebaySubCount } = useEbaySubCountQuery();
    const user = useDecodeToken();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isTrial = /^true$/i.test(user.active_trail);
    const noEbayPlan = ebaySubCount?.result === 0;
    const ebayAlreadyTrial = user.already_trial === "1"
    const ebayIsPayNow = ebayAlreadyTrial || !noEbayPlan || isPayNow
    const handleSelectPlan = (newVal: TSelectedPlan) => {
        dispatch(onSelectPlan(newVal));
        navigate(makeQueryParams(PATH.managementPanel.subscriptionPurchase, {
            type: newVal.type,
            ...(newVal.skip && { skip: true })
        }));
    };
    const plansProps = {
        plans,
        isTrial,
        fromRegistration: !ebayIsPayNow,
        isPayNow: ebayIsPayNow,
        handleSelectPlan,
    }
    return (
        <>
            <Plans
                {...plansProps}
                plansWrapperClassName="justify-center"
            />
            <PlansComparison
                {...plansProps}
            />
        </>

    )
}

export default EbayPlans;