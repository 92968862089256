import Button from "components/Button";
import FilterCheckbox from "components/Filters/FilterCheckbox";
import NewFiltersCard from "components/Filters/FiltersCard";
import MinMaxFilter from "components/Filters/MinMaxFilter";
import HoveredIcon from "components/HoveredIcon";
import Input from "components/Input";
import Switch from "components/Input/Switch";
import Modal from "components/Modal";
import Select from "components/Select";
import { useEffect, useState } from "react";
import {
  autopilotFilterState,
  inclusionsList,
  inclusionsListWalmart, suppliers
} from "../lists/Autopilot";

// Icons
import { saleDateRangeArr } from "assets/data/filters";
import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg";
import { ReactComponent as Reload } from "assets/icons/reload.svg";


import Rating from "components/Rating";
import useModal from "hooks/useModal";
import { toast } from "react-toastify";

interface Props {
  selectedCategoryTitle: string;
  helpers: ReturnType<useModal>;
  categoriesHelpers: ReturnType<useModal>;
  filters: typeof autopilotFilterState;
  handleRatingChange: (newVal: number | null) => void
  handleFilterChange: (
    newVal: string | number | null,
    name: keyof typeof autopilotFilterState,
    type?: "min" | "max"
  ) => void;
  handleListChange: (
    newValue: string | number,
    name: "inclusions" | "categories"
  ) => void;
  clearCategories: () => void;
  startScan: () => void;
  isLoading: boolean;
  handleResetFilters: () => void;
}

const AutopilotFilters = ({
  selectedCategoryTitle,
  helpers,
  categoriesHelpers,
  filters,
  handleRatingChange,
  handleFilterChange,
  handleListChange,
  clearCategories,
  startScan,
  isLoading,
  handleResetFilters,
}: Props) => {
  const [invalidField, setInvalidField] = useState<any>({})
  const [rating, setRating] = useState(false);

  const handleChange = (newVal: string | number | null,
    name: keyof typeof autopilotFilterState,
    type?: "min" | "max") => {
    if (invalidField[name]) {
      const errors = invalidField
      delete errors[name]
      setInvalidField(errors)
    }
    handleFilterChange(newVal, name, type)
  };
  const onCreate = () => {
    const {
      name,
      reviewCount,
      sellerRanking,
      competition,
      sales,
      price,
      roi,
      profit,
      sellThrough,
      successRate,
      supplier
    } = filters;
    let errors: any = {}
    const isAli = supplier === "Ali"
    if (name === "") {
      errors.name = true
    }
    if (price.max && price.min > price.max) {
      errors.price = true
    }
    if (sales.max && sales.min > sales.max) {
      errors.sales = true
    }
    if (!isAli) {
      if (sellerRanking.max === 0 && sellerRanking.min > 0) {
        errors.sellerRanking = true

      }
      if (sellerRanking.max && filters.supplier !== "WAL" && sellerRanking.min > sellerRanking.max) {
        errors.sellerRanking = true
      }
      if (reviewCount.max && reviewCount.min > reviewCount.max) {
        errors.reviewCount = true
      }
      if (competition.max && competition.min > competition.max) {
        errors.competition = true
      }
      if (roi.max && roi.min > roi.max) {
        errors.roi = true
      }
      if (profit.max && profit.min > profit.max) {
        errors.profit = true
      }
      if (sellThrough.max && sellThrough.min > sellThrough.max) {
        errors.sellThrough = true
      }
      if (successRate.max && successRate.min > successRate.max) {
        errors.successRate = true
      }
    }
    setInvalidField(errors)

    if (Object.keys(errors).length === 0) {
      startScan()
    } else if (invalidField.name) {
      toast.error("Please enter scan name!");
    } else if (invalidField.sellerRanking) {
      toast.error("Minimum BSR should be higher than Maximum BSR")
    } else {
      toast.error("Please check the values")
    }
  }

  useEffect(() => {
    if (filters.rating && filters.rating > 0) {
      setRating(true)
    }
  }, [filters.rating])

  useEffect(() => {
    handleRatingChange(rating ? filters.rating || 1 : null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rating]);

  return (
    <Modal
      helpers={helpers}
      title="Use Filters below to create your Autopilot scan"
      cardClassName="pt-[31px] pb-[25px] h-[100%] border-secondary shadow-shadow5 dark:!bg-grey700"
      titleClassName="!mb-[19.5px]"
      buttonCloseClassName="top-[20px] right-[25px] w-5 h-5"
      maxWidth="max-w-[700px]"
      overlay
    >
      <HoveredIcon
        Icon={Reload}
        variant="outlined"
        className="absolute top-[20px] right-[61px]"
        width="w-5"
        title="Reset"
        height="h-5"
        iconClassName="w-3 h-3"
        handleClick={handleResetFilters}
      />

      <div className="px-[20px] h-[100%]">
        <div className="grid grid-cols-3 gap-5">
          <NewFiltersCard
            title="Name"
            titleClassName="text-sm"
            titleWrapperClassName="!mb-1"
            className="w-full !h-[95px] dark:border-grey600"
          >
            <Input
              value={filters.name}
              handleChange={(newVal) => {
                newVal.length < 50 && handleChange(newVal, "name");
              }}

              erase
              placeholder="e.g My Scan"
              className={`h-9 !items-end ${invalidField?.name ? "after:!bg-errorRed" : ""}`}
            />
          </NewFiltersCard>
          <NewFiltersCard
            title="Supplier"
            titleClassName="text-sm"
            titleWrapperClassName="!mb-1"
            className="w-full !h-[95px] dark:border-grey600"
            infoTitle="Pick a supplier to scan the items from (Amazon.com, Amazon.co.uk, Walmart.com, Aliexpress.com)"
          >
            <Select
              className="!min-w-[100%]"
              options={suppliers}
              listWrapperClassName="!top-11"
              btnClassName="w-full !min-w-[100%] !h-9"
              value={filters.supplier}
              handleChange={(newValue) => {
                handleFilterChange(newValue, "supplier");
                clearCategories();
              }}
            />
          </NewFiltersCard>

          {filters.supplier !== "Ali" && (
            <NewFiltersCard
              title="Categories"
              titleClassName="text-sm"
              titleWrapperClassName="!mb-1"
              className="w-full !h-[95px] dark:border-grey600"
              infoTitle="Choose the category you wish to see the items from"
            >
              <button
                className="flex items-end justify-between w-full hover:text-primaryPurple dark:hover:text-purple500 h-9 pb-[9px] relative group
                after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-full after:h-[3px] after:rounded-full after:bg-darkBlue hover:after:bg-primaryPurple dark:after:bg-grey100 dark:hover:after:bg-purple500"
                onClick={() => {
                  categoriesHelpers.open();
                  helpers.close();
                }}
              >
                {
                  <p className=" font-medium text-sm">
                    {filters.categories && filters.categories?.length > 0
                      ? selectedCategoryTitle
                      : "Select Category"}
                  </p>
                }
                <span className="flex items-end justify-center w-6 h-8 pb-[2px]">
                  <ArrowDown
                    className="mb-1 w-2.5 h-[6px] group-hover:fill-primaryPurple dark:group-hover:fill-purple500 fill-darkBlue dark:fill-grey100"
                  />
                </span>

              </button>
            </NewFiltersCard>
          )}

          {filters.supplier !== "Ali" && (
            <>
              <NewFiltersCard
                title="Review Count"
                titleClassName="text-sm"
                titleWrapperClassName="!mb-0 !h-5"
                className="w-full pt-2.5 !h-[95px] dark:border-grey600"
                infoTitle="Number of reviews (from min. to max.)"
              >
                <MinMaxFilter
                  isInvalid={invalidField.reviewCount}
                  name="reviewCount"
                  customInputWrapperStyles="px-0"
                  value={filters.reviewCount}
                  minInputLimit={10000}
                  maxInputLimit={10000}
                  inputWidth="max-w-[64px]"
                  handleChange={(newValue, type) =>
                    handleChange(newValue, "reviewCount", type)
                  }
                />
              </NewFiltersCard>

              <NewFiltersCard
                title="Best Seller Ranking"
                titleClassName="text-sm"
                disabled={filters.supplier === "WAL"}
                className=" w-full pt-2.5 !h-[95px] dark:border-grey600"
                titleWrapperClassName="!mb-0 !h-5"
                infoTitle={filters.supplier === "WAL" ? "" : "The Best Seller Ranking (BSR) is based on a supplier's sales and reflects both recent and historical sales of each item within a specific category. If an item has a BSR of 1, it means that the item is the top-selling product in its category"}
              >
                <MinMaxFilter
                  isInvalid={invalidField.sellerRanking}
                  disabled={filters.supplier === "WAL"}

                  isFromTo
                  name="sellerRanking"
                  customInputWrapperStyles="px-0"
                  minInputLimit={500000}
                  maxInputLimit={500000}
                  value={filters.supplier === "WAL" ? { min: 0, max: 0 } : filters.sellerRanking}
                  inputWidth="max-w-[64px]"
                  handleChange={(newValue, type) =>
                    handleChange(newValue, "sellerRanking", type)
                  }
                />
              </NewFiltersCard>

              <div className="flex flex-col gap-4.5 min-w-[212px] -ml-4 -mr-4 items-center justify-center">
                <FilterCheckbox
                  options={
                    filters.supplier === "WAL"
                      ? inclusionsListWalmart
                      : inclusionsList
                  }
                  value={filters.inclusions}
                  multiselect
                  className="gap-[6px] w-full"
                  handleChange={(newValue) =>
                    handleListChange(newValue, "inclusions")
                  }
                />
              </div>
            </>
          )}

          {filters.supplier !== "Ali" && (
            <>
              <NewFiltersCard
                className="!pt-3 px-2.5 pr-5 pb-3.5 w-full !h-[95px]  dark:border-grey600"
                titleWrapperClassName="!mb-0"
                title=""
              >
                <div className="flex flex-col gap-[19px]">
                  <div className="flex items-center gap-8 justify-center">
                    <h5 className="text-sm font-bold">Rating</h5>
                    <Switch
                      isChecked={rating}
                      setIsChecked={setRating}
                    />
                  </div>
                  <Rating
                    rating={filters.rating || 0}
                    handleRatingChange={(newVal) => {
                      if (!rating) {
                        setRating(true)
                      }
                      handleRatingChange(newVal)
                    }
                    }
                  />
                </div>
              </NewFiltersCard>
              <NewFiltersCard
                title="Competition"
                titleClassName="text-sm"
                className=" w-full !h-[95px] pt-2.5 dark:border-grey600"
                titleWrapperClassName="!mb-0 !h-5"
                infoTitle="Filter by competition range (Ex. from 1 - 10)"
              >
                <MinMaxFilter
                  isInvalid={invalidField.competition}
                  minInputLimit={10000}
                  maxInputLimit={10000}
                  name="competition"
                  customInputWrapperStyles="px-0"
                  value={filters.competition}
                  inputWidth="max-w-[64px]"
                  handleChange={(newValue, type) =>
                    handleChange(newValue, "competition", type)
                  }
                />
              </NewFiltersCard>
            </>
          )}

          <NewFiltersCard
            title="Sales"
            titleClassName="text-sm"
            className=" w-full !h-[95px] pt-2.5 dark:border-grey600"
            titleWrapperClassName="!mb-0 !h-5"
            infoTitle="Filter by sales (Ex from 10-25)"
          >
            <MinMaxFilter
              isInvalid={invalidField.sales}
              name="sales"
              customInputWrapperStyles="px-0"
              value={filters.sales}
              minInputLimit={10000}
              maxInputLimit={10000}
              inputWidth="max-w-[64px]"
              handleChange={(newValue, type) =>
                handleChange(newValue, "sales", type)
              }
            />
          </NewFiltersCard>

          <NewFiltersCard
            title="Price"
            titleClassName="text-sm"
            className=" w-full !h-[95px] pt-2.5 dark:border-grey600"
            titleWrapperClassName="!mb-0 !h-5"
            infoTitle="Filter by price (Ex from $5 to $25)"
          >
            <MinMaxFilter
              isInvalid={invalidField.price}
              name="price"
              minInputLimit={10000}
              maxInputLimit={10000}
              customInputWrapperStyles="px-0"
              value={filters.price}
              inputWidth="max-w-[64px]"
              handleChange={(newValue, type) =>
                handleChange(newValue, "price", type)
              }
            />
          </NewFiltersCard>
          {filters.supplier !== "Ali" && (
            <>
              <NewFiltersCard
                title="Profit %"
                titleClassName="text-sm"
                className=" w-full !h-[95px] pt-2.5 dark:border-grey600"
                titleWrapperClassName="!mb-0 !h-5"
                infoTitle="Desired Profit Percentage"
              >
                <MinMaxFilter
                  minInputLimit={10000}
                  maxInputLimit={10000}
                  isInvalid={invalidField.profit}
                  name="profit"
                  customInputWrapperStyles="px-0"
                  value={filters.profit}
                  inputWidth="max-w-[64px]"
                  handleChange={(newValue, type) =>
                    handleChange(newValue, "profit", type)
                  }
                />
              </NewFiltersCard>
              <NewFiltersCard
                title="Sell Through"
                titleClassName="text-sm"
                className=" w-full pt-2.5 !h-[95px] dark:border-grey600"
                titleWrapperClassName="!mb-0 !h-5"
                infoTitle="Filter by sell through rate (the higher is better)"
              >
                <MinMaxFilter
                  isInvalid={invalidField.sellThrough}
                  minInputLimit={10000}
                  maxInputLimit={10000}
                  name="sellThrough"
                  customInputWrapperStyles="px-0"
                  value={filters.sellThrough}
                  inputWidth="max-w-[64px]"
                  handleChange={(newValue, type) =>
                    handleChange(newValue, "sellThrough", type)
                  }
                />
              </NewFiltersCard>
            </>
          )}

          {filters.supplier !== "Ali" && (
            <>
              {" "}
              <NewFiltersCard
                title="Success Rate"
                titleClassName="text-sm"
                className=" w-full pt-2.5 !h-[95px] dark:border-grey600"
                titleWrapperClassName="!mb-0 !h-5"
                infoTitle="Filter by success rate (higher rate is better)"
              >
                <MinMaxFilter
                  isInvalid={invalidField.successRate}
                  name="successRate"
                  customInputWrapperStyles="px-0"
                  value={filters.successRate}
                  minInputLimit={10000}
                  maxInputLimit={10000}
                  inputWidth="max-w-[64px]"
                  handleChange={(newValue, type) =>
                    handleChange(newValue, "successRate", type)
                  }
                />
              </NewFiltersCard>
            </>
          )}

          <NewFiltersCard
            title="Sales Date Range"
            titleClassName="text-sm"
            titleWrapperClassName="!mb-[7px] !h-5"
            className=" w-full !h-[95px] dark:border-grey600"
            infoTitle="Filter by sales range period (Ex. 30, 21, 14 & 7 days)"
          >
            <Select
              listHeight="max-xl:max-h-[40px] max-h-[80px]"
              className="min-w-[100%]"
              options={saleDateRangeArr}
              listWrapperClassName="!top-12"
              btnClassName="w-full !h-9"
              value={filters.saleRange}
              handleChange={(newValue) =>
                handleChange(newValue, "saleRange")
              }
            />
          </NewFiltersCard>

          <NewFiltersCard
            title="No. Products"
            titleClassName="text-sm"
            className=" w-full pt-2.5 !h-[95px] dark:border-grey600"
            titleWrapperClassName="!mb-0 !h-5"
            infoTitle="Amount of products that you're looking for."
          >
            <MinMaxFilter
              name="numberOfProducts"
              minInputLimit={10000}
              maxInputLimit={10000}
              customInputWrapperStyles="px-0"
              value={filters.numberOfProducts}
              inputWidth="max-w-[64px]"
              handleChange={(newValue, type) =>
                handleChange(newValue, "numberOfProducts", type)
              }
              hideMin={true}
            />
          </NewFiltersCard>
        </div>
      </div>
      <div className="flex justify-center w-full pt-[21.5px]">
        <Button
          title="Create Scan"
          height="h-9"
          handleClick={onCreate}
          className="max-w-[158px]"
          color="smoothGreen"
          loading={isLoading}
        />
      </div>
    </Modal>
  );
};

export default AutopilotFilters;