
import { ReactComponent as CloseIcon } from "assets/icons/clear-input.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info-i.svg";

interface Props {
    text: string | JSX.Element;
    handleClick: () => void;
}

const Discalimer = ({ text, handleClick }: Props) => {
    return (
        <div className="px-[10px] py-[8px] flex gap-[16px] rounded-[8px] bg-secondary dark:bg-grey200">
            <div className="w-[22px] h-[22px] flex items-center justify-center">
                <InfoIcon className="w-[16px] h-[16px] fill-darkBlue dark:fill-grey900" />
            </div>

            <div className="text-[12px] text-darkBlue dark:text-grey900 font-[400] leading-[16px]">{text}</div>
            <div className="w-[22px] h-[22px] flex justify-center ml-auto cursor-pointer" onClick={handleClick}><CloseIcon className="w-[11px] h-[11px] fill-darkBlue dark:fill-grey900" /></div>
        </div>
    )
}

export default Discalimer