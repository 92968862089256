import { helpVideos } from "assets/data/helpVideos";
import Header from "components/Header";
import HeaderHelpVideo from "components/Header/HelpVideo";
import HeaderIcon, { HeaderVariants } from "components/Header/Icon";
import HeaderTab from "components/Header/Tab";
import Toaster from "components/Toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import { makeQueryParams } from "utils/makeQueryParams";
import AliexpressCompetitors from "./components/AliexpressCompetitors";

const MyCompetitors = () => {
  const { t } = useTranslation(['home']);
  const navigate = useNavigate()

  return (
    <>
      <Header
        leftContent={
          <>
            <HeaderTab
              title={t('MyCompetitors')}
              className="min-w-[9rem]"
              isActive={true}
            />
          </>
        }
        rightContent={
          <>
            <HeaderHelpVideo videos={helpVideos.myCompetitors} />
            <HeaderIcon
              variant={HeaderVariants.settings}
              tooltipTitle="Product research settings"
              handleClick={() =>
                navigate(makeQueryParams(PATH.quickSettings.root, { tab: "account" }))
              }
            />
          </>
        }
        leftContentClassName="w-fit"
        middleContentClassName="max-w-[38.1875rem] w-full"
      />
      <div className="flex flex-col gap-5">
        <AliexpressCompetitors />
        <Toaster />
      </div>
    </>
  )
};

export default MyCompetitors;
