import SlidedPlans from "components/Plans/SlidedPlans";
import ShopifyPlansCont from "pages/Shopify/Billing/components/plans/PlansContainer";
import { FC } from "react";
import { PlansType, TSelectedPlan } from "types/plans";
import Trusted from "./Trusted";

interface Props {
    plans: PlansType;
    isEbay?: boolean;
    handleSelectPlan: (newVal: TSelectedPlan) => void;
}

const PlansContentA: FC<Props> = ({ plans, isEbay, handleSelectPlan }) => {
    const nextPrevStyles = "pt-6 max-sm:pt-9"

    return (
        <div className="w-full mx-auto flex flex-col justify-center items-center">
            {isEbay ? (
                <SlidedPlans
                    plansWrapperClassName="w-full max-w-[1051px]"
                    plans={plans}
                    handleSelectPlan={handleSelectPlan}
                    fromRegistration={true}
                    nextPrevStyles="pt-6 max-sm:pt-9"
                />
            ) :
                <ShopifyPlansCont handleSelectPlan={handleSelectPlan} isRegFunnel isResponsive wrapperStyles="lg:pt-6" nextPrevStyles={nextPrevStyles} />
            }
            <Trusted wrapperClassName={"py-[19px] max-lg:py-6 max-sm:py-9"} />
        </div>
    )
}

export default PlansContentA