// Icons
import Toaster from "components/Toast";
import AccountDetails from "./AccountDetails";
import UserAffiliate from "./Affiliate";
import ChangePassword from "./ChangePassword";

const Account = () => {
  return (
    <>
      <div className="flex gap-[69px] justify-center max-xl:gap-[30px] max-xl:justify-start max-[1080px]:flex-wrap  mx-auto">
        <AccountDetails />
        <ChangePassword />
        <UserAffiliate />
      </div>
      <Toaster />
    </>
  );
};
export default Account;
