import { ReactComponent as Cancel } from "assets/icons/clear-input.svg";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import { ReactComponent as Save } from "assets/icons/save.svg";
import HoveredIcon from "components/HoveredIcon";
import ProductNameCell from "components/ProductNameCell";
import { ChangeEvent, FC, HTMLInputTypeAttribute, useState } from "react";
import { toast } from "react-toastify";
import { useEditFolderItemMutation } from "store/api/foldersApi";
import { CURRENCY } from "types/currency";
import { FolderItemsList } from "types/services/folders";
import { CURRENCY_SIGNS, formatCurrency } from "utils/formatCurrency";

interface Props {
  field: keyof FolderItemsList;
  inputWidth?: string;
  inputType?: HTMLInputTypeAttribute;
  item: FolderItemsList;
  checkKeywords?: boolean;
  className?: string;
  folderType?: string;
}


const iconStlyes = {
  width: "w-5.5",
  height: "h-5.5",
  className: "!border-[1.5px]"
}
const ProductEditField: FC<Props> = ({
  field,
  inputWidth,
  inputType,
  className,
  item,
  checkKeywords,
  folderType
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isError, setIsError] = useState(false);
  const isCurrency = field === "ebayPrice" || field === "calculatedProfit";
  const isTitle = field === "ebayTitle"
  const isAsin = field === "asin"
  const [txt, setTxt] = useState(
    isCurrency ? Number(item[field]).toFixed(2) : item[field]
  );
  const [editItem, { isLoading }] = useEditFolderItemMutation();
  const handleChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => setTxt(e.target.value);

  const onEdit = () => setIsEdit(true);

  const onCancel = () => {
    if (isError) {
      setIsError(false)

      return;
    }
    setIsEdit(false);
    setTxt(item[field]);
    setIsError(false);
  };

  const onSave = async () => {
    if (isTitle && txt?.toString() && txt?.toString().length < 11) {
      toast.error("Please type at least 2 words or a minimum of 11 characters to describe your title");
      setIsError(true);

      return;
    }
    if (isCurrency && txt?.toString().length === 0) {
      toast.error("Please type a valid price. E.g 9.99, 98 or 988");
      setIsError(true);

      return;
    }
    if (isAsin && txt?.toString().length === 0) {
      toast.error("Please provide ASIN or ItemID");
      setIsError(true);

      return;
    }
    await editItem({
      id: item.singleScannedItemId,
      ebayTitle: field === "ebayTitle" ? txt?.toString() || "" : item.ebayTitle,
      ebayPrice: field === "ebayPrice" ? Number(txt) : item.ebayPrice,
      profit:
        field === "calculatedProfit" ? Number(txt) : item.calculatedProfit,
      asin: field === "asin" ? txt?.toString() || "" : item.asin,
      folderType: folderType ?? "1",
    });
    setIsError(false);
    setIsEdit(false);
  };

  return (
    <div className="flex items-center gap-[3px] cursor-pointer" onClick={() => { !isEdit && onEdit() }}>
      {/* if currency, show currency sign outside of edit box */}
      {isCurrency && <p>{formatCurrency(Number(txt) || "", CURRENCY.none, item.currency as CURRENCY_SIGNS, true)}</p>}
      <div
        className={`flex items items-between gap-2 py-[8px] px-[5px] relative rounded-[10px] max-h-[88px] border-[2px]  hover:border-primaryPurple ${!isTitle ? "items-center" : ""}  ${!isEdit ? "group" : ""}
              ${isEdit ? "bg-neutral2 dark:bg-deepSpace900 border-primaryPurple dark:border-2 dark:border-purple500" : "border-transparent bg-secondary dark:bg-deepSpace900"}
              ${isEdit && isError ? "!border-errorRed" : ""}
              ${className || ""}
          `}
      >
        {isEdit ? (
          <div className="w-full">
            {!isTitle ? (
              <div className="">

                <input
                  value={txt?.toString()}
                  type={inputType}
                  draggable={false}
                  onChange={handleChange}
                  className={`h-full white-scrollbar resize-none bg-transparent rounded-[5px] ${inputWidth || ""} ${isError ? "text-errorRed" : ""}`}
                />
              </div>
            ) : (
              <textarea
                value={txt?.toString()}
                onChange={handleChange}
                className={`min-w-full overflow-hidden h-[65px] font-medium white-scrollbar resize-none bg-transparent dark:text-purple500 rounded-[5px] ${isError ? "text-errorRed" : ""}`}
                maxLength={80}
              />
            )}
          </div>
        ) : isTitle ? (
          <ProductNameCell
            name={item.ebayTitle}
            checkKeywords={checkKeywords}
            className="pr-0"
            url={`https://ebay${item.domain || ".com"}/itm/${item.ebayItemId}`}
            disableRedirect
            titleClassName="max-h-[50px] overflow-y-scroll transparent-scrollbar"
          />
        ) : (
          <p className="">
            {isCurrency
              ? formatCurrency(Number(txt) || "", CURRENCY.none)
              : txt}
          </p>
        )}

        {isEdit ? (

          <div className="flex flex-col items-center gap-[3px]">
            <HoveredIcon
              Icon={Cancel}
              handleClick={onCancel}
              title="Cancel"
              iconClassName={`w-[8px] ${isError ? "fill-errorRed" : ""}`}
              {...iconStlyes}
              className={`w-[16px] h-[16px] !border-0`}
            />
            <HoveredIcon
              Icon={Save}
              handleClick={onSave}
              isLoading={isLoading}
              title="Save"
              iconClassName={`w-[13.33px] h-[7.5px] ${isError ? "fill-errorRed" : ""}`}
              {...iconStlyes}
              className={`w-[20px] h-[20px] !border-0`}
            />
          </div>
        ) : (
          <div>
            <HoveredIcon
              Icon={Edit}
              handleClick={onEdit}
              title="Edit"
              iconClassName="w-[11px]"
              {...iconStlyes}
              className="w-[20px] h-[20px]"
            />
          </div>

        )}

      </div>
    </div>

  );
};

export default ProductEditField;
