import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg";
import Tooltip from "components/Tooltip";
import useModal from "hooks/useModal";
import useOutsideClick from "hooks/useOutsideClick";
import { FC, ReactNode, useId } from "react";
import { Option } from "types/common";
import cutLongTitle from "utils/cutLongTitle";
import SelectOptionsCard from "./Options";

interface Props {
  value: string;
  options?: Option[];
  handleChange: (newValue: string, txt: string) => void;
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  CustomArrow?: React.FC<React.SVGProps<SVGSVGElement>>;
  title?: string;
  CustomOptions?: ReactNode;
  img?: string;
  className?: string;
  hideOptions?: boolean;
  noclose?: boolean;
  btnClassName?: string;
  iconClassName?: string;
  arrowClassName?: string;
  listItemClassName?: string;
  listWrapperClassName?: string;
  cutTitle?: number
  cutLongOption?: number;
  helpers?: ReturnType<useModal>
  listHeight?: string;
  titleClassName?: string;
  titleAsImage?: ReactNode;
  hideTooltip?: boolean;
  noDisabledOptionTooltip?: boolean;
}

const Select: FC<Props> = ({
  value,
  options,
  handleChange,
  Icon,
  CustomArrow,
  title,
  img,
  className,
  CustomOptions,
  btnClassName,
  noclose,
  hideOptions,
  iconClassName,
  arrowClassName,
  listItemClassName,
  listWrapperClassName,
  noDisabledOptionTooltip,
  titleClassName,
  cutTitle,
  cutLongOption,
  helpers,
  listHeight,
  titleAsImage,
  hideTooltip
}) => {
  const { isOpen, toggle, close } = useModal();
  const open = helpers ? helpers.isOpen : isOpen
  const [ref] = useOutsideClick<HTMLDivElement>(close);
  const id = useId()
  const handleOptionClick = (val: string, txt: string) => {
    !noclose && close();
    handleChange(val, txt);
  };

  const findLabel =
    options?.find((item) => item.value === value)?.text || title;

  return (
    <div className={`relative leading-none ${className || ""}`} ref={ref}>
      <button
        type="button"
        onClick={() => helpers ? helpers.toggle() : toggle()}
        data-tooltip-id={id}
        className={`flex items-end pb-[9px] justify-between min-w-[10.75rem] h-[55px] placeholder:text-darkBlue after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-full after:h-[3px] after:rounded-full group 
        ${open ? "text-primaryPurple after:bg-primaryPurple dark:text-purple500 dark:after:bg-purple500" : "border-darkBlue dark:hover:after:bg-purple500 hover:text-primaryPurple hover:dark:text-purple500 hover:after:bg-primaryPurple after:bg-darkBlue dark:after:bg-grey100 dark:text-grey100"} ${btnClassName || ""}`}
      >
        {!hideTooltip && cutTitle && (findLabel || "").length > cutTitle ? (
          <Tooltip
            title={findLabel}
            anchorId={id}
            place="top"
          />
        ) : ""}
        <div className="flex items-center justify-between w-full">
          {titleAsImage ? titleAsImage
            :
            <div className="flex items-center"
            >
              {img && <img src={img} alt="custom" className={`w-5.5 h-6 mr-2 ${iconClassName || ""}`} />}
              {Icon ? (
                <Icon
                  className={`mr-3 ${iconClassName || ""} ${open ? "fill-primaryPurple dark:fill-purple500" : "fill-darkBlue dark:fill-grey100 group-hover:fill-primaryPurple dark:group-hover:fill-purple500"
                    }`}
                />
              ) : (
                ""
              )}
              <span className={`block my-auto mr-4 font-medium text-sm pl-1 ${titleClassName || ""}`}
              >{cutTitle ? cutLongTitle(findLabel || "", cutTitle) : findLabel}</span>
            </div>}

          <div className="w-6 h-6 flex items-center justify-center">
            {CustomArrow ? (
              <CustomArrow
                className={` my-auto ${open ? "fill-primaryPurple dark:fill-purple500" : "fill-darkBlue dark:fill-grey100 group-hover:fill-primaryPurple dark:group-hover:fill-purple500"
                  } ${arrowClassName || ""}`}
              />
            ) : (
              <ArrowDown
                className={`transition-transform duration-600 h-[6px] w-2.5 w-3 my-auto ${open ? "fill-primaryPurple dark:fill-purple500" : "fill-darkBlue dark:fill-grey100 group-hover:fill-primaryPurple dark:group-hover:fill-purple500"
                  } ${open ? "rotate-180" : ""} ${arrowClassName || ""}`}
              />
            )}
          </div>
        </div>
      </button>
      {open &&
        (
          <SelectOptionsCard
            noDisabledOptionTooltip={noDisabledOptionTooltip}
            listWrapperClassName={listWrapperClassName}
            hideOptions={hideOptions}
            listHeight={listHeight}
            cutLongOption={cutLongOption}
            options={options}
            value={value}
            listItemClassName={listItemClassName}
            handleOptionClick={handleOptionClick}
            CustomOptions={CustomOptions}
          />
        )}
    </div>
  );
};

export default Select;
