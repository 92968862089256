import { ReactComponent as ArrowDown } from "assets/icons/sort-arrow-down.svg";
import Tooltip from "components/Tooltip";
import { FC, useId } from "react";

interface Props {
    handleClick: () => void;
    showGraphics: boolean;
    title?:string;
}

const StatsArrowBtn: FC<Props> = ({ showGraphics, handleClick ,title}) => {
    const id  = useId();
    return(
    <>
    <button
        data-tooltip-id={id}
        className={`absolute right-[18px] bottom-[43px] border-2 w-6 h-6 flex items-center justify-center  
            dark:border-grey100 rounded-full group hover:bg-primaryPurple hover:border-primaryPurple dark:hover:border-purple500 dark:hover:bg-purple500
        ${showGraphics ? "bg-white border-white dark:bg-deepSpace900" : "border-darkBlue"}`}
        onClick={handleClick}

    >
        <ArrowDown className={`fill-darkBlue dark:fill-grey100 w-3 h-3.5 group-hover:fill-white ${showGraphics ? "rotate-180" : ""} transition-transform duration-500`} />
    </button>
    {title && <Tooltip anchorId={id} title={title} />}    
    </>
)}

export default StatsArrowBtn;