import { ColumnDef } from "@tanstack/react-table";
import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";
import { ReactComponent as LineGraph } from "assets/icons/line-graph.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { ReactComponent as ShippingMethod } from "assets/icons/shipping-method.svg";
import { ReactComponent as Shipping } from "assets/icons/shipping.svg";
import { ReactComponent as Star } from "assets/icons/star.svg";

import InfoTooltip from "components/Card/InfoTooltip";
import CutLongTitle from "components/CutLongTitle";
import HoveredIcon from "components/HoveredIcon";
import ProductNameCell from "components/ProductNameCell";
import { Link } from "react-router-dom";
import { PATH } from "routes/path";
import { PrAliSellerList } from "types/services/pr";
import { formatCurrency, formatNumber } from "utils/formatCurrency";
import formatString from "utils/formatString";
import { makeQueryParams } from "utils/makeQueryParams";

export const prAliexpressCols = (
  handleAnalyzeClick: (item: PrAliSellerList) => void
): ColumnDef<PrAliSellerList>[] => [
    {
      header: () => (
        <div className="flex items-center gap-2">
          <CompetitorResearch className="fill-darkBlue dark:fill-grey100 w-[17.5px] h-[18.96px]" />
          <h6>Seller</h6>
        </div>
      ),
      enableSorting: false,
      accessorKey: "storeID",
      cell: ({ row }) => (
        <CutLongTitle
          title={formatString(String(row.original.storeName))}
          length={15}
        />
      ),
      meta: {
        tooltipTitle: "",
        hideBorder: true,
        align: "justify-start",
        className: "pl-4",
      },
    },
    {
      header: "",
      accessorKey: "storeName",
      cell: (storeName) => (
        <Link
          to={`${PATH.competitorResearch.aliexpress
            }?search=${storeName.getValue()}`}
          target="_blank"
          rel="noreferrer"
        >
          <HoveredIcon
            Icon={CompetitorResearch}
            iconClassName="w-[1.3125rem] h-[1.1875rem]"
            className="w-[2.0625rem] h-8"
            title="Scan Seller"
          />
        </Link>
      ),
    },
    {
      header: () => <Star className="fill-darkBlue dark:fill-grey100" />,
      accessorKey: "feedbackCount",
      cell: (info) => formatNumber(Number(info.getValue())),
      meta: {
        tooltipTitle: "Total Feedback",
      },
    },
    {
      header: () => <Shipping className="fill-darkBlue dark:fill-grey100" />,
      accessorKey: "selectedColumnSales",
      cell: (info) => formatNumber(Number(info.getValue())),
      meta: {
        tooltipTitle: "Sales",
      },
    },
    {
      header: "Product Name",
      accessorKey: "mainImage",
      cell: (item) => (
        <ProductNameCell
          name={formatString(String(item.row.original.title))}
          img={String(item.getValue())}
          url={item.row.original.itemURL}
        />
      ),
      meta: {
        align: "justify-start",
        alignHead: "justify-between"
      },
    },
    {
      header: () => <ShippingMethod className="fill-darkBlue dark:fill-grey100" />,
      accessorKey: "shippingSpeed",
      enableSorting: false,
      minSize: 80,
      cell: () => "Standard",
      meta: {
        alignHead: "justify-center"
      }
    },
    {
      header: () => (
        <div className="flex items-center gap-1">
          <p>Sales</p>
          <InfoTooltip title="Total Sales from the last 6 months" />
        </div>
      ),
      accessorKey: "revenueTotal",
      cell: ({ row }) => formatNumber(Number(row.original.selectedColumnSales)),
      meta: {
        sortColumnId: "selectedColumnSales",
      },
    },
    {
      header: "Price",
      accessorKey: "price",
      cell: (price) => formatCurrency(Number(price.getValue())),
    },
    {
      header: () => <SearchIcon className="fill-darkBlue dark:fill-grey100" />,
      enableSorting: false,
      minSize: 50,
      accessorKey: "aliid",
      cell: ({ row }) => (
        <HoveredIcon
          Icon={SearchIcon}
          handleClick={() => handleAnalyzeClick(row.original)}
          title="Analyze"
          iconClassName="w-3.5 h-[13px]"
        />
      ),
      meta: {
        alignHead: "justify-center"
      }
    },
    {
      header: () => <LineGraph className="fill-darkBlue dark:fill-grey100" />,
      enableSorting: false,
      minSize: 50,
      accessorKey: "title",
      cell: (title) => (
        <Link
          to={makeQueryParams(PATH.productResearch.ebay, { search: title.getValue() })}
          target="_blank"
          rel="noreferrer"
        >
          <HoveredIcon Icon={LineGraph} title="Scan on Ebay" />
        </Link>
      ),
      meta: {
        alignHead: "justify-center"
      }
    },
  ];
