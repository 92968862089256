import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg";
import Header from "components/Header";
import HeaderLanguage from "components/Header/Language";
import HeaderNewsFeed from "components/Header/NewsFeed";
import HeaderNotifications from "components/Header/Notifications";
import HeaderTab from "components/Header/Tab";
import Tools from "components/tools";
import useModal from "hooks/useModal";

const ShopifyMarketInsightsHeader = () => {
    const { isOpen, close, toggle } = useModal();

    return (
        <>
            <Header
                leftContent={
                    <>
                        <HeaderTab
                            title="Market Insights"
                            isActive={true}
                            handleClick={() => { }}
                        />
                        <div className="relative text-sm">
                            <button
                                onClick={toggle}
                                className={`
                                    ${isOpen
                                        ? "text-primaryPurple border-primaryPurple dark:text-purple500 dark:border-purple500 pointer-events-none after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-full after:h-[3px] after:rounded after:bg-primaryPurple dark:after:bg-purple500"
                                        : "hover:rounded-[100px] dark:text-grey100  hover:bg-neutral1 hover:text-primaryPurple dark:bg-transparent dark:hover:text-purple500"
                                    } relative h-9 min-w-[6.25rem] flex items-center justify-center gap-6.5 group`}
                            >
                                <p>Tools</p>
                                <ArrowDown
                                    className={`${isOpen ? "fill-primaryPurple dark:fill-purple500 rotate-180" : "fill-darkBlue dark:fill-grey100 group-hover:fill-primaryPurple dark:group-hover:fill-purple500"
                                        } transition-transform duration-600 w-2.5 h-1.5`}
                                />

                            </button>
                            {isOpen && <Tools handleClose={close} isShopify />}
                        </div>
                    </>
                }
                rightContent={
                    <>
                        <HeaderLanguage />
                        <HeaderNewsFeed />
                        <HeaderNotifications />
                    </>
                }
            />
            <div className="flex gap-4 w-full max-xl:flex-wrap" />
        </>
    )
}

export default ShopifyMarketInsightsHeader