import { ReactComponent as Plus } from "assets/icons/plus.svg";
import { ReactComponent as Store } from "assets/icons/store.svg";

import Card from "components/Card";
import AddStore from "pages/ManagementPanel/components/AddStore";
import { FC, useState } from "react";

const StoreAnalytics: FC = () => {
  const [addStore, setAddStore] = useState(false);

  const onAddStoreClick = () => setAddStore(true);

  return addStore ? (
    <AddStore myDashboardPage />
  ) : (
    <Card className=" h-[304px] relative" bordered>
      <div className="flex items-center gap-3 justify-center mt-7">
        <p className="font-bold dark:text-grey100">My Store Analytics</p>
        <Store className="w-[26px] h-[22px] fill-darkBlue dark:fill-grey100" />
      </div>
      <div
        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center justify-center gap-3.5  hover:bg-neutral1 dark:hover:bg-grey900 cursor-pointer px-6 py-5 rounded-10"
        onClick={onAddStoreClick}
      >
        <div className="w-11 h-11 bg-smoothGreen dark:bg-paradiseBlue rounded-full flex items-center justify-center">
          <Plus className="fill-white w-[25px] h-[25px] dark:fill-deepSpace800" />
        </div>
        <p className="text-sm dark:text-grey100">Add Store</p>
      </div>
    </Card>
  );
};

export default StoreAnalytics;
