import { HeaderGroup } from "@tanstack/react-table";
import Loading from "components/Loading";
import { TableEmptyStyles } from "./Table";

interface Props<T> {
  styles?: TableEmptyStyles;
  headerGroup: HeaderGroup<T>;
  isLoading: boolean;
  emptyTableMsg?: string
}

const TableEmpty = <T,>({ styles, headerGroup, isLoading, emptyTableMsg }: Props<T>) => {
  const tdStyles = (
    isFirst?: boolean,
    isLast?: boolean
  ) => `border-y-2 border-secondary dark:border-grey700 dark:bg-grey700
    ${isFirst ? "border-l-2 rounded-l-10" : ""} 
    ${isLast ? "border-r-2 rounded-r-10" : ""}
    ${styles?.rowHeight || "h-[60px]"}
    ${styles?.tb || ""}
  `;

  const tdContStyles = `border-secondary flex items-center justify-center
    ${styles?.cont}
    ${styles?.contHeight || "h-9"}
  `;

  return (
    <tr>
      {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
      {headerGroup.headers.map((h, i, colRow) => (
        <td className={tdStyles(i === 0, i + 1 === colRow.length)} key={i}>
          <div className={tdContStyles}>
            {i === 0 ? (
              <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 font-medium text-base">
                {isLoading ? (
                  <Loading fill="fill-primaryPurple dark:fill-purple500" width={48} height={48} />
                ) : (
                  <p className={`dark:text-grey400 ${styles?.textStyles}`}>{emptyTableMsg || "No Data Available"}</p>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </td>
      ))}
    </tr>
  );
};

export default TableEmpty;
