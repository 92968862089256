import { useEffect } from "react";
import { Link } from "react-router-dom";
import { PATH } from "routes/path";
import { useResetCCMutation } from "store/api/testingApi";
import "./loading.css";
const ResetCC = () => {
    const [reset, { isLoading, error }] = useResetCCMutation();

    useEffect(() => {
        reset(null).unwrap()
    }, []);

    const linesClassName = 'h-[27px] rounded-full absolute left-1/2 -translate-x-1/2';

    return (
        <div className="min-h-screen w-full h-full bg-white flex items-center justify-center ">
            {isLoading && <div className="h-full">
                <div className="h-[95px] -rotate-[55deg] w-fit mx-auto mb-10 relative -top-[70px] max-md:-top-[60px]">
                    <div
                        className={`w-[103px] bg-primaryPurple dark:bg-purple500 top-0 firstLine -left-[150px] ${linesClassName}`}
                    />
                    <div
                        className={`w-[155px] bg-smoothGreen dark:bg-paradiseBlue top-[34px] secondLine ${linesClassName}`}
                    />
                    <div
                        className={`w-[105px] bg-darkBlue top-[68px] thirdLine ${linesClassName}`}
                    />
                </div>
                <h2 className="text-2xl text-darkBlue dark:text-lilac400 font-bold text-center mt-10 mb-5 mx-4">
                    Processing...
                </h2>
                <p className="text-xl text-darkBlue dark:text-lilac400 text-center mb-6">
                    This might take just a few seconds...
                </p>
            </div>
            }
            {!isLoading && <div className="h-full"><h2 className="text-2xl text-darkBlue dark:text-lilac400 font-bold text-center mt-10 mb-5 mx-4">
                Complete. You can navigate away.
            </h2>
                <p className="text-xl text-darkBlue dark:text-lilac400 text-center mb-6">

                    <Link
                        className="text-electricBlue hover:text-primaryPurple dark:hover:text-purple500 underline cursor-pointer text-center mb-[25px] block"
                        to={PATH.root}
                    >
                        Home
                    </Link>
                </p>
            </div>
            }

        </div >
    );
};

export default ResetCC;